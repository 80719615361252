import { useState, useEffect, useRef } from "react";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip from "@mui/material/Tooltip";
import { ToastContainer, toast } from "react-toastify";
import Loader from "./Loader";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Agreement from "./Agreement";

const PopupAgreement = ({ title, required, slug, isAgreeToAllChecked }) => {
  const [openAgreement, setOpenAgreement] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(
    isAgreeToAllChecked ? true : false
  );

  useEffect(() => {
    if (isAgreeToAllChecked) {
      setCheckboxChecked(true);
    } else {
			setCheckboxChecked(false);
		}
  }, [isAgreeToAllChecked]);

  const handleClose = () => {
    setOpenAgreement(false);
  };

  const handleOpen = () => {
    setOpenAgreement(true);
  };

  const handleAgreement = () => {
    setCheckboxChecked(true);
    setOpenAgreement(false);
  };

  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={checkboxChecked}
              onChange={(e) => handleOpen(e)}
              inputProps={{ "aria-label": "controlled" }}
              required={required == "true" ? true : false}
            />
          }
          label={title}
					className="popup-agreement-text"
        />
      </FormGroup>

      {/* Block User Login */}
      <Dialog
        open={openAgreement}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="popupProAgreements"
      >
        <DialogTitle id="alert-dialog-title">{`${title}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <>
              <Agreement slug={slug} />
              <br />
            </>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="confirm" onClick={handleAgreement}>
            Agree
          </button>
          <button className="cancel" onClick={handleClose}>
            Cancel
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PopupAgreement;
