import usePageTitle from '../../hooks/usePageTitle';
import LegalPage from '../../components/LegalPage';

const Conditions = () => {
	usePageTitle("Mobile Alerts Terms and Conditions");

	return (
		<LegalPage title='Mobile Alerts Terms and Conditions' slug='mobile-alerts-terms-and-conditions' />
	)
}

export default Conditions