import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';
import { FaExclamation } from 'react-icons/fa';
import usePageTitle from '../hooks/usePageTitle';
import UserPageNav from '../components/UserPageNav';
import useAuth from '../hooks/useAuth';
import { ToastContainer, toast } from 'react-toastify';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const NAME_REGEX = /^[a-zA-Z0-9-_&@'?! ]{1,50}$/;
const DESCRIPTION_REGEX = /^[a-zA-Z0-9-_.,& #:;'?! ]{3,500}$/;

const SupportForm = ({ addTicket }) => {

	const { auth } = useAuth();
	let isVerified = auth?.user?.email_verified_at;
	const userEmail = auth?.user?.email
	const userID = auth?.user?.id

	const axiosPrivate = useAxiosPrivate();

	const [showAdd, setShowAdd] = useState(false)

	const nameRef = useRef();
	const descriptionRef = useRef();
	const subjectRef = useRef();

	const [type, setType] = useState("Profile");

	const [description, setDescription] = useState("");
	const [validDescription, setValidDescription] = useState(false);

	const [subject, setSubject] = useState()
	const [validSubject, setValidSubject] = useState(false)
	const [subjectFocus, setSubjectFocus] = useState(false);

	/* Check Description */
	useEffect(() => {
		const result = DESCRIPTION_REGEX.test(description);
		setValidDescription(result);
	}, [description])

	/* Check Subject */
	useEffect(() => {
		const result = NAME_REGEX.test(subject);
		setValidSubject(result);
	}, [subject])

	const handleSubmit = async (e) => {
		e.preventDefault();

		const dismiss = () =>  toast.dismiss(addTicketToast.current);
		const addTicketToast = toast.loading("Submitting Ticket");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.post('ticket',
			JSON.stringify({subject: subject, service_type: type, description: description}),
			{
				signal: controller.signal
			});

			// Get ticket id from database
			const id = response?.data?.ticket?.id
			const service_type = type

			// Get date from database
			const created_at = response?.data?.ticket?.created_at

			// Add ticket to tickets
			addTicket({id, subject, service_type, description, created_at})


			// Clear Form
			setDescription("")
			setSubject("")

			// Update Toast Notification
			toast.update(addTicketToast, { render: 'Ticket Submitted', type: 'success', isLoading: false, autoClose: 5000});
		} catch (err) {
			if (!err?.response) {
				toast.update(addTicketToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else if (err.response?.status === 401) {
				toast.update(addTicketToast, { render: 'Authorization Failed', type: 'error', isLoading: false});
				{/* setErrMsg('The email has already been taken.'); */}
			} else {
				toast.update(addTicketToast, { render: 'Ticket Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	return (
		<>
		<h3>Create New Support Ticket</h3>
		<p>User Email: {userEmail ? userEmail : "email not found"}</p>
		<p>User ID: {userID ? userID : "id not found"}</p>
		<form id="supportForm" onSubmit={handleSubmit}>
			{/* Subject */}
			<label htmlFor="subject">Subject:</label>
			<div className="inputWrapper">
				<span className={validSubject ? "valid" : "hide"}><FaCheck /></span>
				<span className={validSubject || !subject ? "hide" : "invalid"}><FaExclamation /></span>
				<input
					type="text"
					id="subject"
					className={validSubject || !subject ? null : "error"}
					ref={subjectRef}
					autoComplete="off"
					onChange={(e) => setSubject(e.target.value)}
					value={subject}
					aria-invalid={validSubject ? "false" : "true"}
					aria-describedby="subjectnote"
					onFocus={() => setSubjectFocus(true)}
					onBlur={() => setSubjectFocus(false)}
				/>
			</div>
			<p id="subjectnote" className={subjectFocus && subject && !validSubject ? "instructions" : "offscreen"}>
				Invalid Subject. 1-50 characters (letters, numbers, dash, underscore, ampersand, apostrophe, exclamation mark and question mark allowed)
			</p>

			{/* Support Category */}
			<label htmlFor="type">What do you need help with?</label>
			<div className="inputWrapper">
				<select
					name="type"
					id="type"
					defaultValue="active"
					onChange={(e) => setType(e.target.value)}
					value={type}
				>
					<option value="Profile">Profile</option>
					<option value="Billing">Billing</option>
					<option value="Promotions">Promotions</option>
					<option value="Complaint">Complaint</option>
					<option value="Reviews">Reviews</option>
					<option value="Magazine">Magazine</option>
					<option value="Cancel Account">Cancel Account</option>
				</select>
			</div>

			{/* Description */}
			<label htmlFor="description">Details</label>
			<div className="inputWrapper">
				<span className={validDescription ? "valid" : "hide"}><FaCheck /></span>
				<span className={validDescription || !description ? "hide" : "invalid"}><FaExclamation /></span>
				<textarea
					ref={descriptionRef}
					onChange={(e) => setDescription(e.target.value)}
					name="description"
					id="description"
					rows="5"
					value={description}
					placeholder="Describe your problem with as much detail as possible."
				>
				</textarea>
				<p id="descriptionnote" className={description && !validDescription ? "instructions" : "offscreen"}>
					Invalid Description. Between 3-500 characters (letters, numbers, dash, underscore, period, comma, ampersand, number sign, colon, simicolon, apostrophe, exclamation mark and question mark allowed.)
				</p>
			</div>
			<button disabled={!validSubject || !validDescription ? true : false}>Submit Ticket</button>


		</form>

		<ToastContainer
		position="top-right"
		autoClose={5000}
		hideProgressBar={false}
		newestOnTop={false}
		closeOnClick
		rtl={false}
		pauseOnFocusLoss
		draggable
		pauseOnHover
		theme="colored"
		/>
	</>
	)
}

export default SupportForm