import { useState, useEffect } from 'react'
import useFormContext from "../hooks/useFormContext"
import useAuth from '../hooks/useAuth'

const states = [
	{
		"State": "Alabama",
		"Code":	"AL"
	},
	{
		"State": "Alaska",
		"Code":	"AK"
	},
	{
		"State": "Arizona",
		"Code":	"AZ"
	},
	{
		"State": "Arkansas",
		"Code":	"AR"
	},
	{
		"State": "California",
		"Code":	"CA"
	},
	{
		"State": "Colorado",
		"Code":	"CO"
	},
	{
		"State": "Connecticut",
		"Code":	"CT"
	},
	{
		"State": "Delaware",
		"Code":	"DE"
	},
	{
		"State": "District of Columbia",
		"Code":	"DC"
	},
	{
		"State": "Florida",
		"Code":	"FL"
	},
	{
		"State": "Georgia",
		"Code":	"GA"
	},
	{
		"State": "Hawaii",
		"Code":	"HI"
	},
	{
		"State": "Idaho",
		"Code":	"ID"
	},
	{
		"State": "Illinois",
		"Code":	"IL"
	},
	{
		"State": "Indiana",
		"Code":	"IN"
	},
	{
		"State": "Illinois",
		"Code":	"IL"
	},
	{
		"State": "Iowa",
		"Code":	"IA"
	},
	{
		"State": "Kansas",
		"Code":	"KS"
	},
	{
		"State": "Kentucky",
		"Code":	"KY"
	},
	{
		"State": "Louisiana",
		"Code":	"LA"
	},
	{
		"State": "Maine",
		"Code":	"ME"
	},
	{
		"State": "Maryland",
		"Code":	"MD"
	},
	{
		"State": "Massachusetts",
		"Code":	"MA"
	},
	{
		"State": "Michigan",
		"Code":	"MI"
	},
	{
		"State": "Minnesota",
		"Code":	"MN"
	},
	{
		"State": "Mississippi",
		"Code":	"MS"
	},
	{
		"State": "Missouri",
		"Code":	"MO"
	},
	{
		"State": "Montana",
		"Code":	"MT"
	},
	{
		"State": "Nebraska",
		"Code":	"NE"
	},
	{
		"State": "Nevada",
		"Code":	"NV"
	},
	{
		"State": "New Hampshire",
		"Code":	"NH"
	},
	{
		"State": "New Jersey",
		"Code":	"NJ"
	},
	{
		"State": "New Mexico",
		"Code":	"NM"
	},
	{
		"State": "New York",
		"Code":	"NY"
	},
	{
		"State": "North Carolina",
		"Code":	"NC"
	},
	{
		"State": "North Dakota",
		"Code":	"ND"
	},
	{
		"State": "Ohio",
		"Code":	"OH"
	},
	{
		"State": "Oklahoma",
		"Code":	"OK"
	},
	{
		"State": "Oregon",
		"Code":	"OR"
	},
	{
		"State": "Pennsylvania",
		"Code":	"PA"
	},
	{
		"State": "Rhode Island",
		"Code":	"RI"
	},
	{
		"State": "South Carolina",
		"Code":	"SC"
	},
	{
		"State": "South Dakota",
		"Code":	"SD"
	},
	{
		"State": "Tennessee",
		"Code":	"TN"
	},
	{
		"State": "Texas",
		"Code":	"TX"
	},
	{
		"State": "Utah",
		"Code":	"UT"
	},
	{
		"State": "Vermont",
		"Code":	"VT"
	},
	{
		"State": "Virginia",
		"Code":	"VA"
	},
	{
		"State": "Washington",
		"Code":	"WA"
	},
	{
		"State": "West Virginia",
		"Code":	"WV"
	},
	{
		"State": "Wisconsin",
		"Code":	"WI"
	},
	{
		"State": "Wyoming",
		"Code":	"WY"
	}
]

const FormStepOverview = () => {

	const { data, updateCharges, handleChange } = useFormContext()
	const selectedCounties = data.selected_counties
	const selectedServices = data.selected_services
	const excludedZips = data.excluded_zips
	const selectedOptions = data.selected_options.filter((option) => option.checked == true)
	console.log(selectedOptions)
	const selectedStateCode = data.active_state
	const selectedState = states?.find(state => {
		return state.Code === selectedStateCode;
	});

	let selectedStateName = selectedState?.State

	const auth = useAuth()
	const subscription = auth?.auth?.subscription
	const subscriptionPackageName = subscription?.package_details?.package_name
	const subscriptionPackageBasePrice = subscription?.package_details?.package_price
	const subscriptionPackageBillingFrequency = subscription?.package_details?.billing_frequency
	const subscriptionCategories = subscription?.package_details?.num_allowed_services
	const [additionalServices, setAdditionalServices] = useState(0)
	const additionalPerServiceFee = subscription?.package_details?.additional_service_charge
	const [monthlyCharges, setMonthlyCharges] = useState()
	const [additionalFees, setAdditionalFees] = useState()
	const [onetimeCharge, setOnetimeCharge] = useState()

	useEffect(() => {
		{/* Additional Fees */}
		if (selectedServices.length > subscriptionCategories){
			setAdditionalServices(selectedServices.length - subscriptionCategories)
			setAdditionalFees(((selectedServices.length - subscriptionCategories) * additionalPerServiceFee).toFixed(2))
			setMonthlyCharges((((selectedServices.length - subscriptionCategories) * additionalPerServiceFee) + +subscriptionPackageBasePrice).toFixed(2))
			updateCharges((((selectedServices.length - subscriptionCategories) * additionalPerServiceFee) + +subscriptionPackageBasePrice).toFixed(2), selectedOptions.reduce((a,v) => a = a + Number(v.price), 0).toFixed(2))
		} else {
			setMonthlyCharges((+subscriptionPackageBasePrice).toFixed(2))
			updateCharges((+subscriptionPackageBasePrice).toFixed(2), selectedOptions.reduce((a,v) => a = a + Number(v.price), 0).toFixed(2))
		}

		setOnetimeCharge(selectedOptions.reduce((a,v) => a = a + Number(v.price), 0).toFixed(2))

		// eslint-disable-next-line
	}, [])

	return (
		<div className="subscriptionOverview">
			<h4>State</h4>
			<ul><li><div>{selectedStateName}</div></li></ul>
			{selectedCounties?.length &&
				<>
					<h4>Counties</h4>
					<ul>
					{selectedCounties.map((county, i) =>
						<li key={i}>
							<div className="county">
								{county}
							</div>
						</li>
					)}
					</ul>
				</>
			}

			<h4>Excluded Zipcodes</h4>
			{excludedZips.length ?
				<>
					<ul>
					{excludedZips.map((zip, i) =>
						<li key={i}>
							<div className="zipcode">
								{zip}
							</div>
						</li>
					)}
					</ul>
				</>
				:
				<p>No zipcodes excluded from service</p>
			}

			<h4>Services</h4>
			{selectedServices.length ?
				<>
					<ul>
					{selectedServices.map((service, i) =>
						<li key={i}>
							<div className="service">
								{service}
							</div>
						</li>
					)}
					</ul>
				</>
				:
				<p>You have not selected any service categories. If you don't select any categories you will not show up in homeowner searches.</p>
			}

			<h4>Selected Add-ons</h4>
			{selectedOptions.length ?
				<>
					<p>Add-ons are not final until discussed with a service representative and are subject to additional fees.</p>
					<ul>
					{selectedOptions.map((option, i) =>
						<>
						{option.checked ?
						<li key={i}>
							<div className="name">
								{option.name}
							</div>
						</li>
						:
						null
						}
						</>
					)}
					</ul>
				</>
				:
				<p>You have not selected any add-ons</p>
			}

			<h4>Selected Package</h4>
			<p>Name: {subscriptionPackageName}</p>
			<p>Base Price: ${subscriptionPackageBasePrice}</p>
			{ additionalServices > 0 &&
				<p>Additional Service Categories (x{additionalServices}): ${additionalFees}</p>
			}
			{ subscriptionPackageBillingFrequency == "monthly" &&
				<p>Montly Total: ${monthlyCharges}</p>
			}
			{ subscriptionPackageBillingFrequency == "annual" &&
				<p>Yearly Total: ${((additionalServices * additionalPerServiceFee * 12) + +subscriptionPackageBasePrice).toFixed(2)}</p>
			}
			{`One-time Setup Charge: $${onetimeCharge}`}


		</div>
	)
}

export default FormStepOverview