import * as React from 'react';
import { useEffect, useState } from "react";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import AdminMenu from '../../components/AdminMenu';
import DashboardItem from '../../components/DashboardItem';
import Loader from '../../components/Loader';
import Coupons from '../../components/Coupons'
import { TbDiscount2 } from 'react-icons/tb';

const AdminCoupons = () => {
	return (
		<section>
			<article className="inner">
				<div className="backendTitleBar">
					<h1>Coupons</h1>
					<TbDiscount2 />
				</div>
				<div className="backendColumnWrapper">
					<div id="menu" className="column">
						<AdminMenu />
					</div>
					<div id="content" className="column">
						<Coupons />
					</div>
				</div>
			</article>
		</section>
	)
}

export default AdminCoupons