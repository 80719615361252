import { useState, useEffect } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import { AiFillDelete } from 'react-icons/ai';
import { AiOutlineShareAlt } from 'react-icons/ai';
import { MdVisibility } from 'react-icons/md';
import { MdOutlineEdit } from 'react-icons/md';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import Loader from './Loader';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


const Reviews = () => {

	const [loading, setLoading] = useState(false);
	const [reviews, setReviews] = useState();
	const [filteredReviews, setFilteredReviews] = useState();
	const axiosPrivate = useAxiosPrivate();

	const navigate = useNavigate();
	const location = useLocation();

	const auth = useAuth();
	const role = auth?.role;
	const id = auth?.id;

	const [status, setStatus] = useState()

	const [filterStatus, setFilterStatus] = useState("review")

	const [name, setName] = useState();
	const [reviewText, setReviewText] = useState();
	const [rating, setRating] = useState();
	const [deleteName, setDeleteName] = useState();
	const [deleteID, setDeleteID] = useState();
	const [approveID, setApproveID] = useState();
	const [open, setOpen] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [openView, setOpenView] = useState(false);
	const [homeowner, setHomeowner] = useState();
	const [homeownerNote, setHomeownerNote] = useState();
	const [contractorNote, setContractorNote] = useState();

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleEdit = (edit) => {
		setOpenEdit(true);
	};

	const handleCloseEdit = () => {
		setOpenEdit(false);
	};

	const handleView = () => {
		setOpenView(true);
	};

	const handleCloseView = () => {
		setOpenView(false);
	};

	const handleApprove = async () => {
		const dismiss = () =>  toast.dismiss(approveReviewToast.current);
		const approveReviewToast = toast.loading("Approving Review");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.put('review/' + approveID,
			JSON.stringify({status: 'approved'}),
			{
				signal: controller.signal
			});

			// Update UI State to show approved
			const index = reviews.map(function(x) {return x.id; }).indexOf(approveID);
			reviews[index].status = "approved"

			// Update Toast Notification
			toast.update(approveReviewToast, { render: 'Review Approved', type: 'success', isLoading: false, autoClose: 5000});

			// Close Alert Window
			setOpenView(false);

		} catch (err) {
			if (!err?.response) {
				toast.update(approveReviewToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(approveReviewToast, { render: 'Review Approved Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	const handleDeny = async () => {
		const dismiss = () =>  toast.dismiss(denyReviewToast.current);
		const denyReviewToast = toast.loading("Denying Review");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.put('review/' + approveID,
			JSON.stringify({status: 'denied'}),
			{
				signal: controller.signal
			});

			// Update UI State to show approved
			const index = reviews.map(function(x) {return x.id; }).indexOf(approveID);
			reviews[index].status = "denied"

			// Update Toast Notification
			toast.update(denyReviewToast, { render: 'Review Denied', type: 'success', isLoading: false, autoClose: 5000});

			// Close Alert Window
			setOpenView(false);

		} catch (err) {
			if (!err?.response) {
				toast.update(denyReviewToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(denyReviewToast, { render: 'Review Deny Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	const handleDelete = async () => {
		const dismiss = () =>  toast.dismiss(deleteReviewToast.current);
		const deleteReviewToast = toast.loading("Deleting Review");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.delete('review/' + deleteID,
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(deleteReviewToast, { render: 'Review Deleted', type: 'success', isLoading: false, autoClose: 5000});

			// Close Alert Window
			setOpen(false);

			// Delete review from UI
			const filtered = reviews.filter((review) => review.id !== deleteID)
			setFilteredReviews(filtered)
			setReviews(filtered)

		} catch (err) {
			if (!err?.response) {
				toast.update(deleteReviewToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(deleteReviewToast, { render: 'Review Delete Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	};

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getReviews = async () => {
			try {
				const response = await axiosPrivate.get('reviews', {
					signal: controller.signal
				});
				console.log(response.data);
				setReviews(response.data);

				const resultsArray = response.data?.filter(review => review.status.includes(filterStatus))
				setFilteredReviews(resultsArray)

				setLoading(false)
			} catch(err) {
				console.log(err.message);
				!auth && navigate('/login', { state: { from: location }, replace: true });
			}
		}

		getReviews();

		return () => controller.abort();

	},[])

	const deleteReview = (e, i, name, id) => {
		setOpen(true);
		setDeleteName(name);
		setDeleteID(id);
	}

	const viewReview = (e, i, name, id, review, rating, homeowner, status, homeowner_note, contractor_note) => {
		setOpenView(true);
		setName(name)
		setReviewText(review)
		setRating(rating)
		setHomeowner(homeowner)
		setApproveID(id)
		setStatus(status)
		setHomeownerNote(homeowner_note)
		setContractorNote(contractor_note)
	}

	const editReview = (e, i, name, id, review, rating) => {
		setOpenEdit(true);
		setName(name)
		setReviewText(review)
		setRating(rating)
	}

	const addReview = (review) => {
		console.log(review);
		setReviews([...reviews, review]);
	}

	const formatDate = (dateString) => {
	  const options = { month: "long", day: "numeric", year: "numeric" }
	  return new Date(dateString).toLocaleDateString(undefined, options)
	}

	useEffect(() => {
		if (filterStatus === "all"){
			setFilteredReviews(reviews)
		} else {
			const resultsArray = reviews?.filter(review => review.status.includes(filterStatus))
			setFilteredReviews(resultsArray)
		}
	}, [filterStatus])

	return (
		<div className="backendList">
			<div className="selectContainer">
				<div className="selectWrapper">
					<Box>
						<FormControl fullWidth>
							<InputLabel id="filter-status-label">Filter Status</InputLabel>
							<Select
						  	labelId="filter-status-label"
						  	id="filterStatus"
						  	onChange={(e) => setFilterStatus(e.target.value)}
						  	value={filterStatus}
							>
								<MenuItem value="all">View All</MenuItem>
								<MenuItem value="approved">Approved</MenuItem>
								<MenuItem value="pending">Pending</MenuItem>
								<MenuItem value="review">Review</MenuItem>
							</Select>
					  	</FormControl>
					</Box>
				</div>
			</div>
			<div>
				<p><strong>Review:</strong> These are reviews that have been disputed and need to be reviewed by you, an Admin.</p>
				<p><strong>Pending:</strong> These are new reviews that the contractor has not approved or disputed yet.</p>
			</div>
			{reviews?.length
				? (
					<>
						<ul className="reviewList">
							<li className="key">
								<div className="name">Contractor</div>
								<div className="statusContainer">Status</div>
								<div className="date">Date</div>
								<div className="buttons"></div>
							</li>
							{filteredReviews.map((review, i) =>
								<li key={i}>
									<div className="name">
										{review?.contractor_name}
									</div>
									<div className="statusContainer">
										<div className={`status ${review?.status}`}>
											{review?.status}
										</div>
									</div>
									<div className="date">
										{formatDate(review?.created_at)}
									</div>
									<div className="buttons">
										<div className="view" onClick={(e) => viewReview(e, i, review?.contractor_name, review?.id, review?.review_text, review?.rating, review?.reviewed_by_name, review?.status, review?.private_homeower_comment, review?.private_contractor_comment)}>
											<span><MdVisibility /></span>
										</div>
										<div className="delete" onClick={(e) => deleteReview(e, i, review?.contractor_name, review?.id)}>
											<span><AiFillDelete /></span>
										</div>
										{/* <div className="edit" onClick={(e) => editReview(e, i, review?.contractor_name, review?.id)}>
											<span><MdOutlineEdit /></span>
										</div> */}
									</div>
								</li>
							)}
						</ul>
						{/* Delete Dialog */}
						<Dialog
							open={open}
							onClose={handleClose}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						  >
							<DialogTitle id="alert-dialog-title">
							  {"Are you sure you want to delete this review?"}
							</DialogTitle>
							<DialogContent>
							  <DialogContentText id="alert-dialog-description">
								This action cannot be undone. This will permanently delete the review with name, "{deleteName}".
							  </DialogContentText>
							</DialogContent>
							<DialogActions>
							  <button className="cancel" onClick={handleClose}>Cancel</button>
							  <button className="confirm" onClick={handleDelete}>Yes, delete review</button>
							</DialogActions>
						  </Dialog>

						{/* Edit Dialog */}
						<Dialog
							open={openEdit}
							onClose={handleCloseEdit}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						  >
							<DialogTitle id="alert-dialog-title">
							  {`Editing ${homeowner}'s review of ${name}`}
							</DialogTitle>
							<DialogContent>
							  <DialogContentText id="alert-dialog-description">
								{"Editing reviews coming soon!"}
							  </DialogContentText>
							</DialogContent>
							<DialogActions>
							  <button className="cancel" onClick={handleCloseEdit}>Cancel</button>
							  {/* <button className="confirm" onClick={handleEdit}>Yes, update review</button> */}
							</DialogActions>
						  </Dialog>

						{/* View Dialog */}
						<Dialog
							open={openView}
							onClose={handleCloseView}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
							className="viewReviewPopup"
						  >
							<DialogTitle id="alert-dialog-title">
							  {`${homeowner}'s review of ${name}`}
							</DialogTitle>
							<DialogContent>
								<>
									<Rating name="size-medium" defaultValue={rating} readOnly />
									<p>{reviewText}</p>
									<h3>Homeowner Note</h3>
									<p>{homeownerNote ? homeownerNote : "Homeowner didn't provide any additional details."}</p>
									<h3>Contractor Note</h3>
									<p>{contractorNote ? contractorNote : "Contractor didn't provide any additional details."}</p>

								</>
							</DialogContent>
							<DialogActions>
							  <button className="cancel" onClick={handleCloseView}>Close</button>
							  {status == "review" && <button className="deny" onClick={handleDeny}>Deny Review</button>}
							  {status == "review" && <button className="confirm" onClick={handleApprove}>Approve Review</button>}
							</DialogActions>
						  </Dialog>
					</>
				) :
					loading ?
					<Loader />
					:
					<p>No reviews to display</p>
			}
			<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
			/>
		</div>
	)
}

export default Reviews