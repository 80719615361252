import AdminMenu from '../../components/AdminMenu';
import Users from "../../components/Users";
import Markets from "../../components/Markets"
import States from "../../components/States"
import { MdAdsClick } from 'react-icons/md';

const AdminMarkets = () => {
	return (
		<section>
			<article className="inner">
				<div className="backendTitleBar">
					<h1>Markets</h1>
					<MdAdsClick />
				</div>
				<div className="backendColumnWrapper">
					<div id="menu" className="column">
						<AdminMenu />
					</div>
					<div id="content" className="column">
						<Markets />
						<States />
					</div>
				</div>
			</article>
		</section>
	)
}


export default AdminMarkets