import usePageTitle from '../hooks/usePageTitle';
import Page from '../components/Page';

const MediaKit = () => {
	usePageTitle("Media Kit");
	return (
		<section>
			<Page title='MediaKit' slug='media-kit' />
		</section>
	)
}

export default MediaKit