import * as React from 'react';
import Badge from '@mui/material/Badge';

const DashboardItemContent = ({ title, icon, count, includeTime, description }) => {
	return (
		<>
			{ icon ?
				<div className="icon">
					{ count !== null ?
						<Badge badgeContent={count}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
						  	}}
							showZero
							max={99999}
						>
							{icon}
						</Badge>
					:
						<div className="icon">{icon}</div>
					}
				</div>
			:
			count !== null && <div className="count">{count}</div>
			}
			<h4>{title ? title : "Title Not Available"}</h4>
			{includeTime == "yes" ? (count > 1 || count == 0) ? "times": "time" : null}
			{ description && <p className="description">{description}</p> }
		</>
	)
}

export default DashboardItemContent