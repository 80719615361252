import { useState, useEffect, useRef } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate, useLocation, Link } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import { AiFillDelete } from 'react-icons/ai';
import { AiOutlineShareAlt } from 'react-icons/ai';
import { MdOutlineEdit } from 'react-icons/md';
import { FaCheck } from 'react-icons/fa';
import { FaExclamation } from 'react-icons/fa';
import { BsArchiveFill } from 'react-icons/bs';
import { MdOutlineRestore } from 'react-icons/md';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ToastContainer, toast } from 'react-toastify';
import CreateUserJob from "./CreateUserJob";
import Loader from './Loader';
import FileDropEmbed from './FileDropEmbed';


const NAME_REGEX = /^[a-zA-Z0-9-_&@ ]{1,50}$/;
const DESCRIPTION_REGEX = /^[a-zA-Z0-9-_.,& ]{1,500}$/;
const ADDRESS_REGEX = /^[a-zA-Z0-9-_/:#.&*\(\)', ]{0,100}$/;

const UserJobs = () => {

	const [loading, setLoading] = useState(false);
	const [jobs, setJobs] = useState();
	const [filteredJobs, setFilteredJobs] = useState()
	const [favorites, setFavorites] = useState();
	const axiosPrivate = useAxiosPrivate();

	const [openArchiveJob, setOpenArchiveJob] = useState(false)
	const [openRestoreJob, setOpenRestoreJob] = useState(false)
	const [openCreateJob, setOpenCreateJob] = useState(false)

	const [viewArchivedJobs, setViewArchivedJobs] = useState(false)
	const [archiveJob, setArchiveJob] = useState()
	const [restoreJob, setRestoreJob] = useState()

	const [services, setServices] = useState()
	const [searchServices, setSearchServices] = useState()
	const [searchServicesResults, setSearchServicesResults] = useState()
	const [selectedServices, setSelectedServices] = useState([])

	const navigate = useNavigate();
	const location = useLocation();

	const {auth, setAuth} = useAuth();
	const role = auth?.role;
	const id = auth?.id;

	const descriptionRef = useRef();
	const nameRef = useRef();
	const addressRef = useRef();

	const [files, setFiles] = useState([]);

	const [deleteName, setDeleteName] = useState();
	const [deleteID, setDeleteID] = useState();
	const [open, setOpen] = useState(false);

	const [shareID, setShareID] = useState();
	const [shareName, setShareName] = useState();
	const [openShare, setOpenShare] = useState(false);
	const [shareContractors, setShareContractors] = useState([])

	const [openEdit, setOpenEdit] = useState(false);

	const [editID, setEditID] = useState();
	const [editIndex, setEditIndex] = useState();
	const [editName, setEditName] = useState();
	const [validEditName, setValidEditName] = useState(false);
	const [editNameFocus, setEditNameFocus] = useState(false);

	const [editAddress, setEditAddress] = useState(auth?.user?.address || "");
	const [validEditAddress, setValidEditAddress] = useState(false);
	const [editAddressFocus, setEditAddressFocus] = useState(false);

	const [editService, setEditService] = useState(auth?.user?.service_category || "");
	const [validEditService, setValidEditService] = useState(false);
	const [editServiceFocus, setEditServiceFocus] = useState(false);

	const [editingCategory, setEditingCategory] = useState(false)

	const [editDescription, setEditDescription] = useState();
	const [validEditDescription, setValidEditDescription] = useState(false);
	const [editDescriptionFocus, setEditDescriptionFocus] = useState(false);

	const [editImages, setEditImages] = useState();
	const [deleteImages, setDeleteImages] = useState([]);
	const [validFiles, setValidFiles] = useState(false);
	const [fileLimit, setFileLimit] = useState(10);

	useEffect(() => {
		if (searchServices === ""){
			setSearchServicesResults()
		} else {
			const resultsArray = services?.filter(service => service.category_name.toLocaleLowerCase().includes(searchServices))
			setSearchServicesResults(resultsArray)
		}
	}, [searchServices])

	const toggleService = (e, i, service) => {
		if (!selectedServices.includes(service)){
				setSelectedServices([service])
				setSearchServicesResults()
				setSearchServices()
				setEditService(service)
		} else {
			setSelectedServices(selectedServices.filter(svc => { return svc !== service}))
		}
		setEditingCategory(false)
	}

	const deleteService = (e, i, service) => {
		setSelectedServices([])
	}

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleClickOpenShare = () => {
		setOpenShare(true);
	};

	const handleCloseShare = () => {
		setOpenShare(false);
	};

	const handleClickOpenEdit = () => {
		setOpenEdit(true);
	};

	const handleCloseEdit = () => {
		setOpenEdit(false);
		setDeleteImages([])
		setFiles([])
		setEditingCategory(false)
	};

	const editCategory = () => {
		editingCategory ? setEditingCategory(false) : setEditingCategory(true)
	}

	const handleArchiveJob = async () => {
		const dismiss = () =>  toast.dismiss(archiveJobToast.current);
		const archiveJobToast = toast.loading("Archiving Project");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.put('archive-job/' + archiveJob.id,
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(archiveJobToast, { render: 'Project Archived', type: 'success', isLoading: false, autoClose: 5000});

			// Update state with new data
			const jobsList = response.data.jobs

			setJobs(jobsList.filter((job) => job.status !== 'archived'));
			setFilteredJobs(jobsList.filter((job) => job.status === 'archived'));


			// Close Archive Popup
			setOpenArchiveJob(false);

		} catch (err) {
			if (!err?.response) {
				toast.update(archiveJobToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(archiveJobToast, { render: 'Project Archive Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	const handleRestoreJob = async () => {
		const dismiss = () =>  toast.dismiss(archiveJobToast.current);
		const archiveJobToast = toast.loading("Restoring Project");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.put('restore-job/' + restoreJob.id,
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(archiveJobToast, { render: 'Project Restored', type: 'success', isLoading: false, autoClose: 5000});

			// Update state with new data
			const jobsList = response.data.jobs

			setJobs(jobsList.filter((job) => job.status !== 'archived'));
			setFilteredJobs(jobsList.filter((job) => job.status === 'archived'));

			if (jobsList.filter((job) => job.status === 'archived').length === 0){
				setViewArchivedJobs(false)
			}

			// Close Archive Popup
			setOpenRestoreJob(false);

		} catch (err) {
			if (!err?.response) {
				toast.update(archiveJobToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(archiveJobToast, { render: 'Project Restore Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	const handleShareJob = async (id) => {
		console.log("sharing job id: " + id);

		const dismiss = () =>  toast.dismiss(shareJobToast.current);
		const shareJobToast = toast.loading("Sharing Project");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.post('share-job',
			JSON.stringify({job: id, contractors: shareContractors}),
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(shareJobToast, { render: 'Project Shared', type: 'success', isLoading: false, autoClose: 5000});

			// Update state with new data
			setOpenShare(false);

		} catch (err) {
			if (!err?.response) {
				toast.update(shareJobToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(shareJobToast, { render: 'Project Share Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	const handleDeleteImagesFromServer = async (image) => {
		console.log("Remove image " + image + " from job " + editID)

		const dismiss = () =>  toast.dismiss(saveJobToast.current);
		const saveJobToast = toast.loading("Deleting Image");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.delete('user/image/' + image,
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(saveJobToast, { render: 'Image Deleted', type: 'success', isLoading: false, autoClose: 5000});

			// Update state with new data



		} catch (err) {
			if (!err?.response) {
				toast.update(saveJobToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(saveJobToast, { render: 'Image Delete Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();

	}

	const handleEditSave = async () => {

		const formData = new FormData();
		console.log(files)
		files.map((file, i) => {
			formData.append('files[]', file);
		});

		formData.append('name', editName)
		formData.append('description', editDescription)
		formData.append('address', editAddress)
		formData.append('service_category', editService)

		// Key to making put work with formdata
		formData.append('_method', 'PUT');

		const dismiss = () =>  toast.dismiss(saveJobToast.current);
		const saveJobToast = toast.loading("Saving Project");
		const controller = new AbortController();

		try {
			const response = await axiosPrivate.post('job/' + editID,
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(saveJobToast, { render: 'Project Saved', type: 'success', isLoading: false, autoClose: 5000});
			const dismiss = () =>  toast.dismiss(saveJobToast.current);

			// Close Alert Window
			setOpenEdit(false);

			// Update state to show the changes
			const index = jobs.map(function(x) {return x.id; }).indexOf(editID);
			jobs[index].name = editName;
			jobs[index].description = editDescription;
			jobs[index].address = editAddress;
			jobs[index].service_category = editService;

			// Update images
			// Check if any images need deleted
			deleteImages && deleteImages.map((image, i) =>
				handleDeleteImagesFromServer(image)
			)

			// Update images in state for deleted state

			// Update images for adding new in state
			jobs[index].images = response?.data?.job?.images

			// Set files back to an empty array
			setFiles([])

			// Set Delete Images array to null so it is ready to go for the next edit
			setDeleteImages([])

		} catch (err) {
			if (!err?.response) {
				toast.update(saveJobToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(saveJobToast, { render: 'Project Update Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();

	}

	const handleDeleteImage = async (e, i, id) => {
		// We won't delete images until the save button is clicked so lets store the ids of images to delete if save is clicked
		setDeleteImages([...deleteImages, id])

		// Delete Image from UI
		setEditImages(editImages.filter((image) => image.id !== id))
	}

	// Delete Job
	const handleDelete = async () => {
		const dismiss = () =>  toast.dismiss(deleteProfileToast.current);
		const deleteProfileToast = toast.loading("Deleting Project");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.delete('job/' + deleteID,
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(deleteProfileToast, { render: 'Project Deleted', type: 'success', isLoading: false, autoClose: 5000});

			// Close Alert Window
			setOpen(false);

			// Delete job from UI
			setJobs(jobs.filter((job) => job.id !== deleteID))

		} catch (err) {
			if (!err?.response) {
				toast.update(deleteProfileToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(deleteProfileToast, { render: 'Project Delete Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	};

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getJobs = async () => {
			try {
				const response = await axiosPrivate.get('user-jobs', {
					signal: controller.signal
				});
				const jobsList = response.data.jobs
				setJobs(jobsList.filter((job) => job.status !== 'archived'));
				setFilteredJobs(jobsList.filter((job) => job.status === 'archived'));
				setFavorites(response.data.favorites);
				setServices(response.data.services);
				setSearchServicesResults([])
				setLoading(false)
			} catch(err) {
				console.log(err.message);
				{/* !auth && navigate('/login', { state: { from: location }, replace: true }); */}
			}
		}

		getJobs();

		return () => controller.abort();

	// eslint-disable-next-line
	},[])

	useEffect(() => {
		const currentCount = files?.length;
		const addCount = editImages?.length;
		const count = currentCount + addCount;

		if (fileLimit){
			count <= fileLimit ? setValidFiles(true) : setValidFiles(false);
		}

		const formData = new FormData();
		files?.map((file, index) => {
			formData.append('files[]', file);
		});

		// Fix for having no files on edit
		if (currentCount == 0){
			setValidFiles(true)
		}

	}, [files])

	/* Check Edit Name */
	useEffect(() => {
		const result = NAME_REGEX.test(editName);
		setValidEditName(result);
	}, [editName])

	/* Check Description */
	useEffect(() => {
		const result = DESCRIPTION_REGEX.test(editDescription);
		setValidEditDescription(result);
	}, [editDescription])

	/* Check Address */
	useEffect(() => {
		const result = ADDRESS_REGEX.test(editAddress);
		setValidEditAddress(result);
	}, [editAddress])

	const deleteJob = (e, i, name, id) => {
		setOpen(true);
		setDeleteName(name);
		setDeleteID(id);
	}

	const editJob = (e, i, name, id, address, service, description, images) => {
		setOpenEdit(true);
		setEditName(name);
		setEditID(id)
		setEditIndex(i)
		setEditDescription(description)
		setEditAddress(address)
		setEditService(service)
		setEditImages(images)
		setSelectedServices([])
		setSearchServicesResults()
		setSearchServices()
	}

	const addJob = (job) => {
		console.log(job);
		setJobs([...jobs, job]);

		// Close popup
		setOpenCreateJob(false)

		// Add job to user auth
		const authJobs = auth?.jobs
		setAuth(prevData => ({
			...prevData,
			jobs:[...authJobs, job]
		}))
	}

	const shareJob = (e,i, name, id) => {
		setOpenShare(true);
		setShareID(id);
		setShareName(name);
	}

	const handleShareChange = (e, contractor_id) => {
		const checked = e.target.checked

		if (checked){
			// add contractor id to array
			const newContractors = [...shareContractors, contractor_id]
			setShareContractors(newContractors)
		} else {
			// remove contractor id from array
			setShareContractors(shareContractors.filter(id => id !== contractor_id))
		}
	}

	const formatDate = (dateString) => {
	  const options = { month: "long", day: "numeric", year: "numeric" }
	  return new Date(dateString).toLocaleDateString(undefined, options)
	}

	const gotoSearch = () => {
		navigate("/")
	}

	const handleArchiveJobPopup = (e, i, job) => {
		setArchiveJob(job)
		setOpenArchiveJob(true)
	}

	const handleRestoreJobPopup = (e, i, job) => {
		setRestoreJob(job)
		setOpenRestoreJob(true)
	}

	const handleCloseArchiveJob = () => {
		setOpenArchiveJob(false)
	}

	const handleCloseRestoreJob = () => {
		setOpenRestoreJob(false)
	}

	const handleCloseCreateJob = () => {
		setOpenCreateJob(false)
	}

	return (
		<div className="backendList">
			<div className="selectContainer">
				<div className="selectWrapper">
					<button onClick={(e) => setOpenCreateJob(true)}>Create Project</button>
				</div>
				<div className="selectWrapper">
				{filteredJobs?.length >= 1 ? <button onClick={(e) => setViewArchivedJobs(!viewArchivedJobs)}>{viewArchivedJobs ? 'View Active' : 'View Archived'}</button> : null}
				</div>
			</div>
			{jobs?.length && !viewArchivedJobs
				? (
					<>
					<h4>Active Projects</h4>
					<ul className="jobsList">
						<li className="key">
							<div className="name">Name</div>
							<div className="date">Date</div>
							<div className="buttons"></div>
						</li>
						{jobs.map((job, i) =>
							<li key={i}>
								<div className="name">
									{job?.name}
								</div>
								<div className="date">
									{formatDate(job?.created_at)}
								</div>
								<div className="buttons">
									<div className="share" onClick={(e) =>shareJob(e, i, job?.name, job?.id)}>
										<span><AiOutlineShareAlt /></span>
									</div>
									<div className="edit" onClick={(e) => editJob(e, i, job?.name, job?.id, job?.address, job?.service_category, job?.description, job?.images)}>
										<span><MdOutlineEdit /></span>
									</div>
									<div className="archive" onClick={(e) => handleArchiveJobPopup(e, i, job)}>
										<span><BsArchiveFill /></span>
									</div>
									<div className="delete" onClick={(e) => deleteJob(e, i, job?.name, job?.id)}>
										<span><AiFillDelete /></span>
									</div>
								</div>
							</li>
						)}
					</ul>
					</>
				) :
					loading ?
						<Loader />
						:
						!viewArchivedJobs ? <p>Click the button to create a home improvement project that you may share with your chosen Home Pros in order to get an estimate.</p> : null
			}

			{filteredJobs?.length && viewArchivedJobs
				? (
					<>
					<h4>Archived Projects</h4>
					<ul className="jobsList">
						<li className="key">
							<div className="name">Name</div>
							<div className="date">Date</div>
							<div className="buttons"></div>
						</li>
						{filteredJobs.map((job, i) =>
							<li key={i}>
								<div className="name">
									{job?.name}
								</div>
								<div className="date">
									{formatDate(job?.created_at)}
								</div>
								<div className="buttons">
									<div className="edit" onClick={(e) => editJob(e, i, job?.name, job?.id, job?.address, job?.service_category, job?.description, job?.images)}>
										<span><MdOutlineEdit /></span>
									</div>
									<div className="restore" onClick={(e) => handleRestoreJobPopup(e, i, job)}>
										<span><MdOutlineRestore /></span>
									</div>
									<div className="delete" onClick={(e) => deleteJob(e, i, job?.name, job?.id)}>
										<span><AiFillDelete /></span>
									</div>
								</div>
							</li>
						)}
					</ul>
					</>
				) :
					null
			}

					{/* DELETE JOB */}
					<Dialog
						open={open}
						onClose={handleClose}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					  >
						<DialogTitle id="alert-dialog-title">
						  {"Are you sure you want to delete this project?"}
						</DialogTitle>
						<DialogContent>
						  <DialogContentText id="alert-dialog-description">
							{`This action cannot be undone. This will permanently delete the project with name, ${deleteName}`}.
						  </DialogContentText>
						</DialogContent>
						<DialogActions>
						  <button className="cancel" onClick={handleClose}>Cancel</button>
						  <button className="confirm" onClick={handleDelete}>Yes, delete project</button>
						</DialogActions>
					</Dialog>

					{/* ARCHIVE JOB */}
					<Dialog
						open={openArchiveJob}
						onClose={handleCloseArchiveJob}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					  >
						<DialogTitle id="alert-dialog-title">
						  {"Are you sure you want to archive this project?"}
						</DialogTitle>
						<DialogContent>
						  <DialogContentText id="alert-dialog-description">
							{`Are you sure you want to archived project named '${archiveJob?.name}'`}.
						  </DialogContentText>
						</DialogContent>
						<DialogActions>
						  <button className="cancel" onClick={handleCloseArchiveJob}>Cancel</button>
						  <button className="confirm" onClick={handleArchiveJob}>Yes, archive project</button>
						</DialogActions>
					</Dialog>

					{/* RESTORE JOB */}
					<Dialog
						open={openRestoreJob}
						onClose={handleCloseRestoreJob}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					  >
						<DialogTitle id="alert-dialog-title">
						  {"Are you sure you want to restore this project?"}
						</DialogTitle>
						<DialogContent>
						  <DialogContentText id="alert-dialog-description">
							{`Are you sure you want to restore project named '${restoreJob?.name}'`}.
						  </DialogContentText>
						</DialogContent>
						<DialogActions>
						  <button className="cancel" onClick={handleCloseRestoreJob}>Cancel</button>
						  <button className="confirm" onClick={handleRestoreJob}>Yes, restore project</button>
						</DialogActions>
					</Dialog>

					{/* SHARE JOB */}
					<Dialog
						open={openShare}
						onClose={handleCloseShare}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					  >
						<DialogTitle id="alert-dialog-title">
						  {`Sharing project '${shareName}'`}
						</DialogTitle>
						<DialogContent>
						  <DialogContentText id="alert-dialog-description">
							<>
							{"Select the contractors you whould like to send this project to."}
							<FormGroup className="contractors_share_list">
							{favorites?.length ?
								favorites.map((favorite, i) =>
								  <FormControlLabel key={i} control={<Checkbox onChange={(e) => handleShareChange(e, favorite.contractor_id)}/>} label={favorite?.contractor_name} />
								)
								:
								<p>You have not added any favorite contractors. Try Searching for contractors in your area and favorite them or share from a contractor page.</p>
							}
							</FormGroup>
							</>
						  </DialogContentText>
						</DialogContent>
						<DialogActions>
						  <button className="cancel" onClick={handleCloseShare}>Cancel</button>
						  {favorites?.length ?
						  	<button className="confirm" onClick={(e) => handleShareJob(shareID)}>Yes, share project</button>
							:
						  	<button className="confirm" onClick={gotoSearch}>Search</button>
					  	  }
						</DialogActions>
					</Dialog>

					{/* EDIT JOB */}
					<Dialog
						open={openEdit}
						onClose={handleCloseEdit}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
						id="editJobPopup"
					  >
						<DialogTitle id="alert-dialog-title">
						  {`Editing project '${editName}'`}
						</DialogTitle>
						<DialogContent>
						  <DialogContentText id="alert-dialog-description">
							<form encType="multipart/form-data" >

								<p>Please provide as much information as possible about your potential project including any photos. Once you have created a project, you will be able to share it with any Home Pros of your choice in order to request an estimate.</p>

								{/* Name */}
								<label htmlFor="name">Project Name:</label>
								<div className="inputWrapper">
									<span className={validEditName ? "valid" : "hide"}><FaCheck /></span>
									<span className={validEditName || !editName ? "hide" : "invalid"}><FaExclamation /></span>
									<input
										type="text"
										id="name"
										className={validEditName || !editName ? null : "error"}
										ref={nameRef}
										autoComplete="off"
										onChange={(e) => setEditName(e.target.value)}
										value={editName}
										aria-invalid={validEditName ? "false" : "true"}
										aria-describedby="namenote"
										onFocus={() => setEditNameFocus(true)}
										onBlur={() => setEditNameFocus(false)}
									/>
								</div>
								<p id="namenote" className={editNameFocus && editName && !validEditName ? "instructions" : "offscreen"}>
									Invalid Project Name. 1-50 characters (letters, numbers, dash, underscore, &, @ allowed)
								</p>

								{/* Address */}
								<label htmlFor="address">Address: <span>(Optional)</span></label>
								<div className="inputWrapper">
									<span className={validEditAddress ? "valid" : "hide"}><FaCheck /></span>
									<span className={validEditAddress || !editAddress ? "hide" : "invalid"}><FaExclamation /></span>
									<input
										type="text"
										id="address"
										className={validEditAddress || !editAddress ? null : "error"}
										ref={addressRef}
										autoComplete="off"
										onChange={(e) => setEditAddress(e.target.value)}
										value={editAddress}
										required
										aria-invalid={validEditAddress ? "false" : "true"}
										aria-describedby="addressnote"
										onFocus={() => setEditAddressFocus(true)}
										onBlur={() => setEditAddressFocus(false)}
									/>
								</div>
								<p id="addressnote" className={editAddressFocus && editAddress && !validEditAddress ? "instructions" : "offscreen"}>
									Invalid Address. Valid Format includes letters, numbers and special characters (-_/:#.&*()',).
								</p>

								{/* Service */}
								<label htmlFor="Service">Service Category:</label>
								<p>{editService ? editService : "not defined"} <MdOutlineEdit onClick={(e) => editCategory(e)}/></p>

								{ editingCategory &&
									<>
									<label htmlFor="category">Find a Service Category:</label>
									<div className="servicesContainer">

										<div className="serviceContent">
											<div className="serviceSearchWrapper">
												<input onChange={(e) => setSearchServices(e.target.value)} id="type" type="text" autoComplete="off" placeholder="Start typing to search for service categories" />
											</div>
										</div>

										{ searchServices &&
											<div className="serviceContainer">
												<div className="serviceWrapper">
												{searchServicesResults?.length ?
													<>
														<h4>Select a Categories</h4>
														<ul className="marketingCategories">
														{searchServicesResults?.map((service, i) =>
															<li className={selectedServices.includes(service?.category_name) ? 'active' : null} key={i} onClick={(e) => toggleService(e, i, service?.category_name)}>
																<div className="category">
																	{service?.category_name}
																</div>
															</li>
														)}
														</ul>
													</>
												:
													<p>No Categories available</p>
												}
												</div>
											</div>
										}
									</div>
									</>
								}

								{/* Description */}
								<label htmlFor="description">Description:</label>
								<div className="inputWrapper">
									<span className={validEditDescription ? "valid" : "hide"}><FaCheck /></span>
									<span className={validEditDescription || !validEditName ? "hide" : "invalid"}><FaExclamation /></span>
									<textarea
										ref={descriptionRef}
										onChange={(e) => setEditDescription(e.target.value)}
										name="description"
										id="description"
										rows="5"
										value={editDescription}
									>
									</textarea>
									<p id="descriptionnote" className={editDescriptionFocus && editDescription && !validEditDescription ? "instructions" : "offscreen"}>
										Invalid Description. Between 1-500 characters (letters, numbers, dash, underscore, & allowed)
									</p>
								</div>

								{/* Images */}
								<label htmlFor="images">Images {`(${editImages?.length} of 10)`}:</label>
								<div className="inputWrapper">
									{editImages ?
									(
										<ul className="jobImageList">
											{editImages.map((image, i) =>
												<li key={i} onClick={(e) => handleDeleteImage(e, i, image?.id)}>
													<img src={`https://storage.iyh.app/${image.path}`} alt="" />
												</li>
											)}
										</ul>
									)
									: <p>No images uploaded</p>}
									{fileLimit - editImages?.length > 0 &&
										<FileDropEmbed showFiles="true" autoUpload="false" limit={fileLimit - editImages?.length} multiple="true" files={files} setFiles={setFiles} validFiles={validFiles} setValidFiles={setValidFiles} />
									}
								</div>
								<p>Valid Description: {validEditDescription ? "true" : "false"}</p>
								<p>Valid Name: {validEditName ? "true" : "false"}</p>
								<p>Valid Files: {validFiles ? "true" : "false"}</p>
							</form>
						  </DialogContentText>
						</DialogContent>
						<DialogActions>
						  <button className="cancel" onClick={handleCloseEdit}>Cancel</button>
						  <button disabled={!validEditDescription || !validEditName  || !validFiles ? true : false} className="confirm" onClick={handleEditSave}>Save Project</button>
						</DialogActions>
					</Dialog>

					{/* Create JOB */}
					<Dialog
						open={openCreateJob}
						onClose={handleCloseCreateJob}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					  >
						<DialogTitle id="alert-dialog-title">

						</DialogTitle>
						<DialogContent>
						  <DialogContentText id="alert-dialog-description">
							<CreateUserJob addJob={addJob}/>
						  </DialogContentText>
						</DialogContent>
						<DialogActions>
						  <button className="cancel" onClick={handleCloseCreateJob}>Cancel</button>
						</DialogActions>
					</Dialog>


			<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
			/>
		</div>
	)
}

export default UserJobs