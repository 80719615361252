import { useRef, useState, useEffect } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { AiFillDelete } from 'react-icons/ai';
import { FcImageFile } from 'react-icons/fc';
import { FcVideoFile } from 'react-icons/fc';
import { FcFile } from 'react-icons/fc';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { ToastContainer, toast } from 'react-toastify';
import LinearProgress from '@mui/material/LinearProgress';
import { BsExclamationTriangleFill } from 'react-icons/bs'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const ImageConfig = {
	default: <FcVideoFile />,
	mp4: <FcVideoFile />,
	mov: <FcVideoFile />,
	avi: <FcVideoFile />,
	mkv: <FcVideoFile />,
	m4v: <FcVideoFile />,
}

const VideoDropEmbed = ({ showFiles, autoUpload, limit, multiple, accept, buttonTitle, videos, setVideos, totalVideoCount }) => {
	videos = videos.slice(0, limit);
	const axiosPrivate = useAxiosPrivate();

	const dropRef = useRef();

	const [uploadComplete, setUploadComplete] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(0);
	const [fileUploadCount, setFileUploadCount] = useState(1);

	const [showFilePreview, setShowFilePreview] = useState(false)

	const [fileTypeError, setFileTypeError] = useState(false)

	const onDragEnter = () => dropRef.current.classList.add('dragover');

	const onDragLeave = () => dropRef.current.classList.remove('dragover');

	const onDrop = () => dropRef.current.classList.remove('dragover');

	useEffect(() => {
		if (uploadProgress == 100 && fileUploadCount <= videos.length){
			setFileUploadCount(fileUploadCount + 1);
		}
	}, [uploadProgress])

	const onvideosDrop = (e) => {
		// Reset error
		setFileTypeError(false)

		// Get files
		const newvideos = e.target.files
		let clean = [...newvideos]
		const acceptedFileType = accept.replace('/*', '')

		const showSwal = (filename) => {
			withReactContent(Swal).fire({
				title: <i>File Over Size Limit!</i>,
				icon: "warning",
				confirmButtonColor: "#3c6d8d",
				html: 'Your file ' + filename + ' has not been added.<br><span style="font-size:0.8rem;color:red;">(Maximum size of 20MB per file is allowed)</span>'
			})
		}

		const showSwalFileType = (filename) => {
			withReactContent(Swal).fire({
				title: <i>Wrong File Type!</i>,
				icon: "warning",
				confirmButtonColor: "#3c6d8d",
				html: 'Your file ' + filename + ' has to be a video (mp4) file. The file has not been added.'
			})
		}

		// Check if the file is valid, if not remove it from the array
		var i
		for(i=0; i < clean?.length; i++){
			let fileType = clean[i].type
			if (!fileType.includes(acceptedFileType)){
				// Remove invalid file from array
				console.log(`invalid file at index ${i} - will be removed`)
				showSwalFileType(clean[i].name)
				clean = clean.filter((file, fileIndex) => fileIndex !== i)
				setFileTypeError(true)
			}
			if (clean[i].size > 19990000) {
				showSwal(clean[i].name)
				clean = clean.filter((file, fileIndex) => fileIndex !== i)
			}
		}

		//console.log(newvideos)
		const videosArray = [...videos, ...clean]
		setVideos(videosArray)
		setUploadComplete(false)
	}

	const fileRemove = (file) => {
		const updatedFiles = [...videos];
		updatedFiles.splice(videos.indexOf(file), 1);
		setVideos(updatedFiles);
	}

	const toggleFiles = () => {

	}

	function formatBytes(bytes, decimals = 2) {
		if (bytes === 0) return '0 Bytes';

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}



	return (
		<>
			<div className="drop-file-container"
				ref={dropRef}
				className="drop-file-input embed"
				onDragEnter={onDragEnter}
				onDragLeave={onDragLeave}
				onDrop={onDrop}
			>
				<div className="drop-file-input__label">
					<AiOutlineCloudUpload />
					<p>Drag & Drop your {limit ?
						limit >= 1 && multiple == "true" ?
							`videos (max ${limit}) `
							:
							"file "
								: "files "}
						here
					</p>
				</div>
				{ multiple == "true" ?
				<input type="file" id="files" accept={accept} value="" multiple onChange={onvideosDrop}/>
				:
				<input type="file" id="files" accept={accept} value="" onChange={onvideosDrop}/>
				}
			</div>
			{
				videos?.length > 0 && showFiles == "true" ? (
					<div className="drop-file-preview">
						<p className="drop-file-preview__title">
							{totalVideoCount < limit && <>{videos?.length} files ready to upload <span onClick={() => setShowFilePreview(!showFilePreview)}>{showFilePreview ? `Hide` : `Show` } Files</span></>}
							{totalVideoCount == limit && "Ready to upload. File Limit Reached!"}
							{totalVideoCount > limit && `File Limit Exceded! We deleted ${totalVideoCount - limit} files.`}
							{fileTypeError ? <span className="fileTypeError"><BsExclamationTriangleFill /> Some files removed due to wrong file type. Only Mp4 accepted.</span> : ''}
						</p>

						{
							videos?.map((file, index) => (
								<div key={index} className={`drop-file-preview__item ${showFilePreview ? "" : "hide"}`}>
									{ImageConfig[file?.type.split('/')[1]] || ImageConfig['default']}
									<div className="drop-file-preview__item__info">
										<p>{file?.name}</p>
										<p>{formatBytes(file?.size)}</p>
									</div>
									<span className="drop-file-preview__item__del" onClick={() => fileRemove(file)}><AiFillDelete /></span>
								</div>

							))
						}
					</div>
				) : (
					<p>
						{videos?.length < limit && videos?.length > 0 && `${videos?.length} file(s) ready to upload`}
					</p>
				)
			}

		</>
	)
}


export default VideoDropEmbed