import { useState, useEffect, useRef } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import AdminMenu from '../../components/AdminMenu';
import LegalPageEdit from '../../components/LegalPageEdit';
import { GoLaw } from 'react-icons/go';
import { MdOutlineEdit } from 'react-icons/md';
import { MdClose } from 'react-icons/md';
import Advertising from '../legal/Advertising'
import Conditions from '../legal/Conditions'
import Conduct from '../legal/Conduct'
import Membership from '../legal/Membership'
import Privacy from '../legal/Privacy'
import Project from '../legal/Project'
import Sell from '../legal/Sell'
import Service from '../legal/Service'
import Terms from '../legal/Terms'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const AdminLegal = () => {

	const [editSlug, setEditSlug] = useState();
	const [editName, setEditName] = useState();
	const [openEdit, setOpenEdit] = useState(false);

	const editLegalDoc = (slug, name) => {
		setEditSlug(slug)
		setEditName(name)
		setOpenEdit(true)
	}

	const handleClose = () => {
		setOpenEdit(false)
	}
	const handleSaveDoc = () => {
		console.log("saving legal doc")
	}

	return (
		<>
		<section>
			<article className="inner">
				<div className="backendTitleBar">
					<h1>Legal</h1>
					<GoLaw />
				</div>
				<div className="backendColumnWrapper">
					<div id="menu" className="column">
						<AdminMenu />
					</div>
					<div id="content" className="column">
						<ul className="legalDocs">
							<li>
								IYH Terms of Use
								<button className="edit" onClick={(e) => editLegalDoc("terms-of-use", "IYH Terms of Use")}>
									<MdOutlineEdit />
								</button>
							</li>
							<li>
								IYH Membership Agreement
								<button className="edit" onClick={(e) => editLegalDoc("membership-agreement", "IYH Membership Agreement")}>
									<MdOutlineEdit />
								</button>
							</li>
							<li>
								IYH Project Submission Customer Agreement
								<button className="edit" onClick={(e) => editLegalDoc("project-submission-customer-agreement", "IYH Project Submission Customer Agreement")}>
									<MdOutlineEdit />
								</button>
							</li>
							<li>
								IYH Service Provider's User Agreement
								<button className="edit" onClick={(e) => editLegalDoc("service-providers-user-agreement", "IYH Service Provider's User Agreement")}>
									<MdOutlineEdit />
								</button>
							</li>
							<li>
								IYH Advertising Agreement
								<button className="edit" onClick={(e) => editLegalDoc("advertising-agreement", "IYH Advertising Agreement")}>
									<MdOutlineEdit />
								</button>
							</li>
							<li>
								IYH Code of Conduct
								<button className="edit" onClick={(e) => editLegalDoc("code-of-conduct", "IYH Code of Conduct")}>
									<MdOutlineEdit />
								</button>
							</li>
							<li>
								IYH Privacy Policy
								<button className="edit" onClick={(e) => editLegalDoc("privacy-policy", "IYH Privacy Policy")}>
									<MdOutlineEdit />
								</button>
							</li>
							<li>
								IYH Do Not Sell Policy
								<button className="edit" onClick={(e) => editLegalDoc("do-not-sell-policy", "IYH Do Not Sell Policy")}>
									<MdOutlineEdit />
								</button>
							</li>
							<li>
								IYH Mobile Alerts Terms and Conditions
								<button className="edit" onClick={(e) => editLegalDoc("mobile-alerts-terms-and-conditions", "IYH Mobile Alerts Terms and Conditions")}>
									<MdOutlineEdit />
								</button>
							</li>
						</ul>
					</div>
				</div>
			</article>
		</section>

		{/* Add/Edit User */}
		<Dialog
			fullScreen
			open={openEdit}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			className="legalPopupEditor"
		>
			<AppBar sx={{ position: 'relative' }}>
				<Toolbar>
					<IconButton
						edge="start"
						color="inherit"
						onClick={handleClose}
						aria-label="close"
					>
						<MdClose />
					</IconButton>
					<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
						Editing {editName}
					</Typography>
					{/* <Button disabled={false} autoFocus color="inherit" onClick={(e) => handleSaveDoc(e)}>
						  save
					</Button> */}
				</Toolbar>
			</AppBar>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					<LegalPageEdit slug={editSlug} />
				</DialogContentText>
			</DialogContent>
		</Dialog>

		</>
	)
}

export default AdminLegal