import FormStepBilling from "./FormStepBilling"
import FormStepOptions from "./FormStepOptions"
import FormStepShipping from "./FormStepShipping"
import FormStepState from "./FormStepState"
import FormStepCounty from "./FormStepCounty"
import FormStepExclude from "./FormStepExclude"
import FormStepCategories from "./FormStepCategories"
import FormStepOverview from "./FormStepOverview"
import FormStepPayment from "./FormStepPayment"
import useFormContext from "../hooks/useFormContext"
import useAdminFormContext from "../hooks/useAdminFormContext"
import AdminFormStepState from "./AdminFormStepState"
import AdminFormStepCounty from "./AdminFormStepCounty"
import AdminFormStepExclude from "./AdminFormStepExclude"
import AdminFormStepCategories from "./AdminFormStepCategories"
import AdminFormStepOptions from "./AdminFormStepOptions"
import AdminFormStepOverview from "./AdminFormStepOverview"
import AdminFormStepPayment from "./AdminFormStepPayment"

const AdminFormInputs = () => {

	const { page } = useAdminFormContext()

	const display = {
		0: <AdminFormStepState />,
		1: <AdminFormStepCounty />,
		2: <AdminFormStepExclude />,
		3: <AdminFormStepCategories />,
		4: <AdminFormStepOptions />,
		5: <AdminFormStepOverview />,
		6: <AdminFormStepPayment />,
	}

	const content = (
		<div className="form-inputs">
			{display[page]}
		</div>
	)


	return content
}
export default AdminFormInputs