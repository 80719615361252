import { useRef, useState, useEffect } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { AiFillDelete } from 'react-icons/ai';
import { FcImageFile } from 'react-icons/fc';
import { FcVideoFile } from 'react-icons/fc';
import { FcFile } from 'react-icons/fc';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { ToastContainer, toast } from 'react-toastify';
import LinearProgress from '@mui/material/LinearProgress';
import { BsExclamationTriangleFill } from 'react-icons/bs'

const ImageConfig = {
	default: <FcFile />,
	png: <FcImageFile />,
	jpg: <FcImageFile />,
	jpeg: <FcImageFile />,
	gif: <FcImageFile />,
	mp4: <FcVideoFile />,
	mov: <FcVideoFile />,
	avi: <FcVideoFile />,
	mkv: <FcVideoFile />,
	m4v: <FcVideoFile />,
}

const FileDropEmbed = ({ accept, buttonTitle, files, setFiles }) => {

	const axiosPrivate = useAxiosPrivate();

	const dropRef = useRef();

	const [uploadComplete, setUploadComplete] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(0);
	const [fileUploadCount, setFileUploadCount] = useState(1);

	const [showFilePreview, setShowFilePreview] = useState(false)

	const [fileTypeError, setFileTypeError] = useState(false)

	const onDragEnter = () => dropRef.current.classList.add('dragover');

	const onDragLeave = () => dropRef.current.classList.remove('dragover');

	const onDrop = () => dropRef.current.classList.remove('dragover');

	useEffect(() => {
		if (uploadProgress == 100 && fileUploadCount <= files.length){
			setFileUploadCount(fileUploadCount + 1);
		}
	}, [uploadProgress])

	const onFileDrop = (e) => {
		// Reset error
		setFileTypeError(false)
		console.log('new file added')
		// Get files
		const newFiles = e.target.files;
		let clean = [...newFiles]
		const acceptedFileType = accept.replace('/*', '')

		// Check if the file is valid, if not remove it from the array
		var i
		for(i=0; i < clean?.length; i++){
			let fileType = clean[i].type
			if (!fileType.includes(acceptedFileType)){
				// Remove invalid file from array
				console.log(`invalid file at index ${i} - will be removed`)
				clean = clean.filter((file, fileIndex) => fileIndex !== i)
				setFileTypeError(true)
			}
		}

		const filesArray = [...files, ...clean]
		setFiles(filesArray)
		setUploadComplete(false)
	}

	const fileRemove = (file) => {
		const updatedFiles = [...files];
		updatedFiles.splice(files.indexOf(file), 1);
		setFiles(updatedFiles);
	}

	const toggleFiles = () => {

	}

	function formatBytes(bytes, decimals = 2) {
		if (bytes === 0) return '0 Bytes';

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}



	return (
		<>
			<div className="drop-file-container"
				ref={dropRef}
				className="drop-file-input single"
				onDragEnter={onDragEnter}
				onDragLeave={onDragLeave}
				onDrop={onDrop}
			>
				<div className="drop-file-input__label">
					<AiOutlineCloudUpload />
					{buttonTitle ? <p>{buttonTitle}</p> : <p>Drag & Drop your image here</p>}
				</div>
				<input type="file" id="files" accept={accept} value="" onChange={onFileDrop}/>
			</div>
			{files?.length > 0 && !fileTypeError && `File ready to upload`}
			{fileTypeError ? <span className="fileTypeError"><BsExclamationTriangleFill /> File removed due to wrong file type.</span> : ''}

		</>
	)
}


export default FileDropEmbed