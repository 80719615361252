import FormStepBilling from "./FormStepBilling"
import FormStepOptions from "./FormStepOptions"
import FormStepShipping from "./FormStepShipping"
import FormStepState from "./FormStepState"
import FormStepCounty from "./FormStepCounty"
import FormStepExclude from "./FormStepExclude"
import FormStepCategories from "./FormStepCategories"
import FormStepOverview from "./FormStepOverview"
import FormStepPayment from "./FormStepPayment"
import useFormContext from "../hooks/useFormContext"

const FormInputs = () => {

	const { page } = useFormContext()

	const display = {
		0: <FormStepState />,
		1: <FormStepCounty />,
		2: <FormStepExclude />,
		3: <FormStepCategories />,
		4: <FormStepOptions />,
		5: <FormStepOverview />,
		6: <FormStepPayment />,
	}

	const content = (
		<div className="form-inputs">
			{display[page]}
		</div>
	)


	return content
}
export default FormInputs