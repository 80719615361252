import { useState, useEffect, useRef } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import { FaCheck } from 'react-icons/fa';
import { FaExclamation } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import { AiOutlineShareAlt } from 'react-icons/ai';
import { MdOutlineEdit } from 'react-icons/md';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import CreateUserPromotion from "./CreateUserPromotion";
import Loader from './Loader';
import SingleFileDropEmbed from '../components/SingleFileDropEmbed';

const NAME_REGEX = /^[a-zA-Z0-9-_!?%'#$,:;]{1,50}$/;
const DETAILS_REGEX = /^[a-zA-Z0-9--_!?%'#$,:;]{1,500}$/;

const UserPromotions = () => {

	const [loading, setLoading] = useState(false);
	const [promotions, setPromotions] = useState();
	const axiosPrivate = useAxiosPrivate();

	const navigate = useNavigate();
	const location = useLocation();

	const auth = useAuth();
	const role = auth?.role;
	const id = auth?.id;

	const nameRef = useRef();
	const detailsRef = useRef();

	const [deleteName, setDeleteName] = useState();
	const [deleteID, setDeleteID] = useState();
	const [open, setOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);
	const [editID, setEditID] = useState();
	const [editName, setEditName] = useState();
	const [editDetails, setEditDetails] = useState();
	const [editStatus, setEditStatus] = useState();
	const [editImage, setEditImage] = useState();
	const [editStatusFocus, setEditStatusFocus] = useState(false);

	const [name, setName] = useState("");
	const [validEditName, setValidEditName] = useState(true);
	const [nameFocus, setNameFocus] = useState(false);

	const [details, setDetails] = useState("");
	const [validEditDetails, setValidEditDetails] = useState(true);
	const [detailsFocus, setDetailsFocus] = useState(false);

	const [files, setFiles] = useState([]);
	const [deleteImages, setDeleteImages] = useState();
	const [editImages, setEditImages] = useState();
	const [validFiles, setValidFiles] = useState(false);
	const [fileLimit, setFileLimit] = useState(1);
	const [buttonTitle, setButtonTitle] = useState("Promotion Image");


	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleEditClickOpen = () => {
		setEditOpen(true);
	};

	const handleEditClose = () => {
		setEditOpen(false);
		setFiles([])
		setDeleteImages()
	};

	const handleDelete = async () => {
		const dismiss = () =>  toast.dismiss(deletePromotionToast.current);
		const deletePromotionToast = toast.loading("Deleting Promotion");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.delete('promotion/' + deleteID,
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(deletePromotionToast, { render: 'Promotion Deleted', type: 'success', isLoading: false, autoClose: 5000});

			// Close Alert Window
			setOpen(false);

			// Delete job from UI
			setPromotions(promotions.filter((promotion) => promotion.id !== deleteID))

		} catch (err) {
			if (!err?.response) {
				toast.update(deletePromotionToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(deletePromotionToast, { render: 'Promotion Delete Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	};

	const handleEditSave = async () => {
		const dismiss = () =>  toast.dismiss(updatePromotionToast.current);
		const updatePromotionToast = toast.loading("Updating Promotion");
		const controller = new AbortController();

		const formData = new FormData();
		files.map((file, i) => {
			formData.append('files[]', file);
		});

		console.log(deleteImages)
		formData.append('name', editName)
		formData.append('details', editDetails)
		formData.append('status', editStatus)
		formData.append('deleteImagePath', deleteImages)

		// Key to making put work with formdata
		formData.append('_method', 'PUT');

		try {
			const response = await axiosPrivate.post('promotion/' + editID,
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				signal: controller.signal
			});

			const updatedImage = response.data.promotions.image

			// Update promotions in state
			const index = promotions.map(function(x) {return x.id; }).indexOf(editID);
			promotions[index].name = editName;
			promotions[index].details = editDetails;
			promotions[index].status = editStatus;
			promotions[index].image = updatedImage;

			// Clear data
			setEditOpen(false);
			setFiles([])
			setDeleteImages()

			// Update Toast Notification
			toast.update(updatePromotionToast, { render: 'Promotion Updated', type: 'success', isLoading: false, autoClose: 5000});
		} catch (err) {
			if (!err?.response) {
				toast.update(updatePromotionToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else if (err.response?.status === 401) {
				toast.update(updatePromotionToast, { render: 'Authorization Failed', type: 'error', isLoading: false});
				{/* setErrMsg('The email has already been taken.'); */}
			} else {
				toast.update(updatePromotionToast, { render: 'Promotion Update Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getPromotions = async () => {
			try {
				const response = await axiosPrivate.get('user-promotions', {
					signal: controller.signal
				});
				console.log(response.data);
				setPromotions(response.data);
				setLoading(false)
			} catch(err) {
				console.log(err.message);
				{/* !auth && navigate('/login', { state: { from: location }, replace: true }); */}
			}
		}

		getPromotions();

		return () => controller.abort();

	},[])

	/* Check Name */
	useEffect(() => {
		const result = NAME_REGEX.test(editName);
		setValidEditName(result);
	}, [editName])

	/* Check Details */
	useEffect(() => {
		const result = DETAILS_REGEX.test(editDetails);
		setValidEditDetails(result);
	}, [editDetails])

	const deletePromotion = (e, i, name, id) => {
		setOpen(true);
		setDeleteName(name);
		setDeleteID(id);
	}

	const editPromotion = (e, i, name, id, details, status, image) => {
		setEditOpen(true);
		setEditName(name)
		setEditID(id)
		setEditDetails(details)
		setEditStatus(status)
		setEditImage(image)
	}

	const handleDeleteImage = async (e, path) => {
		// We won't delete images until the save button is clicked so lets store the ids of images to delete if save is clicked
		setDeleteImages(path)

		// Delete Image from UI
		setEditImage()
	}

	const addPromotion = (promotion) => {
		console.log(promotion);
		setPromotions([...promotions, promotion]);
	}

	const formatDate = (dateString) => {
	  const options = { month: "long", day: "numeric", year: "numeric" }
	  return new Date(dateString).toLocaleDateString(undefined, options)
	}

	/* Check Name */
	useEffect(() => {
		const result = NAME_REGEX.test(editName);
		setValidEditName(result);
	}, [editName])

	/* Check Description */
	useEffect(() => {
		const result = DETAILS_REGEX.test(editDetails);
		setValidEditDetails(result);
	}, [editDetails])

	useEffect(() => {
		const currentCount = files?.length;
		const addCount = editImages?.length;
		const count = currentCount + addCount;

		if (fileLimit){
			count <= fileLimit ? setValidFiles(true) : setValidFiles(false);
		}

		const formData = new FormData();
		console.log(files)
		files?.map((file, index) => {
			formData.append('files[]', file);
		});

	}, [files])

	return (
		<div className="backendList">
			{promotions?.length
				? (
					<>
					<ul className="promotionsList">
						<li className="key">
							<div className="name">Name</div>
							<div className="statusContainer">Status</div>
							<div className="date">Date Created</div>
							<div className="buttons"></div>
						</li>
						{promotions.map((promotion, i) =>
							<li key={i}>
								<div className="name">
									{promotion?.name}
								</div>
								<div className="statusContainer">
									<div className={`status ${promotion?.status}`}>
										{promotion?.status}
									</div>
								</div>
								<div className="date">
									{formatDate(promotion?.created_at)}
								</div>
								<div className="buttons">
									<div className="edit" onClick={(e) => editPromotion(e, i, promotion?.name, promotion?.id, promotion?.details, promotion?.status, promotion?.image)}>
										<span><MdOutlineEdit /></span>
									</div>
									<div className="delete" onClick={(e) => deletePromotion(e, i, promotion?.name, promotion?.id)}>
										<span><AiFillDelete /></span>
									</div>
								</div>
							</li>
						)}
					</ul>
					{/* Delete Dialog */}
					<Dialog
						open={open}
						onClose={handleClose}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					  >
						<DialogTitle id="alert-dialog-title">
						  {"Are you sure you want to delete this promotion?"}
						</DialogTitle>
						<DialogContent>
						  <DialogContentText id="alert-dialog-description">
							This action cannot be undone. This will permanently delete the promotion with name, "{deleteName}".
						  </DialogContentText>
						</DialogContent>
						<DialogActions>
						  <button className="cancel" onClick={handleClose}>Cancel</button>
						  <button className="confirm" onClick={handleDelete}>Yes, delete promotion</button>
						</DialogActions>
					</Dialog>

					{/* Edit Dialog */}
					<Dialog
						open={editOpen}
						onClose={handleEditClose}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
						id="promotionsEditPopup"
					  >
						<DialogTitle id="alert-dialog-title">
						  {"Editing Promotion"}
						</DialogTitle>
						<DialogContent>
						  <DialogContentText id="alert-dialog-description">
							<form encType="multipart/form-data">
								{/* Status */}
								<label htmlFor="status">Status:</label>
								<div className="inputWrapper">
									<select
										id="status"
										onChange={(e) => setEditStatus(e.target.value)}
										value={editStatus}
										required
										onFocus={() => setEditStatusFocus(true)}
										onBlur={() => setEditStatusFocus(false)}
									>
										<option value="draft">Draft</option>
										<option value="active">Active</option>
										<option value="archived">Archived</option>
									</select>
								</div>

								{/* Name */}
								<label htmlFor="name">Promotion Name:</label>
								<div className="inputWrapper">
									<span className={validEditName ? "valid" : "hide"}><FaCheck /></span>
									<span className={validEditName || !editName ? "hide" : "invalid"}><FaExclamation /></span>
									<input
										type="text"
										id="name"
										className={validEditName || !editName ? null : "error"}
										ref={nameRef}
										autoComplete="off"
										onChange={(e) => setEditName(e.target.value)}
										value={editName}
										aria-invalid={validEditName ? "false" : "true"}
										aria-describedby="namenote"
										onFocus={() => setNameFocus(true)}
										onBlur={() => setNameFocus(false)}
									/>
								</div>
								<p id="namenote" className={nameFocus && editName && !validEditName ? "instructions" : "offscreen"}>
									Invalid Promotion Name. 1-50 characters (letters, numbers, and special characters (-_.!?%'#$,:;) allowed)
								</p>

								{/* Description */}
								<label htmlFor="details">Details:</label>
								<div className="inputWrapper">
									<span className={validEditDetails ? "valid" : "hide"}><FaCheck /></span>
									<span className={validEditDetails || !editDetails ? "hide" : "invalid"}><FaExclamation /></span>
									<textarea
										ref={detailsRef}
										onChange={(e) => setEditDetails(e.target.value)}
										name="details"
										id="details"
										rows="5"
										value={editDetails}
									>
									</textarea>
									<p id="descriptionnote" className={detailsFocus && editDetails && !validEditDetails ? "instructions" : "offscreen"}>
										Invalid Description. Between 1-500 characters (letters, numbers, and special characters (-_.!?%'#$,:;) allowed)
									</p>
								</div>
								{/* Promotional Image */}
								<label htmlFor="image">Promotional Image:</label>
								<div className={`promotionalImageWrapper ${!editImage && 'noImage'}`} onClick={(e) => handleDeleteImage(e, editImage)}>
									{ editImage ?
									<img src={`https://storage.iyh.app/${editImage}`} alt={`Promotional Image`} />
									:
									"No image selected"
									}
								</div>

								{/* File Uploader */}
								<SingleFileDropEmbed buttonTitle={buttonTitle} files={files} setFiles={setFiles} validFiles={validFiles} setValidFiles={setValidFiles} accept="image/*" />
							</form>
						  </DialogContentText>
						</DialogContent>
						<DialogActions>
						  <button className="cancel" onClick={handleEditClose}>Cancel</button>
						  <button className="confirm" disabled={!validEditName || !validEditDetails ? true : false} onClick={handleEditSave} >Save</button>
						</DialogActions>
					</Dialog>
		</>
				) :
					loading ?
					<Loader />
					:
					<p>You have not created any promotions yet.</p>
			}
			<CreateUserPromotion addPromotion={addPromotion}/>
		</div>
	)
}

export default UserPromotions