import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { BsArrowRightShort, BsArrowLeftShort } from "react-icons/bs";
import Slider from "react-slick";
import styled from "styled-components";

const StyledContainer = styled(Container)`
  .something {
    & > div {
      height: ${({ cssCardHeight }) =>
        cssCardHeight ? `${cssCardHeight}px` : "unset"};
    }
  }
`;

const TestimonialsBlock = ({ header, subhead, testimonialArr }) => {
  const [width, setWidth] = useState(0);
  const [cardHeight, setCardHeight] = useState(0);
  const [isBreakpointSmall, setBreakpoint] = useState(false);
  const cardRefs = useRef();
  if (!cardRefs.current) {
    cardRefs.current = [];
  }

  // useEffect(() => {
  //   const handleResize = () => {
  //     setWidth(window.innerWidth);
  //   };
  //   handleResize();
  //   setTimeout(() => {
  //     handleResize();
  //   }, 1000);
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  // useEffect(() => {
  //   console.log("cardHeight", cardHeight);
  // }, [cardHeight]);

  useEffect(() => {
    setBreakpoint(width < 767);
    if (!width) {
    }
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });

    if (width !== window.innerWidth) {
      setWidth(window.innerWidth);
    }

    return () => {
      window.removeEventListener("resize", () => {
        setWidth(window.innerWidth);
      });
    };
  }, [width, cardHeight, isBreakpointSmall]);

  useEffect(() => {
    const getCardHeight = () => {
      let height = 0;
      cardRefs.current.forEach((item) => {
        const elHeight = item.clientHeight;
        if (elHeight > height) {
          height = elHeight;
          if (elHeight > cardHeight){
            setCardHeight(elHeight)
          } else {
            setCardHeight(0)
          }
          //elHeight > cardHeight ? setCardHeight(elHeight) : null;
        }
      });
    };
    getCardHeight();
    setCardHeight(0);
    setTimeout(() => {
      getCardHeight();
    }, 100);
  }, [width]);

  const addToRefs = (el) => {
    if (el && !cardRefs.current.includes(el)) {
      cardRefs.current.push(el);
    }
  };

  // useEffect(() => {
  //   console.log("cardHeight", cardHeight);
  // }, [cardHeight]);

  const testimonialList = [
    {
      copy: '“I have found ImproveYourHouse Magazine to be the best print source in the Denver area for high quality, new business leads. Greg and his staff are a pleasure to work with, and they respond very quickly to my questions and changes."',
      testimonyGiver: "Mike, Garage Storage Solutions",
    },
    {
      copy: "“ImproveYourHouse Magazine has definitely helped me reach some customers that I would not of reached in the past. The demographics that this magazine hits has been good for super sales.”",
      testimonyGiver: "Paul, Innovative Painting",
    },
    {
      copy: "“Our business relies heavily on the lead traffic created by the ImproveYourHouse Magazine. We have partnered with Greg and ImproveYourHouse for 3 years now and highly recommend them. This is a quality product that generates quality leads.”",
      testimonyGiver: "Kathleen, Denver Doors & Closets",
    },
    {
      copy: '"Lorem Ipsum',
      testimonyGiver: "Lorem Ipsum",
    },
  ];

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={`${className} arrow-right`} style={{ ...style }}>
        <BsArrowRightShort className="ar-right" onClick={onClick} size="40px" />
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={`${className} arrow-right`} style={{ ...style }}>
        <BsArrowLeftShort onClick={onClick} size="40px" className="ar-left" />
      </div>
    );
  };

  const settings = {
    arrows: true,
    className: "slider",
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: width > 1000 ? 3 : width > 700 ? 2 : 3,
    swipeToSlide: true,
    vertical: width > 700 ? false : true,
    verticalSwiping: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div
      className="testimonial-block-wrapper"
      style={{ background: "#F3F3F3" }}
    >
      <StyledContainer className="testimonial-block" cssCardHeight={cardHeight}>
        <Row>
          <Col className="testimonial-text-col">
            <h2>{header}</h2>
            {subhead && <h4>{subhead}</h4>}
          </Col>
        </Row>
        <Row style={{ paddingBottom: "4rem" }}>
          <Col>
            <Slider {...settings}>
              {testimonialArr &&
                Array.isArray(testimonialArr) &&
                testimonialArr.map((testimony) => {
                  return (
                    <div className="something">
                      <Card className="testimonial-card" ref={addToRefs}>
                        <Row>
                          <Col>
                            <p>{testimony.copy}</p>
                            {testimony?.testimonyGiver && (
                              <p>{testimony.testimonyGiver}</p>
                            )}
                          </Col>
                        </Row>
                      </Card>
                    </div>
                  );
                })}
            </Slider>
          </Col>
        </Row>
      </StyledContainer>
    </div>
  );
};

export default TestimonialsBlock;
