import { useState, useEffect } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import { AiFillDelete } from 'react-icons/ai';
import { AiOutlineShareAlt } from 'react-icons/ai';
import { MdOutlineEdit } from 'react-icons/md';
import { MdVisibility } from 'react-icons/md';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import Loader from './Loader';
import Rating from '@mui/material/Rating';


const ContractorReviews = () => {

	const [loading, setLoading] = useState(false);
	const axiosPrivate = useAxiosPrivate();

	const navigate = useNavigate();
	const location = useLocation();

	const auth = useAuth();
	const role = auth?.role;
	const id = auth?.id;

	const [viewID, setViewID] = useState()
	const [reviews, setReviews] = useState();
	const [name, setName] = useState();
	const [rating, setRating] = useState();
	const [status, setStatus] = useState();
	const [commentsText, setCommentsText] = useState();
	const [reviewText, setReviewText] = useState();
	const [deleteName, setDeleteName] = useState();
	const [deleteID, setDeleteID] = useState();
	const [open, setOpen] = useState(false);
	const [subPop, setSubPop] = useState(false)
	const [subReview, setSubReview] = useState(false)
	const [subComment, setSubComment] = useState(false)
	const [subReply, setSubReply] = useState(false)
	const [subApprove, setSubApprove] = useState(false)
	const [responseText, setResponseText] = useState()
	const [canReply, setCanReply] = useState(false)

	const [viewProject, setViewProject] = useState()
	const [viewProjectID, setViewProjectID] = useState()

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setSubPop(false)
	};

	const handleCancel = () => {
		setSubPop(false)
		setSubReply(false)
		setSubReview(false)
		setSubApprove(false)
	};

	const handleReply = async () => {
		setSubPop(true)
		setSubReply(true)
	};

	const handleApprove = async () => {
		setSubPop(true)
		setSubApprove(true)
	};

	const handleReview = async () => {
		setSubPop(true)
		setSubReview(true)
	};

	// Send Review Response
	const handleSendPublicReply = async () => {
		console.log(responseText)
		const dismiss = () =>  toast.dismiss(reviewReplyProfileToast.current);
		const reviewReplyProfileToast = toast.loading("Adding Review Reply");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.put('add-review-reply/' + viewID,
			JSON.stringify({public_contractor_comment: responseText}),
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(reviewReplyProfileToast, { render: 'Review Reply Added', type: 'success', isLoading: false, autoClose: 5000});

			// Update State


			// On Success reset back to defaults
			setSubPop(true)
			setSubReply(true)
			setOpen(false)
			setResponseText("")

		} catch (err) {
			if (!err?.response) {
				toast.update(reviewReplyProfileToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(reviewReplyProfileToast, { render: 'Review Reply Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	};

	// Approve review and send response
	const handleSendApproval = async () => {
		console.log(responseText)
		const dismiss = () =>  toast.dismiss(reviewApprovalProfileToast.current);
		const reviewApprovalProfileToast = toast.loading("Approving Review");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.put('approve-review/' + viewID,
			JSON.stringify({status: 'approved', public_contractor_comment: responseText}),

			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(reviewApprovalProfileToast, { render: 'Review Approved', type: 'success', isLoading: false, autoClose: 5000});

			// Update State
			const index = reviews.map(function(x) {return x.id; }).indexOf(viewID);
			reviews[index].status = "approved"

			// On Success reset back to defaults
			setSubPop(false)
			setSubApprove(false)
			setOpen(false);
			setResponseText("")

		} catch (err) {
			if (!err?.response) {
				toast.update(reviewApprovalProfileToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(reviewApprovalProfileToast, { render: 'Approving Review Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	};

	// Send review to admin for review
	const handleSendForReview = async () => {
		console.log(commentsText)
		const dismiss = () =>  toast.dismiss(reviewReviewProfileToast.current);
		const reviewReviewProfileToast = toast.loading("Sending review for review");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.put('review-review/' + viewID,
			JSON.stringify({status: 'review', private_contractor_comment: commentsText}),
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(reviewReviewProfileToast, { render: 'Review in queue', type: 'success', isLoading: false, autoClose: 5000});

			// Update State
			const index = reviews.map(function(x) {return x.id; }).indexOf(viewID);
			reviews[index].status = "review"

			// On Success reset back to defaults
			setSubPop(false)
			setSubComment(false)
			setOpen(false)
			setCommentsText("")

		} catch (err) {
			if (!err?.response) {
				toast.update(reviewReviewProfileToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(reviewReviewProfileToast, { render: 'Review Send Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	};

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getReviews = async () => {
			try {
				const response = await axiosPrivate.get('contractor-reviews/' + id, {
					signal: controller.signal
				});
				console.log(response.data);
				setReviews(response.data);
				setLoading(false)
			} catch(err) {
				console.log(err.message);
				{/* !auth && navigate('/login', { state: { from: location }, replace: true }); */}
			}
		}

		getReviews();

		return () => controller.abort();

	},[])

	const viewReview = (id, name, review, rating, status, comment, projectID, project) => {
		setOpen(true);
		setName(name);
		setReviewText(review);
		setRating(rating);
		setStatus(status);
		setViewID(id)
		setViewProject(project)
		setViewProjectID(projectID)
		comment ? setCanReply(false) : setCanReply(true)
	}

	const formatDate = (dateString) => {
	  const options = { month: "long", day: "numeric", year: "numeric" }
	  return new Date(dateString).toLocaleDateString(undefined, options)
	}

	function convertToSlug(Text)
	{
		return Text
			.toLowerCase()
			.replace(/ /g,'-')
			.replace(/[^\w-]+/g,'')
			;
	}

	function contractorURL(name){
		return "/contractor/" + convertToSlug(name);
	}

	return (
		<div className="backendList">
			{reviews?.length
				? (
					<>
					<ul className="reviewList client">
						<li className="key">
							<div className="name">Homeowner</div>
							<div className="statusContainer">Status</div>
							<div className="rating">Rating</div>
							<div className="date">Date of Review</div>
							<div className="buttons"></div>
						</li>
						{reviews.map((review, i) =>
							<li key={i}>
								<div className="name">
									<p>{review?.reviewed_by_full_name}</p>
								</div>
								<div className="statusContainer">
									<div className={`status ${review?.status}`}>
										{review?.status == "review" && "pending review"}
										{review?.status == "pending" && "pending approval"}
										{review?.status !== "pending" && review?.status !== "review" && review?.status}
									</div>
								</div>
								<div className="rating">
									<Rating name="size-small" defaultValue={review?.rating} readOnly size="small"/>
								</div>
								<div className="date">
									{formatDate(review?.created_at)}
								</div>
								<div className="buttons">
									<div className="view" onClick={(e) => viewReview(review?.id, review?.reviewed_by_full_name, review?.review_text, review?.rating, review?.status, review?.public_contractor_comment, review?.associated_project_id, review?.associated_project)}>
										<span><MdVisibility /></span>
									</div>
								</div>
							</li>
						)}
					</ul>

					<Dialog
						open={open}
						onClose={handleClose}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
						className="contractorReviewPopup"
					  >
						<DialogTitle id="alert-dialog-title">
						  {`${name} left this review...`}
						</DialogTitle>
						<DialogContent>
						  <DialogContentText id="alert-dialog-description">
							<Rating name="size-medium" defaultValue={rating} readOnly />
							<p>{reviewText}</p>
							<h4>Associated Project:</h4>
							{viewProjectID ?
								<>
									<p><strong>Project Name:</strong> {viewProject?.name}</p>
									<p><strong>Project Description:</strong> {viewProject?.description}</p>
						  	  	</>
								:
								<p>No project associated with this review</p>
							}
							{/* Add Public Reply to Review */}
							{(subReply || subApprove) && subPop &&
								<>
									<form>
										{/* Response */}
										<label htmlFor="response">Add public response {subApprove && "(optional)"}</label>
										<div className="inputWrapper">
											<textarea
												onChange={(e) => setResponseText(e.target.value)}
												name="response"
												id="repsonse"
												rows="5"
												value={responseText}
											>
											</textarea>
										</div>
									</form>
						  	  	</>
							}

							{/* Add Admin Comment to Review */}
							{subReview && subPop &&
								<>
									<form>
										{/* Response */}
										<label htmlFor="comments">Add comments to review admin (recommended)</label>
										<span className="">Explain your side for the best chance at getting a bad review denied.</span>
										<div className="inputWrapper">
											<textarea
												onChange={(e) => setCommentsText(e.target.value)}
												name="comments"
												id="comments"
												rows="5"
												value={commentsText}
											>
											</textarea>
										</div>
									</form>
						  	  	</>
							}

						  </DialogContentText>
						</DialogContent>
						<DialogActions>
						  {!subPop && <button className="cancel" onClick={handleClose}>Close</button>}
						  {subPop && <button className="cancel" onClick={handleCancel}>Cancel</button>}
						  {status !== "approved" && status !== "denied" && status !== "review" && !subPop &&
						  	<>
						  		<button className="approve" onClick={handleApprove}>Approve</button>
						  		<button className="dispute" onClick={handleReview}>Dispute</button>
							</>
						  }
						  {status == "approved" && canReply && !subPop && <button className="reply" onClick={handleReply}>Public Reply</button>}
						  {subReview && subPop && <button className="review" onClick={handleSendForReview}>Send</button>}
						  {subReply && subPop && <button className="review" onClick={handleSendPublicReply}>Send</button>}
						  {subApprove && subPop && <button className="review" onClick={handleSendApproval}>Approve Review</button>}
						</DialogActions>
					  </Dialog>
		</>
				) :
					loading ?
					<Loader />
					:
					<p>You have not reviewed any contractors yet.<br />To review a contractor go to the contractors profile and click review.</p>
			}
			<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
			/>
		</div>
	)
}

export default ContractorReviews