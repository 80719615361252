import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';
import { FaExclamation } from 'react-icons/fa';
import useAuth from '../hooks/useAuth';
import { ToastContainer, toast } from 'react-toastify';

const MESSAGE_REGEX = /^[a-zA-Z0-9-_.,& ?'/]{1,500}$/;

const SendMessage = ({ message, setMessage, validMessage, setValidMessage }) => {
		
	const messageRef = useRef();
	
	const [messageFocus, setMessageFocus] = useState(false);
	
	/* Check Message */
	useEffect(() => {
		const result = MESSAGE_REGEX.test(message);
		setValidMessage(result);
	}, [message])
	
	return (
		<>
			<form>
				
				{/* Message */}
				<label htmlFor="message">Message:</label>
				<div className="inputWrapper">
					<span className={validMessage ? "valid" : "hide"}><FaCheck /></span>
					<span className={validMessage || !message ? "hide" : "invalid"}><FaExclamation /></span>
					<textarea
						ref={messageRef}
						onChange={(e) => setMessage(e.target.value)}
						name="message"
						id="message"
						rows="5"
						value={message}
					>
					</textarea>
					<p id="descriptionnote" className={messageFocus && message && !validMessage ? "instructions" : "offscreen"}>
						Invalid Message. Between 1-500 characters (letters, numbers, dash , period, slash and underscore allowed)
					</p>
				</div>
							
			</form>
			<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
			/>
		</>
	)
}

export default SendMessage