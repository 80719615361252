import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { FaSearch } from "react-icons/fa";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";

const SearchBoxNew = ({ userZip, startingService }) => {
  const [loading, setLoading] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const zipRef = useRef();
  const serviceRef = useRef();

  const [zipcodes, setZipcodes] = useState();
  const [searchZip, setSearchZip] = useState(userZip ? userZip : null);
  const [searchZipResults, setSearchZipResults] = useState();
  const [services, setServices] = useState();
  const [updatingStartingService, setUpdatingStartingService] = useState(startingService)
  const [searchServices, setSearchServices] = useState();
  const [searchServicesResults, setSearchServicesResults] = useState();

  const [zipErrorActive, setZipErrorActive] = useState(false);
  const [zipErrorMessage, setZipErrorMessage] = useState(null);
  const [serviceErrorActive, setServiceErrorActive] = useState(false);
  const [serviceErrorMessage, setServiceErrorMessage] = useState(null);

  const [userZipObj, setUserZipObj] = useState();

  const [serviceValue, setServiceValue] = useState();
  // const [zipValue, setZipValue] = useState(userZip ? userZip : null)
  const [zipValue, setZipValue] = useState();

  const [validService, setValidService] = useState(false);
  const [validZip, setValidZip] = useState(userZip ? true : false);

  const [showServices, setShowServices] = useState(true);
  const [showZips, setShowZips] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const auth = useAuth();
  const role = auth?.role;
  const id = auth?.id;

  const [serviceCursor, setServiceCursor] = useState(0);
  const [zipCursor, setZipCursor] = useState(0);

  const [zipcodeNotInSystem, setZipcodeNotInSystem] = useState(false);
  const [serviceNotInSystem, setServiceNotInSystem] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    const getActivePackages = async () => {
      try {
        const response = await axiosPrivate.get("search-data", {
          signal: controller.signal,
        });
        const zips = response?.data?.zipcodes;
        setZipcodes(zips);
        setServices(response?.data?.categories);

        if (userZip) {
          setZipValue(userZip);
          setValidZip(true);
        }

        console.log(response?.data?.categories);
        setLoading(false);
      } catch (err) {
        console.log(err.message);
        !auth &&
          navigate("/login", { state: { from: location }, replace: true });
      }
    };

    getActivePackages();

    return () => controller.abort();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {

    if (startingService && services) {
      let modifiedStartingService
      if (startingService.includes("%20")) {
        modifiedStartingService = startingService.split("%20").join(" ")
      }
      const serviceForDisplay = services.find((service) => {
        if (modifiedStartingService) {
          return service.category_name === modifiedStartingService;
        } else {
          return service.category_name === startingService;
        }
      });
      if (serviceForDisplay) {
        setServiceValue(serviceForDisplay?.category_name);
      }
    }
  }, [services]);

  const handleSearch = async (e) => {
    e.preventDefault();

    const controller = new AbortController();
    try {
      const response = await axiosPrivate.post(
        "save-search-query",
        JSON.stringify({ zipcode: zipValue, service_category: serviceValue }),
        {
          signal: controller.signal,
        }
      );

      // Redirect to search results page
      navigate("search-contractor-results", {
        state: { service: serviceValue, zipcode: zipValue },
        replace: false,
      });
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response");
      } else {
        console.log("Failed to save search query");
      }
    }
    return () => controller.abort();
  };

  useEffect(() => {
    if (serviceValue) {
      console.log("service selected");
      console.log(serviceValue);
      setValidService(true);
      setServiceErrorActive(false);
      setServiceErrorMessage(null);
    } else {
      console.log("service cleared");
      setValidService(false);
    }
  }, [serviceValue]);

  useEffect(() => {
    if (zipValue) {
      console.log("zip selected");
      console.log(zipValue);
      setValidZip(true);
      setZipErrorActive(false);
      setZipErrorMessage(null);
    } else {
      console.log("zip cleared");
      setValidZip(false);
    }
  }, [zipValue]);

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option?.title,
  });

  // console.log("filterOptions", filterOptions)

  return (
    <div className="searchBoxWrapper">
      <div className="serachBoxContent">
        <h2>Find the right local home pro for your project</h2>
        <div className="searchBox">
          <div className="serviceSearchWrapper">
            <Autocomplete
              id="service-select"
              options={services}
              onChange={(event, newValue) => {
                setServiceValue(newValue?.category_name);
                setUpdatingStartingService("")
              }}
              onInputChange={(event, newInputValue) => {
                setValidService(false);
                setUpdatingStartingService("")
              }}
              autoHighlight
              getOptionLabel={(option) => {
                return option?.category_name}}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label={"Enter service type search word. Ex: Painting"}
                    inputProps={{
                      ...params?.inputProps,
                      value:
                        startingService && serviceValue && updatingStartingService
                          ? serviceValue
                          : params?.inputProps?.value,
                    }}
                    helperText={serviceErrorMessage}
                    error={serviceErrorActive}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && validService && validZip) {
                        return handleSearch(e);
                      } else if (e.key === "Enter" && !validService) {
                        setServiceErrorActive(true);
                        setServiceErrorMessage("Invalid Service");
                      } else if (e.key === "Enter" && !validZip) {
                        setZipErrorActive(true);
                        setZipErrorMessage("Invalid Zip Code");
                      }
                    }}
                  />
                );
              }}
            />
          </div>

          <div className="zipcodeSearchWrapper">
            <TextField
              label={userZip ? userZip : "Zipcode"}
              value={zipValue}
              helperText={zipErrorMessage}
              error={zipErrorActive}
              onChange={(event, newValue) => {
                // console.log("event", event);
                // console.log("event.target.value", event.target.value);
                // console.log("newValue", newValue)
                // console.log("newValue.zipcode", newValue.zipcode);
                setZipValue(event.target.value);
                // setValidZip(false)
              }}
              className="MuiAutocomplete-root MuiAutocomplete-hasPopupIcon css-1l6di18"
              style={serviceErrorActive ? {} : { height: "100%" }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && validService && validZip) {
                  return handleSearch(e);
                } else if (e.key === "Enter" && !validService) {
                  setServiceErrorActive(true);
                  setServiceErrorMessage("Invalid Service");
                } else if (e.key === "Enter" && !validZip) {
                  setZipErrorActive(true);
                  setZipErrorMessage("Invalid Zip Code");
                }
              }}
            ></TextField>
            {/* <Autocomplete
								id="zip-select"
								options={zipcodes}
								value={zipValue}
								// filterOptions={filterOptions}
								onChange={(event, newValue) => {
									setZipValue(newValue.zipcode)
									console.log(newValue.zipcode)
								}}
								onInputChange={(event, newInputValue) => {
									setValidZip(false)
									console.log(newInputValue)
								}}
								autoHighlight
								getOptionLabel={(option) => option.zipcode}
								renderInput={(params) => {
									console.log("params", params)
									return(
									<TextField
										{...params}
										label={userZip ? userZip : "Zipcode"}
										// inputProps={{
										// 	...params.inputProps,
										// }}
									/>
								)}}
							/> */}
          </div>
          <button
            disabled={!validService || !validZip ? true : false}
            onClick={(e) => handleSearch(e)}
          >
            <FaSearch />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchBoxNew;
