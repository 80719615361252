import { createContext , useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
	const [auth, setAuth] = useState({});
	const [persist, setPersist] = useState(JSON.parse(localStorage.getItem("persist")) || true);
	const [editing, setEditing] = useState(false);
	
	return (
		<AuthContext.Provider value={{ auth, setAuth, persist, setPersist, editing, setEditing }}>
			{children}
		</AuthContext.Provider>
	)
}

export default AuthContext;