import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from "react";
import useAuth from '../hooks/useAuth';
import Navigation from './Navigation';
import { AiOutlineCloseSquare } from 'react-icons/ai'
import { Link as MuiLink } from "@mui/material";
import bannerImage from "../assets/images/HomeShowBanner.jpg";
import bannerImage2 from "../assets/images/DHS24_v2.jpg";
const Header = () => {
	const { auth } = useAuth();

	const location = useLocation();

	const [usersName, setUsersName] = useState()
	const [showWelcomeText, setShowWelcomeText] = useState(true)

	useEffect(() => {
		setUsersName(`${auth?.user?.first_name} ${auth?.user?.last_name}`)
		setShowWelcomeText(true)

		// eslint-disable-next-line
	}, [auth])

	const hideWelcomeText = () => {
		setShowWelcomeText(false)
	}

	return (
		<>
			<header>
			<article className='inner'>
				{/* <div className="banner-link">
					<MuiLink href={`https://denverhomeshow.mpetickets.com/`}>
						<img 
							src={bannerImage2}
							alt="Denver Home Show Banner"
						/>
					</MuiLink>
				</div> */}
			</article>
				<article className='inner'>
					{auth?.user?.role !== "5150" && auth?.user?.role !== "127001" && auth?.user && showWelcomeText &&
						<span className="welcome" onClick={hideWelcomeText}>Welcome back {usersName} <AiOutlineCloseSquare /></span>
					}
					<div className="logoWrapper"><Link to="/"><img className="logo" src="/assets/images/iyh_logo-blue.png" alt="Improve Your House"/></Link></div>
					<Navigation />
				</article>
			</header>

		</>
	)
}

export default Header