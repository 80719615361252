import { useRef, useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from '../hooks/useAuth';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PageFileDrop from "./PageFileDrop"
import { ToastContainer, toast } from 'react-toastify';

const LegalTextEditor = ({ content, id, pageImages, setPageImages }) => {

	const { auth } = useAuth();
	const updatePageURL = "page/" + id;

	const [loading, setLoading] = useState(false);
	const axiosPrivate = useAxiosPrivate();

	const editorRef = useRef();
	const errRef = useRef();

	const [errMsg, setErrMsg] = useState('')

	const [open, setOpen] = useState(false)
	const [upload, setUpload] = useState(false)
	const [bannerOpen, setBannerOpen] = useState(false)

	const [selectedImage, setSelectedImage] = useState()

	const [editor, setEditor] = useState()

	const handleInit = (evt, editor) => {
		editorRef.current = editor
	};

	const handleClickOpen = () => {
		setOpen(true)
	};

	const handleClose = () => {
		setOpen(false)
	};

	const handleUploadClose = () => {
		setUpload(false)
		setOpen(true)
	};

	const handleBannerClose = () => {
		setUpload(false)
		setBannerOpen(true)
	};

	const handleUpload = () => {
		setOpen(false)
		setUpload(true)
	};

	const handleInsertImage = () => {
		setOpen(false)
	};

	const handleUploadImage = () => {
		console.log("upload images to server")
		setUpload(false)
	};

	const handleSave = async (e) => {

		const dismiss = () =>  toast.dismiss(savePageContentToast.current);
		const savePageContentToast = toast.loading("Saving Page");
		const controller = new AbortController();
		try {
			const html = { content: editorRef.current.getContent() };
			const response = await axiosPrivate.put(updatePageURL,
			html,
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(savePageContentToast, { render: 'Page Saved', type: 'success', isLoading: false, autoClose: 5000});

			// Close Alert Window
			setOpen(false);


		} catch (err) {
			if (!err?.response) {
				toast.update(savePageContentToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(savePageContentToast, { render: 'Saving Page Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			errRef.current.focus();
		}
		return () => controller.abort();

	}

	const insertImage = (e, image) => {
		// Verify there is a image before proceeding
		if (image){
			// Get image and generate image html
			setSelectedImage(image)
			var html = `<img src="https://storage.iyh.app/${image}" alt="" />`

			// Insert html at cursor in editor
			editor.insertContent(html)

			// Close Popup
			setOpen(false)
		}
	}

	const showImages = (editor) => {
		setOpen(true)
		setEditor(editor)
	};

	return (
		<>
			<Editor
				apiKey='yyg9if3xush2hhmsnlca26i5mtqzzjeu3rmsr2d6mfoowlh2'
				onInit = {handleInit}
				initialValue = { content }
				init = {{
					menubar: false,
					paste_as_text: false,
					plugins: [
						'lists', 'link', 'image', 'preview', 'media', 'imagetools'
					],
					toolbar: [
						'undo redo | styles | bold italic | outdent indent | alignleft aligncenter alignright | bullist numlist | link customImageSelector | preview code',
					],
					link_class_list: [
						{ title: 'Default', value: '' },
						{ title: 'Button', value: 'button' },
					],
					setup: function (editor) {
						editor.ui.registry.addButton("customImageSelector", {
						  icon: "gallery",
						  onAction: function(){
						  	showImages(editor)
						  }
						});
					},
				}}
			/>
			<button onClick={handleSave}>Save Changes</button>

			{/* Add TinyMCE Image Dialog */}
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			  >
				<DialogTitle id="alert-dialog-title">
				  {"Add Image"}
				</DialogTitle>
				<DialogContent>
				  <>
				  	<p>Click on a image to insert to the page or click add to upload a new one.</p>
					<ul className="pageImages">
					{pageImages?.map((image, i) =>
						<li onClick={(e) => insertImage(e, image.path)}><img key={i} src={`https://storage.iyh.app/${image.path}`} alt={image.name} /></li>
					)}
					</ul>
				  </>
				</DialogContent>
				<DialogActions>
				  <button className="cancel" onClick={handleClose}>Cancel</button>
				  <button className="confirm" onClick={handleUpload}>Add</button>
				</DialogActions>
			  </Dialog>

			{/* Upload Image Dialog */}
			<Dialog
				open={upload}
				onClose={handleUploadClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			  >
				<DialogTitle id="alert-dialog-title">
				  {"Upload Image(s)"}
				</DialogTitle>
				<DialogContent>
				  <>
				  	<PageFileDrop showFiles="true" autoUpload="" limit={10} multiple="true" accept="" buttonTitle="" handleUploadImage={handleUploadImage} setPageImages={setPageImages}/>
				  </>
				</DialogContent>
				<DialogActions>
				  <button className="cancel" onClick={handleUploadClose}>Cancel</button>
				  {/* <button className="confirm" onClick={handleUploadImage}>Upload New</button> */}
				</DialogActions>
			  </Dialog>

			  <ToastContainer
			  position="top-right"
			  autoClose={5000}
			  hideProgressBar={false}
			  newestOnTop={false}
			  closeOnClick
			  rtl={false}
			  pauseOnFocusLoss
			  draggable
			  pauseOnHover
			  theme="colored"
			  />
		</>
	)
}


export default LegalTextEditor