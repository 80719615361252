import { Link } from "react-router-dom";
import Page from "../components/Page";

const HomeownerFormTerms = () => {
  return (
    <section>
      <article className="inner">
        {/* <h1>Error 404</h1> */}
        <h3>ImproveYourHouse.com Sweepstakes Official Rules</h3>
        <br />
        <h5>
          NO PURCHASE NECESSARY TO ENTER OR WIN. A PURCHASE DOES NOT INCREASE
          YOUR CHANCES OF WINNING.
        </h5>
        <br />
        <p>
          <strong>Eligibility:</strong> Sweepstakes is open to all legal
          residents of the United States, the District of Columbia, and Canada
          (excluding the province of Quebec) who are 18 years of age or older as
          of March 27, 2024. Employees, officers and directors of the Sponsor
          (GDHI Marketing LLC), their parents, affiliates, subsidiaries, agents,
          advertising and promotion agencies, (collectively including Sponsor,
          “Sweepstakes Entities”) their immediate family members (parents,
          children, siblings, spouses) and persons living in the same household
          (whether related or not), are not eligible to participate in the
          Sweepstakes. Participation constitutes entrant's full and
          unconditional agreement to and acceptance of these Official Rules. The
          Sweepstakes will open on March 22, 2024, at 8:00 am MST. The
          Sweepstakes will end at 4:00 pm MST on March 27, 2024 ("Sweepstakes
          Period"). Void where prohibited.
        </p>
        <br />
        <p>
          <strong>How to Enter:</strong> Eligible participants during the
          Sweepstakes may enter by visiting any of ImproveYourHouse.com website
          pages listed at{" "}
          <Link to="/dhs-homeowner-giveaway-2024">
            ImproveYourHouse.com/dhs-homeowner-giveaway-2024
          </Link>{" "}
          or by scanning one of the many QR Codes posted around the Home Show
          and Booth #705. Entrants must complete the online entry form during
          the Sweepstakes Period. Participants must complete all requested
          information fields. All entries must be received by 4:00 pm MST on
          March 27, 2024, to qualify for the Grand Prize. Once submitted, an
          online entry cannot be deleted, canceled or modified. Participants
          automatically will receive one (1) entry into the Sweepstakes. Limit
          one (1) entry per person per email address is permitted. Sponsor
          reserves the right to disqualify any person or email address that
          submits multiple entries. All entries become the exclusive property of
          the Sponsor.
        </p>
        <br />
        <p>
          <strong>Grand Prize:</strong> One (1) Grand Prize winner will receive
          a credit of $2000 payable to the ImproveYourHouse.com Home Pro or
          Denver Home Show Exhibitor that the winner has contracted with for
          their home improvement project. No prize or payment will be paid
          directly to the winner.(Credit payable in currency of the winner's
          residing city, US or Canada). Average Retail Value (AVR) of this prize
          is $2000.
        </p>
        <br />
        <p>
          <strong>Drawing and Notification:</strong> The potential Grand Prize
          Winner will be selected, from among all eligible entries received, in
          a random drawing conducted on or about{" "}
          <strong>March 27th, 2024</strong>{" "}
          by a random draw. Odds of winning will depend on the total number of
          eligible entries received. Potential winner will be notified by both
          email and by telephone. Upon initial notification and verification of
          potential Grand Prize Winner confirmation will be sent to the winner
          in writing via email or USPS mail. Potential winner will have (72)
          hours to respond to accept their prize, failure to respond in the (72)
          hour window upon initial notification will result in a new drawing
          among all eligible entries. Upon acceptance of the Grand Prize, winner
          will have six (6) months from the acceptance date to utilize the
          Credit Prize.
        </p>
        <br />
        <p>
          <strong>Prize Conditions:</strong> To utilize the Prize, entrant
          winner must provide a signed contract or agreement of a scheduled job
          with a participating ImproveYourHouse.com Home Pro or Denver Home Show
          Exhibitor to which GDHI Marketing LLC will pay $2,000 towards the
          contracted project or job. A minimum contract value of $2,000 is
          required to claim credit. Grand Prize winner is solely responsible for
          all applicable federal, state, and local taxes associated with the
          receipt of any prize. All taxes, if applicable, are the sole
          responsibility of the winner. The winner’s entry and acceptance of the
          prize offered constitutes permission (except where prohibited by law
          or Sponsor’s Privacy Policy) for the Sponsor to use said winner’s
          name, photograph, likeness, statements, biographical information,
          voice and address (city and state), in all forms of media, in
          perpetuity, without notice or further compensation.
        </p>
        <br />
        <p>
          <strong>Limitations of Liability:</strong> If for any reason this
          Sweepstakes is not capable of running as planned due to infection by
          computer virus, bugs, tampering, unauthorized intervention, fraud,
          technical failures, or any other causes beyond the control of the
          Sponsor which corrupt or affect the administration, security,
          fairness, integrity or proper conduct of this Sweepstakes, the Sponsor
          reserves the right at its sole discretion, to disqualify any
          individual who tampers with the entry process, and to cancel,
          terminate, modify or suspend the Sweepstakes in whole or in part, at
          any time, without notice and award the prizes using all non-suspect
          eligible entries received as of or after (if applicable) this
          termination or suspension date. Sweepstakes Entities assume no
          responsibility for any error, omission, interruption, deletion,
          defect, delay in operation or transmission, communications line
          failure, theft or destruction or unauthorized access to, or alteration
          of, entries. Sweepstakes Entities are not responsible for any problems
          or technical malfunction of any telephone network or telephone lines,
          computer online systems, servers, or providers, computer equipment,
          software, failure of any email or entry to be received by Sponsor on
          account of technical problems, human error or traffic congestion on
          the Internet or at any Website, or any combination thereof, including
          any injury or damage to participant's or any other person's computer
          relating to or resulting from participation in this Sweepstakes or
          downloading any materials in this Sweepstakes. CAUTION: ANY ATTEMPT TO
          DELIBERATELY DAMAGE ANY WEBSITE OR UNDERMINE THE LEGITIMATE OPERATION
          OF THE SWEEPSTAKES IS A VIOLATION OF CRIMINAL AND CIVIL LAWS AND
          SHOULD SUCH AN ATTEMPT BE MADE, THE SPONSOR RESERVES THE RIGHT TO SEEK
          DAMAGES OR OTHER REMEDIES FROM ANY SUCH PERSON (S) RESPONSIBLE FOR THE
          ATTEMPT TO THE FULLEST EXTENT PERMITTED BY LAW. In the event of a
          dispute as to the identity of a winner based on an email address, the
          winning entry will be declared made by the authorized account holder
          of the email address submitted at time of entry. "Authorized account
          holder" is defined as the natural person who is assigned to an email
          address by an Internet access provider, online service provider or
          other organization (e.g., business, educational, institution, etc.)
          that is responsible for assigning email addresses for the domain
          associated with the submitted email address.
        </p>
        <br />
        <p>
          <strong>Use of Data:</strong> Sponsor will be collecting personal data
          about entrants including phone numbers and email addresses in
          accordance with its Privacy Policy. Please review the Sponsor's
          Privacy Policy at the following link:{" "}
          <a href="https://legal.improveyourhouse.com/privacy-policy">
            https://legal.improveyourhouse.com/privacy-policy
          </a>{" "}
          By participating in the Promotion and providing this information,
          entrants hereby agree to Sponsor’s collection and usage of their
          personal information and acknowledge that they have read and accepted
          Sponsor’s Privacy Policy. Terms & Conditions: This Sweepstakes is
          subject to all applicable federal, state, and local laws. By
          participating, entrants agree to be bound by these Official Rules and
          the decisions of the Sweepstakes Administrator and waive any right to
          claim ambiguity in the Sweepstakes or these Official Rules. Entrants
          also consent to the use of the personal data they submit to be used
          solely by the sponsor for marketing purposes, statistical analysis, or
          customer relations. This information will never be sold or shared with
          any other third parties and all entrants will be given the opportunity
          to opt-out at any time. Winner and entrants also agree to release,
          discharge, indemnify and hold harmless the Sweepstakes Entities from
          and against any claims, damages or liability due to any injuries,
          damages or losses to any person (including death) or property of any
          kind resulting in whole or in any part, directly or indirectly, from
          acceptance, possession, use or misuse of prize offered, or
          participation in this Sweepstakes. Any person attempting to defraud or
          in any way tamper with the Sweepstakes mechanics and/or implementation
          will be prosecuted to the full extent of the law. This Sweepstakes is
          void where prohibited. This promotion is in no way sponsored,
          endorsed, or administered by, or associated with The Denver Home Show.
          Entrants acknowledge that they are providing their personal
          information to ImproveYourHouse.com and not to The Denver Home Show.
        </p>
      </article>
    </section>
  );
};

export default HomeownerFormTerms;
