import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import useAxiosFunction from "../hooks/useAxiosFunction";
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import Loader from "../components/Loader";
import { MdStar } from 'react-icons/md'

const ContractorSearchNameResults = () => {

	const axiosPrivate = useAxiosPrivate();
	const [loading, setLoading] = useState(false);

	const location = useLocation();

	const [searchBusinessName, setSearchBusinessName] = useState(location?.state?.name)

	const { auth } = useAuth();

	const [data, setData] = useState()

	const contractors = data?.contractors

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getContractors = async () => {
			try {
				const response = await axiosPrivate.post('search-contractors-by-name',
				JSON.stringify({name: searchBusinessName}),
				{
					signal: controller.signal
				});
				console.log(response.data);
				setData(response.data);
				setLoading(false)
			} catch(err) {
				setLoading(false)
				console.log(err.message);
			}
		}

		getContractors();

		return () => controller.abort();

	},[])


	return (
		<section>
			<article className='inner'>
				<h1>Contractor Search Results</h1>
				<div className="searchParams">
					<p>Business Name: {searchBusinessName}</p>
				</div>

				{contractors?.length
					? (
						<ul className="contractorList">
							{contractors.sort((d1, d2) => d2.rating - d1.rating).map((contractor, i) =>
								<li key={i} className={`${contractor?.priority ? 'featured' : null}`}>
									<Link to={`/contractor/${contractor?.slug}`}>
										{contractor?.cover_picture ? (
											<div className="cover">
												<img src={`https://storage.iyh.app/${contractor?.cover_picture}`} alt="" />
											</div>
										)
										:
										null }
										<div className="businessInfo">
											<div className="business_name">
												{contractor?.business_name ?
													contractor?.business_name
													:
													"Business Name N/A"
												}
											</div>
											{contractor?.rating !== "0.0" ?
												<div className="business_rating">
													{contractor?.rating}<MdStar />
												</div>
												:
												null
											}
										</div>
									</Link>
								</li>
							)}
						</ul>
					) :
						loading ?
						<Loader />
						:
						<p>No contractors to display</p>
				}
			</article>
		</section>
	)
}

export default ContractorSearchNameResults