import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from '../hooks/useAuth';
import { FaCheck } from 'react-icons/fa';
import { FaExclamation } from 'react-icons/fa';
import { MdReportProblem } from 'react-icons/md';
import axios from '../api/axios';
import usePageTitle from '../hooks/usePageTitle';
import PopupAgreement from '../components/PopupAgreement'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import RegisterThankYou from "./RegisterThankYou";

/* username and password restrictions */
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%_-]).{8,24}$/;
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const NAME_REGEX = /^[a-zA-Z' -]{2,23}$/;
const POSITION_REGEX = /^[a-zA-Z' -#&*]{2,23}$/;
const PHONE_REGEX = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
const CITY_REGEX = /^[a-zA-Z ]{2,50}$/;
const ZIPCODE_REGEX = /^\d{5}(?:[-\s]\d{4})?$/;
const BUSINESS_REGEX = /[^\s]{1,100}$/;
const CODE_REGEX = /^\d{6}$/;
const MESSAGE_REGEX = /^[a-zA-Z0-9,.#&%!$'()/ ]{2,10000}$/;


const REGISTER_URL = 'register';
const INVITE_URL = 'check-invite-code';

const Register = ({ userType }) => {

	const location = useLocation()
	const navigate = useNavigate()
	const axiosPrivate = useAxiosPrivate();
	const { auth } = useAuth();
	const [loading, setLoading] = useState(false)
	const params = new URLSearchParams(window.location.search);
	const urlCode = params.get('code');

	const emailRef = useRef();
	const errRef = useRef();
	const fnameRef = useRef();
	const phoneRef = useRef();
	const businessNameRef = useRef();
	const cityRef = useRef();
	const stateRef = useRef();
	const zipRef = useRef();
	const codeRef = useRef();
	const contactNameFRef = useRef();
	const contactNameLRef = useRef();
	const businessPositionRef = useRef();

	const [businessName, setBusinessName] = useState('');
	const [validBusinessName, setValidBusinessName] = useState(false);
	const [businessNameFocus, setBusinessNameFocus] = useState(false);

	const [fName, setFName] = useState('');
	const [validFName, setValidFName] = useState(false);
	const [fNameFocus, setFNameFocus] = useState(false);

	const [lName, setLName] = useState('');
	const [validLName, setValidLName] = useState(false);
	const [lNameFocus, setLNameFocus] = useState(false);

	const [email, setEmail] = useState('');
	const [validEmail, setValidEmail] = useState(false);
	const [emailFocus, setEmailFocus] = useState(false);

	const [phone, setPhone] = useState('');
	const [validPhone, setValidPhone] = useState(false);
	const [phoneFocus, setPhoneFocus] = useState(false);

	const [city, setCity] = useState('');
	const [validCity, setValidCity] = useState(false);
	const [cityFocus, setCityFocus] = useState(false);

	const [state, setState] = useState('');
	const [validState, setValidState] = useState(false);
	const [stateFocus, setStateFocus] = useState(false);

	const [zip, setZip] = useState('');
	const [validZip, setValidZip] = useState(false);
	const [zipFocus, setZipFocus] = useState(false);

	const [type, setType] = useState(urlCode ? '2002' : '1001'); // sets default account type to homeowner
	const [validType, setValidType] = useState(false);
	const [typeFocus, setTypeFocus] = useState(false);

	const [pwd, setPwd] = useState('');
	const [validPwd, setValidPwd] = useState(false);
	const [pwdFocus, setPwdFocus] = useState(false);

	const [matchPwd, setMatchPwd] = useState('');
	const [validMatch, setValidMatch] = useState(false);
	const [matchFocus, setMatchFocus] = useState(false);

	const [errMsg, setErrMsg] = useState('');
	const [success, setSuccess] = useState(false);

	const [responseMessage, setResponseMessage] = useState()

	const [offersAndDiscounts, setOffersAndDiscounts] = useState(false)

	const [code, setCode] = useState(urlCode || "")
	const [validCode, setValidCode] = useState(false)
	const [codeFocus, setCodeFocus] = useState(false)
	const [codeErrMsg, setCodeErrMsg] = useState()
	const [inviteData, setInviteData] = useState()


	const [market, setMarket] = useState()
	const [markets, setMarkets] = useState()
	const [reason, setReason] = useState()
	const [message, setMessage] = useState()
	const [validMessage, setValidMessage] = useState()
	const [messageFocus, setMessageFocus] = useState(false)
	const [reasonFocus, setReasonFocus] = useState(false)
	const [marketFocus, setMarketFocus] = useState(false)

	const [contactFName, setContactFName] = useState()
	const [contactLName, setContactLName] = useState()
	const [validContactFName, setValidContactFName] = useState(false)
	const [validContactLName, setValidContactLName] = useState(false)
	const [contactFNameFocus, setContactFNameFocus] = useState(false)
	const [contactLNameFocus, setContactLNameFocus] = useState(false)

	const [businessPosition, setBusinessPosition] = useState()
	const [businessPositionFocus, setBusinessPositionFocus] = useState(false)
	const [validBusinessPosition, setValidBusinessPosition] = useState(false)

  const [agreeToAllTermsAndConditions, setAgreeToAllTermsAndConditions] =
  useState(false);

	/* Usability focus on load to user */
	useEffect(() => {
		type == 1001 ?
			fnameRef.current.focus()
			:
			inviteData ?
				businessNameRef.current.focus()
				:
				// codeRef.current.focus()


		userType && setType(userType)

		if (location?.pathname == "/register-home-pro"){
			setType('2002')
		}

		const controller = new AbortController();
		setLoading(true);
		const getPackages = async () => {
			try {
				const response = await axiosPrivate.get('get-active-markets', {
					signal: controller.signal
				});
				setMarkets(response.data);
				setLoading(false)
			} catch(err) {
				console.log(err.message);
				!auth && navigate('/login', { state: { from: location }, replace: true });
			}
		}

		getPackages();

		return () => controller.abort();
		// eslint-disable-next-line
	}, [])

	/* Check username against regex function on username change */
	useEffect(() => {
		const result = EMAIL_REGEX.test(email);
		setValidEmail(result);
	}, [email])

	/* Check password against regex function on password change */
	useEffect(() => {
		const result = PWD_REGEX.test(pwd);
		setValidPwd(result);
		const match = pwd === matchPwd;
		setValidMatch(match);
	}, [pwd, matchPwd])

	/* Check Business Name */
	useEffect(() => {
		const result = BUSINESS_REGEX.test(businessName);
		setValidBusinessName(result);
	}, [businessName])

	/* Check First Name */
	useEffect(() => {
		const result = NAME_REGEX.test(fName);
		setValidFName(result);
	}, [fName])

	useEffect(() => {
		const result = NAME_REGEX.test(contactFName);
		setValidContactFName(result);
	}, [contactFName])

	/* Check Last Name */
	useEffect(() => {
		const result = NAME_REGEX.test(lName);
		setValidLName(result);
	}, [lName])

	useEffect(() => {
		const result = NAME_REGEX.test(contactLName);
		setValidContactLName(result);
	}, [contactLName])

	/* Check Phone */
	useEffect(() => {
		const result = PHONE_REGEX.test(phone);
		setValidPhone(result);
	}, [phone])

	/* Check City */
	useEffect(() => {
		const result = CITY_REGEX.test(city);
		setValidCity(result);
	}, [city])

	/* Check State */
	useEffect(() => {
		state !== "" || type == 1001 ? setValidState(true) : setValidState(false);
	}, [state, type])

	/* Check Zip */
	useEffect(() => {
		const result = ZIPCODE_REGEX.test(zip);
		setValidZip(result);
	}, [zip])

	/* Check Code */
	useEffect(() => {
		const result = CODE_REGEX.test(code);
		setValidCode(result);
	}, [code])

	/* Clear Error message on any change of user or password */
	useEffect(() => {
		setErrMsg('');
	}, [email, pwd, matchPwd])

	/* Check Message */
	useEffect(() => {
		const result = MESSAGE_REGEX.test(message);
		setValidMessage(result);
	}, [message])

	/* Check Position */
	useEffect(() => {
		const result = POSITION_REGEX.test(businessPosition);
		setValidBusinessPosition(result);
	}, [businessPosition])

	function convertToSlug(Text)
	{
		return Text
			.toLowerCase()
			.replace(/ /g,'-')
			.replace(/[^\w-]+/g,'')
			;
	}

	const handleSubmit = async (e) => {
		e.preventDefault();

		// Prevent Hacking of submit button by checking against regex again
		const v1 = EMAIL_REGEX.test(email);
		const v2 = PWD_REGEX.test(pwd);
		const v3 = PHONE_REGEX.test(phone);

		if (!v1 || !v2 || !v3){
			setErrMsg("Invalid Entry");
			return;
		}
		try {
			const response = await axios.post(REGISTER_URL,
				JSON.stringify({first_name: fName, last_name: lName, email, password: pwd, password_confirmation: matchPwd, role: type, phone: phone, city: city, state: state, zip: zip, business_name: businessName, slug: businessName ? convertToSlug(businessName) : null, receive_offers: offersAndDiscounts, homeshow_raffle_2024: false}),
				{
					headers: { 'Content-Type': 'application/json'},
					withCredentials: true
				}
			);
			// console.log('test')
			// console.log(response.data);
			// console.log(response.token);
			// console.log(JSON.stringify(response));
			setSuccess(true);
			setResponseMessage(response?.data?.message)

			if (response?.data?.user?.callcap_provision_number && response?.data?.user?.role === "2002"){
				console.log("register number with callcap")

				try {
					const callcapResponse = await axios.post('https://api.callcap.com/v3/numbers',
						JSON.stringify({locationID: '27684', destination: phone, campaignName: businessName, recording: true, notes: 'Added with API on new user registration', destination: response?.data?.user?.callcap_provision_number}),
						{
							headers: {
								'Content-Type': 'application/json',
								'Authorization': 'Bearer 8EAB5960035EB360D658CE767020ADAC',
							},
							withCredentials: true
						}
					);


				} catch (err) {
					if (!err?.response) {
						console.log('No Callcap Server Response');
					} else if (err.response?.statusCode === 400) {
						console.log('Bad Callcap Request');
					} else {
						console.log('Callcap Provision Failed');
					}
				}
			} else {
				console.log("nothing to register with callcap")
			}

			if (type == '2002'){
				await window.location.replace('/thank-you-home-pro')
			} else if (type == '1001'){
				await window.location.replace('/thank-you-homeowner')
			}

		} catch (err) {
			if (!err?.response) {
				setErrMsg('No Server Response');
				console.log(err)
			} else if (err.response?.status === 409) {
				setErrMsg('Name Taken');
			} else if (err.response?.status === 422) {
				if (err.response?.data?.message){
					setErrMsg(err.response?.data?.message);
				} else {
					setErrMsg('Registration Failed - Error 422');
					// setErrMsg('Missing Required Field');
				}
			} else {
				setErrMsg('Registration Failed');
			}
			errRef.current.focus();
		}
	}

	const handleCodeSubmit = async (e) => {
		e.preventDefault();

		try {
			const response = await axios.post(INVITE_URL,
				JSON.stringify({code: code}),
				{
					headers: { 'Content-Type': 'application/json'},
					withCredentials: true
				}
			);
			setInviteData(response?.data)

			const inviteUser = response?.data?.user
			console.log(inviteUser)

		} catch (err) {
			if (!err?.response) {
				setCodeErrMsg('No Server Response');
			} else if (err.response?.status === 409) {
				setCodeErrMsg('Name Taken');
			} else if (err.response?.status === 422) {
				if (err.response?.data?.message){
					setCodeErrMsg(err.response?.data?.message);
				} else {
					setCodeErrMsg('Registration Failed - Error 422');
					// setErrMsg('Missing Required Field');
				}
			} else {
				setCodeErrMsg('Registration Failed');
			}
		}
	}

	const handleSubmitRequest = async (e) => {
		e.preventDefault();

		try {
			const response = await axios.post('request-invite',
				JSON.stringify({first_name: contactFName, last_name: contactLName, email: email, phone: phone, business_name: businessName, company_position: businessPosition, market: market, reason: reason, message: message}),
				{
					headers: { 'Content-Type': 'application/json'},
					withCredentials: true
				}
			);
			setSuccess(true)

		} catch (err) {
			if (!err?.response) {
				setErrMsg('No Server Response');
			} else if (err.response?.status === 409) {
				setErrMsg('Name Taken');
			} else if (err.response?.status === 422) {
				if (err.response?.data?.message){
					setErrMsg(err.response?.data?.message);
				} else {
					setErrMsg('Request Failed - Error 422');
					// setErrMsg('Missing Required Field');
				}
			} else {
				setErrMsg('Request Failed');
			}
		}
	}

	useEffect(() => {
		const user = inviteData?.user
		setBusinessName(user?.business_name)
		setFName(user?.first_name)
		setLName(user?.last_name)
		setEmail(user?.email)
		setPhone(user?.phone)
	}, [inviteData])

	usePageTitle("Register");

	return (
		<>
		{success? (
			<section>
				<article className='inner'>
					<h1 className="case">Thanks For Signing Up With ImproveYourHouse.com!</h1>
					{ type == 2002 ?
						<>
							<RegisterThankYou type={2002} />
							{/*{inviteData ?
								<p>A verification email has been sent to "{email}". Once you have verified your email address, you will be able to complete the setup of your new IYH Contractor Profile and appear live on ImproveYourHouse.com. If you don't see a email from us in a few minutes check your spam/junk folder.</p>
								:
								<p>Your registration request has been sent to our sales team.</p>
							}*/}
						</>
						:
						<RegisterThankYou type={1001} />
						/*<p>A verification email has been sent to "{email}". Once you have verified your email address, you will be able to access your Homeowner dashboard that will allow you to create and submit projects, save and favorite Home Pros and more! If you don’t see a verification email from us within a few minutes, please check your spam or junk email folder.</p>*/
					}

				</article>
			</section>
		) : (
		<section>
			<article className='inner'>
				<p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive"><MdReportProblem /> {errMsg}</p>
				<h1>{type == 2002 && !urlCode ? 'Request' : 'Register'} New Account</h1>

				{ type == 2002 && !inviteData && !urlCode &&
					<>
						{/* <a href="#invite">Already have an invite code?</a>*/}
						<form onSubmit={handleSubmitRequest}>

							<label htmlFor="type">Account Type:</label>
							<div className="inputWrapper">
								<select
									id="type"
									onChange={(e) => setType(e.target.value)}
									value={type}
									required
									onFocus={() => setTypeFocus(true)}
									onBlur={() => setTypeFocus(false)}
								>
									<option value="1001">Homeowner</option>
									<option value="2002">Home Pro</option>

								</select>
							</div>

							{/* Business Name */}
							<label htmlFor="businessName">Business Name<span className="required">*</span></label>
							<div className="inputWrapper">
								<span className={validBusinessName && businessName ? "valid" : "hide"}><FaCheck /></span>
								<span className={validBusinessName || !businessName ? "hide" : "invalid"}><FaExclamation /></span>
								<input
									type="text"
									id="businessName"
									ref={businessNameRef}
									autoComplete="off"
									onChange={(e) => setBusinessName(e.target.value)}
									value={businessName}
									required
									aria-invalid={validBusinessName ? "false" : "true"}
									aria-describedby="businessnamenote"
									onFocus={() => setBusinessNameFocus(true)}
									onBlur={() => setBusinessNameFocus(false)}
								/>
							</div>
							<p id="businessnamenote" className={businessNameFocus && businessName && !validBusinessName ? "instructions" : "offscreen"}>
								Invalid business name.
							</p>

							{/* Contact First Name */}
							<label htmlFor="contactName">Contact First Name<span className="required">*</span></label>
							<div className="inputWrapper">
								<span className={validContactFName && contactFName ? "valid" : "hide"}><FaCheck /></span>
								<span className={validContactFName || !contactFName ? "hide" : "invalid"}><FaExclamation /></span>
								<input
									type="text"
									id="contactName"
									className={validContactFName || !contactFName ? null : "error"}
									ref={contactNameFRef}
									autoComplete="off"
									onChange={(e) => setContactFName(e.target.value)}
									value={contactFName}
									required
									aria-invalid={validContactFName ? "false" : "true"}
									aria-describedby="contactdnote"
									onFocus={() => setContactFNameFocus(true)}
									onBlur={() => setContactFNameFocus(false)}
								/>
							</div>
							<p id="contactdnote" className={contactFNameFocus && contactFName && !validContactFName ? "instructions" : "offscreen"}>
								Invalid first name.
							</p>

							{/* Contact Last Name */}
							<label htmlFor="contactName">Contact Last Name<span className="required">*</span></label>
							<div className="inputWrapper">
								<span className={validContactLName && contactLName ? "valid" : "hide"}><FaCheck /></span>
								<span className={validContactLName || !contactLName ? "hide" : "invalid"}><FaExclamation /></span>
								<input
									type="text"
									id="contactName"
									className={validContactLName || !contactLName ? null : "error"}
									ref={contactNameLRef}
									autoComplete="off"
									onChange={(e) => setContactLName(e.target.value)}
									value={contactLName}
									required
									aria-invalid={validContactLName ? "false" : "true"}
									aria-describedby="contactdnote"
									onFocus={() => setContactLNameFocus(true)}
									onBlur={() => setContactLNameFocus(false)}
								/>
							</div>
							<p id="contactdnote" className={contactLNameFocus && contactLName && !validContactLName ? "instructions" : "offscreen"}>
								Invalid last name.
							</p>

							{/* Company Position */}
							<label htmlFor="contactName">Company Position<span className="required">*</span></label>
							<div className="inputWrapper">
								<span className={validBusinessPosition && businessPosition ? "valid" : "hide"}><FaCheck /></span>
								<span className={validBusinessPosition || !businessPosition ? "hide" : "invalid"}><FaExclamation /></span>
								<input
									type="text"
									id="contactName"
									className={validBusinessPosition || !businessPosition ? null : "error"}
									ref={businessPositionRef}
									autoComplete="off"
									onChange={(e) => setBusinessPosition(e.target.value)}
									required
									aria-invalid={validBusinessPosition ? "false" : "true"}
									aria-describedby="positionnote"
									onFocus={() => setBusinessPositionFocus(true)}
									onBlur={() => setBusinessPositionFocus(false)}
								/>
							</div>
							<p id="positionnote" className={businessPositionFocus && businessPosition && !validBusinessPosition ? "instructions" : "offscreen"}>
								Invalid position.
							</p>

							{/* Email */}
							<label htmlFor="email">Email:<span className="required">*</span></label>
							<div className="inputWrapper">
								<span className={validEmail && email ? "valid" : "hide"}><FaCheck /></span>
								<span className={validEmail || !email ? "hide" : "invalid"}><FaExclamation /></span>
								<input
									type="email"
									id="email"
									className={validEmail || !email ? null : "error"}
									ref={emailRef}
									autoComplete="off"
									onChange={(e) => setEmail(e.target.value)}
									required
									aria-invalid={validEmail ? "false" : "true"}
									aria-describedby="uidnote"
									onFocus={() => setEmailFocus(true)}
									onBlur={() => setEmailFocus(false)}
								/>
							</div>
							<p id="uidnote" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}>
								Invalid email address.
							</p>

							{/* Phone */}
							<label htmlFor="phone">Phone:<span className="required">*</span></label>
							<div className="inputWrapper">
								<span className={validPhone && phone ? "valid" : "hide"}><FaCheck /></span>
								<span className={validPhone || !phone ? "hide" : "invalid"}><FaExclamation /></span>
								<input
									type="tel"
									id="phone"
									className={validPhone || !phone ? null : "error"}
									ref={phoneRef}
									autoComplete="off"
									onChange={(e) => setPhone(e.target.value)}
									required
									aria-invalid={validPhone ? "false" : "true"}
									aria-describedby="phonenote"
									onFocus={() => setPhoneFocus(true)}
									onBlur={() => setPhoneFocus(false)}
								/>
							</div>
							<p id="phonenote" className={phoneFocus && phone && !validPhone ? "instructions" : "offscreen"}>
								Invalid phone number.
							</p>

							{/* Market */}
							<label htmlFor="market">Which market are you interested in?<span className="required">*</span></label>
							<div className="inputWrapper">
								<select
									id="type"
									onChange={(e) => setMarket(e.target.value)}
									value={market}
									required
									onFocus={() => setMarketFocus(true)}
									onBlur={() => setMarketFocus(false)}
								>
									<option value="">Select Market</option>
									{markets?.length ?
										markets.map((market, i) =>
											<option key={i} value={market.market_name}>{market.market_name}</option>
										)
										:
										<option value="">No markets available</option>
									}
								</select>
							</div>

							{/* Reason for contacting us */}
							<label htmlFor="reason">Reason for contacting us?<span className="required">*</span></label>
							<div className="inputWrapper">
								<select
									id="type"
									onChange={(e) => setReason(e.target.value)}
									value={reason}
									required
									onFocus={() => setReasonFocus(true)}
									onBlur={() => setReasonFocus(false)}
								>
									<option value="">Select Reason</option>
									<option value="IYH Home Pro Directory Advertising">IYH Home Pro Directory Advertising</option>
									<option value="IYH Magazine Advertising">IYH Magazine Advertising</option>
									<option value="Address Removal">Address Removal</option>
									<option value="Other">Other</option>
								</select>
							</div>

							<label htmlFor="message">Message<span className="required">*</span></label>
							<div className="inputWrapper">
								<span className={validMessage && message ? "valid" : "hide"}><FaCheck /></span>
								<span className={validMessage || !message ? "hide" : "invalid"}><FaExclamation /></span>
								<textarea
									onChange={(e) => setMessage(e.target.value)}
									name="message"
									id="message"
									rows="5"
									value={message}
									required
									aria-invalid={validMessage ? "false" : "true"}
									aria-describedby="messagenote"
									onFocus={() => setMessageFocus(true)}
									onBlur={() => setMessageFocus(false)}
								>
								</textarea>
							</div>
							<p id="messagenote" className={messageFocus && message && !validMessage ? "instructions" : "offscreen"}>
								Invalid message. Valid message includes letters, number and special characters (,.#&%!$'()/ )
							</p>
							<button disabled={!validBusinessName || !validContactFName || !validContactLName || !validEmail || !validPhone || !validMessage || !reason ? true : false}>Submit</button>
						</form>

						<div id="invite">
							<h3>Already have an invite code?</h3>
							<form onSubmit={handleCodeSubmit}>

								{/* Registration Code */}
								<label htmlFor="business">Input Invite Code Below:</label>
								<div className="inputWrapper">
									<span className={validCode && code ? "valid" : "hide"}><FaCheck /></span>
									<span className={validCode || !code ? "hide" : "invalid"}><FaExclamation /></span>
									<input
										type="text"
										id="code"
										className={validCode || !code ? null : "error"}
										ref={codeRef}
										autoComplete="off"
										onChange={(e) => setCode(e.target.value)}
										value={code}
										required
										aria-invalid={validCode ? "false" : "true"}
										aria-describedby="urlnote"
										onFocus={() => setCodeFocus(true)}
										onBlur={() => setCodeFocus(false)}
									/>
								</div>
								<p id="urlnote" className={codeFocus && code && !code ? "instructions" : "offscreen"}>
									Invalid Registration Code. Enter the 6 digit code given to you by your sales rep or from the registration invite email.
								</p>
								{codeErrMsg && <p>{codeErrMsg}</p>}
								<button disabled={!validCode ? true : false}>Sign Up</button>
							</form>
						</div>
					</>
				}
				{urlCode && !inviteData &&
					<>
						<div id="invite">
							<form onSubmit={handleCodeSubmit}>

								{/* Registration Code */}
								<label htmlFor="business">Registration Code:</label>
								<div className="inputWrapper">
									<span className={validCode && code ? "valid" : "hide"}><FaCheck /></span>
									<span className={validCode || !code ? "hide" : "invalid"}><FaExclamation /></span>
									<input
										type="text"
										id="code"
										className={validCode || !code ? null : "error"}
										ref={codeRef}
										autoComplete="off"
										onChange={(e) => setCode(e.target.value)}
										value={code}
										required
										aria-invalid={validCode ? "false" : "true"}
										aria-describedby="urlnote"
										onFocus={() => setCodeFocus(true)}
										onBlur={() => setCodeFocus(false)}
									/>
								</div>
								<p id="urlnote" className={codeFocus && code && !code ? "instructions" : "offscreen"}>
									Invalid Registration Code. Enter the 6 digit code given to you by your sales rep or from the registration invite email.
								</p>
								{codeErrMsg && <p>{codeErrMsg}</p>}
								<button disabled={!validCode ? true : false}>Sign Up</button>
							</form>
						</div>
					</>
				}



				<form onSubmit={handleSubmit}>

					{/* Home Pro Registration Fields */}
					{ type == 2002 && inviteData &&
						<>
							<label htmlFor="type">Account Type:</label>
							<div className="inputWrapper">
								<select
									id="type"
									onChange={(e) => setType(e.target.value)}
									value={type}
									required
									onFocus={() => setTypeFocus(true)}
									onBlur={() => setTypeFocus(false)}
								>
									<option value="1001">Homeowner</option>
									<option value="2002">Home Pro</option>

								</select>
							</div>

							{/* Business Name */}
							<label htmlFor="business">Business Name:</label>
							<div className="inputWrapper">
								<span className={validBusinessName && businessName ? "valid" : "hide"}><FaCheck /></span>
								<span className={validBusinessName || !businessName ? "hide" : "invalid"}><FaExclamation /></span>
								<input
									type="text"
									id="business"
									className={validBusinessName || !businessName ? null : "error"}
									ref={businessNameRef}
									autoComplete="off"
									onChange={(e) => setBusinessName(e.target.value)}
									value={businessName}
									required
									aria-invalid={validBusinessName ? "false" : "true"}
									aria-describedby="urlnote"
									onFocus={() => setBusinessNameFocus(true)}
									onBlur={() => setBusinessNameFocus(false)}
								/>
							</div>
							<p id="urlnote" className={businessNameFocus && businessName && !businessName ? "instructions" : "offscreen"}>
								Invalid Business Name.
							</p>

							{/* First Name */}
							<label htmlFor="fname">First Name:</label>
							<div className="inputWrapper">
								<span className={validFName && fName ? "valid" : "hide"}><FaCheck /></span>
								<span className={validFName || !fName ? "hide" : "invalid"}><FaExclamation /></span>
								<input
									type="text"
									id="fname"
									ref={fnameRef}
									className={validFName || !fName ? null : "error"}
									onChange={(e) => setFName(e.target.value)}
									value={fName}
									required
									aria-invalid={validFName ? "false" : "true"}
									aria-describedby="fnamenote"
									onFocus={() => setFNameFocus(true)}
									onBlur={() => setFNameFocus(false)}
								/>
							</div>
							<p id="fnamenote" className={fNameFocus && fName && !validFName ? "instructions" : "offscreen"}>
								Invalid First Name.
							</p>

							{/* Last Name */}
							<label htmlFor="lname">Last Name:</label>
							<div className="inputWrapper">
								<span className={validLName && lName ? "valid" : "hide"}><FaCheck /></span>
								<span className={validLName || !lName ? "hide" : "invalid"}><FaExclamation /></span>
								<input
									type="text"
									id="lname"
									className={validLName || !lName ? null : "error"}
									onChange={(e) => setLName(e.target.value)}
									required
									value={lName}
									aria-invalid={validLName ? "false" : "true"}
									aria-describedby="lnamenote"
									onFocus={() => setLNameFocus(true)}
									onBlur={() => setLNameFocus(false)}
								/>
							</div>
							<p id="lnamenote" className={lNameFocus && lName && !validLName ? "instructions" : "offscreen"}>
								Invalid Last Name.
							</p>


							{/* Email */}
							<label htmlFor="email">Email:</label>
							<div className="inputWrapper">
								<span className={validEmail && email ? "valid" : "hide"}><FaCheck /></span>
								<span className={validEmail || !email ? "hide" : "invalid"}><FaExclamation /></span>
								<input
									type="email"
									id="email"
									className={validEmail || !email ? null : "error"}
									ref={emailRef}
									autoComplete="off"
									onChange={(e) => setEmail(e.target.value)}
									required
									value={email}
									aria-invalid={validEmail ? "false" : "true"}
									aria-describedby="uidnote"
									onFocus={() => setEmailFocus(true)}
									onBlur={() => setEmailFocus(false)}
								/>
							</div>
							<p id="uidnote" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}>
								Invalid email address.
							</p>

							{/* Phone */}
							<label htmlFor="phone">Phone:</label>
							<div className="inputWrapper">
								<span className={validPhone && phone ? "valid" : "hide"}><FaCheck /></span>
								<span className={validPhone || !phone ? "hide" : "invalid"}><FaExclamation /></span>
								<input
									type="tel"
									id="phone"
									className={validPhone || !phone ? null : "error"}
									ref={phoneRef}
									autoComplete="off"
									onChange={(e) => setPhone(e.target.value)}
									required
									value={phone}
									aria-invalid={validPhone ? "false" : "true"}
									aria-describedby="phonenote"
									onFocus={() => setPhoneFocus(true)}
									onBlur={() => setPhoneFocus(false)}
								/>
							</div>
							<p id="phonenote" className={phoneFocus && phone && !validPhone ? "instructions" : "offscreen"}>
								Invalid phone number.
							</p>

							{/* City */}
							<label htmlFor="city">City:</label>
							<div className="inputWrapper">
								<span className={validCity && city ? "valid" : "hide"}><FaCheck /></span>
								<span className={validCity || !city ? "hide" : "invalid"}><FaExclamation /></span>
								<input
									type="text"
									id="city"
									className={validCity || !city ? null : "error"}
									ref={cityRef}
									autoComplete="off"
									onChange={(e) => setCity(e.target.value)}
									value={city}
									required
									aria-invalid={validCity ? "false" : "true"}
									aria-describedby="citynote"
									onFocus={() => setCityFocus(true)}
									onBlur={() => setCityFocus(false)}
								/>
							</div>
							<p id="citynote" className={cityFocus && city && !validCity ? "instructions" : "offscreen"}>
								Invalid City.
							</p>

							{/* State */}
							<label htmlFor="state">State:</label>
							<div className="inputWrapper">
								<select
									id="state"
									onChange={(e) => setState(e.target.value)}
									value={state}
									required
									onFocus={() => setStateFocus(true)}
									onBlur={() => setStateFocus(false)}
								>
									<option value="">Select State</option>
									<option value="AL">Alabama</option>
									<option value="AK">Alaska</option>
									<option value="AZ">Arizona</option>
									<option value="AR">Arkansas</option>
									<option value="CA">California</option>
									<option value="CO">Colorado</option>
									<option value="CT">Connecticut</option>
									<option value="DE">Delaware</option>
									<option value="DC">District Of Columbia</option>
									<option value="FL">Florida</option>
									<option value="GA">Georgia</option>
									<option value="HI">Hawaii</option>
									<option value="ID">Idaho</option>
									<option value="IL">Illinois</option>
									<option value="IN">Indiana</option>
									<option value="IA">Iowa</option>
									<option value="KS">Kansas</option>
									<option value="KY">Kentucky</option>
									<option value="LA">Louisiana</option>
									<option value="ME">Maine</option>
									<option value="MD">Maryland</option>
									<option value="MA">Massachusetts</option>
									<option value="MI">Michigan</option>
									<option value="MN">Minnesota</option>
									<option value="MS">Mississippi</option>
									<option value="MO">Missouri</option>
									<option value="MT">Montana</option>
									<option value="NE">Nebraska</option>
									<option value="NV">Nevada</option>
									<option value="NH">New Hampshire</option>
									<option value="NJ">New Jersey</option>
									<option value="NM">New Mexico</option>
									<option value="NY">New York</option>
									<option value="NC">North Carolina</option>
									<option value="ND">North Dakota</option>
									<option value="OH">Ohio</option>
									<option value="OK">Oklahoma</option>
									<option value="OR">Oregon</option>
									<option value="PA">Pennsylvania</option>
									<option value="RI">Rhode Island</option>
									<option value="SC">South Carolina</option>
									<option value="SD">South Dakota</option>
									<option value="TN">Tennessee</option>
									<option value="TX">Texas</option>
									<option value="UT">Utah</option>
									<option value="VT">Vermont</option>
									<option value="VA">Virginia</option>
									<option value="WA">Washington</option>
									<option value="WV">West Virginia</option>
									<option value="WI">Wisconsin</option>
									<option value="WY">Wyoming</option>

								</select>
							</div>

							{/* Zip */}
							<label htmlFor="zip">Zip:</label>
							<div className="inputWrapper">
								<span className={validZip && zip ? "valid" : "hide"}><FaCheck /></span>
								<span className={validZip || !zip ? "hide" : "invalid"}><FaExclamation /></span>
								<input
									type="text"
									id="zip"
									className={validZip || !zip ? null : "error"}
									ref={zipRef}
									autoComplete="off"
									onChange={(e) => setZip(e.target.value)}
									value={zip}
									required
									aria-invalid={validZip ? "false" : "true"}
									aria-describedby="zipnote"
									onFocus={() => setZipFocus(true)}
									onBlur={() => setZipFocus(false)}
								/>
							</div>
							<p id="zipnote" className={zipFocus && zip && !validZip ? "instructions" : "offscreen"}>
								Invalid Zip. Valid Format includes 55555 or 55555-5555.
							</p>

							{/* Password */}
							<label htmlFor="password">Password:</label>
							<div className="inputWrapper">
								<span className={validPwd && pwd ? "valid" : "hide"}><FaCheck /></span>
								<span className={validPwd || !pwd ? "hide" : "invalid"}><FaExclamation /></span>
								<input
									type="password"
									id="password"
									className={validPwd || !pwd ? null : "error"}
									onChange={(e) => setPwd(e.target.value)}
									value={pwd}
									required
									aria-invalid={validPwd ? "false" : "true"}
									aria-describedby="pwdnote"
									onFocus={() => setPwdFocus(true)}
									onBlur={() => setPwdFocus(false)}
								/>
							</div>
							<p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
								8 to 24 characters. Must include uppercase and lowercase letters, a number and a special character.	Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span> <span aria-label="dash">-</span>
							</p>

							{/* Password Confirmation */}
							<label htmlFor="confirm_pwd">Confirm Password:</label>
							<div className="inputWrapper">
								<span className={validMatch && matchPwd ? "valid" : "hide"}><FaCheck /></span>
								<span className={validMatch || !matchPwd ? "hide" : "invalid"}><FaExclamation /></span>
								<input
									type="password"
									id="confirm_pwd"
									className={validMatch || !matchPwd ? null : "error"}
									onChange={(e) => setMatchPwd(e.target.value)}
									value={matchPwd}
									required

								/>
							</div>
							<p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
								Must match the first password input field.
							</p>

							<div className={`options pro`}>
								<label htmlFor="options">Optional</label>
								<FormGroup>
									<FormControlLabel control={
										<Checkbox
											checked={offersAndDiscounts}
											onChange={(e) => setOffersAndDiscounts(e.target.checked)}
											inputProps={{ 'aria-label': 'controlled' }}
										/>
									} label="I'd like to receive special email offers and discounts." />
								</FormGroup>
							</div>

							<button disabled={!validEmail || !validPwd || !validMatch || !validState || !validCity || !validZip ? true : false}>Sign Up</button>

						</>
					}



					{/* Homeowner Registration Fields */}
					{ type == 1001 &&
						<>
							<label htmlFor="type">Account Type:</label>
							<div className="inputWrapper">
								<select
									id="type"
									onChange={(e) => setType(e.target.value)}
									value={type}
									required
									onFocus={() => setTypeFocus(true)}
									onBlur={() => setTypeFocus(false)}
								>
									<option value="1001">Homeowner</option>
									<option value="2002">Home Pro</option>

								</select>
							</div>

							{/* First Name */}
							<label htmlFor="fname">First Name:</label>
							<div className="inputWrapper">
								<span className={validFName && fName ? "valid" : "hide"}><FaCheck /></span>
								<span className={validFName || !fName ? "hide" : "invalid"}><FaExclamation /></span>
								<input
									type="text"
									id="fname"
									ref={fnameRef}
									className={validFName || !fName ? null : "error"}
									onChange={(e) => setFName(e.target.value)}
									required
									aria-invalid={validFName ? "false" : "true"}
									aria-describedby="fnamenote"
									onFocus={() => setFNameFocus(true)}
									onBlur={() => setFNameFocus(false)}
								/>
							</div>
							<p id="fnamenote" className={fNameFocus && fName && !validFName ? "instructions" : "offscreen"}>
								Invalid First Name.
							</p>

							{/* Last Name */}
							<label htmlFor="lname">Last Name:</label>
							<div className="inputWrapper">
								<span className={validLName && lName ? "valid" : "hide"}><FaCheck /></span>
								<span className={validLName || !lName ? "hide" : "invalid"}><FaExclamation /></span>
								<input
									type="text"
									id="lname"
									className={validLName || !lName ? null : "error"}
									onChange={(e) => setLName(e.target.value)}
									required
									aria-invalid={validLName ? "false" : "true"}
									aria-describedby="lnamenote"
									onFocus={() => setLNameFocus(true)}
									onBlur={() => setLNameFocus(false)}
								/>
							</div>
							<p id="lnamenote" className={lNameFocus && lName && !validLName ? "instructions" : "offscreen"}>
								Invalid Last Name.
							</p>


							{/* Email */}
							<label htmlFor="email">Email:</label>
							<div className="inputWrapper">
								<span className={validEmail && email ? "valid" : "hide"}><FaCheck /></span>
								<span className={validEmail || !email ? "hide" : "invalid"}><FaExclamation /></span>
								<input
									type="email"
									id="email"
									className={validEmail || !email ? null : "error"}
									ref={emailRef}
									autoComplete="off"
									onChange={(e) => setEmail(e.target.value)}
									required
									aria-invalid={validEmail ? "false" : "true"}
									aria-describedby="uidnote"
									onFocus={() => setEmailFocus(true)}
									onBlur={() => setEmailFocus(false)}
								/>
							</div>
							<p id="uidnote" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}>
								Invalid email address.
							</p>

							{/* Phone */}
							<label htmlFor="phone">Phone:</label>
							<div className="inputWrapper">
								<span className={validPhone && phone ? "valid" : "hide"}><FaCheck /></span>
								<span className={validPhone || !phone ? "hide" : "invalid"}><FaExclamation /></span>
								<input
									type="tel"
									id="phone"
									className={validPhone || !phone ? null : "error"}
									ref={phoneRef}
									autoComplete="off"
									onChange={(e) => setPhone(e.target.value)}
									required
									aria-invalid={validPhone ? "false" : "true"}
									aria-describedby="phonenote"
									onFocus={() => setPhoneFocus(true)}
									onBlur={() => setPhoneFocus(false)}
								/>
							</div>
							<p id="phonenote" className={phoneFocus && phone && !validPhone ? "instructions" : "offscreen"}>
								Invalid phone number.
							</p>

							{/* Zip */}
							<label htmlFor="zip">Zip:</label>
							<div className="inputWrapper">
								<span className={validZip && zip ? "valid" : "hide"}><FaCheck /></span>
								<span className={validZip || !zip ? "hide" : "invalid"}><FaExclamation /></span>
								<input
									type="text"
									id="zip"
									className={validZip || !zip ? null : "error"}
									ref={zipRef}
									autoComplete="off"
									onChange={(e) => setZip(e.target.value)}
									required
									aria-invalid={validZip ? "false" : "true"}
									aria-describedby="zipnote"
									onFocus={() => setZipFocus(true)}
									onBlur={() => setZipFocus(false)}
								/>
							</div>
							<p id="zipnote" className={zipFocus && zip && !validZip ? "instructions" : "offscreen"}>
								Invalid Zip. Valid Format includes 55555 or 55555-5555.
							</p>

							{/* Password */}
							<label htmlFor="password">Password:</label>
							<div className="inputWrapper">
								<span className={validPwd && pwd ? "valid" : "hide"}><FaCheck /></span>
								<span className={validPwd || !pwd ? "hide" : "invalid"}><FaExclamation /></span>
								<input
									type="password"
									id="password"
									className={validPwd || !pwd ? null : "error"}
									onChange={(e) => setPwd(e.target.value)}
									value={pwd}
									required
									aria-invalid={validPwd ? "false" : "true"}
									aria-describedby="pwdnote"
									onFocus={() => setPwdFocus(true)}
									onBlur={() => setPwdFocus(false)}
								/>
							</div>
							<p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
								8 to 24 characters. Must include uppercase and lowercase letters, a number and a special character.	Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span> <span aria-label="dash">-</span>
							</p>

							{/* Password Confirmation */}
							<label htmlFor="confirm_pwd">Confirm Password:</label>
							<div className="inputWrapper">
								<span className={validMatch && matchPwd ? "valid" : "hide"}><FaCheck /></span>
								<span className={validMatch || !matchPwd ? "hide" : "invalid"}><FaExclamation /></span>
								<input
									type="password"
									id="confirm_pwd"
									className={validMatch || !matchPwd ? null : "error"}
									onChange={(e) => setMatchPwd(e.target.value)}
									value={matchPwd}
									required

								/>
							</div>
							<p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
								Must match the first password input field.
							</p>

							{/* Agreements  & Options */}
							<div className="agreements">
								<label htmlFor="agreements">Agreements (Required)</label>
								<FormGroup>
									<FormControlLabel
										control={
											<Checkbox
												checked={agreeToAllTermsAndConditions}
												onChange={(e) =>
													setAgreeToAllTermsAndConditions(
														e.target.checked
													)
												}
												inputProps={{ "aria-label": "controlled" }}
											/>
										}
										label="Agree to All"
									/>
								</FormGroup>
								<PopupAgreement title="Terms of Use" slug="terms-of-use" required="true" isAgreeToAllChecked={agreeToAllTermsAndConditions}/>
								<PopupAgreement title="Membership Agreement" slug="membership-agreement" required="true" isAgreeToAllChecked={agreeToAllTermsAndConditions}/>
								<PopupAgreement title="Project Submission Customer Agreement" slug="project-submission-customer-agreement" required="true" isAgreeToAllChecked={agreeToAllTermsAndConditions}/>
								<PopupAgreement title="Privacy Policy" slug="privacy-policy" required="true" isAgreeToAllChecked={agreeToAllTermsAndConditions}/>
							</div>

							<div className="options">
								<label htmlFor="options">Optional</label>
								<FormGroup>
									<FormControlLabel control={
										<Checkbox
											checked={offersAndDiscounts}
											onChange={(e) => setOffersAndDiscounts(e.target.checked)}
											inputProps={{ 'aria-label': 'controlled' }}
										/>
									} label="I'd like to receive special email offers and discounts." />
								</FormGroup>
							</div>

							<button disabled={!validEmail || !validPwd || !validMatch || !validZip || !validFName || !validLName || !validPhone ? true : false}>Sign Up</button>
						</>
					}

				</form>

				<p>
					Already signed up?<br />
					<span className="line">
						<Link to="/login">Sign In</Link>
					</span>
				</p>
			</article>
		</section>
		)}
	</>

	)
}

export default Register