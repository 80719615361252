import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useRefreshToken from "../hooks/useRefreshToken";
import { AiFillDelete } from "react-icons/ai";
import { MdVisibility } from "react-icons/md";
import { MdOutlineEdit } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import { FaExclamation } from "react-icons/fa";
import { MdPersonAdd } from "react-icons/md";
import { MdClose } from "react-icons/md";
import { MdBlock } from "react-icons/md";
import { MdStar } from "react-icons/md";
import { MdAddCircleOutline } from "react-icons/md";
import { MdOutlineLockOpen } from "react-icons/md";
import { MdOutlineLock } from "react-icons/md";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { IoMdCloseCircleOutline } from "react-icons/io";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip from "@mui/material/Tooltip";
import { ToastContainer, toast } from "react-toastify";
import Loader from "./Loader";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FileDrop from "../components/FileDrop";
import FileDropEmbed from "../components/FileDropEmbed";
import PortfolioDropEmbed from "../components/PortfolioDropEmbed";
import CADropEmbed from "../components/CADropEmbed";
import CoverDropEmbed from "../components/CoverDropEmbed";
import SingleFileDropEmbed from "../components/SingleFileDropEmbed";
import VideoDropEmbed from "../components/VideoDropEmbed";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import AdminCreateUserPromotion from "./AdminCreateUserPromotion";
import Divider from "@mui/material/Divider";
import AdminAccountConfigure from "../routes/admin/Subscription/AdminAccountConfigure";
import useAdminFormContext from "../hooks/useAdminFormContext";

const states = [
  {
    State: "Alabama",
    Code: "AL",
  },
  {
    State: "Alaska",
    Code: "AK",
  },
  {
    State: "Arizona",
    Code: "AZ",
  },
  {
    State: "Arkansas",
    Code: "AR",
  },
  {
    State: "California",
    Code: "CA",
  },
  {
    State: "Colorado",
    Code: "CO",
  },
  {
    State: "Connecticut",
    Code: "CT",
  },
  {
    State: "Delaware",
    Code: "DE",
  },
  {
    State: "District of Columbia",
    Code: "DC",
  },
  {
    State: "Florida",
    Code: "FL",
  },
  {
    State: "Georgia",
    Code: "GA",
  },
  {
    State: "Hawaii",
    Code: "HI",
  },
  {
    State: "Idaho",
    Code: "ID",
  },
  {
    State: "Illinois",
    Code: "IL",
  },
  {
    State: "Indiana",
    Code: "IN",
  },
  {
    State: "Iowa",
    Code: "IA",
  },
  {
    State: "Kansas",
    Code: "KS",
  },
  {
    State: "Kentucky",
    Code: "KY",
  },
  {
    State: "Louisiana",
    Code: "LA",
  },
  {
    State: "Maine",
    Code: "ME",
  },
  {
    State: "Maryland",
    Code: "MD",
  },
  {
    State: "Massachusetts",
    Code: "MA",
  },
  {
    State: "Michigan",
    Code: "MI",
  },
  {
    State: "Minnesota",
    Code: "MN",
  },
  {
    State: "Mississippi",
    Code: "MS",
  },
  {
    State: "Missouri",
    Code: "MO",
  },
  {
    State: "Montana",
    Code: "MT",
  },
  {
    State: "Nebraska",
    Code: "NE",
  },
  {
    State: "Nevada",
    Code: "NV",
  },
  {
    State: "New Hampshire",
    Code: "NH",
  },
  {
    State: "New Jersey",
    Code: "NJ",
  },
  {
    State: "New Mexico",
    Code: "NM",
  },
  {
    State: "New York",
    Code: "NY",
  },
  {
    State: "North Carolina",
    Code: "NC",
  },
  {
    State: "North Dakota",
    Code: "ND",
  },
  {
    State: "Ohio",
    Code: "OH",
  },
  {
    State: "Oklahoma",
    Code: "OK",
  },
  {
    State: "Oregon",
    Code: "OR",
  },
  {
    State: "Pennsylvania",
    Code: "PA",
  },
  {
    State: "Rhode Island",
    Code: "RI",
  },
  {
    State: "South Carolina",
    Code: "SC",
  },
  {
    State: "South Dakota",
    Code: "SD",
  },
  {
    State: "Tennessee",
    Code: "TN",
  },
  {
    State: "Texas",
    Code: "TX",
  },
  {
    State: "Utah",
    Code: "UT",
  },
  {
    State: "Vermont",
    Code: "VT",
  },
  {
    State: "Virginia",
    Code: "VA",
  },
  {
    State: "Washington",
    Code: "WA",
  },
  {
    State: "West Virginia",
    Code: "WV",
  },
  {
    State: "Wisconsin",
    Code: "WI",
  },
  {
    State: "Wyoming",
    Code: "WY",
  },
];

/* REGEX Verification for inputs */
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const NAME_REGEX = /^[a-zA-Z ]{2,23}$/;
const CITY_REGEX = /^[a-zA-Z ]{2,50}$/;
const ZIPCODE_REGEX = /^\d{5}(?:[-\s]\d{4})?$/;
const BUSINESS_REGEX = /[^\s]{1,100}$/;
const PHONE_REGEX =
  /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
const URL_REGEX =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
const LICENSE_REGEX = /^[a-zA-Z0-9\#\-\_ ]{2,50}$/;
//const SPECIALTY_REGEX = /^[a-zA-Z0-9-_/,#& ]{1,50}$/;
const SPECIALTY_REGEX = /^[a-zA-Z0-9-_/,#&\s\$@%\^&\*!\(\)=\+\|\\;\:"'\.\?>< ]{1,50}$/;
const ADDRESS_REGEX = /^[a-zA-Z0-9-_/:#.&*, ]{1,100}$/;

const FACEBOOK_REGEX =
  /^(https:\/\/www\.facebook\.com)\/?([a-zA-Z0-9-_@.])|null/;
const INSTAGRAM_REGEX =
  /^(https:\/\/www\.instagram\.com)\/?([a-zA-Z0-9-_@.])|null/;
const TWITTER_REGEX = /^(https:\/\/twitter\.com)\/?([a-zA-Z0-9-_@.])|null/;
const LINKEDIN_REGEX =
  /^(https:\/\/www\.linkedin\.com)\/?([a-zA-Z0-9-_@.])|null/;
const YELP_REGEX = /^(https:\/\/www\.yelp\.com)\/?([a-zA-Z0-9-_@.])|null/;
const GOOGLE_REGEX =
  /^(https:\/\/(?:www\.)?((goo\.gl)|(google\.com)))\/?([a-zA-Z0-9])/;

const CALLCAP_REGEX = /^\d{10}$/;

const YOUTUBE_REGEX =
  /^(https:\/\/(?:www\.)?youtube\.com)\/?(([a-zA-Z0-9]{11})|(shorts\/[a-zA-Z0-9_-]{11})|(watch\?v=[a-zA-Z0-9-_]{11})|(watch\?v=[a-zA-Z0-9-_]{11}(\&[a-zA-Z0-9]{1,50}\=[a-zA-Z0-9]{1,50})))$/;
const VIMEO_REGEX = /^(https:\/\/(?:www\.)?vimeo\.com)\/?(([0-9]{9}))$/;
const FACEBOOK_VIDEO_REGEX =
  /^(https:\/\/(?:www\.)?facebook\.com)\/?([a-zA-Z0-9]{1,50}\/videos\/[0-9]{15})$/;

const YOUTUBE_VALUDATION_TEXT =
  "Valid YouTube formats are 'https://www.youtube.com/shorts/XXXXXXXXXXX', 'https://www.youtube.com/XXXXXXXXXXX', or 'https://www.youtube.com/watch?v=XXXXXXXXXXX'";
const VIMEO_VALUDATION_TEXT =
  "Valid Vimeo formats are 'https://vimeo.com/XXXXXXXXX'";
const FACEBOOK_VALUDATION_TEXT =
  "Valid Facebook Video formats are 'https://www.facebook.com/XXXXXXXXXX/videos/XXXXXXXXXXXXXX'";

const NO_PROVIDER_VALIDATION_TEXT = " No provider selected!";

const PROVIDER_LIST = [
  {
    Name: "Facebook",
    Value: "facebook",
  },
  {
    Name: "Vimeo",
    Value: "vimeo",
  },
  {
    Name: "YouTube",
    Value: "youtube",
  },
];

const Users = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState();
  const [filteredUsers, setFilteredUsers] = useState();
  
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const onSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        const sortedData = [...filteredUsers].sort((a, b) => {
          if (key === 'name') {
              if (a.business_name !== b.business_name) {
                  return (a.business_name < b.business_name ? -1 : 1) * (direction === 'ascending' ? 1 : -1);
              }
              if (a.first_name !== b.first_name) {
                  return (a.first_name < b.first_name ? -1 : 1) * (direction === 'ascending' ? 1 : -1);
              }
              return (a.last_name < b.last_name ? -1 : 1) * (direction === 'ascending' ? 1 : -1);
          } else {
              if (a[key] < b[key]) {
                  return direction === 'ascending' ? -1 : 1;
              }
              if (a[key] > b[key]) {
                  return direction === 'ascending' ? 1 : -1;
              }
              return 0;
          }
        });
        setFilteredUsers(sortedData);
        setSortConfig({ key, direction });
  };

  const axiosPrivate = useAxiosPrivate();
  const refresh = useRefreshToken();

  const params = new URLSearchParams(window.location.search);
  const usersID = params.get("id");

  const navigate = useNavigate();
  const location = useLocation();

  const auth = useAuth();
  const role = auth?.role;
  const id = auth?.id;
  const userID = auth?.user?.id;

  const addEmailRef = useRef();
  const profileEmailRef = useRef();

  const [deleteName, setDeleteName] = useState();
  const [deleteID, setDeleteID] = useState();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEditSubscription, setOpenEditSubscription] = useState(false);

  const [openBlockUserLogin, setOpenBlockUserLogin] = useState(false);
  const [openBlockUserReview, setOpenBlockUserReview] = useState(false);
  const [blockLoginName, setBlockLoginName] = useState();
  const [blockLoginID, setBlockLoginID] = useState();
  const [blockLoginIndex, setBlockLoginIndex] = useState();
  const [blockedLoginState, setBlockedLoginState] = useState();
  const [blockReviewName, setBlockReviewName] = useState();
  const [blockReviewID, setBlockReviewID] = useState();
  const [blockReviewIndex, setBlockReviewIndex] = useState();
  const [blockedReviewState, setBlockedReviewState] = useState();

  const [editName, setEditName] = useState();
  const [editID, setEditID] = useState();
  const [editRole, setEditRole] = useState();
  const [editEmail, setEditEmail] = useState();

  const [addName, setAddName] = useState();
  const [addID, setAddID] = useState();
  const [addRole, setAddRole] = useState();
  const [addEmail, setAddEmail] = useState();

  const [type, setType] = useState("1001"); //sets default account type to homeowner
  const [validType, setValidType] = useState(false);
  const [typeFocus, setTypeFocus] = useState(false);

  const [addType, setAddType] = useState("1001"); //sets default account type to homeowner
  const [validAddType, setValidAddType] = useState(false);
  const [addTypeFocus, setAddTypeFocus] = useState(false);

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [profileEmail, setProfileEmail] = useState();
  const [validProfileEmail, setValidProfileEmail] = useState(false);
  const [profileEmailFocus, setProfileEmailFocus] = useState(false);

  const [validAddEmail, setValidAddEmail] = useState(false);
  const [addEmailFocus, setAddEmailFocus] = useState(false);

  const [filterUserType, setFilterUserType] = useState("all");
  const [filterSearch, setFilterSearch] = useState("");

  const [deleteImages, setDeleteImages] = useState([]);
  const [deleteVideos, setDeleteVideos] = useState([]);
  const [deleteCAImages, setDeleteCAImages] = useState([]);

  const [addEditUser, setAddEditUser] = useState();
  const [dataEditUser, setDataEditUser] = useState();
  
  // useEffect(() => {
  //   console.log("dataEditUser", dataEditUser)
  // },[dataEditUser])

  const [proSpotlight, setProSpotlight] = useState();
  const [priorityListing, setPriorityListing] = useState();

  const emailRef = useRef();
  const errRef = useRef();
  const phoneRef = useRef();
  const callcapRef = useRef();
  const urlRef = useRef();
  const businessNameRef = useRef();
  const businessLicenseRef = useRef();
  const cityRef = useRef();
  const stateRef = useRef();
  const zipRef = useRef();
  const addressRef = useRef();
  const descriptionRef = useRef();
  const aboutRef = useRef();
  const certificatesRef = useRef();
  const facebookRef = useRef();
  const twitterRef = useRef();
  const instagramRef = useRef();
  const linkedinRef = useRef();
  const yelpRef = useRef();
  const googleRef = useRef();
  const nameRef = useRef();
  const detailsRef = useRef();

  const [files, setFiles] = useState([]);
  const [portfolio, setPortfolio] = useState([]);
  const [caFile, setCAFile] = useState([]);
  const [videos, setVideos] = useState([]);
  const [editImages, setEditImages] = useState();
  const [portfolioImages, setPortfolioImages] = useState(
    auth?.user?.portfolio || ""
  );
  const [caImages, setCAImages] = useState(auth?.user?.ca_files || "");
  const [portfolioVideos, setPortfolioVideos] = useState(
    auth?.user?.videos || ""
  );
  const [validFiles, setValidFiles] = useState(false);
  const [validCA, setValidCA] = useState(false);
  const [validVideos, setValidVideos] = useState(false);
  const [validPortfolio, setValidPortfolio] = useState(false);
  const [fileLimit, setFileLimit] = useState(1);
  const [coverLimit, setCoverLimit] = useState(1);
  const [videoLimit, setVideoLimit] = useState(3);
  const [portfolioLimit, setPortfolioLimit] = useState(10);
  const [caLimit, setCALimit] = useState(10);
  const [buttonTitle, setButtonTitle] = useState(
    type == 2002 ? "Logo" : "Profile Picture"
  );
  const [portfolioButtonTitle, setPortfolioButtonTitle] =
    useState("Portfolio Pictures");
  const [caButtonTitle, setCAButtonTitle] = useState(
    "Certificate & Awards Images"
  );
  const [videoButtonTitle, setVideoButtonTitle] = useState("Videos");

  const [businessSlug, setBusinessSlug] = useState(auth?.user?.slug);

  const [businessName, setBusinessName] = useState(
    auth?.user?.business_name || ""
  );
  const [validBusinessName, setValidBusinessName] = useState(false);
  const [businessNameFocus, setBusinessNameFocus] = useState(false);

  const [address, setAddress] = useState(auth?.user?.address || "");
  const [validAddress, setValidAddress] = useState(false);
  const [addressFocus, setAddressFocus] = useState(false);

  const [businessLicense, setBusinessLicense] = useState(
    auth?.user?.license_number || ""
  );
  const [validBusinessLicense, setValidBusinessLicense] = useState(false);
  const [businessLicenseFocus, setBusinessLicenseFocus] = useState(false);

  const [businessSpecialty, setBusinessSpecialty] = useState(
    auth?.user?.contractor_specialty || ""
  );
  const [validBusinessSpecialty, setValidBusinessSpecialty] = useState(false);
  const [businessSpecialtyFocus, setBusinessSpecialtyFocus] = useState(false);

  const [profileImage, setProfileImage] = useState(auth?.user?.profile_picture);
  const [profileCover, setProfileCover] = useState(auth?.user?.cover_picture);

  const [fName, setFName] = useState(auth?.user?.first_name);
  const [validFName, setValidFName] = useState(false);
  const [fNameFocus, setFNameFocus] = useState(false);

  const [lName, setLName] = useState(auth?.user?.last_name);
  const [validLName, setValidLName] = useState(false);
  const [lNameFocus, setLNameFocus] = useState(false);

  const [phone, setPhone] = useState(auth?.user?.phone);
  const [validPhone, setValidPhone] = useState(false);
  const [phoneFocus, setPhoneFocus] = useState(false);

  const [callcap, setCallcap] = useState();
  const [validCallcap, setValidCallcap] = useState(false);
  const [callcapFocus, setCallcapFocus] = useState(false);

  const [url, setUrl] = useState(auth?.user?.business_website || "");
  const [validUrl, setValidUrl] = useState(false);
  const [urlFocus, setUrlFocus] = useState(false);

  const [city, setCity] = useState(auth?.user?.city);
  const [validCity, setValidCity] = useState(false);
  const [cityFocus, setCityFocus] = useState(false);

  const [state, setState] = useState(auth?.user?.state);
  const [validState, setValidState] = useState(false);
  const [stateFocus, setStateFocus] = useState(false);

  const [zip, setZip] = useState(auth?.user?.zip);
  const [validZip, setValidZip] = useState(false);
  const [zipFocus, setZipFocus] = useState(false);

  const [description, setDescription] = useState(
    auth?.user?.business_description || ""
  );
  const [validDescription, setValidDescription] = useState(true);

  const [about, setAbout] = useState(auth?.user?.business_about || "");
  const [validAbout, setValidAbout] = useState(true);

  const [certificates, setCertificates] = useState(
    auth?.user?.business_certs_awards || ""
  );
  const [validCertificates, setValidCertificates] = useState(true);

  const [social, setSocial] = useState(auth?.user?.social_links);

  const [facebook, setFacebook] = useState("");
  const [validFacebook, setValidFacebook] = useState(false);
  const [facebookFocus, setFacebookFocus] = useState(false);

  const [yelp, setYelp] = useState("");
  const [validYelp, setValidYelp] = useState(false);
  const [yelpFocus, setYelpFocus] = useState(false);

  const [linkedin, setLinkedin] = useState("");
  const [validLinkedin, setValidLinkedin] = useState(false);
  const [linkedinFocus, setLinkedinFocus] = useState(false);

  const [twitter, setTwitter] = useState("");
  const [validTwitter, setValidTwitter] = useState(false);
  const [twitterFocus, setTwitterFocus] = useState(false);

  const [instagram, setInstagram] = useState("");
  const [validInstagram, setValidInstagram] = useState(false);
  const [instagramFocus, setInstagramFocus] = useState(false);

  const [google, setGoogle] = useState("");
  const [validGoogle, setValidGoogle] = useState(false);
  const [googleFocus, setGoogleFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  const [cover, setCover] = useState([]);
  const [validCover, setValidCover] = useState(false);

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [userVerified, setUserVerified] = useState();

  const [totalPortfolioImageCount, setTotalPortfolioImageCount] = useState();
  const [totalVideoCount, setTotalVideoCount] = useState();
  const [totalCAImageCount, setTotalCAImageCount] = useState();

  const video1Ref = useRef();
  const video2Ref = useRef();
  const video3Ref = useRef();

  const [externalURL1, setExternalURL1] = useState(
    auth?.user?.ext_video_url_1 || ""
  );
  const [externalURL2, setExternalURL2] = useState(
    auth?.user?.ext_video_url_2 || ""
  );
  const [externalURL3, setExternalURL3] = useState(
    auth?.user?.ext_video_url_3 || ""
  );

  const [validExternalURL1, setValidExternalURL1] = useState(false);
  const [validExternalURL2, setValidExternalURL2] = useState(false);
  const [validExternalURL3, setValidExternalURL3] = useState(false);

  const [provider1, setProvider1] = useState(
    auth?.user?.ext_video_provider_1 || ""
  );
  const [provider2, setProvider2] = useState(
    auth?.user?.ext_video_provider_2 || ""
  );
  const [provider3, setProvider3] = useState(
    auth?.user?.ext_video_provider_3 || ""
  );

  const [contactVisibility, setContactVisibility] = useState(
    auth?.user?.contact_visibility || true
  );

  const [externalURL1Focus, setExternalURL1Focus] = useState(false);
  const [externalURL2Focus, setExternalURL2Focus] = useState(false);
  const [externalURL3Focus, setExternalURL3Focus] = useState(false);

  const [promotions, setPromotions] = useState();

  const [openCreatePromotion, setOpenCreatePromotion] = useState(false);
  const [openDeletePromotion, setOpenDeletePromotion] = useState(false);
  const [openEditPromotion, setOpenEditPromotion] = useState(false);

  const [deletePromotionName, setDeletePromotionName] = useState();
  const [deletePromotionID, setDeletePromotionID] = useState();

  const [promotionName, setPromotionName] = useState();
  const [promotionDetails, setPromotionDetails] = useState();
  const [promotionStatus, setPromotionStatus] = useState();
  const [promotionFiles, setPromotionFiles] = useState([]);

  const [editPromotionID, setEditPromotionID] = useState();
  const [editDetails, setEditDetails] = useState();
  const [editStatus, setEditStatus] = useState();
  const [editImage, setEditImage] = useState();
  const [editStatusFocus, setEditStatusFocus] = useState(false);

  const [validEditName, setValidEditName] = useState(true);
  const [nameFocus, setNameFocus] = useState(false);

  const [validEditDetails, setValidEditDetails] = useState(true);
  const [detailsFocus, setDetailsFocus] = useState(false);

  const [subscriptionStatus, setSubscriptionStatus] = useState();
  const [subscriptionDetails, setSubscriptionDetails] = useState();
  const [subCategories, setSubCategories] = useState([]);
  const [subExcludedZips, setSubExcludedZips] = useState([]);
  const [subActiveState, setSubActiveState] = useState([]);
  const [subActiveCounties, setSubActiveCounties] = useState([]);
  const [subOptions, setSubOptions] = useState([]);
  const [subPackageDetails, setSubPackageDetails] = useState();
  const [subMaxPaidCategories, setSubMaxPaidCategories] = useState();

  // useEffect(() => {console.log("subMaxPaidCategories",subMaxPaidCategories)} , [subMaxPaidCategories])

  const [openConfirmDeleteCounty, setOpenConfirmDeleteCounty] = useState(false);
  const [openConfirmDeleteCategory, setOpenConfirmDeleteCategory] =
    useState(false);
  const [openConfirmDeleteZipcode, setOpenConfirmDeleteZipcode] =
    useState(false);
  const [openAddCounty, setOpenAddCounty] = useState(false);
  const [openAddCategory, setOpenAddCategory] = useState(false);
  const [openAddZipExclusion, setOpenAddZipExclusion] = useState(false);

  const [deleteSubCategory, setDeleteSubCategory] = useState();
  const [deleteSubCounty, setDeleteSubCounty] = useState();
  const [deleteSubZip, setDeleteSubZip] = useState();

  const [loadComplete, setLoadComplete] = useState(false);

  const [overrideCallCap, setOverrideCallCap] = useState(true);

  const [subscriptionData, setSubscriptionData] = useState({
	})
  const [config, setConfig] = useState()

  const [selectedState, setSelectedState] = useState()
  const [stateZips, setStateZips] = useState()
  const [dataUpdated, setDataUpdated] = useState()
  const [activePackages, setActivePackages] = useState()
  const [currentAllowedServices, setCurrentAllowedServices] = useState()
  const [currentMaxAllowedServices, setCurrentMaxAllowedServices] = useState()  

  useEffect(() => {
		const controller = new AbortController();
		// setLoading(true);
		const getActivePackages = async () => {
			try {
				const response = await axiosPrivate.get('contractor-packages', {
					signal: controller.signal
				});
				setActivePackages(response.data);
				// setLoading(false)
			} catch(err) {
				console.log(err.message);
			}
		}

		getActivePackages();

		return () => controller.abort();
		// eslint-disable-next-line
	},[])

  useEffect(() => {
    if (subPackageDetails?.num_allowed_services && (activePackages && activePackages?.length && activePackages[0]?.num_allowed_services)) {
      if (activePackages[0]?.num_allowed_services > subPackageDetails?.num_allowed_services) {
        setCurrentAllowedServices(activePackages[0]?.num_allowed_services)
      } else {
        setCurrentAllowedServices(subPackageDetails?.num_allowed_services)
      }
    }
  }, [activePackages, subPackageDetails])

  useEffect(() => {
    if (subPackageDetails?.num_allowed_services && (activePackages && activePackages?.length && activePackages[0]?.num_allowed_services)) {
      if (activePackages[0]?.num_max_allowed_services === -1 || subPackageDetails?.num_max_allowed_services === -1) {
        setCurrentMaxAllowedServices(-1)
      } else if (activePackages[0]?.num_max_allowed_services > subPackageDetails?.num_max_allowed_services) {
        setCurrentMaxAllowedServices(activePackages[0]?.num_max_allowed_services)
      } else {
        setCurrentMaxAllowedServices(subPackageDetails?.num_allowed_services)
      }
    }
  }, [activePackages, subPackageDetails])

  useEffect(() => {
    if (state) {
      const currentState = states?.find(currState => {
        return currState.Code === state;
      });
      // console.log("currentState", currentState)
      if (currentState) {
        setSelectedState(currentState.State)
      }
    }

  }, [state])

  useEffect(() => {
    if (selectedState && subscriptionData) {
      const currStateZips = subscriptionData?.excludes?.filter(zip => { return zip.state === selectedState})
      const filteredZips = currStateZips?.filter(zip => { return subActiveCounties.includes(zip.county)})
      // const uniqueZips = filteredZips?.filter((zip, i) => { return filteredZips?.indexOf(zip) === i})
      const uniqueZips = filteredZips.reduce((accumulator, current) => {
        if (!accumulator.find((zip) => zip?.zipcode === current?.zipcode)) {
          accumulator.push(current);
        }
        return accumulator;
      }, []);
      setStateZips(uniqueZips)
    }
  },[selectedState])

  const [openAdminForm, setOpenAdminForm] = useState();

  const [allUserData, setAllUserData] = useState();

  // useEffect(() => {console.log("subscriptionDetails", subscriptionDetails)}, [subscriptionDetails])

  /* Check password against regex function on password change */
  useEffect(() => {
    const result = PWD_REGEX.test(pwd);
    setValidPwd(result);
    const match = pwd === matchPwd;
    setValidMatch(match);
  }, [pwd, matchPwd]);

  /* Check username against regex function on username change */
  useEffect(() => {
    const result = EMAIL_REGEX.test(email);
    setValidEmail(result);
  }, [email]);

  // Check Profile Email
  useEffect(() => {
    const result = EMAIL_REGEX.test(profileEmail);
    setValidProfileEmail(result);
  }, [profileEmail]);

  /* Check Business Name */
  useEffect(() => {
    const result = BUSINESS_REGEX.test(businessName);
    setValidBusinessName(result);
    validBusinessName && setBusinessSlug(convertToSlug(businessName));
  }, [businessName]);

  /* Check Business License */
  useEffect(() => {
    const result = LICENSE_REGEX.test(businessLicense);
    setValidBusinessLicense(result);
  }, [businessLicense]);

  /* Check Business License */
  useEffect(() => {
    const result = SPECIALTY_REGEX.test(businessSpecialty);
    setValidBusinessSpecialty(result);
  }, [businessSpecialty]);

  /* Check First Name */
  useEffect(() => {
    const result = NAME_REGEX.test(fName);
    setValidFName(result);
  }, [fName]);

  /* Check Last Name */
  useEffect(() => {
    const result = NAME_REGEX.test(lName);
    setValidLName(result);
  }, [lName]);

  /* Check Phone */
  useEffect(() => {
    const result = PHONE_REGEX.test(phone);
    setValidPhone(result);
  }, [phone]);

  /* Check Callcap */
  useEffect(() => {
    const result = CALLCAP_REGEX.test(callcap);
    setValidCallcap(result);
  }, [callcap]);

  /* Check URL */
  useEffect(() => {
    const result = URL_REGEX.test(url);
    setValidUrl(result);
  }, [url]);

  /* Check City */
  useEffect(() => {
    const result = CITY_REGEX.test(city);
    setValidCity(result);
  }, [city]);

  /* Check State */
  useEffect(() => {
    state !== "" || auth?.user?.role == 1001
      ? setValidState(true)
      : setValidState(false);
  }, [state]);

  /* Check Zip */
  useEffect(() => {
    const result = ZIPCODE_REGEX.test(zip);
    setValidZip(result);
  }, [zip]);

  /* Check Address */
  useEffect(() => {
    const result = ADDRESS_REGEX.test(address);
    setValidAddress(result);
  }, [address]);

  /* Check URL */
  useEffect(() => {
    const result = URL_REGEX.test(url);
    setValidUrl(result);
  }, [url]);

  // Check Description
  useEffect(() => {
    setValidDescription(description?.length > 999 ? false : true);
  }, [description]);

  // Check About
  useEffect(() => {
    setValidDescription(about?.length > 999 ? false : true);
  }, [about]);

  // Check Certificates
  useEffect(() => {
    setValidCertificates(certificates?.length > 254 ? false : true);
  }, [certificates]);

  /* Check Facebook */
  useEffect(() => {
    if (facebook !== "") {
      const result = FACEBOOK_REGEX.test(facebook);
      setValidFacebook(result);
    } else {
      setValidFacebook(true);
    }
  }, [facebook]);

  /* Check Instagram */
  useEffect(() => {
    if (instagram !== "") {
      const result = INSTAGRAM_REGEX.test(instagram);
      setValidInstagram(result);
    } else {
      setValidInstagram(true);
    }
  }, [instagram]);

  /* Check Twitter */
  useEffect(() => {
    if (twitter !== "") {
      const result = TWITTER_REGEX.test(twitter);
      setValidTwitter(result);
    } else {
      setValidTwitter(true);
    }
  }, [twitter]);

  /* Check LinkedIn */
  useEffect(() => {
    if (linkedin !== "") {
      const result = LINKEDIN_REGEX.test(linkedin);
      setValidLinkedin(result);
    } else {
      setValidLinkedin(true);
    }
  }, [linkedin]);

  /* Check Yelp */
  useEffect(() => {
    if (yelp !== "") {
      const result = YELP_REGEX.test(yelp);
      setValidYelp(result);
    } else {
      setValidYelp(true);
    }
  }, [yelp]);

  /* Check Google */
  useEffect(() => {
    if (google !== "") {
      const result = GOOGLE_REGEX.test(google);
      setValidGoogle(result);
    } else {
      setValidGoogle(true);
    }
  }, [google]);

  /* Clear Error message on any change of user or password */
  useEffect(() => {
    setErrMsg("");
  }, [email]);

  useEffect(() => {
    const currentCount = files?.length;
    const addCount = editImages?.length;
    const count = currentCount + addCount;

    if (fileLimit) {
      count <= fileLimit ? setValidFiles(true) : setValidFiles(false);
    }

    const formData = new FormData();
    files?.map((file, index) => {
      formData.append("files[]", file);
    });
  }, [files]);

  useEffect(() => {
    const currentCount = caFile?.length;
    const addCount = caImages?.length;
    const count = currentCount + addCount;
    setTotalCAImageCount(count);

    if (caLimit) {
      count <= caLimit ? setValidCA(true) : setValidCA(false);
    }

    const formData = new FormData();
    caFile?.map((file, index) => {
      formData.append("ca_files[]", file);
    });
  }, [caFile]);

  useEffect(() => {
    const currentCount = portfolio?.length;
    const addCount = portfolioImages?.length;
    const count = currentCount + addCount;
    setTotalPortfolioImageCount(count);

    if (portfolioLimit) {
      count <= portfolioLimit
        ? setValidPortfolio(true)
        : setValidPortfolio(false);
    }

    const formData = new FormData();
    portfolio?.map((file, index) => {
      formData.append("portfolio[]", file);
    });
  }, [portfolio]);

  useEffect(() => {
    const currentCount = cover?.length;
    const addCount = profileCover?.length;
    const count = currentCount + addCount;

    if (coverLimit) {
      count <= coverLimit ? setValidCover(true) : setValidCover(false);
    }

    const formData = new FormData();
    cover?.map((file, index) => {
      formData.append("cover[]", file);
    });
  }, [cover]);

  useEffect(() => {
    const currentCount = videos?.length;
    const addCount = portfolioVideos?.length;
    const count = currentCount + addCount;
    setTotalVideoCount(count);

    if (videoLimit) {
      count <= videoLimit ? setValidVideos(true) : setValidVideos(false);
    }

    const formData = new FormData();
    videos?.map((file, index) => {
      formData.append("videos[]", file);
    });
  }, [videos]);

  useEffect(() => {
    let result = false;
    if (provider1 == "youtube") {
      result = YOUTUBE_REGEX.test(externalURL1);
    }
    if (provider1 == "vimeo") {
      result = VIMEO_REGEX.test(externalURL1);
    }
    if (provider1 == "facebook") {
      result = FACEBOOK_VIDEO_REGEX.test(externalURL1);
    }

    if (provider1) {
      setValidExternalURL1(result);
    }
    if (result && provider1) {
      // Add
    } else {
      // Delete
    }
  }, [externalURL1, provider1]);

  useEffect(() => {
    let result = false;

    if (provider2 == "youtube") {
      result = YOUTUBE_REGEX.test(externalURL2);
    }
    if (provider2 == "vimeo") {
      result = VIMEO_REGEX.test(externalURL2);
    }
    if (provider2 == "facebook") {
      result = FACEBOOK_VIDEO_REGEX.test(externalURL2);
    }

    if (provider2) {
      setValidExternalURL2(result);
    }
    if (result && provider2) {
      // Add
    } else {
      // Delete
    }
  }, [externalURL2, provider2]);

  useEffect(() => {
    let result = false;

    if (provider3 == "youtube") {
      result = YOUTUBE_REGEX.test(externalURL3);
    }
    if (provider3 == "vimeo") {
      result = VIMEO_REGEX.test(externalURL3);
    }
    if (provider3 == "facebook") {
      result = FACEBOOK_VIDEO_REGEX.test(externalURL3);
    }

    if (provider3) {
      setValidExternalURL3(result);
    }
    if (result && provider3) {
      // Add
    } else {
      // Delete
    }
  }, [externalURL3, provider3]);

  const handleProvider1Change = (e) => {
    const value = e.target.value;
    setProvider1(value);
    // Set input focus
    if (value) {
      video1Ref.current.focus();
    } else {
      setValidExternalURL1(false);
    }
  };

  const handleProvider2Change = (e) => {
    const value = e.target.value;
    setProvider2(value);
    // Set input focus
    if (value) {
      video2Ref.current.focus();
    } else {
      setValidExternalURL2(false);
    }
  };

  const handleProvider3Change = (e) => {
    const value = e.target.value;
    setProvider3(value);
    // Set input focus
    if (value) {
      video3Ref.current.focus();
    } else {
      setValidExternalURL3(false);
    }
  };

  function convertToSlug(Text) {
    if (Text) {
      return Text.toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    } else {
      return;
    }
  }

  const handleProSpotlight = (e) => {
    setProSpotlight(e.target.checked);
  };

  const handlePriorityListing = (e) => {
    setPriorityListing(e.target.checked);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenAdd = (e) => {
    setAddEditUser("add");

    // Clear out fields
    setFName("");
    setLName("");
    setEmail("");
    setProfileEmail("");
    setPhone("");
    setCallcap("");
    setCity("");
    setState("");
    setBusinessLicense("");
    setBusinessName("");
    setBusinessSlug("");
    setBusinessSpecialty("");
    setUrl("");
    setAbout("");
    setCertificates([]);
    setDescription("");
    setZip("");
    setAddress("");
    setSocial("");
    setYelp("");
    setFacebook("");
    setTwitter("");
    setLinkedin("");
    setInstagram("");
    setGoogle("");
    setProfileCover("");
    setProfileImage("");
    setPortfolioImages("");
    setPortfolioVideos("");
    setProSpotlight("");
    setPriorityListing("");
    setPwd("");
    setMatchPwd("");
    setUserVerified();

    setExternalURL1("");
    setExternalURL2("");
    setExternalURL3("");

    setProvider1("");
    setProvider2("");
    setProvider3("");

    setSubscriptionStatus();
    setSubscriptionDetails();
    setSubCategories([]);
    setSubExcludedZips([]);
    setSubActiveState([]);
    setSubActiveCounties([]);
    setSubOptions([]);
    setSubPackageDetails();
    setSubMaxPaidCategories();

    // Clear out files
    // setPortfolioImages([])
    // setPortfolioVideos([])
    setFiles([]);
    setCover([]);
    setPortfolio([]);
    setVideos([]);
    setDeleteImages([]);
    setCAFile([]);
    setDeleteCAImages([]);
    setCAImages([]);

    setPromotions();

    setOpenAdd(true);
  };

  const handleClickOpenBlockLogin = () => {
    setOpenBlockUserLogin(true);
  };

  const handleCloseBlockUserLogin = () => {
    setOpenBlockUserLogin(false);
  };

  const handleClickOpenBlockReview = () => {
    setOpenBlockUserReview(false);
  };

  const handleCloseBlockUserReview = () => {
    setOpenBlockUserReview(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setFilterSearch("");
  };

  const handleCloseAdd = () => {
    // Clear out fields
    setFilterSearch("");
    setFName("");
    setLName("");
    setEmail("");
    setPhone("");
    setCallcap("");
    setCity("");
    setState("");
    setBusinessLicense("");
    setBusinessName("");
    setBusinessSlug("");
    setBusinessSpecialty("");
    setUrl("");
    setAbout("");
    setCertificates("");
    setDescription("");
    setSocial("");
    setZip("");
    setAddress("");
    setPwd("");
    setMatchPwd("");
    setProfileCover("");
    setProfileImage("");
    setUserVerified();

    // Clear out files
    setPortfolioImages([]);
    setPortfolioVideos([]);
    setCAFile([]);
    setFiles([]);
    setCover([]);
    setPortfolio([]);
    setVideos([]);
    setDeleteImages([]);
    setDeleteCAImages([]);
    setCAImages([]);

    setExternalURL1("");
    setExternalURL2("");
    setExternalURL3("");

    setProvider1("");
    setProvider2("");
    setProvider3("");

    setSubscriptionStatus();
    setSubscriptionDetails();
    setSubCategories([]);
    setSubExcludedZips([]);
    setSubActiveState([]);
    setSubActiveCounties([]);
    setSubOptions([]);
    setSubPackageDetails();
    setSubMaxPaidCategories();

    setOpenAdd(false);
  };

  const handleSaveEdit = async () => {};

  const handleDelete = async () => {
    const dismiss = () => toast.dismiss(deleteUserToast.current);
    const deleteUserToast = toast.loading("Deleting User");
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.delete("user/" + deleteID, {
        signal: controller.signal,
      });

      // Update Toast Notification
      toast.update(deleteUserToast, {
        render: "User Deleted",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });

      // Close Alert Window
      setOpen(false);

      // Delete user from UI
      setFilteredUsers(filteredUsers.filter((user) => user.id !== deleteID));

      // Set filter back to all
      setFilterUserType("all");
    } catch (err) {
      if (!err?.response) {
        toast.update(deleteUserToast, {
          render: "No Server Response",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        {
          /* setErrMsg('No Server Response'); */
        }
      } else {
        toast.update(deleteUserToast, {
          render: "User Delete Failed",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        {
          /* setErrMsg('Registration Failed'); */
        }
      }
      {
        /* errRef.current.focus(); */
      }
    }
    return () => controller.abort();
  };

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get("users", {
          signal: controller.signal,
        });
        // console.log(response.data);
        setUsers(response.data);
        setFilteredUsers(response.data);
        setLoading(false);
        setLoadComplete(true);
      } catch (err) {
        console.log(err.message);
        !auth &&
          navigate("/login", { state: { from: location }, replace: true });
      }
    };

    getUsers();

    addEditUser == "add" ? setValidEmail(true) : setValidEmail(false);

    return () => controller.abort();
  }, [dataUpdated]);

  useEffect(() => {
    const editUser = users?.find((user) => user.id == usersID);
    
    if (editUser) {
      setDataEditUser(editUser)
      setEditID(usersID);
      setType(editUser.role);
      setFName(editUser?.first_name);
      setLName(editUser.last_name);
      setEmail(editUser.email);
      setValidEmail(true);
      setPhone(editUser.phone);
      setCallcap(editUser.provision_number);
      setCity(editUser.city);
      setState(editUser.state);
      setBusinessLicense(editUser.license_number);
      setBusinessName(editUser.business_name);
      setBusinessSlug(editUser.slug);
      setBusinessSpecialty(editUser.contractor_specialty);
      setUrl(editUser.business_website);
      setAbout(editUser.business_about);
      setCertificates(editUser.business_certs_awards);
      setDescription(editUser.business_description);
      setZip(editUser.zip);
      setAddress(editUser.address);
      setSocial(editUser.social_links);
      setYelp(social && JSON.parse(social)[0].yelp);
      setFacebook(social && JSON.parse(social)[0].facebook);
      setTwitter(social && JSON.parse(social)[0].twitter);
      setLinkedin(social && JSON.parse(social)[0].linkedin);
      setInstagram(social && JSON.parse(social)[0].instagram);
      setGoogle(social && JSON.parse(social)[0].google);
      setProfileCover(editUser.cover_picture);
      setProfileImage(editUser.profile_picture);
      setPortfolioImages(editUser.portfolio);
      setPortfolioVideos(editUser.videos);
      setProSpotlight(editUser.featured_contractor);
      setPriorityListing(editUser.priority_listing);

      setExternalURL1(editUser.ext_video_url_1);
      setExternalURL2(editUser.ext_video_url_2);
      setExternalURL3(editUser.ext_video_url_3);

      setProvider1(editUser.ext_video_provider_1);
      setProvider2(editUser.ext_video_provider_2);
      setProvider3(editUser.ext_video_provider_3);

      setSubscriptionStatus(editUser?.subscription_status);
      setSubscriptionDetails(editUser?.subscription_details);
      setSubCategories(
        editUser?.subscription_details?.service_categories
          ? JSON.parse(editUser?.subscription_details?.service_categories)
          : []
      );
      setSubExcludedZips(
        editUser?.subscription_details?.excluded_zipcodes
          ? JSON.parse(editUser?.subscription_details?.excluded_zipcodes)
          : []
      );
      setSubActiveState(
        editUser?.subscription_details?.active_state
          ? JSON.parse(editUser?.subscription_details?.active_state)
          : []
      );
      setSubActiveCounties(
        editUser?.subscription_details?.active_counties
          ? JSON.parse(editUser?.subscription_details?.active_counties)
          : []
      );
      setSubOptions(
        editUser?.subscription_details?.selected_options
          ? JSON.parse(editUser?.subscription_details?.selected_options)
          : []
      );
      setSubPackageDetails(
        editUser?.subscription_details?.package_details
          ? JSON.parse(editUser?.subscription_details?.package_details)
          : []
      );
      if (editUser?.subscription_details?.package_details) {
          setSubMaxPaidCategories(JSON.parse(editUser?.subscription_details?.package_details)?.num_max_allowed_services)
        } else {
          setSubMaxPaidCategories(editUser?.subscription_details?.max_paid_categories)
        }

      setOpenAdd(true);
      setAddEditUser("edit");
    } else {
      // Alert to no user available
      if (loadComplete && usersID && usersID !== "undefined") {
        toast.error("User does not exist!", {
          isLoading: false,
          autoClose: 5000,
          toastId: "noUser",
        });
      }
    }
  }, [usersID, users]);

  const deleteUser = (e, i, fname, lname, id) => {
    setOpen(true);
    setDeleteName(`${fname} ${lname}`);
    setDeleteID(id);
  };

  const editUser = (e, i, user) => {
    // console.log("user in user", user)
    // setOpenEdit(true)
    setOpenAdd(true);
    setAddEditUser("edit");
    setOverrideCallCap(true);

    // setEditName(`${fname} ${lname}`)
    setEditID(user?.id);
    setUserVerified(user?.email_verified_at);
    // setEditEmail(email)
    // setEditRole(role)

    setType(user?.role);
    setFName(user?.first_name);
    setLName(user?.last_name);
    setEmail(user?.email);
    setProfileEmail(user?.profile_email);
    setValidEmail(true);
    setPhone(user?.phone);
    setCallcap(user?.provision_number);
    setCity(user?.city);
    setState(user?.state);
    setBusinessLicense(user?.license_number);
    setBusinessName(user?.business_name);
    setBusinessSlug(user?.slug);
    setBusinessSpecialty(user?.contractor_specialty);
    setUrl(user?.business_website);
    setAbout(user?.business_about);
    setCertificates(user?.business_certs_awards);
    setDescription(user?.business_description);
    setZip(user?.zip);
    setAddress(user?.address);
    setSocial(user?.social_links);
    setYelp(user?.social_links && JSON.parse(user?.social_links)[0].yelp);
    setFacebook(
      user?.social_links && JSON.parse(user?.social_links)[0].facebook
    );
    setTwitter(user?.social_links && JSON.parse(user?.social_links)[0].twitter);
    setLinkedin(
      user?.social_links && JSON.parse(user?.social_links)[0].linkedin
    );
    setInstagram(
      user?.social_links && JSON.parse(user?.social_links)[0].instagram
    );
    setGoogle(user?.social_links && JSON.parse(user?.social_links)[0].google);
    setProfileCover(user?.cover_picture);
    setProfileImage(user?.profile_picture);
    setPortfolioImages(user?.portfolio);
    setPortfolioVideos(user?.videos);
    setCAImages(user?.ca_files);
    setProSpotlight(user?.featured_contractor);
    setPriorityListing(user?.priority_listing);

    setExternalURL1(user?.ext_video_url_1);
    setExternalURL2(user?.ext_video_url_2);
    setExternalURL3(user?.ext_video_url_3);

    setProvider1(user?.ext_video_provider_1);
    setProvider2(user?.ext_video_provider_2);
    setProvider3(user?.ext_video_provider_3);

    setPromotions(user?.promotions);

    setSubscriptionStatus(user?.subscription_status);
    setSubscriptionDetails(user?.subscription_details);
    setSubCategories(
      user?.subscription_details?.service_categories
        ? JSON.parse(user?.subscription_details?.service_categories)
        : []
    );
    setSubExcludedZips(
      user?.subscription_details?.excluded_zipcodes
        ? JSON.parse(user?.subscription_details?.excluded_zipcodes)
        : []
    );
    setSubActiveState(
      user?.subscription_details?.active_state
        ? JSON.parse(user?.subscription_details?.active_state)
        : []
    );
    setSubActiveCounties(
      user?.subscription_details?.active_counties
        ? JSON.parse(user?.subscription_details?.active_counties)
        : []
    );
    setSubOptions(
      user?.subscription_details?.selected_options
        ? JSON.parse(user?.subscription_details?.selected_options)
        : []
    );
    setSubPackageDetails(
      user?.subscription_details?.package_details
        ? JSON.parse(user?.subscription_details?.package_details)
        : []
    );
    if (user?.subscription_details?.package_details) {
      setSubMaxPaidCategories(JSON.parse(user?.subscription_details?.package_details)?.num_max_allowed_services)
    } else {
      setSubMaxPaidCategories(user?.subscription_details?.max_paid_categories)
    }   
    setAllUserData(user)

    // setPwd("")
    // setMatchPwd("")
  };

  /* Check username against regex function on username change */
  useEffect(() => {
    const result = EMAIL_REGEX.test(editEmail);
    setValidEmail(result);
  }, [editEmail]);

  const formatTimestamp = (ts) => {
    if (ts) {
      const options = { month: "numeric", day: "numeric", year: "numeric" };
      return new Date(ts).toLocaleDateString(undefined, options);
    } else {
      return "N/A";
    }
  };

  useEffect(() => {
    if (filterUserType === "all") {
      setFilteredUsers(users);
    } else if (filterUserType === "2222") {
      const resultsArray = users
        ?.filter(
          (user) => user.role.includes("2002") && user.featured_contractor
        )
        .sort((a, b) => a.business_name > b.business_name);
      setFilteredUsers(resultsArray);
    } else if (filterUserType === "2022") {
      const resultsArray = users
        ?.filter((user) => user.role.includes("2002") && user.priority_listing)
        .sort((a, b) => a.business_name > b.business_name);
      setFilteredUsers(resultsArray);
    } else if (filterUserType === "2002") {
      const resultsArray = users
        ?.filter((user) => user.role.includes(filterUserType))
        .sort((a, b) => a.business_name > b.business_name);
      setFilteredUsers(resultsArray);
    } else if (filterUserType === "nosub") {
      const resultsArray = users?.filter(
        (user) => user.role.includes("2002") && user.subscription_status == null
      );
      setFilteredUsers(resultsArray);
    } else if (filterUserType === "canceled") {
      const resultsArray = users?.filter(
        (user) =>
          user.role.includes("2002") && user.subscription_status == "canceled"
      );
      setFilteredUsers(resultsArray);
    } else if (filterUserType === "active") {
      const resultsArray = users?.filter(
        (user) =>
          user.role.includes("2002") && user.subscription_status == "active"
      );
      setFilteredUsers(resultsArray);
    } else if (filterUserType === "chosen") {
      const resultsArray = users?.filter(
        (user) =>
          user.role.includes("2002") && user.subscription_status == "chosen"
      );
      setFilteredUsers(resultsArray);
    } else {
      const resultsArray = users?.filter((user) =>
        user.role.includes(filterUserType)
      );
      setFilteredUsers(resultsArray);
    }
  }, [filterUserType]);

  useEffect(() => {
    if (filterSearch === "") {
      setFilteredUsers(users);
    } else {
      const resultsArray = users?.filter((user) => {
        return (
          user.first_name.toLowerCase().includes(filterSearch.toLowerCase()) ||
          user.last_name.toLowerCase().includes(filterSearch.toLowerCase()) ||
          user.id == filterSearch ||
          user.business_name?.toLowerCase().includes(filterSearch.toLowerCase())
        );
      });

      setFilteredUsers(resultsArray);
    }
  }, [filterSearch]);

  const handleAddNewUser = async (e) => {
    e.preventDefault();
    closeAdminForm();

    setFilterSearch("");

    // Failsafe if slug is null
    businessSlug == null &&
      validBusinessName &&
      businessName &&
      type === 2002 &&
      setBusinessSlug(convertToSlug(businessName));

    // Create Social Media JSON
    const socialLinks = JSON.stringify([
      {
        facebook: facebook,
        instagram: instagram,
        twitter: twitter,
        linkedin: linkedin,
        yelp: yelp,
        google: google,
      },
    ]);

    const formData = new FormData();
    files.map((file, i) => {
      formData.append("files[]", file);
    });
    portfolio.map((file, i) => {
      formData.append("portfolio[]", file);
    });
    cover.map((file, i) => {
      formData.append("cover[]", file);
    });
    videos.map((file, i) => {
      formData.append("videos[]", file);
    });
    caFile.map((file, i) => {
      formData.append("ca_files[]", file);
    });

    formData.append("post_type", addEditUser);
    formData.append("first_name", fName);
    formData.append("last_name", lName);
    formData.append("email", email);
    if (type === "1001") {
      formData.append("homeshow_raffle_2024", true);
    } else {
      formData.append("homeshow_raffle_2024", false);
    }
    if (type == "2002") {
      formData.append("phone", phone);
      formData.append("provision_number", callcap);
      formData.append("city", city);
      formData.append("state", state);
      formData.append("business_name", businessName);
      if (overrideCallCap == false) {
        formData.append("override_provision_number", true);
      }
      if (addEditUser == "edit") {
        formData.append("provision_number", callcap);
      }
      if (businessSpecialty) {
        formData.append("contractor_specialty", businessSpecialty);
      }
      if (url) {
        formData.append("business_website", url);
      }
      if (businessLicense) {
        formData.append("license_number", businessLicense);
      }
      formData.append("slug", businessSlug);
      formData.append("business_about", about);
      formData.append("business_certs_awards", certificates);
      formData.append("business_description", description);
      formData.append("social_links", socialLinks);
      formData.append("featured_contractor", proSpotlight);
      formData.append("priority_listing", priorityListing);
      formData.append("ext_video_url_1", externalURL1);
      formData.append("ext_video_provider_1", provider1);
      formData.append("ext_video_url_2", externalURL2);
      formData.append("ext_video_provider_2", provider2);
      formData.append("ext_video_url_3", externalURL3);
      formData.append("ext_video_provider_3", provider3);
      formData.append(
        "profile_email",
        profileEmail === "" || profileEmail === "null" || !profileEmail
          ? email
          : profileEmail
      );
      formData.append("contact_visibility", contactVisibility ? 1 : 0);
    }
    if (type !== "5150") {
      formData.append("zip", zip);
      formData.append(
        "address",
        address === "" || address === "null" || !address ? " " : address
      );
    }
    formData.append("password", pwd);
    formData.append("password_confirmation", matchPwd);
    formData.append("role", type);
    formData.append("delete_images", deleteImages);
    formData.append("delete_videos", deleteVideos);
    formData.append("delete_ca_files", deleteCAImages);

    const dismiss = () => toast.dismiss(updateProfileToast.current);
    const updateProfileToast = toast.loading(
      addEditUser == "add" ? "Adding User" : "Saving User"
    );
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.post("admin-register", formData, {
        signal: controller.signal,
      });

      // Update State
      // setAuth({...auth, user: response?.data?.user});
      setFilteredUsers(response.data);
      setUsers(response.data);

      // Close Add User Popup
      setOpenAdd(false);

      // Clear out fields
      setFName("");
      setLName("");
      setEmail("");
      setPhone("");
      setCallcap("");
      setCity("");
      setState("");
      setBusinessLicense("");
      setAddress("");
      setBusinessName("");
      setBusinessSlug("");
      setBusinessSpecialty("");
      setUrl("");
      setAbout("");
      setCertificates("");
      setDescription("");
      setSocial("");
      setZip("");
      setAddress("");
      setPwd("");
      setMatchPwd("");
      setProfileCover("");
      setProfileImage("");

      setExternalURL1("");
      setExternalURL2("");
      setExternalURL3("");

      setProvider1("");
      setProvider2("");
      setProvider3("");

      // Clear out files
      setFiles([]);
      setCover([]);
      setPortfolio([]);
      setVideos([]);
      setDeleteImages([]);
      setCAFile([]);

      setSubCategories([]);
      setSubExcludedZips([]);
      setSubActiveState([]);
      setSubActiveCounties([]);
      setSubOptions([]);
      setSubPackageDetails();

      // Set filter back to all
      setFilterUserType("all");

      // Update Toast Notification
      toast.update(updateProfileToast, {
        render: addEditUser == "add" ? "User Added" : "User Updated",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
    } catch (err) {
      if (!err?.response) {
        toast.update(updateProfileToast, {
          render: "No Server Response",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        {
          /* setErrMsg('No Server Response'); */
        }
      } else if (err.response?.status === 409) {
        toast.update(updateProfileToast, {
          render: "Name Taken",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        {
          /* setErrMsg('Name Taken'); */
        }
      } else if (err.response?.status === 422) {
        toast.update(updateProfileToast, {
          render: "The email has already been taken.",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        {
          /* setErrMsg('The email has already been taken.'); */
        }
      } else if (err.response?.status === 401) {
        toast.update(updateProfileToast, {
          render: "Authorization Failed",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        {
          /* setErrMsg('The email has already been taken.'); */
        }
      } else {
        toast.update(updateProfileToast, {
          render:
            addEditUser == "add" ? "User Add Failed" : "User Update Failed",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        {
          /* setErrMsg('Registration Failed'); */
        }
      }
      {
        /* errRef.current.focus(); */
      }
    }
    return () => controller.abort();
  };

  const handleSetType = (e) => {
    setType(e.target.value);
    setValidBusinessName(false);
    setValidPhone(false);
    setValidCity(false);
    setValidState(false);
    setValidState(false);
    if (!phone) {
      setOverrideCallCap(false);
      setCallcap("");
    }
  };

  const handleDeletePromotionalImage = async (e, path) => {
    // We won't delete images until the save button is clicked so lets store the ids of images to delete if save is clicked
    setDeleteImages(path);
    console.log(path);
    // Delete Image from UI
    setEditImage();
  };

  const handleDeleteImage = async (e, i, id) => {
    // We won't delete images until the save button is clicked so lets store the ids of images to delete if save is clicked
    setDeleteImages([...deleteImages, id]);

    // Delete Image from UI
    setPortfolioImages(portfolioImages.filter((image) => image.id !== id));
  };

  const handleDeleteVideo = async (e, i, id) => {
    // We won't delete videos until the save button is clicked so lets store the ids of images to delete if save is clicked
    setDeleteImages([...deleteVideos, id]);

    // Delete video from UI
    setPortfolioVideos(portfolioVideos.filter((video) => video.id !== id));
  };

  const handleDeleteCAFile = async (e, i, id) => {
    // We won't delete images until the save button is clicked so lets store the ids of images to delete if save is clicked
    setDeleteCAImages([...deleteCAImages, id]);

    // Delete Image from UI
    setCAImages(caImages.filter((image) => image.id !== id));
  };

  const lockUserAccount = (e, i, id, blocked, fname, lname) => {
    setOpenBlockUserLogin(true);
    setBlockLoginID(id);
    setBlockedLoginState(blocked);
    setBlockLoginIndex(i);
    setBlockLoginName(`${fname} ${lname}`);
  };

  const handleBlockUserLogin = async () => {
    let newValue = !blockedLoginState;

    // Update user in DB
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.put(
        "admin-user-login-block/" + blockLoginID,
        JSON.stringify({ login_blocked: newValue }),
        {
          signal: controller.signal,
        }
      );

      // Close Popup
      setOpenBlockUserLogin(false);

      // Update State
      users[blockLoginIndex].login_blocked = newValue;
      setUsers([...users]);
    } catch (err) {
      console.log(err.message);
      !auth && navigate("/login", { state: { from: location }, replace: true });
    }

    return () => controller.abort();
  };

  const lockUserReviews = (e, i, id, blocked, fname, lname) => {
    setOpenBlockUserReview(true);
    setBlockReviewID(id);
    setBlockedReviewState(blocked);
    setBlockReviewIndex(i);
    setBlockReviewName(`${fname} ${lname}`);
  };

  const handleBlockUserReview = async () => {
    let newValue = !blockedReviewState;

    // Update user in DB
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.put(
        "admin-user-review-block/" + blockReviewID,
        JSON.stringify({ reviews_blocked: newValue }),
        {
          signal: controller.signal,
        }
      );

      // Close Popup
      setOpenBlockUserReview(false);

      // Update State
      users[blockReviewIndex].reviews_blocked = newValue;
      setUsers([...users]);
    } catch (err) {
      console.log(err.message);
      !auth && navigate("/login", { state: { from: location }, replace: true });
    }

    return () => controller.abort();
  };

  const updateUserSubscriptionDetail = async (e) => {
    e.preventDefault();

    const dismiss = () => toast.dismiss(userSubUpdateToast.current);
    const userSubUpdateToast = toast.loading("Updating info");
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.put(
        "update-subscription/" + editID,
        JSON.stringify(
          { 
            active_counties: subActiveCounties,
            service_categories: subCategories, 
            excluded_zipcodes: subExcludedZips 
          }
        ),
        {
          headers: {
            "Accept": "application/json",
          },
          signal: controller.signal,
        }
      );

      // Update Toast Notification
      toast.update(userSubUpdateToast, {
        render: "User Subscription Updated",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
      setDataUpdated(dataUpdated ? dataUpdated + 1 : 1)
      // setTimeout(() => {
      //   window.location.reload(true)
      // }, 3000);
    } catch (err) {
      if (!err?.response) {
        toast.update(userSubUpdateToast, {
          render: "No Server Response",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      } else {
        toast.update(userSubUpdateToast, {
          render: "User update error",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        throw new Error(err)
      }
    }
  }

  const sendUserVerification = async (e) => {
    e.preventDefault();

    const dismiss = () => toast.dismiss(userVerificationToast.current);
    const userVerificationToast = toast.loading("Sending Verification");
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.get(
        "send-user-verification/" + editID,
        {
          signal: controller.signal,
        }
      );

      // Update Toast Notification
      toast.update(userVerificationToast, {
        render: "User Verification Sent",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
    } catch (err) {
      if (!err?.response) {
        toast.update(userVerificationToast, {
          render: "No Server Response",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        {
          /* setErrMsg('No Server Response'); */
        }
      } else {
        toast.update(userVerificationToast, {
          render: "User Verification Send Failed",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        {
          /* setErrMsg('Registration Failed'); */
        }
      }
      {
        /* errRef.current.focus(); */
      }
    }
    return () => controller.abort();
  };

  const adminVerification = async (e) => {
    e.preventDefault();

    const dismiss = () => toast.dismiss(adminVerificationToast.current);
    const adminVerificationToast = toast.loading("Verifying User");
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.get(
        "admin-user-verification/" + editID,
        {
          signal: controller.signal,
        }
      );

      // Update Toast Notification
      toast.update(adminVerificationToast, {
        render: "User Verified",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });

      // Update category state
      const index = users
        .map(function (x) {
          return x.id;
        })
        .indexOf(editID);
      users[index].email_verified_at = Date.now();
    } catch (err) {
      if (!err?.response) {
        toast.update(adminVerificationToast, {
          render: "No Server Response",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        {
          /* setErrMsg('No Server Response'); */
        }
      } else {
        toast.update(adminVerificationToast, {
          render: "User Verification Failed",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        {
          /* setErrMsg('Registration Failed'); */
        }
      }
      {
        /* errRef.current.focus(); */
      }
    }
    return () => controller.abort();
  };

  const sendPasswordReset = async (e) => {
    e.preventDefault();

    const dismiss = () => toast.dismiss(passwordResetToast.current);
    const passwordResetToast = toast.loading("Sending Password Reset");
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.post(
        "forgot-password",
        JSON.stringify({ email: email }),
        {
          signal: controller.signal,
        }
      );

      // Update Toast Notification
      toast.update(passwordResetToast, {
        render: "Password Reset Sent",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });

      // Update category state
      const index = users
        .map(function (x) {
          return x.id;
        })
        .indexOf(editID);
      users[index].email_verified_at = Date.now();
    } catch (err) {
      if (!err?.response) {
        toast.update(passwordResetToast, {
          render: "No Server Response",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        {
          /* setErrMsg('No Server Response'); */
        }
      } else {
        toast.update(passwordResetToast, {
          render: "Send Password Reset Failed",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        {
          /* setErrMsg('Registration Failed'); */
        }
      }
      {
        /* errRef.current.focus(); */
      }
    }
    return () => controller.abort();
  };

  const viewProfile = (slug) => {
    window.open(`/contractor/${slug}`, "_blank");
  };

  const formatDate = (dateString) => {
    const options = { month: "long", day: "numeric", year: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const createPromotion = (e) => {
    e.preventDefault();

    setOpenCreatePromotion(true);
  };

  const handleCloseCreatePromotion = () => {
    setOpenCreatePromotion(false);
  };

  const handleCloseDeletePromotion = () => {
    setOpenDeletePromotion(false);
  };

  const handleCloseEditPromotion = () => {
    setOpenEditPromotion(false);
  };

  const addPromotion = (promotion) => {
    // console.log(promotion);
    setPromotions([...promotions, promotion]);
  };

  const editPromotion = (e, i, promotion) => {
    setOpenEditPromotion(true);

    setEditPromotionID(promotion?.id);
    setEditDetails(promotion?.details);
    setEditName(promotion?.name);
    setEditStatus(promotion?.status);
    setEditImage(promotion?.image);
  };

  const deletePromotion = (e, i, promotion) => {
    setOpenDeletePromotion(true);
    setDeletePromotionName(promotion?.name);
    setDeletePromotionID(promotion?.id);
  };

  const handleDeletePromotion = async () => {
    const dismiss = () => toast.dismiss(deletePromotionToast.current);
    const deletePromotionToast = toast.loading("Deleting Promotion");
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.delete(
        "promotion/" + deletePromotionID,
        {
          signal: controller.signal,
        }
      );

      // Update Toast Notification
      toast.update(deletePromotionToast, {
        render: "Promotion Deleted",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });

      // Close Alert Window
      setOpenDeletePromotion(false);

      // Delete job from UI
      setPromotions(
        promotions.filter((promotion) => promotion.id !== deletePromotionID)
      );
    } catch (err) {
      if (!err?.response) {
        toast.update(deletePromotionToast, {
          render: "No Server Response",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        {
          /* setErrMsg('No Server Response'); */
        }
      } else {
        toast.update(deletePromotionToast, {
          render: "Promotion Delete Failed",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        {
          /* setErrMsg('Registration Failed'); */
        }
      }
      {
        /* errRef.current.focus(); */
      }
    }
    return () => controller.abort();
  };

  const handleContactDisplay = (e) => {
    setContactVisibility(e.target.checked);
  };

  const editUserSubscription = () => {
    setOpenEditSubscription(true);
  };

  const handleCloseEditSubscription = () => {
    setOpenEditSubscription(false);
  };

  const handleSaveEditSubscription = (e) => {
    updateUserSubscriptionDetail(e)
    setOpenEditSubscription(false);
  };

  const handleChangeState = (e) => {
    setSubActiveState(e.target.value);
  };

  const removeSubCategory = (e, i, subCategories, category) => {
    setDeleteSubCategory(category);
    setOpenConfirmDeleteCategory(true);
  };

  const handleRemoveSubCategory = () => {
    setSubCategories(
      subCategories.filter((category) => category !== deleteSubCategory)
    );
    setOpenConfirmDeleteCategory(false);
  };

  const handleCloseConfirmDeleteCategory = () => {
    setDeleteSubCategory();
    setOpenConfirmDeleteCategory(false);
  };

  const removeSubCounty = (e, i, subActiveCounties, county) => {
    setDeleteSubCounty(county);
    setOpenConfirmDeleteCounty(true);
  };

  const handleRemoveSubCounty = () => {
    setSubActiveCounties(
      subActiveCounties.filter((county) => county !== deleteSubCounty)
    );
    setOpenConfirmDeleteCounty(false);
  };

  const handleCloseConfirmDeleteCounty = () => {
    setDeleteSubCounty();
    setOpenConfirmDeleteCounty(false);
  };

  const removeSubZipcode = (e, i, subExcludedZips, zips) => {
    setDeleteSubZip(zips);
    setOpenConfirmDeleteZipcode(true);
  };

  const handleRemoveSubZipcode = () => {
    setSubExcludedZips(subExcludedZips.filter((zip) => zip !== deleteSubZip));
    setOpenConfirmDeleteZipcode(false);
  };

  const handleCloseConfirmDeleteZipcode = () => {
    setDeleteSubZip();
    setOpenConfirmDeleteZipcode(false);
  };

  const addSubCounty = () => {
    setOpenAddCounty(true);
  };

  // const handleAddSubCounty = (newCounty) => {
  //   // setOpenAddCounty(false);
  //   if ((subActiveCounties.length) < subPackageDetails?.num_allowed_counties && !subActiveCounties.includes(newCounty)) {
  //     setSubActiveCounties([newCounty, ...subActiveCounties])
  //   }

  // };

  const toggleCounty = (newCounty) => {
		// Add or remove county from state
		if (!subActiveCounties.includes(newCounty)){
			if (subActiveCounties.length >= subPackageDetails?.num_allowed_counties){
				const createJobToast = toast.error("Max Counties Added", { type: 'error', isLoading: false, autoClose: 5000});
			} else {
				setSubActiveCounties([...subActiveCounties, newCounty])
			}
		} else {
			setSubActiveCounties(subActiveCounties.filter(cty => { return cty !== newCounty}))
		}
	}

  const toggleService = (e, i, newService) => {

		// Add or remove service from subscription
		if (!subCategories.includes(newService)){
      // console.log("!subCategories.includes(newService)")
			if (currentMaxAllowedServices !== -1 && subCategories.length >= (currentAllowedServices ? currentAllowedServices : subPackageDetails?.num_allowed_services)){
        // console.log("subMaxPaidCategories !== -1 && subCategories.length >= (currentAllowedServices ? currentAllowedServices : subPackageDetails?.num_allowed_services)")
				const createJobToast = toast.error("Max Services Added", { type: 'error', isLoading: false, autoClose: 5000});
			} else {
        // console.log("else !subMaxPaidCategories !== -1 && subCategories.length >= (currentAllowedServices ? currentAllowedServices : subPackageDetails?.num_allowed_services)")
				setSubCategories([...subCategories, newService])
			}
		} else {
      // console.log("subCategories.includes(newService) else")
			setSubCategories(subCategories.filter(svc => { return svc !== newService}))
		}
	}

  const toggleZip = (zip) => {
		// Add or remove county from state
		if (!subExcludedZips.includes(zip)){
			setSubExcludedZips([...subExcludedZips, zip])
		} else {
			setSubExcludedZips(subExcludedZips.filter(zipcode => { return zipcode !== zip}))
		}
	}

  const handleCloseAddCounty = () => {
    setOpenAddCounty(false);
  };

  const addSubCategory = () => {
    setOpenAddCategory(true);
  };

  const handleAddSubCategory = () => {
    setOpenAddCategory(false);
  };

  const handleCloseAddCategory = () => {
    setOpenAddCategory(false);
  };

  const addSubZipExclusion = () => {
    setOpenAddZipExclusion(true);
  };

  const handleAddSubZipExclusion = () => {
    setOpenAddZipExclusion(false);
  };

  const handleCloseAddZipExclusion = () => {
    setOpenAddZipExclusion(false);
  };

  const { setUserState, setUserZip, data, setData, setBillingData, setPage, exitAdminForm } = useAdminFormContext()
  // const formContextArr = useAdminFormContext()
  // console.log("formContextArr",formContextArr)
  // console.log("data",data)
  
  const handleSetUserState = (currentUserState) => {
    console.log("run handleSetUserState")
    setUserState(currentUserState)
  } 
  const handleSetUserZip = (currentUserZip) => {
    setUserZip(currentUserZip)
  } 

  const openAdminFormDisplay = (e) => {
    e.preventDefault()
    setOpenAdminForm(true)
  }
  
  const closeAdminForm = () => {
    setData({
      ...data,
      payment: "",
      selected_counties: "",
      excluded_zips: [],
      selected_services: "",
      active_county: [],
      monthly_charges: "",
      one_time_charges: "",
      selected_options: [], 
      subscription: {}
      
    })
    setBillingData({
      subscriber_first_name: "",
      subscriber_last_name: "",
      subscription_name: "",
      subscriber_company: "",
      subscriber_billing_address: "",
      subscriber_billing_city: "",
      subscriber_billing_state: "",
      subscriber_billing_zip: "",
      subscriber_cc: "",
      subscriber_sc: "",
      subscriber_cc_expiration: "",
      subscriber_coupon: "",
      subscription_full_amount: "",
      subscription_trial_amount: "",
      subscription_trial_months: "",
      subscription_initial_payment: ""
    })
    setPage(0)
    setOpenAdminForm(false)
  }

  useEffect(() => {
    if(exitAdminForm) {
      // console.log("exitAdminForm was run and hit Users.js")
      closeAdminForm()
    }
  },[exitAdminForm])

  const handleSaveEditPromotion = async () => {
    const dismiss = () => toast.dismiss(updatePromotionToast.current);
    const updatePromotionToast = toast.loading("Updating Promotion");
    const controller = new AbortController();

    const formData = new FormData();
    files.map((file, i) => {
      formData.append("files[]", file);
    });

    console.log(deleteImages);
    formData.append("name", editName);
    formData.append("details", editDetails);
    formData.append("status", editStatus);
    formData.append("deleteImagePath", deleteImages);

    // Key to making put work with formdata
    formData.append("_method", "PUT");

    try {
      const response = await axiosPrivate.post(
        "promotion/" + editPromotionID,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          signal: controller.signal,
        }
      );

      const updatedImage = response.data.promotions.image;

      // Update promotions in state
      const index = promotions
        .map(function (x) {
          return x.id;
        })
        .indexOf(editPromotionID);
      promotions[index].name = editName;
      promotions[index].details = editDetails;
      promotions[index].status = editStatus;
      promotions[index].image = updatedImage;

      // Clear data
      setFiles([]);
      setDeleteImages();
      setEditPromotionID();

      // Close popup on success
      setOpenEditPromotion(false);

      // Update Toast Notification
      toast.update(updatePromotionToast, {
        render: "Promotion Updated",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
    } catch (err) {
      if (!err?.response) {
        toast.update(updatePromotionToast, {
          render: "No Server Response",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        {
          /* setErrMsg('No Server Response'); */
        }
      } else if (err.response?.status === 401) {
        toast.update(updatePromotionToast, {
          render: "Authorization Failed",
          type: "error",
          isLoading: false,
        });
        {
          /* setErrMsg('The email has already been taken.'); */
        }
      } else {
        toast.update(updatePromotionToast, {
          render: "Promotion Update Failed",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        {
          /* setErrMsg('Registration Failed'); */
        }
      }
      {
        /* errRef.current.focus(); */
      }
    }
    return () => controller.abort();
  };

  useEffect(() => {
		const controller = new AbortController();
		// setLoading(true);
		const getConfig = async () => {
			try {
				const response = await axiosPrivate.get('subscription-config', {
					signal: controller.signal
				});
				// let categories = response.data.service_categories
				// let counties = response.data.active_state_counties
				// let excludes = response.data.zip_codes
				// let states = response.data.active_states
				// let options = response.data.options
				// let zipObject = excludes.filter(zip => { return zip.zipcodes === profileZip})
				// let profileCounty = zipObject.county
				setConfig(response.data)
				setSubscriptionData({states: response.data.active_states, counties: response.data.active_state_counties, categories: response.data.service_categories, excludes: response.data.zip_codes, options: response.data.options})
			} catch(err) {
				console.log("err.message", err.message);
				{/* !auth && navigate('/login', { state: { from: location }, replace: true }); */}
			}
		}

		getConfig();

		return () => controller.abort();
		// eslint-disable-next-line
	},[])

  

  return (
    <div className="backendList">
      <div className="selectContainer">
        <div className="selectWrapper">
          <Box>
            <FormControl fullWidth>
              <InputLabel id="filter-type-label">Filter Type</InputLabel>
              <Select
                labelId="filter-type-label"
                id="filterType"
                onChange={(e) => setFilterUserType(e.target.value)}
                value={filterUserType}
              >
                <MenuItem value="all">View All</MenuItem>
                <MenuItem value="127001">Super Admin</MenuItem>
                <MenuItem value="5150">Admin</MenuItem>
                <MenuItem value="2002">Home Pro</MenuItem>
                <MenuItem value="2222">Pro Spotlight</MenuItem>
                <MenuItem value="2022">Priority Listing</MenuItem>
                <MenuItem value="1001">Homeowner</MenuItem>
                <Divider />
                <MenuItem value="nosub">No Subscription</MenuItem>
                <MenuItem value="chosen">Subscription Chosen</MenuItem>
                <MenuItem value="active">Subscription Active</MenuItem>
                <MenuItem value="canceled">Subscription Canceled</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
        <div className="selectWrapper">
          <Box>
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="Search"
                variant="outlined"
                onClick={(e) => setFilterUserType("all")}
                onChange={(e) => setFilterSearch(e.target.value)}
                value={filterSearch}
              />
            </FormControl>
          </Box>
        </div>
        <div className="addUserWrapper">
          <button className="addUser" onClick={(e) => handleClickOpenAdd(e)}>
            <MdPersonAdd />
          </button>
        </div>
      </div>
      {users?.length ? (
        <>
          <ul className="subscriptionStatusKey">
            <li>No Subscription</li>
            <li className="chosen">Subscription Chosen</li>
            <li className="active">Subscription Active</li>
            <li className="canceled">Subscription Canceled</li>
          </ul>
          <ul className="usersList">
            <li className="key">
              <div className="id" onClick={() => onSort('id')}>ID</div>
              <div className="name" onClick={() => onSort('name')}>Name</div>
              <div className="loggedInDate" onClick={() => onSort('last_logged_in')}>Last Logged In</div>
              <div className="roleContainer" onClick={() => onSort('role')}>Role</div>
              <div className="buttons"></div>
            </li>
            {filteredUsers.map((user, i) => (
              <li
                key={i}
                className={`${
                  user?.role == 2002
                    ? `business ${user?.subscription_status}`
                    : ""
                }${
                  user?.login_blocked || user?.reviews_blocked ? " locked" : ""
                }`}
              >
                <div className="id">{user?.id}</div>
                <div className="name">
                  {user?.role == 2002 ? (
                    <>
                      <span>{user?.business_name}</span>
                      <p>
                        {user?.first_name} {user?.last_name}
                      </p>
                    </>
                  ) : (
                    <>
                      {user?.first_name} {user?.last_name}
                    </>
                  )}
                </div>
                <div className="loggedInDate">
                  {formatTimestamp(user?.last_logged_in)}
                </div>
                <div className="roleContainer">
                  <div
                    className={`role
										${user?.role == 127001 ? "super-admin" : ""}
										${user?.role == 5150 ? "admin" : ""}
										${user?.role == 1001 ? "homeowner" : ""}
										${
                      user?.role == 2002 &&
                      !user?.featured_contractor &&
                      !user?.priority_listing
                        ? "contractor"
                        : ""
                    }
										${
                      user?.role == 2002 &&
                      user?.featured_contractor &&
                      !user?.priority_listing
                        ? "featured"
                        : ""
                    }
										${
                      user?.role == 2002 &&
                      !user?.featured_contractor &&
                      user?.priority_listing
                        ? "priority"
                        : ""
                    }
										${
                      user?.role == 2002 &&
                      user?.featured_contractor &&
                      user?.priority_listing
                        ? "featured priority"
                        : ""
                    }`}
                  >
                    {user?.role == 127001 && "Super Admin"}
                    {user?.role == 5150 && "Admin"}
                    {user?.role == 1001 && "Homeowner"}
                    {user?.role == 2002 &&
                    !user?.featured_contractor &&
                    !user?.priority_listing
                      ? "Home Pro"
                      : ""}
                    {user?.role == 2002 &&
                    user?.featured_contractor &&
                    !user?.priority_listing ? (
                      <>
                        <MdStar />
                        Home Pro
                        <MdStar />
                      </>
                    ) : (
                      ""
                    )}
                    {user?.role == 2002 &&
                    !user?.featured_contractor &&
                    user?.priority_listing ? (
                      <>Priority Home Pro</>
                    ) : (
                      ""
                    )}
                    {user?.role == 2002 &&
                    user?.featured_contractor &&
                    user?.priority_listing ? (
                      <>
                        <MdStar />
                        Home Pro
                        <MdStar />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="buttons">
                  {user?.role !== "127001" && user?.role !== "5150" && (
                    <Tooltip
                      title={`${
                        user?.login_blocked ? "Unlock" : "Lock"
                      } User Login`}
                    >
                      <div
                        className={`accountLocked ${
                          user?.login_blocked ? "locked" : "unlocked"
                        }`}
                        onClick={(e) =>
                          lockUserAccount(
                            e,
                            i,
                            user?.id,
                            user?.login_blocked,
                            user?.first_name,
                            user?.last_name
                          )
                        }
                      >
                        <span>
                          {user?.login_blocked ? (
                            <MdOutlineLock />
                          ) : (
                            <MdOutlineLockOpen />
                          )}
                        </span>
                      </div>
                    </Tooltip>
                  )}
                  {user?.role !== "127001" && user?.role !== "5150" && (
                    <Tooltip
                      title={`${
                        user?.reviews_blocked ? "Unlock" : "Lock"
                      } User Reviews`}
                    >
                      <div
                        className={`reviewsLocked ${
                          user?.reviews_blocked ? "locked" : "unlocked"
                        }`}
                        onClick={(e) =>
                          lockUserReviews(
                            e,
                            i,
                            user?.id,
                            user?.reviews_blocked,
                            user?.first_name,
                            user?.last_name
                          )
                        }
                      >
                        <span>
                          {user?.reviews_blocked ? (
                            <IoMdCloseCircleOutline />
                          ) : (
                            <IoMdCheckmarkCircleOutline />
                          )}
                        </span>
                      </div>
                    </Tooltip>
                  )}
                  {user?.role == "2002" && (
                    <Tooltip title="Profile Preview">
                      <div
                        className="preview"
                        onClick={(e) => viewProfile(user?.slug)}
                      >
                        <span>
                          <MdVisibility />
                        </span>
                      </div>
                    </Tooltip>
                  )}
                  {user?.role !== "127001" && (
                    <Tooltip title="Edit User">
                      <div
                        className="edit"
                        onClick={(e) => editUser(e, i, user)}
                      >
                        <span>
                          <MdOutlineEdit />
                        </span>
                      </div>
                    </Tooltip>
                  )}
                  {user?.role !== "127001" && (
                    <Tooltip title="Delete User">
                      <div
                        className="delete"
                        onClick={(e) =>
                          deleteUser(
                            e,
                            i,
                            user?.first_name,
                            user?.last_name,
                            user?.id
                          )
                        }
                      >
                        <span>
                          <AiFillDelete />
                        </span>
                      </div>
                    </Tooltip>
                  )}
                </div>
              </li>
            ))}
          </ul>

          {/* Edit User Subscription */}
          <Dialog
            open={openEditSubscription}
            onClose={handleCloseEditSubscription}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            id="editSubscriptionPopup"
          >
            <DialogTitle id="alert-dialog-title">
              {`Subscription Details`}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {subActiveState ? (
                  <>
                    <h4>Active State</h4>
                    {states?.length ? (
                      <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <Select
                          id="active_state"
                          name="active_state"
                          value={
                            subActiveState.Code
                              ? subActiveState.Code
                              : subActiveState
                          }
                          onChange={handleChangeState}
                        >
                          {states?.map((s, i) => (
                            <MenuItem key={i} value={s.Code}>
                              {s.State}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      "No states available"
                    )}
                  </>
                ) : null}

                <h4>
                  Counties (max {subPackageDetails?.num_allowed_counties})
                </h4>
                <ul className="marketingCounties">
                  {subActiveCounties.map((county, i) => (
                    <li
                      onClick={(e) =>
                        removeSubCounty(e, i, subActiveCounties, county)
                      }
                    >
                      {county}
                    </li>
                  ))}
                  {/* {console.log("subActiveCounties",subActiveCounties)} */}
                  {subActiveCounties.length <
                  subPackageDetails?.num_allowed_counties || !subActiveCounties.length ? (
                    <li className="addItem" onClick={addSubCounty}>
                      <MdAddCircleOutline />
                    </li>
                  ) : null}
                </ul>

                <h4>
                  Categories (max{" "}
                  {(activePackages && activePackages?.length && activePackages[0])
                    && (activePackages[0]?.num_allowed_services > subPackageDetails?.num_allowed_services) 
                    && (subMaxPaidCategories > activePackages[0]?.num_allowed_services)
                      ? subMaxPaidCategories
                      : (!activePackages || !activePackages?.length || (activePackages && activePackages?.length && !activePackages[0]) 
                        || (activePackages[0] && (activePackages[0]?.num_allowed_services <= subPackageDetails?.num_allowed_services)))
                        && (subMaxPaidCategories > subPackageDetails?.num_allowed_services)
                      ? subMaxPaidCategories
                      : (activePackages && activePackages?.length && activePackages[0])
                      && (activePackages[0]?.num_allowed_services > subPackageDetails?.num_allowed_services)
                      && subMaxPaidCategories < activePackages[0]?.num_allowed_services
                      ? activePackages[0]?.num_allowed_services
                      : subPackageDetails?.num_allowed_services
                  }
                  )
                </h4>
                <ul className="marketingCategories">
                  {subCategories.map((category, i) => (
                    <li
                      onClick={(e) =>
                        removeSubCategory(e, i, subCategories, category)
                      }
                    >
                      {category}
                    </li>
                  ))}
                  {(subCategories?.length <
                  (currentAllowedServices || !subCategories.length) || currentMaxAllowedServices === -1) ? (
                    <li className="addItem" onClick={addSubCategory}>
                      <MdAddCircleOutline />
                    </li>
                  ) : null}
                </ul>

                <h4>Excluded Zipcodes</h4>
                <ul className="marketingZipcodes">
                  {subExcludedZips.map((zips, i) => (
                    <li
                      onClick={(e) =>
                        removeSubZipcode(e, i, subExcludedZips, zips)
                      }
                    >
                      {zips}
                    </li>
                  ))}
                  <li className="addItem" onClick={addSubZipExclusion}>
                    <MdAddCircleOutline />
                  </li>
                </ul>

                {subOptions?.length ? (
                  <>
                    <h4>Selected Options</h4>
                    <ul>
                      {subOptions.map((option, i) => (
                        <li>{option}</li>
                      ))}
                    </ul>
                  </>
                ) : null}
                {subscriptionDetails?.subscription_charge ? (
                  <>
                    <h4>Subscription Charge</h4>
                    <p>${subscriptionDetails?.subscription_charge}</p>
                  </>
                ) : null}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <button className="" onClick={(e) => handleSaveEditSubscription(e)}>
                Save
              </button>
              <button className="cancel" onClick={handleCloseEditSubscription}>
                Cancel
              </button>
            </DialogActions>
          </Dialog>

          {/* Add County */}
          <Dialog
            open={openAddCounty}
            onClose={handleCloseAddCounty}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            id="addCountyPopup"
          >
            <DialogTitle id="alert-dialog-title">
              {`Adding county to subscription`}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Add county to this subscription?
              </DialogContentText>
              <ul className="marketingCounties">
                {subscriptionData.counties.Colorado ? subscriptionData.counties.Colorado.map((county, i) => {
                  return (
                    <li className={subActiveCounties.includes(county?.county) ? 'active' : null} key={i} 
                      onClick={() => toggleCounty(county.county)}
                    >
                      <div className="county">
                        {county?.county}
                      </div>
                    </li>)
                }) : <></>}
              </ul>
            </DialogContent>
            <DialogActions>
              <button className="cancel" onClick={handleCloseAddCounty}>
                Back
              </button>
              {/* <button className="confirm" onClick={handleAddSubCounty}>Yes, add county</button> */}
            </DialogActions>
          </Dialog>

          {/* Add Category */}
          <Dialog
            open={openAddCategory}
            onClose={handleCloseAddCategory}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            id="addCategoryPopup"
          >
            <DialogTitle id="alert-dialog-title">
              {`Adding category to subscription`}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Add category to this subscription?
              </DialogContentText>
              <ul className="marketingCategories">
                {subscriptionData?.categories?.map((service, i) =>
                  <li className={subCategories.includes(service?.category_name) ? 'active' : null} key={i} 
                    onClick={(e) => toggleService(e, i, service?.category_name)}
                  >
                    <div className="category">
                      {service?.category_name}
                    </div>
                  </li>
                )}
              </ul>
            </DialogContent>
            <DialogActions>
              <button className="cancel" onClick={handleCloseAddCategory}>
                Back
              </button>
              {/* <button className="confirm" onClick={handleAddSubCategory}>Yes, add category</button> */}
            </DialogActions>
          </Dialog>

          {/* Add Zip Exclusion */}
          <Dialog
            open={openAddZipExclusion}
            onClose={handleCloseAddZipExclusion}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            id="addZipExclusionPopup"
          >
            <DialogTitle id="alert-dialog-title">
              {`Adding zipcode exlusion to subscription`}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Add zipcode exclusion to this subscription?
              </DialogContentText>

              <ul className="marketingZipcodes">
                {stateZips?.map((zip, i) =>
                  <li className={subExcludedZips.includes(zip?.zipcode) ? 'active' : null} key={i} 
                    onClick={() => toggleZip(zip?.zipcode)}
                  >
                    <div className="zipcode">
                      {zip?.zipcode}
                    </div>
                  </li>
                )}
              </ul>
            </DialogContent>
            <DialogActions>
              <button className="cancel" onClick={handleCloseAddZipExclusion}>
                Back
              </button>
              {/* <button className="confirm" onClick={handleAddSubZipExclusion}>Yes, add zipcode</button> */}
            </DialogActions>
          </Dialog>

          {/* Confirm Delete County */}
          <Dialog
            open={openConfirmDeleteCounty}
            onClose={handleCloseConfirmDeleteCounty}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            id="confirmDeleteCountyPopup"
          >
            <DialogTitle id="alert-dialog-title">
              {`Are you sure you want to delete?`}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete '{deleteSubCounty}' county from
                this subscription?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <button
                className="cancel"
                onClick={handleCloseConfirmDeleteCounty}
              >
                Cancel
              </button>
              <button className="confirm" onClick={handleRemoveSubCounty}>
                Yes, delete county
              </button>
            </DialogActions>
          </Dialog>

          {/* Confirm Delete Category */}
          <Dialog
            open={openConfirmDeleteCategory}
            onClose={handleCloseConfirmDeleteCategory}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            id="confirmDeleteCategoryPopup"
          >
            <DialogTitle id="alert-dialog-title">
              {`Are you sure you want to delete?`}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete '{deleteSubCategory}' category
                from this subscription?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <button
                className="cancel"
                onClick={handleCloseConfirmDeleteCategory}
              >
                Cancel
              </button>
              <button className="confirm" onClick={handleRemoveSubCategory}>
                Yes, delete category
              </button>
            </DialogActions>
          </Dialog>

          {/* Confirm Delete Zipcode */}
          <Dialog
            open={openConfirmDeleteZipcode}
            onClose={handleCloseConfirmDeleteZipcode}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            id="confirmDeleteZipcodePopup"
          >
            <DialogTitle id="alert-dialog-title">
              {`Are you sure you want to delete?`}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete '{deleteSubZip}' zipcode
                exclusion from this subscription?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <button
                className="cancel"
                onClick={handleCloseConfirmDeleteZipcode}
              >
                Cancel
              </button>
              <button className="confirm" onClick={handleRemoveSubZipcode}>
                Yes, delete zipcode
              </button>
            </DialogActions>
          </Dialog>

          {/* Block User Login */}
          <Dialog
            open={openBlockUserLogin}
            onClose={handleCloseBlockUserLogin}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {`Are you sure you want to ${
                blockedLoginState ? "unblock" : "block"
              } this users login?`}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to{" "}
                {blockedLoginState ? "unblock" : "block"} the login for "
                {blockLoginName}".
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <button className="cancel" onClick={handleCloseBlockUserLogin}>
                Cancel
              </button>
              <button className="confirm" onClick={handleBlockUserLogin}>
                Yes, {blockedLoginState ? "unblock" : "block"} login
              </button>
            </DialogActions>
          </Dialog>

          {/* Block User Review */}
          <Dialog
            open={openBlockUserReview}
            onClose={handleCloseBlockUserReview}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {`Are you sure you want to ${
                blockedReviewState ? "unblock" : "block"
              } reviews for this users?`}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to{" "}
                {blockedReviewState ? "unblock" : "block"} reviews for "
                {blockReviewName}".
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <button className="cancel" onClick={handleCloseBlockUserReview}>
                Cancel
              </button>
              <button className="confirm" onClick={handleBlockUserReview}>
                Yes, {blockedReviewState ? "unblock" : "block"} reviews
              </button>
            </DialogActions>
          </Dialog>

          {/* Delete User */}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Are you sure you want to delete this user?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This action cannot be undone. This will permanently delete the
                user with name, "{deleteName}".
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <button className="cancel" onClick={handleClose}>
                Cancel
              </button>
              <button className="confirm" onClick={handleDelete}>
                Yes, delete user
              </button>
            </DialogActions>
          </Dialog>

          {/* Add/Edit User */}
          <Dialog
            fullScreen
            open={openAdd}
            onClose={handleCloseAdd}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    closeAdminForm()
                    handleCloseAdd()
                  }}
                  aria-label="close"
                >
                  <MdClose />
                </IconButton>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  {addEditUser == "add"
                    ? "Add New User"
                    : type == 2002
                    ? `Editing ${businessName}`
                    : `Editing ${fName} ${lName}`}
                </Typography>
                {type == 2002 && addEditUser == "add" && (
                  <Button
                    disabled={
                      !validEmail ||
                      !validPwd ||
                      !validMatch ||
                      !validState ||
                      !validCity ||
                      !validZip ||
                      !validPortfolio ||
                      !validVideos
                        ? true
                        : false
                    }
                    autoFocus
                    color="inherit"
                    onClick={(e) => handleAddNewUser(e)}
                  >
                    save
                  </Button>
                )}
                {type == 2002 && addEditUser == "edit" && (
                  <Button
                    disabled={
                      !validEmail || !validState || !validCity || !validZip
                        ? true
                        : false
                    }
                    autoFocus
                    color="inherit"
                    onClick={(e) => handleAddNewUser(e)}
                  >
                    save
                  </Button>
                )}
                {type == 1001 && addEditUser == "add" && (
                  <Button
                    disabled={
                      !validEmail || !validPwd || !validMatch || !validZip
                        ? true
                        : false
                    }
                    autoFocus
                    color="inherit"
                    onClick={(e) => handleAddNewUser(e)}
                  >
                    save
                  </Button>
                )}
                {type == 1001 && addEditUser == "edit" && (
                  <Button
                    disabled={!validEmail || !validZip ? true : false}
                    autoFocus
                    color="inherit"
                    onClick={(e) => handleAddNewUser(e)}
                  >
                    save
                  </Button>
                )}
                {type == 5150 && addEditUser == "add" && (
                  <Button
                    disabled={
                      !validEmail || !validPwd || !validMatch ? true : false
                    }
                    autoFocus
                    color="inherit"
                    onClick={(e) => handleAddNewUser(e)}
                  >
                    save
                  </Button>
                )}
                {type == 5150 && addEditUser == "edit" && (
                  <Button
                    disabled={!validEmail ? true : false}
                    autoFocus
                    color="inherit"
                    onClick={(e) => handleAddNewUser(e)}
                  >
                    save
                  </Button>
                )}
              </Toolbar>
            </AppBar>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {(() => {
                  // console.log("the anon function with the user sets")
                  if(openAdminForm && state) {
                    // handleSetUserState(state)
                    setUserState(state)
                  }
                  if(openAdminForm && zip) {
                    handleSetUserZip(zip)
                    // setUserZip(zip)
                  }
                })()}
                {openAdminForm && <AdminAccountConfigure userData={allUserData}/>}
                <form encType="multipart/form-data" className="adminAddUser" onSubmit={() => {console.log("hey this is the outer form, so yeah")}}>
                  {/* Role */}
                  <label htmlFor="type">Account Type:</label>
                  <div className="inputWrapper">
                    <select
                      id="type"
                      onChange={(e) => handleSetType(e)}
                      value={type}
                      required
                      onFocus={() => setTypeFocus(true)}
                      onBlur={() => setTypeFocus(false)}
                    >
                      <option value="1001">Homeowner</option>
                      <option value="2002">Home Pro</option>
                      <option value="5150">Admin</option>
                    </select>
                  </div>

                  {type === "2002" && (
                    <>
                      <section id="subscriptionDetails">
                        {/* Subscription Details */}
                        
                       
                        {/* {(openAdminForm && state) ? handleSetUserState(state) : <></>}
                        {(openAdminForm && zip) ? handleSetUserZip(zip) : <></>} */}
                      
                        <label htmlFor="subscription">
                          Subscription Status:{" "}
                          <span
                            onClick={editUserSubscription}
                            class={`status ${subscriptionStatus}`}
                          >
                            {subscriptionStatus}
                            {subscriptionStatus == "active" && (
                              <MdOutlineEdit />
                            )}
                          </span>
                        </label>
                      </section>
                      <section>
                      {/* {console.log("current subscriptionStatus", subscriptionStatus)}
                      {console.log("subscriptionStatus === canceled", subscriptionStatus === "canceled")} */}
                      {((subscriptionStatus === "" || subscriptionStatus === "chosen" || subscriptionStatus === "canceled" || subscriptionStatus === undefined) 
                      && addEditUser !== "add") &&
                          <button onClick={(e) => openAdminFormDisplay(e)}>
                            Create Subscription
                          </button>

                        }
                      </section>

                      <section id="featuredContractor">
                        <h3>Pro Spotlight Featured Contractor</h3>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={proSpotlight}
                                onChange={handleProSpotlight}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Featured"
                          />
                        </FormGroup>
                      </section>

                      <section id="priorityListing">
                        <h3>Priority Listing</h3>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={priorityListing}
                                onChange={handlePriorityListing}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Priority"
                          />
                        </FormGroup>
                      </section>
                    </>
                  )}

                  <section id="profilePhotos">
                    <h3>Profile Photos</h3>
                    <div className="formContentWrapper">
                      <div className="column" id="profile">
                        {profileImage && (
                          <img
                            src={`https://storage.iyh.app/${profileImage}`}
                          />
                        )}
                        <SingleFileDropEmbed
                          accept="image/*"
                          buttonTitle={
                            type == 2002 ? "Business Logo" : "Profile Picture"
                          }
                          files={files}
                          setFiles={setFiles}
                          validFiles={validFiles}
                          setValidFiles={setValidFiles}
                        />
                      </div>
                      {type == 2002 && (
                        <div className="column" id="coverPhoto">
                          {profileCover && (
                            <img
                              src={`https://storage.iyh.app/${profileCover}`}
                            />
                          )}
                          <CoverDropEmbed
                            accept="image/*"
                            buttonTitle="Cover Photo"
                            cover={cover}
                            setCover={setCover}
                            validCover={validCover}
                            setValidCover={setValidCover}
                          />
                        </div>
                      )}
                    </div>
                  </section>
                  {/* <p className="required">Fields marked with an <span>*</span> are required</p> */}
                  {type == 2002 && (
                    <>
                      <section id="businessInfo">
                        <h3>Business Info</h3>

                        <div className="formContentWrapper">
                          {/* Business Name */}
                          <div className="column">
                            <label htmlFor="business">
                              Business Name:<span className="required">*</span>
                            </label>
                            <div className="inputWrapper">
                              <span
                                className={validBusinessName ? "valid" : "hide"}
                              >
                                <FaCheck />
                              </span>
                              <span
                                className={
                                  validBusinessName || !businessName
                                    ? "hide"
                                    : "invalid"
                                }
                              >
                                <FaExclamation />
                              </span>
                              <input
                                type="text"
                                id="business"
                                className={
                                  validBusinessName || !businessName
                                    ? null
                                    : "error"
                                }
                                ref={businessNameRef}
                                autoComplete="off"
                                onChange={(e) =>
                                  setBusinessName(e.target.value)
                                }
                                value={businessName}
                                required
                                aria-invalid={
                                  validBusinessName ? "false" : "true"
                                }
                                aria-describedby="urlnote"
                                onFocus={() => setBusinessNameFocus(true)}
                                onBlur={() => setBusinessNameFocus(false)}
                              />
                            </div>
                            <p
                              id="urlnote"
                              className={
                                businessNameFocus &&
                                businessName &&
                                !validBusinessName
                                  ? "instructions"
                                  : "offscreen"
                              }
                            >
                              Invalid Business Name.
                            </p>
                          </div>

                          {/* Business License */}
                          <div className="column">
                            <label htmlFor="license">Business License:</label>
                            <div className="inputWrapper">
                              <span
                                className={
                                  validBusinessLicense ? "valid" : "hide"
                                }
                              >
                                <FaCheck />
                              </span>
                              <span
                                className={
                                  validBusinessLicense || !businessLicense
                                    ? "hide"
                                    : "invalid"
                                }
                              >
                                <FaExclamation />
                              </span>
                              <input
                                type="text"
                                id="license"
                                className={
                                  validBusinessLicense || !businessLicense
                                    ? null
                                    : "error"
                                }
                                ref={businessLicenseRef}
                                autoComplete="off"
                                onChange={(e) =>
                                  setBusinessLicense(e.target.value)
                                }
                                value={businessLicense}
                                aria-invalid={
                                  validBusinessLicense ? "false" : "true"
                                }
                                aria-describedby="licensenote"
                                onFocus={() => setBusinessLicenseFocus(true)}
                                onBlur={() => setBusinessLicenseFocus(false)}
                              />
                            </div>
                            <p
                              id="licensenote"
                              className={
                                businessLicenseFocus &&
                                businessLicense &&
                                !validBusinessLicense
                                  ? "instructions"
                                  : "offscreen"
                              }
                            >
                              Invalid Business License.
                            </p>
                          </div>

                          {/* Contractor Specialty */}
                          <div className="column">
                            <label htmlFor="license">Business Tagline:</label>
                            <div className="inputWrapper">
                              <span
                                className={
                                  validBusinessSpecialty ? "valid" : "hide"
                                }
                              >
                                <FaCheck />
                              </span>
                              <span
                                className={
                                  validBusinessSpecialty || !businessSpecialty
                                    ? "hide"
                                    : "invalid"
                                }
                              >
                                <FaExclamation />
                              </span>
                              <input
                                type="text"
                                id="license"
                                className={
                                  validBusinessLicense || !businessLicense
                                    ? null
                                    : "error"
                                }
                                ref={businessLicenseRef}
                                autoComplete="off"
                                placeholder="ie. Residential Roofer Contractor, etc"
                                onChange={(e) =>
                                  setBusinessSpecialty(e.target.value)
                                }
                                value={businessSpecialty}
                                aria-invalid={'false'
                                  //validBusinessSpecialty ? "false" : "true"
                                }
                                aria-describedby="specialtynote"
                                onFocus={() => setBusinessSpecialtyFocus(true)}
                                onBlur={() => setBusinessSpecialtyFocus(false)}
                              />
                            </div>
                            <p
                              id="specialtynote"
                              className={
                                businessSpecialtyFocus &&
                                businessSpecialty &&
                                !validBusinessSpecialty
                                  ? "instructions"
                                  : "offscreen"
                              }
                            >
                              Invalid Business Tagline. Letters, numbers and
                              special characters (-_/,#&) allowed.
                            </p>
                          </div>

                          {/* Website */}
                          <div className="column">
                            <label htmlFor="website">Website:</label>
                            <div className="inputWrapper">
                              <span className={validUrl ? "valid" : "hide"}>
                                <FaCheck />
                              </span>
                              <span
                                className={
                                  validUrl || !url ? "hide" : "invalid"
                                }
                              >
                                <FaExclamation />
                              </span>
                              <input
                                type="url"
                                id="website"
                                className={validUrl || !url ? null : "error"}
                                ref={urlRef}
                                autoComplete="off"
                                placeholder="https://"
                                onChange={(e) => setUrl(e.target.value)}
                                value={url}
                                aria-invalid={validUrl ? "false" : "true"}
                                aria-describedby="urlnote"
                                onFocus={() => setUrlFocus(true)}
                                onBlur={() => setUrlFocus(false)}
                              />
                            </div>
                            <p
                              id="urlnote"
                              className={
                                urlFocus && url && !validUrl
                                  ? "instructions"
                                  : "offscreen"
                              }
                            >
                              Invalid website url. Must start with http:// or
                              https://
                            </p>
                          </div>
                        </div>
                      </section>
                    </>
                  )}

                  <section id="businessContact">
                    <h3>{type == 2002 ? "Business" : null} Contact</h3>

                    <div className="formContentWrapper">
                      {type == 2002 && (
                        <div id="contactVisibilityWrapper">
                          {/* Contact Visibility */}
                          <label htmlFor="contactVisibility">
                            Contact Name Profile Visability:
                          </label>
                          <FormGroup id="contactVisibility">
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={contactVisibility}
                                  onChange={handleContactDisplay}
                                />
                              }
                              label={`Contact Name is ${
                                contactVisibility ? "visable" : "hidden"
                              } on your profile`}
                            />
                          </FormGroup>
                          <br />
                        </div>
                      )}

                      {/* First Name */}
                      <div className="column">
                        <label htmlFor="fname">
                          First Name:<span className="required">*</span>
                        </label>
                        <div className="inputWrapper">
                          <span className={validFName ? "valid" : "hide"}>
                            <FaCheck />
                          </span>
                          <span
                            className={
                              validFName || !fName ? "hide" : "invalid"
                            }
                          >
                            <FaExclamation />
                          </span>
                          <input
                            type="text"
                            id="fname"
                            className={validFName || !fName ? null : "error"}
                            onChange={(e) => setFName(e.target.value)}
                            value={fName}
                            required
                            aria-invalid={validFName ? "false" : "true"}
                            aria-describedby="fnamenote"
                            onFocus={() => setFNameFocus(true)}
                            onBlur={() => setFNameFocus(false)}
                          />
                        </div>
                        <p
                          id="fnamenote"
                          className={
                            fNameFocus && fName && !validFName
                              ? "instructions"
                              : "offscreen"
                          }
                        >
                          Invalid First Name.
                        </p>
                      </div>

                      {/* Last Name */}
                      <div className="column">
                        <label htmlFor="lname">
                          Last Name:<span className="required">*</span>
                        </label>
                        <div className="inputWrapper">
                          <span className={validLName ? "valid" : "hide"}>
                            <FaCheck />
                          </span>
                          <span
                            className={
                              validLName || !lName ? "hide" : "invalid"
                            }
                          >
                            <FaExclamation />
                          </span>
                          <input
                            type="text"
                            id="lname"
                            className={validLName || !lName ? null : "error"}
                            onChange={(e) => setLName(e.target.value)}
                            value={lName}
                            required
                            aria-invalid={validLName ? "false" : "true"}
                            aria-describedby="lnamenote"
                            onFocus={() => setLNameFocus(true)}
                            onBlur={() => setLNameFocus(false)}
                          />
                        </div>
                        <p
                          id="lnamenote"
                          className={
                            lNameFocus && lName && !validLName
                              ? "instructions"
                              : "offscreen"
                          }
                        >
                          Invalid Last Name.
                        </p>
                      </div>

                      {/* Email */}
                      <div className="column">
                        <label htmlFor="email">
                          Email:<span className="required">*</span>
                        </label>
                        <div className="inputWrapper">
                          <span className={validEmail ? "valid" : "hide"}>
                            <FaCheck />
                          </span>
                          <span
                            className={
                              validEmail || !email ? "hide" : "invalid"
                            }
                          >
                            <FaExclamation />
                          </span>
                          <input
                            type="email"
                            id="email"
                            className={validEmail || !email ? null : "error"}
                            ref={emailRef}
                            autoComplete="off"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            required
                            disabled={addEditUser == "add" ? false : true}
                            aria-invalid={validEmail ? "false" : "true"}
                            aria-describedby="uidnote"
                            onFocus={() => setEmailFocus(true)}
                            onBlur={() => setEmailFocus(false)}
                          />
                        </div>
                        <p
                          id="uidnote"
                          className={
                            emailFocus && email && !validEmail
                              ? "instructions"
                              : "offscreen"
                          }
                        >
                          Invalid email address.
                        </p>
                      </div>

                      {type == 2002 && (
                        <>
                          {/* Profile Email */}
                          <div className="column">
                            <label htmlFor="email">Profile Email:</label>
                            <div className="inputWrapper">
                              <span
                                className={validProfileEmail ? "valid" : "hide"}
                              >
                                <FaCheck />
                              </span>
                              <span
                                className={
                                  validProfileEmail || !profileEmail
                                    ? "hide"
                                    : "invalid"
                                }
                              >
                                <FaExclamation />
                              </span>
                              <input
                                type="email"
                                id="profileEmail"
                                className={
                                  validProfileEmail || !profileEmail
                                    ? null
                                    : "error"
                                }
                                ref={profileEmailRef}
                                autoComplete="off"
                                onChange={(e) =>
                                  setProfileEmail(e.target.value)
                                }
                                value={profileEmail}
                                aria-invalid={
                                  validProfileEmail ? "false" : "true"
                                }
                                aria-describedby="profileEmailNote"
                                onFocus={() => setProfileEmailFocus(true)}
                                onBlur={() => setProfileEmailFocus(false)}
                              />
                            </div>
                            <p
                              id="profileEmailNote"
                              className={
                                profileEmailFocus &&
                                profileEmail &&
                                !validProfileEmail
                                  ? "instructions"
                                  : "offscreen"
                              }
                            >
                              Invalid email address.
                            </p>
                          </div>
                        </>
                      )}

                      {/* Phone */}
                      {type !== "5150" && (
                        <div className="column">
                          <label htmlFor="phone">
                            Phone:
                            {type !== "1001" && (
                              <span className="required">*</span>
                            )}
                          </label>
                          <div className="inputWrapper">
                            <span className={validPhone ? "valid" : "hide"}>
                              <FaCheck />
                            </span>
                            <span
                              className={
                                validPhone || !phone ? "hide" : "invalid"
                              }
                            >
                              <FaExclamation />
                            </span>
                            <input
                              type="tel"
                              id="phone"
                              className={validPhone || !phone ? null : "error"}
                              ref={phoneRef}
                              autoComplete="off"
                              onChange={(e) => setPhone(e.target.value)}
                              value={phone}
                              required
                              aria-invalid={validPhone ? "false" : "true"}
                              aria-describedby="phonenote"
                              onFocus={() => setPhoneFocus(true)}
                              onBlur={() => setPhoneFocus(false)}
                            />
                          </div>
                          <p
                            id="phonenote"
                            className={
                              phoneFocus && phone && !validPhone
                                ? "instructions"
                                : "offscreen"
                            }
                          >
                            Invalid phone number.
                          </p>
                        </div>
                      )}

                      {/* Callcap Number */}
                      {type !== "5150" && (
                        <div className="column">
                          <label htmlFor="phone">
                            Callcap Number:
                            {type !== "1001" && (
                              <span className="required">*</span>
                            )}
                          </label>
                          <div className="inputWrapper">
                            <span className={validCallcap ? "valid" : "hide"}>
                              <FaCheck />
                            </span>
                            <span
                              className={
                                validCallcap || !callcap ? "hide" : "invalid"
                              }
                            >
                              <FaExclamation />
                            </span>
                            <input
                              type="tel"
                              id="phone"
                              className={
                                validCallcap || !callcap ? null : "error"
                              }
                              ref={callcapRef}
                              autoComplete="off"
                              onChange={(e) => setCallcap(e.target.value)}
                              value={callcap}
                              required
                              disabled={overrideCallCap}
                              aria-invalid={validCallcap ? "false" : "true"}
                              aria-describedby="callcapnote"
                              onFocus={() => setCallcapFocus(true)}
                              onBlur={() => setCallcapFocus(false)}
                            />
                            {overrideCallCap && (
                              <span
                                className="overrideCallCap"
                                onClick={(e) => setOverrideCallCap(false)}
                              >
                                Override CallCap Number
                              </span>
                            )}
                          </div>
                          <p
                            id="callcapnote"
                            className={
                              callcapFocus && callcap && !validCallcap
                                ? "instructions"
                                : "offscreen"
                            }
                          >
                            Invalid Callcap number.
                          </p>
                        </div>
                      )}

                      {type == 1001 && (
                        <>
                          {/* Zip */}
                          <div className="column">
                            <label htmlFor="zip">
                              Zip:<span className="required">*</span>
                            </label>
                            <div className="inputWrapper">
                              <span className={validZip ? "valid" : "hide"}>
                                <FaCheck />
                              </span>
                              <span
                                className={
                                  validZip || !zip ? "hide" : "invalid"
                                }
                              >
                                <FaExclamation />
                              </span>
                              <input
                                type="text"
                                id="zip"
                                className={validZip || !zip ? null : "error"}
                                ref={zipRef}
                                autoComplete="off"
                                onChange={(e) => setZip(e.target.value)}
                                value={zip}
                                required
                                aria-invalid={validZip ? "false" : "true"}
                                aria-describedby="citynote"
                                onFocus={() => setZipFocus(true)}
                                onBlur={() => setZipFocus(false)}
                              />
                            </div>
                            <p
                              id="citynote"
                              className={
                                zipFocus && zip && !validZip
                                  ? "instructions"
                                  : "offscreen"
                              }
                            >
                              Invalid Zip. Valid Format includes 55555 or
                              55555-5555.
                            </p>
                          </div>

                          {/* Address */}
                          <div className="column">
                            <label htmlFor="address">
                              Address:{" "}
                              <span>
                                (Optional: Used for autofill of projects)
                              </span>
                            </label>
                            <div className="inputWrapper">
                              <span className={validAddress ? "valid" : "hide"}>
                                <FaCheck />
                              </span>
                              <span
                                className={
                                  validAddress || !address ? "hide" : "invalid"
                                }
                              >
                                <FaExclamation />
                              </span>
                              <input
                                type="text"
                                id="address"
                                className={
                                  validAddress || !address ? null : "error"
                                }
                                ref={addressRef}
                                autoComplete="off"
                                onChange={(e) => setAddress(e.target.value)}
                                value={address}
                                required
                                aria-invalid={validAddress ? "false" : "true"}
                                aria-describedby="addressnote"
                                onFocus={() => setAddressFocus(true)}
                                onBlur={() => setAddressFocus(false)}
                              />
                            </div>
                            <p
                              id="addressnote"
                              className={
                                addressFocus && address && !validAddress
                                  ? "instructions"
                                  : "offscreen"
                              }
                            >
                              Invalid Address. Valid Format includes letters,
                              numbers and special characters (-_/:#.&*, ).
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </section>

                  {type == 2002 && (
                    <>
                      <section id="businessInfo">
                        <h3>Business Location</h3>
                        <div className="formContentWrapper">
                          {/* Showroom/Business Address */}
                          <div className="column">
                            <label htmlFor="address">Business Address:</label>
                            <div className="inputWrapper">
                              <span className={validAddress ? "valid" : "hide"}>
                                <FaCheck />
                              </span>
                              <span
                                className={
                                  validAddress || !address ? "hide" : "invalid"
                                }
                              >
                                <FaExclamation />
                              </span>
                              <input
                                type="text"
                                id="address"
                                className={
                                  validAddress || !address ? null : "error"
                                }
                                ref={addressRef}
                                autoComplete="off"
                                onChange={(e) => setAddress(e.target.value)}
                                value={address}
                                required
                                aria-invalid={validAddress ? "false" : "true"}
                                aria-describedby="addressnote"
                                onFocus={() => setAddressFocus(true)}
                                onBlur={() => setAddressFocus(false)}
                              />
                            </div>
                            <p
                              id="addressnote"
                              className={
                                addressFocus && address && !validAddress
                                  ? "instructions"
                                  : "offscreen"
                              }
                            >
                              Invalid Address. Valid Format includes letters,
                              numbers and special characters (-_/:#.&*, ).
                            </p>
                          </div>

                          {/* City */}
                          <div className="column">
                            <label htmlFor="city">
                              City:<span className="required">*</span>
                            </label>
                            <div className="inputWrapper">
                              <span className={validCity ? "valid" : "hide"}>
                                <FaCheck />
                              </span>
                              <span
                                className={
                                  validCity || !city ? "hide" : "invalid"
                                }
                              >
                                <FaExclamation />
                              </span>
                              <input
                                type="text"
                                id="city"
                                className={validCity || !city ? null : "error"}
                                ref={cityRef}
                                autoComplete="off"
                                onChange={(e) => setCity(e.target.value)}
                                value={city}
                                required
                                aria-invalid={validCity ? "false" : "true"}
                                aria-describedby="citynote"
                                onFocus={() => setCityFocus(true)}
                                onBlur={() => setCityFocus(false)}
                              />
                            </div>
                            <p
                              id="citynote"
                              className={
                                cityFocus && city && !validCity
                                  ? "instructions"
                                  : "offscreen"
                              }
                            >
                              Invalid City.
                            </p>
                          </div>

                          {/* State */}
                          <div className="column">
                            <label htmlFor="state">
                              State:<span className="required">*</span>
                            </label>
                            <div className="inputWrapper">
                              <select
                                id="state"
                                onChange={(e) => setState(e.target.value)}
                                value={state}
                                required
                                onFocus={() => setStateFocus(true)}
                                onBlur={() => setStateFocus(false)}
                              >
                                <option value="">Select State</option>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District Of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                              </select>
                            </div>
                          </div>

                          {/* Zip */}
                          <div className="column">
                            <label htmlFor="zip">
                              Zip:<span className="required">*</span>
                            </label>
                            <div className="inputWrapper">
                              <span className={validZip ? "valid" : "hide"}>
                                <FaCheck />
                              </span>
                              <span
                                className={
                                  validZip || !zip ? "hide" : "invalid"
                                }
                              >
                                <FaExclamation />
                              </span>
                              <input
                                type="text"
                                id="zip"
                                className={validZip || !zip ? null : "error"}
                                ref={zipRef}
                                autoComplete="off"
                                onChange={(e) => setZip(e.target.value)}
                                value={zip}
                                required
                                aria-invalid={validZip ? "false" : "true"}
                                aria-describedby="citynote"
                                onFocus={() => setZipFocus(true)}
                                onBlur={() => setZipFocus(false)}
                              />
                            </div>
                            <p
                              id="citynote"
                              className={
                                zipFocus && zip && !validZip
                                  ? "instructions"
                                  : "offscreen"
                              }
                            >
                              Invalid Zip. Valid Format includes 55555 or
                              55555-5555.
                            </p>
                          </div>
                        </div>
                      </section>
                    </>
                  )}

                  {type == 2002 && (
                    <>
                      <section id="businessAbout">
                        <h3>About Your Business</h3>

                        <div className="formContentWrapper">
                          {/* Description of Services */}
                          <div className="column">
                            <label htmlFor="description">
                              Description of Services:
                            </label>
                            <div className="inputWrapper">
                              <textarea
                                ref={descriptionRef}
                                className={
                                  validDescription || !description
                                    ? null
                                    : "error"
                                }
                                onChange={(e) => setDescription(e.target.value)}
                                name="description"
                                id="description"
                                rows="5"
                                value={description}
                              ></textarea>
                              <span className="charCount">
                                {description?.length}/1000
                              </span>
                            </div>
                          </div>

                          {/* About  Us */}
                          <div className="column">
                            <label htmlFor="about">About Us:</label>
                            <div className="inputWrapper">
                              <textarea
                                ref={aboutRef}
                                className={
                                  validAbout || !about ? null : "error"
                                }
                                onChange={(e) => setAbout(e.target.value)}
                                name="about"
                                id="about"
                                rows="5"
                                value={about}
                              ></textarea>
                              <span className="charCount">
                                {about?.length}/1000
                              </span>
                            </div>
                          </div>

                          {/* Certificates & Awards */}
                          <div className="column">
                            <label htmlFor="certificates">
                              Certificates & Awards:
                            </label>
                            <div className="inputWrapper">
                              <textarea
                                ref={certificatesRef}
                                className={
                                  validCertificates || !certificates
                                    ? null
                                    : "error"
                                }
                                onChange={(e) =>
                                  setCertificates(e.target.value)
                                }
                                name="certificates"
                                id="certificates"
                                rows="5"
                                value={certificates}
                              ></textarea>
                              <span className="charCount">
                                {certificates?.length}/255
                              </span>
                            </div>
                          </div>

                          <div className="column">
                            <div className="certificatesUploader">
                              <CADropEmbed
                                accept="image/*"
                                showFiles="true"
                                autoUpload="false"
                                limit={caLimit}
                                multiple="true"
                                buttonTitle={caButtonTitle}
                                caFile={caFile}
                                setCAFile={setCAFile}
                                setValidCA={setValidCA}
                                totalCAImageCount={totalCAImageCount}
                              />
                              <div className="profilePreview">
                                {caImages?.length ? (
                                  <ul>
                                    {caImages.map((image, i) => (
                                      <li
                                        key={i}
                                        onClick={(e) =>
                                          handleDeleteCAFile(e, i, image?.id)
                                        }
                                      >
                                        <img
                                          src={`https://storage.iyh.app/${image.path}`}
                                          alt=""
                                        />
                                      </li>
                                    ))}
                                  </ul>
                                ) : (
                                  <p>
                                    No Certificates & Awards images uploaded
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <section id="businessSocial">
                        <h3>Social Media</h3>

                        <div className="formContentWrapper">
                          {/* Facebook */}
                          <div className="column">
                            <label htmlFor="facebook">Facebook:</label>
                            <div className="inputWrapper">
                              <span
                                className={validFacebook ? "valid" : "hide"}
                              >
                                <FaCheck />
                              </span>
                              <span
                                className={
                                  validFacebook || !facebook
                                    ? "hide"
                                    : "invalid"
                                }
                              >
                                <FaExclamation />
                              </span>
                              <input
                                type="text"
                                id="facebook"
                                className={
                                  validFacebook || !facebook ? null : "error"
                                }
                                ref={facebookRef}
                                autoComplete="off"
                                onChange={(e) => setFacebook(e.target.value)}
                                value={facebook}
                                aria-invalid={validFacebook ? "false" : "true"}
                                aria-describedby="facebooknote"
                                onFocus={() => setFacebookFocus(true)}
                                onBlur={() => setFacebookFocus(false)}
                              />
                            </div>
                            <p
                              id="facebooknote"
                              className={
                                facebookFocus && facebook && !validFacebook
                                  ? "instructions"
                                  : "offscreen"
                              }
                            >
                              Invalid Facebook URL, must start with
                              https://www.facebook.com/.
                            </p>
                          </div>

                          {/* Instagram */}
                          <div className="column">
                            <label htmlFor="instagram">Instagram:</label>
                            <div className="inputWrapper">
                              <span
                                className={validInstagram ? "valid" : "hide"}
                              >
                                <FaCheck />
                              </span>
                              <span
                                className={
                                  validInstagram || !instagram
                                    ? "hide"
                                    : "invalid"
                                }
                              >
                                <FaExclamation />
                              </span>
                              <input
                                type="text"
                                id="instagram"
                                className={
                                  validInstagram || !instagram ? null : "error"
                                }
                                ref={instagramRef}
                                autoComplete="off"
                                onChange={(e) => setInstagram(e.target.value)}
                                value={instagram}
                                aria-invalid={validInstagram ? "false" : "true"}
                                aria-describedby="instagramnote"
                                onFocus={() => setInstagramFocus(true)}
                                onBlur={() => setInstagramFocus(false)}
                              />
                            </div>
                            <p
                              id="instagramnote"
                              className={
                                instagramFocus && instagram && !validInstagram
                                  ? "instructions"
                                  : "offscreen"
                              }
                            >
                              Invalid Instagram URL, must start with
                              https://www.instagram.com/.
                            </p>
                          </div>

                          {/* Twitter */}
                          <div className="column">
                            <label htmlFor="twitter">Twitter:</label>
                            <div className="inputWrapper">
                              <span className={validTwitter ? "valid" : "hide"}>
                                <FaCheck />
                              </span>
                              <span
                                className={
                                  validTwitter || !twitter ? "hide" : "invalid"
                                }
                              >
                                <FaExclamation />
                              </span>
                              <input
                                type="text"
                                id="twitter"
                                className={
                                  validTwitter || !twitter ? null : "error"
                                }
                                ref={twitterRef}
                                autoComplete="off"
                                onChange={(e) => setTwitter(e.target.value)}
                                value={twitter}
                                aria-invalid={validTwitter ? "false" : "true"}
                                aria-describedby="instagramnote"
                                onFocus={() => setTwitterFocus(true)}
                                onBlur={() => setTwitterFocus(false)}
                              />
                            </div>
                            <p
                              id="instagramnote"
                              className={
                                twitterFocus && twitter && !validTwitter
                                  ? "instructions"
                                  : "offscreen"
                              }
                            >
                              Invalid Twitter URL, must start with
                              https://twitter.com/.
                            </p>
                          </div>

                          {/* LinkedIn */}
                          <div className="column">
                            <label htmlFor="linkedin">LinkedIn:</label>
                            <div className="inputWrapper">
                              <span
                                className={validLinkedin ? "valid" : "hide"}
                              >
                                <FaCheck />
                              </span>
                              <span
                                className={
                                  validLinkedin || !linkedin
                                    ? "hide"
                                    : "invalid"
                                }
                              >
                                <FaExclamation />
                              </span>
                              <input
                                type="text"
                                id="linkedin"
                                className={
                                  validLinkedin || !linkedin ? null : "error"
                                }
                                ref={linkedinRef}
                                autoComplete="off"
                                onChange={(e) => setLinkedin(e.target.value)}
                                value={linkedin}
                                aria-invalid={validLinkedin ? "false" : "true"}
                                aria-describedby="linkedinnote"
                                onFocus={() => setLinkedinFocus(true)}
                                onBlur={() => setLinkedinFocus(false)}
                              />
                            </div>
                            <p
                              id="linkedinnote"
                              className={
                                linkedinFocus && linkedin && !validLinkedin
                                  ? "instructions"
                                  : "offscreen"
                              }
                            >
                              Invalid LnkedIn URL, must start with
                              https://www.linkedin.com/.
                            </p>
                          </div>

                          {/* Yelp */}
                          <div className="column">
                            <label htmlFor="yelp">Yelp:</label>
                            <div className="inputWrapper">
                              <span className={validYelp ? "valid" : "hide"}>
                                <FaCheck />
                              </span>
                              <span
                                className={
                                  validYelp || !yelp ? "hide" : "invalid"
                                }
                              >
                                <FaExclamation />
                              </span>
                              <input
                                type="text"
                                id="yelp"
                                className={validYelp || !yelp ? null : "error"}
                                ref={yelpRef}
                                autoComplete="off"
                                onChange={(e) => setYelp(e.target.value)}
                                value={yelp}
                                aria-invalid={validYelp ? "false" : "true"}
                                aria-describedby="yelpnote"
                                onFocus={() => setYelpFocus(true)}
                                onBlur={() => setYelpFocus(false)}
                              />
                            </div>
                            <p
                              id="yelpnote"
                              className={
                                yelpFocus && yelp && !validYelp
                                  ? "instructions"
                                  : "offscreen"
                              }
                            >
                              Invalid Yelp URL, must start with
                              https://www.yelp.com/.
                            </p>
                          </div>

                          {/* Google */}
                          <div className="column">
                            <label htmlFor="google">Google:</label>
                            <div className="inputWrapper">
                              <span className={validGoogle ? "valid" : "hide"}>
                                <FaCheck />
                              </span>
                              <span
                                className={
                                  validGoogle || !google ? "hide" : "invalid"
                                }
                              >
                                <FaExclamation />
                              </span>
                              <input
                                type="text"
                                id="google"
                                className={
                                  validGoogle || !google ? null : "error"
                                }
                                ref={googleRef}
                                autoComplete="off"
                                onChange={(e) => setGoogle(e.target.value)}
                                value={google}
                                aria-invalid={validGoogle ? "false" : "true"}
                                aria-describedby="googlenote"
                                onFocus={() => setGoogleFocus(true)}
                                onBlur={() => setGoogleFocus(false)}
                              />
                            </div>
                            <p
                              id="googlenote"
                              className={
                                googleFocus && google && !validGoogle
                                  ? "instructions"
                                  : "offscreen"
                              }
                            >
                              Invalid Google URL, must start with
                              'https://www.google.com/' or 'https://goo.gl/'.
                            </p>
                          </div>
                        </div>
                      </section>

                      <section id="businessPortfolio">
                        <div class="formContentWrapper">
                          {/* Image Portfolio */}
                          <div className="column">
                            <h3>Portfolio</h3>
                            <div className="formContentWrapper">
                              <PortfolioDropEmbed
                                accept="image/*"
                                showFiles="true"
                                autoUpload="false"
                                limit={portfolioLimit}
                                multiple="true"
                                buttonTitle={portfolioButtonTitle}
                                portfolio={portfolio}
                                setPortfolio={setPortfolio}
                                setValidPortfolio={setValidPortfolio}
                                totalPortfolioImageCount={
                                  totalPortfolioImageCount
                                }
                              />
                              <div className="profilePreview">
                                {portfolioImages?.length ? (
                                  <ul>
                                    {portfolioImages.map((image, i) => (
                                      <li
                                        key={i}
                                        onClick={(e) =>
                                          handleDeleteImage(e, i, image?.id)
                                        }
                                      >
                                        <img
                                          src={`https://storage.iyh.app/${image.path}`}
                                          alt=""
                                        />
                                      </li>
                                    ))}
                                  </ul>
                                ) : (
                                  <p>No portfolio images uploaded</p>
                                )}
                              </div>
                            </div>
                          </div>

                          {/* Video Portfolio */}
                          <div className="column">
                            <h3>Videos</h3>
                            <div className="formContentWrapper">
                              <VideoDropEmbed
                                accept="video/mp4"
                                showFiles="true"
                                autoUpload="false"
                                limit={videoLimit}
                                multiple="true"
                                buttonTitle={videoButtonTitle}
                                videos={videos}
                                setVideos={setVideos}
                                setValidVideos={setValidVideos}
                                totalVideoCount={totalVideoCount}
                              />
                              <div className="profilePreview">
                                {portfolioVideos?.length ? (
                                  <ul>
                                    {portfolioVideos.map((video, i) => (
                                      <li
                                        key={i}
                                        onClick={(e) =>
                                          handleDeleteVideo(e, i, video?.id)
                                        }
                                      >
                                        <video>
                                          <source
                                            src={`https://storage.iyh.app/${video.path}`}
                                            type="video/mp4"
                                          />
                                          Your browser does not support HTML
                                          video.
                                        </video>
                                        {/* <img src={`https://storage.iyh.app/${video.path}`} alt="" /> */}
                                      </li>
                                    ))}
                                  </ul>
                                ) : (
                                  <p>No videos uploaded</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <section className="extVideos">
                        <div>
                          <h3>Externally Hosted Videos</h3>

                          <div className="externalVideoWrapper">
                            <select
                              onChange={(e) => handleProvider1Change(e)}
                              value={provider1}
                            >
                              <option value="">Select Provider</option>
                              {PROVIDER_LIST.length &&
                                PROVIDER_LIST.map((provider, i) => (
                                  <option key={i} value={provider.Value}>
                                    {provider.Name}
                                  </option>
                                ))}
                            </select>
                            <div className="inputWrapper">
                              <span
                                className={validExternalURL1 ? "valid" : "hide"}
                              >
                                <FaCheck />
                              </span>
                              <span
                                className={
                                  validExternalURL1 || !externalURL1
                                    ? "hide"
                                    : "invalid"
                                }
                              >
                                <FaExclamation />
                              </span>
                              <input
                                type="text"
                                id="external"
                                autoComplete="off"
                                value={externalURL1}
                                ref={video1Ref}
                                onChange={(e) =>
                                  setExternalURL1(e.target.value)
                                }
                                aria-invalid={
                                  validExternalURL1 ? "false" : "true"
                                }
                                aria-describedby="externalnote1"
                                onFocus={() => setExternalURL1Focus(true)}
                                onBlur={() => setExternalURL1Focus(false)}
                              />
                              <p
                                id="externalnote1"
                                className={
                                  externalURL1Focus &&
                                  externalURL1 &&
                                  !validExternalURL1
                                    ? "instructions"
                                    : "offscreen"
                                }
                              >
                                Invalid video url.
                                {provider1 == "youtube" &&
                                  YOUTUBE_VALUDATION_TEXT}
                                {!provider1 && NO_PROVIDER_VALIDATION_TEXT}
                                {provider1 == "vimeo" && VIMEO_VALUDATION_TEXT}
                                {!provider1 && NO_PROVIDER_VALIDATION_TEXT}
                                {provider1 == "facebook" &&
                                  FACEBOOK_VALUDATION_TEXT}
                                {!provider1 && NO_PROVIDER_VALIDATION_TEXT}
                              </p>
                            </div>
                          </div>

                          <div className="externalVideoWrapper">
                            <select
                              onChange={(e) => handleProvider2Change(e)}
                              value={provider2}
                            >
                              <option value="">Select Provider</option>
                              {PROVIDER_LIST.length &&
                                PROVIDER_LIST.map((provider, i) => (
                                  <option key={i} value={provider.Value}>
                                    {provider.Name}
                                  </option>
                                ))}
                            </select>
                            <div className="inputWrapper">
                              <span
                                className={validExternalURL2 ? "valid" : "hide"}
                              >
                                <FaCheck />
                              </span>
                              <span
                                className={
                                  validExternalURL2 || !externalURL2
                                    ? "hide"
                                    : "invalid"
                                }
                              >
                                <FaExclamation />
                              </span>
                              <input
                                type="text"
                                id="external"
                                autoComplete="off"
                                value={externalURL2}
                                ref={video2Ref}
                                onChange={(e) =>
                                  setExternalURL2(e.target.value)
                                }
                                aria-invalid={
                                  validExternalURL2 ? "false" : "true"
                                }
                                aria-describedby="externalnote2"
                                onFocus={() => setExternalURL2Focus(true)}
                                onBlur={() => setExternalURL2Focus(false)}
                              />
                              <p
                                id="externalnote2"
                                className={
                                  externalURL2Focus &&
                                  externalURL2 &&
                                  !validExternalURL2
                                    ? "instructions"
                                    : "offscreen"
                                }
                              >
                                Invalid video url.
                                {provider2 == "youtube" &&
                                  YOUTUBE_VALUDATION_TEXT}
                                {!provider2 && NO_PROVIDER_VALIDATION_TEXT}
                                {provider2 == "vimeo" && VIMEO_VALUDATION_TEXT}
                                {!provider2 && NO_PROVIDER_VALIDATION_TEXT}
                                {provider2 == "facebook" &&
                                  FACEBOOK_VALUDATION_TEXT}
                                {!provider2 && NO_PROVIDER_VALIDATION_TEXT}
                              </p>
                            </div>
                          </div>

                          <div className="externalVideoWrapper">
                            <select
                              onChange={(e) => handleProvider3Change(e)}
                              value={provider3}
                            >
                              <option value="">Select Provider</option>
                              {PROVIDER_LIST.length &&
                                PROVIDER_LIST.map((provider, i) => (
                                  <option key={i} value={provider.Value}>
                                    {provider.Name}
                                  </option>
                                ))}
                            </select>
                            <div className="inputWrapper">
                              <span
                                className={validExternalURL3 ? "valid" : "hide"}
                              >
                                <FaCheck />
                              </span>
                              <span
                                className={
                                  validExternalURL3 || !externalURL3
                                    ? "hide"
                                    : "invalid"
                                }
                              >
                                <FaExclamation />
                              </span>
                              <input
                                type="text"
                                id="external"
                                autoComplete="off"
                                value={externalURL3}
                                ref={video3Ref}
                                onChange={(e) =>
                                  setExternalURL3(e.target.value)
                                }
                                aria-invalid={
                                  validExternalURL3 ? "false" : "true"
                                }
                                aria-describedby="externalnote3"
                                onFocus={() => setExternalURL3Focus(true)}
                                onBlur={() => setExternalURL3Focus(false)}
                              />
                              <p
                                id="externalnote3"
                                className={
                                  externalURL3Focus &&
                                  externalURL3 &&
                                  !validExternalURL3
                                    ? "instructions"
                                    : "offscreen"
                                }
                              >
                                Invalid video url.
                                {provider3 == "youtube" &&
                                  YOUTUBE_VALUDATION_TEXT}
                                {!provider3 && NO_PROVIDER_VALIDATION_TEXT}
                                {provider3 == "vimeo" && VIMEO_VALUDATION_TEXT}
                                {!provider3 && NO_PROVIDER_VALIDATION_TEXT}
                                {provider3 == "facebook" &&
                                  FACEBOOK_VALUDATION_TEXT}
                                {!provider3 && NO_PROVIDER_VALIDATION_TEXT}
                              </p>
                            </div>
                          </div>
                        </div>
                      </section>

                      <section id="promotions">
                        <h3>Promotions</h3>

                        {promotions?.length ? (
                          <>
                            <ul className="promotionsList">
                              <li className="key">
                                <div className="name">Name</div>
                                <div className="statusContainer">Status</div>
                                <div className="date">Date Created</div>
                                <div className="buttons"></div>
                              </li>
                              {promotions.map((promotion, i) => (
                                <li key={i}>
                                  <div className="name">{promotion?.name}</div>
                                  <div className="statusContainer">
                                    <div
                                      className={`status ${promotion?.status}`}
                                    >
                                      {promotion?.status}
                                    </div>
                                  </div>
                                  <div className="date">
                                    {formatDate(promotion?.created_at)}
                                  </div>
                                  <div className="buttons">
                                    <div
                                      className="edit"
                                      onClick={(e) =>
                                        editPromotion(e, i, promotion)
                                      }
                                    >
                                      <span>
                                        <MdOutlineEdit />
                                      </span>
                                    </div>
                                    <div
                                      className="delete"
                                      onClick={(e) =>
                                        deletePromotion(e, i, promotion)
                                      }
                                    >
                                      <span>
                                        <AiFillDelete />
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </>
                        ) : addEditUser == "add" ? (
                          <p>
                            Promotions can not be added until the user has been
                            created.
                          </p>
                        ) : (
                          <p>No promotions have been created for this user</p>
                        )}
                        {addEditUser !== "add" ? (
                          <button onClick={createPromotion}>
                            Create Promotion
                          </button>
                        ) : null}
                      </section>
                    </>
                  )}
                  {addEditUser == "add" ? (
                    <section className="passwords">
                      <h3>Passwords</h3>
                      <div className="formContentWrapper">
                        {/* Password */}
                        <div className="column">
                          <label htmlFor="password">Password:</label>
                          <div className="inputWrapper">
                            <span className={validPwd ? "valid" : "hide"}>
                              <FaCheck />
                            </span>
                            <span
                              className={validPwd || !pwd ? "hide" : "invalid"}
                            >
                              <FaExclamation />
                            </span>
                            <input
                              type="password"
                              id="password"
                              className={validPwd || !pwd ? null : "error"}
                              onChange={(e) => setPwd(e.target.value)}
                              value={pwd}
                              required
                              aria-invalid={validPwd ? "false" : "true"}
                              aria-describedby="pwdnote"
                              onFocus={() => setPwdFocus(true)}
                              onBlur={() => setPwdFocus(false)}
                            />
                          </div>
                          <p
                            id="pwdnote"
                            className={
                              pwdFocus && !validPwd
                                ? "instructions"
                                : "offscreen"
                            }
                          >
                            8 to 24 characters. Must include uppercase and
                            lowercase letters, a number and a special character.
                            Allowed special characters:{" "}
                            <span aria-label="exclamation mark">!</span>{" "}
                            <span aria-label="at symbol">@</span>{" "}
                            <span aria-label="hashtag">#</span>{" "}
                            <span aria-label="dollar sign">$</span>{" "}
                            <span aria-label="percent">%</span>
                          </p>
                        </div>

                        {/* Password Confirmation */}
                        <div className="column">
                          <label htmlFor="confirm_pwd">Confirm Password:</label>
                          <div className="inputWrapper">
                            <span
                              className={
                                validMatch && matchPwd ? "valid" : "hide"
                              }
                            >
                              <FaCheck />
                            </span>
                            <span
                              className={
                                validMatch || !matchPwd ? "hide" : "invalid"
                              }
                            >
                              <FaExclamation />
                            </span>
                            <input
                              type="password"
                              id="confirm_pwd"
                              className={
                                validMatch || !matchPwd ? null : "error"
                              }
                              onChange={(e) => setMatchPwd(e.target.value)}
                              value={matchPwd}
                              required
                            />
                          </div>
                          <p
                            id="confirmnote"
                            className={
                              matchFocus && !validMatch
                                ? "instructions"
                                : "offscreen"
                            }
                          >
                            Must match the first password input field.
                          </p>
                        </div>
                      </div>
                    </section>
                  ) : (
                    <section>
                      <h3>Passwords</h3>
                      <button onClick={(e) => sendPasswordReset(e)}>
                        Send Password Reset
                      </button>
                    </section>
                  )}

                  {addEditUser == "edit" && !userVerified ? (
                    <section className="verification">
                      <h3>Verify User</h3>
                      <button onClick={(e) => sendUserVerification(e)}>
                        Send User Verification
                      </button>
                      <button onClick={(e) => adminVerification(e)}>
                        Admin Verification
                      </button>
                    </section>
                  ) : null}
                </form>
              </DialogContentText>
            </DialogContent>
          </Dialog>

          {/* Create Promotion */}
          <Dialog
            open={openCreatePromotion}
            onClose={handleCloseCreatePromotion}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            id="adminCreatePromotionPopup"
          >
            <DialogTitle id="alert-dialog-title"></DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <AdminCreateUserPromotion
                  addPromotion={addPromotion}
                  userID={editID}
                  businessName={businessName}
                  setOpenCreatePromotion={setOpenCreatePromotion}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <button className="cancel" onClick={handleCloseCreatePromotion}>
                Cancel
              </button>
            </DialogActions>
          </Dialog>

          {/* Edit Promotion */}
          <Dialog
            open={openEditPromotion}
            onClose={handleCloseEditPromotion}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            id="promotionsEditPopup"
          >
            <DialogTitle id="alert-dialog-title">
              {"Editing Promotion"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <form encType="multipart/form-data">
                  {/* Status */}
                  <label htmlFor="status">Status:</label>
                  <div className="inputWrapper">
                    <select
                      id="status"
                      onChange={(e) => setEditStatus(e.target.value)}
                      value={editStatus}
                      required
                      onFocus={() => setEditStatusFocus(true)}
                      onBlur={() => setEditStatusFocus(false)}
                    >
                      <option value="draft">Draft</option>
                      <option value="active">Active</option>
                      <option value="archived">Archived</option>
                    </select>
                  </div>

                  {/* Name */}
                  <label htmlFor="name">Promotion Name:</label>
                  <div className="inputWrapper">
                    <span className={validEditName ? "valid" : "hide"}>
                      <FaCheck />
                    </span>
                    <span
                      className={
                        validEditName || !editName ? "hide" : "invalid"
                      }
                    >
                      <FaExclamation />
                    </span>
                    <input
                      type="text"
                      id="name"
                      className={validEditName || !editName ? null : "error"}
                      ref={nameRef}
                      autoComplete="off"
                      onChange={(e) => setEditName(e.target.value)}
                      value={editName}
                      aria-invalid={validEditName ? "false" : "true"}
                      aria-describedby="namenote"
                      onFocus={() => setNameFocus(true)}
                      onBlur={() => setNameFocus(false)}
                    />
                  </div>
                  <p
                    id="namenote"
                    className={
                      nameFocus && editName && !validEditName
                        ? "instructions"
                        : "offscreen"
                    }
                  >
                    Invalid Promotion Name. 1-50 characters (letters, numbers,
                    and special characters (-_.!?%'#$,:;) allowed)
                  </p>

                  {/* Description */}
                  <label htmlFor="details">Details:</label>
                  <div className="inputWrapper">
                    <span className={validEditDetails ? "valid" : "hide"}>
                      <FaCheck />
                    </span>
                    <span
                      className={
                        validEditDetails || !editDetails ? "hide" : "invalid"
                      }
                    >
                      <FaExclamation />
                    </span>
                    <textarea
                      ref={detailsRef}
                      onChange={(e) => setEditDetails(e.target.value)}
                      name="details"
                      id="details"
                      rows="5"
                      value={editDetails}
                    ></textarea>
                    <p
                      id="descriptionnote"
                      className={
                        detailsFocus && editDetails && !validEditDetails
                          ? "instructions"
                          : "offscreen"
                      }
                    >
                      Invalid Description. Between 1-500 characters (letters,
                      numbers, and special characters (-_.!?%'#$,:;) allowed)
                    </p>
                  </div>
                  {/* Promotional Image */}
                  <label htmlFor="image">Promotional Image:</label>
                  <div
                    className={`promotionalImageWrapper ${
                      !editImage && "noImage"
                    }`}
                    onClick={(e) => handleDeletePromotionalImage(e, editImage)}
                  >
                    {editImage ? (
                      <img
                        src={`https://storage.iyh.app/${editImage}`}
                        alt={`Promotional Image`}
                      />
                    ) : (
                      "No image selected"
                    )}
                  </div>

                  {/* File Uploader */}
                  <SingleFileDropEmbed
                    buttonTitle={buttonTitle}
                    files={files}
                    setFiles={setFiles}
                    validFiles={validFiles}
                    setValidFiles={setValidFiles}
                    accept="image/*"
                  />
                </form>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <button className="cancel" onClick={handleCloseEditPromotion}>
                Cancel
              </button>
              <button
                className="confirm"
                disabled={!validEditName || !validEditDetails ? true : false}
                onClick={handleSaveEditPromotion}
              >
                Save
              </button>
            </DialogActions>
          </Dialog>

          {/* Delete Promotion Dialog */}
          <Dialog
            open={openDeletePromotion}
            onClose={handleCloseDeletePromotion}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Are you sure you want to delete this promotion?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This action cannot be undone. This will permanently delete the
                promotion with name, "{deletePromotionName}".
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <button className="cancel" onClick={handleCloseDeletePromotion}>
                Cancel
              </button>
              <button className="confirm" onClick={handleDeletePromotion}>
                Yes, delete promotion
              </button>
            </DialogActions>
          </Dialog>
        </>
      ) : loading ? (
        <Loader />
      ) : (
        <p>No users to display</p>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default Users;
