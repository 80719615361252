import * as React from 'react';
import { useEffect, useState } from "react";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import AdminMenu from '../../components/AdminMenu';
import DashboardItem from '../../components/DashboardItem';
import { SiBaremetrics } from 'react-icons/si';
import Loader from '../../components/Loader';
import { AiOutlineDashboard } from 'react-icons/ai'
import { CgProfile } from 'react-icons/cg'
import { MdAccountBox } from 'react-icons/md'
import { FaUserCircle } from 'react-icons/fa'
import { GiStarsStack } from 'react-icons/gi'
import { AiOutlineBarChart } from 'react-icons/ai'
import { HiChartSquareBar } from 'react-icons/hi'
import { ImBullhorn } from 'react-icons/im';
import { MdDashboard } from 'react-icons/md';
import { RiCustomerService2Line } from 'react-icons/ri';
import { MdHelpCenter } from 'react-icons/md';
import { AiFillMessage } from 'react-icons/ai';
import { MdMessage } from 'react-icons/md';
import { MdFavorite } from 'react-icons/md';
import { MdFactCheck } from 'react-icons/md';
import { MdRequestPage } from 'react-icons/md';
import { MdSettings } from 'react-icons/md';
import { MdWork } from 'react-icons/md';
import { MdAdsClick } from 'react-icons/md';
import { MdExplore } from 'react-icons/md';
import { MdDonutSmall } from 'react-icons/md';
import { MdInsertChart } from 'react-icons/md';
import { MdLocalOffer } from 'react-icons/md';
import { MdPeopleAlt } from 'react-icons/md';
import { MdAssignmentInd } from 'react-icons/md';
import { MdHome } from 'react-icons/md';
import { MdBuildCircle } from 'react-icons/md';
import { MdPendingActions } from 'react-icons/md';
import { FaBoxOpen } from 'react-icons/fa';

const AdminDashboard = () => {

	const contacts = "";

	const axiosPrivate = useAxiosPrivate();
	const [loading, setLoading] = useState(false);
	const [userMetrics, setUserMetrics] = useState();
	const [metrics, setMetrics] = useState();

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getFavoriteMetrics = async () => {
			try {
				const response = await axiosPrivate.get('dashboard-metrics', {
					signal: controller.signal
				});
				setUserMetrics(response.data.user_metrics[0]);
				setMetrics(response?.data)
				setLoading(false)
			} catch(err) {
				console.log(err.message);
				{/* !auth && navigate('/login', { state: { from: location }, replace: true }); */}
			}
		}

		getFavoriteMetrics();

		return () => controller.abort();
		// eslint-disable-next-line
	}, [])

	return (
		<section>
			<article className='inner'>
				<div className="backendTitleBar">
					<h1>Admin Dashboard</h1>
					<MdDashboard />
				</div>
				<div className="backendColumnWrapper">
					<div id="menu" className="column">
						<AdminMenu />
					</div>
					<div id="content" className="column">
						<div className="dashboardItemWrapper">
							<DashboardItem title="New Tickets" icon={<MdLocalOffer />} count={metrics?.new_tickets} link="/admin-dashboard/tickets" />
							<DashboardItem title="Invite Requests" icon={<MdPendingActions />} count={userMetrics?.new_requests} link="/admin-dashboard/invites" />
							<DashboardItem title="Users" icon={<MdPeopleAlt />} link="/admin-dashboard/users" />
						</div>

						<div className="dashboardItemWrapper stats">
							<h3>Stats</h3>
							<DashboardItem title="Invite Requests" icon={<MdAssignmentInd />} description="(last 30 days)" count={userMetrics?.new_requests} />
							<DashboardItem title="New Homeowners" icon={<MdHome />} description="(last 30 days)" count={userMetrics?.new_homeowners} />
							<DashboardItem title="New Contractors" icon={<MdBuildCircle />} description="(last 30 days)" count={userMetrics?.new_contractors} />
						</div>
					</div>
				</div>
			</article>
		</section>
	)
}

export default AdminDashboard