
import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';
import { FaExclamation } from 'react-icons/fa';
import usePageTitle from '../hooks/usePageTitle';
import UserPageNav from '../components/UserPageNav';
import useAuth from '../hooks/useAuth';
import { ToastContainer, toast } from 'react-toastify';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import * as React from 'react';
import Slider from '@mui/material/Slider';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const NAME_REGEX = /^[a-zA-Z0-9-_&@#!$ ]{1,50}$/;
const PRICE_REGEX = /^[0-9.]{1,10}$/;
const DESCRIPTION_REGEX = /^[a-zA-Z0-9-_.,& \r\n]{1,500}$/;

const CreatePackage = ({ addPackage, activeStates, activeStateCounties }) => {

	const { auth } = useAuth();
	const userID = auth?.user?.id;
	let isVerified = auth?.user?.email_verified_at;

	const axiosPrivate = useAxiosPrivate();

	const packageNameRef = useRef()
	const packageDescriptionRef = useRef()
	const packagePriceRef = useRef()
	const packageAdditionalServiceChargeRef = useRef()
	const packageNumAllowedStatesRef = useRef()
	const packageNumAllowedCountiesRef = useRef()
	const packageNumAllowedServicesRef = useRef()
	const packageNumMaxAllowedServicesRef = useRef()
	const packageBillingFrequencyRef = useRef()

	const [packageName, setPackageName] = useState()
	const [validName, setValidName] = useState(false);
	const [nameFocus, setNameFocus] = useState(false);

	const [packageDescription, setPackageDescription] = useState(null)
	const [validDescription, setValidDescription] = useState(false);
	const [descriptionFocus, setDescriptionFocus] = useState(false);

	const [packagePrice, setPackagePrice] = useState()
	const [validPrice, setValidPrice] = useState(false);
	const [priceFocus, setPriceFocus] = useState(false);

	const [additionalServiceCharge, setAdditionalServiceCharge] = useState()
	const [validAdditionalServiceCharge, setValidAdditionalServiceCharge] = useState(false);
	const [additionalServiceChargeFocus, setAdditionalServiceChargeFocus] = useState(false);

	const [packageNumAllowedStates, setPackageNumAllowedStates] = useState(1)
	const [validNumAllowedStates, setValidNumAllowedStates] = useState(false);
	const [numAllowedStatesFocus, setNumAllowedStatesFocus] = useState(false);

	const [packageNumAllowedCounties, setPackageNumAllowedCounties] = useState(8)
	const [validNumAllowedCounties, setValidNumAllowedCounties] = useState(false);
	const [numAllowedCountiesFocus, setNumAllowedCountiesFocus] = useState(false);

	const [packageNumAllowedServices, setPackageNumAllowedServices] = useState(4)
	const [validNumAllowedServices, setValidNumAllowedServices] = useState(false);
	const [numAllowedServicesFocus, setNumAllowedServicesFocus] = useState(false);

	const [packageNumMaxAllowedServices, setPackageNumMaxAllowedServices] = useState(8)
	const [validNumMaxAllowedServices, setValidNumMaxAllowedServices] = useState(false);
	const [numMaxAllowedServicesFocus, setNumMaxAllowedServicesFocus] = useState(false);

	const [packageBillingFrequency, setPackageBillingFrequency] = useState("monthly")
	const [validBillingFrequency, setValidBillingFrequency] = useState(false);
	const [billingFrequencyFocus, setBillingFrequencyFocus] = useState(false);

	const [packageStatus, setPackageStatus] = useState("active")
	const [includeOptions, setIncludeOptions] = useState(false)

	/* Set Focus to name on load */
	useEffect(() => {
		packageNameRef.current.focus()
	}, [])

	/* Check Name */
	useEffect(() => {
		const result = NAME_REGEX.test(packageName);
		setValidName(result);
	}, [packageName])

	/* Check Price */
	useEffect(() => {
		const result = PRICE_REGEX.test(packagePrice);
		setValidPrice(result);
	}, [packagePrice])

	/* Check Additional Service Charge */
	useEffect(() => {
		const result = PRICE_REGEX.test(additionalServiceCharge);
		setValidAdditionalServiceCharge(result);
	}, [additionalServiceCharge])

	/* Check Description */
	useEffect(() => {
		const result = DESCRIPTION_REGEX.test(packageDescription);
		setValidDescription(result);
	}, [packageDescription])

	const handleSubmit = async (e) => {
		e.preventDefault();

		const dismiss = () =>  toast.dismiss(addPackageToast.current);
		const addPackageToast = toast.loading("Adding Package");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.post('package',
			JSON.stringify({package_name: packageName, package_description: packageDescription, package_price: packagePrice, num_allowed_states: packageNumAllowedStates, num_allowed_counties: packageNumAllowedCounties, num_allowed_services: packageNumAllowedServices, num_max_allowed_services: packageNumMaxAllowedServices, billing_frequency: packageBillingFrequency, status: packageStatus, add_options: includeOptions ? includeOptions : 0, additional_service_charge: additionalServiceCharge}),
			{
				signal: controller.signal
			});

			// Get package id from database
			const id = response?.data?.packages?.id

			// Get date from database
			const created_at = response?.data?.market?.created_at

			// Add market to markets
			addPackage({id, package_name: packageName, package_description: packageDescription, package_price: packagePrice, num_allowed_states: packageNumAllowedStates, num_allowed_counties: packageNumAllowedCounties, num_allowed_services: packageNumAllowedServices, num_max_allowed_services: packageNumMaxAllowedServices, billing_frequency: packageBillingFrequency, status: packageStatus, created_at, add_options: includeOptions ? includeOptions : 0, additional_service_charge: additionalServiceCharge})

			// Clear Form
			setPackageName("")
			setPackageDescription(null)
			setPackagePrice("")
			setPackageBillingFrequency("monthly")
			setPackageStatus("active")
			setPackageNumAllowedCounties(8)
			setPackageNumAllowedServices(4)
			setPackageNumAllowedStates(1)
			setPackageNumMaxAllowedServices(8)

			// Update Toast Notification
			toast.update(addPackageToast, { render: 'Package Added', type: 'success', isLoading: false, autoClose: 5000});
		} catch (err) {
			if (!err?.response) {
				toast.update(addPackageToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else if (err.response?.status === 401) {
				toast.update(addPackageToast, { render: 'Authorization Failed', type: 'error', isLoading: false});
				{/* setErrMsg('The email has already been taken.'); */}
			} else {
				toast.update(addPackageToast, { render: 'Package Add Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	const handleStateChange = (newValue) => {
		setPackageNumAllowedStates(newValue)
	}

	const handleCountyChange = (newValue) => {
		setPackageNumAllowedCounties(newValue)
	}

	const handleServiceChange = (newValue) => {
		setPackageNumAllowedServices(newValue)
	}

	const handleMaxServiceChange = (newValue) => {
		setPackageNumMaxAllowedServices(newValue)
	}

	const handleCheckbox = (e) => {
		setIncludeOptions(e.target.checked)
	}

	return (
		<>
			<form id="createPackage" onSubmit={handleSubmit} >

				{/* Name */}
				<label htmlFor="name">Package Name:</label>
				<div className="inputWrapper">
					<span className={validName ? "valid" : "hide"}><FaCheck /></span>
					<span className={validName || !packageName ? "hide" : "invalid"}><FaExclamation /></span>
					<input
						type="text"
						id="name"
						className={validName || !packageName ? null : "error"}
						ref={packageNameRef}
						autoComplete="off"
						onChange={(e) => setPackageName(e.target.value)}
						value={packageName}
						aria-invalid={validName ? "false" : "true"}
						aria-describedby="namenote"
						onFocus={() => setNameFocus(true)}
						onBlur={() => setNameFocus(false)}
					/>
				</div>
				<p id="namenote" className={nameFocus && packageName && !validName ? "instructions" : "offscreen"}>
					Invalid Package Name. Package names must be between 1-50 characters long. Valid characters (-,_,&,@,#,$,!)
				</p>

				{/* Description */}
				<label htmlFor="description">Description:</label>
				<div className="inputWrapper">
					<span className={validDescription ? "valid" : "hide"}><FaCheck /></span>
					<span className={validDescription || !packageDescription ? "hide" : "invalid"}><FaExclamation /></span>
					<textarea
						ref={packageDescriptionRef}
						onChange={(e) => setPackageDescription(e.target.value)}
						name="description"
						id="description"
						rows="5"
						value={packageDescription}
					>
					</textarea>
					<p id="descriptionnote" className={descriptionFocus && packageDescription && !validDescription ? "instructions" : "offscreen"}>
						Invalid Description. Between 1-500 characters (letters, numbers, dash and underscore allowed)
					</p>
				</div>

				{/* Name */}
				<label htmlFor="price">Package Price:</label>
				<div className="inputWrapper">
					<span className={validPrice ? "valid" : "hide"}><FaCheck /></span>
					<span className={validPrice || !packagePrice ? "hide" : "invalid"}><FaExclamation /></span>
					<input
						type="text"
						id="price"
						className={validPrice || !packagePrice ? null : "error"}
						ref={packagePriceRef}
						autoComplete="off"
						onChange={(e) => setPackagePrice(e.target.value)}
						value={packagePrice}
						aria-invalid={validPrice ? "false" : "true"}
						aria-describedby="pricenote"
						onFocus={() => setPriceFocus(true)}
						onBlur={() => setPriceFocus(false)}
					/>
				</div>
				<p id="pricenote" className={priceFocus && packagePrice && !validPrice ? "instructions" : "offscreen"}>
					Invalid Package Price. Package price must only contain numbers a a period.
				</p>

				{/* Numbers of States */}
				<label htmlFor="states">Number of States:</label>
				<div className="inputWrapper">
					<Slider
						valueLabelDisplay="auto"
						step={1}
						marks
						min={1}
						max={1}
						defaultValue={1}
						onChange={(e) => handleStateChange(e.target.value)}
					  />
				</div>

				{/* Numbers of Counties */}
				<label htmlFor="counties">Number of Counties:</label>
				<div className="inputWrapper">
					<Slider
						valueLabelDisplay="auto"
						step={1}
						marks
						min={1}
						max={30}
						defaultValue={8}
						onChange={(e) => handleCountyChange(e.target.value)}
					  />
				</div>

				{/* Numbers of Services */}
				<label htmlFor="services">Number of Free Services:</label>
				<div className="inputWrapper">
					<Slider
						valueLabelDisplay="auto"
						step={1}
						marks
						min={1}
						max={30}
						defaultValue={4}
						onChange={(e) => handleServiceChange(e.target.value)}
					  />
				</div>

				{/* Max Numbers of Services */}
				<label htmlFor="services">Max Number of Services:</label>
				<div className="inputWrapper">
					<Slider
						valueLabelDisplay="auto"
						step={1}
						marks
						min={-1}
						max={30}
						defaultValue={8}
						onChange={(e) => handleMaxServiceChange(e.target.value)}
					  />
				</div>

				<label htmlFor="price">Additional Service Charge:</label>
				<div className="inputWrapper">
					<span className={validAdditionalServiceCharge ? "valid" : "hide"}><FaCheck /></span>
					<span className={validAdditionalServiceCharge || !additionalServiceCharge ? "hide" : "invalid"}><FaExclamation /></span>
					<input
						type="text"
						id="price"
						className={validAdditionalServiceCharge || !additionalServiceCharge ? null : "error"}
						ref={packageAdditionalServiceChargeRef}
						autoComplete="off"
						onChange={(e) => setAdditionalServiceCharge(e.target.value)}
						value={additionalServiceCharge}
						aria-invalid={validAdditionalServiceCharge ? "false" : "true"}
						aria-describedby="pricenote2"
						onFocus={() => setAdditionalServiceChargeFocus(true)}
						onBlur={() => setAdditionalServiceChargeFocus(false)}
					/>
				</div>
				<p id="pricenote2" className={additionalServiceChargeFocus && additionalServiceCharge && !validAdditionalServiceCharge ? "instructions" : "offscreen"}>
					Invalid Additional Service Charge. Service Charge must only contain numbers and a period.
				</p>

				{/* Billing Frequency */}
				<label htmlFor="status">Billing Frequency:</label>
				<div className="inputWrapper">
					<select
						name="status"
						id="status"
						defaultValue="monthly"
						onChange={(e) => setPackageStatus(e.target.value)}
						value={packageStatus}
					>
						<option value="monthly">Monthly</option>
						<option value="annual">Annual</option>
					</select>
				</div>


				{/* Add Options */}
				<div className="inputWrapper">
					<FormGroup>
				  		<FormControlLabel control={
							<Checkbox
								checked={includeOptions}
								onChange={(e) => handleCheckbox(e)}
								inputProps={{ 'aria-label': 'controlled' }}
						  	/>
					  	} label="Include Options" />
					</FormGroup>
				</div>

				{/* Package Status */}
				<label htmlFor="status">Package Status:</label>
				<div className="inputWrapper">
					<select
						name="status"
						id="status"
						defaultValue="active"
						onChange={(e) => setPackageStatus(e.target.value)}
						value={packageStatus}
					>
						<option value="active">Active</option>
						<option value="disabled">Disabled</option>
					</select>
				</div>

				<button disabled={!validName || !validDescription || !validPrice || !validDescription ? true : false}>Add Package</button>
			</form>
			<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
			/>
		</>
	)
}

export default CreatePackage