import { useState, useEffect } from 'react';
import useFormContext from "../hooks/useFormContext"
import useAuth from '../hooks/useAuth'

const states = [
	{
		"State": "Alabama",
		"Code":	"AL"
	},
	{
		"State": "Alaska",
		"Code":	"AK"
	},
	{
		"State": "Arizona",
		"Code":	"AZ"
	},
	{
		"State": "Arkansas",
		"Code":	"AR"
	},
	{
		"State": "California",
		"Code":	"CA"
	},
	{
		"State": "Colorado",
		"Code":	"CO"
	},
	{
		"State": "Connecticut",
		"Code":	"CT"
	},
	{
		"State": "Delaware",
		"Code":	"DE"
	},
	{
		"State": "District of Columbia",
		"Code":	"DC"
	},
	{
		"State": "Florida",
		"Code":	"FL"
	},
	{
		"State": "Georgia",
		"Code":	"GA"
	},
	{
		"State": "Hawaii",
		"Code":	"HI"
	},
	{
		"State": "Idaho",
		"Code":	"ID"
	},
	{
		"State": "Illinois",
		"Code":	"IL"
	},
	{
		"State": "Indiana",
		"Code":	"IN"
	},
	{
		"State": "Illinois",
		"Code":	"IL"
	},
	{
		"State": "Iowa",
		"Code":	"IA"
	},
	{
		"State": "Kansas",
		"Code":	"KS"
	},
	{
		"State": "Kentucky",
		"Code":	"KY"
	},
	{
		"State": "Louisiana",
		"Code":	"LA"
	},
	{
		"State": "Maine",
		"Code":	"ME"
	},
	{
		"State": "Maryland",
		"Code":	"MD"
	},
	{
		"State": "Massachusetts",
		"Code":	"MA"
	},
	{
		"State": "Michigan",
		"Code":	"MI"
	},
	{
		"State": "Minnesota",
		"Code":	"MN"
	},
	{
		"State": "Mississippi",
		"Code":	"MS"
	},
	{
		"State": "Missouri",
		"Code":	"MO"
	},
	{
		"State": "Montana",
		"Code":	"MT"
	},
	{
		"State": "Nebraska",
		"Code":	"NE"
	},
	{
		"State": "Nevada",
		"Code":	"NV"
	},
	{
		"State": "New Hampshire",
		"Code":	"NH"
	},
	{
		"State": "New Jersey",
		"Code":	"NJ"
	},
	{
		"State": "New Mexico",
		"Code":	"NM"
	},
	{
		"State": "New York",
		"Code":	"NY"
	},
	{
		"State": "North Carolina",
		"Code":	"NC"
	},
	{
		"State": "North Dakota",
		"Code":	"ND"
	},
	{
		"State": "Ohio",
		"Code":	"OH"
	},
	{
		"State": "Oklahoma",
		"Code":	"OK"
	},
	{
		"State": "Oregon",
		"Code":	"OR"
	},
	{
		"State": "Pennsylvania",
		"Code":	"PA"
	},
	{
		"State": "Rhode Island",
		"Code":	"RI"
	},
	{
		"State": "South Carolina",
		"Code":	"SC"
	},
	{
		"State": "South Dakota",
		"Code":	"SD"
	},
	{
		"State": "Tennessee",
		"Code":	"TN"
	},
	{
		"State": "Texas",
		"Code":	"TX"
	},
	{
		"State": "Utah",
		"Code":	"UT"
	},
	{
		"State": "Vermont",
		"Code":	"VT"
	},
	{
		"State": "Virginia",
		"Code":	"VA"
	},
	{
		"State": "Washington",
		"Code":	"WA"
	},
	{
		"State": "West Virginia",
		"Code":	"WV"
	},
	{
		"State": "Wisconsin",
		"Code":	"WI"
	},
	{
		"State": "Wyoming",
		"Code":	"WY"
	}
]

const FormStepExclude = () => {

	const { data, updateExcludes, handleChange } = useFormContext()
	const excludes = data?.excludes
	const selectedStateCode = data.active_state
	const initExcludes = data.excluded_zips

	const auth = useAuth()
	const subscription = auth?.auth?.subscription
	const savedExcludes = subscription?.excluded_zipcodes ? JSON.parse(subscription?.excluded_zipcodes) : []

	const selectedState = states?.find(state => {
		return state.Code === selectedStateCode;
	});

	const selectedStateName = selectedState.State
	const selectedCounties = data?.selected_counties

	const [excludedZips, setExcludedZips] = useState(initExcludes ? initExcludes : savedExcludes ? savedExcludes : [])
	const stateZips = excludes?.filter(zip => { return zip.state === selectedStateName})
	const filteredZips = stateZips?.filter(zip => { return selectedCounties.includes(zip.county)})


	const toggleZip = (e, i, zip) => {
		// Add or remove county from state
		if (!excludedZips.includes(zip)){
			setExcludedZips([...excludedZips, zip])
		} else {
			setExcludedZips(excludedZips.filter(zipcode => { return zipcode !== zip}))
		}
	}

	useEffect(() => {
		updateExcludes(excludedZips)
		{/* console.log(excludedZips) */}
	}, [excludedZips])

	return (
		<>
			{ excludes.length ?
				<>
					<p>Available Zipcodes to exclude from marketing (optional)</p>
					<ul className="marketingZipcodes">
					{filteredZips?.map((zip, i) =>
						<li className={excludedZips.includes(zip?.zipcode) ? 'active' : null} key={i} onClick={(e) => toggleZip(e, i, zip?.zipcode)}>
							<div className="zipcode">
								{zip?.zipcode}
							</div>
						</li>
					)}
					</ul>
				</>
			:
				<p>No Exclude Zip Codes Available</p>
			}
		</>
	)
}

export default FormStepExclude