import { useState, useEffect, useRef } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import { AiFillDelete } from 'react-icons/ai';
import { AiOutlineShareAlt } from 'react-icons/ai';
import { MdVisibility } from 'react-icons/md';
import { MdOutlineEdit } from 'react-icons/md';
import { FaCheck } from 'react-icons/fa';
import { FaExclamation } from 'react-icons/fa';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import Loader from './Loader';
import CreateMarket from "./CreateMarket"

const statesList = [
	{
		"State": "Alabama",
		"Code":	"AL"
	},
	{
		"State": "Alaska",
		"Code":	"AK"
	},
	{
		"State": "Arizona",
		"Code":	"AZ"
	},
	{
		"State": "Arkansas",
		"Code":	"AR"
	},
	{
		"State": "California",
		"Code":	"CA"
	},
	{
		"State": "Colorado",
		"Code":	"CO"
	},
	{
		"State": "Connecticut",
		"Code":	"CT"
	},
	{
		"State": "Delaware",
		"Code":	"DE"
	},
	{
		"State": "District of Columbia",
		"Code":	"DC"
	},
	{
		"State": "Florida",
		"Code":	"FL"
	},
	{
		"State": "Georgia",
		"Code":	"GA"
	},
	{
		"State": "Hawaii",
		"Code":	"HI"
	},
	{
		"State": "Idaho",
		"Code":	"ID"
	},
	{
		"State": "Illinois",
		"Code":	"IL"
	},
	{
		"State": "Indiana",
		"Code":	"IN"
	},
	{
		"State": "Illinois",
		"Code":	"IL"
	},
	{
		"State": "Iowa",
		"Code":	"IA"
	},
	{
		"State": "Kansas",
		"Code":	"KS"
	},
	{
		"State": "Kentucky",
		"Code":	"KY"
	},
	{
		"State": "Louisiana",
		"Code":	"LA"
	},
	{
		"State": "Maine",
		"Code":	"ME"
	},
	{
		"State": "Maryland",
		"Code":	"MD"
	},
	{
		"State": "Massachusetts",
		"Code":	"MA"
	},
	{
		"State": "Michigan",
		"Code":	"MI"
	},
	{
		"State": "Minnesota",
		"Code":	"MN"
	},
	{
		"State": "Mississippi",
		"Code":	"MS"
	},
	{
		"State": "Missouri",
		"Code":	"MO"
	},
	{
		"State": "Montana",
		"Code":	"MT"
	},
	{
		"State": "Nebraska",
		"Code":	"NE"
	},
	{
		"State": "Nevada",
		"Code":	"NV"
	},
	{
		"State": "New Hampshire",
		"Code":	"NH"
	},
	{
		"State": "New Jersey",
		"Code":	"NJ"
	},
	{
		"State": "New Mexico",
		"Code":	"NM"
	},
	{
		"State": "New York",
		"Code":	"NY"
	},
	{
		"State": "North Carolina",
		"Code":	"NC"
	},
	{
		"State": "North Dakota",
		"Code":	"ND"
	},
	{
		"State": "Ohio",
		"Code":	"OH"
	},
	{
		"State": "Oklahoma",
		"Code":	"OK"
	},
	{
		"State": "Oregon",
		"Code":	"OR"
	},
	{
		"State": "Pennsylvania",
		"Code":	"PA"
	},
	{
		"State": "Rhode Island",
		"Code":	"RI"
	},
	{
		"State": "South Carolina",
		"Code":	"SC"
	},
	{
		"State": "South Dakota",
		"Code":	"SD"
	},
	{
		"State": "Tennessee",
		"Code":	"TN"
	},
	{
		"State": "Texas",
		"Code":	"TX"
	},
	{
		"State": "Utah",
		"Code":	"UT"
	},
	{
		"State": "Vermont",
		"Code":	"VT"
	},
	{
		"State": "Virginia",
		"Code":	"VA"
	},
	{
		"State": "Washington",
		"Code":	"WA"
	},
	{
		"State": "West Virginia",
		"Code":	"WV"
	},
	{
		"State": "Wisconsin",
		"Code":	"WI"
	},
	{
		"State": "Wyoming",
		"Code":	"WY"
	}
]

const NAME_REGEX = /^[a-zA-Z0-9-_&@ ]{1,50}$/;
const DESCRIPTION_REGEX = /^[a-zA-Z0-9-_.,& ]{0,500}$/;

const Markets = () => {

	const [loading, setLoading] = useState(false);
	const [markets, setMarkets] = useState();
	const axiosPrivate = useAxiosPrivate();

	const navigate = useNavigate();
	const location = useLocation();

	const auth = useAuth();
	const role = auth?.role;
	const id = auth?.id;

	const [status, setStatus] = useState()

	const [name, setName] = useState();
	const [description, setDescription] = useState();
	const [state, setState] = useState();
	const [open, setOpen] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [openView, setOpenView] = useState(false);

	const [deleteID, setDeleteID] = useState()
	const [deleteName, setDeleteName] = useState()

	const [viewName, setViewName] = useState()
	const [viewDescription, setViewDescription] = useState()
	const [viewZips, setViewZips] = useState()
	const [viewState, setViewState] = useState()

	const [editID, setEditID] = useState()
	const [editName, setEditName] = useState()
	const [editDescription, setEditDescription] = useState()
	const [editState, setEditState] = useState()

	const nameRef = useRef();
	const descriptionRef = useRef();

	const [validName, setValidName] = useState(false);
	const [nameFocus, setNameFocus] = useState(false);
	const [validDescription, setValidDescription] = useState(false);

	const [activeState, setActiveState] = useState([])

	const handleStateChange = () => {

	}

	/* Check Name */
	useEffect(() => {
		const result = NAME_REGEX.test(name);
		setValidName(result);
	}, [name])

	/* Check Description */
	useEffect(() => {
		const result = DESCRIPTION_REGEX.test(description);
		setValidDescription(result);
	}, [description])

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleEdit = (edit) => {
		setOpenEdit(true);
	};

	const handleCloseEdit = () => {
		setOpenEdit(false);
	};

	const handleView = () => {
		setOpenView(true);
	};

	const handleCloseView = () => {
		setOpenView(false);
	};

	const handleDelete = async () => {
		const dismiss = () =>  toast.dismiss(deleteMarketToast.current);
		const deleteMarketToast = toast.loading("Deleting Market");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.delete('market/' + deleteID,
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(deleteMarketToast, { render: 'Market Deleted', type: 'success', isLoading: false, autoClose: 5000});

			// Close Alert Window
			setOpen(false);

			// Delete job from UI
			setMarkets(markets.filter((market) => market.id !== deleteID))

		} catch (err) {
			if (!err?.response) {
				toast.update(deleteMarketToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(deleteMarketToast, { render: 'Market Delete Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	};

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getMarkets = async () => {
			try {
				const response = await axiosPrivate.get('markets', {
					signal: controller.signal
				});
				console.log(response.data);
				setMarkets(response.data);
				setLoading(false)
			} catch(err) {
				console.log(err.message);
				!auth && navigate('/login', { state: { from: location }, replace: true });
			}
		}

		getMarkets();

		return () => controller.abort();

	},[])

	const deleteMarket = (e, i, id, name) => {
		setOpen(true);
		setDeleteName(name);
		setDeleteID(id);
	}

	const viewMarket = (e, i, id, name, description, zipcodes, state) => {
		setOpenView(true);
		setViewName(name)
		setViewDescription(description)
		setViewZips(zipcodes)
		setViewState(statesList[statesList?.findIndex( s => s.Code == state )].State)
	}

	const editMarket = (e, i, id, name, description, state) => {
		setOpenEdit(true);
		setEditDescription(description)
		setEditName(name)
		setEditID(id)
		setEditState(state)
	}

	const addMarket = (market) => {
		console.log(market);
		setMarkets([...markets, market]);
	}

	const formatDate = (dateString) => {
	  const options = { month: "long", day: "numeric", year: "numeric" }
	  return new Date(dateString).toLocaleDateString(undefined, options)
	}

	const handleEditSave = async () => {
		const dismiss = () =>  toast.dismiss(saveMarketToast.current);
		const saveMarketToast = toast.loading("Updating Market");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.put('market/' + editID,
			JSON.stringify({market_name: editName, market_description: editDescription, market_state: editState}),
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(saveMarketToast, { render: 'Market Updated', type: 'success', isLoading: false, autoClose: 5000});

			// Close Alert Window
			setOpenEdit(false);
			setEditDescription()
			setEditName()
			setEditID()
			setEditState()

			// Update category state
			const index = markets.map(function(x) {return x.id; }).indexOf(editID);
			markets[index].market_name = editName;
			markets[index].market_description = editDescription;
			markets[index].market_state = editState;

		} catch (err) {
			if (!err?.response) {
				toast.update(saveMarketToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(saveMarketToast, { render: 'Market Update Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
	const onSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        const sortedData = [...markets].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setMarkets(sortedData);
        setSortConfig({ key, direction });
    };

	return (
		<div className="backendList">
			{markets?.length
				? (
					<>
						<ul className="marketsList">
							<li className="key">
								<div className="name" onClick={() => onSort('market_name')}>Name</div>
								<div className="state" onClick={() => onSort('market_state')}>State</div>
								<div className="date" onClick={() => onSort('created_at')}>Date Created</div>
								<div className="buttons"></div>
							</li>
							{markets.map((market, i) =>
								<li key={i}>
									<div className="name">
										{market?.market_name}
									</div>
									<div className="state">
										{market?.market_state}
									</div>
									<div className="date">
										{formatDate(market?.created_at)}
									</div>
									<div className="buttons">
										<div className="edit" onClick={(e) => editMarket(e, i, market?.id, market?.market_name, market?.market_description, market?.market_state)}>
											<span><MdOutlineEdit /></span>
										</div>
										<div className="view" onClick={(e) => viewMarket(e, i,  market?.id, market?.market_name, market?.market_description, market?.zipcodes, market?.market_state)}>
											<span><MdVisibility /></span>
										</div>
										<div className="delete" onClick={(e) => deleteMarket(e, i, market?.id, market?.market_name, market?.market_description)}>
											<span><AiFillDelete /></span>
										</div>
									</div>
								</li>
							)}
						</ul>
						{/* Delete Dialog */}
						<Dialog
							open={open}
							onClose={handleClose}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						  >
							<DialogTitle id="alert-dialog-title">
							  {"Are you sure you want to delete this market?"}
							</DialogTitle>
							<DialogContent>
							  <DialogContentText id="alert-dialog-description">
								This action cannot be undone. This will permanently delete the market with name, "{deleteName}".
							  </DialogContentText>
							</DialogContent>
							<DialogActions>
							  <button className="cancel" onClick={handleClose}>Cancel</button>
							  <button className="confirm" onClick={handleDelete}>Yes, delete market</button>
							</DialogActions>
						  </Dialog>

						{/* Edit Dialog */}
						<Dialog
							open={openEdit}
							onClose={handleCloseEdit}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						  >
							<DialogTitle id="alert-dialog-title">
							  {`Editing ${editName} Market`}
							</DialogTitle>
							<DialogContent>
							  <DialogContentText id="alert-dialog-description">
								<form>
									{/* Name */}
									<label htmlFor="name">Market Name:</label>
									<div className="inputWrapper">
										<span className={validName ? "valid" : "hide"}><FaCheck /></span>
										<span className={validName || !editName ? "hide" : "invalid"}><FaExclamation /></span>
										<input
											type="text"
											id="name"
											className={validName || !editName ? null : "error"}
											ref={nameRef}
											autoComplete="off"
											onChange={(e) => setEditName(e.target.value)}
											value={editName}
											aria-invalid={validName ? "false" : "true"}
											aria-describedby="namenote"
											onFocus={() => setNameFocus(true)}
											onBlur={() => setNameFocus(false)}
										/>
									</div>
									<p id="namenote" className={nameFocus && editName && !validName ? "instructions" : "offscreen"}>
										Invalid Market Name. 1-50 characters (letters, numbers, dash and underscore allowed)
									</p>

									{/* Description */}
									<label htmlFor="description">Description:</label>
									<div className="inputWrapper">
										<span className={validDescription ? "valid" : "hide"}><FaCheck /></span>
										<span className={validDescription || !editDescription ? "hide" : "invalid"}><FaExclamation /></span>
										<textarea
											ref={descriptionRef}
											onChange={(e) => setEditDescription(e.target.value)}
											name="description"
											id="description"
											rows="5"
											value={editDescription}
										>
										</textarea>
										<p id="descriptionnote" className={editDescription && !validDescription ? "instructions" : "offscreen"}>
											Invalid Description. Between 1-500 characters (letters, numbers, dash and underscore allowed)
										</p>
									</div>

									{/* States */}
									<label htmlFor="description">State:</label>
									<div className="inputWrapper">
										{statesList?.length ?
											<select
												id="active_state"
												name="active_state"
												value={editState}
												onChange={(e) => setEditState(e.target.value)}
											>
												{statesList?.map((s, i) =>
													<option key={i} value={s.Code}>{s.State}</option>
												)}

											</select>
											: "No states available"
										}
									</div>

								</form>
							  </DialogContentText>
							</DialogContent>
							<DialogActions>
							  <button className="cancel" onClick={handleCloseEdit}>Cancel</button>
							  <button className="confirm" onClick={handleEditSave}>Yes, update market</button>
							</DialogActions>
						  </Dialog>

						{/* View Dialog */}
						<Dialog
							open={openView}
							onClose={handleCloseView}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
							className="viewMarketPopup"
						  >
							<DialogTitle id="alert-dialog-title">
							  {`${viewName} Market`}
							</DialogTitle>
							<DialogContent>
								<>
									State: {viewState}<br />
									Description: {viewDescription}
								</>
							</DialogContent>
							<DialogActions>
							  <button className="cancel" onClick={handleCloseView}>Close</button>
							</DialogActions>
						  </Dialog>
					</>
				) :
					loading ?
					<Loader />
					:
					<p>No markets to display</p>
			}
			<CreateMarket addMarket={addMarket} />
			<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
			/>
		</div>
	)
}

export default Markets