import { useState, useEffect, useRef } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import { AiFillDelete } from 'react-icons/ai';
import { AiOutlineShareAlt } from 'react-icons/ai';
import { GrFormEdit } from 'react-icons/gr';
import { FaCheck } from 'react-icons/fa';
import { FaExclamation } from 'react-icons/fa';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ToastContainer, toast } from 'react-toastify';
import Loader from './Loader';
import FileDropEmbed from './FileDropEmbed';
import SupportForm from './SupportForm'

const NAME_REGEX = /^[a-zA-Z0-9-_&@'?! ]{1,50}$/;
const DESCRIPTION_REGEX = /^[a-zA-Z0-9-_.,& #:;'?!]{3,500}$/;

const SupportTickets = () => {

	const [loading, setLoading] = useState(false);
	const [tickets, setTickets] = useState();
	const [favorites, setFavorites] = useState();
	const axiosPrivate = useAxiosPrivate();

	const navigate = useNavigate();
	const location = useLocation();

	const auth = useAuth();
	const role = auth?.role;
	const id = auth?.id;

	const descriptionRef = useRef();
	const nameRef = useRef();

	const [files, setFiles] = useState([]);

	const [deleteDescription, setDeleteDescription] = useState();
	const [deleteID, setDeleteID] = useState();
	const [deleteSubject, setDeleteSubject] = useState();
	const [open, setOpen] = useState(false);

	const [shareID, setShareID] = useState();
	const [shareName, setShareName] = useState();
	const [openShare, setOpenShare] = useState(false);

	const [openEdit, setOpenEdit] = useState(false);

	const [editID, setEditID] = useState();
	const [editIndex, setEditIndex] = useState();
	const [editSubject, setEditSubject] = useState();
	const [validEditSubject, setValidEditSubject] = useState(false);
	const [editSubjectFocus, setEditSubjectFocus] = useState(false);
	const [editType, setEditType] = useState()

	const [editDescription, setEditDescription] = useState();
	const [validEditDescription, setValidEditDescription] = useState(false);
	const [editDescriptionFocus, setEditDescriptionFocus] = useState(false);

	const [editImages, setEditImages] = useState();
	const [deleteImages, setDeleteImages] = useState([]);
	const [validFiles, setValidFiles] = useState(false);
	const [fileLimit, setFileLimit] = useState(10);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleClickOpenShare = () => {
		setOpenShare(true);
	};

	const handleCloseShare = () => {
		setOpenShare(false);
	};

	const handleClickOpenEdit = () => {
		setOpenEdit(true);
	};

	const handleCloseEdit = () => {
		setOpenEdit(false);
		setDeleteImages([])
		setFiles([])
	};

	// Edit Ticket
	const handleEditSave = async () => {

	}

	// Delete Ticket
	const handleDelete = async () => {
		const dismiss = () =>  toast.dismiss(deleteTicketToast.current);
		const deleteTicketToast = toast.loading("Deleting Ticket");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.delete('ticket/' + deleteID,
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(deleteTicketToast, { render: 'Ticket Deleted', type: 'success', isLoading: false, autoClose: 5000});

			// Close Alert Window
			setOpen(false);

			// Delete job from UI
			setTickets(tickets.filter((ticket) => ticket.id !== deleteID))

		} catch (err) {
			if (!err?.response) {
				toast.update(deleteTicketToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(deleteTicketToast, { render: 'Job Delete Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	};

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getTickets = async () => {
			try {
				const response = await axiosPrivate.get('user/tickets', {
					signal: controller.signal
				});
				console.log(response.data);
				setTickets(response.data.tickets);
				setLoading(false)
			} catch(err) {
				console.log(err.message);
				{/* !auth && navigate('/login', { state: { from: location }, replace: true }); */}
			}
		}

		getTickets();

		return () => controller.abort();

	// eslint-disable-next-line
	},[])

	/* Check Name */
	useEffect(() => {
		const result = NAME_REGEX.test(editSubject);
		setValidEditSubject(result);
	}, [editSubject])

	/* Check Description */
	useEffect(() => {
		const result = DESCRIPTION_REGEX.test(editDescription);
		setValidEditDescription(result);
	}, [editDescription])

	const deleteJob = (e, i, subject, id) => {
		setOpen(true);
		setDeleteSubject(subject);
		setDeleteID(id);
	}

	const editTicket = (e, i, subject, id, description, type) => {
		setOpenEdit(true);
		setEditSubject(subject);
		setEditID(id)
		setEditIndex(i)
		setEditDescription(description)
		setEditType(type)
	}

	const addTicket = (ticket) => {
		console.log(ticket);
		setTickets([...tickets, ticket]);
	}

	const shareJob = (e,i, name, id) => {
		setOpenShare(true);
		setShareID(id);
		setShareName(name);
	}

	const formatDate = (dateString) => {
	  const options = { month: "long", day: "numeric", year: "numeric" }
	  return new Date(dateString).toLocaleDateString(undefined, options)
	}

	return (
		<div className="backendList">
			{tickets?.length
				? (
					<>
					<ul className="ticketList">
						<li className="key">
							<div className="subject">Subject</div>
							<div className="date">Date</div>
							<div className="buttons"></div>
						</li>
						{tickets.map((ticket, i) =>
							<li key={i}>
								<div className="subject">
									{ticket?.subject}
								</div>
								<div className="date">
									{formatDate(ticket?.created_at)}
								</div>
								<div className="buttons">
									<div className="edit" onClick={(e) => editTicket(e, i, ticket?.subject, ticket?.id, ticket?.description, ticket?.service_type)}>
										<span><GrFormEdit /></span>
									</div>
									<div className="delete" onClick={(e) => deleteJob(e, i, ticket?.subject, ticket?.id)}>
										<span><AiFillDelete /></span>
									</div>
								</div>
							</li>
						)}
					</ul>

					{/* DELETE JOB */}
					<Dialog
						open={open}
						onClose={handleClose}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					  >
						<DialogTitle id="alert-dialog-title">
						  {"Are you sure you want to delete this ticket?"}
						</DialogTitle>
						<DialogContent>
						  <DialogContentText id="alert-dialog-description">
							{`This action cannot be undone. This will permanently delete the ticket with subject, ${deleteSubject}`}.
						  </DialogContentText>
						</DialogContent>
						<DialogActions>
						  <button className="cancel" onClick={handleClose}>Cancel</button>
						  <button className="confirm" onClick={handleDelete}>Yes, delete ticket</button>
						</DialogActions>
					</Dialog>

					{/* EDIT Ticket */}
					<Dialog
						open={openEdit}
						onClose={handleCloseEdit}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
						id="editJobPopup"
					  >
						<DialogTitle id="alert-dialog-title">
						  {`Editing ticket '${editSubject}'`}
						</DialogTitle>
						<DialogContent>
						  <DialogContentText id="alert-dialog-description">
							<form encType="multipart/form-data" >
								{/* Name */}
								<label htmlFor="subject">Subject:</label>
								<div className="inputWrapper">
									<span className={validEditSubject ? "valid" : "hide"}><FaCheck /></span>
									<span className={validEditSubject || !editSubject ? "hide" : "invalid"}><FaExclamation /></span>
									<input
										type="text"
										id="name"
										className={validEditSubject || !editSubject ? null : "error"}
										ref={nameRef}
										autoComplete="off"
										onChange={(e) => setEditSubject(e.target.value)}
										value={editSubject}
										aria-invalid={validEditSubject ? "false" : "true"}
										aria-describedby="namenote"
										onFocus={() => setEditSubjectFocus(true)}
										onBlur={() => setEditSubjectFocus(false)}
									/>
								</div>
								<p id="namenote" className={editSubjectFocus && editSubject && !validEditSubject ? "instructions" : "offscreen"}>
									Invalid Job Name. 1-50 characters (letters, numbers, dash, exclamation mark, underscore, &, @ allowed)
								</p>

								{/* Support Category */}
								<label htmlFor="type">What do you need help with?</label>
								<div className="inputWrapper">
									<select
										name="type"
										id="type"
										defaultValue={editType}
										onChange={(e) => setEditType(e.target.value)}
										value={editType}
									>
										<option value="Profile">Profile</option>
										<option value="Billing">Billing</option>
										<option value="Promotions">Promotions</option>
										<option value="Complaint">Complaint</option>
										<option value="Reviews">Reviews</option>
										<option value="Magazine">Magazine</option>
										<option value="Cancel Account">Cancel Account</option>
									</select>
								</div>

								{/* Description */}
								<label htmlFor="description">Description:</label>
								<div className="inputWrapper">
									<span className={validEditDescription ? "valid" : "hide"}><FaCheck /></span>
									<span className={validEditDescription || !validEditSubject ? "hide" : "invalid"}><FaExclamation /></span>
									<textarea
										ref={descriptionRef}
										onChange={(e) => setEditDescription(e.target.value)}
										name="description"
										id="description"
										rows="5"
										value={editDescription}
									>
									</textarea>
									<p id="descriptionnote" className={editDescriptionFocus && editDescription && !validEditDescription ? "instructions" : "offscreen"}>
										Invalid Description. Between 1-500 characters (letters, numbers, dash, underscore, exclamation mark, & allowed)
									</p>
								</div>

							</form>
						  </DialogContentText>
						</DialogContent>
						<DialogActions>
						  <button className="cancel" onClick={handleCloseEdit}>Cancel</button>
						  <button disabled={!validEditDescription || !validEditSubject  || !validFiles ? true : false} className="confirm" onClick={handleEditSave}>Save Ticket</button>
						</DialogActions>
					</Dialog>

		</>
				) :
					loading ?
					<Loader />
					:
					<p>You have not created any tickets yet.</p>
			}
			<SupportForm addTicket={addTicket} />
			<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
			/>
		</div>
	)
}

export default SupportTickets