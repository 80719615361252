import { useEffect, useState } from 'react';
import useAuth from "../hooks/useAuth";
import TextEditor from "./TextEditor";
import { Markup } from 'interweave';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Loader from './Loader';
import SingleFileDrop from './SingleFileDrop';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import PageFileDrop from "./PageFileDrop"
import SearchBox from "./SearchBox"


const LegalPage = ({ slug, title }) => {

	const axiosPrivate = useAxiosPrivate();
	const [loading, setLoading] = useState(false);

	const { auth, editing, setEditing } = useAuth()
	const [page, setPage] = useState()
	const [pageID, setPageID] = useState()
	const [files, setFiles] = useState([])

	const [pageImages, setPageImages] = useState([])
	const [selectedImage, setSelectedImage] = useState()
	const [uploadOpen, setUploadOpen] = useState(false)

	const handleUpload = () => {
		setUploadOpen(true)
	};

	const handleUploadClose = () => {
		setUploadOpen(false)
	};

	const handleUploadImage = () => {
		setUploadOpen(false)
	};

	const getPage = async () => {
		const controller = new AbortController();
		setLoading(true);
		try {
			const response = await axiosPrivate.get('page-by-slug/' + slug, {
				signal: controller.signal
			});
			setPage(response.data.page);
			setPageID(response.data.page.id)
			setPageImages(response?.data.images)
			setLoading(false)
		} catch(err) {
			console.log(err.message);
			{/* !auth && navigate('/login', { state: { from: location }, replace: true }); */}
		}
		return () => controller.abort();
	}

	useEffect(() => {
		getPage();
		// eslint-disable-next-line
	},[])


	useEffect(() => {
		if (editing == true && (auth?.user?.role === "5150" || auth?.user?.role === "127001")){
			document.body.classList.add('editing')
		} else {
			document.body.classList.remove('editing');
		}
		// Update data anytime page editing is changed
		getPage();
	}, [editing])

	return (
		<>
		{editing && (auth?.user?.role == 5150 || auth?.user?.role == 127001) ?
			<>
				<div className='legalInner'>
					<h3>Editing Page</h3>
					<p className="slug">Page Slug: { slug }</p>
				</div>
			</>
		:
		<>

			{loading && <div className='legalInner'><Loader /></div>}
			{!loading && page &&
				<>
					{ slug !== "home" &&
						<div className='legalInner legalContent'>
							{title && <h2>{title}</h2>}
							<Markup content={page?.content} />
						</div>
					}
				</>
			}

			{!loading && !page?.content && <div className='legalInner'><p>No Page Content Available</p></div>}

		</>
		}

		{/* Upload Image Dialog */}
		<Dialog
			open={uploadOpen}
			onClose={handleUploadClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
			<DialogTitle id="alert-dialog-title">
			  {"Upload Image(s)"}
			</DialogTitle>
			<DialogContent>
			  <>
				  <PageFileDrop showFiles="true" autoUpload="" limit={10} multiple="true" accept="" buttonTitle="" handleUploadImage={handleUploadImage} setPageImages={setPageImages} pageImages={pageImages} />
			  </>
			</DialogContent>
			<DialogActions>
			  <button className="cancel" onClick={handleUploadClose}>Cancel</button>
			  {/* <button className="confirm" onClick={handleUploadImage}>Upload New</button> */}
			</DialogActions>
		  </Dialog>
		</>
	)
}

export default LegalPage