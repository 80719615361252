import usePageTitle from '../hooks/usePageTitle';
import Page from '../components/Page';

const About = () => {
	usePageTitle("About Us");
	return (
		
		<section>
			<Page title='About Us' slug='about-us' />
		</section>
	)
}

export default About