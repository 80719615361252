import { useState, useEffect, useRef } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import { AiFillDelete } from 'react-icons/ai';
import { AiOutlineShareAlt } from 'react-icons/ai';
import { MdOutlineEdit } from 'react-icons/md';
import { MdVisibility } from 'react-icons/md';
import { FaCheck } from 'react-icons/fa';
import { FaExclamation } from 'react-icons/fa';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import Loader from './Loader';
import CreatePackage from './CreatePackage'
import Slider from '@mui/material/Slider';
import CreateOption from './CreateOption'
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const NAME_REGEX = /^[a-zA-Z0-9-_&@#%$ ]{1,50}$/;
const PRICE_REGEX = /^[0-9.]{1,10}$/;
const DESCRIPTION_REGEX = /^[a-zA-Z0-9-_.,& \r\n]{1,500}$/;
const CODE_REGEX = /^[a-zA-Z0-9-_&@#$%!-/ ]{1,20}$/;
const DISCOUNT_REGEX = /^[0-9%.]{1,10}$/;

const Coupons = () => {

	const [loading, setLoading] = useState(false);
	const [coupons, setCoupons] = useState();
	const axiosPrivate = useAxiosPrivate();

	const navigate = useNavigate();
	const location = useLocation();

	const auth = useAuth();
	const role = auth?.role;
	const id = auth?.id;

	const editNameRef = useRef()
	const editCouponRef = useRef()
	const couponRef = useRef()
	const discountRef = useRef()
	const editDiscountRef = useRef()

	const [openEdit, setOpenEdit] = useState(false)
	const [openView, setOpenView] = useState(false)
	const [openDelete, setOpenDelete] = useState(false)
	const [openAdd, setOpenAdd] = useState(false)

	const [name, setName] = useState()
	const [nameFocus, setNameFocus] = useState(false)
	const [validName, setValidName] = useState(false)

	const [code, setCode] = useState()
	const [validCode, setValidCode] = useState(false)
	const [codeFocus, setCodeFocus] = useState(false)

	const [discount, setDiscount] = useState()
	const [validDiscount, setValidDiscount] = useState(false)
	const [discountFocus, setDiscountFocus] = useState(false)

	const [trialMonths, setTrialMonths] = useState()
	const [editTrialMonths, setEditTrialMonths] = useState()
	const [viewTrialMonths, setViewTrialMonths] = useState()

	const [editDiscount, setEditDiscount] = useState()
	const [validEditDiscount, setValidEditDiscount] = useState(false)
	const [editDiscountFocus, setEditDiscountFocus] = useState(false)

	const [deleteName, setDeleteName] = useState()
	const [deleteID, setDeleteID] = useState()

	const [editName, setEditName] = useState()
	const [validEditName, setValidEditName] = useState(false)
	const [editNameFocus, setEditNameFocus] = useState(false)

	const [status, setStatus] = useState()
	const [editStatus, setEditStatus] = useState()
	const [editID, setEditID] = useState()

	const [editCode, setEditCode] = useState()
	const [validEditCode, setValidEditCode] = useState(false)
	const [editCodeFocus, setEditCodeFocus] = useState(false)

	const [viewName, setViewName] = useState()
	const [viewStatus, setViewStatus] = useState()
	const [viewCode, setViewCode] = useState()
	const [viewDiscount, setViewDiscount] = useState()

	const [couponEnabled, setCouponEnabled] = useState(0)
	const [editCouponEnabled, setEditCouponEnabled] = useState(0)

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getCoupons = async () => {
			try {
				const response = await axiosPrivate.get('coupons', {
					signal: controller.signal
				});
				setCoupons(response.data);
				setLoading(false)
			} catch(err) {
				console.log(err.message);
				!auth && navigate('/login', { state: { from: location }, replace: true });
			}
		}

		getCoupons();

		return () => controller.abort();
		// eslint-disable-next-line
	},[])

	/* Check Name */
	useEffect(() => {
		const result = NAME_REGEX.test(name);
		setValidName(result);
	}, [name])

	useEffect(() => {
		const result = NAME_REGEX.test(editName);
		setValidEditName(result);
	}, [editName])

	/* Check Code */
	useEffect(() => {
		const result = CODE_REGEX.test(code);
		setValidCode(result);
	}, [code])

	useEffect(() => {
		const result = CODE_REGEX.test(editCode);
		setValidEditCode(result);
	}, [editCode])

	/* Check Discount */
	useEffect(() => {
		const result = DISCOUNT_REGEX.test(discount);
		setValidDiscount(result);
	}, [discount])

	useEffect(() => {
		const result = DISCOUNT_REGEX.test(editDiscount);
		setValidEditDiscount(result);
	}, [editDiscount])

	const addCoupon = () => {
		setOpenAdd(true)
	}

	const editCoupon = (e, i, coupon) => {
		setEditCode(coupon.coupon_code)
		setEditID(coupon.id)
		setEditDiscount(coupon.discount)
		setEditName(coupon.coupon_name)
		setEditStatus(coupon.active)
		setEditTrialMonths(coupon.applies_to_months)
		setOpenEdit(true)
	};

	const viewCoupon = (e, i, coupon) => {
		setViewCode(coupon.coupon_code)
		setViewName(coupon.coupon_name)
		setViewStatus(coupon.active)
		setViewDiscount(coupon.discount)
		setViewTrialMonths(coupon.applies_to_months)
		setOpenView(true)
	};

	const deleteCoupon = (e, i, coupon) => {
		setDeleteID(coupon.id)
		setDeleteName(coupon.coupon_name)
		setOpenDelete(true)
	};

	const handleCloseAdd = () => {
		setOpenAdd(false);
	};

	const handleCloseView = () => {
		setOpenView(false);
	};

	const handleCloseEdit = () => {
		setOpenEdit(false);
	};

	const handleCloseDelete = () => {
		setOpenDelete(false);
	};

	const handleAddCoupon = async () => {
		const dismiss = () =>  toast.dismiss(addCouponToast.current);
		const addCouponToast = toast.loading("Adding Coupon");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.post('coupon',
			JSON.stringify({coupon_name: name, coupon_code: code, active: couponEnabled === "1" ? true : false, discount: discount, coupon_description: null, limit: null, expiration: null, applies_to_months: trialMonths}),
			{
				signal: controller.signal
			});

			// Update State
			setCoupons([...coupons, response?.data?.coupon])

			// Close edit popup
			setOpenAdd(false)

			// Clear previous data
			setName()
			setCode()
			setDiscount()
			setTrialMonths()

			// Update Toast Notification
			toast.update(addCouponToast, { render: 'Coupon Added', type: 'success', isLoading: false, autoClose: 5000});
		} catch (err) {
			if (!err?.response) {
				toast.update(addCouponToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else if (err.response?.status === 401) {
				toast.update(addCouponToast, { render: 'Authorization Failed', type: 'error', isLoading: false});
				{/* setErrMsg('The email has already been taken.'); */}
			} else {
				toast.update(addCouponToast, { render: 'Coupon Add Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	const handleUpdateCoupon = async () => {
		const dismiss = () =>  toast.dismiss(addCouponToast.current);
		const addCouponToast = toast.loading("Updating Coupon");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.put('coupon/' + editID,
			JSON.stringify({coupon_name: editName, coupon_code: editCode, active: editCouponEnabled, discount: editDiscount, coupon_description: null, limit: null, expiration: null, applies_to_months: editTrialMonths}),
			{
				signal: controller.signal
			});

			// Update State
			const index = coupons.map(function(x) {return x.id; }).indexOf(editID);
			coupons[index].coupon_name = editName;
			coupons[index].coupon_code = editCode;
			coupons[index].active = editCouponEnabled
			coupons[index].discount = editDiscount
			coupons[index].applies_to_months = editTrialMonths

			// Close edit popup
			setOpenEdit(false)

			// Clear previous data
			setName()
			setCode()
			setDiscount()

			// Update Toast Notification
			toast.update(addCouponToast, { render: 'Coupon Updated', type: 'success', isLoading: false, autoClose: 5000});
		} catch (err) {
			if (!err?.response) {
				toast.update(addCouponToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else if (err.response?.status === 401) {
				toast.update(addCouponToast, { render: 'Authorization Failed', type: 'error', isLoading: false});
				{/* setErrMsg('The email has already been taken.'); */}
			} else {
				toast.update(addCouponToast, { render: 'Coupon Update Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	const handleDeleteCoupon = async () => {
		const dismiss = () =>  toast.dismiss(deleteCouponToast.current);
		const deleteCouponToast = toast.loading("Deleting Coupon");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.delete('coupon/' + deleteID,
			{
				signal: controller.signal
			});

			// Update State
			setCoupons(coupons.filter((p) => p.id !== deleteID))

			// Close edit popup
			setOpenDelete(false)

			// Update Toast Notification
			toast.update(deleteCouponToast, { render: 'Coupon Deleted', type: 'success', isLoading: false, autoClose: 5000});
		} catch (err) {
			if (!err?.response) {
				toast.update(deleteCouponToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else if (err.response?.status === 401) {
				toast.update(deleteCouponToast, { render: 'Authorization Failed', type: 'error', isLoading: false});
				{/* setErrMsg('The email has already been taken.'); */}
			} else {
				toast.update(deleteCouponToast, { render: 'Coupon Delete Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	const newCoupon = (e) => {
		setOpenAdd(true)
	}

	const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
	const onSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        const sortedData = [...coupons].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setCoupons(sortedData);
        setSortConfig({ key, direction });
    };

	return (
		<div className="backendList">
			<div className="buttonContainer">
				<button onClick={(e) => newCoupon(e)}>Add Coupon</button>
			</div>

			<h3>Coupons</h3>
			{coupons?.length ?
			(
				<ul className="couponList">
					<li className="key">
						<div className="name" onClick={() => onSort('coupon_name')}>Name</div>
						<div className="code" onClick={() => onSort('coupon_code')}>Code</div>
						<div className="statusContainer" onClick={() => onSort('active')}>Status</div>
						<div className="buttons"></div>
					</li>
					{coupons?.map((coupon, i) =>
						<li key={i} >
							<div className="name">
								{coupon?.coupon_name}
							</div>
							<div className="code">
								{coupon?.coupon_code}
							</div>
							<div className="statusContainer">
								<div className={`status ${coupon.active ? 'active' : 'disabled'}`}>
									{coupon.active ? 'active' : 'disabled'}
								</div>
							</div>
							<div className="buttons">
								<div className="edit" onClick={(e) => editCoupon(e, i, coupon)}>
									<span><MdOutlineEdit /></span>
								</div>
								<div className="view" onClick={(e) => viewCoupon(e, i, coupon)}>
									<span><MdVisibility /></span>
								</div>
								<div className="delete" onClick={(e) => deleteCoupon(e, i, coupon)}>
									<span><AiFillDelete /></span>
								</div>
							</div>
						</li>
					)}
				</ul>
			) : loading ?
				<Loader />
				:
				<p>No coupons to display</p>
			}

			{/* Delete Coupon Dialog */}
			<Dialog
				open={openDelete}
				onClose={handleCloseDelete}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			  >
				<DialogTitle id="alert-dialog-title">
				  {"Are you sure you want to delete this coupon?"}
				</DialogTitle>
				<DialogContent>
				  <DialogContentText id="alert-dialog-description">
					This action cannot be undone. This will permanently delete the coupon with name, "{deleteName}".
				  </DialogContentText>
				</DialogContent>
				<DialogActions>
				  <button className="cancel" onClick={handleCloseDelete}>Cancel</button>
				  <button className="confirm" onClick={handleDeleteCoupon}>Yes, delete coupon</button>
				</DialogActions>
			</Dialog>

			{/* Edit Coupon Dialog */}
			<Dialog
				open={openEdit}
				onClose={handleCloseEdit}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			  >
				<DialogTitle id="alert-dialog-title">
				  {`Editing coupon ${editName}`}
				</DialogTitle>
				<DialogContent>
					<form>
						{/* Status */}
						<label htmlFor="enabled">Coupon Enabled:</label>
						<div className="inputWrapper">
							<select
								name="status"
								id="status"
								defaultValue={editCouponEnabled}
								onChange={(e) => setEditCouponEnabled(e.target.value)}
								value={editCouponEnabled}
							>
								<option value="1">Active</option>
								<option value="0">Disabled</option>
							</select>
						</div>


						{/* Name */}
						<label htmlFor="name">Coupon Name:</label>
						<div className="inputWrapper">
							<span className={validEditName ? "valid" : "hide"}><FaCheck /></span>
							<span className={validEditName || !editName ? "hide" : "invalid"}><FaExclamation /></span>
							<input
								type="text"
								id="name"
								className={validEditName || !editName ? null : "error"}
								ref={editNameRef}
								autoComplete="off"
								onChange={(e) => setEditName(e.target.value)}
								value={editName}
								aria-invalid={validEditName ? "false" : "true"}
								aria-describedby="namenote"
								onFocus={() => setEditNameFocus(true)}
								onBlur={() => setEditNameFocus(false)}
							/>
						</div>
						<p id="namenote" className={setEditNameFocus && editName && !validEditName ? "instructions" : "offscreen"}>
							Invalid Coupon Name. Coupon names must be between 1-50 characters long. Valid characters (-,_,&,@,#)
						</p>

						{/* Code */}
						<label htmlFor="code">Coupon Code:</label>
						<div className="inputWrapper">
							<span className={validEditCode ? "valid" : "hide"}><FaCheck /></span>
							<span className={validEditCode || !editCode ? "hide" : "invalid"}><FaExclamation /></span>
							<input
								type="text"
								name="code"
								ref={editCouponRef}
								onChange={(e) => setEditCode(e.target.value)}
								id="code"
								value={editCode}
								aria-invalid={validEditCode ? "false" : "true"}
								aria-describedby="codenote"
								onFocus={() => setEditCodeFocus(true)}
								onBlur={() => setEditCodeFocus(false)}
							/>
							<p id="codenote" className={editCodeFocus && editCode && !validEditCode ? "instructions" : "offscreen"}>
								Invalid Coupon Code. Between 1-20 characters (letters, numbers and these special characters allowed ($, %, #, @, &, -, /, !))
							</p>
						</div>

						{/* Discount */}
						<label htmlFor="code">Discount:</label>
						<div className="inputWrapper">
							<span className={validEditDiscount ? "valid" : "hide"}><FaCheck /></span>
							<span className={validEditDiscount || !editDiscount ? "hide" : "invalid"}><FaExclamation /></span>
							<input
								type="text"
								name="discount"
								ref={editDiscountRef}
								onChange={(e) => setEditDiscount(e.target.value)}
								id="discount"
								value={editDiscount}
								aria-invalid={validEditDiscount ? "false" : "true"}
								aria-describedby="discountnote"
								onFocus={() => setEditDiscountFocus(true)}
								onBlur={() => setEditDiscountFocus(false)}
							/>
							<p id="discountnote" className={editDiscountFocus && editDiscount && !validEditDiscount ? "instructions" : "offscreen"}>
								Invalid Discount. (Use a number between 1 and 100 with a % for percentage discount. For flat discount enter the value of the discount itself. etc ( 50% for 50% off, or 25.00 to subtract $25 ))
							</p>
						</div>

						{/* Trial Months */}
						<label htmlFor="code">Trial Months:</label>
						<p className="inputNote">This is the number of months you want the discount to apply to.</p>
						<div className="inputWrapper">
							<input
								type="number"
								name="trialMonths"
								onChange={(e) => setEditTrialMonths(e.target.value)}
								id="trialMonths"
								value={editTrialMonths}
							/>
						</div>

					</form>
				</DialogContent>
				<DialogActions>
				  <button className="cancel" onClick={handleCloseEdit}>Cancel</button>
				  <button className="confirm" disabled={!validEditCode || !validEditName ? true : false} onClick={handleUpdateCoupon}>Yes, update coupon</button>
				</DialogActions>
			</Dialog>

			{/* View Coupon Dialog */}
			<Dialog
				open={openView}
				onClose={handleCloseView}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="viewMarketPopup"
			  >
				<DialogTitle id="alert-dialog-title">
				  {`${viewName}`}
				</DialogTitle>
				<DialogContent>
					<>
						{viewStatus && <div><strong>Status:</strong> {viewStatus ? 'active' : 'disabled'}</div> }
						{viewName && <div><strong>Name:</strong> {viewName}</div> }
						{viewCode && <div><strong>Code:</strong> {viewCode}</div> }
						{viewDiscount && <div><strong>Discount:</strong> {viewDiscount}</div> }
					</>
				</DialogContent>
				<DialogActions>
				  <button className="cancel" onClick={handleCloseView}>Close</button>
				</DialogActions>
			</Dialog>

			{/* Add Coupon Dialog */}
			<Dialog
				open={openAdd}
				onClose={handleCloseAdd}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			  >
				<DialogTitle id="alert-dialog-title">
				  {"Adding Coupon"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<form>
							{/* Status */}
							<label htmlFor="enabled">Coupon Enabled:</label>
							<div className="inputWrapper">
								<select
									name="status"
									id="status"
									onChange={(e) => setCouponEnabled(e.target.value)}
									value={couponEnabled}
								>
									<option value="1">Active</option>
									<option value="0">Disabled</option>
								</select>
							</div>


							{/* Name */}
							<label htmlFor="name">Coupon Name:</label>
							<div className="inputWrapper">
								<span className={validName ? "valid" : "hide"}><FaCheck /></span>
								<span className={validName || !name ? "hide" : "invalid"}><FaExclamation /></span>
								<input
									type="text"
									id="name"
									className={validName || !name ? null : "error"}
									ref={editNameRef}
									autoComplete="off"
									onChange={(e) => setName(e.target.value)}
									value={name}
									aria-invalid={validName ? "false" : "true"}
									aria-describedby="namenote"
									onFocus={() => setNameFocus(true)}
									onBlur={() => setNameFocus(false)}
								/>
							</div>
							<p id="namenote" className={nameFocus && name && !validName ? "instructions" : "offscreen"}>
								Invalid Coupon Name. Coupon names must be between 1-50 characters long. Valid characters (-,_,&,@,#,$)
							</p>

							{/* Code */}
							<label htmlFor="code">Coupon Code:</label>
							<div className="inputWrapper">
								<span className={validCode ? "valid" : "hide"}><FaCheck /></span>
								<span className={validCode || !code ? "hide" : "invalid"}><FaExclamation /></span>
								<input
									type="text"
									ref={couponRef}
									onChange={(e) => setCode(e.target.value)}
									name="code"
									id="code"
									value={code}
									aria-invalid={validCode ? "false" : "true"}
									aria-describedby="codenote"
									onFocus={() => setCodeFocus(true)}
									onBlur={() => setCodeFocus(false)}
								/>
								<p id="codenote" className={codeFocus && code && !validCode ? "instructions" : "offscreen"}>
									Invalid Coupon Code. Between 1-20 characters (letters, numbers and these special characters allowed ($, %, #, @, &, -, /, !))
								</p>
							</div>

							{/* Discount */}
							<label htmlFor="code">Discount:</label>
							<div className="inputWrapper">
								<span className={validDiscount ? "valid" : "hide"}><FaCheck /></span>
								<span className={validDiscount || !discount ? "hide" : "invalid"}><FaExclamation /></span>
								<input
									type="text"
									name="discount"
									ref={discountRef}
									onChange={(e) => setDiscount(e.target.value)}
									id="discount"
									value={discount}
									aria-invalid={validDiscount ? "false" : "true"}
									aria-describedby="discountnote"
									onFocus={() => setDiscountFocus(true)}
									onBlur={() => setDiscountFocus(false)}
								/>
								<p id="discountnote" className={discountFocus && discount && !validDiscount ? "instructions" : "offscreen"}>
									Invalid Discount. (Use a number between 1 and 100 with a % for percentage discount. For flat discount enter the value of the discount itself. etc ( 50% for 50% off, or 25.00 to subtract $25 ))
								</p>
							</div>

							{/* Trial Months */}
							<label htmlFor="code">Trial Months:</label>
							<p className="inputNote">This is the number of months you want the discount to apply to.</p>
							<div className="inputWrapper">
								<input
									type="number"
									name="trialMonths"
									onChange={(e) => setTrialMonths(e.target.value)}
									id="trialMonths"
									value={trialMonths}
								/>
							</div>

						</form>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					  <button className="cancel" onClick={handleCloseAdd}>Cancel</button>
					  <button className="confirm" onClick={handleAddCoupon} disabled={!validCode || !validDiscount || !validName ? true : false}>Add Coupon</button>
				</DialogActions>
			</Dialog>

			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="colored"
			/>

		</div>
	)
}

export default Coupons