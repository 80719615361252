import { Link } from 'react-router-dom';
import { RiFacebookCircleLine } from 'react-icons/ri';
import { RiInstagramLine } from 'react-icons/ri';
import { useMediaQuery } from 'react-responsive'

const Footer = () => {

	const getCurrentYear = () => {
		return new Date().getFullYear();
	}

	const isMobile = useMediaQuery({
		query: '(max-width: 800px)'
	})

	return (
		<>
		{ isMobile ?
			<footer>
				<article className='inner'>
					<Link to="/"><img className="logo" src="/assets/images/iyh_logo-white.png" alt="Improve Your House"/></Link>
					<ul>
						<li><Link to="about-us">About Us</Link></li>
						<li><Link to="become-a-home-pro">Advertise</Link></li>
						<li><a href="https://magazine.improveyourhouse.com" target="_blank">IYH Magazine</a></li>
						<li><Link to="contact-us">Contact Us</Link></li>
						<li><a href={`http://legal.${process.env.REACT_APP_URL}/terms-of-use`} target="_blank">Terms of Use</a></li>
						<li><a href={`http://legal.${process.env.REACT_APP_URL}/privacy-policy`} target="_blank">Privacy Policy</a></li>
						<li><a href={`http://legal.${process.env.REACT_APP_URL}`} target="_blank">Legal</a></li>
						<li><Link to="login">Login/Signup</Link></li>

					</ul>
				</article>
				<div className="sub__footer">
					<article>
						<p id='copy'>©Copyright 2018-{getCurrentYear()}, GDHI Marketing LLC.<br /><span>All rights reserved.</span></p>
						<p id='credit'><a href="https://tandemdesignlab.com" target="_blank" rel="noreferrer">Tandem Design Lab</a></p>
					</article>
				</div>
			</footer>
			:
			<footer>
				<article className='inner'>
					<div className='columnWrapper'>
						<div className="column">
							<h3>Learn More</h3>
							<ul className='footer-links-ul'>
								<li><Link to="about-us">About Us</Link></li>
								<li><Link to="become-a-home-pro">Advertise</Link></li>
								<li><a href="https://magazine.improveyourhouse.com" target="_blank">IYH Magazine</a></li>
							</ul>
						</div>
						<div className="column">
							<Link to="/"><img className="logo" src="/assets/images/iyh_logo-white.png" alt="Improve Your House"/></Link>
						</div>
						<div className="column">
							<h3>Links</h3>
							<ul className='footer-links-ul'>
								{/* <li><Link to="media-kit">Request Media Kit</Link></li> */}
								<li><Link to="contact-us">Contact Us</Link></li>
								<li><Link to="login">Login/Signup</Link></li>
								{/* <li><Link to="frequently-asked-questions">FAQ</Link></li> */}
								{/* <li><Link to="recruiting">Recruiting</Link></li> */}
							</ul>
						</div>
					</div>
				</article>
				<article className='inner'>

					<ul className='terms'>
						<li><a href={`https://legal.${process.env.REACT_APP_URL}/terms-of-use`}>Terms of Use</a></li>
						<li><a href={`https://legal.${process.env.REACT_APP_URL}/privacy-policy`}>Privacy Policy</a></li>
						<li><a href={`https://legal.${process.env.REACT_APP_URL}`}>Legal</a></li>
					</ul>
				</article>

				<div className="sub__footer">
					<article>
						<p id='copy'>©Copyright 2018-{getCurrentYear()}, GDHI Marketing LLC. All rights reserved.</p>
						<p id='credit'><a href="https://tandemdesignlab.com" target="_blank" rel="noreferrer">Tandem Design Lab</a></p>
					</article>
				</div>
			</footer>
		}
		</>
	)
}

export default Footer