
import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';
import { FaExclamation } from 'react-icons/fa';
import usePageTitle from '../hooks/usePageTitle';
import UserPageNav from '../components/UserPageNav';
import useAuth from '../hooks/useAuth';
import { ToastContainer, toast } from 'react-toastify';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useRefreshToken from '../hooks/useRefreshToken';
import FileDropEmbed from './FileDropEmbed';

const NAME_REGEX = /^[a-zA-Z0-9-_&@ ]{1,50}$/;
const DESCRIPTION_REGEX = /^[a-zA-Z0-9-_.,& ]{1,500}$/;
const ADDRESS_REGEX = /^[a-zA-Z0-9-_/:#.&*\(\)', ]{0,100}$/;

const CreateUserJob = ({ addJob }) => {

	const [loading, setLoading] = useState(false);

	const { auth } = useAuth();
	const userID = auth?.user?.id;
	let isVerified = auth?.user?.email_verified_at;

	const axiosPrivate = useAxiosPrivate();
	const refresh = useRefreshToken();

	const [services, setServices] = useState()
	const [searchServices, setSearchServices] = useState()
	const [searchServicesResults, setSearchServicesResults] = useState()
	const [selectedServices, setSelectedServices] = useState([])

	const nameRef = useRef();
	const descriptionRef = useRef()
	const addressRef = useRef()

	const [name, setName] = useState("");
	const [validName, setValidName] = useState(false);
	const [nameFocus, setNameFocus] = useState(false);

	const [description, setDescription] = useState("");
	const [validDescription, setValidDescription] = useState(false);

	const [address, setAddress] = useState(auth?.user?.address || "");
	const [validAddress, setValidAddress] = useState(false);
	const [addressFocus, setAddressFocus] = useState(false);

	const [files, setFiles] = useState([]);

	/* Set Focus to name on load */
	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getActivePackages = async () => {
			try {
				const response = await axiosPrivate.get('search-data', {
					signal: controller.signal
				});
				setServices(response?.data?.categories)
				setSearchServicesResults([])
				setLoading(false)
			} catch(err) {
				console.log(err.message);
			}
		}

		getActivePackages();

		return () => controller.abort();

		nameRef.current.focus()

		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (searchServices === ""){
			setSearchServicesResults()
		} else {
			const resultsArray = services?.filter(service => service.category_name.toLocaleLowerCase().includes(searchServices))
			setSearchServicesResults(resultsArray)
		}
	}, [searchServices])

	const toggleService = (e, i, service) => {
		if (!selectedServices.includes(service)){
				setSelectedServices([service])
				setSearchServicesResults()
				setSearchServices()
		} else {
			setSelectedServices(selectedServices.filter(svc => { return svc !== service}))
		}
	}

	const deleteService = (e, i, service) => {
		setSelectedServices([])
	}

	/* Check Name */
	useEffect(() => {
		const result = NAME_REGEX.test(name);
		setValidName(result);
	}, [name])

	/* Check Description */
	useEffect(() => {
		const result = DESCRIPTION_REGEX.test(description);
		setValidDescription(result);
	}, [description])

	/* Check Address */
	useEffect(() => {
		const result = ADDRESS_REGEX.test(address);
		setValidAddress(result);
	}, [address])

	const handleSubmit = async (e) => {
		e.preventDefault();

		const formData = new FormData();
		console.log(files)
		files.map((file, index) => {
			formData.append('files[]', file);
		});

		formData.append('name', name)
		formData.append('description', description)
		formData.append('address', address)
		formData.append('service_category', selectedServices[0])

		console.log(formData)

		const dismiss = () =>  toast.dismiss(createJobToast.current);
		const createJobToast = toast.loading("Adding Project");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.post('job',
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				signal: controller.signal
			});

			// Get job id from database
			const id = response?.data?.job?.id;

			// Get date from database
			const created_at = response?.data?.job.created_at;

			// Get images from database
			const images = response?.data?.job?.images;

			// Get service category
			const service_category = response?.data?.job?.service_category

			// Add Job to Jobs
			addJob({id, name, address, description, service_category, created_at, images});

			// Clear Form
			setName("")
			setDescription("")
			setSelectedServices([])
			setFiles([])

			// Update Toast Notification
			toast.update(createJobToast, { render: 'Project Added', type: 'success', isLoading: false, autoClose: 5000});
		} catch (err) {
			if (!err?.response) {
				toast.update(createJobToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else if (err.response?.status === 401) {
				toast.update(createJobToast, { render: 'Authorization Failed', type: 'error', isLoading: false});
				{/* setErrMsg('The email has already been taken.'); */}
			} else {
				toast.update(createJobToast, { render: 'Project Creation Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	return (
		<>
			<h3>Create Project</h3>
			<p>Please provide as much information as possible about your potential project including any photos. Once you have created a project, you will be able to share it with any Home Pros of your choice in order to request an estimate.</p>
			<form onSubmit={handleSubmit} encType="multipart/form-data" className="createProject">
				{/* Name */}
				<label htmlFor="name">Project Name:</label>
				<div className="inputWrapper">
					<span className={validName ? "valid" : "hide"}><FaCheck /></span>
					<span className={validName || !name ? "hide" : "invalid"}><FaExclamation /></span>
					<input
						type="text"
						id="name"
						className={validName || !name ? null : "error"}
						ref={nameRef}
						autoComplete="off"
						onChange={(e) => setName(e.target.value)}
						value={name}
						aria-invalid={validName ? "false" : "true"}
						aria-describedby="namenote"
						onFocus={() => setNameFocus(true)}
						onBlur={() => setNameFocus(false)}
					/>
				</div>
				<p id="namenote" className={nameFocus && name && !validName ? "instructions" : "offscreen"}>
					Invalid Project Name. 1-50 characters (letters, numbers, dash and underscore allowed)
				</p>

				{/* Service */}
				<label htmlFor="category">Find a Service Category:</label>
				<div className="servicesContainer">

					<div className="serviceContent">
						<div className="serviceSearchWrapper">
							<input onChange={(e) => setSearchServices(e.target.value)} id="type" type="text" autoComplete="off" placeholder="Start typing to search for service categories" />
						</div>
					</div>

					{ searchServices &&
						<div className="serviceContainer">
							<div className="serviceWrapper">
							{searchServicesResults?.length ?
								<>
									<h4>Select a Categories</h4>
									<ul className="marketingCategories">
									{searchServicesResults?.map((service, i) =>
										<li className={selectedServices.includes(service?.category_name) ? 'active' : null} key={i} onClick={(e) => toggleService(e, i, service?.category_name)}>
											<div className="category">
												{service?.category_name}
											</div>
										</li>
									)}
									</ul>
								</>
							:
								<p>No Categories available</p>
							}
							</div>
						</div>
					}

					<div className="selectedCategories">
						<label>Selected Category</label>
						<ul className="marketingCategories">
						{ selectedServices.length ?
							<>
								{selectedServices?.map((service, i) =>
								<li key={i} onClick={(e) => deleteService(e, i, service?.category_name)}>
									<div className="category">
										{service}
									</div>
								</li>
								)}
							</>
							:
							<p>No Service Selected</p>
						}
						</ul>
					</div>
				</div>

				{/* Address */}
				<label htmlFor="address">Address: <span>(Optional)</span>{ address.length > 0 && <button className="clearAddress" onClick={(e) => setAddress("")}>Clear</button>}</label>
				<div className="inputWrapper">
					<span className={validAddress ? "valid" : "hide"}><FaCheck /></span>
					<span className={validAddress || !address ? "hide" : "invalid"}><FaExclamation /></span>
					<input
						type="text"
						id="address"
						className={validAddress || !address ? null : "error"}
						ref={addressRef}
						autoComplete="off"
						onChange={(e) => setAddress(e.target.value)}
						value={address}

						aria-invalid={validAddress ? "false" : "true"}
						aria-describedby="addressnote"
						onFocus={() => setAddressFocus(true)}
						onBlur={() => setAddressFocus(false)}
					/>
				</div>
				<p id="addressnote" className={addressFocus && address && !validAddress ? "instructions" : "offscreen"}>
					Invalid Address. Valid Format includes letters, numbers and special characters (-_/:#.&*()',).
				</p>

				{/* Description */}
				<label htmlFor="description">Description:</label>
				<div className="inputWrapper">
					<span className={validDescription ? "valid" : "hide"}><FaCheck /></span>
					<span className={validDescription || !description ? "hide" : "invalid"}><FaExclamation /></span>
					<textarea
						ref={descriptionRef}
						onChange={(e) => setDescription(e.target.value)}
						name="description"
						id="description"
						rows="5"
						value={description}
					>
					</textarea>
					<p id="descriptionnote" className={nameFocus && description && !validDescription ? "instructions" : "offscreen"}>
						Invalid Description. Between 1-500 characters (letters, numbers, dash and underscore allowed)
					</p>
				</div>

				<FileDropEmbed showFiles="true" autoUpload="false" limit="10" multiple="true" files={files} setFiles={setFiles} />

				<button disabled={!validName || !validDescription || !selectedServices.length ? true : false}>Add Project</button>
			</form>
			<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
			/>
		</>
	)
}

export default CreateUserJob