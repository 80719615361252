import usePageTitle from '../../hooks/usePageTitle';
import LegalPage from '../../components/LegalPage';

const Advertising = () => {
	usePageTitle("Advertising Agreement");

	return (
		<LegalPage title='Advertising Agreement' slug='advertising-agreement' />
	)
}

export default Advertising