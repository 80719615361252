import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from "react";

const LegalHeader = () => {

	const location = useLocation();

	return (
		<>
			<header>
				<article className='inner'>
					<div className="logoWrapper"><a href={`https://${process.env.REACT_APP_URL}`}><img className="logo" src="/assets/images/iyh_logo-blue.png" alt="Improve Your House"/></a></div>
					<h1>Legal Center</h1>
				</article>
			</header>

		</>
	)
}

export default LegalHeader