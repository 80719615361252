import { useState, useEffect } from 'react'
import useFormContext from "../hooks/useFormContext"
import useAuth from '../hooks/useAuth'
import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const FormStepOptions = () => {
	const { data, updateOptions, handleChange } = useFormContext()
	const options = data.options
	const initOptions = data.selected_options

	const auth = useAuth()
	const subscription = auth?.auth?.subscription
	const savedOptions = subscription?.selected_options ? JSON.parse(subscription?.selected_options) : []

	const [includeOptions, setIncludeOptions] = useState(initOptions ? initOptions : savedOptions ? savedOptions : [])

	const handleCheckbox = (e, i, id, name, price) => {
		// console.log("checkbox id: " + id + " with name: " + name + " " + e.target.checked)
		const newOptions = [...includeOptions]
		newOptions[i] = {id: id, name: name, checked: e.target.checked, price: price}
		setIncludeOptions(newOptions)
	}

	const addCheckedCheckboxes = (option, i) => {
		const newOptions = [...includeOptions]
		newOptions[i] = {id: option?.id, name: option?.option_name, checked: true, price: option?.option_price}
		console.log(newOptions)
		// setIncludeOptions(newOptions)
	}

	useEffect(() => {
		const newOptions = [...includeOptions]

		options?.map((option, i) =>
			newOptions[i] = {id: option?.id, name: option?.option_name, checked: option?.checkbox_checked, price: option?.option_price}
		)
		setIncludeOptions(newOptions)

		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		updateOptions(includeOptions)
		console.log(includeOptions)
	}, [includeOptions])

	const content = (
		<>
			{options?.length ?
				<>
					<p>All add-ons can vary in price and these will be discussed with a account representative. If you select any of these add-ons a representative will contact you to discuss your account.</p>
					<ul className="subscriptionAddonList">
						{options?.map((option, i) =>
							<>
							{option?.checkbox_checked ? addCheckedCheckboxes(option, i) : null}
							<li key={option.id}>
								<FormGroup>
								  	<FormControlLabel control={
										<Checkbox
											checked={ option?.checkbox_checked ? true : includeOptions[i] ? includeOptions[i].checked : false}
											onChange={(e) => handleCheckbox(e, i, option.id, option.option_name, option?.option_price)}
											inputProps={{ 'aria-label': 'controlled' }}
											disabled={ option?.option_disabled ? true : false }
									  	/>
								  	} label={option.option_name} />
								</FormGroup>
								<p>{option.option_description}</p>
							</li>
							</>
						)}
					</ul>
				</>
			:
			<p>No options available for this subscription.</p>
			}
		</>
	)

	return content
}
export default FormStepOptions