import { useState, useEffect } from 'react';
import useFormContext from "../hooks/useFormContext"
import useAuth from '../hooks/useAuth'
import { ToastContainer, toast } from 'react-toastify'

const states = [
	{
		"State": "Alabama",
		"Code":	"AL"
	},
	{
		"State": "Alaska",
		"Code":	"AK"
	},
	{
		"State": "Arizona",
		"Code":	"AZ"
	},
	{
		"State": "Arkansas",
		"Code":	"AR"
	},
	{
		"State": "California",
		"Code":	"CA"
	},
	{
		"State": "Colorado",
		"Code":	"CO"
	},
	{
		"State": "Connecticut",
		"Code":	"CT"
	},
	{
		"State": "Delaware",
		"Code":	"DE"
	},
	{
		"State": "District of Columbia",
		"Code":	"DC"
	},
	{
		"State": "Florida",
		"Code":	"FL"
	},
	{
		"State": "Georgia",
		"Code":	"GA"
	},
	{
		"State": "Hawaii",
		"Code":	"HI"
	},
	{
		"State": "Idaho",
		"Code":	"ID"
	},
	{
		"State": "Illinois",
		"Code":	"IL"
	},
	{
		"State": "Indiana",
		"Code":	"IN"
	},
	{
		"State": "Illinois",
		"Code":	"IL"
	},
	{
		"State": "Iowa",
		"Code":	"IA"
	},
	{
		"State": "Kansas",
		"Code":	"KS"
	},
	{
		"State": "Kentucky",
		"Code":	"KY"
	},
	{
		"State": "Louisiana",
		"Code":	"LA"
	},
	{
		"State": "Maine",
		"Code":	"ME"
	},
	{
		"State": "Maryland",
		"Code":	"MD"
	},
	{
		"State": "Massachusetts",
		"Code":	"MA"
	},
	{
		"State": "Michigan",
		"Code":	"MI"
	},
	{
		"State": "Minnesota",
		"Code":	"MN"
	},
	{
		"State": "Mississippi",
		"Code":	"MS"
	},
	{
		"State": "Missouri",
		"Code":	"MO"
	},
	{
		"State": "Montana",
		"Code":	"MT"
	},
	{
		"State": "Nebraska",
		"Code":	"NE"
	},
	{
		"State": "Nevada",
		"Code":	"NV"
	},
	{
		"State": "New Hampshire",
		"Code":	"NH"
	},
	{
		"State": "New Jersey",
		"Code":	"NJ"
	},
	{
		"State": "New Mexico",
		"Code":	"NM"
	},
	{
		"State": "New York",
		"Code":	"NY"
	},
	{
		"State": "North Carolina",
		"Code":	"NC"
	},
	{
		"State": "North Dakota",
		"Code":	"ND"
	},
	{
		"State": "Ohio",
		"Code":	"OH"
	},
	{
		"State": "Oklahoma",
		"Code":	"OK"
	},
	{
		"State": "Oregon",
		"Code":	"OR"
	},
	{
		"State": "Pennsylvania",
		"Code":	"PA"
	},
	{
		"State": "Rhode Island",
		"Code":	"RI"
	},
	{
		"State": "South Carolina",
		"Code":	"SC"
	},
	{
		"State": "South Dakota",
		"Code":	"SD"
	},
	{
		"State": "Tennessee",
		"Code":	"TN"
	},
	{
		"State": "Texas",
		"Code":	"TX"
	},
	{
		"State": "Utah",
		"Code":	"UT"
	},
	{
		"State": "Vermont",
		"Code":	"VT"
	},
	{
		"State": "Virginia",
		"Code":	"VA"
	},
	{
		"State": "Washington",
		"Code":	"WA"
	},
	{
		"State": "West Virginia",
		"Code":	"WV"
	},
	{
		"State": "Wisconsin",
		"Code":	"WI"
	},
	{
		"State": "Wyoming",
		"Code":	"WY"
	}
]


const FormStepCounty = () => {

	const auth = useAuth()
	const subscription = auth?.auth?.subscription
	const allowedCounties = subscription?.package_details?.num_allowed_counties
	const savedCounties = subscription?.active_counties ? JSON.parse(subscription?.active_counties) : null

	const { data, updateCounties, handleChange } = useFormContext()
	const counties = data.counties
	const selectedStateCode = data.active_state
	const zipcodes = data.excludes
	const selectedZipcode = data.active_zip
	const initSelectedCounties = data.selected_counties

	const selectedCounty = zipcodes?.find(zips => {
		return zips.zipcode === selectedZipcode;
	});

	const selectedState = states?.find(state => {
		return state.Code === selectedStateCode;
	});

	let selectedStateName = selectedState?.State
	let selectedCountyName = selectedCounty?.county
	let availableCounties = counties[selectedStateName]

	const [selectedCounties, setSelectedCounties] = useState(initSelectedCounties ? initSelectedCounties : savedCounties ? savedCounties : [])

	useEffect(() => {
		{/* Failsafe for switching states to reset selected counties */}
		const activeCounties = document.querySelectorAll('li.active').length;

		if (activeCounties !== selectedCounties.length){
			setSelectedCounties([])
		}
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		updateCounties(selectedCounties)
		{/* console.log(selectedCounties) */}
	}, [selectedCounties])

	const toggleCounty = (e, i, county) => {
		// Add or remove county from state
		if (!selectedCounties.includes(county)){
			if (selectedCounties.length >= allowedCounties){
				const createJobToast = toast.error("Max Counties Added", { type: 'error', isLoading: false, autoClose: 5000});
			} else {
				setSelectedCounties([...selectedCounties, county])
			}
		} else {
			setSelectedCounties(selectedCounties.filter(cty => { return cty !== county}))
		}
	}

	return (
		<>
			{ availableCounties && availableCounties?.length ?
				<>
					<p>Available Counties for {selectedState.State}</p>
					<p>Your subscription package includes up to {allowedCounties} counties.</p>
					<p>Selected {selectedCounties.length} of {allowedCounties}</p>
					<ul className="marketingCounties">
					{availableCounties?.map((county, i) =>
						<li className={selectedCounties.includes(county?.county) ? 'active' : null}
							key={i} onClick={(e) => toggleCounty(e, i, county?.county)}>
							<div className="county">
								{county?.county}
							</div>
						</li>
					)}
					</ul>
				</>
			:
				<p>No Counties Available</p>
			}
			<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
			/>
		</>
	)
}

export default FormStepCounty