import { useEffect, useState } from 'react';
import useAuth from "../hooks/useAuth";
import TextEditor from "./TextEditor";
import { Markup } from 'interweave';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Loader from './Loader';
import SingleFileDrop from './SingleFileDrop';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import PageFileDrop from "./PageFileDrop"
import SearchBoxNew from "./SearchBoxNew"

const Agreement = ({ slug }) => {

	const axiosPrivate = useAxiosPrivate();
	const [agreementContent, setAgreementContent] = useState()
	const [loading, setLoading] = useState(false)

	const getPage = async () => {
		const controller = new AbortController();
		setLoading(true);
		try {
			const response = await axiosPrivate.get('page-by-slug/' + slug, {
				signal: controller.signal
			});
			setAgreementContent(response.data.page.content);
			setLoading(false)
		} catch(err) {
			console.log(err.message);
			{/* !auth && navigate('/login', { state: { from: location }, replace: true }); */}
		}
		return () => controller.abort();
	}

	useEffect(() => {
		getPage()
		// eslint-disable-next-line
	}, [])

	return (
		<>
			<Markup content={agreementContent} />
		</>
	)
}

export default Agreement