import { Box, Button, Container, Grid, Link } from "@mui/material";
import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { BsArrowRightShort } from "react-icons/bs";
import ReactPlayer from "react-player";

const Hero = ({
  header,
  subheader,
  link,
  linkText,
  image,
  mobileImage,
  videoUrl,
}) => {
  const [width, setWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState();
  const [reload, setReload] = useState();
  const imageRef = useRef();
  useEffect(() => {
    const handleResize = async () => {
      // const current = imageRef.current;
      // const rect = await current.getBoundingClientRect();
      // console.log("handle resize is happening", window.innerWidth)
      setWidth(window.innerWidth);
      // setImageHeight(rect.height ? rect.height : window.innerWidth * 0.5625);
    };
    handleResize();
    setTimeout(() => {
      return handleResize();
    }, 500);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [reload]);

  // useEffect(() => {console.log("width", width)}, [width])
  // useEffect(() => {console.log("window.innerWidth", window.innerWidth)}, [window.innerWidth])

  return (
    <>
      <Box className="hero-comp">
        <Grid
          container
          spacing={2}
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          className="hero-grid"
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={10} md={5} lg={5}>
            <>
              <h2>{header}</h2>
              <h4>{subheader}</h4>
              {width > 899 ? (
                <Button variant="contained">
                  <Link href={link}>
                    {linkText} <BsArrowRightShort />
                  </Link>
                </Button>
              ) : (
                <></>
              )}
            </>
          </Grid>
          {width > 899 ? (
            <></>
          ) : (
            <>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}></Grid>
            </>
          )}
          {videoUrl ? (
            <Grid item xs={10} md={6} className="hero-video-grid">
              <ReactPlayer
                controls="true"
                url={videoUrl}
                width="100%"
                height="auto"
              />
              {width > 899 ? (
                <></>
              ) : (
                <Button variant="contained">
                  <Link href={link}>
                    {linkText} <BsArrowRightShort />
                  </Link>
                </Button>
              )}
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        {/* <img
          ref={imageRef}
          src={width >= 900 ? image : mobileImage}
          alt="a tablet in a kitchen displaying the Improve Your House website"
          style={{ width: "100%", position: "absolute", top: 0, zIndex: -1, opacity: 0 }}
          onLoad={() => setReload(true)}
        /> */}
      </Box>
    </>
  );
};

export default Hero;
