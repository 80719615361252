import usePageTitle from '../hooks/usePageTitle';
import Page from '../components/Page';

const Recruiting = () => {
	usePageTitle("Recruiting");
	return (
		<section>
			<Page title='Recruiting' slug='recruiting' />
		</section>
	)
}

export default Recruiting