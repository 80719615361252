import { useEffect, useState } from "react";
import usePageTitle from '../../hooks/usePageTitle';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Loader from '../../components/Loader';
import DashboardItem from '../../components/DashboardItem';
import UserPageNav from '../../components/UserPageNav';
import FavoriteMetrics from '../../components/FavoriteMetrics';
import { AiOutlineDashboard } from 'react-icons/ai'
import { CgProfile } from 'react-icons/cg'
import { MdAccountBox } from 'react-icons/md'
import { FaUserCircle } from 'react-icons/fa'
import { GiStarsStack } from 'react-icons/gi'
import { AiOutlineBarChart } from 'react-icons/ai'
import { HiChartSquareBar } from 'react-icons/hi'
import { ImBullhorn } from 'react-icons/im';
import { MdDashboard } from 'react-icons/md';
import { RiCustomerService2Line } from 'react-icons/ri';
import { MdHelpCenter } from 'react-icons/md';
import { AiFillMessage } from 'react-icons/ai';
import { MdMessage } from 'react-icons/md';
import { MdFavorite } from 'react-icons/md';
import { MdFactCheck } from 'react-icons/md';
import { MdRequestPage } from 'react-icons/md';
import { MdSettings } from 'react-icons/md';
import { MdWork } from 'react-icons/md';
import { AiFillPhone } from 'react-icons/ai'
import { MdEmail } from 'react-icons/md';
import { MdHandyman } from 'react-icons/md';
import { MdLink } from 'react-icons/md';
import { BsTelephoneInboundFill } from 'react-icons/bs';
import useAuth from '../../hooks/useAuth';

const Metrics = () => {

	usePageTitle("Metrics");

	const axiosPrivate = useAxiosPrivate();
	const [loading, setLoading] = useState(false);

	const auth = useAuth();
	const subscription = auth?.auth?.subscription

	const [promotions, setPromotions] = useState('');
	const [messages, setMessages] = useState('');
	const [favorites, setFavorites] = useState('');
	const [reviews, setReviews] = useState('');
	const [account, setAccount] = useState('');
	const [contacts, setContacts] = useState('');
	const [projects, setProjects] = useState('');

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getDashboardOverview = async () => {
			try {
				const response = await axiosPrivate.get('dashboard-overview', {
					signal: controller.signal
				});
				setPromotions(response.data.promotions);
				setFavorites(response.data.favorites);
				setMessages(response.data.messages);
				setReviews(response.data.reviews);
				setAccount(response.data.account);
				setContacts(response.data.contacts);
				setProjects(response.data.projects);
				setLoading(false)
			} catch(err) {
				console.log(err.message);
				{/* !auth && navigate('/login', { state: { from: location }, replace: true }); */}
			}
		}

		getDashboardOverview();

		return () => controller.abort();
		// eslint-disable-next-line
	}, [])

	return (
		<section>
			<article className='inner'>
				<div className="backendTitleBar">
					<h1>Metrics</h1>
					<HiChartSquareBar />
				</div>
				<div className="backendColumnWrapper">
					<div id="menu" className="column">
						<UserPageNav />
					</div>
					<div id="content" className="column">
						<div className="dashboardItemWrapper stats">
							<h3>Stats</h3>
							<DashboardItem title="Unique Views" icon={<MdAccountBox />} count={contacts.views ? contacts.unique_views : "N/A" } />
							<DashboardItem title="Total Views" icon={<MdAccountBox />} count={contacts.views ? contacts.views : "N/A" } />
							<DashboardItem title="Phone Calls" icon={<AiFillPhone />} count={contacts.calls ? contacts.calls : "N/A" } />
							<DashboardItem title="Emails Received" icon={<MdEmail />} count={contacts.emails ? contacts.emails : "N/A" } />
							<DashboardItem title="Website Clicks" icon={<MdLink />} count={contacts.website} />
							<DashboardItem title="Favorited" icon={<MdFavorite />} count={favorites.count} />
							<DashboardItem title="Reviewed" icon={<GiStarsStack />} count={reviews.count} />
							<DashboardItem title="Projects Shared" icon={<MdHandyman />} count={projects.shared} />
						</div>
					</div>
				</div>
			</article>
		</section>
	)
}

export default Metrics