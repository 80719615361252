import * as React from 'react';
import { useEffect, useState } from "react";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import AdminMenu from '../../components/AdminMenu';
import DashboardItem from '../../components/DashboardItem';
import Loader from '../../components/Loader';
import Packages from '../../components/Packages'
import { FaBoxOpen } from 'react-icons/fa';

const AdminPackages = () => {

	const axiosPrivate = useAxiosPrivate();
	const [loading, setLoading] = useState(false);

	return (
		<section>
			<article className="inner">
				<div className="backendTitleBar">
					<h1>Subscription Packages</h1>
					<FaBoxOpen />
				</div>
				<div className="backendColumnWrapper">
					<div id="menu" className="column">
						<AdminMenu />
					</div>
					<div id="content" className="column">
						<Packages />
					</div>
				</div>
			</article>
		</section>
	)
}

export default AdminPackages