import { useState, useEffect, useRef } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import { AiFillDelete } from 'react-icons/ai';
import { AiOutlineShareAlt } from 'react-icons/ai';
import { MdVisibility } from 'react-icons/md';
import { MdOutlineEdit } from 'react-icons/md';
import { FaCheck } from 'react-icons/fa';
import { FaExclamation } from 'react-icons/fa';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import Loader from './Loader';
import CreatePackage from './CreatePackage'
import Slider from '@mui/material/Slider';
import CreateOption from './CreateOption'
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const NAME_REGEX = /^[a-zA-Z0-9-_&@#$! ]{1,50}$/;
const PRICE_REGEX = /^[0-9.]{1,10}$/;
const DESCRIPTION_REGEX = /^[a-zA-Z0-9-_.,&$#@! \r\n]{1,500}$/;

const Packages = () => {

	const [loading, setLoading] = useState(false);
	const [packages, setPackages] = useState();
	const [options, setOptions] = useState();
	const axiosPrivate = useAxiosPrivate();

	const navigate = useNavigate();
	const location = useLocation();

	const auth = useAuth();
	const role = auth?.role;
	const id = auth?.id;

	const packageNameRef = useRef()
	const packagePriceRef = useRef()
	const packageDescriptionRef = useRef()
	const optionNameRef = useRef()
	const optionDescriptionRef = useRef()
	const editOptionPriceRef = useRef()
	const packageAdditionalServiceChargeRef = useRef()

	const [open, setOpen] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [openEditOptions, setOpenEditOptions] = useState(false);
	const [openView, setOpenView] = useState(false);
	const [openViewOption, setOpenViewOption] = useState(false);
	const [openDelete, setOpenDelete] = useState(false);
	const [openAdd, setOpenAdd] = useState(false);
	const [openOption, setOpenOption] = useState(false);

	const [deleteID, setDeleteID] = useState()
	const [deleteName, setDeleteName] = useState()

	const [openOptionDelete, setOpenOptionDelete] = useState(false)
	const [deleteOptionID, setDeleteOptionID] = useState()
	const [deleteOptionName, setDeleteOptionName] = useState()

	const [viewName, setViewName] = useState()
	const [viewDescription, setViewDescription] = useState()
	const [viewPrice, setViewPrice] = useState()
	const [viewAdditionalServiceCharge, setViewAdditionalServiceCharge] = useState()
	const [viewStates, setViewStates] = useState()
	const [viewCounties, setViewCounties] = useState()
	const [viewServices, setViewServices] = useState()
	const [viewMaxServices, setViewMaxServices] = useState()
	const [viewBilling, setViewBilling] = useState()

	const [viewOptionName, setViewOptionName] = useState()
	const [viewOptionType, setViewOptionType] = useState()
	const [viewOptionDescription, setViewOptionDescription] = useState()

	const [editOptionID, setEditOptionID] = useState()
	const [editOptionName, setEditOptionName] = useState()
	const [editOptionDescription, setEditOptionDescription] = useState()
	const [editOptionType, setEditOptionType] = useState()

	const [editID, setEditID] = useState()
	const [editName, setEditName] = useState()
	const [validName, setValidName] = useState(false);
	const [validOptionName, setValidOptionName] = useState(false);
	const [nameFocus, setNameFocus] = useState(false);
	const [optionNameFocus, setOptionNameFocus] = useState(false);

	const [editDescription, setEditDescription] = useState()
	const [validDescription, setValidDescription] = useState(false);
	const [validOptionDescription, setValidOptionDescription] = useState(false);
	const [descriptionFocus, setDescriptionFocus] = useState(false);
	const [optionDescriptionFocus, setOptionDescriptionFocus] = useState(false);
	const [optionEnabled, setOptionEnabled] = useState()

	const [editPrice, setEditPrice] = useState();
	const [validPrice, setValidPrice] = useState(false);
	const [priceFocus, setPriceFocus] = useState(false);
	const [validEditOptionPrice, setValidEditOptionPrice] = useState(false);
	const [editOptionPriceFocus, setEditOptionPriceFocus] = useState(false);

	const [editAdditionalServiceCharge, setEditAdditionalServiceCharge] = useState();
	const [validAdditionalServiceCharge, setValidAdditionalServiceCharge] = useState(false);
	const [additionalServiceChargeFocus, setAdditionalServiceChargeFocus] = useState(false);

	const [editOptionPrice, setEditOptionPrice] = useState();
	const [validOptionPrice, setValidOptionPrice] = useState(false);
	const [optionPriceFocus, setOptionPriceFocus] = useState(false);
	const [viewOptionPrice, setViewOptionPrice] = useState()

	const [editStates, setEditStates] = useState();
	const [validNumAllowedStates, setValidNumAllowedStates] = useState(false);
	const [numAllowedStatesFocus, setNumAllowedStatesFocus] = useState(false);

	const [editCounties, setEditCounties] = useState();
	const [validNumAllowedCounties, setValidNumAllowedCounties] = useState(false);
	const [numAllowedCountiesFocus, setNumAllowedCountiesFocus] = useState(false);

	const [editServices, setEditServices] = useState();
	const [validNumAllowedServices, setValidNumAllowedServices] = useState(false);
	const [numAllowedServicesFocus, setNumAllowedServicesFocus] = useState(false);

	const [editMaxServices, setEditMaxServices] = useState();
	const [validNumMaxAllowedServices, setValidNumMaxAllowedServices] = useState(false);
	const [numMaxAllowedServicesFocus, setNumMaxAllowedServicesFocus] = useState(false);

	const [editBilling, setEditBilling] = useState();
	const [validBillingFrequency, setValidBillingFrequency] = useState(false);
	const [billingFrequencyFocus, setBillingFrequencyFocus] = useState(false);

	const [editStatus, setEditStatus] = useState()
	const [editIncludeOptions, setEditIncludeOptions] = useState()

	const [editOptionCheckboxChecked, setEditOptionCheckboxChecked] = useState()
	const [viewOptionCheckboxChecked, setViewOptionCheckboxChecked] = useState()
	const [editOptionDisabled, setEditOptionDisabled] = useState()
	const [viewOptionDisabled, setViewOptionDisabled] = useState()
	const [editOnetimePayment, setEditOnetimePayment] = useState()
	const [viewOnetimePayment, setViewOnetimePayment] = useState()

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getPackages = async () => {
			try {
				const response = await axiosPrivate.get('packages', {
					signal: controller.signal
				});
				setPackages(response.data?.packages);
				setOptions(response.data?.options);
				setLoading(false)
			} catch(err) {
				console.log(err.message);
				!auth && navigate('/login', { state: { from: location }, replace: true });
			}
		}

		getPackages();

		return () => controller.abort();
		// eslint-disable-next-line
	},[])

	/* Check Name */
	useEffect(() => {
		const result = NAME_REGEX.test(editName);
		setValidName(result);
	}, [editName])

	/* Check Option Name */
	useEffect(() => {
		const result = NAME_REGEX.test(editOptionName);
		setValidOptionName(result);
	}, [editOptionName])

	/* Check Price */
	useEffect(() => {
		const result = PRICE_REGEX.test(editPrice);
		setValidPrice(result);
	}, [editPrice])

	/* Check Price */
	useEffect(() => {
		const result = PRICE_REGEX.test(editOptionPrice);
		setValidEditOptionPrice(result);
	}, [editOptionPrice])

	/* Check Additional Service Charge */
	useEffect(() => {
		const result = PRICE_REGEX.test(editAdditionalServiceCharge);
		setValidAdditionalServiceCharge(result);
	}, [editAdditionalServiceCharge])

	/* Check Description */
	useEffect(() => {
		const result = DESCRIPTION_REGEX.test(editDescription);
		setValidDescription(result);
	}, [editDescription])

	/* Check Option Description */
	useEffect(() => {
		const result = DESCRIPTION_REGEX.test(editOptionDescription);
		setValidOptionDescription(result);
	}, [editOptionDescription])

	const handleDelete = async () => {
		const dismiss = () =>  toast.dismiss(deletePackageToast.current);
		const deletePackageToast = toast.loading("Deleting Package");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.delete('package/' + deleteID,
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(deletePackageToast, { render: 'Package Deleted', type: 'success', isLoading: false, autoClose: 5000});

			// Close Alert Window
			setOpenDelete(false);

			// Delete job from UI
			setPackages(packages.filter((p) => p.id !== deleteID))

		} catch (err) {
			if (!err?.response) {
				toast.update(deletePackageToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(deletePackageToast, { render: 'Package Delete Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	};

	const handleDeleteOption = async () => {
		const dismiss = () =>  toast.dismiss(deleteOptionToast.current);
		const deleteOptionToast = toast.loading("Deleting Option");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.delete('option/' + deleteOptionID,
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(deleteOptionToast, { render: 'Option Deleted', type: 'success', isLoading: false, autoClose: 5000});

			// Close Alert Window
			setOpenOptionDelete(false);

			// Delete job from UI
			setOptions(options.filter((p) => p.id !== deleteOptionID))

		} catch (err) {
			if (!err?.response) {
				toast.update(deleteOptionToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(deleteOptionToast, { render: 'Option Delete Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	};

	const handleClose = () => {
		setOpen(false);
		setOpenDelete(false)
		setOpenEdit(false)
		setOpenView(false)
	};

	const handleCloseEdit = () => {
		setOpenEdit(false);
	};

	const handleCloseDelete = () => {
		setOpenDelete(false);
	};

	const handleCloseEditOptions = () => {
		setOpenEditOptions(false);
	};

	const handleCloseAdd = () => {
		setOpenAdd(false);
	};

	const handleCloseOption = () => {
		setOpenOption(false);
	};

	const handleView = () => {
		setOpenView(true);
	};

	const handleCloseView = () => {
		setOpenView(false);
	};

	const handleCloseViewOption = () => {
		setOpenViewOption(false);
	};

	const handleCloseOptionDelete = () => {
		setOpenOptionDelete(false);
	};

	const deletePackage = (e, i, id, name) => {
		setDeleteName(name);
		setDeleteID(id);
		setOpenDelete(true)
	};

	const deleteOption = (e, i, option) => {
		setDeleteOptionName(option?.option_name);
		setDeleteOptionID(option?.id);
		setOpenOptionDelete(true)
	};

	const viewOption = (e, i, option) => {
		setViewOptionName(option?.option_name)
		setViewOptionType(option?.option_type)
		setViewOptionDescription(option?.option_description)
		setViewOptionDisabled(option?.option_disabled)
		setViewOptionCheckboxChecked(option?.checkbox_checked)
		setViewOnetimePayment(option?.onetime_payment)
		setViewOptionPrice(option?.option_price)
		setOpenViewOption(true)
	}

	const viewPackage = (e, i, id, name, description, price, states, counties, services, max_allowed_services, billing, additional_service_charge) => {
		setViewName(name)
		setViewDescription(description)
		setViewPrice(price)
		setViewStates(states)
		setViewCounties(counties)
		setViewServices(services)
		setViewMaxServices(max_allowed_services)
		setViewBilling(billing)
		setOpenView(true)
		setViewAdditionalServiceCharge(additional_service_charge)
	};

	const editPackage = (e, i, id, name, description, price, states, counties, services, max_allowed_services, billing, status, includeOptions, additional_service_charge) => {
		setEditID(id)
		setEditName(name)
		setEditDescription(description)
		setEditPrice(price)
		setEditStates(states)
		setEditCounties(counties)
		setEditServices(services)
		setEditMaxServices(max_allowed_services)
		setEditBilling(billing)
		setOpenEdit(true)
		setEditIncludeOptions(includeOptions)
		setEditStatus(status)
		setEditAdditionalServiceCharge(additional_service_charge)
	};

	const editOption = (e, i, option) => {
		setEditOptionName(option?.option_name)
		setEditOptionDescription(option?.option_description)
		setEditOptionType(options?.option_type)
		setEditOptionID(option?.id)
		setOptionEnabled(option?.option_enabled)
		setEditOptionDisabled(option?.option_disabled)
		setEditOptionCheckboxChecked(option?.checkbox_checked)
		setEditOnetimePayment(option?.onetime_payment)
		setOptionEnabled(option?.option_enabled)
		setOpenEditOptions(true)
	}

	const addPackage = (pkg) => {
		setPackages([...packages, pkg]);
		setOpenAdd(false)
	}

	const addOption = (opt) => {
		setOptions([...options, opt]);
		setOpenOption(false)
	}

	const formatCurrency = (q) => {
		var num = +q
		return '$' + num.toFixed(2)
	}

	const handleEdit = async (e) => {
		e.preventDefault();

		const dismiss = () =>  toast.dismiss(addPackageToast.current);
		const addPackageToast = toast.loading("Updating Package");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.put('package/' + editID ,
			JSON.stringify({package_name: editName, package_description: editDescription, package_price: editPrice, num_allowed_states: editStates, num_allowed_counties: editCounties, num_allowed_services: editServices, num_max_allowed_services: editMaxServices, billing_frequency: editBilling, status: editStatus, add_options: editIncludeOptions, additional_service_charge: editAdditionalServiceCharge}),
			{
				signal: controller.signal
			});

			// Get package id from database
			const id = response?.data?.package?.id

			// Get date from database
			const created_at = response?.data?.market?.created_at

			// Update Package State
			const index = packages.map(function(x) {return x.id; }).indexOf(editID);
			packages[index].package_name = editName;
			packages[index].package_description = editDescription;
			packages[index].package_price = editPrice
			packages[index].num_allowed_states = editStates
			packages[index].num_allowed_counties = editCounties
			packages[index].num_allowed_services = editServices
			packages[index].num_max_allowed_services = editMaxServices
			packages[index].billing_frequency = editBilling
			packages[index].status = editStatus
			packages[index].add_options = editIncludeOptions
			packages[index].additional_service_charge = editAdditionalServiceCharge

			// Update Toast Notification
			toast.update(addPackageToast, { render: 'Package Updated', type: 'success', isLoading: false, autoClose: 5000});

			// Close edit popup
			setOpenEdit(false)

		} catch (err) {
			if (!err?.response) {
				toast.update(addPackageToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else if (err.response?.status === 401) {
				toast.update(addPackageToast, { render: 'Authorization Failed', type: 'error', isLoading: false});
				{/* setErrMsg('The email has already been taken.'); */}
			} else {
				toast.update(addPackageToast, { render: 'Package Update Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	const handleEditOption = async (e) => {
		e.preventDefault();

		const dismiss = () =>  toast.dismiss(updateOptionToast.current);
		const updateOptionToast = toast.loading("Updating Option");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.put('option/' + editOptionID ,
			JSON.stringify({option_name: editOptionName, option_description: editOptionDescription, option_enabled: optionEnabled, checkbox_checked: editOptionCheckboxChecked, option_disabled: editOptionDisabled, onetime_payment: editOnetimePayment, option_price: editOptionPrice}),
			{
				signal: controller.signal
			});

			// Update Package State
			const index = options.map(function(x) {return x.id; }).indexOf(editOptionID);
			options[index].option_name = editOptionName;
			options[index].option_description = editOptionDescription;
			options[index].option_enabled = optionEnabled
			options[index].checkbox_checked = editOptionCheckboxChecked
			options[index].option_disabled = editOptionDisabled
			options[index].onetime_payment = editOnetimePayment

			// Update Toast Notification
			toast.update(updateOptionToast, { render: 'Option Updated', type: 'success', isLoading: false, autoClose: 5000});

			// Close edit popup
			setOpenEditOptions(false)

		} catch (err) {
			if (!err?.response) {
				toast.update(updateOptionToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else if (err.response?.status === 401) {
				toast.update(updateOptionToast, { render: 'Authorization Failed', type: 'error', isLoading: false});
				{/* setErrMsg('The email has already been taken.'); */}
			} else {
				toast.update(updateOptionToast, { render: 'Option Update Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	const handleStateChange = (newValue) => {
		setEditStates(newValue)
	}

	const handleCountyChange = (newValue) => {
		setEditCounties(newValue)
	}

	const handleServiceChange = (newValue) => {
		setEditServices(newValue)
	}

	const handleMaxServiceChange = (newValue) => {
		setEditMaxServices(newValue)
	}

	const newPackage = (e) => {
		setOpenAdd(true)
	}

	const newOption = (e) => {
		setOpenOption(true)
	}

	const handleCheckbox = (e) => {
		setEditIncludeOptions(e.target.checked)
	}

	const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
	const onSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        const sortedData = [...options].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setOptions(sortedData);
        setSortConfig({ key, direction });
    };

	const onPackageSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        const sortedData = [...packages].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setPackages(sortedData);
        setSortConfig({ key, direction });
    };

	return (
		<div className="backendList">
			<div className="buttonContainer">
				<button onClick={(e) => newPackage(e)}>Add Package</button>
				<button onClick={(e) => newOption(e)}>Add Option</button>
			</div>

			<h3>Packages</h3>
			{packages?.length ?
			(
					<ul className="packageList">
						<li className="key">
							<div className="name" onClick={() => onPackageSort('package_name')}>Name</div>
							<div className="price" onClick={() => onPackageSort('package_price')}>Price</div>
							<div className="statusContainer" onClick={() => onPackageSort('status')}>Status</div>
							<div className="buttons"></div>
						</li>
						{packages?.map((p, i) =>
							<li key={i} >
								<div className="name">
									{p?.package_name}
								</div>
								<div className="price">
									{formatCurrency(p?.package_price)}
								</div>
								<div className="statusContainer">
									<div className={`status ${p.status}`}>
										{p.status}
									</div>
								</div>
								<div className="buttons">
									<div className="edit" onClick={(e) => editPackage(e, i, p?.id, p?.package_name, p?.package_description, p?.package_price, p?.num_allowed_states, p?.num_allowed_counties, p?.num_allowed_services, p?.num_max_allowed_services, p?.billing_frequency, p?.status, p?.add_options, p?.additional_service_charge)}>
										<span><MdOutlineEdit /></span>
									</div>
									<div className="view" onClick={(e) => viewPackage(e, i, p?.id, p?.package_name, p?.package_description, p?.package_price, p?.num_allowed_states, p?.num_allowed_counties, p?.num_allowed_services, p?.num_max_allowed_services, p?.billing_frequency, p?.additional_service_charge)}>
										<span><MdVisibility /></span>
									</div>
									<div className="delete" onClick={(e) => deletePackage(e, i, p?.id, p?.package_name)}>
										<span><AiFillDelete /></span>
									</div>
								</div>
							</li>
						)}
					</ul>
			) : loading ?
				<Loader />
				:
				<p>No packages to display</p>
			}

			<h3>Options</h3>
			{options?.length ?
			(
				<ul className="packageList">
					<li className="key">
						<div className="name" onClick={() => onSort('option_name')}>Name</div>
						<div className="type" onClick={() => onSort('option_type')}>Type</div>
						<div className="statusContainer" onClick={() => onSort('option_enabled')}>Status</div>
						<div className="buttons"></div>
					</li>
					{options?.map((option, i) =>
						<li key={i} >
							<div className="name">
								{option?.option_name}
							</div>
							<div className="type">
								{option?.option_type}
							</div>
							<div className="statusContainer">
								<div className={`status ${option.option_enabled ? 'active' : 'disabled'}`}>
									{option.option_enabled ? 'active' : 'disabled'}
								</div>
							</div>
							<div className="buttons">
								<div className="edit" onClick={(e) => editOption(e, i, option)}>
									<span><MdOutlineEdit /></span>
								</div>
								<div className="view" onClick={(e) => viewOption(e, i, option)}>
									<span><MdVisibility /></span>
								</div>
								<div className="delete" onClick={(e) => deleteOption(e, i, option)}>
									<span><AiFillDelete /></span>
								</div>
							</div>
						</li>
					)}
				</ul>
			) : loading ?
				<Loader />
				:
				<p>No options to display</p>
			}

			{/* Delete Dialog */}
			<Dialog
				open={openDelete}
				onClose={handleCloseDelete}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			  >
				<DialogTitle id="alert-dialog-title">
				  {"Are you sure you want to delete this package?"}
				</DialogTitle>
				<DialogContent>
				  <DialogContentText id="alert-dialog-description">
					This action cannot be undone. This will permanently delete the package with name, "{deleteName}".
				  </DialogContentText>
				</DialogContent>
				<DialogActions>
				  <button className="cancel" onClick={handleCloseDelete}>Cancel</button>
				  <button className="confirm" onClick={handleDelete}>Yes, delete package</button>
				</DialogActions>
			</Dialog>

			{/* Delete Option Dialog */}
			<Dialog
				open={openOptionDelete}
				onClose={handleCloseOptionDelete}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			  >
				<DialogTitle id="alert-dialog-title">
				  {"Are you sure you want to delete this option?"}
				</DialogTitle>
				<DialogContent>
				  <DialogContentText id="alert-dialog-description">
					This action cannot be undone. This will permanently delete the option with name, "{deleteOptionName}".
				  </DialogContentText>
				</DialogContent>
				<DialogActions>
				  <button className="cancel" onClick={handleCloseOptionDelete}>Cancel</button>
				  <button className="confirm" onClick={handleDeleteOption}>Yes, delete option</button>
				</DialogActions>
			</Dialog>

			{/* Edit Dialog */}
			<Dialog
				open={openEdit}
				onClose={handleCloseEdit}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			  >
				<DialogTitle id="alert-dialog-title">
				  {`Editing package ${editName}`}
				</DialogTitle>
				<DialogContent>
					<form>

						{/* Name */}
						<label htmlFor="name">Package Name:</label>
						<div className="inputWrapper">
							<span className={validName ? "valid" : "hide"}><FaCheck /></span>
							<span className={validName || !editName ? "hide" : "invalid"}><FaExclamation /></span>
							<input
								type="text"
								id="name"
								className={validName || !editName ? null : "error"}
								ref={packageNameRef}
								autoComplete="off"
								onChange={(e) => setEditName(e.target.value)}
								value={editName}
								aria-invalid={validName ? "false" : "true"}
								aria-describedby="namenote"
								onFocus={() => setNameFocus(true)}
								onBlur={() => setNameFocus(false)}
							/>
						</div>
						<p id="namenote" className={nameFocus && editName && !validName ? "instructions" : "offscreen"}>
							Invalid Package Name. Package names must be between 1-50 characters long. Valid characters (-,_,&,@,#,$,!)
						</p>

						{/* Description */}
						<label htmlFor="description">Description:</label>
						<div className="inputWrapper">
							<span className={validDescription ? "valid" : "hide"}><FaCheck /></span>
							<span className={validDescription || !editDescription ? "hide" : "invalid"}><FaExclamation /></span>
							<textarea
								ref={packageDescriptionRef}
								onChange={(e) => setEditDescription(e.target.value)}
								name="description"
								id="description"
								rows="5"
								value={editDescription}
							>
							</textarea>
							<p id="descriptionnote" className={descriptionFocus && editDescription && !validDescription ? "instructions" : "offscreen"}>
								Invalid Description. Between 1-500 characters (letters, numbers, dash and underscore allowed)
							</p>
						</div>

						{/* Name */}
						<label htmlFor="price">Package Price:</label>
						<div className="inputWrapper">
							<span className={validPrice ? "valid" : "hide"}><FaCheck /></span>
							<span className={validPrice || !editPrice ? "hide" : "invalid"}><FaExclamation /></span>
							<input
								type="text"
								id="price"
								className={validPrice || !editPrice ? null : "error"}
								ref={packagePriceRef}
								autoComplete="off"
								onChange={(e) => setEditPrice(e.target.value)}
								value={editPrice}
								aria-invalid={validPrice ? "false" : "true"}
								aria-describedby="pricenote"
								onFocus={() => setPriceFocus(true)}
								onBlur={() => setPriceFocus(false)}
							/>
						</div>
						<p id="pricenote" className={priceFocus && editPrice && !validPrice ? "instructions" : "offscreen"}>
							Invalid Package Price. Package price must only contain numbers and a period.
						</p>

						{/* Numbers of States */}
						<label htmlFor="states">Number of States:</label>
						<div className="inputWrapper">
							<Slider
								valueLabelDisplay="auto"
								step={1}
								marks
								min={1}
								max={1}
								defaultValue={editStates}
								onChange={(e) => handleStateChange(e.target.value)}
						  	/>
						</div>

						{/* Numbers of Counties */}
						<label htmlFor="counties">Number of Counties:</label>
						<div className="inputWrapper">
							<Slider
								valueLabelDisplay="auto"
								step={1}
								marks
								min={1}
								max={30}
								defaultValue={editCounties}
								onChange={(e) => handleCountyChange(e.target.value)}
						  	/>
						</div>

						{/* Numbers of Services */}
						<label htmlFor="services">Number of Free Services:</label>
						<div className="inputWrapper">
							<Slider
								valueLabelDisplay="auto"
								step={1}
								marks
								min={1}
								max={30}
								defaultValue={editServices}
								onChange={(e) => handleServiceChange(e.target.value)}
						  	/>
						</div>

						{/* Max Numbers of Services */}
						<label htmlFor="services">Max Number of Services:</label>
						<p className="packageNote">Set to -1 for unlimited</p>
						<div className="inputWrapper">
							<Slider
								valueLabelDisplay="auto"
								step={1}
								marks
								min={-1}
								max={30}
								defaultValue={editMaxServices}
								onChange={(e) => handleMaxServiceChange(e.target.value)}
						  	/>
						</div>

						<label htmlFor="price">Additional Service Charge:</label>
						<div className="inputWrapper">
							<span className={validAdditionalServiceCharge ? "valid" : "hide"}><FaCheck /></span>
							<span className={validAdditionalServiceCharge || !editAdditionalServiceCharge ? "hide" : "invalid"}><FaExclamation /></span>
							<input
								type="text"
								id="price"
								className={validAdditionalServiceCharge || !editAdditionalServiceCharge ? null : "error"}
								ref={packageAdditionalServiceChargeRef}
								autoComplete="off"
								onChange={(e) => setEditAdditionalServiceCharge(e.target.value)}
								value={editAdditionalServiceCharge}
								aria-invalid={validAdditionalServiceCharge ? "false" : "true"}
								aria-describedby="pricenote2"
								onFocus={() => setAdditionalServiceChargeFocus(true)}
								onBlur={() => setAdditionalServiceChargeFocus(false)}
							/>
						</div>
						<p id="pricenote2" className={additionalServiceChargeFocus && editAdditionalServiceCharge && !validAdditionalServiceCharge ? "instructions" : "offscreen"}>
							Invalid Additional Service Charge. Service Charge must only contain numbers and a period.
						</p>

						{/* Billing Frequency */}
						<label htmlFor="status">Billing Frequency:</label>
						<div className="inputWrapper">
							<select
								name="status"
								id="status"
								defaultValue={editBilling}
								onChange={(e) => setEditBilling(e.target.value)}
								value={editBilling}
							>
								<option value="monthly">Monthly</option>
								<option value="annual">Annual</option>
							</select>
						</div>

						{/* Add Options */}
						<div className="inputWrapper">
							<FormGroup>
								  <FormControlLabel control={
									<Checkbox
										checked={editIncludeOptions}
										onChange={(e) => handleCheckbox(e)}
										inputProps={{ 'aria-label': 'controlled' }}
									  />
								  } label="Include Options" />
							</FormGroup>
						</div>

						{/* Package Status */}
						<label htmlFor="status">Package Status:</label>
						<div className="inputWrapper">
							<select
								name="status"
								id="status"
								defaultValue={editStatus}
								onChange={(e) => setEditStatus(e.target.value)}
								value={editStatus}
							>
								<option value="active">Active</option>
								<option value="disabled">Disabled</option>
							</select>
						</div>
					</form>
				</DialogContent>
				<DialogActions>
				  <button className="cancel" onClick={handleCloseEdit}>Cancel</button>
				  <button className="confirm" disabled={!validName || !validDescription || !validPrice || !validDescription || !validAdditionalServiceCharge ? true : false} onClick={handleEdit}>Yes, update package</button>
				</DialogActions>
			</Dialog>

			{/* Edit Option Dialog */}
			<Dialog
				open={openEditOptions}
				onClose={handleCloseEditOptions}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			  >
				<DialogTitle id="alert-dialog-title">
				  {`Editing option '${editOptionName}'`}
				</DialogTitle>
				<DialogContent>
					<form>
						{/* Status */}
						<label htmlFor="enabled">Option Enabled:</label>
						<div className="inputWrapper">
							<select
								name="status"
								id="status"
								defaultValue={optionEnabled}
								onChange={(e) => setOptionEnabled(e.target.value)}
								value={optionEnabled}
							>
								<option value="1">Active</option>
								<option value="0">Disabled</option>
							</select>
						</div>


						{/* Name */}
						<label htmlFor="name">Option Name:</label>
						<div className="inputWrapper">
							<span className={validOptionName ? "valid" : "hide"}><FaCheck /></span>
							<span className={validOptionName || !editOptionName ? "hide" : "invalid"}><FaExclamation /></span>
							<input
								type="text"
								id="name"
								className={validOptionName || !editOptionName ? null : "error"}
								ref={optionNameRef}
								autoComplete="off"
								onChange={(e) => setEditOptionName(e.target.value)}
								value={editOptionName}
								aria-invalid={validOptionName ? "false" : "true"}
								aria-describedby="namenote"
								onFocus={() => setNameFocus(true)}
								onBlur={() => setNameFocus(false)}
							/>
						</div>
						<p id="namenote" className={optionNameFocus && editOptionName && !validOptionName ? "instructions" : "offscreen"}>
							Invalid Option Name. Option names must be between 1-50 characters long. Valid characters (-,_,&,@,#,$,!)
						</p>

						{/* Description */}
						<label htmlFor="description">Description:</label>
						<div className="inputWrapper">
							<span className={validOptionDescription ? "valid" : "hide"}><FaCheck /></span>
							<span className={validOptionDescription || !editOptionDescription ? "hide" : "invalid"}><FaExclamation /></span>
							<textarea
								ref={optionDescriptionRef}
								onChange={(e) => setEditOptionDescription(e.target.value)}
								name="description"
								id="description"
								rows="5"
								value={editOptionDescription}
							>
							</textarea>
							<p id="descriptionnote" className={optionDescriptionFocus && editOptionDescription && !validOptionDescription ? "instructions" : "offscreen"}>
								Invalid Description. Between 1-500 characters (letters, numbers, dash and underscore allowed)
							</p>
						</div>

						{/* Checkbox Options */}
						<label>Checkbox Options</label>
						<FormGroup>
							<FormControlLabel control={<Checkbox checked={editOptionCheckboxChecked} onChange={(e) => setEditOptionCheckboxChecked(e.target.checked)} />} label="Default Checked" />
							<FormControlLabel control={<Checkbox checked={editOptionDisabled} onChange={(e) => setEditOptionDisabled(e.target.checked)} />} label="Disabled" />
							<FormControlLabel control={<Checkbox checked={editOnetimePayment} onChange={(e) => setEditOnetimePayment(e.target.checked)} />} label="One-time Payment" />
						</FormGroup>

						{editOnetimePayment ?
							<>
								<label htmlFor="price">Price:</label>
								<div className="inputWrapper">
									<span className={validEditOptionPrice ? "valid" : "hide"}><FaCheck /></span>
									<span className={validEditOptionPrice || !editOptionPrice ? "hide" : "invalid"}><FaExclamation /></span>
									<input
										type="text"
										id="name"
										className={validEditOptionPrice || !editOptionPrice ? null : "error"}
										ref={editOptionPriceRef}
										autoComplete="off"
										onChange={(e) => setEditOptionPrice(e.target.value)}
										value={editOptionPrice}
										aria-invalid={validEditOptionPrice ? "false" : "true"}
										aria-describedby="pricenote"
										onFocus={() => setPriceFocus(true)}
										onBlur={() => setPriceFocus(false)}
									/>
								</div>
								<p id="pricenote" className={editOptionPriceFocus && editOptionPrice && !validEditOptionPrice ? "instructions" : "offscreen"}>
									Invalid Option Price.
								</p>
							</>
							:
							null
						}

					</form>
				</DialogContent>
				<DialogActions>
				  <button className="cancel" onClick={handleCloseEditOptions}>Cancel</button>
				  <button className="confirm" disabled={!validOptionName || !validOptionDescription ? true : false} onClick={handleEditOption}>Yes, update option</button>
				</DialogActions>
			</Dialog>

			{/* View Dialog */}
			<Dialog
				open={openView}
				onClose={handleCloseView}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="viewMarketPopup"
			  >
				<DialogTitle id="alert-dialog-title">
				  {`${viewName}`}
				</DialogTitle>
				<DialogContent>
					<>
						{viewDescription && <div>{viewDescription}</div> }
						{viewPrice && <div>{formatCurrency(viewPrice)}</div>}
						{viewStates && <div>States {viewStates}</div>}
						{viewCounties && <div>Counties {viewCounties}</div>}
						{viewServices && <div>Service Categories (free) {viewServices}</div>}
						{viewMaxServices && <div>Max Service Categories {viewMaxServices}</div>}
						{viewAdditionalServiceCharge && <div>Additional Service Charge {formatCurrency(viewAdditionalServiceCharge)}</div>}
						{viewBilling && <div>Billed {viewBilling}</div>}
					</>
				</DialogContent>
				<DialogActions>
				  <button className="cancel" onClick={handleCloseView}>Close</button>
				</DialogActions>
			</Dialog>

			{/* Add Package Dialog */}
			<Dialog
				open={openAdd}
				onClose={handleCloseAdd}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			  >
			  <DialogTitle id="alert-dialog-title">
				{"Adding Package"}
			  </DialogTitle>
				<DialogContent>
		  	  		<CreatePackage addPackage={addPackage} />
				</DialogContent>
				<DialogActions>
				  	<button className="cancel" onClick={handleCloseAdd}>Cancel</button>
				</DialogActions>
			</Dialog>

			{/* Add Option Dialog */}
			<Dialog
				open={openOption}
				onClose={handleCloseOption}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			  >
				<DialogTitle id="alert-dialog-title">
				  {"Adding Option"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<CreateOption addOption={addOption} />
					</DialogContentText>
				</DialogContent>
				<DialogActions>
				  	<button className="cancel" onClick={handleCloseOption}>Cancel</button>
				</DialogActions>
			</Dialog>

			{/* View Option Dialog */}
			<Dialog
				open={openViewOption}
				onClose={handleCloseViewOption}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="viewMarketPopup"
			  >
				<DialogTitle id="alert-dialog-title">
				  {`${viewOptionName}`}
				</DialogTitle>
				<DialogContent>
					<>
						{viewOptionType && <div><strong>Type:</strong> {viewOptionType}</div> }
						{viewOptionName && <div><strong>Name:</strong> {viewOptionName}</div> }
						{viewOptionCheckboxChecked ? viewOptionType == 'checkbox' && <div><strong>Default Checked:</strong> {viewOptionCheckboxChecked ? 'true' : 'false'}</div> : null}
						{viewOptionDisabled ? viewOptionType == 'checkbox' && <div><strong>Checkbox Disabled:</strong> {viewOptionDisabled ? 'true' : 'false'}</div> : null }
						{viewOnetimePayment ? viewOptionType == 'checkbox' && <div><strong>One-time Payment:</strong> {viewOnetimePayment ? 'true' : 'false'}</div> : null}
						{viewOptionDescription && <div><strong>Description:</strong> {viewOptionDescription}</div> }
					</>
				</DialogContent>
				<DialogActions>
				  <button className="cancel" onClick={handleCloseViewOption}>Close</button>
				</DialogActions>
			</Dialog>

			<ToastContainer
				position="top-right"
		    	autoClose={5000}
		    	hideProgressBar={false}
		    	newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="colored"
			/>

		</div>
		)
}

export default Packages