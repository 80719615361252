import Layout from './components/Layout';
import LegalLayout from './components/LegalLayout';
import Home from './routes/Home';
import Login from './routes/Login';
import Register from './routes/Register';
import Profile from './routes/user/Profile';
import Logout from './routes/Logout';
import About from './routes/About';
import Contractor from './routes/Contractor';
import Homeowner from './routes/Homeowner';
import Error from './routes/Error';
import Careers from './routes/Careers';
import Contact from './routes/Contact';
import Advertise from './routes/Advertise';
import FAQ from './routes/FAQ';
import Recruiting from './routes/Recruiting';
import MediaKit from './routes/MediaKit';
import PrivacyPolicy from './routes/PrivacyPolicy';
import EmailVerification from './routes/EmailVerification';
import Dashboard from './routes/user/Dashboard';
import Reviews from './routes/user/Reviews';
import Messages from './routes/user/Messages';
import Promotions from './routes/user/Promotions';
import Jobs from './routes/user/Jobs';
import Metrics from './routes/user/Metrics';
import Support from './routes/user/Support';
import Account from './routes/user/Account';
import AccountConfigure from './routes/user/AccountConfigure';
import FavoriteContractors from './routes/user/FavoriteContractors';
import AdminDashboard from './routes/admin/AdminDashboard';
import AdminUsers from './routes/admin/AdminUsers';
import AdminTickets from './routes/admin/AdminTickets';
import AdminJobs from './routes/admin/AdminJobs';
import AdminReviews from './routes/admin/AdminReviews';
import AdminMarkets from './routes/admin/AdminMarkets';
import AdminPackages from './routes/admin/AdminPackages';
import AdminZipCodes from './routes/admin/AdminZipCodes';
import ServiceCategories from './routes/admin/ServiceCategories';
import AdminMetrics from './routes/admin/AdminMetrics';
import AdminLegal from './routes/admin/AdminLegal';
import AdminInvites from './routes/admin/AdminInvites';
import AdminCoupons from './routes/admin/AdminCoupons';
import Unauthorized from './routes/Unauthorized';
import ForgotPassword from './routes/ForgotPassword';
import PasswordReset from './routes/PasswordReset';
import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';
import ViewContractor from './routes/ViewContractor';
import ContractorSearchResults from './routes/ContractorSearchResults';
import ContractorSearchNameResults from './routes/ContractorSearchNameResults';
import { Routes, Route } from 'react-router-dom';
import Legal from './routes/legal/Legal'
import Terms from './routes/legal/Terms'
import Advertising from './routes/legal/Advertising'
import Conditions from './routes/legal/Conditions'
import Conduct from './routes/legal/Conduct'
import Membership from './routes/legal/Membership'
import Privacy from './routes/legal/Privacy'
import Project from './routes/legal/Project'
import Sell from './routes/legal/Sell'
import Service from './routes/legal/Service'
import ZohoFormThankYou from './routes/ZohoFormThankYou';
import RegisterThankYou from "./routes/RegisterThankYou";
import HomeProLandingPage from './routes/HomeProLandingPage';
import HomeownerLandingPageForm from './routes/HomeownerLandingPageForm';
import HomeownerFormTerms from './routes/HomeownerTermsPage';

function App() {
  if (window.location.host.split(".")[0] == "legal") {
    return (
      <Routes>
        <Route path="/" element={<LegalLayout />}>
          <Route path="/" element={<Terms />}></Route>
          <Route path="terms-of-use" element={<Terms />}></Route>
          <Route path="membership-agreement" element={<Membership />}></Route>
          <Route path="project-submission-customer-agreement" element={<Project />}></Route>
          <Route path="service-providers-user-agreement" element={<Service />}></Route>
          <Route path="advertising-agreement" element={<Advertising />}></Route>
          <Route path="code-of-conduct" element={<Conduct />}></Route>
          <Route path="privacy-policy" element={<Privacy />}></Route>
          <Route path="do-not-sell-policy" element={<Sell />}></Route>
          <Route path="mobile-alerts-terms-and-conditions" element={<Conditions />}></Route>
        </Route>
      </Routes>
    )

  }

  return (
    <Routes>

      <Route path="/" element={<Layout />}>

            {/* Public Routes */}
            <Route path="/" element={<Home />} />
            <Route path="login" element={<Login />} />

            <Route path="contractor/:slug" element={<ViewContractor />} />
            <Route path="email-verification" element={<EmailVerification />} />
          {/* Persist Login Routes */}
          <Route element={<PersistLogin />}>

            {/* Search Results */}
            <Route path="search-contractor-results" element={<ContractorSearchResults />} />
            <Route path="search-contractor-name-results" element={<ContractorSearchNameResults />} />

            {/* Registration Routes */}
            <Route path="register-home-pro" element={<Register userType="2002" />} />
            <Route path="register-homeowner" element={<Register userType="1001" />} />
            <Route path="register" element={<Register />} />
            <Route path="thank-you-home-pro" element={<RegisterThankYou userType="2002" />} />
            <Route path="thank-you-homeowner" element={<RegisterThankYou userType="1001" />} />
            <Route path="dhs-homepro-signup-2024" element={<HomeProLandingPage />} />
            <Route path="dhs-homeowner-giveaway-2024" element={<HomeownerLandingPageForm />} />
            <Route path="form-terms-and-conditions" element={<HomeownerFormTerms />} />

            {/* Thank You Page Routes */}
            <Route path="thank-you" element={<ZohoFormThankYou />} />

            {/* Password Routes */}
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<PasswordReset />} />

            {/* General Pages */}
            <Route path="become-a-home-pro" element={<Contractor />} />
            <Route path="become-a-homeowner" element={<Homeowner />} />
            <Route path="unauthorized" element={<Unauthorized />} />

            {/* Footer Links */}
            <Route path="terms-and-conditions" element={<Terms />} />
            <Route path="privacy-policy" element={< PrivacyPolicy />} />
            <Route path="about-us" element={<About />} />
            <Route path="advertise" element={<Advertise />} />
            <Route path="careers" element={<Careers />} />
            <Route path="media-kit" element={<MediaKit />} />
            <Route path="contact-us" element={<Contact />} />
            <Route path="frequently-asked-questions" element={<FAQ />} />
            <Route path="recruiting" element={<Recruiting />} />


            {/* Private Routes */}
            <Route element={<RequireAuth allowedRoles={['1001','2002','5150','127001']}/>}>
              <Route path="user/dashboard" element={<Dashboard />} />
              <Route path="user/profile" element={<Profile />} />
              <Route path="user/reviews" element={<Reviews />} />
              <Route path="user/messages" element={<Messages />} />
              <Route path="user/promotions" element={<Promotions />} />
              <Route path="user/projects" element={<Jobs />} />
              <Route path="user/metrics" element={<Metrics />} />
              <Route path="user/support" element={<Support />} />
              <Route path="user/account" element={<Account />} />
              <Route path="user/account/configure" element={<AccountConfigure />} />
              <Route path="user/favorite-contractors" element={<FavoriteContractors />} />
              <Route path="logout" element={<Logout />} />
            </Route>

            {/* Private Homeowner Routes */}
            <Route element={<RequireAuth allowedRoles={['1001']}/>}>
              <Route path="h-profile" element={<Profile />} />
            </Route>

            {/* Private Contractor Routes */}
            <Route element={<RequireAuth allowedRoles={['2002']}/>}>
              <Route path="c-profile" element={<Profile />} />
            </Route>

            {/* Private Admin Routes */}
            <Route element={<RequireAuth allowedRoles={['5150', '127001']}/>}>
              <Route path="admin-dashboard" element={<AdminDashboard />} />
              <Route path="admin-dashboard/users" element={<AdminUsers />} />
              <Route path="admin-dashboard/tickets" element={<AdminTickets />} />
              <Route path="admin-dashboard/projects" element={<AdminJobs />} />
              <Route path="admin-dashboard/reviews" element={<AdminReviews />} />
              <Route path="admin-dashboard/markets" element={<AdminMarkets />} />
              <Route path="admin-dashboard/zip-codes" element={<AdminZipCodes />} />
              <Route path="admin-dashboard/service-categories" element={<ServiceCategories />} />
              <Route path="admin-dashboard/packages" element={<AdminPackages />} />
              <Route path="admin-dashboard/coupons" element={<AdminCoupons />} />
              <Route path="admin-dashboard/metrics" element={<AdminMetrics />} />
              <Route path="admin-dashboard/legal" element={<AdminLegal />} />
              <Route path="admin-dashboard/invites" element={<AdminInvites />} />
            </Route>

        </Route>

        {/* 404 Error */}
        <Route path="*" element={<Error />} />

      </Route>
    </Routes>
  );
}

export default App;