import { useState, useEffect } from 'react'
import useFormContext from "../hooks/useFormContext"
import useAuth from '../hooks/useAuth'
import { ToastContainer, toast } from 'react-toastify'

const FormStepCategories = () => {

	const { data, updateServices, handleChange } = useFormContext()
	const services = data.categories
	const initSelectedServices = data.selected_services

	const auth = useAuth()
	const subscription = auth?.auth?.subscription
	const allowedFreeServices = subscription?.package_details?.num_allowed_services
	const allowedMaxServices = subscription?.package_details?.num_max_allowed_services
	const allowedAdditionalServices = allowedMaxServices - allowedFreeServices
	const savedServices = subscription?.service_categories ? JSON.parse(subscription?.service_categories) : []

	const [searchServices, setSearchServices] = useState()
	const [searchServicesResults, setSearchServicesResults] = useState()

	const [selectedServices, setSelectedServices] = useState(initSelectedServices ? initSelectedServices : savedServices ? savedServices : [])

	const additionalCategoryCharge = subscription?.package_details?.additional_service_charge

	useEffect(() => {
		updateServices(selectedServices)
		{/* console.log(selectedCounties) */}
	}, [selectedServices])

	useEffect(() => {
		if (searchServices === ""){
			setSearchServicesResults()
		} else {
			const resultsArray = services?.filter(service => service?.category_name.toLowerCase().includes(searchServices?.toLowerCase()))
			setSearchServicesResults(resultsArray)
		}
	}, [searchServices])

	const toggleService = (e, i, service) => {

		// Add or remove service from subscription
		if (!selectedServices.includes(service)){
			if (allowedMaxServices !== -1 && selectedServices.length >= allowedMaxServices){
				const createJobToast = toast.error("Max Services Added", { type: 'error', isLoading: false, autoClose: 5000});
			} else {
				setSelectedServices([...selectedServices, service])
			}
		} else {
			setSelectedServices(selectedServices.filter(svc => { return svc !== service}))
		}
	}

	return (
		<>
		<div className="servicesContainer">
			<div className="serviceContent">
				<p>Your subscription package includes {allowedFreeServices} service categories.</p>
				<p>You can add {allowedMaxServices == -1 ? 'unlimited' : `up to ${allowedAdditionalServices}`} additional service categories for ${additionalCategoryCharge} each per month.</p>
				<div className="serviceSearchWrapper">
					<input onChange={(e) => setSearchServices(e.target.value)} id="type" type="text" autoComplete="off" placeholder="Start typing to search for service categories" />
				</div>
			</div>

			<div className="serviceContainer">
				<div className="serviceWrapper">
				{services?.length ?
					<>
						<h4>Available Categories</h4>
						<ul className="marketingCategories">
						{searchServicesResults?.map((service, i) =>
							<li className={selectedServices.includes(service?.category_name) ? 'active' : null} key={i} onClick={(e) => toggleService(e, i, service?.category_name)}>
								<div className="category">
									{service?.category_name}
								</div>
							</li>
						)}
						</ul>
					</>
				:
					<p>No Categories available</p>
				}
				</div>

				<div className="selectedCategories">
					<h4>Selected Categories</h4>
					<p><strong>Included Service Categories</strong></p>
					{!selectedServices.length ?
						<p>Select at least one service category to show up in homeowner searches.</p>
					:
						<p>{selectedServices.length > allowedFreeServices ? allowedFreeServices : selectedServices.length} of {allowedFreeServices} Selected</p>
					}
					{selectedServices.length > allowedFreeServices &&
						<>
							<p><strong>Additional Service Categories</strong></p>
							<p>{selectedServices.length - allowedFreeServices} of {allowedMaxServices !== -1 ? allowedMaxServices - allowedFreeServices : 'unlimited'} Selected</p>
							<p>{`Additional service categories ($${(selectedServices.length - allowedFreeServices) * additionalCategoryCharge}.00 each, per month)`}</p>
						</>
					}
					<ul className="marketingCategories">
					{ selectedServices?.map((service, i) =>
						<li key={i} onClick={(e) => toggleService(e, i, service)}>
							<div className="category">
								{service}
							</div>
						</li>
					)}
					</ul>
				</div>
			</div>
		</div>
		<ToastContainer
		position="top-right"
		autoClose={5000}
		hideProgressBar={false}
		newestOnTop={false}
		closeOnClick
		rtl={false}
		pauseOnFocusLoss
		draggable
		pauseOnHover
		theme="colored"
		/>
		</>
	)
}

export default FormStepCategories