import { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AiFillHeart } from "react-icons/ai";
import { AiOutlineHeart } from "react-icons/ai";
import { AiFillPhone } from "react-icons/ai";
import { AiOutlinePhone } from "react-icons/ai";
import { AiFillMessage } from "react-icons/ai";
import { AiOutlineMessage } from "react-icons/ai";
import { AiFillHome } from "react-icons/ai";
import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
import { AiOutlineTrophy } from "react-icons/ai";
import { AiOutlineStar } from "react-icons/ai";
import { AiFillStar } from "react-icons/ai";
import { MdOutlineReviews } from "react-icons/md";
import { AiOutlineShareAlt } from "react-icons/ai";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ToastContainer, toast } from "react-toastify";
import SubmitReview from "./SubmitReview";
import SendMessage from "./SendMessage";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";

const ContractorActions = ({ url, phone, email, id, name }) => {
  const [favorite, setFavorite] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const [loading, setLoading] = useState(false);

  const { auth } = useAuth();
  const userID = auth?.user?.id;
  const userType = auth?.user?.role;
  const userReviewBlock = auth?.user?.reviews_blocked;
  const userName = `${auth?.user?.first_name} ${auth?.user?.last_name}`;
  const contractorName = name;
  const contractorID = id;

  const jobs = auth?.jobs;
  const [shareJobs, setShareJobs] = useState([]);

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const [message, setMessage] = useState("");
  const [validMessage, setValidMessage] = useState(false);
  const [validShare, setValidShare] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleClickOpen3 = () => {
    setOpen3(true);
  };

  const handleClose3 = () => {
    setOpen3(false);
    setShareJobs([]);
  };

  useEffect(() => {
    console.log(shareJobs);
    if (shareJobs?.length >= 1) {
      setValidShare(true);
    } else {
      setValidShare(false);
    }
  }, [shareJobs]);

  const handleShare = async (e) => {
    // Convert job id array to string for API
    const shareIDs = shareJobs.join(",");

    const controller = new AbortController();
    const dismiss = () => toast.dismiss(shareJobsToast.current);
    const shareJobsToast = toast.loading("Sharing Projects");
    try {
      const response = await axiosPrivate.post(
        "share-jobs/" + contractorID,
        JSON.stringify({ shared_jobs: shareIDs }),
        {
          signal: controller.signal,
        }
      );
      setOpen3(false);
      // Update Toast Notification
      toast.update(shareJobsToast, {
        render: "Project Shared",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
    } catch (err) {
      if (!err?.response) {
        toast.update(shareJobsToast, {
          render: "No Server Response",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        {
          /* setErrMsg('No Server Response'); */
        }
      } else if (err.response?.status === 401) {
        toast.update(shareJobsToast, {
          render: "Authorization Failed",
          type: "error",
          isLoading: false,
        });
        {
          /* setErrMsg('The email has already been taken.'); */
        }
      } else {
        toast.update(shareJobsToast, {
          render: "Sharing Projects Failed",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        {
          /* setErrMsg('Registration Failed'); */
        }
      }
    }

    return () => controller.abort();
  };

  const incrementPhoneCount = async (e) => {
    const controller = new AbortController();

    // Needs to be public route

    return () => controller.abort();
  };

  const incrementEmailCount = async (e) => {
    const controller = new AbortController();

    // Needs to be public route

    return () => controller.abort();
  };

  const incrementWebsiteCount = async (e) => {
    const controller = new AbortController();

    // Needs to be public route

    return () => controller.abort();
  };

  const handleShareChange = async (e) => {
    var checked = e.target.checked;
    var value = e.target.name;

    if (checked && !shareJobs.includes(value)) {
      setShareJobs([...shareJobs, value]);
    } else {
      setShareJobs(shareJobs.filter((item) => item !== value));
    }
  };

  const handleSendMessage = async (e) => {
    const controller = new AbortController();
    const dismiss = () => toast.dismiss(sendMessageToast.current);
    const sendMessageToast = toast.loading("Sending Message");
    try {
      const response = await axiosPrivate.post(
        "message",
        JSON.stringify({
          receiver_id: id,
          receiver_name: name,
          sender_id: userID,
          sender_name: userName,
          message: message,
        }),
        {
          signal: controller.signal,
        }
      );
      setOpen2(false);
      // Update Toast Notification
      toast.update(sendMessageToast, {
        render: "Message Sent",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
    } catch (err) {
      if (!err?.response) {
        toast.update(sendMessageToast, {
          render: "No Server Response",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        {
          /* setErrMsg('No Server Response'); */
        }
      } else if (err.response?.status === 401) {
        toast.update(sendMessageToast, {
          render: "Authorization Failed",
          type: "error",
          isLoading: false,
        });
        {
          /* setErrMsg('The email has already been taken.'); */
        }
      } else {
        toast.update(sendMessageToast, {
          render: "Message Send Failed",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        {
          /* setErrMsg('Registration Failed'); */
        }
      }
    }

    return () => controller.abort();
  };

  const handleFavorite = async (e) => {
    const controller = new AbortController();
    if (favorite == true) {
      try {
        const response = await axiosPrivate.delete("user/favorite/" + id, {
          signal: controller.signal,
        });
        {
          /* console.log(response.data.isFavorite); */
        }
        setFavorite(false);
      } catch (err) {
        console.log(err.message);
        {
          /* !auth && navigate('/login', { state: { from: location }, replace: true }); */
        }
      }
    } else {
      try {
        const response = await axiosPrivate.post(
          "user-favorite",
          JSON.stringify({ contractor_id: id, contractor_name: name }),
          {
            signal: controller.signal,
          }
        );
        {
          /* console.log(response.data.isFavorite); */
        }
        setFavorite(true);
      } catch (err) {
        console.log(err);
        {
          /* !auth && navigate('/login', { state: { from: location }, replace: true }); */
        }
      }
    }

    return () => controller.abort();
  };

  return (
    <div className="actionsContainer">
      <ul>
        {userType == 1001 && !userReviewBlock ? (
          <li onClick={(e) => handleClickOpen()}>
            <Tooltip title="Write Review">
              <MdOutlineReviews />
            </Tooltip>
          </li>
        ) : (
          <Link to="/login">
            <li>
              <Tooltip title="Write Review">
                <MdOutlineReviews />
              </Tooltip>
            </li>
          </Link>
        )}
        {userType == 1001 ? (
          <li onClick={(e) => handleClickOpen3()}>
            <Tooltip title="Share Projects">
              <AiOutlineShareAlt />
            </Tooltip>
          </li>
        ) : (
          <Link to="/login">
            <li>
              <Tooltip title="Share Projects">
                <AiOutlineShareAlt />
              </Tooltip>
            </li>
          </Link>
        )}
        {userType == 1001 ? (
          <li onClick={(e) => handleClickOpen2()}>
            <Tooltip title="Direct Message Contrator">
              <AiOutlineMessage />
            </Tooltip>
          </li>
        ) : (
          <Link to="/login">
            <li>
              <Tooltip title="Direct Message Contrator">
                <AiOutlineMessage />
              </Tooltip>
            </li>
          </Link>
        )}
        {userType == 1001 ? (
          <li onClick={(e) => handleFavorite()}>
            {favorite ? (
              <Tooltip title="Unfavorite Contractor">
                <AiFillHeart />
              </Tooltip>
            ) : (
              <Tooltip title="Favorite Contractor">
                <AiOutlineHeart />
              </Tooltip>
            )}
          </li>
        ) : (
          <Link to="/login">
            <li>
              {favorite ? (
                <Tooltip title="Unfavorite Contractor">
                  <AiFillHeart />
                </Tooltip>
              ) : (
                <Tooltip title="Favorite Contractor">
                  <AiOutlineHeart />
                </Tooltip>
              )}
            </li>
          </Link>
        )}
      </ul>

      {/* Review Popup */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Review ${name}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <SubmitReview name={name} contractor_id={id} setOpen={setOpen} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="cancel" onClick={handleClose}>
            Cancel
          </button>
        </DialogActions>
      </Dialog>

      {/* Send Message Popup */}
      <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Send a message to ${name}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <SendMessage
              name={name}
              message={message}
              setMessage={setMessage}
              validMessage={validMessage}
              setValidMessage={setValidMessage}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="cancel" onClick={handleClose2}>
            Cancel
          </button>
          <button
            disabled={!validMessage ? true : false}
            className="confirm"
            onClick={handleSendMessage}
          >
            Send
          </button>
        </DialogActions>
      </Dialog>

      {/* Share Popup */}
      <Dialog
        open={open3}
        onClose={handleClose3}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Share a project with ${contractorName}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <>
              {jobs?.length ? (
                <>
                  {
                    "Select the projects you whould like to send this contractor."
                  }
                  <FormGroup className="contractors_share_list">
                    {jobs?.map((job, i) => (
                      <FormControlLabel
                        key={i}
                        control={
                          <Checkbox
                            name={job?.id}
                            onChange={handleShareChange}
                          />
                        }
                        label={job?.name}
                      />
                    ))}
                  </FormGroup>
                </>
              ) : (
                <p>
                  You don't currently have any projects created. Would you like
                  to make one now?
                </p>
              )}
            </>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="cancel" onClick={handleClose3}>
            Cancel
          </button>
          {jobs?.length ? (
            <button
              disabled={!validShare ? true : false}
              className="confirm"
              onClick={handleShare}
            >
              Share
            </button>
          ) : (
            <Link to="/user/projects">
              <button className="confirm createJob">Create Project</button>
            </Link>
          )}
        </DialogActions>
      </Dialog>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default ContractorActions;
