import * as React from 'react';
import { useSearchParams } from "react-router-dom"
import { useEffect, useState } from "react";
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Loader from './Loader';
import Chart from 'react-apexcharts'
import MetricsMenu from './MetricsMenu'

const AdminMetrics = () => {

	const axiosPrivate = useAxiosPrivate();
	const [loading, setLoading] = useState(false);
	const [metrics, setMetrics] = useState();
	const [userMetrics, setUserMetrics] = useState()
	const [projectMetrics, setProjectMetrics] = useState()
	const [categoryMetrics, setCategoryMetrics] = useState()

	const [userMetricData, setUserMetricData] = useState({ months: [], contractors: [], homeowners: []});
	const [projectMetricData, setProjectMetricData] = useState({ months: [], new: [], shared: []});

	const [sortedProjectCategories, setSortedProjectCategories] = useState()
	const [sortedSearchCategories, setSortedSearchCategories] = useState()

	const [queryParameters] = useSearchParams()

	const userChartOptions = {
	  options: {
		chart: {
		  id: 'apexchart-example'
		},
		plotOptions: {
			bar: {
			  horizontal: false,
			  columnWidth: '55%',
			  endingShape: 'rounded'
			},
		},
		dataLabels: {
			enabled: false
		},
		stroke: {
			show: true,
			width: 2,
			colors: ['transparent']
		},
		fill: {
			opacity: 1
		},
		xaxis: {
		  categories: userMetricData.months
		}
	  },
	  series: [{
		name: 'HomePros',
		data: userMetricData.contractors
	  },
  	  {
	    name: 'Homeowners',
	    data: userMetricData.homeowners
	  }
	  ]
	}

	const projectChartOptions = {
	  options: {
		chart: {
		  id: 'apexchart-example'
		},
		plotOptions: {
			bar: {
			  horizontal: false,
			  columnWidth: '55%',
			  endingShape: 'rounded'
			},
		},
		dataLabels: {
			enabled: false
		},
		stroke: {
			show: true,
			width: 2,
			colors: ['transparent']
		},
		fill: {
			opacity: 1
		},
		xaxis: {
		  categories: projectMetricData.months
		}
	  },
	  series: [{
		name: 'New Projects',
		data: projectMetricData.new
	  },
  	  {
	    name: 'Shared Projects',
	    data: projectMetricData.shared
	  }
	  ]
	}

	useEffect(() => {
		const metricDates = [];
		const metricHomeowners = [];
		const metricContractors = []

		userMetrics?.map((metric, index) => {
			metricDates.push(metric.month)
			metricHomeowners.push(metric.new_homeowners)
			metricContractors.push(metric.new_contractors)
		})

		setUserMetricData({months: metricDates, contractors: metricContractors, homeowners: metricHomeowners})

		console.log(userMetricData)
	}, [userMetrics])

	useEffect(() => {
		const searchCategories = categoryMetrics?.top_search_categories
		const projectCategories = categoryMetrics?.top_project_categories

		if (searchCategories?.length){
			const sortedSearch = searchCategories.sort((a, b) => b.search_count - a.search_count)
			setSortedSearchCategories(sortedSearch)
		} else {
			console.log("can't get search categories")
		}
		if (projectCategories?.length){
			const sortedProject = projectCategories.sort((a, b) => b.project_count - a.project_count)
			setSortedProjectCategories(sortedProject)
		} else {
			console.log("can't get project categories")
		}


	}, [categoryMetrics])

	useEffect(() => {
		const metricDates = [];
		const metricNew = [];
		const metricShared = []

		projectMetrics?.map((metric, index) => {
			metricDates.push(metric.month)
			metricNew.push(metric.new_projects)
			metricShared.push(metric.shared_projects)
		})

		setProjectMetricData({months: metricDates, new: metricNew, shared: metricShared})

		console.log(userMetricData)
	}, [projectMetrics])

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getFavoriteMetrics = async () => {
			try {
				const response = await axiosPrivate.get('metrics', {
					signal: controller.signal
				});
				setUserMetrics(response.data.user_metrics);
				setProjectMetrics(response.data.project_metrics);
				setCategoryMetrics(response.data.category_metrics);
				setLoading(false)
			} catch(err) {
				console.log(err.message);
				{/* !auth && navigate('/login', { state: { from: location }, replace: true }); */}
			}
		}

		getFavoriteMetrics();

		return () => controller.abort();
		// eslint-disable-next-line
	}, [])

	return (
		<>
			<MetricsMenu />
			{(queryParameters.get("metric") == "user" || queryParameters.get("metric") == null) &&
			<>
				<h1>New User Metrics</h1>
				<Chart options={userChartOptions.options} series={userChartOptions.series} type="bar" />
			</>
			}
			{queryParameters.get("metric") == "services" &&
			<>
				<h3>Search Categories</h3>
				<p>Top 10 search categories all time</p>
				{sortedSearchCategories?.length ?
					<ul className="metrics">
						<li className="key">
							<p className="rank">Rank</p>
							<p className="name">Name</p>
							<p className="count">Search Count</p>
						</li>
						{sortedSearchCategories?.slice(0,10).map((category, i) =>
							<li>
								<p className="rank">{i+1}</p>
								<p className="name">{category.category_name}</p>
								<p className="count">{category.search_count}</p>
							</li>
						)}
					</ul>
					:
					<p>No Search Categories available</p>
				}
				<h3>Project Categories</h3>
				<p>Top 10 project categories all time</p>
				{sortedProjectCategories?.length ?
					<ul className="metrics">
						<li className="key">
							<p className="rank">Rank</p>
							<p className="name">Name</p>
							<p className="count">Project Count</p>
						</li>
						{sortedProjectCategories?.slice(0,10).map((category, i) =>
							<li>
								<p className="rank">{i+1}</p>
								<p className="name">{category.category_name}</p>
								<p className="count">{category.project_count}</p>
							</li>
						)}
					</ul>
					:
					<p>No Search Categories available</p>
				}
			</>
			}
			{queryParameters.get("metric") == "projects" &&
			<>
				<h1>Project Metrics</h1>
				<Chart options={projectChartOptions.options} series={projectChartOptions.series} type="bar" />
			</>
			}
		</>
	)
}

export default AdminMetrics