import usePageTitle from '../../hooks/usePageTitle';
import LegalPage from '../../components/LegalPage';

const Service = () => {
	usePageTitle("Service Provider's User Agreement");

	return (
		<LegalPage title="Service Provider's User Agreement" slug="service-providers-user-agreement" />
	)
}

export default Service