import usePageTitle from '../hooks/usePageTitle';
import Page from '../components/Page';

const Advertise = () => {
	usePageTitle("Advertise");
	return (
		
		<section>
			<Page title='Advertise with us' slug='advertise' />
		</section>
	)
}

export default Advertise