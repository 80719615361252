import usePageTitle from '../../hooks/usePageTitle';
import LegalPage from '../../components/LegalPage';

const Conduct = () => {
	usePageTitle("Code of Conduct");

	return (
		<LegalPage title='Code of Conduct' slug='code-of-conduct' />
	)
}

export default Conduct