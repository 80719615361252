import { useState, useEffect, useRef } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import * as React from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { GrFormEdit } from 'react-icons/gr';
import { GrFormView } from 'react-icons/gr';
import { AiOutlineSend } from 'react-icons/ai';
import { AiOutlineMail } from 'react-icons/ai';
import { BsArchiveFill } from 'react-icons/bs';
import { MdOutlineRestore } from 'react-icons/md';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ToastContainer, toast } from 'react-toastify';
import Loader from './Loader';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from './TabPanel'
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';



const MESSAGE_REGEX = /^[a-zA-Z0-9-_!?#,.;: ]{1,250}$/;

const UserMessages = () => {

	const [loading, setLoading] = useState(false);
	const [messages, setMessages] = useState()
	const [sentMessages, setSentMessages] = useState();
	const [receivedMessages, setReceivedMessages] = useState();
	const [threads, setThreads] = useState([]);
	const [fullThreads, setFullThreads] = useState([]);
	const [threadCount, setThreadCount] = useState()
	const [threadMessages, setThreadMessages] = useState();
	const [favorites, setFavorites] = useState();
	const axiosPrivate = useAxiosPrivate();

	const [restoreThreadIDs, setRestoreThreadIDs] = useState([])

	const [initChat, setInitChat] = useState(true)

	const navigate = useNavigate();
	const location = useLocation();

	const { auth } = useAuth();
	const role = auth?.user?.role;
	const id = auth?.user?.id;
	const [userID, setUserID] = useState()
	const messageRef = useRef()
	const scrollToBottom = useRef()

	const [tab, setTab] = useState(0)

	const [openArchiveConversation, setOpenArchiveConversation] = useState(false)

	const [open, setOpen] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [openView, setOpenView] = useState(false);
	const [openThread, setOpenThread] = useState(false);
	const [openThreadDelete, setOpenThreadDelete] = useState(false);
	const [openRestoreArchive, setOpenRestoreArchive] = useState(false)

	const [viewText, setViewText] = useState();
	const [viewReceiver, setViewReceiver] = useState();
	const [viewSender, setViewSender] = useState();
	const [viewID, setViewID] = useState();
	const [deleteID, setDeleteID] = useState();

	const [deleteThreadID, setDeleteThreadID] = useState();
	const [viewThreadID, setViewThreadID] = useState();
	const [threadSender, setThreadSender] = useState()
	const [viewThreadSender, setViewThreadSender] = useState()
	const [viewThreadSenderID, setViewThreadSenderID] = useState()
	const [viewThreadReceiver, setViewThreadReceiver] = useState()
	const [viewThreadReceiverID, setViewThreadReceiverID] = useState()
	const [archivedThreads, setArchivedThreads] = useState()

	const [sendMessage, setSendMessage] = useState()
	const [validMessage, setValidMessage] = useState(false)
	const [messageFocus, setMessageFocus] = useState(false)

	const handleRestoreConversations = async () => {
		const dismiss = () =>  toast.dismiss(restoreConversationToast.current);
		const restoreConversationToast = toast.loading("Restoring Conversation(s)");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.post('messages/unarchive-threads',
			JSON.stringify({threads: restoreThreadIDs}),
			{
				signal: controller.signal
			});

			// Set restore ids back to empty array
			setRestoreThreadIDs([])

			// Close restore popup
			setOpenRestoreArchive(false)


			// Update Toast Notification
			toast.update(restoreConversationToast, { render: 'Conversation(s) Restored', type: 'success', isLoading: false, autoClose: 5000});

		} catch (err) {
			if (!err?.response) {
				toast.update(restoreConversationToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(restoreConversationToast, { render: 'Conversation(s) Restore Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();

	}

	const handleSelectRestoreThread = (e) => {
		if (e.target.checked === true && !restoreThreadIDs.includes(e.target.value)){
			setRestoreThreadIDs([...restoreThreadIDs, e.target.value])
		} else {
			setRestoreThreadIDs(restoreThreadIDs.filter((thread) => thread !== e.target.value))
		}
	}

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleView = () => {
		setOpenView(true);
	};

	const handleCloseView = () => {
		setOpenView(false);
	};

	const handleCloseThread = () => {
		setOpenThread(false);
	};

	/* Check Message */
	useEffect(() => {
		const result = MESSAGE_REGEX.test(sendMessage);
		setValidMessage(result);
	}, [sendMessage])

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getMessages = async () => {
			try {
				const response = await axiosPrivate.get('messages', {
					signal: controller.signal
				});

				// Get response data to compare arrays
				const tempThreads = response.data.messages.threads
				const tempArchivedThreads = response.data.messages.archived

				// Filter threads with archived
				// setThreads(response.data.messages.threads)
				const filteredThreads = tempThreads.filter((thread) => !tempArchivedThreads.includes(thread.thread_id))
				setThreads(filteredThreads)
				setFullThreads(response.data.messages.threads)

				// Set the rest of the state
				setMessages(response.data.messages.messages)
				setArchivedThreads(tempArchivedThreads)
				setUserID(response.data.messages.user_id)
				setLoading(false)

				// Set first thread as active so you can just start chatting
				if (initChat == true){
					setViewThreadID(response.data.messages.threads[0].thread_id)
					setViewThreadSender(response.data.messages.threads[0].sender_name)
					setViewThreadSenderID(response.data.messages.threads[0].sender_id)
					setViewThreadReceiver(response.data.messages.threads[0].receiver_name)
					setViewThreadReceiverID(response.data.messages.threads[0].receiver_id)
					setInitChat(false)
				}

			} catch(err) {
				console.log(err.message);
				{/* !auth && navigate('/login', { state: { from: location }, replace: true }); */}
			}
		}

		// Get Initial messages
		getMessages();

		// Update messages on interval
		const interval = setInterval(() => {
			getMessages();
		}, 10000) // Updates every minute (60000 | currently set to 10 seconds at 10000)

		return () => {
			clearInterval(interval)
			controller.abort()
		}

	// eslint-disable-next-line
	},[])

	useEffect(() => {

		setThreadCount(threads?.length)

		// If there is only one thread mark it read
		if (threadCount == 1){
			let threadID = threads[0]?.thread_id

			const dismiss = () =>  toast.dismiss(threadReadToast.current);
			const threadReadToast = toast.dismiss();

			const markMessageRead = async () => {
				const controller = new AbortController();
				try {
					const response = await axiosPrivate.put('message/thread-read/' + threadID,
					{
						signal: controller.signal
					});

					// Update Toast Notification
					toast.update(threadReadToast, { render: 'Thread Updated', type: 'success', isLoading: false, autoClose: 500});

					// Update State read status
					threads[0].has_unread_messages = 0;

				} catch (err) {
					if (!err?.response) {
						toast.update(threadReadToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
						{/* setErrMsg('No Server Response'); */}
					} else {
						toast.update(threadReadToast, { render: 'Thread Update Failed', type: 'error', isLoading: false, autoClose: 5000});
						{/* setErrMsg('Registration Failed'); */}
					}
					{/* errRef.current.focus(); */}
				}
				return () => controller.abort();
			}
			markMessageRead()
		}
	}, [threads])

	const viewMessage = (e, i, name, id, text) => {
		setOpenView(true);
		setViewText(text)
		setViewID(id)
		setViewReceiver(name)
	}

	const viewThread = (e, i, thread_id, thread_sender) => {
		setOpenThread(true);
		setViewThreadID(thread_id)
		console.log("thread clicked with id:" + thread_id)
		setThreadSender(thread_sender)
		const resultsArray = messages?.filter(message => message.thread_id.includes(thread_id))
		setThreadMessages(resultsArray)
	}

	useEffect(() => {
		console.log("view thread changed to: " + viewThreadID)
	}, [viewThreadID])

	const handleDelete = async () => {
		const dismiss = () =>  toast.dismiss(deleteMessageToast.current);
		const deleteMessageToast = toast.loading("Deleting Message");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.delete('message/' + deleteID,
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(deleteMessageToast, { render: 'Message Deleted', type: 'success', isLoading: false, autoClose: 5000});

			// Close Alert Window
			setOpen(false);

			// Delete message from UI
			setReceivedMessages(receivedMessages.filter((message) => message.id !== deleteID))
			setSentMessages(sentMessages.filter((message) => message.id !== deleteID))

		} catch (err) {
			if (!err?.response) {
				toast.update(deleteMessageToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(deleteMessageToast, { render: 'Message Delete Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	const deleteMessage = (e, i, name, id) => {
		setOpen(true);
		setDeleteID(id);
	}

	const deleteThread = (e, i, thread_id) => {
		setOpenThreadDelete(true);
		setDeleteThreadID(id);
	}

	const formatDate = (dateString) => {
	  const options = { month: "long", day: "numeric", year: "numeric" }
	  return new Date(dateString).toLocaleDateString(undefined, options)
	}

	const formatChatDate = (dateString) => {
	  const options = { month: "long", day: "numeric", year: "numeric"}
	  return new Date(dateString).toLocaleDateString(undefined, options)
	}

	const handleSendReply = async (e) => {
		e.preventDefault()

		const controller = new AbortController();
		try {
			const response = await axiosPrivate.post('message/thread/' + viewThreadID,
			JSON.stringify({message: sendMessage}),
			{
				signal: controller.signal
			});

			// Update State read status
			let newMessage = response.data.message
			setMessages([...messages, newMessage])

			// scrollToBottom.current.scrollIntoView({ behavior: 'smooth' });

			// Set message input box to null
			setSendMessage("")


		} catch (err) {
			if (!err?.response) {
				// toast.update(postReplyToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				console.log("No Server Response")
				{/* setErrMsg('No Server Response'); */}
			} else {
				// toast.update(postReplyToast, { render: 'Message Send Failed', type: 'error', isLoading: false, autoClose: 5000});
				console.log("Message Send Failed")
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();

	}

	function a11yProps(index) {
	  return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	  };
	}

	const handleTabChange = async (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue);

		// get thread id from tab value
		const thread_id = threads[newValue].thread_id
		const thread_unread = threads[newValue].has_unread_messages
		setViewThreadSender(threads[newValue].sender_name)
		console.log(thread_id)
		setViewThreadID(thread_id)

		const dismiss = () =>  toast.dismiss(threadReadToast.current);
		const threadReadToast = toast.dismiss();

		const controller = new AbortController();
		if (thread_unread > 0){
			try {
				const response = await axiosPrivate.put('message/thread-read/' + thread_id,
				{
					signal: controller.signal
				});

				// Update Toast Notification
				toast.update(threadReadToast, { render: 'Thread Updated', type: 'success', isLoading: false, autoClose: 500});

				// Update State read status
				threads[newValue].has_unread_messages = 0;

			} catch (err) {
				if (!err?.response) {
					toast.update(threadReadToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
					{/* setErrMsg('No Server Response'); */}
				} else {
					toast.update(threadReadToast, { render: 'Thread Update Failed', type: 'error', isLoading: false, autoClose: 5000});
					{/* setErrMsg('Registration Failed'); */}
				}
				{/* errRef.current.focus(); */}
			}
		}
		return () => controller.abort();

	}

	const handleCloseArchiveConversation = () => {
		setOpenArchiveConversation(false)
	}

	const handleCloseRestoreArchivedConversation = () => {
		setOpenRestoreArchive(false)
	}

	const handleRestoreArchivedConversation = async () => {

	}

	const handleArchiveConversation = async () => {
		const dismiss = () =>  toast.dismiss(archiveMessageToast.current);
		const archiveMessageToast = toast.loading("Archiving Conversation");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.put('messages/archive-thread/' + viewThreadID,
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(archiveMessageToast, { render: 'Conversation Archived', type: 'success', isLoading: false, autoClose: 5000});

			// Close Alert Window
			setOpenArchiveConversation(false)

			// Delete message from UI
			setThreads(threads.filter((thread) => thread.thread_id !== viewThreadID))

			// Set tab to 0 as the selected tap was deleted
			setTab(0)

		} catch (err) {
			if (!err?.response) {
				toast.update(archiveMessageToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(archiveMessageToast, { render: 'Conversation Archive Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();

	}

	return (
		<>
			{threads?.length ?
			<>

			<Box
				sx={{ display: 'flex', height: 500 }}
				className="chatBox"
			>
				  	<Tabs
						orientation="vertical"
						variant="scrollable"
						value={tab}
						onChange={handleTabChange}
						aria-label="Vertical tabs example"
		  			>
			  		{threads?.map((thread, i) =>
						<Tab icon={thread?.has_unread_messages > 0 && <Badge badgeContent={4} color="primary" variant="dot" overlap="circular"><AiOutlineMail /></Badge>} iconPosition="start" label={thread?.sender_id == userID ? thread?.receiver_name : thread.sender_name} {...a11yProps({i})} />
					)}
					</Tabs>

					{threads?.map((thread, i) =>
						<TabPanel value={tab} index={i}>
							<ul className="chat">
							{messages?.filter(message => message.thread_id.includes(thread?.thread_id)).map((message,i) =>
								<li className={`messsageItem ${message?.sender_id == userID ? "sent" : "received"}`} key={i}>
									<div className="message">
										<div className='bubble'>{message?.message}</div>
										{messages?.filter(message => message.thread_id.includes(thread?.thread_id)).length - 1 === i && <span className="timestamp" ref={scrollToBottom}>{message?.sender_id == userID ? `Sent` : `Received`} {formatChatDate(message?.created_at)}</span> }
									</div>
								</li>
							)}
							</ul>
						</TabPanel>
					)}

			</Box>
			<form className="chatSendMessage">
				<input
					type="text"
					id="message"
					className={validMessage || !sendMessage ? null : "error"}
					ref={messageRef}
					autoComplete="off"
					onChange={(e) => setSendMessage(e.target.value)}
					value={sendMessage}
					aria-invalid={validMessage ? "false" : "true"}
					aria-describedby="messagenote"
					onFocus={() => setMessageFocus(true)}
					onBlur={() => setMessageFocus(false)}
					placeholder="Message"
				/>
				<button onClick={handleSendReply}>Send</button>
			</form>
			{viewThreadSender && viewThreadReceiver && <div className="archiveConversationContainer"><span onClick={(e) => setOpenArchiveConversation(true)}><BsArchiveFill /> Archive Conversation with {viewThreadSenderID === id ? viewThreadSender : viewThreadReceiver}</span></div>}

			{archivedThreads.length ?
				<div className="archiveConversationContainer"><span onClick={(e) => setOpenRestoreArchive(true)}><MdOutlineRestore /> Restore Archived Conversation</span></div>
				:
				null
			}
			</>
			:
			<>
				<p>{`You have not ${role === "2002" ? "received" : "sent"} any messages yet.`}</p>
				{archivedThreads?.length >= 1 ?
					<div className="archiveConversationContainer"><span onClick={(e) => setOpenRestoreArchive(true)}><MdOutlineRestore /> Restore Archived Conversation</span></div>
					:
					null
				}
			</>
			}

			{/* Archive Conversation */}
			<Dialog
				open={openArchiveConversation}
				onClose={handleCloseArchiveConversation}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			  >
				<DialogTitle id="alert-dialog-title">
				  {`Archive Conversation`}
				</DialogTitle>
				<DialogContent>
				  <DialogContentText id="alert-dialog-description">
					{`Are you sure you want to archive your conversation with ${viewThreadSender}?`}
				  </DialogContentText>
				</DialogContent>
				<DialogActions>
				  <button className="cancel" onClick={handleCloseArchiveConversation}>Cancel</button>
				  <button className="confirm" onClick={handleArchiveConversation}>Archive</button>
				</DialogActions>
			</Dialog>

			{/* Restore Archived Conversation */}
			<Dialog
				open={openRestoreArchive}
				onClose={handleCloseRestoreArchivedConversation}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			  >
				<DialogTitle id="alert-dialog-title">
				  {`Restore Conversation`}
				</DialogTitle>
				<DialogContent>
				  <DialogContentText id="alert-dialog-description">
					{
						<>
						<p>Select a archived conversation to restore.</p>
						{archivedThreads?.length ?
							<ul className="restoreConversation">
								{fullThreads.filter(thread => archivedThreads.includes(thread.thread_id )).map((ft, i) =>
									<li key={i}><FormControlLabel control={<Checkbox value={ft.thread_id} onChange={(e) => handleSelectRestoreThread(e)}/>} label={ft?.sender_id == userID ? ft?.receiver_name : ft.sender_name} /></li>
								)}
							</ul>
							:
							<p>No archives available</p>
						}
				  	  	</>
					}
				  </DialogContentText>
				</DialogContent>
				<DialogActions>
				  <button className="cancel" onClick={handleCloseRestoreArchivedConversation}>Cancel</button>
				  <button className="confirm" disabled={restoreThreadIDs.length ? false : true} onClick={handleRestoreConversations}>Restore Conversations</button>
				</DialogActions>
			</Dialog>

			<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
			/>
		</>
	)
}

export default UserMessages