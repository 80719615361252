import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAxiosFunction from "../hooks/useAxiosFunction";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import Loader from "../components/Loader";
import SearchBox from "../components/SearchBox";
import { MdStar } from "react-icons/md";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "react-bootstrap";
import { FiMapPin } from "react-icons/fi";
import { FaArrowRight } from "react-icons/fa";
import { MdOutlineStar } from "react-icons/md";
import { Rating } from "@mui/material";

const EmptyResultWrap = styled.div`
  display: flex;
  flex-direction: column;
  .form-portion {
    order: 2;
  }
  .text-portion {
    order: 1;
  }
  .form {
    height: 40rem;
  }
  @media all and (max-width: 800px) {
    .form-portion {
      order: 1;
    }
    .text-portion {
      order: 2;
    }
  }
  @media all and (max-width: 569px) {
    .form {
      width: 88%;
      margin: 0px 6%;
      height: 43rem;
    }
  }
  @media all and (max-width: 494px) {
    .form {
      width: 102%;
      margin: 0px -1%;
      height: 43rem;
    }
  }
  @media all and (max-width: 428px) {
    .form {
      width: 118%;
      margin: 0px -9%;
      height: 43rem;
    }
  }
  @media all and (max-width: 372px) {
    .form {
      width: 124%;
      margin: 0px -12%;
      height: 43rem;
    }
  }
`;

const ContractorSearchResults = () => {
  const axiosPrivate = useAxiosPrivate();
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const [searchServices, setSearchServices] = useState(
    location?.state?.service
  );
  const [searchZipcode, setSearchZipcode] = useState(location?.state?.zipcode);

  const { auth } = useAuth();

  const [width, setWidth] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    handleResize();
    setTimeout(() => {
      handleResize();
    }, 1000);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [data, setData] = useState();

  const contractors = data?.contractors;
  const searchState = data?.search_query?.state;
  const searchCounty = data?.search_query?.county;

  const MapPinSvg = () => {
    return (
      <svg
        width="23"
        height="30"
        viewBox="0 0 23 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.48645 6.34268C1.79815 8.89797 1.36892 12.1753 3.14416 15.2418C5.85428 19.9233 9.25266 24.4713 11.1885 26.8814C13.1339 24.1731 16.6064 19.2796 19.3731 15.1832C21.4678 12.0818 21.0398 8.84766 19.2827 6.3243C17.4955 3.75778 14.3719 2 11.2402 2C8.12897 2 5.19026 3.7639 3.48645 6.34268ZM1.81777 5.24018C3.84789 2.16753 7.38135 0 11.2402 0C15.0785 0 18.7937 2.12222 20.924 5.18142C23.0842 8.28378 23.652 12.4211 21.0305 16.3026C17.8356 21.0331 13.7127 26.809 12.0503 29.1063C11.8699 29.3555 11.5849 29.5078 11.2775 29.5193C10.9701 29.5308 10.6745 29.4001 10.4761 29.1651C8.79074 27.1688 4.63718 21.8128 1.41327 16.2439C-0.794355 12.4304 -0.227869 8.33632 1.81777 5.24018Z"
          fill="#476D8A"
        />
        <circle cx="11.5002" cy="11.76" r="4.5" fill="#962B29" />
      </svg>
    );
  };

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    const getContractors = async () => {
      try {
        const response = await axiosPrivate.post(
          "search-contractors",
          JSON.stringify({ zipcode: searchZipcode, service: searchServices }),
          {
            signal: controller.signal,
          }
        );
        console.log(response.data);
        setData(response.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };

    getContractors();

    return () => controller.abort();
  }, []);

  const returnToSearch = () => {
    navigate("/");
  };

  // const categories = [
  //   "House cleaning",
  //   "Deck cleaning",
  //   "Drain cleaning",
  //   "Land clearing",
  //   "Drapery cleaning",
  //   "Green cleaning",
  //   "Gutter clearing",
  //   "Concrete clearing",
  // ];

  const exampleRating = 4.1;
  // const newRating = Math.floor(exampleRating);
  // // console.log("<MdOutlineStar />", <MdOutlineStar />);
  // const getRatingStars = (rating) => {
  //   let stars = [];
  //   for (let i = 0; i + 1 < rating; i++) {
  //     // Runs 5 times, with values of step 0 through 4.
  //     stars.push(<MdOutlineStar />);
  //   }
  //   // console.log("stars", stars);
  //   return stars;
  // };
  // getRatingStars(newRating);

  return (
    <>
      <Helmet>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event' : 'search',
            'searchTerm' : '${searchServices}'
          });
        `}
        </script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event' : 'zipSearch',
            'zipSearch' : '${searchZipcode}'
          });
        `}
        </script>
      </Helmet>

      <section>
        <article className="inner">
          <h1 className="searchResultsH1">Contractor Search Results</h1>
          <div className="searchParams">
            <p>Service: {searchServices}</p> | <p>Zipcode: {searchZipcode}</p> |{" "}
            <p>State: {searchState}</p> | <p>County: {searchCounty}</p>
          </div>

          {contractors?.length ? (
            <ul className="contractorList">
              <Container>
                {contractors
                  .sort((d1, d2) => d2.rating - d1.rating)
                  .map((contractor, i) => {
                    // console.log("contractor", contractor);
                    // console.log(
                    //   "contractor?.contractor_details?.service_categories",
                    //   contractor?.contractor_details?.service_categories
                    // );
                    const contractorCategories = contractor?.service_categories
                      ? JSON.parse(contractor?.service_categories)
                      : null;
                    // console.log("contractorCategories", contractorCategories);
                    return (
                      <>
                        {width < 776 ? (
                          <>
                            <Row className="mobileContractorHead">
                              <Col xs={5}>
                                <li
                                  key={i}
                                  className={`${
                                    contractor?.priority ? "featured" : null
                                  }`}
                                >
                                  <Link
                                    to={`/contractor/${contractor?.contractor_details?.slug}`}
                                    className="searchImageLink"
                                  >
                                    {contractor?.contractor_details
                                      ?.profile_picture ? (
                                      <div className="cover">
                                        <img
                                          src={`https://storage.iyh.app/${contractor?.contractor_details?.profile_picture}`}
                                          alt=""
                                        />
                                      </div>
                                    ) : null}
                                  </Link>
                                </li>
                              </Col>
                              <Col xs={7}>
                                <div className="titleButtonSection">
                                  <h2>
                                    <Link
                                      to={`/contractor/${contractor?.contractor_details?.slug}`}
                                    >
                                      {
                                        contractor?.contractor_details
                                          ?.business_name
                                      }
                                    </Link>
                                  </h2>
                                  {/* <br /> */}
                                  {/* <p className="ratingSection">
                                  {exampleRating}{" "}
                                  <span>{getRatingStars(exampleRating)}</span>
                                </p> */}
                                </div>
                                <div className="searchRatingSection">
                                  {contractor?.overall_rating &&
                                    parseInt(contractor?.overall_rating) >=
                                      3 && (
                                      <>
                                        <p className="searchRatingText">
                                          {contractor?.overall_rating}
                                        </p>
                                          <Rating
                                            name="read-only"
                                            value={parseInt(
                                              contractor?.overall_rating
                                            )}
                                            readOnly
                                          />
                                      </>
                                    )}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <p className="contractorDescription">
                                  {
                                    contractor?.contractor_details
                                      ?.business_about
                                  }
                                </p>

                                <p className="contractorLocation">
                                  <MapPinSvg />{" "}
                                  {contractor?.contractor_details?.city},{" "}
                                  {contractor?.contractor_details?.state}
                                </p>
                                {/* <Row>
                                  {contractorCategories.map((category) => {
                                    return (
                                      <Col
                                        xs={6}
                                        sm={4}
                                        md={3}
                                        className="categoryCol"
                                      >
                                        <div className="categoryTag">
                                          <p>{category}</p>
                                        </div>
                                      </Col>
                                    );
                                  })}
                                </Row> */}
                              </Col>
                            </Row>
                            <Row className="mobilbeButtonRow">
                              <Col className="mobileButtonCol">
                                <a
                                  className="button-red"
                                  href={`/contractor/${contractor?.contractor_details?.slug}`}
                                >
                                  View Profile <FaArrowRight />
                                </a>
                              </Col>
                            </Row>
                          </>
                        ) : (
                          <Row className="contractorRow">
                            <Col sm={3}>
                              <li
                                key={i}
                                className={`${
                                  contractor?.priority ? "featured" : null
                                } pictureLi`}
                              >
                                <Link
                                  to={`/contractor/${contractor?.contractor_details?.slug}`}
                                >
                                  {contractor?.contractor_details
                                    ?.profile_picture ? (
                                    <div className="cover">
                                      <img
                                        src={`https://storage.iyh.app/${contractor?.contractor_details?.profile_picture}`}
                                        alt=""
                                      />
                                    </div>
                                  ) : null}
                                </Link>
                              </li>
                            </Col>
                            <Col>
                              <div className="titleButtonSection">
                                <h2>
                                  <Link
                                    to={`/contractor/${contractor?.contractor_details?.slug}`}
                                  >
                                    {
                                      contractor?.contractor_details
                                        ?.business_name
                                    }
                                  </Link>
                                </h2>
                                <a
                                  className="button-red"
                                  href={`/contractor/${contractor?.contractor_details?.slug}`}
                                >
                                  View Profile <FaArrowRight />
                                </a>
                                {width >= 1000 && <br />}
                                <div className="searchRatingSection">
                                  {contractor?.overall_rating &&
                                    parseInt(contractor?.overall_rating) >=
                                      3 && (
                                      <>
                                        <p className="searchRatingText">
                                          {contractor?.overall_rating}
                                        </p>
                                        <Rating
                                          name="read-only"
                                          value={parseInt(
                                            contractor?.overall_rating
                                          )}
                                          readOnly
                                        />
                                      </>
                                    )}
                                </div>
                                {/* <p className="ratingSection">
                              {exampleRating}{" "}
                              <span>{getRatingStars(exampleRating)}</span>
                            </p> */}
                              </div>

                              <p className="contractorDescription">
                                {
                                  contractor?.contractor_details
                                    ?.business_about

                                }
                              </p>

                              <p className="contractorLocation">
                                <MapPinSvg />{" "}
                                {contractor?.contractor_details?.city},{" "}
                                {contractor?.contractor_details?.state}
                              </p>
                              {/* <Row className="categoryRow">
                                {contractorCategories.map((category) => {
                                  return (
                                    <Col sm={"auto"} className="categoryCol">
                                      <div className="categoryTag">
                                        <p>{category}</p>
                                      </div>
                                    </Col>
                                  );
                                })}
                              </Row> */}
                            </Col>
                          </Row>
                        )}
                      </>
                    );
                  })}
              </Container>
            </ul>
          ) : loading ? (
            <Loader />
          ) : (
            <EmptyResultWrap>
              <div className="text-portion">
                <h2>Let us connect you with a contractor</h2>
                <p>
                  ImproveYourHouse.com has just launched, we are adding quality
                  contractors everyday to help service your home improvement
                  needs! Fill out your information here and we will connect you
                  with a qualified vendor.
                </p>
              </div>
              <div className="form-portion">
                <script
                  type="text/javascript"
                  src="https://campaigns.zoho.com/js/zc.iframe.js"
                ></script>
                <iframe
                  frameborder="0"
                  id="iframewin"
                  width="100%"
                  height="80%"
                  src="https://zach-cmpzourl.maillist-manage.com/ua/Optin?od=11287eca745efd&zx=131285ca5&lD=132cc949d3474306&n=11699f74d6ba6f6&sD=132cc949d34742e1"
                  className="form"
                ></iframe>
              </div>
            </EmptyResultWrap>
          )}
        </article>
      </section>
    </>
  );
};

export default ContractorSearchResults;
