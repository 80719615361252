import usePageTitle from '../../hooks/usePageTitle';
import LegalPage from '../../components/LegalPage';

const Project = () => {
	usePageTitle("Project Submission Customer Agreement");

	return (
		<LegalPage title='Project Submission Customer Agreement' slug='project-submission-customer-agreement' />
	)
}

export default Project