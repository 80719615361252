import { useState, useEffect } from 'react'
import usePageTitle from '../hooks/usePageTitle'
import Page from '../components/Page'
import Contractors from '../components/Contractors'
import ContractorCategoryBlock from '../components/ContractorCategoryBlock'
import axios from 'axios'
import useAuth from '../hooks/useAuth'
import { useOutletContext } from "react-router-dom";

const Home = () => {

	usePageTitle("Home");

	const { auth } = useAuth();
	const isLoggedIn = auth?.user ? true : false

	const [geoLocation, setGeoLocation] = useOutletContext()
	const [loading, setLoading] = useState(false)

	const [ip, setIP] = useState()
	const [city, setCity] = useState()
	const [zip, setZip] = useState()
	const [startingService, setStartingService] = useState("")

	useEffect(() => {
		if (geoLocation){
			setIP(geoLocation?.ip_address)
			setCity(geoLocation?.city)
			setZip(geoLocation?.postal_code)
		}
	},[geoLocation])

	useEffect(() => {
		if (window) {
			const hash = window?.location?.hash
			const updatadHash = hash.slice(1)
			setStartingService(updatadHash)
		}
	},[])

	return (
		<section>
			<Page slug='home' userCity={city} startingService={startingService}/>
			<Contractors userZip={zip} userCity={city}/>
		</section>
	)
}

export default Home