import { Link, useNavigate } from 'react-router-dom';
import { useState } from "react";
import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const MetricsMenu = () => {

	const navigate = useNavigate()
	const [defaultMetric, setDefaultMetric] = useState("/admin-dashboard/metrics?metric=user")

	const handleSelectChange = (e) => {
		const value = e.target.value
		setDefaultMetric(value)
		navigate(value, { replace: true })
	}

	return (
		<div className="selectContainer">
			<div className="selectWrapper">
				<Box>
					<FormControl fullWidth>
						<InputLabel id="demo-simple-select-label">Select Metric</InputLabel>
						<Select
				  		labelId="demo-simple-select-label"
				  		id="demo-simple-select"
				  		label="Select Metric"
				  		onChange={handleSelectChange}
						value={defaultMetric}
						>
					  		<MenuItem value="/admin-dashboard/metrics?metric=user">User</MenuItem>
					  		<MenuItem value="/admin-dashboard/metrics?metric=services">Service Categories</MenuItem>
					  		<MenuItem value="/admin-dashboard/metrics?metric=projects">Projects</MenuItem>
						</Select>
			  		</FormControl>
				</Box>
			</div>
		</div>
	)
}

export default MetricsMenu