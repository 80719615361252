import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';
import { FaExclamation } from 'react-icons/fa';
import usePageTitle from '../hooks/usePageTitle';
import UserPageNav from '../components/UserPageNav';
import useAuth from '../hooks/useAuth';
import { ToastContainer, toast } from 'react-toastify';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useRefreshToken from '../hooks/useRefreshToken';
import SingleFileDropEmbed from '../components/SingleFileDropEmbed';


const NAME_REGEX = /^[a-zA-Z0-9-_!?%'#$,:; ]{1,50}$/;
const DETAILS_REGEX = /^[a-zA-Z0-9-_!?%'#$,:; ]{0,500}$/;

const AdminCreateUserPromotion = ({ addPromotion, userID, businessName, setOpenCreatePromotion }) => {

	const { auth } = useAuth();
	let isVerified = auth?.user?.email_verified_at;

	const axiosPrivate = useAxiosPrivate();
	const refresh = useRefreshToken();

	const nameRef = useRef();
	const detailsRef = useRef();

	const [name, setName] = useState("");
	const [validName, setValidName] = useState(false);
	const [nameFocus, setNameFocus] = useState(false);

	const [details, setDetails] = useState("");
	const [validDetails, setValidDetails] = useState(false);
	const [detailsFocus, setDetailsFocus] = useState(false);

	const [status, setStatus] = useState("draft");
	const [validStatus, setValidStatus] = useState(false);
	const [statusFocus, setStatusFocus] = useState(false);

	const [files, setFiles] = useState([]);
	const [editImages, setEditImages] = useState();
	const [validFiles, setValidFiles] = useState(false);
	const [fileLimit, setFileLimit] = useState(1);
	const [buttonTitle, setButtonTitle] = useState("Promotion Image");

	/* Set Focus to name on load */
	useEffect(() => {
		nameRef.current.focus()
	}, [])

	/* Check Name */
	useEffect(() => {
		const result = NAME_REGEX.test(name);
		setValidName(result);
	}, [name])

	/* Check Description */
	useEffect(() => {
		const result = DETAILS_REGEX.test(details);
		setValidDetails(result);
	}, [details])

	useEffect(() => {
		const currentCount = files?.length;
		const addCount = editImages?.length;
		const count = currentCount + addCount;

		if (fileLimit){
			count <= fileLimit ? setValidFiles(true) : setValidFiles(false);
		}

		const formData = new FormData();
		console.log(files)
		files?.map((file, index) => {
			formData.append('files[]', file);
		});

	}, [files])

	const handleSubmit = async (e) => {
		e.preventDefault();

		const dismiss = () =>  toast.dismiss(createPromotionToast.current);
		const createPromotionToast = toast.loading("Adding Promotion");
		const controller = new AbortController();

		const formData = new FormData();
		console.log(files)
		files.map((file, i) => {
			formData.append('files[]', file);
		});

		formData.append('name', name)
		formData.append('details', details)
		formData.append('status', status)
		formData.append('id', userID)

		try {
			const response = await axiosPrivate.post('admin-promotion',
			formData,
			{
				signal: controller.signal
			});

			// Get promotion id from database
			const id = response?.data?.promotion?.id;

			// Get date from database
			const created_at = response?.data?.promotion.created_at;

			// Get status from database
			const status = response?.data?.promotion.status;

			// Get image from database
			const image = response?.data?.promotion.image;

			// Add promotion to promotions
			addPromotion({id, name, details, status, image, created_at});

			// Clear Form
			setName("");
			setDetails("");
			setFiles([]);

			// Close parent popup
			setOpenCreatePromotion(false)

			// Update Toast Notification
			toast.update(createPromotionToast, { render: 'Promotion Added', type: 'success', isLoading: false, autoClose: 5000});
		} catch (err) {
			if (!err?.response) {
				toast.update(createPromotionToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else if (err.response?.status === 401) {
				toast.update(createPromotionToast, { render: 'Authorization Failed', type: 'error', isLoading: false});
				{/* setErrMsg('The email has already been taken.'); */}
			} else {
				toast.update(createPromotionToast, { render: 'Promotion Creation Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	return (
		<>
			<h3>Creating Promotion for {businessName ? businessName : 'n/a'}</h3>
			<form id="createPromotion" onSubmit={handleSubmit} encType="multipart/form-data">
				{/* Status */}
				<label htmlFor="status">Status:</label>
				<div className="inputWrapper">
					<select
						id="status"
						onChange={(e) => setStatus(e.target.value)}
						value={status}
						required
						onFocus={() => setStatusFocus(true)}
						onBlur={() => setStatusFocus(false)}
					>
						<option value="draft">Draft</option>
						<option value="active">Active</option>
						<option value="archived">Archived</option>
					</select>
				</div>

				{/* Name */}
				<label htmlFor="name">Promotion Name:</label>
				<div className="inputWrapper">
					<span className={validName ? "valid" : "hide"}><FaCheck /></span>
					<span className={validName || !name ? "hide" : "invalid"}><FaExclamation /></span>
					<input
						type="text"
						id="name"
						className={validName || !name ? null : "error"}
						ref={nameRef}
						autoComplete="off"
						onChange={(e) => setName(e.target.value)}
						value={name}
						aria-invalid={validName ? "false" : "true"}
						aria-describedby="namenote"
						onFocus={() => setNameFocus(true)}
						onBlur={() => setNameFocus(false)}
					/>
				</div>
				<p id="namenote" className={nameFocus && name && !validName ? "instructions" : "offscreen"}>
					Invalid Promotion Name. 1-50 characters (letters, numbers, and special characters (-_.!?%'#$,:;) allowed)
				</p>

				{/* Description */}
				<label htmlFor="details">Details:</label>
				<div className="inputWrapper">
					<span className={validDetails ? "valid" : "hide"}><FaCheck /></span>
					<span className={validDetails || !details ? "hide" : "invalid"}><FaExclamation /></span>
					<textarea
						ref={detailsRef}
						onChange={(e) => setDetails(e.target.value)}
						name="details"
						id="details"
						rows="5"
						value={details}
					>
					</textarea>
					<p id="descriptionnote" className={detailsFocus && details && !validDetails ? "instructions" : "offscreen"}>
						Invalid Description. Between 1-500 characters (letters, numbers, and special characters (-_.!?%'#$,:;) allowed)
					</p>
				</div>
				{/* File Uploader */}
				<SingleFileDropEmbed buttonTitle={buttonTitle} files={files} setFiles={setFiles} validFiles={validFiles} setValidFiles={setValidFiles} accept="image/*"/>
				<button disabled={!validName || !validDetails ? true : false}>Add Promotion</button>
			</form>
			{/* <ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
			/> */}
		</>
	)
}

export default AdminCreateUserPromotion