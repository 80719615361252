import { useState, useEffect, useRef } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import { AiFillDelete } from 'react-icons/ai';
import { GrFormEdit } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import Loader from './Loader';
import { MdStar } from 'react-icons/md'
import { Slider } from '@contentco/react-scroll-snap-slider'

const Contractors = ({ userZip, userCity }) => {

	const [loading, setLoading] = useState(false);
	const [contractors, setContractors] = useState();
	const [featured, setFeatured] = useState();
	const axiosPrivate = useAxiosPrivate();

	const gallerySliderRef = useRef()
	const [activeSlide, setActiveSlide] = useState(0)
	const [index, setIndex] = useState(0)

	const navigate = useNavigate();
	const location = useLocation();

	const auth = useAuth();

	const [url, setURL] = useState('contractors')

	useEffect(() => {
		let curl = "contractors"

		if(userZip){
			// setURL("contractors/" + userZip)
			curl = curl + "/" + userZip
			console.log("zipcode defined lets add it to the url")
		} else {
			console.log("no zipcode defined")
		}
		const controller = new AbortController();
		setLoading(true);
		const getContractors = async () => {
			try {
				const response = await axiosPrivate.get(curl, {
					signal: controller.signal
				});
				console.log(response.data);
				setContractors(response.data.contractors);
				setFeatured(response.data.featured);
				setLoading(false)
			} catch(err) {
				console.log(err.message);
				!auth && navigate('/login', { state: { from: location }, replace: true });
			}
		}

		getContractors();

		return () => controller.abort();

	}, [userZip])

	useEffect(() => {


		// eslint-disable-next-line
	},[])

	function formatTelLink(phone){
		//Filter only numbers from the input
		let cleaned = ('' + phone).replace(/\D/g, '');
		  return cleaned
	}

	function formatPhone(phone){
		//Filter only numbers from the input
		  let cleaned = ('' + phone).replace(/\D/g, '');

		  //Check if the input is of correct length
		  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

		  if (match) {
			return '(' + match[1] + ') ' + match[2] + '-' + match[3]
		  };

		  return null
	}

	const changeGalleryIndex = (i) => {
		gallerySliderRef.current?.scrollToSlide(i)
		setActiveSlide(i)
	}

	const onGallerySlidesVisibilityChange = (i: number) => {
		setActiveSlide(i)
	}

	return (
		<>
		<section>
			<article className="spotlight">
				<h2>PRO Spotlight</h2>
				<div className="featuredContractor">
					<div className="wrapper">
							{featured?.length ?
								<>
									<Slider
										ref={gallerySliderRef}
										onSlidesVisibilityChange={onGallerySlidesVisibilityChange}
									>
									{featured?.map((feature, i) => {

									return (
										<Link to={`/contractor/${feature?.slug}`}>
										{feature?.cover_picture ? (<div className="cover"><img src={`https://storage.iyh.app/${feature?.cover_picture}`} alt="" /></div>) : null }
										<div className="contentWrapper">
											<div className="content">
													<div className="title"><h4><strong>Meet</strong> {feature?.business_name}</h4></div>
												{feature?.contractor_specialty ? <div className="specialty">Specialty: {feature?.contractor_specialty}</div> : ''}
												<div className="location">Location: {feature?.city}, {feature?.state}</div>
												<div className="phone">Contact: {formatPhone(feature?.provision_number)}</div>
												{/* <div className="bio">{feature?.business_description}</div> */}
											</div>
										</div>
										</Link>
									)
									}
									)}
									</Slider>


								</>
								:
								<p className="center">No featured contractors available in this location</p>
							}

							<div className="pagination">
								{featured?.map((feature, i) =>
									<span onClick={(e) => changeGalleryIndex(i)} className={`bullet ${i === activeSlide ? 'active' : ''}`}></span>
								)}
							</div>

					</div>
				</div>
			</article>
		</section>

		<section className='contractorCategoryBlock'>
			<article className='inner'>
				{userCity ? <h3>Local Contractors Around {userCity}</h3> : <h3>Find Local Contractors</h3> }
				{contractors?.length
					? (
						<ul className="contractorList">
							{contractors.map((contractor, i) =>
								<li key={i}>
									<Link to={`/contractor/${contractor?.slug}`}>
										{contractor?.cover_picture ? (
											<div className="cover">
												<img src={`https://storage.iyh.app/${contractor?.cover_picture}`} alt="" />
											</div>
										)
										:
										null }
										<div className="businessInfo">
											<div className="business_name">
												{contractor?.business_name ?
													contractor?.business_name
													:
													"Business Name N/A"
												}
											</div>
											{contractor?.rating !== "0.0" ?
												<div className="business_rating">
													{contractor?.rating}<MdStar />
												</div>
												:
												null
											}
										</div>
									</Link>
								</li>
							)}
						</ul>
					) :
						loading ?
						<Loader />
						:
						<p>Sorry, there are no contractors available at this time.</p>
				}
			</article>
		</section>

		</>
	)
}

export default Contractors