import { useRef, useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { ToastContainer, toast } from "react-toastify";
import Rating from "@mui/material/Rating";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const SubmitReview = ({ name, contractor_id, setOpen }) => {
  const axiosPrivate = useAxiosPrivate();

  const { auth } = useAuth();
  const jobs = auth?.jobs;

  const emailRef = useRef();
  const reviewRef = useRef();
  const errRef = useRef();
  const titleRef = useRef();

  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const [review, setReview] = useState("");
  const [rating, setRating] = useState("");
  const [title, setTitle] = useState("");

  const [associatedProjectID, setAssociatedProjectID] = useState();

  const [commentText, setCommentText] = useState("");

  useEffect(() => {
    titleRef.current.focus();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log(rating);
  }, [rating]);

  useEffect(() => {
    console.log(associatedProjectID);
  }, [associatedProjectID]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dismiss = () => toast.dismiss(reviewToast.current);
    const reviewToast = toast.loading("Submitting Review");
    try {
      const response = await axiosPrivate.post(
        "review",
        JSON.stringify({
          contractor_id: contractor_id,
          contractor_name: name,
          review_text: review,
          rating: rating,
          review_title: title,
          private_homeower_comment: commentText,
          status: "pending",
          associated_project_id: associatedProjectID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          withCredentials: true,
        }
      );

      // Update Toast Notification
      toast.update(reviewToast, {
        render: "Review Submitted",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });

      // Close Alert Window
      setOpen(false);
    } catch (err) {
      if (!err?.response) {
        toast.update(reviewToast, {
          render: "No Server Response",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        {
          /* setErrMsg('No Server Response'); */
        }
      } else {
        toast.update(reviewToast, {
          render: "Review Failed",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        {
          /* setErrMsg('Login Failed') */
        }
      }
      {
        /* errRef.current.focus(); */
      }
    }
  };

  return (
    <section>
      <p>
        Your review will be reviewed by {name}, if they don't approve or submit
        it for review by an admin it will post in 7 days.
      </p>
      <form onSubmit={handleSubmit}>
        {/* Rating */}
        <label htmlFor="rating">Rating:</label>
        <Rating
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
        />

        {/* Title */}
        <label htmlFor="title">Review Title:</label>
        <div className="inputWrapper">
          <input
            type="text"
            id="license"
            ref={titleRef}
            autoComplete="off"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
        </div>

        {/* Job */}
        <label htmlFor="review">Does this apply one of your projects?</label>
        <p className="note">(If yes select the project by name)</p>
        <select
          name="job"
          id="job"
          value={associatedProjectID}
          onChange={(e) => setAssociatedProjectID(e.target.value)}
        >
          <option value="">None</option>
          {jobs && jobs?.length ? (
            jobs.map((job, i) => <option value={job.id}>{job.name}</option>)
          ) : (
            <></>
          )}
        </select>

        {/* Review */}
        <label htmlFor="review">Review:</label>
        <div className="inputWrapper">
          <textarea
            ref={reviewRef}
            onChange={(e) => setReview(e.target.value)}
            name="review"
            id="review"
            rows="5"
            value={review}
          ></textarea>
        </div>

        {/* Private Comment */}
        {rating < 3 && (
          <>
            <label htmlFor="comment">Private Admin Comment:</label>
            <span className="commentInstructions">
              Poor ratings are often sent for review by the contractor. Please
              provide detailed information of your experience for our review
              process. If you don't fill this out and the contractor explains
              their side the review may not post.
            </span>
            <div className="inputWrapper">
              <textarea
                onChange={(e) => setCommentText(e.target.value)}
                name="comment"
                id="comment"
                rows="5"
                value={commentText}
              ></textarea>
            </div>
          </>
        )}

        <button>Submit Review</button>
      </form>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </section>
  );
};

export default SubmitReview;
