import { useEffect, useState } from "react";
import { NavLink, Link, useNavigate } from 'react-router-dom';
import useLogout from '../hooks/useLogout';
import { AiOutlineDashboard } from 'react-icons/ai'
import { CgProfile } from 'react-icons/cg'
import { MdAccountBox } from 'react-icons/md'
import { FaUserCircle } from 'react-icons/fa'
import { GiStarsStack } from 'react-icons/gi'
import { AiOutlineBarChart } from 'react-icons/ai'
import { HiChartSquareBar } from 'react-icons/hi'
import { ImBullhorn } from 'react-icons/im';
import { MdDashboard } from 'react-icons/md';
import { RiCustomerService2Line } from 'react-icons/ri';
import { MdHelpCenter } from 'react-icons/md';
import { AiFillMessage } from 'react-icons/ai';
import { MdMessage } from 'react-icons/md';
import { MdFavorite } from 'react-icons/md';
import { MdFactCheck } from 'react-icons/md';
import { MdRequestPage } from 'react-icons/md';
import { MdSettings } from 'react-icons/md';
import { MdWork } from 'react-icons/md';
import { MdAdsClick } from 'react-icons/md';
import { MdExplore } from 'react-icons/md';
import { MdDonutSmall } from 'react-icons/md';
import { MdInsertChart } from 'react-icons/md';
import { MdLocalOffer } from 'react-icons/md';
import { MdPeopleAlt } from 'react-icons/md';
import { FaBoxOpen } from 'react-icons/fa';
import { MdLogout } from 'react-icons/md';
import { MdHandyman } from 'react-icons/md';
import { MdAssignmentInd } from 'react-icons/md';
import { TbDiscount2 } from 'react-icons/tb';
import { GoLaw } from 'react-icons/go';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useMediaQuery } from 'react-responsive'
import Cookies from 'js-cookie'

const AdminMenu = () => {

	const navigate = useNavigate();
	const exit = useLogout();

	const signOut = async () => {
		Cookies.remove("iyh-can-refresh")
		await exit();
		// navigate('/');
	}

	const [isBackendMenuOpen, setIsBackendMenuOpen] = useState(false)

	const isBackendMobileMenu = useMediaQuery({
		query: '(max-width: 1050px)'
	})

	return (
		<>
			{isBackendMobileMenu &&
				<button className={`backendMobileMenu ${isBackendMenuOpen ? 'open' : null}`} onClick={(e) => setIsBackendMenuOpen(!isBackendMenuOpen)}><GiHamburgerMenu /> {isBackendMenuOpen ? 'Close Menu' : 'Admin Menu'}</button>
			}
			<nav className={`${isBackendMobileMenu ? 'mobile' : null} ${isBackendMobileMenu && isBackendMenuOpen ? 'open' : null}`} id="admin-nav">
				<ul>
					<li><NavLink to="/admin-dashboard/"><MdDashboard />Dashboard</NavLink></li>
					<li><NavLink to="/admin-dashboard/tickets"><MdLocalOffer />Tickets</NavLink></li>
					<li><NavLink to="/admin-dashboard/invites"><MdAssignmentInd />Invite Requests</NavLink></li>
					<li><NavLink to="/admin-dashboard/users"><MdPeopleAlt />Users</NavLink></li>
					<li><NavLink to="/admin-dashboard/projects"><MdHandyman />Projects</NavLink></li>
					{/* <li><NavLink to="/admin-dashboard/reviews"><GiStarsStack />Reviews</NavLink></li> */}
					<li><NavLink to="/admin-dashboard/markets"><MdAdsClick />Markets</NavLink></li>
					<li><NavLink to="/admin-dashboard/zip-codes"><MdExplore />Zip Codes</NavLink></li>
					<li><NavLink to="/admin-dashboard/service-categories"><MdDonutSmall />Service Categories</NavLink></li>
					<li><NavLink to="/admin-dashboard/packages"><FaBoxOpen />Packages</NavLink></li>
					<li><NavLink to="/admin-dashboard/coupons"><TbDiscount2 />Coupons</NavLink></li>
					<li><NavLink to="/admin-dashboard/metrics"><MdInsertChart />Metrics</NavLink></li>
					<li><NavLink to="/admin-dashboard/legal"><GoLaw />Legal</NavLink></li>
					<li onClick={signOut} className="logout"><MdLogout />Logout</li>
				</ul>
			</nav>
		</>
	)
}

export default AdminMenu