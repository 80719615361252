import usePageTitle from '../../hooks/usePageTitle';
import LegalPage from '../../components/LegalPage';

const Sell = () => {
	usePageTitle("Do Not Sell Policy");

	return (
		<LegalPage title='Do Not Sell Policy' slug='do-not-sell-policy' />
	)
}

export default Sell