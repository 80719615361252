import AdminMenu from '../../components/AdminMenu';
import Jobs from '../../components/Jobs';
import { MdWork } from 'react-icons/md'
import { MdHandyman } from 'react-icons/md'

const AdminJobs = () => {
	return (
		<section>
			<article className="inner">
				<div className="backendTitleBar">
					<h1>Homeowner Projects</h1>
					<MdHandyman />
				</div>
				<div className="backendColumnWrapper">
					<div id="menu" className="column">
						<AdminMenu />
					</div>
					<div id="content" className="column">
						<Jobs />
					</div>
				</div>
			</article>
		</section>
	)
}

export default AdminJobs