import { useRef, useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import ContractorMenu from "./ContractorMenu"
import HomeownerMenu from "./HomeownerMenu"
import { ToastContainer, toast } from 'react-toastify';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
const EMAIL_VERIFICATION_URL = 'email/verification-notification';

const UserPageNav = ({ title }) => {

	const axiosPrivate = useAxiosPrivate();

	const emailRef = useRef();
	const errRef = useRef();

	const [email, setEmail] = useState('');
	const [errMsg, setErrMsg] = useState('');

	const { auth } = useAuth();
	const [isVerified, setIsVerified] = useState(false)
	let checkVerified = auth?.user?.email_verified_at;
	let name = auth?.user?.first_name;

	useEffect(() => {
		setEmail(auth?.user?.email);
		checkVerified ? setIsVerified(true) : setIsVerified(false);
	}, [auth])

	const handleSubmit = async () => {
		try {
			const response = await axiosPrivate.post(EMAIL_VERIFICATION_URL, {
				withCredentials: true
			});
			setIsVerified(true);
			toast.success("Email Sent!");
		} catch (err) {
			if (!err?.response){
				toast.error('No Server Response')
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.error('Reset Failed')
				{/* setErrMsg('Reset Failed') */}
			}
			{/* errRef.current.focus(); */}
		}
	}

	return (
		<>
			{isVerified ? (
				<>
					{auth.user.role == 1001 && <HomeownerMenu />}
					{auth.user.role == 2002 && <ContractorMenu />}
					<h1>{title}</h1>
				</>
			)
				:
				<>
					<p>Please verify your email to edit your profile.</p>
					<p>Didn't get an email? Try resending your verfication.</p>
					<button onClick={handleSubmit}>Resend Verification</button>
				</>
			}
		</>
	)
}

export default UserPageNav