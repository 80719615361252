import useFormContext from "../hooks/useFormContext"
import PaymentForm from '../components/PaymentForm'
import { useRef, useState, useEffect } from 'react';

const FormStepPayment = () => {

	const { data, handleChange } = useFormContext()

	const monthlyCharges = data.monthly_charges
	const onetimeCharge = data.one_time_charges
	const canSubmit = data.canSubmit

	useEffect(() => {
		console.log(canSubmit)
		console.log(data)
	}, [data])

	return (
		<>
			<PaymentForm monthlyCharges={monthlyCharges} onetimeCharge={onetimeCharge} />
		</>
	)
}

export default FormStepPayment