import { useRef, useState, useEffect } from 'react';
import usePageTitle from '../hooks/usePageTitle';
import { ToastContainer, toast } from 'react-toastify';
import axios from '../api/axios';
const PASSWORD_RESET_URL = 'forgot-password';

const ForgotPassword = () => {

	usePageTitle("Forgot Password");
	
	const emailRef = useRef();
	const errRef = useRef();
	
	const [email, setEmail] = useState('');
	const [errMsg, setErrMsg] = useState('');
	
	useEffect(() => {
		emailRef.current.focus();
	}, [])
	
	useEffect(() => {
		setErrMsg('');
	}, [email])
	
	const handleSubmit = async (e) => {
		e.preventDefault();
		
		try {
			const response = await axios.post(PASSWORD_RESET_URL, 
				JSON.stringify({email: email}),
				{
					headers: { 
						'Content-Type': 'application/json',
						'Accept': 'application/json'
					},
					withCredentials: true
				}
			);
			toast.success("Email Sent!");
		} catch (err) {
			if (!err?.response){
				toast.error('No Server Response')
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.error('Reset Failed')
				{/* setErrMsg('Reset Failed') */}
			}
			{/* errRef.current.focus(); */}
		}
	}
	
	return (
		<section>
			<article className='inner'>
				<h1>Forgot Password?</h1>
				<p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
				<p>Please enter your email address.<br />You will receive an email message with instructions on how to reset your password.</p>
				<form onSubmit={handleSubmit}>
					<label htmlFor="email">Email:</label>
					<input
						type="email"
						id="email"
						ref={emailRef}
						autoComplete="off"
						onChange={(e) => setEmail(e.target.value)}
						value={email}
						required
					/>
					<button>Reset Password</button>
				</form>
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="colored"
					/>
			</article>
		</section>
	)
}

export default ForgotPassword