import axios from 'axios';
let url = window.location.href;
//const BASE_URL = 'https://api.improveyourhouse.com/v1/';
let base_url = 'https://api.improveyourhouse.com/v1/';
if (url.indexOf('improveyourhouse.com') > -1) {
	base_url = 'https://api.improveyourhouse.com/v1/';
}else {
	base_url = 'http://staging-env.eba-dpspw4bj.us-west-2.elasticbeanstalk.com/v1/';
}

export default axios.create({
	baseURL: base_url,
	headers: {
		'Content-Type': 'application/json',
		'Accept': 'application/json'
	}
});

export const axiosPrivate = axios.create({
	baseURL: base_url,
	headers: {
		'Content-Type': 'application/json',
	},
	withCredentials: true
});


export const axiosBlank = axios.create({
	baseURL: '',
	headers: { 'Content-Type': 'application/json'},
});