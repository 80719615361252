import usePageTitle from '../../hooks/usePageTitle';
import LegalPage from '../../components/LegalPage';

const Membership = () => {
	usePageTitle("Membership Agreement");

	return (
		<LegalPage title='Membership Agreement' slug='membership-agreement' />
	)
}

export default Membership