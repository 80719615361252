import usePageTitle from '../hooks/usePageTitle';
import Page from '../components/Page';
import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import { ToastContainer, toast } from 'react-toastify';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { FaCheck } from 'react-icons/fa';
import axios from '../api/axios';
import { FaExclamation } from 'react-icons/fa';
import { MdNotifications } from 'react-icons/md';

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const NAME_REGEX = /^[a-zA-Z ]{2,40}$/;
const MESSAGE_REGEX = /^[a-zA-Z0-9,.#&%!$'()/ ]{2,10000}$/;
const PHONE_REGEX = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
const BUSINESS_REGEX = /[^\s]{1,100}$/;

const Contact = () => {

	usePageTitle("Contact Us");
	const location = useLocation()
	const navigate = useNavigate()
	const axiosPrivate = useAxiosPrivate();
	const { auth } = useAuth();
	const [loading, setLoading] = useState(false)
	const [errMsg, setErrMsg] = useState()

	const businessNameRef = useRef();
	const contactNameRef = useRef();
	const emailRef = useRef();
	const phoneRef = useRef();

	const [formSent, setFormSent] = useState(false)
	const [markets, setMarkets] = useState()

	const [market, setMarket] = useState()
	const [marketFocus, setMarketFocus] = useState()

	const [reason, setReason] = useState()
	const [reasonFocus, setReasonFocus] = useState(false)

	const [businessName, setBusinessName] = useState('');
	const [validBusinessName, setValidBusinessName] = useState(false);
	const [businessNameFocus, setBusinessNameFocus] = useState(false);

	const [contactName, setContactName] = useState('');
	const [validContactName, setValidContactName] = useState(false);
	const [contactNameFocus, setContactNameFocus] = useState(false);

	const [email, setEmail] = useState('');
	const [validEmail, setValidEmail] = useState(false);
	const [emailFocus, setEmailFocus] = useState(false);

	const [phone, setPhone] = useState('');
	const [validPhone, setValidPhone] = useState(false);
	const [phoneFocus, setPhoneFocus] = useState(false);

	const [message, setMessage] = useState('');
	const [validMessage, setValidMessage] = useState(false);
	const [messageFocus, setMessageFocus] = useState(false);

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getPackages = async () => {
			try {
				const response = await axiosPrivate.get('get-active-markets', {
					signal: controller.signal
				});
				setMarkets(response.data);
				setLoading(false)
			} catch(err) {
				console.log(err.message);
				!auth && navigate('/login', { state: { from: location }, replace: true });
			}
		}

		getPackages();

		return () => controller.abort();
		// eslint-disable-next-line
	},[])


	/* Check username against regex function on username change */
	useEffect(() => {
		const result = EMAIL_REGEX.test(email);
		setValidEmail(result);
	}, [email])

	/* Check Business Name */
	useEffect(() => {
		const result = BUSINESS_REGEX.test(businessName);
		setValidBusinessName(result);
	}, [businessName])

	/* Check Contact Name */
	useEffect(() => {
		const result = NAME_REGEX.test(contactName);
		setValidContactName(result);
	}, [contactName])

	/* Check Phone */
	useEffect(() => {
		const result = PHONE_REGEX.test(phone);
		setValidPhone(result);
	}, [phone])

	/* Check Message */
	useEffect(() => {
		const result = MESSAGE_REGEX.test(message);
		setValidMessage(result);
	}, [message])

	const submitContactForm = async (e) => {
		e.preventDefault();

		// Prevent Hacking of submit button by checking against regex again
		const v1 = EMAIL_REGEX.test(email);
		const v3 = PHONE_REGEX.test(phone);

		if (!v1 || !v3){
			setErrMsg("Invalid Entry");
			return;
		}

		const dismiss = () =>  toast.dismiss(contactFormToast.current);
		const contactFormToast = toast.loading("Sending Message");
		try {
			const response = await axios.post('send-contact',
				JSON.stringify({contact: contactName, business: businessName, phone, email, market, message, reason}),
				{
					headers: { 'Content-Type': 'application/json'},
					withCredentials: true
				}
			);

			// Clear Form
			setContactName("")
			setMessage(null)
			setMarket("")
			setReason("")
			setBusinessName("monthly")
			setPhone("active")
			setEmail("active")

			// Hide form on success
			setFormSent(true)

			// Update Toast Notification
			toast.update(contactFormToast, { render: 'Message Sent', type: 'success', isLoading: false, autoClose: 5000});
		} catch (err) {
			if (!err?.response) {
				toast.update(contactFormToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else if (err.response?.status === 401) {
				toast.update(contactFormToast, { render: 'Authorization Failed', type: 'error', isLoading: false});
				{/* setErrMsg('The email has already been taken.'); */}
			} else {
				toast.update(contactFormToast, { render: 'Message Send Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
	}

	return (
		<section>
			<Page title='Contact Us' slug='contact-us' />

			<article className="inner content">
				{formSent ?
				<p className="mailNote"><MdNotifications />Contact form has been sent. Someone will get back to you as soon as they can.</p>
				:
				<form onSubmit={submitContactForm}>

					{/* Business Name */}
					<label htmlFor="businessName">Business Name</label>
					<div className="inputWrapper">
						<span className={validBusinessName ? "valid" : "hide"}><FaCheck /></span>
						<span className={validBusinessName || !businessName ? "hide" : "invalid"}><FaExclamation /></span>
						<input
							type="text"
							id="businessName"
							ref={businessNameRef}
							autoComplete="off"
							onChange={(e) => setBusinessName(e.target.value)}
							value={businessName}
							aria-invalid={validBusinessName ? "false" : "true"}
							aria-describedby="businessnamenote"
							onFocus={() => setBusinessNameFocus(true)}
							onBlur={() => setBusinessNameFocus(false)}
						/>
					</div>
					<p id="businessnamenote" className={businessNameFocus && businessName && !validBusinessName ? "instructions" : "offscreen"}>
						Invalid business name.
					</p>

					{/* Contact Name */}
					<label htmlFor="contactName">Contact Name</label>
					<div className="inputWrapper">
						<span className={validContactName ? "valid" : "hide"}><FaCheck /></span>
						<span className={validContactName || !contactName ? "hide" : "invalid"}><FaExclamation /></span>
						<input
							type="text"
							id="contactName"
							className={validContactName || !contactName ? null : "error"}
							ref={contactNameRef}
							autoComplete="off"
							onChange={(e) => setContactName(e.target.value)}
							value={contactName}
							required
							aria-invalid={validContactName ? "false" : "true"}
							aria-describedby="contactdnote"
							onFocus={() => setContactNameFocus(true)}
							onBlur={() => setContactNameFocus(false)}
						/>
					</div>
					<p id="contactdnote" className={contactNameFocus && contactName && !validContactName ? "instructions" : "offscreen"}>
						Invalid contact name.
					</p>

					{/* Email */}
					<label htmlFor="email">Email:</label>
					<div className="inputWrapper">
						<span className={validEmail ? "valid" : "hide"}><FaCheck /></span>
						<span className={validEmail || !email ? "hide" : "invalid"}><FaExclamation /></span>
						<input
							type="email"
							id="email"
							className={validEmail || !email ? null : "error"}
							ref={emailRef}
							autoComplete="off"
							onChange={(e) => setEmail(e.target.value)}
							required
							aria-invalid={validEmail ? "false" : "true"}
							aria-describedby="uidnote"
							onFocus={() => setEmailFocus(true)}
							onBlur={() => setEmailFocus(false)}
						/>
					</div>
					<p id="uidnote" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}>
						Invalid email address.
					</p>

					{/* Phone */}
					<label htmlFor="phone">Phone:</label>
					<div className="inputWrapper">
						<span className={validPhone ? "valid" : "hide"}><FaCheck /></span>
						<span className={validPhone || !phone ? "hide" : "invalid"}><FaExclamation /></span>
						<input
							type="tel"
							id="phone"
							className={validPhone || !phone ? null : "error"}
							ref={phoneRef}
							autoComplete="off"
							onChange={(e) => setPhone(e.target.value)}
							required
							aria-invalid={validPhone ? "false" : "true"}
							aria-describedby="phonenote"
							onFocus={() => setPhoneFocus(true)}
							onBlur={() => setPhoneFocus(false)}
						/>
					</div>
					<p id="phonenote" className={phoneFocus && phone && !validPhone ? "instructions" : "offscreen"}>
						Invalid phone number.
					</p>

					{/* Market */}
					<label htmlFor="market">Which market are you interested in?</label>
					<div className="inputWrapper">
						<select
							id="type"
							onChange={(e) => setMarket(e.target.value)}
							value={market}
							required
							onFocus={() => setMarketFocus(true)}
							onBlur={() => setMarketFocus(false)}
						>
							<option value="">Select Market</option>
							{markets?.length ?
								markets.map((market, i) =>
									<option key={i} value={market.market_name}>{market.market_name}</option>
								)
								:
								<option value="">No markets available</option>
							}
						</select>
					</div>

					{/* Reason for contacting us */}
					<label htmlFor="reason">Reason for contacting us?</label>
					<div className="inputWrapper">
						<select
							id="type"
							onChange={(e) => setReason(e.target.value)}
							value={reason}
							required
							onFocus={() => setReasonFocus(true)}
							onBlur={() => setReasonFocus(false)}
						>
							<option value="">Select Reason</option>
							<option value="IYH Home Pro Directory Advertising">IYH Home Pro Directory Advertising</option>
							<option value="IYH Magazine Advertising">IYH Magazine Advertising</option>
							<option value="Address Removal">Address Removal</option>
							<option value="Other">Other</option>
						</select>
					</div>

					<label htmlFor="message">Message</label>
					<div className="inputWrapper">
						<span className={validMessage ? "valid" : "hide"}><FaCheck /></span>
						<span className={validMessage || !message ? "hide" : "invalid"}><FaExclamation /></span>
						<textarea
							onChange={(e) => setMessage(e.target.value)}
							name="message"
							id="message"
							rows="5"
							value={message}
							required
							aria-invalid={validMessage ? "false" : "true"}
							aria-describedby="messagenote"
							onFocus={() => setMessageFocus(true)}
							onBlur={() => setMessageFocus(false)}
						>
						</textarea>
					</div>
					<p id="messagenote" className={messageFocus && message && !validMessage ? "instructions" : "offscreen"}>
						Invalid message. Valid message includes letters, number and special characters (,.#&%!$'()/ )
					</p>
					<button disabled={!validBusinessName || !validContactName || !validEmail || !validPhone || !validMessage || !reason ? true : false}>Submit</button>
				</form>
				}
			</article>
		</section>
	)
}

export default Contact