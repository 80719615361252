import { NavLink } from "react-router-dom";

const LegalMenu = () => {
	return (
		<nav id="legal">
			<NavLink to="/terms-of-use">IYH Terms of Use</NavLink>
			<NavLink to="/membership-agreement">IYH Membership Agreement</NavLink>
			<NavLink to="/project-submission-customer-agreement">IYH Project Submission Customer Agreement</NavLink>
			<NavLink to="/service-providers-user-agreement">IYH Service Provider's User Agreement</NavLink>
			<NavLink to="/advertising-agreement">IYH Advertising Agreement</NavLink>
			<NavLink to="/code-of-conduct">IYH Code of Conduct</NavLink>
			<NavLink to="/privacy-policy">IYH Privacy Policy</NavLink>
			<NavLink to="/do-not-sell-policy">IYH Do Not Sell Policy</NavLink>
			<NavLink to="/mobile-alerts-terms-and-conditions">IYH Mobile Alerts Terms and Conditions</NavLink>
		</nav>
	)
}

export default LegalMenu