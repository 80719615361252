import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';
import { FaExclamation } from 'react-icons/fa';
import usePageTitle from '../hooks/usePageTitle';
import UserPageNav from '../components/UserPageNav';
import useAuth from '../hooks/useAuth';
import { ToastContainer, toast } from 'react-toastify';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import * as React from 'react';
import Slider from '@mui/material/Slider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const NAME_REGEX = /^[a-zA-Z0-9-_&@#$! ]{1,50}$/;
const PRICE_REGEX = /^[0-9.]{1,10}$/;
const DESCRIPTION_REGEX = /^[a-zA-Z0-9-_.,&$#@! \r\n]{1,500}$/;

const CreateOption = ({ addOption }) => {

	const { auth } = useAuth();
	const userID = auth?.user?.id;
	let isVerified = auth?.user?.email_verified_at;

	const axiosPrivate = useAxiosPrivate();

	const optionNameRef = useRef()
	const optionDescriptionRef = useRef()
	const optionPriceRef = useRef()

	const [optionStatus, setOptionStatus] = useState(true)

	const [optionName, setOptionName] = useState()
	const [validName, setValidName] = useState(false);
	const [nameFocus, setNameFocus] = useState(false);

	const [optionType, setOptionType] = useState('checkbox')
	const [validType, setValidType] = useState(false);
	const [typeFocus, setTypeFocus] = useState(false);

	const [optionDescription, setOptionDescription] = useState(null)
	const [validDescription, setValidDescription] = useState(false);
	const [descriptionFocus, setDescriptionFocus] = useState(false);

	const [optionPrice, setOptionPrice] = useState(null)
	const [validPrice, setValidPrice] = useState(false);
	const [priceFocus, setPriceFocus] = useState(false);

	const [defaultChecked, setDefaultChecked] = useState(false)
	const [optionDisabled, setOptionDisabled] = useState(false)
	const [onetimePayment, setOnetimePayment] = useState(false)

	/* Check Name */
	useEffect(() => {
		const result = NAME_REGEX.test(optionName);
		setValidName(result);
	}, [optionName])

	/* Check Description */
	useEffect(() => {
		const result = DESCRIPTION_REGEX.test(optionDescription);
		setValidDescription(result);
	}, [optionDescription])

	/* Check Price */
	useEffect(() => {
		const result = PRICE_REGEX.test(optionPrice);
		setValidPrice(result);
	}, [optionPrice])

	const handleSubmit = async (e) => {
		e.preventDefault();

		const dismiss = () =>  toast.dismiss(addOptionToast.current);
		const addOptionToast = toast.loading("Adding Option");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.post('option',
			JSON.stringify({option_name: optionName, option_description: optionDescription, option_type: optionType, option_enabled: optionStatus, option_type_options: null, option_disabled: optionDisabled, checkbox_checked: defaultChecked, onetime_payment: onetimePayment, option_price: optionPrice}),
			{
				signal: controller.signal
			});

			// Get package id from database
			const id = response?.data?.option?.id

			// Get date from database
			const created_at = response?.data?.option?.created_at

			// Add market to markets
			addOption({id, option_name: optionName, option_description: optionDescription, option_type: optionType, option_enabled: optionStatus, created_at, option_disabled: optionDisabled, checkbox_checked: defaultChecked, onetime_payment: onetimePayment, option_price: optionPrice})

			// Clear Form
			setOptionName("")
			setOptionDescription(null)
			setOptionType("")
			setDefaultChecked(false)
			setOptionDisabled(false)
			setOnetimePayment(false)
			setOptionPrice()

			// Update Toast Notification
			toast.update(addOptionToast, { render: 'Option Added', type: 'success', isLoading: false, autoClose: 5000});
		} catch (err) {
			if (!err?.response) {
				toast.update(addOptionToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else if (err.response?.status === 401) {
				toast.update(addOptionToast, { render: 'Authorization Failed', type: 'error', isLoading: false});
				{/* setErrMsg('The email has already been taken.'); */}
			} else {
				toast.update(addOptionToast, { render: 'Option Add Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	return (
		<>
			<form id="createPackage" onSubmit={handleSubmit} >

				{/* Name */}
				<label htmlFor="name">Option Name:</label>
				<div className="inputWrapper">
					<span className={validName ? "valid" : "hide"}><FaCheck /></span>
					<span className={validName || !optionName ? "hide" : "invalid"}><FaExclamation /></span>
					<input
						type="text"
						id="name"
						className={validName || !optionName ? null : "error"}
						ref={optionNameRef}
						autoComplete="off"
						onChange={(e) => setOptionName(e.target.value)}
						value={optionName}
						aria-invalid={validName ? "false" : "true"}
						aria-describedby="namenote"
						onFocus={() => setNameFocus(true)}
						onBlur={() => setNameFocus(false)}
					/>
				</div>
				<p id="namenote" className={nameFocus && optionName && !validName ? "instructions" : "offscreen"}>
					Invalid Option Name. Package names must be between 1-50 characters long. Valid characters (-,_,&,@,#,$,!)
				</p>

				{/* Description */}
				<label htmlFor="description">Description:</label>
				<div className="inputWrapper">
					<span className={validDescription ? "valid" : "hide"}><FaCheck /></span>
					<span className={validDescription || !optionDescription ? "hide" : "invalid"}><FaExclamation /></span>
					<textarea
						ref={optionDescriptionRef}
						onChange={(e) => setOptionDescription(e.target.value)}
						name="description"
						id="description"
						rows="5"
						value={optionDescription}
					>
					</textarea>
					<p id="descriptionnote" className={descriptionFocus && optionDescription && !validDescription ? "instructions" : "offscreen"}>
						Invalid Description. Between 1-500 characters (letters, numbers, dash and underscore allowed)
					</p>
				</div>


				{/* Type */}
				<label htmlFor="price">Option Type:</label>
				<div className="inputWrapper">
					<select
						name="type"
						id="type"
						defaultValue="active"
						onChange={(e) => setOptionType(e.target.value)}
						value={optionType}
					>
						<option value="checkbox">Checkbox</option>
					</select>
				</div>

				{ optionType == "checkbox" &&
					<>
						<label>Checkbox Options</label>
						<FormGroup>
							<FormControlLabel control={<Checkbox value={defaultChecked} onChange={(e) => setDefaultChecked(e.target.checked)} />} label="Default Checked" />
							<FormControlLabel control={<Checkbox value={optionDisabled} onChange={(e) => setOptionDisabled(e.target.checked)} />} label="Disabled" />
							<FormControlLabel control={<Checkbox value={onetimePayment} onChange={(e) => setOnetimePayment(e.target.checked)} />} label="One-time Payment" />
						</FormGroup>
					</>
				}

				{/* Price */}
				{onetimePayment ?
					<>
						<label htmlFor="price">Price:</label>
						<div className="inputWrapper">
							<span className={validPrice ? "valid" : "hide"}><FaCheck /></span>
							<span className={validPrice || !optionPrice ? "hide" : "invalid"}><FaExclamation /></span>
							<input
								type="text"
								id="name"
								className={validPrice || !optionPrice ? null : "error"}
								ref={optionPriceRef}
								autoComplete="off"
								onChange={(e) => setOptionPrice(e.target.value)}
								value={optionPrice}
								aria-invalid={validPrice ? "false" : "true"}
								aria-describedby="pricenote"
								onFocus={() => setPriceFocus(true)}
								onBlur={() => setPriceFocus(false)}
							/>
						</div>
						<p id="pricenote" className={priceFocus && optionPrice && !validPrice ? "instructions" : "offscreen"}>
							Invalid Option Price.
						</p>
					</>
					:
					null
				}

				{/* Option Status */}
				<label htmlFor="status">Option Status:</label>
				<p>Disabled option only disables it from being checked. It will still be shown as an option when configuring the subscription.</p>
				<div className="inputWrapper">
					<select
						name="status"
						id="status"
						defaultValue="active"
						onChange={(e) => setOptionStatus(e.target.value)}
						value={optionStatus}
					>
						<option value="active">Active</option>
						<option value="disabled">Disabled</option>
					</select>
				</div>

				<button disabled={!validName || !validDescription || !validDescription ? true : false}>Add Option</button>
			</form>

			<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
			/>
		</>
	)
}

export default CreateOption