import { useEffect } from "react";
import usePageTitle from '../../hooks/usePageTitle';
import UserPageNav from '../../components/UserPageNav';
import useAuth from '../../hooks/useAuth';
import FavoriteCount from '../../components/FavoriteCount';
import ContractorDashboard from '../../components/ContractorDashboard';
import UserDashboard from '../../components/UserDashboard';
import { AiOutlineDashboard } from 'react-icons/ai'
import { CgProfile } from 'react-icons/cg'
import { MdAccountBox } from 'react-icons/md'
import { FaUserCircle } from 'react-icons/fa'
import { GiStarsStack } from 'react-icons/gi'
import { AiOutlineBarChart } from 'react-icons/ai'
import { HiChartSquareBar } from 'react-icons/hi'
import { ImBullhorn } from 'react-icons/im';
import { MdDashboard } from 'react-icons/md';
import { RiCustomerService2Line } from 'react-icons/ri';
import { MdHelpCenter } from 'react-icons/md';
import { AiFillMessage } from 'react-icons/ai';
import { MdMessage } from 'react-icons/md';
import { MdFavorite } from 'react-icons/md';
import { MdFactCheck } from 'react-icons/md';
import { MdRequestPage } from 'react-icons/md';
import { MdSettings } from 'react-icons/md';
import { MdWork } from 'react-icons/md';

const Dashboard = () => {

	usePageTitle("Dashboard");

	const { auth } = useAuth();
	let isVerified = auth?.user?.email_verified_at;

	return (
		<section>
			<article className='inner'>
				<div className="backendTitleBar">
					<h1>{auth?.user?.role == 1001 && 'Homeowner' }{auth?.user?.role == 2002 && 'Home Pro' } Dashboard</h1>
					<MdDashboard />
				</div>
				<div className="backendColumnWrapper">
					<div id="menu" className="column">
						<UserPageNav />
					</div>
					<div id="content" className="column">
						{ isVerified &&
							<>
								{auth?.user?.role == 2002 && <ContractorDashboard />}
								{auth?.user?.role == 1001 && <UserDashboard />}
							</>
						}
					</div>
				</div>
			</article>
		</section>
	)
}

export default Dashboard