import AdminMenu from '../../components/AdminMenu';
import AdminMetrics from '../../components/AdminMetrics'
import { MdInsertChart } from 'react-icons/md';

const Metrics = () => {
	return (
		<section>
			<article className="inner">
				<div className="backendTitleBar">
					<h1>Metrics</h1>
					<MdInsertChart />
				</div>
				<div className="backendColumnWrapper">
					<div id="menu" className="column">
						<AdminMenu />
					</div>
					<div id="content" className="column">
						<AdminMetrics />
					</div>
				</div>
			</article>
		</section>
	)
}

export default Metrics