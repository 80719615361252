import { AiOutlineFacebook } from "react-icons/ai";
import { AiOutlineInstagram } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiOutlineLinkedin } from "react-icons/ai";
import { AiOutlineGoogle } from "react-icons/ai";
import { ImYelp } from "react-icons/im";


const ContractorSocial = ({ links }) => {

	if ( !links ){
		return;
	}

	return (
		<div className="socialContainer">
			{ JSON.parse(links)[0].facebook || JSON.parse(links)[0].instagram || JSON.parse(links)[0].linkedin || JSON.parse(links)[0].twitter || JSON.parse(links)[0].yelp || JSON.parse(links)[0].google ? <h3>Follow Us</h3> : null}
			<ul>
				{JSON.parse(links)[0].facebook &&
					<li><a href={JSON.parse(links)[0].facebook} target="_blank"><AiOutlineFacebook /></a></li>
				}
				{JSON.parse(links)[0].instagram &&
					<li><a href={JSON.parse(links)[0].instagram} target="_blank"><AiOutlineInstagram /></a></li>
				}
				{JSON.parse(links)[0].linkedin &&
					<li><a href={JSON.parse(links)[0].linkedin} target="_blank"><AiOutlineLinkedin /></a></li>
				}
				{JSON.parse(links)[0].twitter &&
					<li><a href={JSON.parse(links)[0].twitter} target="_blank"><AiOutlineTwitter /></a></li>
				}
				{JSON.parse(links)[0].yelp &&
					<li><a href={JSON.parse(links)[0].yelp} target="_blank"><ImYelp /></a></li>
				}
				{JSON.parse(links)[0].google &&
					<li><a href={JSON.parse(links)[0].google} target="_blank"><AiOutlineGoogle /></a></li>
				}
			</ul>
		</div>
	)
}

export default ContractorSocial