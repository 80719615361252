import { useState, useEffect, useRef } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import useRefreshToken from '../hooks/useRefreshToken';
import { BsSendFill } from 'react-icons/bs';
import { BsFillSendCheckFill } from 'react-icons/bs';
import { AiFillDelete } from 'react-icons/ai';
import { MdVisibility } from 'react-icons/md';
import { FaPeopleArrows } from 'react-icons/fa';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import { ToastContainer, toast } from 'react-toastify';
import Loader from './Loader';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

const RequestedUsers = () => {

	const [loading, setLoading] = useState(false);
	const [users, setUsers] = useState();
	const axiosPrivate = useAxiosPrivate();
	const refresh = useRefreshToken();

	const navigate = useNavigate();
	const location = useLocation();

	const {auth} = useAuth();
	const role = auth?.role;
	const id = auth?.id;
	const userID = auth?.user?.id;

	const [viewRequestID, setViewRequestID] = useState()
	const [viewRequestName, setViewRequestName] = useState()
	const [viewRequestEmail, setViewRequestEmail] = useState()
	const [viewRequestPhone, setViewRequestPhone] = useState()
	const [viewRequestBusiness, setViewRequestBusiness] = useState()
	const [viewRequestPosition, setViewRequestPosition] = useState()
	const [viewRequestMarket, setViewRequestMarket] = useState()
	const [viewRequestReason, setViewRequestReason] = useState()
	const [viewRequestMessage, setViewRequestMessage] = useState()
	const [viewRequestCode, setViewRequestCode] = useState()
	const [viewRequestIndex, setViewRequestIndex] = useState()
	const [viewRequestCodeSentAt, setViewRequestCodeSentAt] = useState()

	const [openViewRequest, setOpenViewRequest] = useState(false)

	const [sendCodeID, setSendCodeID] = useState()
	const [sendCodeIndex, setSendCodeIndex] = useState()
	const [deleteID,setDeleteID] = useState()
	const [deleteName,setDeleteName] = useState()
	const [sendCodeName, setSendCodeName] = useState()
	const [openSendRegistrationCode, setOpenSendRegistrationCode] = useState(false)
	const [openDeleteRequest, setOpenDeleteRequest] = useState(false)

	const [convertID, setConvertID] = useState()
	const [convertName, setConvertName] = useState()
	const [openConvertToUser, setOpenConvertToUser] = useState(false)

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getUsers = async () => {
			try {
				const response = await axiosPrivate.get('requested-users', {
					signal: controller.signal
				});
				console.log(response.data);
				setUsers(response.data);
				setLoading(false)
			} catch(err) {
				console.log(err.message);
				!auth && navigate('/login', { state: { from: location }, replace: true });
			}
		}

		getUsers();

		return () => controller.abort();

	},[])

	const sendCode = (e, i, user, value) => {
		// if (value !== 1){
			setSendCodeID(user?.id)
			setSendCodeName(`${user?.first_name} ${user?.last_name}`)
			setSendCodeIndex(i)
			setOpenSendRegistrationCode(true)
		// }
	}
	
	const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
	const onSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        const sortedData = [...users].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setUsers(sortedData);
        setSortConfig({ key, direction });
    };

	const deleteRequest = (e, i, user) => {
		setDeleteID(user?.id)
		setDeleteName(`${user?.first_name} ${user?.last_name}`)

		setOpenDeleteRequest(true)
	}

	const handleCloseSendRegistrationCode = () => {
		setOpenSendRegistrationCode(false)
	}

	const handleCloseViewRequest = () => {
		setOpenViewRequest(false)
	}

	const handleCloseDeleteRequest = () => {
		setOpenDeleteRequest(false)
	}

	const viewRequest = (e,i,user) => {
		setViewRequestIndex(i)
		setViewRequestID(user?.id)
		setViewRequestName(`${user?.first_name} ${user?.last_name}`)
		setViewRequestEmail(user?.email)
		setViewRequestPhone(user?.phone)
		setViewRequestBusiness(user?.business_name)
		setViewRequestPosition(user?.company_position)
		setViewRequestMarket(user?.market)
		setViewRequestReason(user?.reason)
		setViewRequestMessage(user?.message)
		setViewRequestCode(user?.code)
		setViewRequestCodeSentAt(user?.code_sent ? formatTimestamp(user?.updated_at) : 'N/A (Code not sent)')

		setOpenViewRequest(true)
		handleViewRequest()
	}

	const handleViewRequest = async () => {
		const controller = new AbortController();
		try {

			const response = await axiosPrivate.put('requested-user/' + viewRequestID,
			{
				signal: controller.signal
			});

			// Close Popup
			setOpenSendRegistrationCode(false)

			// Update State
			users[viewRequestIndex].status = 'viewed'
			setUsers([...users])


		} catch(err) {
			console.log(err.message);
		}

		return () => controller.abort();
	}

	const handleSendCode = async () => {
		const dismiss = () =>  toast.dismiss(sendCodeToast.current);
		const sendCodeToast = toast.loading("Sending Code");
		const controller = new AbortController();
		try {

			const response = await axiosPrivate.put('send-homepro-code/' + sendCodeID,
			JSON.stringify({code_sent: 1, status: 'viewed'}),
			{
				signal: controller.signal
			});

			// Close Popup
			setOpenSendRegistrationCode(false)

			// Update State
			users[sendCodeIndex].code_sent = 1
			setUsers([...users])

			// Update Toast Notification
			toast.update(sendCodeToast, { render: 'Code Sent', type: 'success', isLoading: false, autoClose: 5000});


		} catch(err) {
			console.log(err?.message);
			if (!err?.response) {
				toast.update(sendCodeToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(sendCodeToast, { render: 'Code Send Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
		}

		return () => controller.abort();
	}

	const handleDeleteRequest = async () => {
		const dismiss = () =>  toast.dismiss(deleteUserToast.current);
		const deleteUserToast = toast.loading("Deleting Request");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.delete('requested-user/' + deleteID,
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(deleteUserToast, { render: 'Request Deleted', type: 'success', isLoading: false, autoClose: 5000});

			// Close Alert Window
			setOpenDeleteRequest(false);

			// Delete user from UI
			setUsers(users.filter((user) => user.id !== deleteID))

		} catch (err) {
			if (!err?.response) {
				toast.update(deleteUserToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(deleteUserToast, { render: 'Request Delete Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	const convertToUser = (e,i,user) => {
		setOpenConvertToUser(true)
		setConvertName(`${user?.first_name} ${user?.last_name}`)
		setConvertID(user?.id)
	}

	const handleCloseConvertToUser = () => {
		setOpenConvertToUser(false)
	}

	const handleConvertToUser = async (e) => {
		const dismiss = () =>  toast.dismiss(convertToUserToast.current);
		const convertToUserToast = toast.loading("Converting to User");
		const controller = new AbortController();
		console.log("converting")
		try {

			const response = await axiosPrivate.get('convert-to-user/' + convertID,
			{
				signal: controller.signal
			});

			console.log(response?.message)
			// Update Toast Notification
			toast.update(convertToUserToast, { render: 'Request Converted', type: 'success', isLoading: false, autoClose: 5000});

			// Close Popup
			console.log("converting to user")
			setOpenConvertToUser(false)

			// Filter out converted user
			setUsers(users.filter((user) => user.id !== convertID))

		} catch(err) {
			console.error(err);
			if (!err?.response) {
				toast.update(convertToUserToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else if (err.response?.status === 409) {
				toast.update(convertToUserToast, { render: 'User already exists!', type: 'error', isLoading: false, autoClose: 5000});
			} else {
				toast.update(convertToUserToast, { render: 'User Convert Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			setOpenConvertToUser(false)
		}

		console.log('test')
		return () => controller.abort();
	}

	const formatTimestamp = (ts) => {
		if (ts){
			const options = { month: "numeric", day: "numeric", year: "numeric" }
			return new Date(ts).toLocaleDateString(undefined, options)
		} else {
			return "N/A"
		}
	}

	return (
		<>
		<div className="backendList">
			{users?.length
			? (
				<>
					<ul className="requestedUsersList">
						<li className="key">
							<div className="name" onClick={() => onSort('first_name')}>Name</div>
							<div className="business" onClick={() => onSort('business_name')}>Business</div>
							<div className="code" onClick={() => onSort('code')}>Code</div>
							<div className="date" onClick={() => onSort('created_at')}>Date</div>
							<div className="buttons"></div>
						</li>
						{users.map((user, i) =>
						<li key={i} className="requestedUser">
							<div className="name">
								{user?.first_name} {user?.last_name}
							</div>
							<div className="business">
								{user?.business_name}
							</div>
							<div className="code">
								{user?.code}
							</div>
							<div className="date">
								{formatTimestamp(user?.created_at)}
							</div>
							<div className="buttons">
								<Tooltip title={`${user?.code_sent ? 'Code Sent' : 'Send Code'}`}>
									<div className={`send ${user?.code_sent && 'sent'}`} onClick={(e) => sendCode(e, i, user, user?.code_sent)}>
										<span>{ user?.code_sent ? <BsFillSendCheckFill /> : <BsSendFill />}</span>
									</div>
								</Tooltip>
								<Tooltip title="View Request">
									<div className="view" onClick={(e) => viewRequest(e, i, user)}>
										<span><MdVisibility /></span>
									</div>
								</Tooltip>
								<Tooltip title="Convert to User">
									<div className="convert" onClick={(e) => convertToUser(e, i, user)}>
										<span><FaPeopleArrows /></span>
									</div>
								</Tooltip>
								<Tooltip title="Delete Request">
									<div className="delete" onClick={(e) => deleteRequest(e, i, user)}>
										<span><AiFillDelete /></span>
									</div>
								</Tooltip>
							</div>
						</li>)}
					</ul>
				</>
			):
				<p>No Requested Home Pros</p>
			}

			{/* Send Registration Code */}
			<Dialog
				open={openSendRegistrationCode}
				onClose={handleCloseSendRegistrationCode}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{`Are you sure you want to send a registration code?`}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Are you sure you want to send registration code to "{sendCodeName}".
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<button className="cancel" onClick={handleCloseSendRegistrationCode}>Cancel</button>
					<button className="confirm" onClick={handleSendCode}>Yes, send code</button>
				</DialogActions>
			</Dialog>

			{/* Convert to User */}
			<Dialog
				open={openConvertToUser}
				onClose={handleCloseConvertToUser}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{`Are you sure you want to this request to a user?`}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Are you sure you want to convert "{convertName}" to a user? This will delete this request and add associated data as a user.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<button className="cancel" onClick={handleCloseConvertToUser}>Cancel</button>
					<button className="confirm" onClick={handleConvertToUser}>Yes, convert to user</button>
				</DialogActions>
			</Dialog>

			{/* Delete Request */}
			<Dialog
				open={openDeleteRequest}
				onClose={handleCloseDeleteRequest}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{`Are you sure you want delete request?`}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Are you sure you want to delete request "{sendCodeName}".
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<button className="cancel" onClick={handleCloseDeleteRequest}>Cancel</button>
					<button className="confirm" onClick={handleDeleteRequest}>Yes, delete request</button>
				</DialogActions>
			</Dialog>

			{/* View Request */}
			<Dialog
				open={openViewRequest}
				onClose={handleCloseViewRequest}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{`You are viewing request for ${viewRequestName}`}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<>
							<p>Name: {viewRequestName}</p>
							<p>Business: {viewRequestBusiness}</p>
							<p>Email: {viewRequestEmail}</p>
							<p>Phone: {viewRequestPhone}</p>
							<p>Position: {viewRequestPosition}</p>
							<p>Market: {viewRequestMarket}</p>
							<p>Reason: {viewRequestReason}</p>
							<p>Market: {viewRequestMarket}</p>
							<p>Code: {viewRequestCode}</p>
							<p>Code Sent: {viewRequestCodeSentAt}</p>
						</>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<button className="cancel" onClick={handleCloseViewRequest}>Cancel</button>
				</DialogActions>
			</Dialog>


		</div>
			<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
			/>
	</>
	)
}

export default RequestedUsers