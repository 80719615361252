import { useRef, useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
import { FaCheck } from "react-icons/fa";
import { FaExclamation } from "react-icons/fa";
import { MdReportProblem } from "react-icons/md";
import axios from "../api/axios";
import usePageTitle from "../hooks/usePageTitle";
import PopupAgreement from "../components/PopupAgreement";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import RegisterThankYou from "./RegisterThankYou";
import { Box, Grid } from "@mui/material";
import heroImage from "../assets/images/IYH_Home-Show-Landing-Page.png";
import mobileImage from "../assets/images/IYH_Landing_Page_Mobile.png";

/* username and password restrictions */
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%_-]).{8,24}$/;
const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const NAME_REGEX = /^[a-zA-Z' -]{2,23}$/;
const POSITION_REGEX = /^[a-zA-Z' -#&*]{2,23}$/;
const PHONE_REGEX =
  /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
const CITY_REGEX = /^[a-zA-Z ]{2,50}$/;
const ZIPCODE_REGEX = /^\d{5}(?:[-\s]\d{4})?$/;
const BUSINESS_REGEX = /[^\s]{1,100}$/;
const CODE_REGEX = /^\d{6}$/;
const MESSAGE_REGEX = /^[a-zA-Z0-9,.#&%!$'()/ ]{2,10000}$/;

const REGISTER_URL = "register";
const INVITE_URL = "check-invite-code";

const HomeownerLandingPageForm = ({ userType }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const [loading, setLoading] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const urlCode = params.get("code");

  const emailRef = useRef();
  const errRef = useRef();
  const fnameRef = useRef();
  const phoneRef = useRef();
  const businessNameRef = useRef();
  const cityRef = useRef();
  const stateRef = useRef();
  const zipRef = useRef();
  const codeRef = useRef();
  const contactNameFRef = useRef();
  const contactNameLRef = useRef();
  const businessPositionRef = useRef();

  const [businessName, setBusinessName] = useState("");
  const [validBusinessName, setValidBusinessName] = useState(false);
  const [businessNameFocus, setBusinessNameFocus] = useState(false);

  const [fName, setFName] = useState("");
  const [validFName, setValidFName] = useState(false);
  const [fNameFocus, setFNameFocus] = useState(false);

  const [lName, setLName] = useState("");
  const [validLName, setValidLName] = useState(false);
  const [lNameFocus, setLNameFocus] = useState(false);

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [phone, setPhone] = useState("");
  const [validPhone, setValidPhone] = useState(false);
  const [phoneFocus, setPhoneFocus] = useState(false);

  const [city, setCity] = useState("");
  const [validCity, setValidCity] = useState(false);
  const [cityFocus, setCityFocus] = useState(false);

  const [state, setState] = useState("");
  const [validState, setValidState] = useState(false);
  const [stateFocus, setStateFocus] = useState(false);

  const [zip, setZip] = useState("");
  const [validZip, setValidZip] = useState(false);
  const [zipFocus, setZipFocus] = useState(false);

  const [type, setType] = useState("1001"); // sets default account type to homeowner
  const [validType, setValidType] = useState(false);
  const [typeFocus, setTypeFocus] = useState(false);

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  const [responseMessage, setResponseMessage] = useState();

  const [offersAndDiscounts, setOffersAndDiscounts] = useState(false);

  const [code, setCode] = useState(urlCode || "");
  const [validCode, setValidCode] = useState(false);
  const [codeFocus, setCodeFocus] = useState(false);
  const [codeErrMsg, setCodeErrMsg] = useState();
  const [inviteData, setInviteData] = useState();

  const [market, setMarket] = useState();
  const [markets, setMarkets] = useState();
  const [reason, setReason] = useState();
  const [message, setMessage] = useState();
  const [validMessage, setValidMessage] = useState();
  const [messageFocus, setMessageFocus] = useState(false);
  const [reasonFocus, setReasonFocus] = useState(false);
  const [marketFocus, setMarketFocus] = useState(false);

  const [contactFName, setContactFName] = useState();
  const [contactLName, setContactLName] = useState();
  const [validContactFName, setValidContactFName] = useState(false);
  const [validContactLName, setValidContactLName] = useState(false);
  const [contactFNameFocus, setContactFNameFocus] = useState(false);
  const [contactLNameFocus, setContactLNameFocus] = useState(false);

  const [businessPosition, setBusinessPosition] = useState();
  const [businessPositionFocus, setBusinessPositionFocus] = useState(false);
  const [validBusinessPosition, setValidBusinessPosition] = useState(false);

  const [width, setWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState();
  const [reload, setReload] = useState();
  const imageRef = useRef();

  const [agreeToAllTermsAndConditions, setAgreeToAllTermsAndConditions] =
    useState(false);

  useEffect(() => {
    const handleResize = async () => {
      const current = imageRef.current;
      const rect = await current.getBoundingClientRect();
      setWidth(window.innerWidth);
      setImageHeight(rect.height ? rect.height : window.innerWidth * 0.5625);
    };
    handleResize();
    setTimeout(() => {
      return handleResize();
    }, 500);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [reload]);

  /* Usability focus on load to user */
  useEffect(() => {
    type == 1001
      ? fnameRef.current.focus()
      : inviteData
      ? businessNameRef.current.focus()
      : // codeRef.current.focus()

        userType && setType(userType);

    if (location?.pathname == "/register-home-pro") {
      setType("2002");
    }

    const controller = new AbortController();
    setLoading(true);
    const getPackages = async () => {
      try {
        const response = await axiosPrivate.get("get-active-markets", {
          signal: controller.signal,
        });
        setMarkets(response.data);
        setLoading(false);
      } catch (err) {
        console.log(err.message);
        !auth &&
          navigate("/login", { state: { from: location }, replace: true });
      }
    };

    getPackages();

    return () => controller.abort();
    // eslint-disable-next-line
  }, []);

  /* Check username against regex function on username change */
  useEffect(() => {
    const result = EMAIL_REGEX.test(email);
    setValidEmail(result);
  }, [email]);

  /* Check password against regex function on password change */
  useEffect(() => {
    const result = PWD_REGEX.test(pwd);
    setValidPwd(result);
    const match = pwd === matchPwd;
    setValidMatch(match);
  }, [pwd, matchPwd]);

  /* Check Business Name */
  useEffect(() => {
    const result = BUSINESS_REGEX.test(businessName);
    setValidBusinessName(result);
  }, [businessName]);

  /* Check First Name */
  useEffect(() => {
    const result = NAME_REGEX.test(fName);
    setValidFName(result);
  }, [fName]);

  useEffect(() => {
    const result = NAME_REGEX.test(contactFName);
    setValidContactFName(result);
  }, [contactFName]);

  /* Check Last Name */
  useEffect(() => {
    const result = NAME_REGEX.test(lName);
    setValidLName(result);
  }, [lName]);

  useEffect(() => {
    const result = NAME_REGEX.test(contactLName);
    setValidContactLName(result);
  }, [contactLName]);

  /* Check Phone */
  useEffect(() => {
    const result = PHONE_REGEX.test(phone);
    setValidPhone(result);
  }, [phone]);

  /* Check City */
  useEffect(() => {
    const result = CITY_REGEX.test(city);
    setValidCity(result);
  }, [city]);

  /* Check State */
  useEffect(() => {
    state !== "" || type == 1001 ? setValidState(true) : setValidState(false);
  }, [state, type]);

  /* Check Zip */
  useEffect(() => {
    const result = ZIPCODE_REGEX.test(zip);
    setValidZip(result);
  }, [zip]);

  /* Check Code */
  useEffect(() => {
    const result = CODE_REGEX.test(code);
    setValidCode(result);
  }, [code]);

  /* Clear Error message on any change of user or password */
  useEffect(() => {
    setErrMsg("");
  }, [email, pwd, matchPwd]);

  /* Check Message */
  useEffect(() => {
    const result = MESSAGE_REGEX.test(message);
    setValidMessage(result);
  }, [message]);

  /* Check Position */
  useEffect(() => {
    const result = POSITION_REGEX.test(businessPosition);
    setValidBusinessPosition(result);
  }, [businessPosition]);

  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prevent Hacking of submit button by checking against regex again
    const v1 = EMAIL_REGEX.test(email);
    const v2 = PWD_REGEX.test(pwd);
    const v3 = PHONE_REGEX.test(phone);

    if (!v1 || !v2 || !v3) {
      setErrMsg("Invalid Entry");
      return;
    }
    try {
      const response = await axios.post(
        REGISTER_URL,
        JSON.stringify({
          first_name: fName,
          last_name: lName,
          email,
          password: pwd,
          password_confirmation: matchPwd,
          role: type,
          phone: phone,
          city: city,
          state: state,
          zip: zip,
          business_name: businessName,
          slug: businessName ? convertToSlug(businessName) : null,
          receive_offers: offersAndDiscounts,
          homeshow_raffle_2024: type === "1001" ? true : false,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      // console.log('test')
      // console.log(response.data);
      // console.log(response.token);
      // console.log(JSON.stringify(response));
      setSuccess(true);
      setResponseMessage(response?.data?.message);

      if (
        response?.data?.user?.callcap_provision_number &&
        response?.data?.user?.role === "2002"
      ) {
        try {
          const callcapResponse = await axios.post(
            "https://api.callcap.com/v3/numbers",
            JSON.stringify({
              locationID: "27684",
              destination: phone,
              campaignName: businessName,
              recording: true,
              notes: "Added with API on new user registration",
              destination: response?.data?.user?.callcap_provision_number,
            }),
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer 8EAB5960035EB360D658CE767020ADAC",
              },
              withCredentials: true,
            }
          );
        } catch (err) {
          if (!err?.response) {
            console.log("No Callcap Server Response");
          } else if (err.response?.statusCode === 400) {
            console.log("Bad Callcap Request");
          } else {
            console.log("Callcap Provision Failed");
          }
        }
      } else {
        console.log("nothing to register with callcap");
      }

      if (type == "2002") {
        await window.location.replace("/thank-you-home-pro");
      } else if (type == "1001") {
        await window.location.replace("/thank-you-homeowner");
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
        console.log(err);
      } else if (err.response?.status === 409) {
        setErrMsg("Name Taken");
      } else if (err.response?.status === 422) {
        if (err.response?.data?.message) {
          setErrMsg(err.response?.data?.message);
        } else {
          setErrMsg("Registration Failed - Error 422");
          // setErrMsg('Missing Required Field');
        }
      } else {
        setErrMsg("Registration Failed");
      }
      errRef.current.focus();
    }
  };

  const handleCodeSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        INVITE_URL,
        JSON.stringify({ code: code }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      setInviteData(response?.data);

      const inviteUser = response?.data?.user;
      console.log(inviteUser);
    } catch (err) {
      if (!err?.response) {
        setCodeErrMsg("No Server Response");
      } else if (err.response?.status === 409) {
        setCodeErrMsg("Name Taken");
      } else if (err.response?.status === 422) {
        if (err.response?.data?.message) {
          setCodeErrMsg(err.response?.data?.message);
        } else {
          setCodeErrMsg("Registration Failed - Error 422");
          // setErrMsg('Missing Required Field');
        }
      } else {
        setCodeErrMsg("Registration Failed");
      }
    }
  };

  const handleSubmitRequest = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "request-invite",
        JSON.stringify({
          first_name: contactFName,
          last_name: contactLName,
          email: email,
          phone: phone,
          business_name: businessName,
          company_position: businessPosition,
          market: market,
          reason: reason,
          message: message,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      setSuccess(true);
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 409) {
        setErrMsg("Name Taken");
      } else if (err.response?.status === 422) {
        if (err.response?.data?.message) {
          setErrMsg(err.response?.data?.message);
        } else {
          setErrMsg("Request Failed - Error 422");
          // setErrMsg('Missing Required Field');
        }
      } else {
        setErrMsg("Request Failed");
      }
    }
  };

  useEffect(() => {
    const user = inviteData?.user;
    setBusinessName(user?.business_name);
    setFName(user?.first_name);
    setLName(user?.last_name);
    setEmail(user?.email);
    setPhone(user?.phone);
  }, [inviteData]);

  usePageTitle("Register");

  return (
    <>
      {success ? (
        <section>
          <article className="inner">
            <h1 className="case">
              Thanks For Signing Up With ImproveYourHouse.com!
            </h1>
            {
              type == 2002 ? (
                <>
                  <RegisterThankYou type={2002} />
                  {/*{inviteData ?
								<p>A verification email has been sent to "{email}". Once you have verified your email address, you will be able to complete the setup of your new IYH Contractor Profile and appear live on ImproveYourHouse.com. If you don't see a email from us in a few minutes check your spam/junk folder.</p>
								:
								<p>Your registration request has been sent to our sales team.</p>
							}*/}
                </>
              ) : (
                <RegisterThankYou type={1001} />
              )
              /*<p>A verification email has been sent to "{email}". Once you have verified your email address, you will be able to access your Homeowner dashboard that will allow you to create and submit projects, save and favorite Home Pros and more! If you don’t see a verification email from us within a few minutes, please check your spam or junk email folder.</p>*/
            }
          </article>
        </section>
      ) : (
        <section className="landing-page-hero-comp">
          <Box className="hero-comp" style={{ height: imageHeight }}>
            <Grid
              container
              spacing={2}
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              className="hero-grid"
            >
              {width > 774 ? <Grid item xs={1}></Grid> : <></>}
              <Grid
                item
                xs={10}
                sm={width > 774 ? 5 : 10}
                lg={5}
                className="hero-text"
              >
                <>
                  <h2>
                    Denver Home Show <br />
                    <span>Homeowners</span>
                  </h2>
                  <div className="hero-line-divider" />
                  <h5>Enter Below For Your Chance to Receive a</h5>
                  <h3 className="form-hero-large-text">$2,000</h3>
                  <h5>
                    Credit Towards Your Next Home Improvement Project with one
                    of the Denver Home Show Exhibitors or an IYH Home Pro
                  </h5>
                </>
                {/* <>
                   <h2>
                    Denver Home Show <br />
                    <span>Home Pros</span>
                  </h2>
                  <div className="hero-line-divider" /> 
                  <h4>
                    Enter for a chance to win any of the 3 prizes below: <br />{" "}
                  </h4>
                  <h3>
                    <span>$1,000</span> voucher <br />
                  </h3>
                  <p>to a vendor at the Denver Home Show</p>
                  <h3>
                    <span>$500</span> voucher <br />
                  </h3>
                  <p>to a vendor at the Denver Home Show</p>
                  <h3>
                    <span>$100</span> gift card <br />
                  </h3>
                </> */}
              </Grid>
            </Grid>
            <img
              ref={imageRef}
              src={width > 774 ? heroImage : mobileImage}
              alt="a tablet in a kitchen displaying the Improve Your House website"
              style={{
                width: "100%",
                position: "absolute",
                top: 0,
                zIndex: -1,
              }}
              onLoad={() => setReload(true)}
            />
          </Box>
          <article className="inner landing-page-form">
            <div className="form-div">
              <h3>
                There's Still Time to Enter
                <br />
                Drawing Extended Through Wednesday, March 27th
              </h3>
              <p
                ref={errRef}
                className={errMsg ? "errmsg" : "offscreen"}
                aria-live="assertive"
              >
                <MdReportProblem /> {errMsg}
              </p>

              <form onSubmit={handleSubmit}>
                {/* Homeowner Registration Fields */}
                {type == 1001 && (
                  <>
                    <label htmlFor="type">Account Type:</label>
                    <div className="inputWrapper">
                      <select
                        id="type"
                        onChange={(e) => setType(e.target.value)}
                        value={type}
                        required
                        onFocus={() => setTypeFocus(true)}
                        onBlur={() => setTypeFocus(false)}
                      >
                        <option value="1001">Homeowner</option>
                        {/* <option value="2002">Home Pro</option> */}
                      </select>
                    </div>

                    {/* First Name */}
                    <label htmlFor="fname">First Name:</label>
                    <div className="inputWrapper">
                      <span className={validFName && fName ? "valid" : "hide"}>
                        <FaCheck />
                      </span>
                      <span
                        className={validFName || !fName ? "hide" : "invalid"}
                      >
                        <FaExclamation />
                      </span>
                      <input
                        type="text"
                        id="fname"
                        ref={fnameRef}
                        className={validFName || !fName ? null : "error"}
                        onChange={(e) => setFName(e.target.value)}
                        required
                        aria-invalid={validFName ? "false" : "true"}
                        aria-describedby="fnamenote"
                        onFocus={() => setFNameFocus(true)}
                        onBlur={() => setFNameFocus(false)}
                      />
                    </div>
                    <p
                      id="fnamenote"
                      className={
                        fNameFocus && fName && !validFName
                          ? "instructions"
                          : "offscreen"
                      }
                    >
                      Invalid First Name.
                    </p>

                    {/* Last Name */}
                    <label htmlFor="lname">Last Name:</label>
                    <div className="inputWrapper">
                      <span className={validLName && lName ? "valid" : "hide"}>
                        <FaCheck />
                      </span>
                      <span
                        className={validLName || !lName ? "hide" : "invalid"}
                      >
                        <FaExclamation />
                      </span>
                      <input
                        type="text"
                        id="lname"
                        className={validLName || !lName ? null : "error"}
                        onChange={(e) => setLName(e.target.value)}
                        required
                        aria-invalid={validLName ? "false" : "true"}
                        aria-describedby="lnamenote"
                        onFocus={() => setLNameFocus(true)}
                        onBlur={() => setLNameFocus(false)}
                      />
                    </div>
                    <p
                      id="lnamenote"
                      className={
                        lNameFocus && lName && !validLName
                          ? "instructions"
                          : "offscreen"
                      }
                    >
                      Invalid Last Name.
                    </p>

                    {/* Email */}
                    <label htmlFor="email">Email:</label>
                    <div className="inputWrapper">
                      <span className={validEmail && email ? "valid" : "hide"}>
                        <FaCheck />
                      </span>
                      <span
                        className={validEmail || !email ? "hide" : "invalid"}
                      >
                        <FaExclamation />
                      </span>
                      <input
                        type="email"
                        id="email"
                        className={validEmail || !email ? null : "error"}
                        ref={emailRef}
                        autoComplete="off"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        aria-invalid={validEmail ? "false" : "true"}
                        aria-describedby="uidnote"
                        onFocus={() => setEmailFocus(true)}
                        onBlur={() => setEmailFocus(false)}
                      />
                    </div>
                    <p
                      id="uidnote"
                      className={
                        emailFocus && email && !validEmail
                          ? "instructions"
                          : "offscreen"
                      }
                    >
                      Invalid email address.
                    </p>

                    {/* Phone */}
                    <label htmlFor="phone">Phone:</label>
                    <div className="inputWrapper">
                      <span className={validPhone && phone ? "valid" : "hide"}>
                        <FaCheck />
                      </span>
                      <span
                        className={validPhone || !phone ? "hide" : "invalid"}
                      >
                        <FaExclamation />
                      </span>
                      <input
                        type="tel"
                        id="phone"
                        className={validPhone || !phone ? null : "error"}
                        ref={phoneRef}
                        autoComplete="off"
                        onChange={(e) => setPhone(e.target.value)}
                        required
                        aria-invalid={validPhone ? "false" : "true"}
                        aria-describedby="phonenote"
                        onFocus={() => setPhoneFocus(true)}
                        onBlur={() => setPhoneFocus(false)}
                      />
                    </div>
                    <p
                      id="phonenote"
                      className={
                        phoneFocus && phone && !validPhone
                          ? "instructions"
                          : "offscreen"
                      }
                    >
                      Invalid phone number.
                    </p>

                    {/* Zip */}
                    <label htmlFor="zip">Zip:</label>
                    <div className="inputWrapper">
                      <span className={validZip && zip ? "valid" : "hide"}>
                        <FaCheck />
                      </span>
                      <span className={validZip || !zip ? "hide" : "invalid"}>
                        <FaExclamation />
                      </span>
                      <input
                        type="text"
                        id="zip"
                        className={validZip || !zip ? null : "error"}
                        ref={zipRef}
                        autoComplete="off"
                        onChange={(e) => setZip(e.target.value)}
                        required
                        aria-invalid={validZip ? "false" : "true"}
                        aria-describedby="zipnote"
                        onFocus={() => setZipFocus(true)}
                        onBlur={() => setZipFocus(false)}
                      />
                    </div>
                    <p
                      id="zipnote"
                      className={
                        zipFocus && zip && !validZip
                          ? "instructions"
                          : "offscreen"
                      }
                    >
                      Invalid Zip. Valid Format includes 55555 or 55555-5555.
                    </p>

                    {/* Password */}
                    <label htmlFor="password">Password:</label>
                    <div className="inputWrapper">
                      <span className={validPwd && pwd ? "valid" : "hide"}>
                        <FaCheck />
                      </span>
                      <span className={validPwd || !pwd ? "hide" : "invalid"}>
                        <FaExclamation />
                      </span>
                      <input
                        type="password"
                        id="password"
                        className={validPwd || !pwd ? null : "error"}
                        onChange={(e) => setPwd(e.target.value)}
                        value={pwd}
                        required
                        aria-invalid={validPwd ? "false" : "true"}
                        aria-describedby="pwdnote"
                        onFocus={() => setPwdFocus(true)}
                        onBlur={() => setPwdFocus(false)}
                      />
                    </div>
                    <p
                      id="pwdnote"
                      className={
                        pwdFocus && !validPwd ? "instructions" : "offscreen"
                      }
                    >
                      8 to 24 characters. Must include uppercase and lowercase
                      letters, a number and a special character. Allowed special
                      characters: <span aria-label="exclamation mark">!</span>{" "}
                      <span aria-label="at symbol">@</span>{" "}
                      <span aria-label="hashtag">#</span>{" "}
                      <span aria-label="dollar sign">$</span>{" "}
                      <span aria-label="percent">%</span>{" "}
                      <span aria-label="dash">-</span>
                    </p>

                    {/* Password Confirmation */}
                    <label htmlFor="confirm_pwd">Confirm Password:</label>
                    <div className="inputWrapper">
                      <span
                        className={validMatch && matchPwd ? "valid" : "hide"}
                      >
                        <FaCheck />
                      </span>
                      <span
                        className={validMatch || !matchPwd ? "hide" : "invalid"}
                      >
                        <FaExclamation />
                      </span>
                      <input
                        type="password"
                        id="confirm_pwd"
                        className={validMatch || !matchPwd ? null : "error"}
                        onChange={(e) => setMatchPwd(e.target.value)}
                        value={matchPwd}
                        required
                      />
                    </div>
                    <p
                      id="confirmnote"
                      className={
                        matchFocus && !validMatch ? "instructions" : "offscreen"
                      }
                    >
                      Must match the first password input field.
                    </p>

                    {/* Agreements  & Options */}
                    <div className="agreements">
                      <label htmlFor="agreements">Agreements (Required)</label>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={agreeToAllTermsAndConditions}
                              onChange={(e) =>
                                setAgreeToAllTermsAndConditions(
                                  e.target.checked
                                )
                              }
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Agree to All"
                        />
                      </FormGroup>
                      <PopupAgreement
                        title="Terms of Use"
                        slug="terms-of-use"
                        required="true"
                        isAgreeToAllChecked={agreeToAllTermsAndConditions}
                      />
                      <PopupAgreement
                        title="Membership Agreement"
                        slug="membership-agreement"
                        required="true"
                        isAgreeToAllChecked={agreeToAllTermsAndConditions}
                      />
                      <PopupAgreement
                        title="Project Submission Customer Agreement"
                        slug="project-submission-customer-agreement"
                        required="true"
                        isAgreeToAllChecked={agreeToAllTermsAndConditions}
                      />
                      <PopupAgreement
                        title="Privacy Policy"
                        slug="privacy-policy"
                        required="true"
                        isAgreeToAllChecked={agreeToAllTermsAndConditions}
                      />
                    </div>

                    <div className="options">
                      <label htmlFor="options">Optional</label>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={offersAndDiscounts}
                              onChange={(e) =>
                                setOffersAndDiscounts(e.target.checked)
                              }
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="I'd like to receive special email offers and discounts."
                        />
                      </FormGroup>
                    </div>

                    <button
                      disabled={
                        !validEmail ||
                        !validPwd ||
                        !validMatch ||
                        !validZip ||
                        !validFName ||
                        !validLName ||
                        !validPhone
                          ? true
                          : false
                      }
                    >
                      Sign Up
                    </button>
                  </>
                )}
              </form>
              <div className="conditions-text">
                <p>{`This Sweepstake is independently run by GDHI Marketing LLC, dba. ImproveYourHouse.com. One [1] Grand Prize winner will be chosen randomly on or after March 27, 2024 from a list of entries received between 8:00 a.m., Mountain Time (“MT”) March 22, 2024 and 4:00 p.m. MT on March 27, 2024. This sweepstakes is open to legal residents of the forty-eight [48] contiguous United States and the District of Columbia, and Canada (excluding the province of Quebec) who have reached the age of majority in his or her state, province or territory of residence as of the Sweepstakes start date. The Grand Prize winner will receive a Credit (Paid out by ImproveYourHouse.com) to a confirmed purchase from a participating ImproveYourHouse.com Home Pro or Denver Home Show Exhibitor. Credit Amount (in currency of the winner's residing city, US or Canada). Average Retail Value (AVR) of this prize is $2000. `}</p>
                <br />
                {/* <button></button> */}
                <Link to="/form-terms-and-conditions">
                  Click Here to View our official sweepstakes rules
                </Link>
              </div>
              <br />
              <br />

              <p>
                Already signed up?
                <br />
                <span className="line">
                  <Link to="/login">Sign In</Link>
                </span>
              </p>
            </div>
            <div className="form-cta-div">
              <div className="cta-card">
                <h5>
                  Proud sponsor of the <br />
                  <span>Denver Home Show</span>
                </h5>
                <div className="line-display"></div>
                <h5>
                  Visit us at <br />
                  <span>BOOTH #705</span>
                </h5>
              </div>
            </div>
          </article>
        </section>
      )}
    </>
  );
};

export default HomeownerLandingPageForm;
