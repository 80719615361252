import { useEffect, useState } from "react";
import useAdminFormContext from "../hooks/useAdminFormContext";

const AdminFormStepState = () => {
  const { data, handleChange, setData } = useAdminFormContext();
  const statesList = data?.states;
  const activeState = data?.active_state;
  const activeStateInStatesList = statesList.find((state) => {
    return state.code === data?.active_state;
  });
  useEffect(() => {
    if (
      !activeStateInStatesList &&
      statesList &&
      statesList?.length &&
      statesList[0] &&
      statesList[0]?.code
    ) {
      setData((prevData) => ({
        ...prevData,
        active_state: statesList[0]?.code,
      }));
    }
  }, [data]);
	
  return (
    <>
      <p>Select the state you wish to market for.</p>
      <div className="noteWrapper">
        <p>
          <em className="subscriptionNote">
            If your state isn't listed, it isn't available for active marketing
            at this time.
          </em>
        </p>
      </div>
      {statesList?.length ? (
        <select
          id="active_state"
          name="active_state"
          value={activeState}
          onChange={handleChange}
        >
          {statesList?.map((s, i) => (
            <option
              data-active-state={s.status ? s.status : "disabled"}
              key={i}
              value={s.code}
            >
              {s.state}
            </option>
          ))}
        </select>
      ) : (
        "No states available"
      )}
    </>
  );
};

export default AdminFormStepState;
