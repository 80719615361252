import { useEffect, useState } from "react";
import { NavLink, Link, useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { AiOutlineDashboard } from 'react-icons/ai'
import { CgProfile } from 'react-icons/cg'
import { MdAccountBox } from 'react-icons/md'
import { FaUserCircle } from 'react-icons/fa'
import { GiStarsStack } from 'react-icons/gi'
import { MdOutlinePreview } from 'react-icons/md'
import { AiOutlineBarChart } from 'react-icons/ai'
import { HiChartSquareBar } from 'react-icons/hi'
import { ImBullhorn } from 'react-icons/im';
import { MdDashboard } from 'react-icons/md';
import { RiCustomerService2Line } from 'react-icons/ri';
import { MdHelpCenter } from 'react-icons/md';
import { AiFillMessage } from 'react-icons/ai';
import { MdMessage } from 'react-icons/md';
import { MdFavorite } from 'react-icons/md';
import { MdFactCheck } from 'react-icons/md';
import { MdRequestPage } from 'react-icons/md';
import { MdSettings } from 'react-icons/md';
import { MdWork } from 'react-icons/md';
import { MdPhotoCamera } from 'react-icons/md';
import { MdPhotoLibrary } from 'react-icons/md';
import { MdInfo } from 'react-icons/md';
import { MdPeople } from 'react-icons/md';
import { MdOutlineLocationCity } from 'react-icons/md';
import { MdPermContactCalendar } from 'react-icons/md';
import { MdArticle } from 'react-icons/md';
import { MdVideocam } from 'react-icons/md';
import { MdLogout } from 'react-icons/md';
import { MdHandyman } from 'react-icons/md';
import { GiHamburgerMenu } from 'react-icons/gi';
import useLogout from '../hooks/useLogout';
import { useMediaQuery } from 'react-responsive'
import Cookies from 'js-cookie'

const ContractorMenu = () => {

	const { auth, setAuth } = useAuth();

	const [isBackendMenuOpen, setIsBackendMenuOpen] = useState(false)

	const isBackendMobileMenu = useMediaQuery({
		query: '(max-width: 1050px)'
	})

	const navigate = useNavigate();
	const exit = useLogout();

	const signOut = async () => {
		Cookies.remove("iyh-can-refresh")
		await exit();
		// navigate('/');
	}

	const scrollToElement = (hash) => {
		var element = document.getElementById(hash);
		if (element != null){
			element.scrollIntoView({behavior:"smooth", block: "start"})
		}
	}

	const viewProfile = () => {
		window.open(`/contractor/${auth?.user?.slug}`, "_blank")
	}


	return (
		<>
			{isBackendMobileMenu &&
				<button className={`backendMobileMenu ${isBackendMenuOpen ? 'open' : null}`} onClick={(e) => setIsBackendMenuOpen(!isBackendMenuOpen)}><GiHamburgerMenu /> {isBackendMenuOpen ? 'Close Menu' : 'Contractor Menu'}</button>
			}
			<nav className={`${isBackendMobileMenu ? 'mobile' : null} ${isBackendMobileMenu && isBackendMenuOpen ? 'open' : null}`} id="user-menu">
				<ul>
					<li><NavLink to="/user/dashboard"><MdDashboard />Dashboard</NavLink></li>
					<li><NavLink to="/user/profile"><MdAccountBox />Profile
						<ul className="subMenu">
							<li className="viewProfile" onClick={viewProfile}><MdOutlinePreview />View Profile</li>
							<li onClick={() => scrollToElement("profilePhotos")}><MdPhotoCamera />Photos</li>
							<li onClick={() => scrollToElement("businessInfo")}><MdInfo />Info</li>
							<li onClick={() => scrollToElement("businessContact")}><MdPermContactCalendar />Contact</li>
							<li onClick={() => scrollToElement("businessLocation")}><MdOutlineLocationCity />Location</li>
							<li onClick={() => scrollToElement("businessAbout")}><MdArticle />About</li>
							<li onClick={() => scrollToElement("businessSocial")}><MdPeople />Social</li>
							<li onClick={() => scrollToElement("businessPortfolio")}><MdPhotoLibrary />Portfolio</li>
							<li onClick={() => scrollToElement("businessVideos")}><MdVideocam />Videos</li>
						</ul>
					</NavLink></li>
					{/* <li><NavLink to="/user/reviews"><GiStarsStack />Reviews</NavLink></li> */}
					<li><NavLink to="/user/projects"><MdHandyman />Shared Projects</NavLink></li>
					<li><NavLink to="/user/messages"><MdMessage />My Messages</NavLink></li>
					<li><NavLink to="/user/promotions"><ImBullhorn />Promotions</NavLink></li>
					<li><NavLink to="/user/metrics"><HiChartSquareBar />Metrics</NavLink></li>
					<li><NavLink to="/user/support"><MdHelpCenter />Support</NavLink></li>
					<li><NavLink to="/user/account"><MdSettings />My Account</NavLink></li>
					<li onClick={signOut} className="logout"><MdLogout />Logout</li>
				</ul>
			</nav>
		</>
	)
}

export default ContractorMenu