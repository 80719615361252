import { useEffect, useState } from "react";
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { AiOutlineDashboard } from 'react-icons/ai'
import { CgProfile } from 'react-icons/cg'
import { MdAccountBox } from 'react-icons/md'
import { FaUserCircle } from 'react-icons/fa'
import { GiStarsStack } from 'react-icons/gi'
import { AiOutlineBarChart } from 'react-icons/ai'
import { ImBullhorn } from 'react-icons/im';
import { MdDashboard } from 'react-icons/md';
import { RiCustomerService2Line } from 'react-icons/ri';
import { AiFillMessage } from 'react-icons/ai';
import { MdHelpCenter } from 'react-icons/md';
import { MdMessage } from 'react-icons/md';
import { MdWork } from 'react-icons/md';
import { MdLogout } from 'react-icons/md';
import { MdFavorite } from 'react-icons/md';
import { MdHandyman } from 'react-icons/md';
import { GiHamburgerMenu } from 'react-icons/gi';
import useLogout from '../hooks/useLogout';
import { useMediaQuery } from 'react-responsive'
import Cookies from 'js-cookie'

const HomeownerMenu = () => {

	const navigate = useNavigate();
	const exit = useLogout();

	const signOut = async () => {
		Cookies.remove("iyh-can-refresh")
		await exit();
		// navigate('/');
	}

	const [isBackendMenuOpen, setIsBackendMenuOpen] = useState(false)

	const isBackendMobileMenu = useMediaQuery({
		query: '(max-width: 1050px)'
	})

	return (
		<>
			{isBackendMobileMenu &&
				<button className={`backendMobileMenu ${isBackendMenuOpen ? 'open' : null}`} onClick={(e) => setIsBackendMenuOpen(!isBackendMenuOpen)}><GiHamburgerMenu /> {isBackendMenuOpen ? 'Close Menu' : 'Homeowner Menu'}</button>
			}
				<nav className={`${isBackendMobileMenu ? 'mobile' : null} ${isBackendMobileMenu && isBackendMenuOpen ? 'open' : null}`} id="user-menu">

					<ul>
						<li><NavLink to="/user/dashboard"><MdDashboard />Dashboard</NavLink></li>
						<li><NavLink to="/user/messages"><MdMessage />My Messages</NavLink></li>
						<li><NavLink to="/user/favorite-contractors"><MdFavorite />Favorites</NavLink></li>
						<li><NavLink to="/user/projects"><MdHandyman />Projects</NavLink></li>
						<li><NavLink to="/user/profile"><MdAccountBox />My Profile</NavLink></li>
						{/* <li><NavLink to="/user/reviews"><GiStarsStack />My Reviews</NavLink></li> */}
						<li><NavLink to="/user/support"><MdHelpCenter />Support</NavLink></li>
						<li onClick={signOut} className="logout"><MdLogout />Logout</li>
					</ul>
				</nav>
		</>
	)
}

export default HomeownerMenu