import { useState, useEffect } from "react";
import useLogout from "../hooks/useLogout";
import useAuth from "../hooks/useAuth";
import useRefreshToken from "../hooks/useRefreshToken";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AiOutlineLogin } from "react-icons/ai";
import { AiOutlineLogout } from "react-icons/ai";
import { RiAdminFill } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { FaEdit } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import { MdAccountBox } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import { GiStarsStack } from "react-icons/gi";
import { AiFillStar } from "react-icons/ai";
import { AiOutlineBarChart } from "react-icons/ai";
import { HiChartSquareBar } from "react-icons/hi";
import { MdHelpCenter } from "react-icons/md";
import { MdLocalOffer } from "react-icons/md";
import { MdMessage } from "react-icons/md";
import { MdFavorite } from "react-icons/md";
import { MdFactCheck } from "react-icons/md";
import { MdRequestPage } from "react-icons/md";
import { MdSettings } from "react-icons/md";
import { MdLogout } from "react-icons/md";
import { MdWork } from "react-icons/md";
import { MdHandyman } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { MdPeopleAlt } from "react-icons/md";
import { MdOutlineSearch } from "react-icons/md";
import { BsTelephoneInboundFill } from "react-icons/bs";
import { RiCustomerService2Line } from "react-icons/ri";
import { AiFillMessage } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Cookies from "js-cookie";
import { useMediaQuery } from "react-responsive";

const Navigation = () => {
  const { auth, editing, setEditing } = useAuth();
  const [toggle, setToggle] = useState(false);
  const [usersName, setUsersName] = useState();
  const [usersInitials, setUsersInitials] = useState();
  const [profilePicture, setProfilePicture] = useState();

  const refresh = useRefreshToken();

  const location = useLocation();
  const navigate = useNavigate();
  const exit = useLogout();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const isMobile = useMediaQuery({
    query: "(max-width: 800px)",
  });

  const signOut = async () => {
    Cookies.remove("iyh-can-refresh");
    await exit();
    // navigate('/');
  };

  useEffect(() => {
    let canRefresh = Cookies.get("iyh-can-refresh");

    if (canRefresh && canRefresh === "true") {
      console.log("Trying to log back in...");
      refresh();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setUsersName(`${auth?.user?.first_name} ${auth?.user?.last_name}`);
    setUsersInitials(`${auth?.user?.first_name[0]}${auth?.user?.last_name[0]}`);
    setProfilePicture(
      auth?.user?.profile_picture &&
        `https://storage.iyh.app/${auth?.user?.profile_picture}`
    );
    // eslint-disable-next-line
  }, [auth]);

  useEffect(() => {
    const state = toggle;
    setEditing(!editing);
  }, [toggle]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorMEl, setAnchorMEl] = React.useState(null);
  const mopen = Boolean(anchorMEl);
  const handleMenuClick = (event) => {
    setAnchorMEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorMEl(null);
  };

  return (
    <nav id="top-nav">
      {auth?.user && (
        <>
          <Tooltip title="Account Menu">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                className={profilePicture ? "image" : "initials"}
                src={profilePicture}
              >
                {usersInitials !== "uu" ? usersInitials : <CgProfile />}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 20,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem>
              <Avatar />
              {usersName}
            </MenuItem>
            {auth?.user?.role === "5150" ||
              (auth?.user?.role === "127001" && (
                <MenuItem
                  className={editing ? "enabled" : null}
                  onClick={() => setToggle(!toggle)}
                >
                  <ListItemIcon>
                    <FaEdit />
                  </ListItemIcon>
                  {editing ? "Stop Editing" : "Edit"} Page
                </MenuItem>
              ))}
            <Divider />
            {auth?.user?.role === "5150" || auth?.user?.role === "127001" ? (
              <>
                <Link to="/admin-dashboard">
                  <MenuItem>
                    <ListItemIcon>
                      <MdDashboard />
                    </ListItemIcon>
                    Dashboard
                  </MenuItem>
                </Link>
                <Link to="/admin-dashboard/tickets">
                  <MenuItem>
                    <ListItemIcon>
                      <MdLocalOffer />
                    </ListItemIcon>
                    Tickets
                  </MenuItem>
                </Link>
                <Link to="/admin-dashboard/users">
                  <MenuItem>
                    <ListItemIcon>
                      <MdPeopleAlt />
                    </ListItemIcon>
                    Users
                  </MenuItem>
                </Link>
                <Link to="/admin-dashboard/reviews">
                  <MenuItem>
                    <ListItemIcon>
                      <GiStarsStack />
                    </ListItemIcon>
                    Reviews
                  </MenuItem>
                </Link>
              </>
            ) : (
              <>
                <Link to="/user/dashboard">
                  <MenuItem>
                    <ListItemIcon>
                      <MdDashboard />
                    </ListItemIcon>
                    Dashboard
                  </MenuItem>
                </Link>
                <Link to="/user/profile">
                  <MenuItem>
                    <ListItemIcon>
                      <MdAccountBox />
                    </ListItemIcon>
                    Profile
                  </MenuItem>
                </Link>
                <Link to="/user/messages">
                  <MenuItem>
                    <ListItemIcon>
                      <MdMessage />
                    </ListItemIcon>
                    Messages
                  </MenuItem>
                </Link>
                {auth?.user?.role === "1001" && (
                  <Link to="/user/projects">
                    <MenuItem>
                      <ListItemIcon>
                        <MdHandyman />
                      </ListItemIcon>
                      Projects
                    </MenuItem>
                  </Link>
                )}
                {auth?.user?.role === "2002" && (
                  <Link to="/user/account">
                    <MenuItem>
                      <ListItemIcon>
                        <MdDashboard />
                      </ListItemIcon>
                      Account
                    </MenuItem>
                  </Link>
                )}
                <Link to="/user/support">
                  <MenuItem>
                    <ListItemIcon>
                      <MdHelpCenter />
                    </ListItemIcon>
                    Support
                  </MenuItem>
                </Link>
              </>
            )}
            <MenuItem onClick={signOut}>
              <ListItemIcon>
                <MdLogout />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </>
      )}
      {isMobile && !auth?.user && (
        <>
          <Tooltip title="Menu">
            <IconButton
              onClick={handleMenuClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={mopen ? "menu" : undefined}
              aria-haspopup="true"
              aria-expanded={mopen ? "true" : undefined}
              class="mobileMenu"
            >
              <GiHamburgerMenu />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorMEl}
            id="menu"
            open={mopen}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 16,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <Link to="/become-a-home-pro">
              <MenuItem>Home Pro</MenuItem>
            </Link>
            <Link to="/become-a-homeowner">
              <MenuItem>Homeowner</MenuItem>
            </Link>
            <Divider />
            <Link to="/login">
              <MenuItem>Login</MenuItem>
            </Link>
          </Menu>
          <Tooltip title="Home Pro Search">
            <Link className="search" to="/">
              <MdOutlineSearch />
            </Link>
          </Tooltip>
        </>
      )}
      {!auth?.user ? (
        <>
          {!isMobile && (
            <ul className="headerButtons">
              <li className="search" onClick={() => handleNavigation('/')}>
                <Link to="/">
                  <MdOutlineSearch />
                </Link>
              </li>
              <li className="button" onClick={() => handleNavigation('/become-a-home-pro')}>
                <Link to="/become-a-home-pro">Home Pro</Link>
              </li>
              <li className="button" onClick={() => handleNavigation('/become-a-homeowner')}>
                <Link to="/become-a-homeowner">Homeowner</Link>
              </li>
              <li className="button red" onClick={() => handleNavigation('/login')}>
                <Link to="/login" state={{ from: location.pathname }}>
                  Login/Signup
                </Link>
              </li>
            </ul>
          )}
        </>
      ) : (
        <ul className="headerButtons">
          {auth?.user?.role === "5150" || auth?.user?.role === "127001" ? (
            <>
              {!isMobile ? (
                <>
                  <li className="search" onClick={() => handleNavigation('/')}>
                    <Link to="/">
                      <MdOutlineSearch />
                    </Link>
                  </li>
                  <li className="button" onClick={() => handleNavigation('/become-a-home-pro')}>
                    <Link to="/become-a-home-pro">Home Pro</Link>
                  </li>

                  <li className="button" onClick={() => handleNavigation('/become-a-homeowner')}>
                    <Link to="/become-a-homeowner">Homeowner</Link>
                  </li>
                </>
              ) : (
                <>
                  <Tooltip title="Menu">
                    <IconButton
                      onClick={handleMenuClick}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={mopen ? "menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={mopen ? "true" : undefined}
                      class="mobileMenu"
                    >
                      <GiHamburgerMenu />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={anchorMEl}
                    id="menu"
                    open={mopen}
                    onClose={handleMenuClose}
                    onClick={handleMenuClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 16,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <Link to="/become-a-home-pro">
                      <MenuItem>Home Pro</MenuItem>
                    </Link>
                    <Link to="/become-a-homeowner">
                      <MenuItem>Homeowner</MenuItem>
                    </Link>
                  </Menu>
                </>
              )}
            </>
          ) : (
            <>
              {auth?.user?.role !== "2002" && (
                <>
                  {isMobile ? (
                    <Tooltip title="Home Pro Search">
                      <li>
                        <Link className="search" to="/">
                          <MdOutlineSearch />
                        </Link>
                      </li>
                    </Tooltip>
                  ) : (
                    <li className="button">
                      <Link to="/">Home Pro Search</Link>
                    </li>
                  )}
                </>
              )}
            </>
          )}
        </ul>
      )}
    </nav>
  );
};

export default Navigation;
