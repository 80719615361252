import { useEffect, useRef, useState } from "react"
import { Link, useNavigate, useLocation } from "react-router-dom"
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import usePageTitle from '../../hooks/usePageTitle'
import UserPageNav from '../../components/UserPageNav'
import ContractorPackages from '../../components/ContractorPackages'
import useAuth from '../../hooks/useAuth'
import AccountConfigureForm from "../../components/AccountConfigureForm"
import FormProgressBar from "../../components/FormProgressBar"
import { FormProvider } from '../../context/FormContext'
import { AiOutlineDashboard } from 'react-icons/ai'
import { CgProfile } from 'react-icons/cg'
import { MdAccountBox } from 'react-icons/md'
import { FaUserCircle } from 'react-icons/fa'
import { GiStarsStack } from 'react-icons/gi'
import { AiOutlineBarChart } from 'react-icons/ai'
import { HiChartSquareBar } from 'react-icons/hi'
import { ImBullhorn } from 'react-icons/im';
import { MdDashboard } from 'react-icons/md';
import { RiCustomerService2Line } from 'react-icons/ri';
import { MdHelpCenter } from 'react-icons/md';
import { AiFillMessage } from 'react-icons/ai';
import { MdMessage } from 'react-icons/md';
import { MdFavorite } from 'react-icons/md';
import { MdFactCheck } from 'react-icons/md';
import { MdRequestPage } from 'react-icons/md';
import { MdSettings } from 'react-icons/md';
import { MdWork } from 'react-icons/md';
import { useMediaQuery } from 'react-responsive'

const AccountConfigure = () => {

	usePageTitle("Configure Account")

	const [loading, setLoading] = useState(false);
	const [config, setConfig] = useState();
	const [packages, setPackages] = useState();
	const [selectedPackage, setSelectedPackage] = useState();
	const axiosPrivate = useAxiosPrivate();

	const auth = useAuth()
	const role = auth?.roles
	const userID = auth?.user?.id
	const subscription = auth?.auth?.subscription
	const contractorStateAbv = auth?.auth?.user?.state
	const contractorZip = auth?.auth?.user?.zip
	const [contractorState, setContractorState] = useState()
	const navigate = useNavigate()
	const location = useLocation()

	const isMobile = useMediaQuery({
		query: '(max-width: 650px)'
	})

	return (
		<section>
			<article className="inner">
				<div className="backendTitleBar">
					<h1>Configuring subscription {subscription?.package_details?.package_name}</h1>
					<MdDashboard />
				</div>
				<div className="backendColumnWrapper">
					<div id="menu" className="column">
						<UserPageNav />
					</div>
					<div className="column accountConfigure">
						<FormProvider>
				  			<FormProgressBar isMobile={isMobile}/>
				  			<AccountConfigureForm />
						</FormProvider>
					</div>
				</div>



				{/* {subscription ?
					<>
						<div className="entryContent">
							<p>Configuring subscription {subscription?.package_details?.package_name}</p>
							<p>Your subscription package includes {subscription?.package_details?.num_allowed_services} service categories for {subscription?.package_details?.num_allowed_counties} counties.</p>
							<p>You can add up to {subscription?.package_details?.num_max_allowed_services - subscription?.package_details?.num_allowed_services} additional service categories for $50 each.</p>
						</div>
						{ !confirmState && !newState &&
							<>
								<p>Based on your business location do you want to continue marketing with state, {contractorState?.State}?</p>
								<div className="buttonWrapper">
									<button onClick={() => setConfirmState(!confirmState)}>Confirm State</button>
									<button onClick={() => setNewState(!newState)}>Select New State</button>
								</div>
							</>
						}
					</>
				: "no selected package"} */}

			</article>
		</section>
	)
}

export default AccountConfigure