import { useEffect, useState } from "react";
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Loader from './Loader';
import ApexCharts from 'apexcharts'
import DashboardItem from './DashboardItem';


const FavoriteMetrics = () => {

	const axiosPrivate = useAxiosPrivate();
	const [loading, setLoading] = useState(false);
	const [metrics, setMetrics] = useState('');

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getFavoriteMetrics = async () => {
			try {
				const response = await axiosPrivate.get('metrics/favorite', {
					signal: controller.signal
				});
				setMetrics(response.data);
				setLoading(false)
			} catch(err) {
				console.log(err.message);
				{/* !auth && navigate('/login', { state: { from: location }, replace: true }); */}
			}
		}

		getFavoriteMetrics();

		return () => controller.abort();
		// eslint-disable-next-line
	}, [])

	return (
		<>
		{loading ?
			<Loader />
			:

			<>
				<div className="dashboardItemWrapper">
					<DashboardItem title="Favorited" description="(Last 30 days)" includeTime="yes" count={metrics.last_month} />
					<DashboardItem title="Favorited" description="(Last 6 Months)" includeTime="yes" count={metrics.last_6_months} />
					<DashboardItem title="Favorited" description="(Last Year)" includeTime="yes" count={metrics.last_year} />
					<DashboardItem title="Favorited" description="(Lifetime)" includeTime="yes" count={metrics.lifetime} />
				</div>
			</>
		}
		</>
	)
}

export default FavoriteMetrics