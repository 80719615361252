import { useEffect, useState } from "react";
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Loader from './Loader';
import DashboardItem from './DashboardItem';
import { ImBullhorn } from 'react-icons/im';
import { AiOutlineDashboard } from 'react-icons/ai'
import { AiFillPhone } from 'react-icons/ai'
import { CgProfile } from 'react-icons/cg'
import { MdAccountBox } from 'react-icons/md'
import { FaUserCircle } from 'react-icons/fa'
import { GiStarsStack } from 'react-icons/gi'
import { AiFillStar } from 'react-icons/ai'
import { AiOutlineBarChart } from 'react-icons/ai'
import { HiChartSquareBar } from 'react-icons/hi'
import { MdDashboard } from 'react-icons/md';
import { RiCustomerService2Line } from 'react-icons/ri';
import { MdHelpCenter } from 'react-icons/md';
import { AiFillMessage } from 'react-icons/ai';
import { MdMessage } from 'react-icons/md';
import { MdFavorite } from 'react-icons/md';
import { MdFactCheck } from 'react-icons/md';
import { MdRequestPage } from 'react-icons/md';
import { MdSettings } from 'react-icons/md';
import { MdWork } from 'react-icons/md';
import { MdEmail } from 'react-icons/md';
import { MdHandyman } from 'react-icons/md';
import { MdLink } from 'react-icons/md';
import { BsTelephoneInboundFill } from 'react-icons/bs';
import useAuth from '../hooks/useAuth';

const ContractorDashboard = () => {

	const axiosPrivate = useAxiosPrivate();
	const [loading, setLoading] = useState(false);

	const auth = useAuth();
	const subscription = auth?.auth?.subscription

	const [promotions, setPromotions] = useState('');
	const [messages, setMessages] = useState('');
	const [favorites, setFavorites] = useState('');
	const [reviews, setReviews] = useState('');
	const [account, setAccount] = useState('');
	const [contacts, setContacts] = useState('');
	const [projects, setProjects] = useState('');

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getDashboardOverview = async () => {
			try {
				const response = await axiosPrivate.get('dashboard-overview', {
					signal: controller.signal
				});
				setPromotions(response.data.promotions);
				setFavorites(response.data.favorites);
				setMessages(response.data.messages);
				setReviews(response.data.reviews);
				setAccount(response.data.account);
				setContacts(response.data.contacts);
				setProjects(response.data.projects);
				setLoading(false)
			} catch(err) {
				console.log(err.message);
				{/* !auth && navigate('/login', { state: { from: location }, replace: true }); */}
			}
		}

		getDashboardOverview();

		return () => controller.abort();
		// eslint-disable-next-line
	}, [])

	return (
		<>
		{loading ? <Loader />
		:
		<div className="overview">
			<div className="dashboardItemWrapper">
				<DashboardItem title="Unread Messages" icon={<MdMessage />} count={messages.unread} link="/user/messages" />
				<DashboardItem title="Projects to Review" icon={<MdHandyman />} count={messages.unread} link="/user/projects" />
				<DashboardItem title="Pending Reviews" icon={<GiStarsStack />} count={reviews.pending} link="/user/reviews" />
			</div>

			<div className="dashboardItemWrapper stats">
				<h3>Stats</h3>
				<DashboardItem title="Unique Views" icon={<MdAccountBox />} count={contacts.views ? contacts.unique_views : "N/A" } />
				<DashboardItem title="Total Views" icon={<MdAccountBox />} count={contacts.views ? contacts.views : "N/A" } />
				<DashboardItem title="Phone Calls" icon={<AiFillPhone />} count={contacts.calls ? contacts.calls : "N/A" } />
				<DashboardItem title="Emails Received" icon={<MdEmail />} count={contacts.emails ? contacts.emails : "N/A" } />
				<DashboardItem title="Website Clicks" icon={<MdLink />} count={contacts.website} />
				<DashboardItem title="Favorited" icon={<MdFavorite />} count={favorites.count} />
				<DashboardItem title="Reviewed" icon={<GiStarsStack />} count={reviews.count} />
				<DashboardItem title="Projects Shared" icon={<MdHandyman />} count={projects.shared} />
			</div>

			<div className="dashboardItemWrapper">
			{!subscription ?
				<DashboardItem id="account" title={'You are not visible in search yet!. Click to select a package.'} link="/user/account" />
				:
				null
			}
			{subscription == null || subscription?.subscription_status == "chosen" &&
				<DashboardItem id="account" title={subscription == null || subscription?.subscription_status == "chosen" ? `You are not visible in search yet!. Click to select a package.` : `Your account is currently ${subscription.subscription_status} with subscription "${subscription?.package_details?.package_name}".`} link="/user/account" />
			}
			</div>

		</div>
		}
		</>
	)
}

export default ContractorDashboard