import usePageTitle from '../hooks/usePageTitle';
import Page from '../components/Page';

const Careers = () => {
	usePageTitle("Join Our Team");
	return (
		<section>
			<Page title='Join Our Team' slug='careers' />
		</section>
	)
}

export default Careers