const ZohoFormThankYou = () => {
  return (
    <section>
      <article className="inner">
        <h1 className="case">Thank You for Contacting Improve Your House</h1>
        <p>
          We have successfully received your inquiry. Someone from the Improve
          Your House team will review your request and be in contact with you.
        </p>
      </article>
    </section>
  );
};

export default ZohoFormThankYou;
