
import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';
import { FaExclamation } from 'react-icons/fa';
import usePageTitle from '../hooks/usePageTitle';
import UserPageNav from '../components/UserPageNav';
import useAuth from '../hooks/useAuth';
import { ToastContainer, toast } from 'react-toastify';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const statesList = [
	{
		"State": "Alabama",
		"Code":	"AL"
	},
	{
		"State": "Alaska",
		"Code":	"AK"
	},
	{
		"State": "Arizona",
		"Code":	"AZ"
	},
	{
		"State": "Arkansas",
		"Code":	"AR"
	},
	{
		"State": "California",
		"Code":	"CA"
	},
	{
		"State": "Colorado",
		"Code":	"CO"
	},
	{
		"State": "Connecticut",
		"Code":	"CT"
	},
	{
		"State": "Delaware",
		"Code":	"DE"
	},
	{
		"State": "District of Columbia",
		"Code":	"DC"
	},
	{
		"State": "Florida",
		"Code":	"FL"
	},
	{
		"State": "Georgia",
		"Code":	"GA"
	},
	{
		"State": "Hawaii",
		"Code":	"HI"
	},
	{
		"State": "Idaho",
		"Code":	"ID"
	},
	{
		"State": "Illinois",
		"Code":	"IL"
	},
	{
		"State": "Indiana",
		"Code":	"IN"
	},
	{
		"State": "Illinois",
		"Code":	"IL"
	},
	{
		"State": "Iowa",
		"Code":	"IA"
	},
	{
		"State": "Kansas",
		"Code":	"KS"
	},
	{
		"State": "Kentucky",
		"Code":	"KY"
	},
	{
		"State": "Louisiana",
		"Code":	"LA"
	},
	{
		"State": "Maine",
		"Code":	"ME"
	},
	{
		"State": "Maryland",
		"Code":	"MD"
	},
	{
		"State": "Massachusetts",
		"Code":	"MA"
	},
	{
		"State": "Michigan",
		"Code":	"MI"
	},
	{
		"State": "Minnesota",
		"Code":	"MN"
	},
	{
		"State": "Mississippi",
		"Code":	"MS"
	},
	{
		"State": "Missouri",
		"Code":	"MO"
	},
	{
		"State": "Montana",
		"Code":	"MT"
	},
	{
		"State": "Nebraska",
		"Code":	"NE"
	},
	{
		"State": "Nevada",
		"Code":	"NV"
	},
	{
		"State": "New Hampshire",
		"Code":	"NH"
	},
	{
		"State": "New Jersey",
		"Code":	"NJ"
	},
	{
		"State": "New Mexico",
		"Code":	"NM"
	},
	{
		"State": "New York",
		"Code":	"NY"
	},
	{
		"State": "North Carolina",
		"Code":	"NC"
	},
	{
		"State": "North Dakota",
		"Code":	"ND"
	},
	{
		"State": "Ohio",
		"Code":	"OH"
	},
	{
		"State": "Oklahoma",
		"Code":	"OK"
	},
	{
		"State": "Oregon",
		"Code":	"OR"
	},
	{
		"State": "Pennsylvania",
		"Code":	"PA"
	},
	{
		"State": "Rhode Island",
		"Code":	"RI"
	},
	{
		"State": "South Carolina",
		"Code":	"SC"
	},
	{
		"State": "South Dakota",
		"Code":	"SD"
	},
	{
		"State": "Tennessee",
		"Code":	"TN"
	},
	{
		"State": "Texas",
		"Code":	"TX"
	},
	{
		"State": "Utah",
		"Code":	"UT"
	},
	{
		"State": "Vermont",
		"Code":	"VT"
	},
	{
		"State": "Virginia",
		"Code":	"VA"
	},
	{
		"State": "Washington",
		"Code":	"WA"
	},
	{
		"State": "West Virginia",
		"Code":	"WV"
	},
	{
		"State": "Wisconsin",
		"Code":	"WI"
	},
	{
		"State": "Wyoming",
		"Code":	"WY"
	}
]

const NAME_REGEX = /^[a-zA-Z0-9-_&@, ]{1,50}$/;
const DESCRIPTION_REGEX = /^[a-zA-Z0-9-_.,& ]{0,500}$/;

const CreateMarket = ({ addMarket }) => {

	const { auth } = useAuth();
	const userID = auth?.user?.id;
	let isVerified = auth?.user?.email_verified_at;

	const axiosPrivate = useAxiosPrivate();

	const [showAdd, setShowAdd] = useState(false)

	const nameRef = useRef();
	const descriptionRef = useRef();

	const [name, setName] = useState("");
	const [validName, setValidName] = useState(false);
	const [nameFocus, setNameFocus] = useState(false);

	const [description, setDescription] = useState("");
	const [validDescription, setValidDescription] = useState(false);

	const [state, setState] = useState()

	const [open, setOpen] = useState(false);

	const handleStateChange = () => {

	}

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setName("");
		setDescription("");
	};

	/* Set Focus to name on load */
	useEffect(() => {
		showAdd && nameRef.current.focus()
	}, [])

	/* Check Name */
	useEffect(() => {
		const result = NAME_REGEX.test(name);
		setValidName(result);
	}, [name])

	/* Check Description */
	useEffect(() => {
		const result = DESCRIPTION_REGEX.test(description);
		setValidDescription(result);
	}, [description])

	const handleSubmit = async (e) => {
		e.preventDefault();

		const dismiss = () =>  toast.dismiss(addMarketToast.current);
		const addMarketToast = toast.loading("Adding Market");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.post('market',
			JSON.stringify({market_name: name, market_description: description, market_state: state}),
			{
				signal: controller.signal
			});

			// Get market id from database
			const id = response?.data?.market?.id
			const market_name = name
			const market_description = description
			const market_state = state

			// Get date from database
			const created_at = response?.data?.market?.created_at

			// Add market to markets
			addMarket({id, market_name, market_description, created_at, market_state})

			// Clear Form
			setName("");
			setDescription("");

			// Close popup
			setOpen(false);

			// Update Toast Notification
			toast.update(addMarketToast, { render: 'Market Added', type: 'success', isLoading: false, autoClose: 5000});
		} catch (err) {
			if (!err?.response) {
				toast.update(addMarketToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else if (err.response?.status === 401) {
				toast.update(addMarketToast, { render: 'Authorization Failed', type: 'error', isLoading: false});
				{/* setErrMsg('The email has already been taken.'); */}
			} else {
				toast.update(addMarketToast, { render: 'Market Add Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	return (
		<>
		<button onClick={handleClickOpen}>Add Market</button>

		{/* Add Market Dialog */}
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
			<DialogTitle id="alert-dialog-title">
			  {"Add a Market"}
			</DialogTitle>
			<DialogContent>
				<>
				<form>
					{/* Name */}
					<label htmlFor="name">Market Name:</label>
					<div className="inputWrapper">
						<span className={validName ? "valid" : "hide"}><FaCheck /></span>
						<span className={validName || !name ? "hide" : "invalid"}><FaExclamation /></span>
						<input
							type="text"
							id="name"
							className={validName || !name ? null : "error"}
							ref={nameRef}
							autoComplete="off"
							onChange={(e) => setName(e.target.value)}
							value={name}
							aria-invalid={validName ? "false" : "true"}
							aria-describedby="namenote"
							onFocus={() => setNameFocus(true)}
							onBlur={() => setNameFocus(false)}
						/>
					</div>
					<p id="namenote" className={nameFocus && name && !validName ? "instructions" : "offscreen"}>
						Invalid Market Name. 1-50 characters (letters, numbers, dash, comma and underscore allowed)
					</p>

					{/* Description */}
					<label htmlFor="description">Description:</label>
					<div className="inputWrapper">
						<span className={validDescription ? "valid" : "hide"}><FaCheck /></span>
						<span className={validDescription || !description ? "hide" : "invalid"}><FaExclamation /></span>
						<textarea
							ref={descriptionRef}
							onChange={(e) => setDescription(e.target.value)}
							name="description"
							id="description"
							rows="5"
							value={description}
						>
						</textarea>
						<p id="descriptionnote" className={nameFocus && description && !validDescription ? "instructions" : "offscreen"}>
							Invalid Description. Between 1-500 characters (letters, numbers, dash and underscore allowed)
						</p>
					</div>

					{/* States */}
					<label htmlFor="description">State:</label>
					<div className="inputWrapper">
						{statesList?.length ?
							<select
								id="active_state"
								name="active_state"
								value={state}
								onChange={(e) => setState(e.target.value)}
							>
								{statesList?.map((s, i) =>
									<option key={i} value={s.Code}>{s.State}</option>
								)}

							</select>
							: "No states available"
						}
					</div>

				</form>
				</>
			</DialogContent>
			<DialogActions>
			  <button className="cancel" onClick={handleClose}>Cancel</button>
			  <button className="confirm" onClick={handleSubmit} disabled={!validName || !validDescription ? true : false}>Add Market</button>
			</DialogActions>
		</Dialog>

			<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
			/>
		</>
	)
}

export default CreateMarket