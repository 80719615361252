import { useEffect, useRef, useState } from "react"
import { Link, useNavigate, useLocation } from "react-router-dom"
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import usePageTitle from '../../../hooks/usePageTitle'
import UserPageNav from '../../../components/UserPageNav'
import ContractorPackages from '../../../components/ContractorPackages'
import useAuth from '../../../hooks/useAuth'
import FormProgressBar from "../../../components/FormProgressBar"
import { FormProvider } from '../../../context/FormContext'
import { AiOutlineDashboard } from 'react-icons/ai'
import { CgProfile } from 'react-icons/cg'
import { MdAccountBox } from 'react-icons/md'
import { FaUserCircle } from 'react-icons/fa'
import { GiStarsStack } from 'react-icons/gi'
import { AiOutlineBarChart } from 'react-icons/ai'
import { HiChartSquareBar } from 'react-icons/hi'
import { ImBullhorn } from 'react-icons/im';
import { MdDashboard } from 'react-icons/md';
import { RiCustomerService2Line } from 'react-icons/ri';
import { MdHelpCenter } from 'react-icons/md';
import { AiFillMessage } from 'react-icons/ai';
import { MdMessage } from 'react-icons/md';
import { MdFavorite } from 'react-icons/md';
import { MdFactCheck } from 'react-icons/md';
import { MdRequestPage } from 'react-icons/md';
import { MdSettings } from 'react-icons/md';
import { MdWork } from 'react-icons/md';
import { useMediaQuery } from 'react-responsive'
import AdminAccountConfigureForm from "./AdminAccountConfigureForm"
import { AdminFormProvider } from "../../../context/AdminFormContext"
import AdminFormProgressBar from "../../../components/AdminFormProgressBar"
import useAdminFormContext from "../../../hooks/useAdminFormContext"

const AdminAccountConfigure = ({userData}) => {

	usePageTitle("Configure Account")

	const [loading, setLoading] = useState(false);
	const [config, setConfig] = useState();
	const [packages, setPackages] = useState();
	const [selectedPackage, setSelectedPackage] = useState();
	const [activePackages, setActivePackages] = useState();
	const axiosPrivate = useAxiosPrivate();

	useEffect(() => {console.log("activePackages", activePackages)},[activePackages])

	const auth = useAuth()
	const adminFormUserData = useAdminFormContext()
	// console.log("user Data", userData)
	// console.log("adminFormUserData ------------------------", adminFormUserData)
	// const role = auth?.roles
	// const userID = auth?.user?.id
	// const subscription = auth?.auth?.subscription
	// const contractorStateAbv = auth?.auth?.user?.state
	// const contractorZip = auth?.auth?.user?.zip
	const [contractorState, setContractorState] = useState()
	const [refreshUserData, setRefreshUserData] = useState()
	const navigate = useNavigate()
	const location = useLocation()

	const isMobile = useMediaQuery({
		query: '(max-width: 650px)'
	})

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getActivePackages = async () => {
			try {
				const response = await axiosPrivate.get('contractor-packages', {
					signal: controller.signal
				});
				setActivePackages(response.data);
				setLoading(false)
				// console.log("Admin Account Configure response", response)
			} catch(err) {
				console.log(err.message);
			}
		}

		getActivePackages();

		return () => controller.abort();
		// eslint-disable-next-line
	},[])

	useEffect(() => {
		const handlePackage = async (name, id) => {
			// setOpenSubscriptionChange(false)
			// const dismiss = () =>  toast.dismiss(addSubscriptionToast.current);
			// const addSubscriptionToast = toast.loading("Subscription Updating");
			// console.log("userData.id", userData.id)
			const controller = new AbortController();
			try {
				const response = await axiosPrivate.post('subscription',
				JSON.stringify(
					{subscription_status: 'chosen', 
					package_id: activePackages[0]?.id, 
					admin_call: true, 
					user_id:userData.id}
				),
				{
					signal: controller.signal
				});
	
				// console.log("response -----------", response)
				// Update auth state to include selected package
				let responseSubscription = response.data.subscription
				responseSubscription.package_details = activePackages[0]
				adminFormUserData.setData({...adminFormUserData.data, subscription: response.data.subscription, });
	
				// Update Toast Notification
				// toast.update(addSubscriptionToast, { render: 'Subscription Selected', type: 'success', isLoading: false, autoClose: 5000});
			} catch (err) {
				if (!err?.response) {
					console.log("err !err?.response",err)
					// toast.update(addSubscriptionToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
					{/* setErrMsg('No Server Response'); */}
				} else if (err.response?.status === 401) {
					console.log("err err.response?.status === 401",err)
					// toast.update(addSubscriptionToast, { render: 'Authorization Failed', type: 'error', isLoading: false});
					{/* setErrMsg('The email has already been taken.'); */}
				} else {
					console.log("err",err)
					// toast.update(addSubscriptionToast, { render: 'Subscription Failed', type: 'error', isLoading: false, autoClose: 5000});
					{/* setErrMsg('Registration Failed'); */}
				}
				{/* errRef.current.focus(); */}
			}
			return () => controller.abort();
	
		}
		if (activePackages) {
			handlePackage()

		}
	}, [activePackages])


	return (
		<section className="adminForm">
			<article className="inner">
				<div className="backendTitleBar">
					<h1>Configuring subscription {adminFormUserData?.data?.subscription?.package_details?.package_name}</h1>
					<MdDashboard />
				</div>
				<div className="backendColumnWrapper">
					{/* <div id="menu" className="column">
						<UserPageNav />
					</div> */}
					<div className="column accountConfigure">
				  			<AdminFormProgressBar isMobile={isMobile}/>
				  			<AdminAccountConfigureForm />
					</div>
				</div>
			</article>
		</section>
	)
}

export default AdminAccountConfigure