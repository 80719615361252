import usePageTitle from '../hooks/usePageTitle';
import Page from '../components/Page';

const FAQ = () => {
	usePageTitle("FAQ");
	return (
		<section>
			<Page title='Frequently Asked Questions' slug='frequently-asked-questions' />
		</section>
	)
}

export default FAQ