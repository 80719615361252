import usePageTitle from '../hooks/usePageTitle';
import Page from '../components/Page';

const PrivacyPolicy = () => {
	usePageTitle("Privacy Policy");
	return (
		<section>
			<Page title='Privacy Policy' slug='privacy-policy' />
		</section>
	)
}

export default PrivacyPolicy