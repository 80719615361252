import { Outlet } from 'react-router-dom';
import LegalHeader from '../components/LegalHeader';
import LegalFooter from '../components/LegalFooter';
import { useState, useEffect } from 'react';
import LegalMenu from './LegalMenu';

const LegalLayout = () => {

	return (
		<main className="App legal">
			<LegalHeader />
			<section>
				<aside>
					<LegalMenu />
				</aside>
				<Outlet />
			</section>
			<LegalFooter />
		</main>
	)
}

export default LegalLayout