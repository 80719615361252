import AdminMenu from '../../components/AdminMenu'
import Categories from "../../components/Categories"
import { MdDonutSmall } from 'react-icons/md';

const ServiceCategories = () => {
	return (
		<section id="adminCategories">
			<article className="inner">
				<div className="backendTitleBar">
					<h1>Service Categories</h1>
					<MdDonutSmall />
				</div>
				<div className="backendColumnWrapper">
					<div id="menu" className="column">
						<AdminMenu />
					</div>
					<div id="content" className="column">
						<Categories />
					</div>
				</div>
			</article>
		</section>
	)
}


export default ServiceCategories