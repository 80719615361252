import usePageTitle from '../hooks/usePageTitle';

const Error = () => {
	usePageTitle("Error 404");
	return (
		<section>
			<article className='inner'>
				<h1>Error 404</h1>
				<p>The page you are looking for can't be found.</p>
			</article>
		</section>
	)
}

export default Error