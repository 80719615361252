import { useNavigate } from 'react-router-dom'
import usePageTitle from '../hooks/usePageTitle';

const Unauthorized = () => {
	const navigate = useNavigate();
	
	const goBack = () => navigate(-1);
	usePageTitle("Unauthorized");
	return (
		<section>
			<article className='inner'>
				<h1>Unauthorized</h1>
				<p>You do not have access to this page.</p>
				<div className="flexGrow">
					<button onClick={goBack}>Go Back</button>
				</div>
			</article>
		</section>
	)
}

export default Unauthorized