import { useEffect, useState, useRef } from "react";
import {
  useLocation,
  NavLink,
  useParams,
  Link,
  useOutletContext,
  useNavigate,
} from "react-router-dom";
import usePageTitle from "../hooks/usePageTitle";
import useAxiosFunction from "../hooks/useAxiosFunction";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import ContractorActions from "../components/ContractorActions";
import ContractorSocial from "../components/ContractorSocial";
import Loader from "../components/Loader";
import Rating from "@mui/material/Rating";
import CoverActions from "../components/CoverActions";
import { GoAlert } from "react-icons/go";
import { BsChatRightTextFill } from "react-icons/bs";
import { ReactComponent as QuotesIcon } from "../assets/images/iyh_quotes-icon.svg";
import { ReactComponent as AwardsIcon } from "../assets/images/iyh_awards-icon.svg";
import { ReactComponent as AboutIcon } from "../assets/images/iyh_about-icon.svg";
import { AiFillPhone } from "react-icons/ai";
import { AiOutlinePhone } from "react-icons/ai";
import { AiFillMessage } from "react-icons/ai";
import { AiOutlineMessage } from "react-icons/ai";
import { AiFillHome } from "react-icons/ai";
import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
import { AiOutlineLink } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { AiOutlineEnvironment } from "react-icons/ai";
import { AiOutlineVerified } from "react-icons/ai";
import ImageGallery from "react-image-gallery";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ToastContainer, toast } from "react-toastify";
import SendMessage from "../components/SendMessage";
import ContractorNameSearch from "../components/ContractorNameSearch";
import ReactPlayer from "react-player";
import { Col, Container, Row } from "react-bootstrap";

const ViewContractor = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { auth } = useAuth();
  const userType = auth?.user?.role;
  const userID = auth?.user?.id;
  const userName = `${auth?.user?.first_name} ${auth?.user?.last_name}`;

  const axiosPrivate = useAxiosPrivate();
  const [loadingPrivate, setLoadingPrivate] = useState(false);

  const [contractor, error, loading, axiosFetch] = useAxiosFunction();
  const { slug } = useParams();

  const portfolio = contractor?.portfolio;
  const [portfolioImages, setPortfolioImages] = useState([]);

  const [geoLocation, setGeoLocation] = useOutletContext();

  const [ip, setIP] = useState();
  const [city, setCity] = useState();
  const [zip, setZip] = useState();

  const [message, setMessage] = useState("");
  const [validMessage, setValidMessage] = useState(false);
  const [validShare, setValidShare] = useState(false);

  const [open2, setOpen2] = useState(false);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const getData = () => {
    axiosFetch({
      axiosInstance: axios,
      method: "get",
      url: "contractor-by-slug/" + slug + "?uvid=" + geoLocation?.ip_address,
    });
  };

  useEffect(() => {
    // if not a hash link, scroll to top
    if (location.hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = location.hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [location]);

  useEffect(() => {
    getData();
    // if not a hash link, scroll to top
    if (location.hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = location.hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Build Gallery Array
    let images = [];
    portfolio?.map((item, i) =>
      images.push({
        original: `https://storage.iyh.app/${item.path}`,
        thumbnail: `https://storage.iyh.app/${item.path}`,
      })
    );
    setPortfolioImages(images);
  }, [portfolio]);

  function formatPhone(phone) {
    //Filter only numbers from the input
    let cleaned = ("" + phone).replace(/\D/g, "");

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }

    return null;
  }

  function formatTelLink(phone) {
    //Filter only numbers from the input
    let cleaned = ("" + phone).replace(/\D/g, "");
    return cleaned;
  }

  const incrementPhoneCount = async (e) => {
    const controller = new AbortController();
    console.log("increment phone count");

    // Needs to be public route

    return () => controller.abort();
  };

  const incrementEmailCount = async (e) => {
    if (contractor?.id) {
      const controller = new AbortController();
      setLoadingPrivate(true);
      try {
        const response = await axiosPrivate.get(
          "increment-email/" + contractor.id,
          {
            signal: controller.signal,
          }
        );
        setLoadingPrivate(false);
      } catch (err) {
        console.log(err.message);
        {
          /* !auth && navigate('/login', { state: { from: location }, replace: true }); */
        }
      }
      return () => controller.abort();
    }
  };

  const incrementWebsiteCount = async (e) => {
    if (contractor?.id) {
      const controller = new AbortController();
      setLoadingPrivate(true);
      try {
        const response = await axiosPrivate.get(
          "increment-website/" + contractor.id,
          {
            signal: controller.signal,
          }
        );
        setLoadingPrivate(false);
      } catch (err) {
        console.log(err.message);
        {
          /* !auth && navigate('/login', { state: { from: location }, replace: true }); */
        }
      }
      return () => controller.abort();
    }
  };

  const handleSendMessage = async (e) => {
    const controller = new AbortController();
    const dismiss = () => toast.dismiss(sendMessageToast.current);
    const sendMessageToast = toast.loading("Sending Message");
    try {
      const response = await axiosPrivate.post(
        "message",
        JSON.stringify({
          receiver_id: contractor?.id,
          receiver_name: contractor.business_name,
          sender_id: userID,
          sender_name: userName,
          message: message,
        }),
        {
          signal: controller.signal,
        }
      );
      setOpen2(false);
      // Update Toast Notification
      toast.update(sendMessageToast, {
        render: "Message Sent",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
    } catch (err) {
      if (!err?.response) {
        toast.update(sendMessageToast, {
          render: "No Server Response",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        {
          /* setErrMsg('No Server Response'); */
        }
      } else if (err.response?.status === 401) {
        toast.update(sendMessageToast, {
          render: "Authorization Failed",
          type: "error",
          isLoading: false,
        });
        {
          /* setErrMsg('The email has already been taken.'); */
        }
      } else {
        toast.update(sendMessageToast, {
          render: "Message Send Failed",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        {
          /* setErrMsg('Registration Failed'); */
        }
      }
    }

    return () => controller.abort();
  };

  const testReviews = {
    address_components: [
      { long_name: "48", short_name: "48", types: ["street_number"] },
      {
        long_name: "Pirrama Road",
        short_name: "Pirrama Rd",
        types: ["route"],
      },
      {
        long_name: "Pyrmont",
        short_name: "Pyrmont",
        types: ["locality", "political"],
      },
      {
        long_name: "City of Sydney",
        short_name: "City of Sydney",
        types: ["administrative_area_level_2", "political"],
      },
      {
        long_name: "New South Wales",
        short_name: "NSW",
        types: ["administrative_area_level_1", "political"],
      },
      {
        long_name: "Australia",
        short_name: "AU",
        types: ["country", "political"],
      },
      {
        long_name: "2009",
        short_name: "2009",
        types: ["postal_code"],
      },
    ],
    adr_address:
      '<span class="street-address">48 Pirrama Rd</span>, <span class="locality">Pyrmont</span> <span class="region">NSW</span> <span class="postal-code">2009</span>, <span class="country-name">Australia</span>',
    business_status: "OPERATIONAL",
    formatted_address: "48 Pirrama Rd, Pyrmont NSW 2009, Australia",
    formatted_phone_number: "(02) 9374 4000",
    geometry: {
      location: { lat: -33.866489, lng: 151.1958561 },
      viewport: {
        northeast: { lat: -33.8655112697085, lng: 151.1971156302915 },
        southwest: { lat: -33.86820923029149, lng: 151.1944176697085 },
      },
    },
    icon: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/generic_business-71.png",
    icon_background_color: "#7B9EB0",
    icon_mask_base_uri:
      "https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet",
    international_phone_number: "+61 2 9374 4000",
    name: "Google Workplace 6",
    opening_hours: {
      open_now: false,
      periods: [
        {
          close: { day: 1, time: "1700" },
          open: { day: 1, time: "0900" },
        },
        {
          close: { day: 2, time: "1700" },
          open: { day: 2, time: "0900" },
        },
        {
          close: { day: 3, time: "1700" },
          open: { day: 3, time: "0900" },
        },
        {
          close: { day: 4, time: "1700" },
          open: { day: 4, time: "0900" },
        },
        {
          close: { day: 5, time: "1700" },
          open: { day: 5, time: "0900" },
        },
      ],
      weekday_text: [
        "Monday: 9:00 AM – 5:00 PM",
        "Tuesday: 9:00 AM – 5:00 PM",
        "Wednesday: 9:00 AM – 5:00 PM",
        "Thursday: 9:00 AM – 5:00 PM",
        "Friday: 9:00 AM – 5:00 PM",
        "Saturday: Closed",
        "Sunday: Closed",
      ],
    },
    photos: [
      {
        height: 3024,
        html_attributions: [
          '<a href="https://maps.google.com/maps/contrib/117600448889234589608">Cynthia Wei</a>',
        ],
        photo_reference:
          "Aap_uEC6jqtpflLS8GxQqPHBjlcwBf2sri0ZErk9q1ciHGZ6Zx5HBiiiEsPEO3emtB1PGyWbBQhgPL2r9CshoVlJEG4xzB71QMhGBTqqeaCNk1quO3vTTiP50aM1kmOaBQ-DF1ER7zpu6BQOEtnusKMul0m4KA45wfE3h6Xh2IxjLNzx-IiX",
        width: 4032,
      },
      {
        height: 3264,
        html_attributions: [
          '<a href="https://maps.google.com/maps/contrib/102493344958625549078">Heyang Li</a>',
        ],
        photo_reference:
          "Aap_uECyRjHhOQgGaKTW6Z3ZfTEaDhNc44m0F6GrNSFIMffixwI5xqD35QhecdzVY-FUuDtVE1huu8-2HkxgI9Gwvy6W18fU-_E3UUkdSFBQqGK8_slKlT8BZZc66sTX53IEcTDrZfT-E5_YUBYBOm13yxOTOfWfEDABhaxCGC5Hu_XYh0fI",
        width: 4912,
      },
      {
        height: 3036,
        html_attributions: [
          '<a href="https://maps.google.com/maps/contrib/104829437842034782235">Anna Linetsky</a>',
        ],
        photo_reference:
          "Aap_uEAumTzSdhRHDutPAj6wVPSZZmBV-brI6TPFwI0tcQlbSR74z44mUPr4aXMQKck_AzHaKmbfR3P2c1qsu45i1RQPHrcpIXxrA78FmDjCdWYYZWUnFozdcmEj9OQ_V0G08adpKivMKZyeaQ1NuwRy9GhSopeKpzkzkFZG5vXMYPPSgpa1",
        width: 4048,
      },
      {
        height: 4016,
        html_attributions: [
          '<a href="https://maps.google.com/maps/contrib/107755640736541028674">Jonah Dell</a>',
        ],
        photo_reference:
          "Aap_uECC7cSbDkh-TdmXr6m5d5pgVXJmvXg8dF2jzhL0b0Ko4CtnVll6-tIvdz7vhbCsd3hl2u9EgZ4Y30FBxKmFcimfeYUgW2XJyv8JY5IYGuXsKkCLqpV3QH9dIGwoUv2uX0eosDsUsTN2DOlyOasUgVxcYqzIzEmrL5ofIssThQWZeozD",
        width: 6016,
      },
      {
        height: 3024,
        html_attributions: [
          '<a href="https://maps.google.com/maps/contrib/115886271727815775491">Anthony Huynh</a>',
        ],
        photo_reference:
          "Aap_uEDTdw58CglFmZZAR9iZ05x3y2oK9r5_dRqKWnbZKSS9gs6gp9AeBa1QDvBL6dzZyQAZfN8H2Eppu6y4NBaPOp-GkulZYiKRM7Yww8sUEv-8dmcq35Tx38pe4LEX2wIicFkQHedRgMc0FfV9aFtgosQ5ps5-HCjJSApg8eLGyuxxqPm9",
        width: 4032,
      },
      {
        height: 3024,
        html_attributions: [
          '<a href="https://maps.google.com/maps/contrib/102939237947063969663">Jasen Baker</a>',
        ],
        photo_reference:
          "Aap_uEAGqslqZPhZUk0T2Y6l7mkCYnY7JN9li4g5NkZsE0N4Cdy7_cZ-fZWyV02VhpQR4Ph4fLUL6_WTXrlGMXXzUJXUcSmSTs2d_Dzf3Q_A1y07Dm-vtv7pS3JXsWyrWETGIoT1pIj81PPdUc1vlR2i3GFMWAbx9rCC472ZJclY8JlvMg-x",
        width: 4032,
      },
      {
        height: 3024,
        html_attributions: [
          '<a href="https://maps.google.com/maps/contrib/100678816592586275978">Jeremy Hsiao</a>',
        ],
        photo_reference:
          "Aap_uEBaGxeN90YFjD-AUjxZqM44kpMcICKKBBhb0RQQS7DHHFaay8RRAwjWsAt8GEmmB5QnxrbQWHU3TwhVXXHP0m-YNp9Ds3ihpiFan0moNv4QB7kern5cfjWhhrWe8B0dz_vYvmPssJE24P-24YfWWHubOo0L2MjQyueZfDv57N_RvDZk",
        width: 4032,
      },
      {
        height: 1515,
        html_attributions: [
          '<a href="https://maps.google.com/maps/contrib/112343109286948028063">Andrew W</a>',
        ],
        photo_reference:
          "Aap_uEBDzJlmTeNUreMop6_hkC1HKTCRLyPs5fikJi58qCejtkWp5PIM6vzNN3HErkSWUwnamTr_WLyT7jXMAIdByR-hx8dG-OHjj5JxzmcPvuT_VeVLmdSbNPeIlpmp6EUcPOhaVrhEKojSd44QXkl0za29eZ0oj1KDOnAsGxmhanDFW7lI",
        width: 2048,
      },
      {
        height: 3024,
        html_attributions: [
          '<a href="https://maps.google.com/maps/contrib/100678816592586275978">Jeremy Hsiao</a>',
        ],
        photo_reference:
          "Aap_uEBvYFpzCDQzvQ0kdBxxB70lTkLbTM0yH3xF-BCHsb7DQ63cuWnutvwv8oVLDSbA14_kns3WVlEInTyy2elvmH5lzQteb6zzRu3exkwE65_55TgJqdLO7RYYiPFliWk4ocszn9nn5ELv5uP2BQmqr9QET5vwgxR-0eshyVmcdM42jb39",
        width: 4032,
      },
      {
        height: 4032,
        html_attributions: [
          '<a href="https://maps.google.com/maps/contrib/100678816592586275978">Jeremy Hsiao</a>',
        ],
        photo_reference:
          "Aap_uECQynuD_EnSnbz8sJQ6-B6uR-j2tuu4Z1tuGUjq8xnxFDk-W8OdeLzWBX8suNKTCsPlkzTqC22BXf_hX33XclGPL4SS9xnPmHcMrLoUl0H_xHYevFvT17Hgw5DZpSyVmLvDvxzzJ1rsZTh55QwopmAty083a1r1ZIfL32iXh_q8FUas",
        width: 3024,
      },
    ],
    place_id: "ChIJN1t_tDeuEmsRUsoyG83frY4",
    plus_code: {
      compound_code: "45MW+C8 Pyrmont NSW, Australia",
      global_code: "4RRH45MW+C8",
    },
    rating: 4,
    reference: "ChIJN1t_tDeuEmsRUsoyG83frY4",
    reviews: [
      {
        author_name: "Luke Archibald",
        author_url:
          "https://www.google.com/maps/contrib/113389359827989670652/reviews",
        language: "en",
        profile_photo_url:
          "https://lh3.googleusercontent.com/a-/AOh14GhGGmTmvtD34HiRgwHdXVJUTzVbxpsk5_JnNKM5MA=s128-c0x00000000-cc-rp-mo",
        rating: 1,
        relative_time_description: "a week ago",
        text: "Called regarding paid advertising google pages to the top of its site of a scam furniture website misleading and taking peoples money without ever sending a product - explained the situation,  explained I'd spoken to an ombudsman regarding it.  Listed ticket numbers etc.\n\nThey left the advertisement running.",
        time: 1652286798,
      },
      {
        author_name: "Tevita Taufoou",
        author_url:
          "https://www.google.com/maps/contrib/105937236918123663309/reviews",
        language: "en",
        profile_photo_url:
          "https://lh3.googleusercontent.com/a/AATXAJwZANdRSSg96QeZG--6BazG5uv_BJMIvpZGqwSz=s128-c0x00000000-cc-rp-mo",
        rating: 1,
        relative_time_description: "6 months ago",
        text: "I need help.  Google Australia is taking my money. Money I don't have any I am having trouble sorting this issue out",
        time: 1637215605,
      },
      {
        author_name: "Jordy Baker",
        author_url:
          "https://www.google.com/maps/contrib/102582237417399865640/reviews",
        language: "en",
        profile_photo_url:
          "https://lh3.googleusercontent.com/a/AATXAJwgg1tM4aVA4nJCMjlfJtHtFZuxF475Vb6tT74S=s128-c0x00000000-cc-rp-mo",
        rating: 1,
        relative_time_description: "4 months ago",
        text: "I have literally never been here in my life, I am 17 and they are taking money I don't have for no reason.\n\nThis is not ok. I have rent to pay and my own expenses to deal with and now this.",
        time: 1641389490,
      },
      {
        author_name: "Prem Rathod",
        author_url:
          "https://www.google.com/maps/contrib/115981614018592114142/reviews",
        language: "en",
        profile_photo_url:
          "https://lh3.googleusercontent.com/a/AATXAJyEQpqs4YvPPzMPG2dnnRTFPC4jxJfn8YXnm2gz=s128-c0x00000000-cc-rp-mo",
        rating: 1,
        relative_time_description: "4 months ago",
        text: "Terrible service. all reviews are fake and irrelevant. This is about reviewing google as business not the building/staff etc.",
        time: 1640159655,
      },
      {
        author_name: "Husuni Hamza",
        author_url:
          "https://www.google.com/maps/contrib/102167316656574288776/reviews",
        language: "en",
        profile_photo_url:
          "https://lh3.googleusercontent.com/a/AATXAJwRkyvoSlgd06ahkF9XI9D39o6Zc_Oycm5EKuRg=s128-c0x00000000-cc-rp-mo",
        rating: 5,
        relative_time_description: "7 months ago",
        text: "Nice site. Please I want to work with you. Am Alhassan Haruna, from Ghana. Contact me +233553851616",
        time: 1633197305,
      },
    ],
    types: ["point_of_interest", "establishment"],
    url: "https://maps.google.com/?cid=10281119596374313554",
    user_ratings_total: 939,
    utc_offset: 600,
    vicinity: "48 Pirrama Road, Pyrmont",
    website: "http://google.com/",
  };

  const testRating = 4.1;

  return (
    <>
      <section className="contractorHeader">
        <article className="inner">
          {loading && <Loader />}
          {!loading && error && !contractor.business_name && (
            <>
              <p>
                No contractor available under this name. Looking for a specific
                contractor? Try searching by name.
              </p>
              <ContractorNameSearch />
              {/* <p className="error">{error}</p>}} */}
            </>
          )}
          {!loading && !error && contractor && (
            <>
              {!userType && (
                <p className="notification">
                  <GoAlert />{" "}
                  <span>
                    Sign up or login to access more features.{" "}
                    <Link to="/login" state={{ from: location.pathname }}>
                      Login
                    </Link>
                  </span>
                </p>
              )}

              <div className="coverPhotoWrapper">
                {contractor?.cover_picture ? (
                  <img
                    className="coverPhoto"
                    src={`https://storage.iyh.app/${contractor?.cover_picture}`}
                    alt={`${contractor.business_name}`}
                  />
                ) : null}
              </div>

              <div className="businessInfo">
                <div className="logoWrapper">
                  {contractor?.profile_picture ? (
                    <img
                      className="contractorLogo"
                      src={`https://storage.iyh.app/${contractor?.profile_picture}`}
                      alt={`${contractor.first_name} ${contractor.last_name}`}
                    />
                  ) : null}
                </div>
                <div className="content">
                  <div className="title">
                    <h1>{contractor.business_name}</h1>
                  </div>
                  <div className="specialty">
                    {contractor?.contractor_specialty &&
                      contractor.contractor_specialty !== "null" && (
                        <p>{contractor?.contractor_specialty}</p>
                      )}
                  </div>
                </div>
                <div className="headerActionButtons">
                  <ContractorActions
                    url={contractor.business_website}
                    phone={contractor.provision_number}
                    email={contractor.email}
                    id={contractor.id}
                    name={contractor.business_name}
                  />
                </div>
              </div>
            </>
          )}
          <ul className="contractorTabs">
            <li>
              <Link to="#about">About Us</Link>
            </li>
            <li>
              <Link to="#portfolio">Portfolio</Link>
            </li>
            <li>
              <Link to="#promotions">Promotions</Link>
            </li>
            <li>
              <Link to="#certs">Certificates & Awards</Link>
            </li>
            <li>
              <Link to="#videos">Videos</Link>
            </li>
            <li>
              <Link to="#reviews">Reviews</Link>
            </li>
          </ul>
        </article>
      </section>

      {!loading && !error && contractor && (
        <>
          <section className="contractorContent">
            <article className="inner">
              <div className="entryContent">
                <div className="contractorDesscription" id="description">
                  <h3>Description of Services</h3>
                  {contractor.business_description ? (
                    contractor.business_description
                  ) : (
                    <p>
                      This contractor has not filled out their description
                      section.
                    </p>
                  )}
                </div>

                <div className="contractorContact">
                  <div className="contactWrapper">
                    <div className="contactTitleWrapper">
                      <h3>Contact</h3>
                    </div>
                    <div className="contactContent">
                      {contractor?.contact_visibility ? (
                        <p>
                          <AiOutlineUser />
                          {contractor.first_name} {contractor.last_name}
                        </p>
                      ) : null}
                      {contractor.provision_number && (
                        <p>
                          <a
                            onClick={(e) => incrementPhoneCount()}
                            href={`tel:${formatTelLink(
                              contractor.provision_number
                            )}`}
                          >
                            <AiOutlinePhone />
                            {formatPhone(contractor.provision_number)}
                          </a>
                        </p>
                      )}
                      {contractor.business_website && (
                        <p className="website">
                          <a
                            onClick={(e) => incrementWebsiteCount()}
                            href={contractor.business_website}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <AiOutlineLink />
                            {contractor.business_website}
                          </a>
                        </p>
                      )}
                      {userType == 1001 && (
                        <p className="directMessage" onClick={handleClickOpen2}>
                          <AiOutlineMessage />
                          Direct Message
                        </p>
                      )}
                      {contractor?.profile_email &&
                      contractor?.profile_email !== "null" ? (
                        <p>
                          <a
                            onClick={(e) => incrementEmailCount()}
                            href={`mailto:${contractor.profile_email}`}
                          >
                            <AiOutlineMail />
                            {contractor.profile_email}
                          </a>
                        </p>
                      ) : (
                        <p>
                          <a
                            onClick={(e) => incrementEmailCount()}
                            href={`mailto:${contractor.email}`}
                          >
                            <AiOutlineMail />
                            {contractor.email}
                          </a>
                        </p>
                      )}
                      {contractor.license_number && (
                        <p>
                          <AiOutlineVerified />
                          {contractor.license_number}
                        </p>
                      )}
                      {contractor.address && contractor.address !== "null" ? (
                        <p>
                          <AiOutlineEnvironment />
                          {contractor.address}
                          <br />
                          <span>
                            {contractor.city}, {contractor.state}{" "}
                            {contractor.zip}
                          </span>
                        </p>
                      ) : (
                        <p>
                          <AiOutlineEnvironment />
                          {contractor.city} {contractor.state}, {contractor.zip}
                        </p>
                      )}
                      <ContractorSocial links={contractor.social_links} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mainContent">
                <div id="about" className="iyhBorderBox">
                  <div className="icon">
                    <AboutIcon />
                  </div>
                  <div className="content">
                    <h3>About Us</h3>
                    {contractor.business_about ? (
                      contractor.business_about
                    ) : (
                      <p>
                        This contractor has not filled out their about section.
                      </p>
                    )}
                  </div>
                </div>
                {/* <div id="googleReview" className="iyhBorderBox">
                  <div className="content">
                    <div className="headerSection">
                      <h3>Google Reviews</h3>
                      <a
                        href={
                          contractor?.google_reviews &&
                          contractor?.google_reviews &&
                          contractor?.google_reviews?.length
                            ? `https://www.google.com/maps/place/?q=place_id:${contractor?.google_reviews[0]?.google_reference}`
                            : ``
                        }
                        className="button-red"
                      >
                        Write a review
                      </a>
                    </div>
                    <Container className="reviewsContainer">
                      {contractor?.google_reviews &&
                      contractor?.google_reviews?.length &&
                      contractor?.google_reviews[0] &&
                      contractor?.google_reviews[0]?.overall_rating &&
                      parseInt(contractor?.google_reviews[0]?.overall_rating) >=
                        3 ? (
                        <Row>
                          <Col className="companyReviewCol">
                            <p>{`${contractor?.business_name}: ${contractor?.google_reviews[0]?.overall_rating}`}</p>
                            <Rating
                              name="read-only"
                              value={parseInt(
                                contractor?.google_reviews[0]?.overall_rating
                              )}
                              readOnly
                            />
                          </Col>
                        </Row>
                      ) : (
                        <></>
                      )}
                      <Row className="reviewsRow">
                        {contractor?.google_reviews &&
                        contractor?.google_reviews &&
                        contractor?.google_reviews?.length ? (
                          contractor?.google_reviews.map((review) => {
                            const reviewDate = new Date(
                              parseInt(review?.time * 1000)
                            );
                            const options = {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            };
                            // console.log(new Intl.DateTimeFormat("en-US", options).format(reviewDate));
                            return review?.rating && review?.rating >= 3 ? (
                              <Col className="reviewCol" lg={3}>
                                <h6>{review.author_name}</h6>
                                {reviewDate ? (
                                  <p>
                                    {new Intl.DateTimeFormat(
                                      "en-US",
                                      options
                                    ).format(reviewDate)}
                                  </p>
                                ) : (
                                  <></>
                                )}
                                <Rating
                                  name="read-only"
                                  value={review.rating}
                                  readOnly
                                />
                                <p className="reviewText">{review.message}</p>
                              </Col>
                            ) : (
                              <></>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </Row>
                    </Container>
                    <p className="all-reviews-link">
                      <a
                        href={
                          contractor?.google_reviews &&
                          contractor?.google_reviews &&
                          contractor?.google_reviews?.length
                            ? `https://www.google.com/maps/place/?q=place_id:${contractor?.google_reviews[0]?.google_reference}`
                            : ``
                        }
                        className="all-reviews-link"
                      >
                        See all Reviews
                      </a>
                    </p>
                  </div>
                </div> */}
              </div>
            </article>
          </section>

          <section className="contractorPortfolio" id="portfolio">
            <article className="inner">
              <h3>Portfolio</h3>
              {contractor?.portfolio?.length > 0 ? (
                <ImageGallery items={portfolioImages} />
              ) : (
                <p className="centeredNote">No photos available.</p>
              )}
            </article>
          </section>

          <section>
            <article>
              <div>
                <div id="promotions" className="iyhBorderBox">
                  <div className="content">
                    <h3>Promotions</h3>
                    {contractor?.promotions?.length > 0 ? (
                      <ul className="promotionalList">
                        {contractor?.promotions?.map((promotion, i) => (
                          <li key={i}>
                            {/* <div className="promotionName">
													<h4>{promotion?.name}</h4>
												</div> */}
                            <div className="promotionWrapper">
                              <div className="image">
                                <img
                                  src={`https://storage.iyh.app/${promotion?.image}`}
                                  alt=""
                                />
                              </div>
                            </div>
                            {promotion?.details && (
                              <div className="promotionDetails">
                                <p>
                                  <strong>Promotion Details:</strong>{" "}
                                  {promotion?.details}
                                </p>
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p className="centeredNote">
                        No promotions at this time.
                      </p>
                    )}
                  </div>
                </div>

                <div id="certs" className="iyhBorderBox">
                  <div className="icon">
                    <AwardsIcon />
                  </div>
                  <div className="content">
                    <h3>Certificates & Awards</h3>
                    {contractor.business_certs_awards ? (
                      contractor.business_certs_awards
                    ) : contractor?.certs?.length > 0 ? null : (
                      <p>
                        This contractor has not filled out their certificates
                        and awards section.
                      </p>
                    )}
                    {contractor?.certs?.length > 0 ? (
                      <ul id="certAwardsImageWrapper">
                        {contractor?.certs?.map((cert, i) => (
                          <li key={i}>
                            <div className="image">
                              <img
                                src={`https://storage.iyh.app/${cert?.path}`}
                                alt=""
                              />
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </div>
                </div>
              </div>
              {!loading && !error && !contractor && (
                <p>No Contractor Data Available</p>
              )}
            </article>
          </section>

          <section className="contractorVideos" id="videos">
            <article className="inner">
              <h3>Videos</h3>
              {contractor?.videos?.length > 0 ||
              contractor?.ext_video_url_1 ||
              contractor?.ext_video_url_2 ||
              contractor?.ext_video_url_3 ? (
                <div className="videoContainer">
                  {contractor?.videos?.map((video, i) => (
                    <li key={i}>
                      <div className="video">
                        <video controls>
                          <source
                            src={`https://storage.iyh.app/${video.path}`}
                          />
                          Your browser does not support HTML video.
                        </video>
                        {/* <img src={`https://storage.iyh.app/${video?.path}`} alt="" /> */}
                      </div>
                    </li>
                  ))}

                  {contractor?.ext_video_url_1 && (
                    <li>
                      <div
                        className={`video ${contractor?.ext_video_provider_1}`}
                      >
                        <ReactPlayer
                          controls="true"
                          url={contractor?.ext_video_url_1}
                          width="100%"
                          height="auto"
                        />
                      </div>
                    </li>
                  )}
                  {contractor?.ext_video_url_2 && (
                    <li>
                      <div
                        className={`video ${contractor?.ext_video_provider_2}`}
                      >
                        <ReactPlayer
                          controls="true"
                          url={contractor?.ext_video_url_2}
                          width="100%"
                          height="auto"
                        />
                      </div>
                    </li>
                  )}
                  {contractor?.ext_video_url_3 && (
                    <li>
                      <div
                        className={`video ${contractor?.ext_video_provider_3}`}
                      >
                        <ReactPlayer
                          controls="true"
                          url={contractor?.ext_video_url_3}
                          width="100%"
                          height="auto"
                        />
                      </div>
                    </li>
                  )}
                </div>
              ) : (
                <p className="centeredNote">No videos available.</p>
              )}
            </article>
          </section>
          {contractor?.google_reviews &&
            contractor?.google_reviews?.length &&
            contractor?.google_reviews[0] &&
            contractor?.google_reviews[0]?.overall_rating ? (
              <section className="contractorContent">
                <article>
                  <div id="googleReview" className="iyhBorderBox">
                    <div className="content">
                      <div className="headerSection">
                        <h3>Google Reviews</h3>
                        <a
                          href={
                            contractor?.google_reviews &&
                            contractor?.google_reviews &&
                            contractor?.google_reviews?.length
                              ? `https://www.google.com/maps/place/?q=place_id:${contractor?.google_reviews[0]?.google_reference}`
                              : ``
                          }
                          className="button-red"
                        >
                          Write a review
                        </a>
                      </div>
                      <Container className="reviewsContainer">
                        {contractor?.google_reviews &&
                        contractor?.google_reviews?.length &&
                        contractor?.google_reviews[0] &&
                        contractor?.google_reviews[0]?.overall_rating &&
                        parseInt(
                          contractor?.google_reviews[0]?.overall_rating
                        ) >= 3 ? (
                          <Row>
                            <Col className="companyReviewCol">
                              <p>{`${contractor?.business_name}: ${contractor?.google_reviews[0]?.overall_rating}`}</p>
                              <Rating
                                name="read-only"
                                value={parseInt(
                                  contractor?.google_reviews[0]?.overall_rating
                                )}
                                readOnly
                              />
                            </Col>
                          </Row>
                        ) : (
                          <></>
                        )}
                        <Row className="reviewsRow">
                          {contractor?.google_reviews &&
                          contractor?.google_reviews &&
                          contractor?.google_reviews?.length ? (
                            contractor?.google_reviews.map((review) => {
                              const reviewDate = new Date(
                                parseInt(review?.time * 1000)
                              );
                              const options = {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              };
                              // console.log(new Intl.DateTimeFormat("en-US", options).format(reviewDate));
                              return review?.rating && review?.rating >= 3 ? (
                                <Col className="reviewCol" lg={3}>
                                  <h6>{review.author_name}</h6>
                                  {reviewDate ? (
                                    <p>
                                      {new Intl.DateTimeFormat(
                                        "en-US",
                                        options
                                      ).format(reviewDate)}
                                    </p>
                                  ) : (
                                    <></>
                                  )}
                                  <Rating
                                    name="read-only"
                                    value={review.rating}
                                    readOnly
                                  />
                                  <p className="reviewText">{review.message}</p>
                                </Col>
                              ) : (
                                <></>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </Row>
                      </Container>
                      <p className="all-reviews-link">
                        <a
                          href={
                            contractor?.google_reviews &&
                            contractor?.google_reviews &&
                            contractor?.google_reviews?.length
                              ? `https://www.google.com/maps/place/?q=place_id:${contractor?.google_reviews[0]?.google_reference}`
                              : ``
                          }
                          className="all-reviews-link"
                        >
                          See all Reviews
                        </a>
                      </p>
                    </div>
                  </div>
                </article>
              </section>
            ) : <></>}
          {/* <section id="reviews" className="contractorReviews">
            <article className="inner">
              <h3>Reviews</h3>
              <ul className="publicReviewList">
                {contractor?.reviews?.length > 0 ? (
                  contractor?.reviews?.map((review, i) => (
                    <li key={i}>
                      <div className="reviewContainer">
                        <div className="review">
                          <div className="ratingsWrapper">
                            <Rating
                              name="size-medium"
                              defaultValue={review.rating}
                              readOnly
                            />
                          </div>
                          <div className="reviewWrapper">
                            <div className="reviewAvatar"></div>
                            <div className="reviewQuotes">
                              <QuotesIcon />
                            </div>
                            <div className="reviewContent">
                              <p classname="reviewTitle">
                                <strong>{review.review_title}</strong>
                              </p>
                              <p className="reviewText">{review.review_text}</p>
                              <p className="reviewedBy">
                                -{review.reviewed_by_name}
                              </p>
                            </div>
                          </div>
                          {review.public_contractor_comment && (
                            <div className="responseWrapper">
                              <div className="responseIcon">
                                <BsChatRightTextFill />
                              </div>
                              <div className="response">
                                <p className="respondedBy">
                                  <strong>
                                    Reply from {review.contractor_name}:
                                  </strong>
                                </p>
                                <p className="replyText">
                                  {review.public_contractor_comment}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <p className="centeredNote">
                    Contractor does not have any reviews at this time.
                  </p>
                )}
              </ul>

              // Send Message Popup 
              <Dialog
                open={open2}
                onClose={handleClose2}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {`Send a message to ${contractor?.business_name}`}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <SendMessage
                      name={contractor?.business_name}
                      message={message}
                      setMessage={setMessage}
                      validMessage={validMessage}
                      setValidMessage={setValidMessage}
                    />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <button className="cancel" onClick={handleClose2}>
                    Cancel
                  </button>
                  <button
                    disabled={!validMessage ? true : false}
                    className="confirm"
                    onClick={handleSendMessage}
                  >
                    Send
                  </button>
                </DialogActions>
              </Dialog>
            </article>
          </section> */}
        </>
      )}
    </>
  );
};

export default ViewContractor;
