import AdminMenu from '../../components/AdminMenu';
import Users from "../../components/Users";
import ZipCodes from "../../components/ZipCodes"
import { MdExplore } from 'react-icons/md';

const AdminZipCodes = () => {
	return (
		<section>
			<article className="inner">
				<div className="backendTitleBar">
					<h1>Zip Codes</h1>
					<MdExplore />
				</div>
				<div className="backendColumnWrapper">
					<div id="menu" className="column">
						<AdminMenu />
					</div>
					<div id="content" className="column">
						<ZipCodes />
					</div>
				</div>
			</article>
		</section>
	)
}


export default AdminZipCodes