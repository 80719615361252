import { useRef, useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import axios from '../api/axios';
import usePageTitle from '../hooks/usePageTitle';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { FaCheck } from 'react-icons/fa';
import { FaExclamation } from 'react-icons/fa';

const RESET_PASSWORD_URL = 'reset-password';

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%_-]).{8,24}$/;
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const PasswordReset = () => {

	const { auth } = useAuth();

	const params = new URLSearchParams(window.location.search);
	const url = params.get('url');

	const token = params.get('token');

	const emailRef = useRef();
	const errRef = useRef();

	const [email, setEmail] = useState('');
	const [validEmail, setValidEmail] = useState(false);
	const [emailFocus, setEmailFocus] = useState(false);

	const [pwd, setPwd] = useState('');
	const [validPwd, setValidPwd] = useState(false);
	const [pwdFocus, setPwdFocus] = useState(false);

	const [matchPwd, setMatchPwd] = useState('');
	const [validMatch, setValidMatch] = useState(false);
	const [matchFocus, setMatchFocus] = useState(false);

	const [errMsg, setErrMsg] = useState('');
	const [success, setSuccess] = useState(false);

	/* Check username against regex function on username change */
	useEffect(() => {
		const result = EMAIL_REGEX.test(email);
		setValidEmail(result);
	}, [email])

	/* Check password against regex function on password change */
	useEffect(() => {
		const result = PWD_REGEX.test(pwd);
		setValidPwd(result);
		const match = pwd === matchPwd;
		setValidMatch(match);
	}, [pwd, matchPwd])

	/* Clear Error message on any change of user or password */
	useEffect(() => {
		setErrMsg('');
	}, [email, pwd, matchPwd])

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			const response = await axios.post(RESET_PASSWORD_URL,
				JSON.stringify({email: email, password: pwd, password_confirmation: matchPwd, token: token}),
				{
					headers: {
						'Content-Type': 'application/json',
						'Accept': 'application/json'
					},
					withCredentials: true
				}
			);
			console.log(JSON.stringify(response?.data));
			setEmail('');
			setPwd('');
			setMatchPwd('');
			toast.success('Password Sucessfully Changed');
		} catch (err) {
			if (!err?.response){
				toast.error('No Server Response');
				setErrMsg('No Server Response');
			} else if ( err.response?.status === 400){
				toast.error('Missing username or password')
				setErrMsg('Missing username or password');
			} else {
				toast.error('Password Reset Failed')
				setErrMsg('Password Reset Failed')
			}
			{/* errRef.current.focus(); */}
		}
	}

	usePageTitle("Email Verification");

	return (
		<section>
			<article className='inner'>
				<h1>Password Reset</h1>
				<form onSubmit={handleSubmit}>
					{/* Email */}
					<label htmlFor="email">Email:</label>
					<div className="inputWrapper">
						<span className={validEmail ? "valid" : "hide"}><FaCheck /></span>
						<span className={validEmail || !email ? "hide" : "invalid"}><FaExclamation /></span>
						<input
							type="email"
							id="email"
							className={validEmail || !email ? null : "error"}
							ref={emailRef}
							value={email}
							autoComplete="off"
							onChange={(e) => setEmail(e.target.value)}
							required
							aria-invalid={validEmail ? "false" : "true"}
							aria-describedby="uidnote"
							onFocus={() => setEmailFocus(true)}
							onBlur={() => setEmailFocus(false)}
						/>
					</div>
					<p id="uidnote" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}>
						Invalid email address.
					</p>

					{/* Password */}
					<label htmlFor="password">Password:</label>
					<div className="inputWrapper">
						<span className={validPwd ? "valid" : "hide"}><FaCheck /></span>
						<span className={validPwd || !pwd ? "hide" : "invalid"}><FaExclamation /></span>
						<input
							type="password"
							id="password"
							className={validPwd || !pwd ? null : "error"}
							onChange={(e) => setPwd(e.target.value)}
							value={pwd}
							required
							aria-invalid={validPwd ? "false" : "true"}
							aria-describedby="pwdnote"
							onFocus={() => setPwdFocus(true)}
							onBlur={() => setPwdFocus(false)}
						/>
					</div>
					<p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
						8 to 24 characters. Must include uppercase and lowercase letters, a number and a special character.	Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
					</p>

					{/* Password Confirmation */}
					<label htmlFor="confirm_pwd">Confirm Password:</label>
					<div className="inputWrapper">
						<span className={validMatch && matchPwd ? "valid" : "hide"}><FaCheck /></span>
						<span className={validMatch || !matchPwd ? "hide" : "invalid"}><FaExclamation /></span>
						<input
							type="password"
							id="confirm_pwd"
							className={validMatch || !matchPwd ? null : "error"}
							onChange={(e) => setMatchPwd(e.target.value)}
							value={matchPwd}
							required

						/>
					</div>
					<p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
						Must match the first password input field.
					</p>

					<button>Reset Password</button>
				</form>
				<Link to="/login">Login</Link>
				<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="colored"
				/>
			</article>
		</section>
	)
}

export default PasswordReset