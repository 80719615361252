import { useEffect } from "react";
import usePageTitle from '../../hooks/usePageTitle';
import UserPageNav from '../../components/UserPageNav';
import UserReviews from '../../components/UserReviews';
import ContractorReviews from '../../components/ContractorReviews';
import useAuth from '../../hooks/useAuth';
import { AiOutlineDashboard } from 'react-icons/ai'
import { CgProfile } from 'react-icons/cg'
import { MdAccountBox } from 'react-icons/md'
import { FaUserCircle } from 'react-icons/fa'
import { GiStarsStack } from 'react-icons/gi'
import { AiOutlineBarChart } from 'react-icons/ai'
import { HiChartSquareBar } from 'react-icons/hi'
import { ImBullhorn } from 'react-icons/im';
import { MdDashboard } from 'react-icons/md';
import { RiCustomerService2Line } from 'react-icons/ri';
import { MdHelpCenter } from 'react-icons/md';
import { AiFillMessage } from 'react-icons/ai';
import { MdMessage } from 'react-icons/md';
import { MdFavorite } from 'react-icons/md';
import { MdFactCheck } from 'react-icons/md';
import { MdRequestPage } from 'react-icons/md';
import { MdSettings } from 'react-icons/md';
import { MdWork } from 'react-icons/md';

const Reviews = () => {

	usePageTitle("Reviews");

	const { auth } = useAuth();
	const role = auth?.user?.role


	return (
		<section>
			<article className='inner'>
				<div className="backendTitleBar">
					<h1>Reviews</h1>
					<GiStarsStack />
				</div>
				<div className="backendColumnWrapper">
					<div id="menu" className="column">
						<UserPageNav />
					</div>
					<div id="content" className="column">
						{role == 1001 && <p>New reviews are are put in a pending state and not viewable to the public until approved. Contractors will get the oportunity to approve or send it to review. If they do nothing the review will automatically post after 7 days.</p>}
						{role == 2002 && <p>To approve or dispute the review, please click on the “eyeball” icon</p>}
						{role == 1001 ? <UserReviews /> : <ContractorReviews />}
					</div>
				</div>
			</article>
		</section>
	)
}

export default Reviews