import { useNavigate, Link } from "react-router-dom";
import useLogout from '../hooks/useLogout';
import usePageTitle from '../hooks/usePageTitle';

const Logout = () => {

	const navigate = useNavigate();
	const exit = useLogout();

	const signOut = async () => {
		await exit();
		navigate('/');
	}

	usePageTitle("Logout");

	return (
		<section>
			<article className='inner'>
				<h1>Logout</h1>
				<button onClick={exit}>Sign Out</button>
			</article>
		</section>
	)
}

export default Logout