import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';
import { FaExclamation } from 'react-icons/fa';
import usePageTitle from '../../hooks/usePageTitle';
import UserPageNav from '../../components/UserPageNav';
import useAuth from '../../hooks/useAuth';
import { ToastContainer, toast } from 'react-toastify';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useRefreshToken from '../../hooks/useRefreshToken';
import FileDrop from '../../components/FileDrop';
import FileDropEmbed from '../../components/FileDropEmbed';
import PortfolioDropEmbed from '../../components/PortfolioDropEmbed';
import CADropEmbed from '../../components/CADropEmbed';
import CoverDropEmbed from '../../components/CoverDropEmbed';
import SingleFileDropEmbed from '../../components/SingleFileDropEmbed';
import VideoDropEmbed from '../../components/VideoDropEmbed';
import ContractorCategoriesSelect from '../../components/ContractorCategoriesSelect'
import { AiOutlineDashboard } from 'react-icons/ai'
import { CgProfile } from 'react-icons/cg'
import { MdAccountBox } from 'react-icons/md'
import { FaUserCircle } from 'react-icons/fa'
import { GiStarsStack } from 'react-icons/gi'
import { AiOutlineBarChart } from 'react-icons/ai'
import { HiChartSquareBar } from 'react-icons/hi'
import { ImBullhorn } from 'react-icons/im';
import { MdDashboard } from 'react-icons/md';
import { RiCustomerService2Line } from 'react-icons/ri';
import { MdHelpCenter } from 'react-icons/md';
import { AiFillMessage } from 'react-icons/ai';
import { MdMessage } from 'react-icons/md';
import { MdFavorite } from 'react-icons/md';
import { MdFactCheck } from 'react-icons/md';
import { MdRequestPage } from 'react-icons/md';
import { MdSettings } from 'react-icons/md';
import { MdWork } from 'react-icons/md';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';

/* REGEX Verification for inputs */
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const NAME_REGEX = /^[a-zA-Z-' ]{2,23}$/;
const CITY_REGEX = /^[a-zA-Z ]{2,50}$/;
const ZIPCODE_REGEX = /^\d{5}(?:[-\s]\d{4})?$/;
const BUSINESS_REGEX = /[^\s]{1,100}$/;
const PHONE_REGEX = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
const URL_REGEX = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
const LICENSE_REGEX = /^[a-zA-Z0-9\#\-\_ ]{2,50}$/;
//const SPECIALTY_REGEX = /^[a-zA-Z0-9-_/,#& ]{1,50}$/;
const SPECIALTY_REGEX = /^[a-zA-Z0-9-_/,#&\s\$@%\^&\*!\(\)=\+\|\\;\:"'\.\?>< ]{1,50}$/;
const ADDRESS_REGEX = /^[a-zA-Z0-9-_/:#.&*, ]{1,100}$/;

const FACEBOOK_REGEX = /^(https:\/\/www\.facebook\.com)\/?([a-zA-Z0-9-_@.])|null/;
const INSTAGRAM_REGEX = /^(https:\/\/www\.instagram\.com)\/?([a-zA-Z0-9-_@.])|null/;
const TWITTER_REGEX = /^(https:\/\/twitter\.com)\/?([a-zA-Z0-9-_@.])|null/;
const LINKEDIN_REGEX = /^(https:\/\/www\.linkedin\.com)\/?([a-zA-Z0-9-_@.])|null/;
const YELP_REGEX = /^(https:\/\/www\.yelp\.com)\/?([a-zA-Z0-9-_@.])|null/;
const GOOGLE_REGEX = /^(https:\/\/(?:www\.)?((goo\.gl)|(google\.com)))\/?([a-zA-Z0-9])/;

const YOUTUBE_REGEX = /^(https:\/\/(?:www\.)?youtube\.com)\/?(([a-zA-Z0-9]{11})|(shorts\/[a-zA-Z0-9_-]{11})|(watch\?v=[a-zA-Z0-9-_]{11})|(watch\?v=[a-zA-Z0-9-_]{11}(\&[a-zA-Z0-9]{1,50}\=[a-zA-Z0-9]{1,50})))$/;
const VIMEO_REGEX = /^(https:\/\/(?:www\.)?vimeo\.com)\/?(([0-9]{9}))$/;
const FACEBOOK_VIDEO_REGEX = /^(https:\/\/(?:www\.)?facebook\.com)\/?([a-zA-Z0-9]{1,50}\/videos\/[0-9]{15})$/;

const UPDATE_PROFILE = "user/";

const YOUTUBE_VALUDATION_TEXT = "Valid YouTube formats are 'https://www.youtube.com/shorts/XXXXXXXXXXX', 'https://www.youtube.com/XXXXXXXXXXX', or 'https://www.youtube.com/watch?v=XXXXXXXXXXX'"
const VIMEO_VALUDATION_TEXT = "Valid Vimeo formats are 'https://vimeo.com/XXXXXXXXX'"
const FACEBOOK_VALUDATION_TEXT = "Valid Facebook Video formats are 'https://www.facebook.com/XXXXXXXXXX/videos/XXXXXXXXXXXXXX'"

const NO_PROVIDER_VALIDATION_TEXT = " No provider selected!"

const PROVIDER_LIST = [
	{
		"Name": "Facebook",
		"Value": "facebook"
	},
	{
		"Name": "Vimeo",
		"Value": "vimeo"
	},
	{
		"Name": "YouTube",
		"Value": "youtube"
	}
]

const Profile = () => {

	const { auth, setAuth } = useAuth();
	const userID = auth?.user?.id;
	let isVerified = auth?.user?.email_verified_at;

	const [type, setType] = useState(auth?.user?.role); //sets default account type to homeowner
	const [validType, setValidType] = useState(false);
	const [typeFocus, setTypeFocus] = useState(false);

	const axiosPrivate = useAxiosPrivate();
	const refresh = useRefreshToken();

	const [updating, setUpdating] = useState(false)

	const [externalLinks, setExternalLinks] = useState([])

	const [deleteImages, setDeleteImages] = useState([]);
	const [deleteVideos, setDeleteVideos] = useState([]);
	const [deleteCAImages, setDeleteCAImages] = useState([]);

	const emailRef = useRef();
	const errRef = useRef();
	const phoneRef = useRef();
	const urlRef = useRef();
	const businessNameRef = useRef();
	const businessLicenseRef = useRef();
	const cityRef = useRef();
	const stateRef = useRef();
	const zipRef = useRef();
	const addressRef = useRef();
	const descriptionRef = useRef();
	const aboutRef = useRef();
	const certificatesRef = useRef();
	const facebookRef = useRef();
	const twitterRef = useRef();
	const instagramRef = useRef();
	const linkedinRef = useRef();
	const yelpRef = useRef();
	const googleRef = useRef();
	const externalURLRef = useRef();
	const profileEmailRef = useRef();

	const [files, setFiles] = useState([]);
	const [portfolio, setPortfolio] = useState([]);
	const [caFile, setCAFile] = useState([]);
	const [videos, setVideos] = useState([]);
	const [editImages, setEditImages] = useState();
	const [portfolioImages, setPortfolioImages] = useState(auth?.user?.portfolio || "");
	const [caImages, setCAImages] = useState(auth?.user?.ca_files || "");
	const [portfolioVideos, setPortfolioVideos] = useState(auth?.user?.videos || "");
	const [validFiles, setValidFiles] = useState(false);
	const [validVideos, setValidVideos] = useState(false);
	const [validPortfolio, setValidPortfolio] = useState(false);
	const [validCA, setValidCA] = useState(false);
	const [fileLimit, setFileLimit] = useState(1);
	const [coverLimit, setCoverLimit] = useState(1);
	const [videoLimit, setVideoLimit] = useState(3);
	const [portfolioLimit, setPortfolioLimit] = useState(10);
	const [caLimit, setCALimit] = useState(10);
	const [buttonTitle, setButtonTitle] = useState(type == 2002 ? "Logo" : "Profile Picture");
	const [portfolioButtonTitle, setPortfolioButtonTitle] = useState("Portfolio Pictures");
	const [caButtonTitle, setCAButtonTitle] = useState("Certificate & Award Pictures");
	const [videoButtonTitle, setVideoButtonTitle] = useState("Videos");

	const [businessSlug, setBusinessSlug] = useState(auth?.user?.slug);

	const [businessName, setBusinessName] = useState(auth?.user?.business_name || "");
	const [validBusinessName, setValidBusinessName] = useState(false);
	const [businessNameFocus, setBusinessNameFocus] = useState(false);

	const [address, setAddress] = useState(auth?.user?.address || "");
	const [validAddress, setValidAddress] = useState(false);
	const [addressFocus, setAddressFocus] = useState(false);

	const [businessLicense, setBusinessLicense] = useState(auth?.user?.license_number || "");
	const [validBusinessLicense, setValidBusinessLicense] = useState(false);
	const [businessLicenseFocus, setBusinessLicenseFocus] = useState(false);

	const [businessSpecialty, setBusinessSpecialty] = useState(auth?.user?.contractor_specialty || "");
	const [validBusinessSpecialty, setValidBusinessSpecialty] = useState(false);
	const [businessSpecialtyFocus, setBusinessSpecialtyFocus] = useState(false);

	const [profileImage, setProfileImage] = useState(auth?.user?.profile_picture)
	const [profileCover, setProfileCover] = useState(auth?.user?.cover_picture)

	const [fName, setFName] = useState(auth?.user?.first_name);
	const [validFName, setValidFName] = useState(false);
	const [fNameFocus, setFNameFocus] = useState(false);

	const [lName, setLName] = useState(auth?.user?.last_name);
	const [validLName, setValidLName] = useState(false);
	const [lNameFocus, setLNameFocus] = useState(false);

	const [email, setEmail] = useState(auth?.user?.email);
	const [validEmail, setValidEmail] = useState(false);
	const [emailFocus, setEmailFocus] = useState(false);

	const [profileEmail, setProfileEmail] = useState(auth?.user?.profile_email);
	const [validProfileEmail, setValidProfileEmail] = useState(false);
	const [profileEmailFocus, setProfileEmailFocus] = useState(false);

	const [phone, setPhone] = useState(auth?.user?.phone);
	const [validPhone, setValidPhone] = useState(false);
	const [phoneFocus, setPhoneFocus] = useState(false);

	const [externalURL, setExternalURL] = useState();
	const [validExternalURL, setValidExternalURL] = useState(false);
	const [externalURLFocus, setExternalURLFocus] = useState(false);

	const [url, setUrl] = useState(auth?.user?.business_website || "");
	const [validUrl, setValidUrl] = useState(false);
	const [urlFocus, setUrlFocus] = useState(false);

	const [city, setCity] = useState(auth?.user?.city);
	const [validCity, setValidCity] = useState(false);
	const [cityFocus, setCityFocus] = useState(false);

	const [state, setState] = useState(auth?.user?.state);
	const [validState, setValidState] = useState(false);
	const [stateFocus, setStateFocus] = useState(false);

	const [zip, setZip] = useState(auth?.user?.zip);
	const [validZip, setValidZip] = useState(false);
	const [zipFocus, setZipFocus] = useState(false);

	const [description, setDescription] = useState(auth?.user?.business_description || "");
	const [validDescription, setValidDescription] = useState(true)

	const [about, setAbout] = useState(auth?.user?.business_about || "");
	const [validAbout, setValidAbout] = useState(true)

	const [certificates, setCertificates] = useState(auth?.user?.business_certs_awards || "");
	const [validCertificates, setValidCertificates] = useState(true)

	const [social, setSocial] = useState(auth?.user?.social_links);

	const [facebook, setFacebook] = useState('');
	const [validFacebook, setValidFacebook] = useState(false);
	const [facebookFocus, setFacebookFocus] = useState(false);

	const [yelp, setYelp] = useState('');
	const [validYelp, setValidYelp] = useState(false);
	const [yelpFocus, setYelpFocus] = useState(false);

	const [linkedin, setLinkedin] = useState('');
	const [validLinkedin, setValidLinkedin] = useState(false);
	const [linkedinFocus, setLinkedinFocus] = useState(false);

	const [twitter, setTwitter] = useState('');
	const [validTwitter, setValidTwitter] = useState(false);
	const [twitterFocus, setTwitterFocus] = useState(false);

	const [instagram, setInstagram] = useState('');
	const [validInstagram, setValidInstagram] = useState(false);
	const [instagramFocus, setInstagramFocus] = useState(false);

	const [google, setGoogle] = useState('');
	const [validGoogle, setValidGoogle] = useState(false);
	const [googleFocus, setGoogleFocus] = useState(false);

	const [errMsg, setErrMsg] = useState('');
	const [success, setSuccess] = useState(false);

	const [cover, setCover] = useState([])
	const [validCover, setValidCover] = useState(false)

	const [totalPortfolioImageCount, setTotalPortfolioImageCount] = useState()
	const [totalCAImageCount, setTotalCAImageCount] = useState()
	const [totalVideoCount, setTotalVideoCount] = useState()

	const video1Ref = useRef()
	const video2Ref = useRef()
	const video3Ref = useRef()

	const [externalURL1, setExternalURL1] = useState()
	const [externalURL2, setExternalURL2] = useState()
	const [externalURL3, setExternalURL3] = useState()

	const [validExternalURL1, setValidExternalURL1] = useState(false)
	const [validExternalURL2, setValidExternalURL2] = useState(false)
	const [validExternalURL3, setValidExternalURL3] = useState(false)

	const [provider1, setProvider1] = useState()
	const [provider2, setProvider2] = useState()
	const [provider3, setProvider3] = useState()

	const [externalURL1Focus, setExternalURL1Focus] = useState(false)
	const [externalURL2Focus, setExternalURL2Focus] = useState(false)
	const [externalURL3Focus, setExternalURL3Focus] = useState(false)

	const [contactVisibility, setContactVisibility] = useState(auth?.user?.contact_visibility || true)

	/* Usability focus on load to user */
	useEffect(() => {
		// Set default values if user is contractor
		if (type == 2002 && social){
			setFacebook(JSON.parse(auth?.user?.social_links)[0].facebook);
			setYelp(JSON.parse(auth?.user?.social_links)[0].yelp);
			setLinkedin(JSON.parse(auth?.user?.social_links)[0].linkedin);
			setTwitter(JSON.parse(auth?.user?.social_links)[0].twitter);
			setInstagram(JSON.parse(auth?.user?.social_links)[0].instagram);
			setGoogle(JSON.parse(auth?.user?.social_links)[0].google);
		}

	}, [])

	useEffect(() => {
		setPortfolioImages(auth?.user?.portfolio)
		setCAImages(auth?.user?.ca_files)
		setPortfolioVideos(auth?.user?.videos)
		setBusinessSlug(auth?.user?.slug)
		setBusinessName(auth?.user?.business_name)
		setBusinessLicense(auth?.user?.license_number || "")
		setBusinessSpecialty(auth?.user?.contractor_specialty)
		setProfileImage(auth?.user?.profile_picture)
		setProfileCover(auth?.user?.cover_picture)
		setFName(auth?.user?.first_name);
		setLName(auth?.user?.last_name);
		setEmail(auth?.user?.email);
		setPhone(auth?.user?.phone)
		setUrl(auth?.user?.business_website || "")
		setCity(auth?.user?.city)
		setState(auth?.user?.state)
		setZip(auth?.user?.zip)
		setDescription(auth?.user?.business_description  || "")
		setAbout(auth?.user?.business_about || "")
		setCertificates(auth?.user?.business_certs_awards || "")
		setAddress(auth?.user?.address || "")
		setExternalURL1(auth?.user?.ext_video_url_1 || "")
		setProvider1(auth?.user?.ext_video_provider_1 || "")
		setExternalURL2(auth?.user?.ext_video_url_2 || "")
		setProvider2(auth?.user?.ext_video_provider_2 || "")
		setExternalURL3(auth?.user?.ext_video_url_3 || "")
		setProvider3(auth?.user?.ext_video_provider_3 || "")
	}, [auth, setAuth])

	/* Check username against regex function on username change */
	useEffect(() => {
		const result = EMAIL_REGEX.test(email);
		setValidEmail(result);
	}, [email])

	/* Check Business Name */
	useEffect(() => {
		const result = BUSINESS_REGEX.test(businessName);
		setValidBusinessName(result);
		validBusinessName && setBusinessSlug(convertToSlug(businessName));
	}, [businessName])

	/* Check Business License */
	useEffect(() => {
		const result = LICENSE_REGEX.test(businessLicense);
		setValidBusinessLicense(result);
	}, [businessLicense])

	/* Check Business Specialty */
	useEffect(() => {
		const result = SPECIALTY_REGEX.test(businessSpecialty);
		setValidBusinessSpecialty(result);
	}, [businessSpecialty])

	/* Check Profile Email */
	useEffect(() => {
		const result = EMAIL_REGEX.test(profileEmail);
		setValidProfileEmail(result);
	}, [profileEmail])

	/* Check First Name */
	useEffect(() => {
		const result = NAME_REGEX.test(fName);
		setValidFName(result);
	}, [fName])

	/* Check Last Name */
	useEffect(() => {
		const result = NAME_REGEX.test(lName);
		setValidLName(result);
	}, [lName])

	/* Check Phone */
	useEffect(() => {
		const result = PHONE_REGEX.test(phone);
		setValidPhone(result);
	}, [phone])

	/* Check URL */
	useEffect(() => {
		const result = URL_REGEX.test(url);
		setValidUrl(result);
	}, [phone])

	/* Check City */
	useEffect(() => {
		const result = CITY_REGEX.test(city);
		setValidCity(result);
	}, [city])

	/* Check State */
	useEffect(() => {
		state !== "" || auth?.user?.role == 1001 ? setValidState(true) : setValidState(false);
	}, [state])

	/* Check Zip */
	useEffect(() => {
		const result = ZIPCODE_REGEX.test(zip);
		setValidZip(result);
	}, [zip])

	/* Check Address */
	useEffect(() => {
		const result = ADDRESS_REGEX.test(address);
		setValidAddress(result);
	}, [address])

	/* Check URL */
	useEffect(() => {
		const result = URL_REGEX.test(url);
		setValidUrl(result);
	}, [url])

	// Check Description
	useEffect(() => {
		setValidDescription(description?.length > 999 ? false : true)
	}, [description])

	// Check About
	useEffect(() => {
		setValidDescription(about?.length > 999 ? false : true)
	}, [about])

	// Check Certificates
	useEffect(() => {
		setValidCertificates(certificates?.length > 254 ? false : true)
	}, [certificates])

	/* Check Facebook */
	useEffect(() => {
		if (facebook !== ""){
			const result = FACEBOOK_REGEX.test(facebook);
			setValidFacebook(result);
		} else {
			setValidFacebook(true);
		}
	}, [facebook])

	/* Check Instagram */
	useEffect(() => {
		if (instagram !== ""){
			const result = INSTAGRAM_REGEX.test(instagram);
			setValidInstagram(result);
		} else {
			setValidInstagram(true);
		}
	}, [instagram])

	/* Check Twitter */
	useEffect(() => {
		if (twitter !== ""){
			const result = TWITTER_REGEX.test(twitter);
			setValidTwitter(result);
		} else {
			setValidTwitter(true)
		}
	}, [twitter])

	/* Check LinkedIn */
	useEffect(() => {
		if (linkedin !== ""){
			const result = LINKEDIN_REGEX.test(linkedin);
			setValidLinkedin(result);
		} else {
			setValidLinkedin(true);
		}
	}, [linkedin])

	/* Check Yelp */
	useEffect(() => {
		if (yelp !== ""){
			const result = YELP_REGEX.test(yelp);
			setValidYelp(result);
		} else {
			setValidYelp(true);
		}
	}, [yelp])

	/* Check Google */
	useEffect(() => {
		if (google !== ""){
			const result = GOOGLE_REGEX.test(google);
			setValidGoogle(result);
		} else {
			setValidGoogle(true);
		}
	}, [google])

	/* Clear Error message on any change of user or password */
	useEffect(() => {
		setErrMsg('');
	}, [email])

	useEffect(() => {
		const currentCount = files?.length;
		const addCount = editImages?.length;
		const count = currentCount + addCount;

		if (fileLimit){
			count <= fileLimit ? setValidFiles(true) : setValidFiles(false);
		}

		const formData = new FormData();
		files?.map((file, index) => {
			formData.append('files[]', file);
		});

	}, [files])

	useEffect(() => {
		const currentCount = portfolio?.length;
		const addCount = portfolioImages?.length;
		const count = currentCount + addCount;
		setTotalPortfolioImageCount(count)

		if (portfolioLimit){
			count <= portfolioLimit ? setValidPortfolio(true) : setValidPortfolio(false);
		}

		const formData = new FormData();
		portfolio?.map((file, index) => {
			formData.append('portfolio[]', file);
		});

	}, [portfolio])

	useEffect(() => {
		const currentCount = caFile?.length;
		const addCount = caImages?.length;
		const count = currentCount + addCount;
		setTotalCAImageCount(count)

		if (caLimit){
			count <= caLimit ? setValidCA(true) : setValidCA(false);
		}

		const formData = new FormData();
		caFile?.map((file, index) => {
			formData.append('ca_files[]', file);
		});

	}, [caFile])

	useEffect(() => {
		const currentCount = cover?.length;
		const addCount = profileCover?.length;
		const count = currentCount + addCount;

		if (coverLimit){
			count <= coverLimit ? setValidCover(true) : setValidCover(false);
		}

		const formData = new FormData();
		cover?.map((file, index) => {
			formData.append('cover[]', file);
		});

	}, [cover])

	useEffect(() => {
		const currentCount = videos?.length;
		const addCount = portfolioVideos?.length;
		const count = currentCount + addCount;
		setTotalVideoCount(count)

		if (videoLimit){
			count <= videoLimit ? setValidVideos(true) : setValidVideos(false);
		}

		const formData = new FormData();
		videos?.map((file, index) => {
			formData.append('videos[]', file);
		});

	}, [videos])

	useEffect(() => {
		let result = false
		if (provider1 == "youtube"){
			result = YOUTUBE_REGEX.test(externalURL1);
		}
		if (provider1 == "vimeo"){
			result = VIMEO_REGEX.test(externalURL1);
		}
		if (provider1 == "facebook"){
			result = FACEBOOK_VIDEO_REGEX.test(externalURL1);
		}

		if (provider1){
			setValidExternalURL1(result);
		}
		if (result && provider1){
			// Add
		} else {
			// Delete
		}
	}, [externalURL1, provider1])

	useEffect(() => {
		let result = false

		if (provider2 == "youtube"){
			result = YOUTUBE_REGEX.test(externalURL2);
		}
		if (provider2 == "vimeo"){
			result = VIMEO_REGEX.test(externalURL2);
		}
		if (provider2 == "facebook"){
			result = FACEBOOK_VIDEO_REGEX.test(externalURL2);
		}

		if (provider2){
			setValidExternalURL2(result);
		}
		if (result && provider2){
			// Add
		} else {
			// Delete
		}
	}, [externalURL2, provider2])

	useEffect(() => {
		let result = false

		if (provider3 == "youtube"){
			result = YOUTUBE_REGEX.test(externalURL3);
		}
		if (provider3 == "vimeo"){
			result = VIMEO_REGEX.test(externalURL3);
		}
		if (provider3 == "facebook"){
			result = FACEBOOK_VIDEO_REGEX.test(externalURL3);
		}

		if (provider3){
			setValidExternalURL3(result);
		}
		if (result && provider3){
			// Add
		} else {
			// Delete
		}
	}, [externalURL3, provider3])

	const handleProvider1Change = (e) => {
		const value = e.target.value
		setProvider1(value)
		// Set input focus
		if ( value ){
			video1Ref.current.focus()
		} else {
			setValidExternalURL1(false)
		}
	}

	const handleProvider2Change = (e) => {
		const value = e.target.value
		setProvider2(value)
		// Set input focus
		if ( value ){
			video2Ref.current.focus()
		} else {
			setValidExternalURL2(false)
		}
	}

	const handleProvider3Change = (e) => {
		const value = e.target.value
		setProvider3(value)
		// Set input focus
		if ( value ){
			video3Ref.current.focus()
		} else {
			setValidExternalURL3(false)
		}
	}

	function convertToSlug(Text)
	{
		if (Text){
		return Text
			.toLowerCase()
			.replace(/ /g,'-')
			.replace(/[^\w-]+/g,'')
			;
		} else {
			return
		}
	}

	const handleDeleteCAFile = async (e, i, id) => {
		// We won't delete images until the save button is clicked so lets store the ids of images to delete if save is clicked
		setDeleteCAImages([...deleteCAImages, id])

		// Delete Image from UI
		setCAImages(caImages.filter((image) => image.id !== id))
	}

	const handleDeleteImage = async (e, i, id) => {
		// We won't delete images until the save button is clicked so lets store the ids of images to delete if save is clicked
		setDeleteImages([...deleteImages, id])

		// Delete Image from UI
		setPortfolioImages(portfolioImages.filter((image) => image.id !== id))
	}

	const handleDeleteVideo = async (e, i, id) => {
		// We won't delete videos until the save button is clicked so lets store the ids of images to delete if save is clicked
		setDeleteVideos([...deleteVideos, id])

		// Delete video from UI
		setPortfolioVideos(portfolioVideos.filter((video) => video.id !== id))
	}

	const handleContactDisplay = (e) => {
		setContactVisibility(e.target.checked)
	}

	const handleSubmit = async (e) => {
		e.preventDefault();

		setUpdating(true)

		// Failsafe if slug is null
		businessSlug == null && validBusinessName && businessName && setBusinessSlug(convertToSlug(businessName));
		console.log(businessSlug);

		// Prevent Hacking of submit button by checking against regex again
		const v1 = EMAIL_REGEX.test(email);
		let v3 = false
		if (type == 2002){
			v3 = PHONE_REGEX.test(phone);
		} else {
			v3 = true
		}

		if (!v1 || !v3){
			setErrMsg("Invalid Entry");
			return;
		}

		if (provider1 == "undefined"){
			setProvider1(null)
		}
		if (provider2 == "undefined"){
			setProvider2(null)
		}
		if (provider3 == "undefined"){
			setProvider3(null)
		}

		// Create Social Media JSON
		const socialLinks = JSON.stringify([{
			facebook: facebook,
			instagram: instagram,
			twitter: twitter,
			linkedin: linkedin,
			yelp: yelp,
			google: google
		}]);

		const formData = new FormData();
		console.log(files)
		console.log(videos)
		files.map((file, i) => {
			formData.append('files[]', file);
		});
		if (type == 2002){
			portfolio.map((file, i) => {
				formData.append('portfolio[]', file);
			});
			cover.map((file, i) => {
				formData.append('cover[]', file);
			});
			videos.map((file, i) => {
				formData.append('videos[]', file);
			});
			caFile.map((file, i) => {
				formData.append('ca_files[]', file);
			});
		}

		formData.append('first_name', fName)
		formData.append('last_name', lName)
		formData.append('email', email)
		formData.append('phone', phone)
		formData.append('city', city)
		formData.append('state', state)
		formData.append('zip', zip)
		formData.append('address', address)
		if (type == 2002){
			formData.append('business_name', businessName)
			formData.append('contractor_specialty', businessSpecialty)
			formData.append('business_website', url)
			formData.append('license_number', businessLicense)
			formData.append('slug', businessSlug)
			formData.append('business_about', about)
			formData.append('business_certs_awards', certificates)
			formData.append('business_description', description)
			formData.append('social_links', socialLinks)
			formData.append('ext_video_url_1', externalURL1)
			formData.append('ext_video_provider_1', provider1)
			formData.append('ext_video_url_2', externalURL2)
			formData.append('ext_video_provider_2', provider2)
			formData.append('ext_video_url_3', externalURL3)
			formData.append('ext_video_provider_3', provider3)
			formData.append('delete_images', deleteImages)
			formData.append('delete_videos', deleteVideos)
			formData.append('delete_ca_files', deleteCAImages)
			formData.append('profile_email', profileEmail === "" || profileEmail === "null" || !profileEmail ? email : profileEmail)
			formData.append('contact_visibility', contactVisibility ? 1 : 0)
		}

		// Key to making put work with formdata
		formData.append('_method', 'PUT');

		const dismiss = () =>  toast.dismiss(updateProfileToast.current);
		const updateProfileToast = toast.loading("Updating Profile");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.post('user/' + userID,
			formData,
			{
				signal: controller.signal
			});

			// Use refresh token to update user state from database
			// refresh();
			// console.log(response?.data)

			// Update State
			setAuth({...auth, user: response?.data?.user});

			// Clear out files
			setFiles([])
			setCover([])
			setPortfolio([])
			setVideos([])
			setDeleteImages([])
			setCAFile([])

			setUpdating(false)

			// Update Toast Notification
			toast.update(updateProfileToast, { render: 'Profile Updated', type: 'success', isLoading: false, autoClose: 5000});
		} catch (err) {
			if (!err?.response) {
				toast.update(updateProfileToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else if (err.response?.status === 409) {
				toast.update(updateProfileToast, { render: 'Name Taken', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Name Taken'); */}
			} else if (err.response?.status === 422) {
				toast.update(updateProfileToast, { render: 'The email has already been taken.', type: 'error', isLoading: false});
				{/* setErrMsg('The email has already been taken.'); */}
			} else if (err.response?.status === 401) {
				toast.update(updateProfileToast, { render: 'Authorization Failed', type: 'error', isLoading: false});
				{/* setErrMsg('The email has already been taken.'); */}
			} else {
				toast.update(updateProfileToast, { render: 'Profile Update Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}
	usePageTitle("Profile");

	return (
		<section>
			<article id="editProfile" className='inner'>
				<div className="backendTitleBar">
					<h1>Profile</h1>
					<MdAccountBox />
				</div>
				<div className="backendColumnWrapper">
					<div id="menu" className="column">
						<UserPageNav />
					</div>
					<div id="content" className="column">
						<form onSubmit={handleSubmit} encType="multipart/form-data" className={`${type == 2002 ? 'contractor' : ''}${type == 1001 ? 'homeowner' : ''}`}>

								<section id="profilePhotos">
									<h3>Profile Photos</h3>
									<div className="formContentWrapper">
										<div className="column" id="profile">
											{ profileImage && <img src={`https://storage.iyh.app/${profileImage}`} />}
											<SingleFileDropEmbed accept="image/*" buttonTitle={ type == 2002 ? "Business Logo" : "Profile Picture"} files={files} setFiles={setFiles} validFiles={validFiles} setValidFiles={setValidFiles} />
										</div>
										{ type == 2002 &&
											<div className="column" id="coverPhoto">
												{profileCover && <img src={`https://storage.iyh.app/${profileCover}`} />}
												<CoverDropEmbed accept="image/*" buttonTitle="Cover Photo" cover={cover} setCover={setCover} validCover={validCover} setValidCover={setValidCover} />
											</div>
										}
									</div>
								</section>
							{/* <p className="required">Fields marked with an <span>*</span> are required</p> */}
							{ type == 2002 &&
								<>

									<section id="businessInfo">
										<h3>Business Info</h3>

										<div className="formContentWrapper">
											{/* Business Name */}
											<label htmlFor="business">Business Name:<span className="required">*</span></label>
											<div className="inputWrapper">
												<span className={validBusinessName && businessName ? "valid" : "hide"}><FaCheck /></span>
												<span className={validBusinessName || !businessName ? "hide" : "invalid"}><FaExclamation /></span>
												<input
													type="text"
													id="business"
													className={validBusinessName || !businessName ? null : "error"}
													ref={businessNameRef}
													autoComplete="off"
													onChange={(e) => setBusinessName(e.target.value)}
													value={businessName}
													required
													aria-invalid={validBusinessName ? "false" : "true"}
													aria-describedby="urlnote"
													onFocus={() => setBusinessNameFocus(true)}
													onBlur={() => setBusinessNameFocus(false)}
												/>
											</div>
											<p id="urlnote" className={businessNameFocus && businessName && !validBusinessName ? "instructions" : "offscreen"}>
												Invalid Business Name.
											</p>

											{/* Business License */}
											<label htmlFor="license">Business License:</label>
											<div className="inputWrapper">
												<span className={validBusinessLicense ? "valid" : "hide"}><FaCheck /></span>
												<span className={validBusinessLicense || !businessLicense ? "hide" : "invalid"}><FaExclamation /></span>
												<input
													type="text"
													id="license"
													className={validBusinessLicense || !businessLicense ? null : "error"}
													ref={businessLicenseRef}
													autoComplete="off"
													onChange={(e) => setBusinessLicense(e.target.value)}
													value={businessLicense}
													aria-invalid={validBusinessLicense ? "false" : "true"}
													aria-describedby="licensenote"
													onFocus={() => setBusinessLicenseFocus(true)}
													onBlur={() => setBusinessLicenseFocus(false)}
												/>
											</div>
											<p id="licensenote" className={businessLicenseFocus && businessLicense && !validBusinessLicense ? "instructions" : "offscreen"}>
												Invalid Business License.
											</p>

											{/* Contractor Tagline */}
											<label htmlFor="license">Business Tagline:</label>
											<div className="inputWrapper">
												<span className={validBusinessSpecialty ? "valid" : "hide"}><FaCheck /></span>
												<span className={validBusinessSpecialty || !businessSpecialty ? "hide" : "invalid"}><FaExclamation /></span>
												<input
													type="text"
													id="license"
													className={validBusinessLicense || !businessLicense ? null : "error"}
													ref={businessLicenseRef}
													autoComplete="off"
													onChange={(e) => setBusinessSpecialty(e.target.value)}
													value={businessSpecialty}
													aria-invalid={validBusinessSpecialty ? "false" : "true"}
													aria-describedby="specialtynote"
													onFocus={() => setBusinessSpecialtyFocus(true)}
													onBlur={() => setBusinessSpecialtyFocus(false)}
												/>
											</div>
											<p id="specialtynote" className={businessSpecialtyFocus && businessSpecialty && !validBusinessSpecialty ? "instructions" : "offscreen"}>
												Invalid Business Tagline. Letters, numbers and special characters (-_/,#&) allowed.
											</p>

											{/* Website */}
											<label htmlFor="website">Website:</label>
											<div className="inputWrapper">
												<span className={validUrl ? "valid" : "hide"}><FaCheck /></span>
												<span className={validUrl || !url ? "hide" : "invalid"}><FaExclamation /></span>
												<input
													type="url"
													id="website"
													className={validUrl || !url ? null : "error"}
													ref={urlRef}
													autoComplete="off"
													onChange={(e) => setUrl(e.target.value)}
													value={url}
													aria-invalid={validUrl ? "false" : "true"}
													aria-describedby="urlnote"
													onFocus={() => setUrlFocus(true)}
													onBlur={() => setUrlFocus(false)}
												/>
											</div>
											<p id="urlnote" className={urlFocus && url && !validUrl ? "instructions" : "offscreen"}>
												Invalid website URL. URLs must contain (http:// or https://) a full example would be (https://google.com or http://www.google.com))
											</p>
										</div>
									</section>
								</>
							}

							<section id="businessContact">
								<h3>{type == 2002 ? "Business" : null} Contact</h3>

								<div className="formContentWrapper">
									{type == 2002 &&
										<>
											{/* Contact Visibility */}
											<label htmlFor="contactVisibility">Contact Name Profile Visability:</label>
											<FormGroup id="contactVisibility">
												<FormControlLabel control={<Switch checked={contactVisibility} onChange={handleContactDisplay} />} label={`Contact Name is ${contactVisibility ? "visable" : "hidden"} on your profile`} />
											</FormGroup>
										</>
									}

									{/* First Name */}
									<label htmlFor="fname">First Name:<span className="required">*</span></label>
									<div className="inputWrapper">
										<span className={validFName && fName ? "valid" : "hide"}><FaCheck /></span>
										<span className={validFName || !fName ? "hide" : "invalid"}><FaExclamation /></span>
										<input
											type="text"
											id="fname"
											className={validFName || !fName ? null : "error"}
											onChange={(e) => setFName(e.target.value)}
											value={fName}
											required
											aria-invalid={validFName ? "false" : "true"}
											aria-describedby="fnamenote"
											onFocus={() => setFNameFocus(true)}
											onBlur={() => setFNameFocus(false)}
										/>
									</div>
									<p id="fnamenote" className={fNameFocus && fName && !validFName ? "instructions" : "offscreen"}>
										Invalid First Name.
									</p>

									{/* Last Name */}
									<label htmlFor="lname">Last Name:<span className="required">*</span></label>
									<div className="inputWrapper">
										<span className={validLName && lName ? "valid" : "hide"}><FaCheck /></span>
										<span className={validLName || !lName ? "hide" : "invalid"}><FaExclamation /></span>
										<input
											type="text"
											id="lname"
											className={validLName || !lName ? null : "error"}
											onChange={(e) => setLName(e.target.value)}
											value={lName}
											required
											aria-invalid={validLName ? "false" : "true"}
											aria-describedby="lnamenote"
											onFocus={() => setLNameFocus(true)}
											onBlur={() => setLNameFocus(false)}
										/>
									</div>
									<p id="lnamenote" className={lNameFocus && lName && !validLName ? "instructions" : "offscreen"}>
										Invalid Last Name.
									</p>


									{/* Email */}
									<label htmlFor="email">Email:<span className="required">*</span></label>
									<div className="inputWrapper">
										<span className={validEmail && email ? "valid" : "hide"}><FaCheck /></span>
										<span className={validEmail || !email ? "hide" : "invalid"}><FaExclamation /></span>
										<input
											type="email"
											id="email"
											className={validEmail || !email ? null : "error"}
											ref={emailRef}
											autoComplete="off"
											onChange={(e) => setEmail(e.target.value)}
											value={email}
											required
											disabled
											aria-invalid={validEmail ? "false" : "true"}
											aria-describedby="uidnote"
											onFocus={() => setEmailFocus(true)}
											onBlur={() => setEmailFocus(false)}
										/>
									</div>
									<p id="uidnote" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}>
										Invalid email address.
									</p>

									{type == 2002 &&
										<>
										{/* Profile Email */}
										<label htmlFor="email">Profile Email:</label>
										<p className="profileFieldNote">Overrides default email address above for display on public profile. If left blank the email field above will be displayed.</p>
										<div className="inputWrapper">
											<span className={validProfileEmail ? "valid" : "hide"}><FaCheck /></span>
											<span className={validProfileEmail || !profileEmail ? "hide" : "invalid"}><FaExclamation /></span>
											<input
												type="email"
												id="profileEmail"
												className={validProfileEmail || !profileEmail ? null : "error"}
												ref={profileEmailRef}
												autoComplete="off"
												onChange={(e) => setProfileEmail(e.target.value)}
												value={profileEmail}
												aria-invalid={validProfileEmail ? "false" : "true"}
												aria-describedby="profileemailnote"
												onFocus={() => setProfileEmailFocus(true)}
												onBlur={() => setProfileEmailFocus(false)}
											/>
										</div>
										<p id="profileemaildnote" className={profileEmailFocus && profileEmail && !validProfileEmail ? "instructions" : "offscreen"}>
											Invalid email address.
										</p>
										</>
									}

									{/* Phone */}
									<label htmlFor="phone">Phone: {type == 2002 ? <span className="required">*</span> : <span>(Optional: Used for support)</span> }</label>
									<div className="inputWrapper">
										<span className={validPhone && phone ? "valid" : "hide"}><FaCheck /></span>
										<span className={validPhone || !phone ? "hide" : "invalid"}><FaExclamation /></span>
										<input
											type="tel"
											id="phone"
											className={validPhone || !phone ? null : "error"}
											ref={phoneRef}
											autoComplete="off"
											required
											onChange={(e) => setPhone(e.target.value)}
											value={phone}
											aria-invalid={validPhone ? "false" : "true"}
											aria-describedby="phonenote"
											onFocus={() => setPhoneFocus(true)}
											onBlur={() => setPhoneFocus(false)}
										/>
									</div>
									<p id="phonenote" className={phoneFocus && phone && !validPhone ? "instructions" : "offscreen"}>
										Invalid phone number.
									</p>

									{ type == 1001 &&
									<>
										{/* Zip */}
										<label htmlFor="zip">Zip:<span className="required">*</span></label>
										<div className="inputWrapper">
											<span className={validZip ? "valid" : "hide"}><FaCheck /></span>
											<span className={validZip || !zip ? "hide" : "invalid"}><FaExclamation /></span>
											<input
												type="text"
												id="zip"
												className={validZip || !zip ? null : "error"}
												ref={zipRef}
												autoComplete="off"
												onChange={(e) => setZip(e.target.value)}
												value={zip}
												required
												aria-invalid={validZip ? "false" : "true"}
												aria-describedby="citynote"
												onFocus={() => setZipFocus(true)}
												onBlur={() => setZipFocus(false)}
											/>
										</div>
										<p id="citynote" className={zipFocus && zip && !validZip ? "instructions" : "offscreen"}>
											Invalid Zip. Valid Format includes 55555 or 55555-5555.
										</p>

										{/* Address */}
										<label htmlFor="address">Address: <span>(Optional: Used for autofill of projects)</span></label>
										<div className="inputWrapper">
											<span className={validAddress ? "valid" : "hide"}><FaCheck /></span>
											<span className={validAddress || !address ? "hide" : "invalid"}><FaExclamation /></span>
											<input
												type="text"
												id="address"
												className={validAddress || !address ? null : "error"}
												ref={addressRef}
												autoComplete="off"
												onChange={(e) => setAddress(e.target.value)}
												value={address}
												aria-invalid={validAddress ? "false" : "true"}
												aria-describedby="addressnote"
												onFocus={() => setAddressFocus(true)}
												onBlur={() => setAddressFocus(false)}
											/>
										</div>
										<p id="addressnote" className={addressFocus && address && !validAddress ? "instructions" : "offscreen"}>
											Invalid Address. Valid Format includes letters, numbers and special characters (-_/:#.&*, ).
										</p>
									</>
									}
								</div>
							</section>

							{ type == 2002 &&
							<>
								<section id="businessInfo">
									<h3>Business Location</h3>
									<div className="formContentWrapper">
										{/* City */}
										<label htmlFor="city">City:<span className="required">*</span></label>
										<div className="inputWrapper">
											<span className={validCity && city ? "valid" : "hide"}><FaCheck /></span>
											<span className={validCity || !city ? "hide" : "invalid"}><FaExclamation /></span>
											<input
												type="text"
												id="city"
												className={validCity || !city ? null : "error"}
												ref={cityRef}
												autoComplete="off"
												onChange={(e) => setCity(e.target.value)}
												value={city}
												required
												aria-invalid={validCity ? "false" : "true"}
												aria-describedby="citynote"
												onFocus={() => setCityFocus(true)}
												onBlur={() => setCityFocus(false)}
											/>
										</div>
										<p id="citynote" className={cityFocus && city && !validCity ? "instructions" : "offscreen"}>
											Invalid City.
										</p>

										{/* State */}
										<label htmlFor="state">State:<span className="required">*</span></label>
										<div className="inputWrapper">
											<select
												id="state"
												onChange={(e) => setState(e.target.value)}
												value={state}
												required
												onFocus={() => setStateFocus(true)}
												onBlur={() => setStateFocus(false)}
											>
												<option value="">Select State</option>
												<option value="AL">Alabama</option>
												<option value="AK">Alaska</option>
												<option value="AZ">Arizona</option>
												<option value="AR">Arkansas</option>
												<option value="CA">California</option>
												<option value="CO">Colorado</option>
												<option value="CT">Connecticut</option>
												<option value="DE">Delaware</option>
												<option value="DC">District Of Columbia</option>
												<option value="FL">Florida</option>
												<option value="GA">Georgia</option>
												<option value="HI">Hawaii</option>
												<option value="ID">Idaho</option>
												<option value="IL">Illinois</option>
												<option value="IN">Indiana</option>
												<option value="IA">Iowa</option>
												<option value="KS">Kansas</option>
												<option value="KY">Kentucky</option>
												<option value="LA">Louisiana</option>
												<option value="ME">Maine</option>
												<option value="MD">Maryland</option>
												<option value="MA">Massachusetts</option>
												<option value="MI">Michigan</option>
												<option value="MN">Minnesota</option>
												<option value="MS">Mississippi</option>
												<option value="MO">Missouri</option>
												<option value="MT">Montana</option>
												<option value="NE">Nebraska</option>
												<option value="NV">Nevada</option>
												<option value="NH">New Hampshire</option>
												<option value="NJ">New Jersey</option>
												<option value="NM">New Mexico</option>
												<option value="NY">New York</option>
												<option value="NC">North Carolina</option>
												<option value="ND">North Dakota</option>
												<option value="OH">Ohio</option>
												<option value="OK">Oklahoma</option>
												<option value="OR">Oregon</option>
												<option value="PA">Pennsylvania</option>
												<option value="RI">Rhode Island</option>
												<option value="SC">South Carolina</option>
												<option value="SD">South Dakota</option>
												<option value="TN">Tennessee</option>
												<option value="TX">Texas</option>
												<option value="UT">Utah</option>
												<option value="VT">Vermont</option>
												<option value="VA">Virginia</option>
												<option value="WA">Washington</option>
												<option value="WV">West Virginia</option>
												<option value="WI">Wisconsin</option>
												<option value="WY">Wyoming</option>

											</select>
										</div>

										{/* Zip */}
										<label htmlFor="zip">Zip:<span className="required">*</span></label>
										<div className="inputWrapper">
											<span className={validZip && zip ? "valid" : "hide"}><FaCheck /></span>
											<span className={validZip || !zip ? "hide" : "invalid"}><FaExclamation /></span>
											<input
												type="text"
												id="zip"
												className={validZip || !zip ? null : "error"}
												ref={zipRef}
												autoComplete="off"
												onChange={(e) => setZip(e.target.value)}
												value={zip}
												required
												aria-invalid={validZip ? "false" : "true"}
												aria-describedby="citynote"
												onFocus={() => setZipFocus(true)}
												onBlur={() => setZipFocus(false)}
											/>
										</div>
										<p id="citynote" className={zipFocus && zip && !validZip ? "instructions" : "offscreen"}>
											Invalid Zip. Valid Format includes 55555 or 55555-5555.
										</p>

										{/* Showroom/Business Address */}
										<label htmlFor="address">Showroom/Business Address:<span className="required">*</span></label>
										<div className="inputWrapper">
											<span className={validAddress && address ? "valid" : "hide"}><FaCheck /></span>
											<span className={validAddress || !address ? "hide" : "invalid"}><FaExclamation /></span>
											<input
												type="text"
												id="address"
												className={validAddress || !address ? null : "error"}
												ref={addressRef}
												autoComplete="off"
												onChange={(e) => setAddress(e.target.value)}
												value={address}
												required
												aria-invalid={validAddress ? "false" : "true"}
												aria-describedby="addressnote"
												onFocus={() => setAddressFocus(true)}
												onBlur={() => setAddressFocus(false)}
											/>
										</div>
										<p id="addressnote" className={addressFocus && address && !validAddress ? "instructions" : "offscreen"}>
											Invalid Address. Valid Format includes letters, numbers and special characters (-_/:#.&* ).
										</p>
									</div>
								</section>
							</>
							}


							{ type == 2002 &&
								<>
									<section id="businessAbout">
										<h3>About your business</h3>

										<div className="formContentWrapper">
											{/* Description of Services */}
											<label htmlFor="description">Description of Services:</label>
											<div className="inputWrapper">
												<textarea
													ref={descriptionRef}
													className={validDescription || !description ? null : "error"}
													onChange={(e) => setDescription(e.target.value)}
													name="description"
													id="description"
													rows="5"
													value={description}
												>
												</textarea>
												<span className="charCount">{description?.length}/1000</span>
											</div>

											{/* About Us */}
											<label htmlFor="about">About Us:</label>
											<div className="inputWrapper">
												<textarea
													ref={aboutRef}
													className={validAbout || !about ? null : "error"}
													onChange={(e) => setAbout(e.target.value)}
													name="about"
													id="about"
													rows="5"
													value={about}
												>
												</textarea>
												<span className="charCount">{about?.length}/1000</span>
											</div>

											{/* Certificates & Awards */}
											<label htmlFor="certificates">Certificates & Awards:</label>
											<div className="inputWrapper">
												<textarea
													ref={certificatesRef}
													className={validCertificates || !certificates ? null : "error"}
													onChange={(e) => setCertificates(e.target.value)}
													name="certificates"
													id="certificates"
													rows="5"
													value={certificates}
												>
												</textarea>
												<span className="charCount">{certificates?.length}/255</span>
											</div>

											<div className="certificatesUploader">
												<CADropEmbed accept="image/*" showFiles="true" autoUpload="false" limit={caLimit} multiple="true" buttonTitle={caButtonTitle} caFile={caFile} setCAFile={setCAFile} setValidCA={setValidCA} totalCAImageCount={totalCAImageCount}/>
												<div className="profilePreview">
													{caImages?.length ? (
														<ul>
															{ caImages.map((image, i) =>
																<li key={i} onClick={(e) => handleDeleteCAFile(e, i, image?.id)}>
																	<img src={`https://storage.iyh.app/${image.path}`} alt="" />
																</li>
															)}
														</ul>
													 ) :
													<p>No Certificates & Awards images uploaded</p>
													}
												</div>
											</div>

										</div>
									</section>

									<section id="businessSocial">
										<h3>Social Media</h3>

										<div className="formContentWrapper">

											{/* Facebook */}
											<label htmlFor="facebook">Facebook:</label>
											<div className="inputWrapper">
												<span className={validFacebook ? "valid" : "hide"}><FaCheck /></span>
												<span className={validFacebook || !facebook ? "hide" : "invalid"}><FaExclamation /></span>
												<input
													type="text"
													id="facebook"
													className={validFacebook || !facebook ? null : "error"}
													ref={facebookRef}
													autoComplete="off"
													onChange={(e) => setFacebook(e.target.value)}
													value={facebook}
													aria-invalid={validFacebook ? "false" : "true"}
													aria-describedby="facebooknote"
													onFocus={() => setFacebookFocus(true)}
													onBlur={() => setFacebookFocus(false)}
												/>
											</div>
											<p id="facebooknote" className={facebookFocus && facebook && !validFacebook ? "instructions" : "offscreen"}>
												Invalid Facebook URL, must start with https://www.facebook.com/.
											</p>

											{/* Instagram */}
											<label htmlFor="instagram">Instagram:</label>
											<div className="inputWrapper">
												<span className={validInstagram ? "valid" : "hide"}><FaCheck /></span>
												<span className={validInstagram || !instagram ? "hide" : "invalid"}><FaExclamation /></span>
												<input
													type="text"
													id="instagram"
													className={validInstagram || !instagram ? null : "error"}
													ref={instagramRef}
													autoComplete="off"
													onChange={(e) => setInstagram(e.target.value)}
													value={instagram}
													aria-invalid={validInstagram ? "false" : "true"}
													aria-describedby="instagramnote"
													onFocus={() => setInstagramFocus(true)}
													onBlur={() => setInstagramFocus(false)}
												/>
											</div>
											<p id="instagramnote" className={instagramFocus && instagram && !validInstagram ? "instructions" : "offscreen"}>
												Invalid Instagram URL, must start with https://www.instagram.com/.
											</p>

											{/* Twitter */}
											<label htmlFor="twitter">Twitter:</label>
											<div className="inputWrapper">
												<span className={validTwitter ? "valid" : "hide"}><FaCheck /></span>
												<span className={validTwitter || !twitter ? "hide" : "invalid"}><FaExclamation /></span>
												<input
													type="text"
													id="twitter"
													className={validTwitter || !twitter ? null : "error"}
													ref={twitterRef}
													autoComplete="off"
													onChange={(e) => setTwitter(e.target.value)}
													value={twitter}
													aria-invalid={validTwitter ? "false" : "true"}
													aria-describedby="instagramnote"
													onFocus={() => setTwitterFocus(true)}
													onBlur={() => setTwitterFocus(false)}
												/>
											</div>
											<p id="instagramnote" className={twitterFocus && twitter && !validTwitter ? "instructions" : "offscreen"}>
												Invalid Twitter URL, must start with https://twitter.com/.
											</p>

											{/* LinkedIn */}
											<label htmlFor="linkedin">LinkedIn:</label>
											<div className="inputWrapper">
												<span className={validLinkedin ? "valid" : "hide"}><FaCheck /></span>
												<span className={validLinkedin || !linkedin ? "hide" : "invalid"}><FaExclamation /></span>
												<input
													type="text"
													id="linkedin"
													className={validLinkedin || !linkedin ? null : "error"}
													ref={linkedinRef}
													autoComplete="off"
													onChange={(e) => setLinkedin(e.target.value)}
													value={linkedin}
													aria-invalid={validLinkedin ? "false" : "true"}
													aria-describedby="linkedinnote"
													onFocus={() => setLinkedinFocus(true)}
													onBlur={() => setLinkedinFocus(false)}
												/>
											</div>
											<p id="linkedinnote" className={linkedinFocus && linkedin && !validLinkedin ? "instructions" : "offscreen"}>
												Invalid LnkedIn URL, must start with https://www.linkedin.com/.
											</p>

											{/* Yelp */}
											<label htmlFor="yelp">Yelp:</label>
											<div className="inputWrapper">
												<span className={validYelp ? "valid" : "hide"}><FaCheck /></span>
												<span className={validYelp || !yelp ? "hide" : "invalid"}><FaExclamation /></span>
												<input
													type="text"
													id="yelp"
													className={validYelp || !yelp ? null : "error"}
													ref={yelpRef}
													autoComplete="off"
													onChange={(e) => setYelp(e.target.value)}
													value={yelp}
													aria-invalid={validYelp ? "false" : "true"}
													aria-describedby="yelpnote"
													onFocus={() => setYelpFocus(true)}
													onBlur={() => setYelpFocus(false)}
												/>
											</div>
											<p id="yelpnote" className={yelpFocus && yelp && !validYelp ? "instructions" : "offscreen"}>
												Invalid Yelp URL, must start with https://www.yelp.com/.
											</p>

											{/* Google */}
											<label htmlFor="google">Google:</label>
											<div className="inputWrapper">
												<span className={validGoogle ? "valid" : "hide"}><FaCheck /></span>
												<span className={validGoogle || !google ? "hide" : "invalid"}><FaExclamation /></span>
												<input
													type="text"
													id="google"
													className={validYelp || !google ? null : "error"}
													ref={googleRef}
													autoComplete="off"
													onChange={(e) => setGoogle(e.target.value)}
													value={google}
													aria-invalid={validGoogle ? "false" : "true"}
													aria-describedby="googlenote"
													onFocus={() => setGoogleFocus(true)}
													onBlur={() => setGoogleFocus(false)}
												/>
											</div>
											<p id="googlenote" className={googleFocus && google && !validGoogle ? "instructions" : "offscreen"}>
												Invalid Google URL, must start with https://www.google.com/.
											</p>
										</div>
									</section>

									<section id="businessPortfolio">
										<h3>Portfolio</h3>
										<div className="formContentWrapper">
											<div className="portfolio">
												<PortfolioDropEmbed accept="image/*" showFiles="true" autoUpload="false" limit={portfolioLimit} multiple="true" buttonTitle={portfolioButtonTitle} portfolio={portfolio} setPortfolio={setPortfolio} setValidPortfolio={setValidPortfolio} totalPortfolioImageCount={totalPortfolioImageCount}/>
												<div className="profilePreview">
													{portfolioImages?.length ? (
														<ul>
															{ portfolioImages.map((image, i) =>
																<li key={i} onClick={(e) => handleDeleteImage(e, i, image?.id)}>
																	<img src={`https://storage.iyh.app/${image.path}`} alt="" />
																</li>
															)}
														</ul>
													 ) :
													<p>No portfolio images uploaded</p>
													}
												</div>
											</div>
										</div>
									</section>

									<section id="businessVideos">
										<h3>Videos</h3>
										<div className="formContentWrapper">
											<VideoDropEmbed accept="video/mp4" showFiles="true" autoUpload="false" limit={videoLimit} multiple="true" buttonTitle={videoButtonTitle} videos={videos} setVideos={setVideos} setValidVideos={setValidVideos} totalVideoCount={totalVideoCount} />
											<div className="profilePreview">
												{portfolioVideos?.length ? (
													<ul>
														{ portfolioVideos?.map((video, i) =>
															<li key={i} onClick={(e) => handleDeleteVideo(e, i, video?.id)}>
																<video>
																  <source src={`https://storage.iyh.app/${video.path}`} type="video/mp4" />
																  Your browser does not support HTML video.
																</video>
																{/* <img src={`https://storage.iyh.app/${video.path}`} alt="" /> */}
															</li>
														)}
													</ul>
												 ) :
												<p>No videos uploaded</p>
												}
											</div>
											<hr />
											<p>Have videos hosted on a service already?</p>
											<div className="externalVideoWrapper">
												<select onChange={(e) => handleProvider1Change(e)} value={provider1}>
													<option value="">Select Provider</option>
													{ PROVIDER_LIST.length && PROVIDER_LIST.map((provider, i) =>
														<option key={i} value={provider.Value}>{provider.Name}</option>
													)}
												</select>
												<div className="inputWrapper">
													<span className={validExternalURL1 ? "valid" : "hide"}><FaCheck /></span>
													<span className={validExternalURL1 || !externalURL1 ? "hide" : "invalid"}><FaExclamation /></span>
													<input
														type="text"
														id="external"
														autoComplete="off"
														value={externalURL1}
														ref={video1Ref}
														onChange={(e) => setExternalURL1(e.target.value)}
														aria-invalid={validExternalURL1 ? "false" : "true"}
														aria-describedby="externalnote1"
														onFocus={() => setExternalURL1Focus(true)}
														onBlur={() => setExternalURL1Focus(false)}

													/>
													<p id="externalnote1" className={externalURL1Focus && externalURL1 && !validExternalURL1 ? "instructions" : "offscreen"}>
														Invalid video url.
														{provider1 == "youtube" && YOUTUBE_VALUDATION_TEXT}{!provider1 && NO_PROVIDER_VALIDATION_TEXT}
														{provider1 == "vimeo" && VIMEO_VALUDATION_TEXT}{!provider1 && NO_PROVIDER_VALIDATION_TEXT}
														{provider1 == "facebook" && FACEBOOK_VALUDATION_TEXT}{!provider1 && NO_PROVIDER_VALIDATION_TEXT}
													</p>
												</div>
											</div>

											<div className="externalVideoWrapper">
												<select onChange={(e) => handleProvider2Change(e)} value={provider2}>
													<option value="">Select Provider</option>
													{ PROVIDER_LIST.length && PROVIDER_LIST.map((provider, i) =>
														<option key={i} value={provider.Value}>{provider.Name}</option>
													)}
												</select>
												<div className="inputWrapper">
													<span className={validExternalURL2 ? "valid" : "hide"}><FaCheck /></span>
													<span className={validExternalURL2 || !externalURL2 ? "hide" : "invalid"}><FaExclamation /></span>
													<input
														type="text"
														id="external"
														autoComplete="off"
														value={externalURL2}
														ref={video2Ref}
														onChange={(e) => setExternalURL2(e.target.value)}
														aria-invalid={validExternalURL2 ? "false" : "true"}
														aria-describedby="externalnote2"
														onFocus={() => setExternalURL2Focus(true)}
														onBlur={() => setExternalURL2Focus(false)}

													/>
													<p id="externalnote2" className={externalURL2Focus && externalURL2 && !validExternalURL2 ? "instructions" : "offscreen"}>
														Invalid video url.
														{provider2 == "youtube" && YOUTUBE_VALUDATION_TEXT}{!provider2 && NO_PROVIDER_VALIDATION_TEXT}
														{provider2 == "vimeo" && VIMEO_VALUDATION_TEXT}{!provider2 && NO_PROVIDER_VALIDATION_TEXT}
														{provider2 == "facebook" && FACEBOOK_VALUDATION_TEXT}{!provider2 && NO_PROVIDER_VALIDATION_TEXT}
													</p>
												</div>
											</div>

											<div className="externalVideoWrapper">
												<select onChange={(e) => handleProvider3Change(e)} value={provider3}>
													<option value="">Select Provider</option>
													{ PROVIDER_LIST.length && PROVIDER_LIST.map((provider, i) =>
														<option key={i} value={provider.Value}>{provider.Name}</option>
													)}
												</select>
												<div className="inputWrapper">
													<span className={validExternalURL3 ? "valid" : "hide"}><FaCheck /></span>
													<span className={validExternalURL3 || !externalURL3 ? "hide" : "invalid"}><FaExclamation /></span>
													<input
														type="text"
														id="external"
														autoComplete="off"
														value={externalURL3}
														ref={video3Ref}
														onChange={(e) => setExternalURL3(e.target.value)}
														aria-invalid={validExternalURL3 ? "false" : "true"}
														aria-describedby="externalnote3"
														onFocus={() => setExternalURL3Focus(true)}
														onBlur={() => setExternalURL3Focus(false)}

													/>
													<p id="externalnote3" className={externalURL3Focus && externalURL3 && !validExternalURL3 ? "instructions" : "offscreen"}>
														Invalid video url.
														{provider3 == "youtube" && YOUTUBE_VALUDATION_TEXT}{!provider3 && NO_PROVIDER_VALIDATION_TEXT}
														{provider3 == "vimeo" && VIMEO_VALUDATION_TEXT}{!provider3 && NO_PROVIDER_VALIDATION_TEXT}
														{provider3 == "facebook" && FACEBOOK_VALUDATION_TEXT}{!provider3 && NO_PROVIDER_VALIDATION_TEXT}
													</p>
												</div>
											</div>

										</div>
									</section>
								</>
							}
							{type == 2002 ?
								<button disabled={!validEmail || !validPhone || !validFName || !validLName || !validCity || !validState || !validZip || !isVerified || !validFacebook || !validInstagram || !validLinkedin || !validTwitter || !validYelp || updating || !validAbout || !validDescription || !validCertificates ? true : false}>{updating ? 'Updating' : 'Update'} Profile</button>
								:
								<button disabled={!validEmail || !validFName || !validLName || !validCity || !validState || !validZip || !isVerified || !validFacebook || !validInstagram || !validLinkedin || !validTwitter || !validYelp || updating ? true : false}>{updating ? 'Updating' : 'Update'} Profile</button>
							}
						</form>
					</div>
				</div>
			</article>
			<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
			/>
		</section>

	)
}

export default Profile