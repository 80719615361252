import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from '../hooks/useAuth';
import { AiFillDelete } from 'react-icons/ai';
import { AiOutlineShareAlt } from 'react-icons/ai';
import { MdOutlineEdit } from 'react-icons/md';
import { GrFormView } from 'react-icons/gr';
import { FaCheck } from 'react-icons/fa';
import { FaExclamation } from 'react-icons/fa';
import { MdOutlineLibraryAdd } from 'react-icons/md';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import Loader from './Loader';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';


const Categories = () => {

	const [loading, setLoading] = useState(false);
	const [categories, setCategories] = useState();
	const axiosPrivate = useAxiosPrivate();

	const navigate = useNavigate();
	const location = useLocation();

	const auth = useAuth();
	const role = auth?.role;
	const id = auth?.id;

	const [filterLetter, setFilterLetter] = useState()
	const [search, setSearch] = useState()
	const [searchResults, setSearchResults] = useState()

	const [open, setOpen] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [openView, setOpenView] = useState(false);
	const [openDelete, setOpenDelete] = useState(false);
	const [openAdd, setOpenAdd] = useState(false);

	const [deleteID, setDeleteID] = useState()
	const [deleteName, setDeleteName] = useState()

	const [viewName, setViewName] = useState()
	const [viewDescription, setViewDescription] = useState()
	const [viewZips, setViewZips] = useState()

	const [editID, setEditID] = useState()
	const [editName, setEditName] = useState()
	const [editDescription, setEditDescription] = useState()

	const [addName, setAddName] = useState()

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getCategories = async () => {
			try {
				const response = await axiosPrivate.get('categories', {
					signal: controller.signal
				});
				response.data.sort((a, b) => {
					if (a.category_name < b.category_name) {
            return -1;
          }
          if (a.category_name > b.category_name) {
            return 1;
          }
          return 0;
				})
				setCategories(response.data);
				setSearchResults(response.data);
				setLoading(false)
			} catch(err) {
				console.log(err.message);
				!auth && navigate('/login', { state: { from: location }, replace: true });
			}
		}

		getCategories();

		return () => controller.abort();
		// eslint-disable-next-line
	},[])

	useEffect(() => {
		if (search === ""){
			setSearchResults(categories)
		} else {
			const resultsArray = categories?.filter(category => category.category_name.toLocaleLowerCase().includes(search))
			setSearchResults(resultsArray)
		}
	}, [search])

	const addCat = (cat) => {
		console.log(cat);
		setCategories([...categories, cat]);
		setSearchResults([...searchResults, cat]);
	}

	const handleAdd = async () => {
		const dismiss = () =>  toast.dismiss(addCategoryToast.current);
		const addCategoryToast = toast.loading("Adding Category");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.post('category',
			JSON.stringify({category_name: addName, category_description: null}),
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(addCategoryToast, { render: 'Category Added', type: 'success', isLoading: false, autoClose: 5000});

			// Close Alert Window
			setOpenAdd(false)

			// Add category to UI
			// Get market id from database
			const id = response?.data?.categories?.id
			const category_name = addName

			// Get date from database
			const created_at = response?.data?.categories?.created_at

			// Add market to markets
			addCat({id, category_name, created_at})

			// Clear Form
			setAddName("");

		} catch (err) {
			if (!err?.response) {
				toast.update(addCategoryToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(addCategoryToast, { render: 'Category Add Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();

	}

	const handleDelete = async () => {
		const dismiss = () =>  toast.dismiss(deleteCategoryToast.current);
		const deleteCategoryToast = toast.loading("Deleting Category");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.delete('category/' + deleteID,
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(deleteCategoryToast, { render: 'Category Deleted', type: 'success', isLoading: false, autoClose: 5000});

			// Close Alert Window
			setOpenDelete(false);

			// Delete job from UI
			setSearchResults(searchResults.filter((category) => category.id !== deleteID))
			setCategories(categories.filter((category) => category.id !== deleteID))

		} catch (err) {
			if (!err?.response) {
				toast.update(deleteCategoryToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(deleteCategoryToast, { render: 'Category Delete Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	};

	const handleClose = () => {
		setOpen(false);
		setOpenDelete(false)
		setOpenEdit(false)
		setOpenView(false)
	};

	const handleEdit = (e, i, id, name, description) => {
		setOpenEdit(true);
		setEditDescription(description)
		setEditName(editName)
		setEditID(editID)
	};

	const handleEditSave = async () => {
		const dismiss = () =>  toast.dismiss(saveCategoryToast.current);
		const saveCategoryToast = toast.loading("Updating Category");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.put('category/' + editID,
			JSON.stringify({category_name: editName}),
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(saveCategoryToast, { render: 'Category Updated', type: 'success', isLoading: false, autoClose: 5000});

			// Close Alert Window
			setOpenEdit(false);
			setEditDescription()
			setEditName()
			setEditID()

			// Update category state
			const index = searchResults.map(function(x) {return x.id; }).indexOf(editID);
			searchResults[index].category_name = editName;

		} catch (err) {
			if (!err?.response) {
				toast.update(saveCategoryToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(saveCategoryToast, { render: 'Category Update Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	};

	const handleCloseEdit = () => {
		setOpenEdit(false);
	};

	const handleCloseAdd = () => {
		setOpenAdd(false);
	};

	const handleCloseDelete = () => {
		setOpenDelete(false);
	};

	const handleView = () => {
		setOpenView(true);
	};

	const handleAddCategory = () => {
		setOpenAdd(true);
	};

	const handleCloseView = () => {
		setOpenView(false);
	};

	const deleteCategory = (e, i, id, name) => {
		setDeleteName(name);
		setDeleteID(id);
		setOpenDelete(true)
	};

	const viewCategory = (e, i, id, name, description) => {
		setViewName(name)
		setViewDescription(description)
		setOpenView(true)
	};

	const editCategory = (e, i, id, name, description) => {
		setEditDescription(description)
		setEditName(name)
		setEditID(id)
		setOpenEdit(true)
	};

	return (
		<>
			{categories?.length ?
		    (
				<>
					<div className="selectContainer">
						<div className="selectWrapper">
							<Box>
								<FormControl fullWidth>
									<TextField id="outlined-basic" label="Search" variant="outlined" onChange={(e) => setSearch(e.target.value)} />
								</FormControl>
							</Box>
						</div>
						<div className="selectWrapper">

						</div>
						<div className="selectWrapper alignRight">
							<MdOutlineLibraryAdd onClick={(e) => handleAddCategory()}/>
						</div>
					</div>

					<ul className="serviceCatList">
						<li className="key">
							<div className="category">Name</div>
							<div className="buttons"></div>
						</li>
						{searchResults?.sort((a, b) => a.category_name > b.category_name).map((category, i) =>
							<li key={i} >
								<div className="category">
									{category?.category_name}
								</div>
								<div className="buttons">
									<div className="edit" onClick={(e) => editCategory(e, i, category?.id, category?.category_name, category?.category_description)}>
										<span><MdOutlineEdit /></span>
									</div>
									<div className="delete" onClick={(e) => deleteCategory(e, i, category?.id, category?.category_name)}>
										<span><AiFillDelete /></span>
									</div>
								</div>
							</li>
						)}
					</ul>
					{/* Delete Dialog */}
					<Dialog
						open={openDelete}
						onClose={handleCloseDelete}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					  >
						<DialogTitle id="alert-dialog-title">
						  {"Are you sure you want to delete this category?"}
						</DialogTitle>
						<DialogContent>
						  <DialogContentText id="alert-dialog-description">
							This action cannot be undone. This will permanently delete the category with name, "{deleteName}".
						  </DialogContentText>
						</DialogContent>
						<DialogActions>
						  <button className="cancel" onClick={handleCloseDelete}>Cancel</button>
						  <button className="confirm" onClick={handleDelete}>Yes, delete category</button>
						</DialogActions>
					  </Dialog>

					{/* Edit Dialog */}
					<Dialog
						open={openEdit}
						onClose={handleCloseEdit}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					  >
						<DialogTitle id="alert-dialog-title">
						  {`Editing category ${editName}`}
						</DialogTitle>
						<DialogContent>
						  <DialogContentText id="alert-dialog-description">
							<form>

								{/* Name */}
								<label htmlFor="name">Category Name:</label>
								<div className="inputWrapper">
									<input
										type="text"
										id="name"
										autoComplete="off"
										onChange={(e) => setEditName(e.target.value)}
										value={editName}
									/>
								</div>
							</form>
						  </DialogContentText>
						</DialogContent>
						<DialogActions>
						  <button className="cancel" onClick={handleCloseEdit}>Cancel</button>
						  <button className="confirm" onClick={handleEditSave}>Yes, update category</button>
						</DialogActions>
					  </Dialog>

					{/* Add Category Dialog */}
					<Dialog
						open={openAdd}
						onClose={handleCloseAdd}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					  >
						<DialogTitle id="alert-dialog-title">
						  {`Adding category`}
						</DialogTitle>
						<DialogContent>
						  <DialogContentText id="alert-dialog-description">
							<form>

								{/* Name */}
								<label htmlFor="name">Category Name:</label>
								<div className="inputWrapper">
									<input
										type="text"
										id="name"
										autoComplete="off"
										onChange={(e) => setAddName(e.target.value)}
										value={addName}
									/>
								</div>
							</form>
						  </DialogContentText>
						</DialogContent>
						<DialogActions>
						  <button className="cancel" onClick={handleCloseAdd}>Cancel</button>
						  <button className="confirm" onClick={handleAdd}>Yes, add category</button>
						</DialogActions>
					  </Dialog>

					{/* View Dialog */}
					<Dialog
						open={openView}
						onClose={handleCloseView}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
						className="viewMarketPopup"
					  >
						<DialogTitle id="alert-dialog-title">
						  {`${viewName} Market`}
						</DialogTitle>
						<DialogContent>
							<>
								{viewDescription}
							</>
						</DialogContent>
						<DialogActions>
						  <button className="cancel" onClick={handleCloseView}>Close</button>
						</DialogActions>
					  </Dialog>
				</>
			) : loading ?
				<Loader />
				:
				<p>No categories to display</p>
			}


			  <ToastContainer
			  position="top-right"
			  autoClose={5000}
			  hideProgressBar={false}
			  newestOnTop={false}
			  closeOnClick
			  rtl={false}
			  pauseOnFocusLoss
			  draggable
			  pauseOnHover
			  theme="colored"
			  />
		</>
	)
}

export default Categories