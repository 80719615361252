import { Outlet } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useState, useEffect } from 'react';
import axios from 'axios'

const Layout = () => {

	const [loading, setLoading] = useState(false)

	const [geoLocation, setGeoLocation] = useState()
	const [ip, setIP] = useState()
	const [zip, setZip] = useState()
	const [city, setCity] = useState()

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getUserLocationFromAPI = async () => {
			try {
				const response = await axios.get('https://ipgeolocation.abstractapi.com/v1/?api_key=7429bd415acf4f8090593cd63a568a3b',
				{
					signal: controller.signal
				});
				setGeoLocation(response.data)
				setLoading(false)
			} catch(err) {
				console.log(err.message)
			}
		}

		getUserLocationFromAPI()

		return () => controller.abort();
		// eslint-disable-next-line
	},[])
	return (
		<main className="App">
			<Header />
			<Outlet context={[geoLocation, setGeoLocation]} />
			<Footer />
		</main>
	)
}

export default Layout