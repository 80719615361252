import { useState, useEffect } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import { AiFillDelete } from 'react-icons/ai';
import { AiOutlineShareAlt } from 'react-icons/ai';
import { GrFormEdit } from 'react-icons/gr';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import Loader from './Loader';

const UserFavorites = () => {

	const [loading, setLoading] = useState(false);
	const [favorites, setFavorites] = useState();
	const axiosPrivate = useAxiosPrivate();

	const navigate = useNavigate();
	const location = useLocation();

	const auth = useAuth();
	const role = auth?.role;
	const id = auth?.id;

	const [deleteName, setDeleteName] = useState();
	const [deleteID, setDeleteID] = useState();
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleDelete = async () => {
		const dismiss = () =>  toast.dismiss(deleteProfileToast.current);
		const deleteProfileToast = toast.loading("Deleting Job");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.delete('user/favorite/' + deleteID,
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(deleteProfileToast, { render: 'Job Deleted', type: 'success', isLoading: false, autoClose: 5000});

			// Close Alert Window
			setOpen(false);

			// Delete job from UI
			setFavorites(favorites.filter((favorite) => favorite.id !== deleteID))

		} catch (err) {
			if (!err?.response) {
				toast.update(deleteProfileToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(deleteProfileToast, { render: 'Job Delete Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	};

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getFavorites = async () => {
			try {
				const response = await axiosPrivate.get('user/favorites', {
					signal: controller.signal
				});
				console.log(response.data);
				setFavorites(response.data);
				setLoading(false)
			} catch(err) {
				console.log(err.message);
				{/* !auth && navigate('/login', { state: { from: location }, replace: true }); */}
			}
		}

		getFavorites();

		return () => controller.abort();

	},[])

	const deleteFavorite = (e, i, name, id) => {
		setOpen(true);
		setDeleteName(name);
		setDeleteID(id);
	}

	const addFavorite = (favorite) => {
		console.log(favorite);
		setFavorites([...favorites, favorite]);
	}


	const formatDate = (dateString) => {
	  const options = { month: "long", day: "numeric", year: "numeric" }
	  return new Date(dateString).toLocaleDateString(undefined, options)
	}

	function convertToSlug(Text)
	{
		return Text
			.toLowerCase()
			.replace(/ /g,'-')
			.replace(/[^\w-]+/g,'')
			;
	}

	function contractorURL(name){
		return "/contractor/" + convertToSlug(name);
	}

	return (
		<div className="backendList">
			{favorites?.length
				? (
					<>
					<ul className="favoriteList">
						<li className="key">
							<div className="name">Contractor</div>
							<div className="date">Date Added</div>
							<div className="buttons"></div>
						</li>
						{favorites.map((favorite, i) =>
							<li key={i}>
								<div className="name">
									<Link to={contractorURL(favorite?.contractor_name)}>{favorite?.contractor_name}</Link>
								</div>
								<div className="date">
									{formatDate(favorite?.created_at)}
								</div>
								<div className="buttons">
									<div className="delete" onClick={(e) => deleteFavorite(e, i, favorite?.contractor_name, favorite?.id)}>
										<span><AiFillDelete /></span>
									</div>
								</div>
							</li>
						)}
					</ul>

					<Dialog
						open={open}
						onClose={handleClose}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					  >
						<DialogTitle id="alert-dialog-title">
						  {"Are you sure you want to delete this favorite?"}
						</DialogTitle>
						<DialogContent>
						  <DialogContentText id="alert-dialog-description">
							This action cannot be undone. This will permanently delete the favorite with name, "{deleteName}".
						  </DialogContentText>
						</DialogContent>
						<DialogActions>
						  <button className="cancel" onClick={handleClose}>Cancel</button>
						  <button className="confirm" onClick={handleDelete}>Yes, delete favorite</button>
						</DialogActions>
					  </Dialog>
		</>
				) :
					loading ?
					<Loader />
					:
					<p>You have not created any favorites yet.</p>
			}
		</div>
	)
}

export default UserFavorites