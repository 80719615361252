const Loader = () => {
	return (
		<div className="waterfall">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>	
		</div>
	)
}

export default Loader