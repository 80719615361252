
import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';
import { FaExclamation } from 'react-icons/fa';
import usePageTitle from '../hooks/usePageTitle';
import UserPageNav from '../components/UserPageNav';
import useAuth from '../hooks/useAuth';
import { ToastContainer, toast } from 'react-toastify';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const NAME_REGEX = /^[a-zA-Z0-9-_&@ ]{1,50}$/;
const ZIP_REGEX = /^[0-9]{5}$/;
const STATE_REGEX = /^[a-zA-Z0-9-_&@ ]{1,50}$/;
const DESCRIPTION_REGEX = /^[a-zA-Z0-9-_.,& ]{1,500}$/;
const COUNTY_REGEX = /^[a-zA-Z0-9-_.,& ]{1,50}$/;

const CreateZipCode = ({ addZipCode, activeStates, activeStateCounties }) => {

	const { auth } = useAuth();
	const userID = auth?.user?.id;
	let isVerified = auth?.user?.email_verified_at;

	const axiosPrivate = useAxiosPrivate();

	const zipRef = useRef();
	const countyRef = useRef();
	const stateRef = useRef();
	const descriptionRef = useRef();

	const [zipcode, setZipcode] = useState("");
	const [validZipcode, setValidZipcode] = useState(false);
	const [zipcodeFocus, setZipcodeFocus] = useState(false);

	const [description, setDescription] = useState("");
	const [validDescription, setValidDescription] = useState(false);

	const [county, setCounty] = useState("");
	const [countyFocus, setCountyFocus] = useState("");
	const [validCounty, setValidCounty] = useState(false);

	const [state, setState] = useState("");
	const [stateFocus, setStateFocus] = useState("");
	const [validState, setValidState] = useState(false);

	/* Set Focus to name on load */
	useEffect(() => {
		zipRef.current.focus()
	}, [])

	/* Check Zipcode */
	useEffect(() => {
		const result = ZIP_REGEX.test(zipcode);
		setValidZipcode(result);
	}, [zipcode])

	/* Check State */
	useEffect(() => {
		state?.length > 0 ? setValidState(true) :  setValidState(false)
		state?.length > 0 ? console.log("valid state") :  console.log("invalid state")
	}, [state])

	/* Check County */
	useEffect(() => {
		county?.length > 0 ? setValidCounty(true) : setValidCounty(false)
		county?.length > 0 ? console.log("valid county") :  console.log("invalid county")
	}, [county])

	const handleSubmit = async (e) => {
		e.preventDefault();

		const dismiss = () =>  toast.dismiss(addMarketToast.current);
		const addMarketToast = toast.loading("Adding Zip Code");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.post('zip-code',
			JSON.stringify({zipcode: zipcode, county: county, state: state, market: null}),
			{
				signal: controller.signal
			});

			// Get market id from database
			const id = response?.data?.zipcode?.id

			// Get date from database
			const created_at = response?.data?.zipcode?.created_at

			// Add market to markets
			addZipCode({id, zipcode, county, state, created_at})

			// Clear Form
			setZipcode("");
			setState("");
			setCounty("");

			// Update Toast Notification
			toast.update(addMarketToast, { render: 'Zip Code Added', type: 'success', isLoading: false, autoClose: 5000});
		} catch (err) {
			if (!err?.response) {
				toast.update(addMarketToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else if (err.response?.status === 401) {
				toast.update(addMarketToast, { render: 'Authorization Failed', type: 'error', isLoading: false});
				{/* setErrMsg('The email has already been taken.'); */}
			} else {
				toast.update(addMarketToast, { render: 'Zip Code Add Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	const renderOpts = key => activeStateCounties[key].map((c, i) => <option key={i} value={c.county}>{c.county}</option>)

	// useEffect(() => {
	// 	state !== null && state !== "" ? setValidState(false) : setValidState(true)
	// }, [state])

	return (
		<>
			<form onSubmit={handleSubmit} >
				{/* Name */}
				<label htmlFor="name">Zip Code:</label>
				<div className="inputWrapper">
					<span className={validZipcode ? "valid" : "hide"}><FaCheck /></span>
					<span className={validZipcode || !zipcode ? "hide" : "invalid"}><FaExclamation /></span>
					<input
						type="text"
						id="name"
						className={validZipcode || !zipcode ? null : "error"}
						ref={zipRef}
						autoComplete="off"
						onChange={(e) => setZipcode(e.target.value)}
						value={zipcode}
						aria-invalid={validZipcode ? "false" : "true"}
						aria-describedby="namenote"
						onFocus={() => setZipcodeFocus(true)}
						onBlur={() => setZipcodeFocus(false)}
					/>
				</div>
				<p id="namenote" className={zipcodeFocus && zipcode && !validZipcode ? "instructions" : "offscreen"}>
					Invalid Zipcode. Zipcodes must be exactly 5 numbers long.
				</p>

				{/* State */}
				<label htmlFor="state">State:</label>
				<div className="inputWrapper">
					<select
						id="state"
						onChange={(e) => setState(e.target.value)}
						value={state}
						required
						onFocus={() => setStateFocus(true)}
						onBlur={() => setStateFocus(false)}
					>
						<option value="">Select State</option>
						{activeStates?.map((s, i) =>
							<option key={i} value={s.state}>{s.state}</option>
						)}
					</select>
				</div>

				{/* County */}
				<label htmlFor="county">County:</label>
				<div className="inputWrapper">
					<select
						id="county"
						onChange={(e) => setCounty(e.target.value)}
						value={county}
						required
						onFocus={() => setCountyFocus(true)}
						onBlur={() => setCountyFocus(false)}
						disabled={!validState}
					>
						{state !== "" &&
						<>
							<option value="">Select County</option>
							{state == "Alabama" && renderOpts('Alabama')}
							{state == "Alaska" && renderOpts('Alaska')}
							{state == "Arizona" && renderOpts('Arizona')}
							{state == "Arkansas" && renderOpts('Arkansas')}
							{state == "California" && renderOpts('California')}
							{state == "Colorado" && renderOpts('Colorado')}
							{state == "Connecticut" && renderOpts('Connecticut')}
							{state == "Delaware" && renderOpts('Delaware')}
							{state == "District of Columbia" && renderOpts('District of Columbia')}
							{state == "Florida" && renderOpts('Florida')}
							{state == "Georgia" && renderOpts('Georgia')}
							{state == "Hawaii" && renderOpts('Hawaii')}
							{state == "Idaho" && renderOpts('Idaho')}
							{state == "Illinois" && renderOpts('Illinois')}
							{state == "Indiana" && renderOpts('Indiana')}
							{state == "Iowa" && renderOpts('Iowa')}
							{state == "Kansas" && renderOpts('Kansas')}
							{state == "Kentucky" && renderOpts('Kentucky')}
							{state == "Louisiana" && renderOpts('Louisiana')}
							{state == "Maine" && renderOpts('Maine')}
							{state == "Maryland" && renderOpts('Maryland')}
							{state == "Massachusetts" && renderOpts('Massachusetts')}
							{state == "Michigan" && renderOpts('Michigan')}
							{state == "Minnesota" && renderOpts('Minnesota')}
							{state == "Mississippi" && renderOpts('Mississippi')}
							{state == "Missouri" && renderOpts('Missouri')}
							{state == "Montana" && renderOpts('Montana')}
							{state == "Nebraska" && renderOpts('Nebraska')}
							{state == "Nevada" && renderOpts('Nevada')}
							{state == "New Hampshire" && renderOpts('New Hampshire')}
							{state == "New Jersey" && renderOpts('New Jersey')}
							{state == "New Mexico" && renderOpts('New Mexico')}
							{state == "New York" && renderOpts('New York')}
							{state == "North Carolina" && renderOpts('North Carolina')}
							{state == "North Dakota" && renderOpts('North Dakota')}
							{state == "Ohio" && renderOpts('Ohio')}
							{state == "Oklahoma" && renderOpts('Oklahoma')}
							{state == "Oregon" && renderOpts('Oregon')}
							{state == "Pennsylvania" && renderOpts('Pennsylvania')}
							{state == "Rhode Island" && renderOpts('Rhode Island')}
							{state == "South Carolina" && renderOpts('South Carolina')}
							{state == "South Dakota" && renderOpts('South Dakota')}
							{state == "Tennessee" && renderOpts('Tennessee')}
							{state == "Texas" && renderOpts('Texas')}
							{state == "Utah" && renderOpts('Utah')}
							{state == "Vermont" && renderOpts('Vermont')}
							{state == "Virginia" && renderOpts('Virginia')}
							{state == "Washington" && renderOpts('Washington')}
							{state == "West Virginia" && renderOpts('West Virginia')}
							{state == "Wisconsin" && renderOpts('Wisconsin')}
							{state == "Wyoming" && renderOpts('Wyoming')}
						</>
						}

					</select>
				</div>
				<button disabled={!validCounty || !validCounty || !validState ? true : false}>Add Zipcode</button>
			</form>
			<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
			/>
		</>
	)
}

export default CreateZipCode