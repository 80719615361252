import { useEffect, useState } from "react";
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Loader from './Loader';
import DashboardItem from './DashboardItem';
import { ImBullhorn } from 'react-icons/im';
import { AiOutlineDashboard } from 'react-icons/ai'
import { AiFillPhone } from 'react-icons/ai'
import { CgProfile } from 'react-icons/cg'
import { MdAccountBox } from 'react-icons/md'
import { FaUserCircle } from 'react-icons/fa'
import { GiStarsStack } from 'react-icons/gi'
import { AiFillStar } from 'react-icons/ai'
import { AiOutlineBarChart } from 'react-icons/ai'
import { HiChartSquareBar } from 'react-icons/hi'
import { MdDashboard } from 'react-icons/md';
import { RiCustomerService2Line } from 'react-icons/ri';
import { MdHelpCenter } from 'react-icons/md';
import { AiFillMessage } from 'react-icons/ai';
import { MdMessage } from 'react-icons/md';
import { MdFavorite } from 'react-icons/md';
import { MdFactCheck } from 'react-icons/md';
import { MdRequestPage } from 'react-icons/md';
import { MdSettings } from 'react-icons/md';
import { MdWork } from 'react-icons/md';
import { MdEmail } from 'react-icons/md';
import { MdSearch } from 'react-icons/md';
import { MdPendingActions } from 'react-icons/md';
import { MdManageSearch } from 'react-icons/md';
import { BsTelephoneInboundFill } from 'react-icons/bs';

const UserDashboard = () => {

	const axiosPrivate = useAxiosPrivate();
	const [loading, setLoading] = useState(false);

	const [messages, setMessages] = useState('');
	const [favorites, setFavorites] = useState('');
	const [reviews, setReviews] = useState('');
	const [jobs, setJobs] = useState('');

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getDashboardOverview = async () => {
			try {
				const response = await axiosPrivate.get('user-dashboard-overview', {
					signal: controller.signal
				});
				setFavorites(response.data.favorites);
				setMessages(response.data.messages);
				setReviews(response.data.reviews);
				setJobs(response.data.jobs);
				setLoading(false)
			} catch(err) {
				console.log(err.message);
				{/* !auth && navigate('/login', { state: { from: location }, replace: true }); */}
			}
		}

		getDashboardOverview();

		return () => controller.abort();
		// eslint-disable-next-line
	}, [])

	return (
		<>
		{loading ? <Loader />
		:
		<div className="overview">
			<div className="dashboardItemWrapper">
				<DashboardItem title="Unread Messages" icon={<MdMessage />} count={messages.unread} link="/user/messages" />
				<DashboardItem title="Projects Created" icon={<MdWork />} count={jobs.count} link="/user/projects" />
				<DashboardItem title="Search" icon={<MdSearch />} link="/" />
			</div>

			<div className="dashboardItemWrapper stats">
				<h3>Stats</h3>
				<DashboardItem title="Favorite Contractors" icon={<MdFavorite />} count={favorites.count} link="/user/favorite-contractors" />
				<DashboardItem title="Approved Reviews" icon={<MdFactCheck />} count={reviews.approved} link="/user/reviews" />
				<DashboardItem title="Pending Reviews" icon={<MdPendingActions />} count={reviews.pending} link="/user/reviews" />
				<DashboardItem title="Reviews in Review" icon={<MdManageSearch />} count={reviews.review} link="/user/reviews" />
			</div>

		</div>
		}
		</>
	)
}

export default UserDashboard