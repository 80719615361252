const RegisterThankYou = ({ userType }) => {
    if (userType == '2002') {

        return (
            <section>
                <article className="inner">
                    <h1 className="case">Thank You for Contacting Improve Your House</h1>
                    <p>
                        We have successfully received your inquiry. Someone from the Improve
                        Your House team will review your request and be in contact with you.
                    </p>
                </article>
            </section>
        );
    } else if (userType == '1001'){
        return (
            <section>
                <article className="inner">
                    <h1 className="case">Thank You for Contacting Improve Your House</h1>
                    <p>
                        A verification email has been sent. Once you have verified your email address, you will be able to access your Homeowner dashboard that will allow you to create and submit projects,
                        save and favorite Home Pros and more! If you don’t see a verification email from us within a few minutes, please check your spam or junk email folder.
                    </p>
                </article>
            </section>
        );
    }
};

export default RegisterThankYou;
