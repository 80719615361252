import { Button, Link } from "@mui/material";
import * as React from "react";
import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BsArrowRightShort } from "react-icons/bs";

const ImageCopy = ({ imagePos, image, headline, copy, ctaButton, subhead }) => {
  const align =
    imagePos === "left" ? "left" : imagePos === "right" ? "right" : "right";
  const ImgColJSX = imagePos ? (
    <Col
      xs={{
        span: 12,
        order: imagePos === "left" ? 1 : 2,
      }}
      md={{ span: 6, order: imagePos === "left" ? 1 : 2 }}
      lg={{ span: 6, order: imagePos === "left" ? 1 : 2 }}
      className="img-col"
    >
      {image && <img src={image} style={{ width: "100%" }} />}
    </Col>
  ) : null;

  const CopyColJSX = (
    <Col
      xs={imagePos === "left" ? { span: 12, order: 2 } : { span: 12, order: 1 }}
      md={
        image && imagePos === "left"
          ? { span: 6, order: 2 }
          : image && imagePos === "right"
          ? { span: 6, order: 1 }
          : 12
      }
      lg={
        image && imagePos === "left"
          ? { span: 6, order: 2 }
          : image && imagePos === "right"
          ? { span: 6, order: 1 }
          : 11
      }
      className="copy-col"
    >
      <div>
        <h2
          style={{
            width: "100%",
            display: "block",
          }}
        >
          {headline}
        </h2>
        {subhead && (
          <h4
            style={{
              width: "100%",
              display: "block",
              marginBottom: "0 !important",
            }}
          >
            {subhead}
          </h4>
        )}
        <p>{copy}</p>
        {ctaButton?.to && ctaButton?.to !== "" ? (
          <Button variant="contained">
            <Link href={ctaButton.to}>
              {ctaButton.linkText} <BsArrowRightShort />
            </Link>
          </Button>
        ) : null}
      </div>
    </Col>
  );
  return (
    <Container>
      <Row style={{ padding: "2rem 0" }}>
        {ImgColJSX}
        {CopyColJSX}
      </Row>
    </Container>
  );
};

export default ImageCopy;
