import { useState, useEffect } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import { AiFillDelete } from 'react-icons/ai';
import { AiOutlineShareAlt } from 'react-icons/ai';
import { MdOutlineEdit } from 'react-icons/md';
import { MdVisibility } from 'react-icons/md';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import Loader from './Loader';
import CreateMarket from "./CreateMarket"
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';

const Tickets = () => {

	const [loading, setLoading] = useState(false);
	const [tickets, setTickets] = useState();
	const [filteredTickets, setFilteredTickets] = useState();
	const axiosPrivate = useAxiosPrivate();

	const navigate = useNavigate();
	const location = useLocation();

	const auth = useAuth();
	const role = auth?.role;
	const id = auth?.id;

	const [status, setStatus] = useState()

	const [subject, setSubject] = useState();
	const [description, setDescription] = useState();
	const [open, setOpen] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [openView, setOpenView] = useState(false);

	const [deleteID, setDeleteID] = useState()
	const [deleteName, setDeleteName] = useState()

	const [viewSubject, setViewSubject] = useState()
	const [viewDescription, setViewDescription] = useState()
	const [viewType, setViewType] = useState()
	const [viewStatus, setViewStatus] = useState()
	const [viewUser, setViewUser] = useState()
	const [viewID, setViewID] = useState()
	const [viewPhone, setViewPhone] = useState()
	const [viewEmail, setViewEmail] = useState()
	const [viewSlug, setViewSlug] = useState()
	const [viewUsersID, setViewUsersID] = useState()

	const [editID, setEditID] = useState()
	const [editName, setEditName] = useState()
	const [editDescription, setEditDescription] = useState()

	const [filterSearch, setFilterSearch] = useState("")
	const [filterStatus, setFilterStatus] = useState()
	const [filterServiceType, setFilterServiceType] = useState()

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleEdit = (edit) => {
		setOpenEdit(true);
	};

	const handleCloseEdit = () => {
		setOpenEdit(false);
	};

	const handleView = () => {
		setOpenView(true);
	};
	
	const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
	const onSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        const sortedData = [...filteredTickets].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setFilteredTickets(sortedData);
        setSortConfig({ key, direction });
    };

	const handleCloseView = async () => {
		// Mark Read on close if status is unread
		if (viewStatus === "unread"){
			const controller = new AbortController();
			try {
				const response = await axiosPrivate.put('ticket/' + viewID,
				JSON.stringify({status: 'read'}),
				{
					signal: controller.signal
				});

				// Close Alert Window
				setOpenView(false);

				// Update ticket state
				const index = tickets.map(function(x) {return x.id; }).indexOf(viewID);
				tickets[index].status = 'read';

			} catch (err) {
				if (!err?.response) {
					{/* setErrMsg('No Server Response'); */}
				} else {
					{/* setErrMsg('Registration Failed'); */}
				}
				{/* errRef.current.focus(); */}
			}
			return () => controller.abort();
		} else {
			// Close Alert Window
			setOpenView(false);
		}
	};

	const handleMarkUnread = async () => {
		// Mark unread on close if status is read
		if (viewStatus === "unread"){
			// Close Alert Window
			setOpenView(false);
		} else {
			const controller = new AbortController();
			try {
				const response = await axiosPrivate.put('ticket/' + viewID,
				JSON.stringify({status: 'unread'}),
				{
					signal: controller.signal
				});

				// Close Alert Window
				setOpenView(false);

				// Update ticket state
				const index = tickets.map(function(x) {return x.id; }).indexOf(viewID);
				tickets[index].status = 'unread';

			} catch (err) {
				if (!err?.response) {
					{/* setErrMsg('No Server Response'); */}
				} else {
					{/* setErrMsg('Registration Failed'); */}
				}
				{/* errRef.current.focus(); */}
			}
			return () => controller.abort();
		}
	};

	const handleDelete = async () => {
		const dismiss = () =>  toast.dismiss(deleteMarketToast.current);
		const deleteMarketToast = toast.loading("Deleting Ticket");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.delete('ticket/' + deleteID,
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(deleteMarketToast, { render: 'Ticket Deleted', type: 'success', isLoading: false, autoClose: 5000});

			// Close Alert Window
			setOpen(false);

			// Delete job from UI
			const filtered = tickets.filter((ticket) => ticket.id !== deleteID)
			setFilteredTickets(filtered)
			setTickets(filtered)


		} catch (err) {
			if (!err?.response) {
				toast.update(deleteMarketToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(deleteMarketToast, { render: 'Ticket Delete Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	};

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getMarkets = async () => {
			try {
				const response = await axiosPrivate.get('tickets', {
					signal: controller.signal
				});
				console.log(response.data);
				setTickets(response.data);
				setFilteredTickets(response.data);
				setLoading(false)
			} catch(err) {
				console.log(err.message);
				!auth && navigate('/login', { state: { from: location }, replace: true });
			}
		}

		getMarkets();

		return () => controller.abort();

	},[])

	const deleteTicket = (e, i, id, subject) => {
		setOpen(true);
		setDeleteName(subject);
		setDeleteID(id);
	}

	const viewTicket = (e, i, ticket) => {
		setOpenView(true);
		setViewID(ticket?.id)
		setViewSubject(ticket?.subject)
		setViewDescription(ticket?.description)
		setViewType(ticket?.service_type)
		setViewStatus(ticket?.status)
		setViewUser(ticket?.users_name)
		setViewPhone(ticket?.phone)
		setViewEmail(ticket?.email)
		setViewSlug(ticket?.slug)
		setViewUsersID(ticket?.users_id)
	}

	const editTicket = (e, i, id, subject, description) => {
		setOpenEdit(true);
		setEditDescription(description)
		setEditName(subject)
		setEditID(id)
	}

	const formatDate = (dateString) => {
	  const options = { month: "long", day: "numeric", year: "numeric" }
	  return new Date(dateString).toLocaleDateString(undefined, options)
	}

	useEffect(() => {
		if (filterSearch === ""){
			setFilteredTickets(tickets)
		} else {
			const resultsArray = tickets?.filter(ticket => {
				return (
					ticket.users_name.toLowerCase().includes(filterSearch.toLowerCase()) ||
					ticket.users_id == filterSearch
				)
			})

			setFilteredTickets(resultsArray)
		}
	}, [filterSearch])

	// useEffect(() => {
	// 	if (filterServiceType === "all" && filterStatus === "all"){
	// 		setFilteredTickets(tickets)
	// 	} else if (filterServiceType === "all") {
	// 		console.log("filtering status")
	// 		console.log(filterStatus)
	// 		const resultsArray = tickets?.filter(ticket => ticket.status === filterStatus)
	// 		setFilteredTickets(resultsArray)
	// 	} else if (filterStatus === "all") {
	// 		console.log("filtering service type")
	// 		console.log(filterServiceType)
	// 		const resultsArray = tickets?.filter(ticket => ticket.service_type.includes(filterServiceType))
	// 		setFilteredTickets(resultsArray)
	// 	} else {
	// 		console.log("filtering both status and service type")
	// 		console.log(filterStatus)
	// 		console.log(filterServiceType)
	// 		const resultsArray = tickets?.filter(ticket => {
	// 			ticket.status === filterStatus &&
	// 			ticket.service_type.includes(filterServiceType)
	// 		})
	// 		setFilteredTickets(resultsArray)
	// 	}
	// }, [filterServiceType, filterStatus])

	useEffect(() => {
		if (filterStatus === "all"){
			setFilteredTickets(tickets)
		} else {
			const resultsArray = tickets?.filter(ticket => ticket.status === filterStatus)
			setFilteredTickets(resultsArray)
		}
	}, [filterStatus])

	const handleViewProfile = () => {
		window.open(`/contractor/${viewSlug}`, "_blank")
	}

	const handleEditUser = () => {
		window.open(`/admin-dashboard/users?id=${viewUsersID}`, "_blank")
	}

	return (
		<div className="backendList">

			<div className="selectContainer">
				{/* <div className="selectWrapper">
					<Box>
						<FormControl fullWidth>
							<InputLabel id="filter-type-label">Filter Type</InputLabel>
							<Select
							  labelId="filter-type-label"
							  id="filterType"
							  onChange={(e) => setFilterServiceType(e.target.value)}
							  value={filterServiceType}
							>
								<MenuItem value="all">View All</MenuItem>
								<MenuItem value="Profile">Profile</MenuItem>
								<MenuItem value="Billing">Billing</MenuItem>
								<MenuItem value="Promotions">Promotions</MenuItem>
								<MenuItem value="Cancel Account">Cancel Account</MenuItem>
							</Select>
						  </FormControl>
					</Box>
				</div> */}
				<div className="selectWrapper">
					<Box>
						<FormControl fullWidth>
							<InputLabel id="filter-status-label">Filter Status</InputLabel>
							<Select
							  labelId="filter-status-label"
							  id="filterStatus"
							  onChange={(e) => setFilterStatus(e.target.value)}
							  value={filterStatus}
							>
								<MenuItem value="all">View All</MenuItem>
								<MenuItem value="read">Read</MenuItem>
								<MenuItem value="unread">Unread</MenuItem>

							</Select>
						  </FormControl>
					</Box>
				</div>
				{/* <div className="selectWrapper">
					<Box>
						<FormControl fullWidth>
							<TextField id="outlined-basic" label="Search" variant="outlined" onChange={(e) => setFilterSearch(e.target.value)} />
						</FormControl>
					</Box>
				</div> */}
			</div>

			{filteredTickets?.length
				? (
					<>
						<ul className="ticketList admin">
							<li className="key">
								<div className="user" onClick={() => onSort('users_name')}>From</div>
								<div className="subject" onClick={() => onSort('subject')}>Subject</div>
								<div className="type" onClick={() => onSort('service_type')}>Type</div>
								<div className="statusContainer" onClick={() => onSort('status')}>Status</div>
								<div className="date" onClick={() => onSort('created_at')}>Date Created</div>
								<div className="buttons"></div>
							</li>
							{filteredTickets.map((ticket, i) =>
								<li key={i}>
									<div className="user">
										{ticket?.users_name}
										{ticket?.business && <p>{ticket?.business}</p>}
									</div>
									<div className="subject">
										{ticket?.subject}
									</div>
									<div className="type">
										{ticket?.service_type}
									</div>
									<div className="statusContainer">
										<div className={`status ${ticket?.status}`}>
											{ticket?.status}
										</div>
									</div>
									<div className="date">
										{formatDate(ticket?.created_at)}
									</div>
									<div className="buttons">
										<div className="view" onClick={(e) => viewTicket(e, i,  ticket)}>
											<span><MdVisibility /></span>
										</div>
										<div className="delete" onClick={(e) => deleteTicket(e, i, ticket?.id, ticket?.subject)}>
											<span><AiFillDelete /></span>
										</div>
									</div>
								</li>
							)}
						</ul>
						{/* Delete Dialog */}
						<Dialog
							open={open}
							onClose={handleClose}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						  >
							<DialogTitle id="alert-dialog-title">
							  {"Are you sure you want to delete this ticket?"}
							</DialogTitle>
							<DialogContent>
							  <DialogContentText id="alert-dialog-description">
								This action cannot be undone. This will permanently delete the ticket with subject, "{deleteName}".
							  </DialogContentText>
							</DialogContent>
							<DialogActions>
							  <button className="cancel" onClick={handleClose}>Cancel</button>
							  <button className="confirm" onClick={handleDelete}>Yes, delete ticket</button>
							</DialogActions>
						  </Dialog>

						{/* Edit Dialog */}
						<Dialog
							open={openEdit}
							onClose={handleCloseEdit}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						  >
							<DialogTitle id="alert-dialog-title">
							  {`Editing ${editName} Market`}
							</DialogTitle>
							<DialogContent>
							  <DialogContentText id="alert-dialog-description">
								{"Editing markets coming soon!"}
							  </DialogContentText>
							</DialogContent>
							<DialogActions>
							  <button className="cancel" onClick={handleCloseEdit}>Cancel</button>
							  {/* <button className="confirm" onClick={handleEdit}>Yes, update review</button> */}
							</DialogActions>
						  </Dialog>

						{/* View Dialog */}
						<Dialog
							open={openView}
							onClose={handleCloseView}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
							className="viewTicketPopup"
						  >
							<DialogTitle id="alert-dialog-title">
							  {`${viewSubject} Ticket for ${viewUser}`}
							</DialogTitle>
							<DialogContent>
								<>
									<p><strong>From: </strong>{viewUser}</p>
									<p><strong>Users Phone: </strong>{viewPhone}</p>
									<p><strong>Users Email: </strong><a href={`mailto:${viewEmail}`}>{viewEmail}</a></p>
									<p><strong>User ID: </strong>{viewID}</p>
									<p><strong>Subject: </strong>{viewSubject}</p>
									<p><strong>Support Type: </strong>{viewType}</p>
									<p><strong>Status: </strong><span className={`status ${viewStatus}`}>{viewStatus}</span></p>
									<p><strong>Details: </strong>{viewDescription}</p>
								</>
							</DialogContent>
							<DialogActions className='ticket-action-buttons'>
							  <button className="cancel" onClick={handleCloseView}>Close</button>
							  <button className="cancel" onClick={handleMarkUnread}>Mark Unread</button>
							  {viewSlug ? <button className="confirm" onClick={handleViewProfile}>View Profile</button> : <></>}
							  <button className="confirm" onClick={handleEditUser}>Edit User</button>
							</DialogActions>
						  </Dialog>
					</>
				) :
					loading ?
					<Loader />
					:
					<p>No tickets to display</p>
			}
			<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
			/>
		</div>
	)
}

export default Tickets