import { useEffect, useState } from 'react';
import useAuth from "../hooks/useAuth";
import LegalTextEditor from "./LegalTextEditor";
import { Markup } from 'interweave';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Loader from './Loader';
import SingleFileDrop from './SingleFileDrop';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import PageFileDrop from "./PageFileDrop"
import SearchBox from "./SearchBox"


const LegalPageEdit = ({ slug, title }) => {

	const axiosPrivate = useAxiosPrivate();
	const [loading, setLoading] = useState(false);

	const { auth, editing, setEditing } = useAuth()
	const [page, setPage] = useState()
	const [pageID, setPageID] = useState()
	const [files, setFiles] = useState([])

	const [pageImages, setPageImages] = useState([])
	const [selectedImage, setSelectedImage] = useState()
	const [uploadOpen, setUploadOpen] = useState(false)

	const handleUpload = () => {
		setUploadOpen(true)
	};

	const handleUploadClose = () => {
		setUploadOpen(false)
	};

	const handleUploadImage = () => {
		setUploadOpen(false)
	};

	const getPage = async () => {
		const controller = new AbortController();
		setLoading(true);
		try {
			const response = await axiosPrivate.get('page-by-slug/' + slug, {
				signal: controller.signal
			});
			setPage(response.data.page);
			setPageImages(response?.data.images)
			setLoading(false)
		} catch(err) {
			console.log(err.message);
			{/* !auth && navigate('/login', { state: { from: location }, replace: true }); */}
		}
		return () => controller.abort();
	}

	useEffect(() => {
		getPage();
		// eslint-disable-next-line
	},[])


	return (
		<LegalTextEditor content={page?.content} id={page?.id} />
	)
}

export default LegalPageEdit