import { useEffect } from "react";
import usePageTitle from '../../hooks/usePageTitle';
import UserPageNav from '../../components/UserPageNav';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import UserJobs from '../../components/UserJobs';
import SharedJobs from '../../components/SharedJobs';
import useAuth from '../../hooks/useAuth';
import { AiOutlineDashboard } from 'react-icons/ai'
import { CgProfile } from 'react-icons/cg'
import { MdAccountBox } from 'react-icons/md'
import { FaUserCircle } from 'react-icons/fa'
import { GiStarsStack } from 'react-icons/gi'
import { AiOutlineBarChart } from 'react-icons/ai'
import { HiChartSquareBar } from 'react-icons/hi'
import { ImBullhorn } from 'react-icons/im';
import { MdDashboard } from 'react-icons/md';
import { RiCustomerService2Line } from 'react-icons/ri';
import { MdHelpCenter } from 'react-icons/md';
import { AiFillMessage } from 'react-icons/ai';
import { MdMessage } from 'react-icons/md';
import { MdFavorite } from 'react-icons/md';
import { MdFactCheck } from 'react-icons/md';
import { MdRequestPage } from 'react-icons/md';
import { MdSettings } from 'react-icons/md';
import { MdWork } from 'react-icons/md';
import { MdHandyman } from 'react-icons/md';

const Jobs = () => {

	usePageTitle("Manage Projects");
	const axiosPrivate = useAxiosPrivate();
	const { auth } = useAuth();

	return (
		<section>
			<article className='inner'>
				<div className="backendTitleBar">
					<h1>{auth?.user?.role == 2002 ? 'Shared' : ''} Projects</h1>
					<MdHandyman />
				</div>
				<div className="backendColumnWrapper">
					<div id="menu" className="column">
						<UserPageNav />
					</div>
					<div id="content" className="column">
						{auth?.user?.role == 2002 && <SharedJobs />}
						{auth?.user?.role == 1001 && <UserJobs />}
					</div>
				</div>
			</article>
		</section>
	)
}

export default Jobs