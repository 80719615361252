import { useState, useEffect, useRef } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { FiSettings } from 'react-icons/fi';
import useAuth from '../hooks/useAuth';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DashboardItem from './DashboardItem';


const ContractorPackages = (props) => {

	const {auth, setAuth} = useAuth();
	const role = auth?.roles;
	const userID = auth?.auth?.user?.id;
	const subscription = auth?.subscription
	const activeSubscription = subscription?.subscription_status === "active" ? true : false
	const activeSubscriptionID = subscription?.package_id
	const [loading, setLoading] = useState(false);
	const [activePackages, setActivePackages] = useState();
	const axiosPrivate = useAxiosPrivate();

	const [isCanceled, setIsCanceled] = useState(false)

	const navigate = useNavigate();
	const location = useLocation();

	const [isNewSubscription, setIsNewSubscription] = useState(location?.state?.newSub | false)

	const [openSubscriptionChange, setOpenSubscriptionChange] = useState(false);
	const [openSubscriptionCancel, setOpenSubscriptionCancel] = useState(false);
	const [subChangeID, setSubChangeID] = useState()
	const [subChangeName, setSubChangeName] = useState()

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getActivePackages = async () => {
			try {
				const response = await axiosPrivate.get('contractor-packages', {
					signal: controller.signal
				});
				setActivePackages(response.data);
				setLoading(false)
			} catch(err) {
				console.log(err.message);
			}
		}

		getActivePackages();

		// If new subscription scroll to top of page
		if (isNewSubscription){
			window.scrollTo({
			  top: 0,
			  behavior: 'smooth' // for smoothly scrolling
			});
		}

		return () => controller.abort();
		// eslint-disable-next-line
	},[])

	const formatCurrency = (q) => {
		var num = +q
		return '$' + num.toFixed(2)
	}

	const handleSubscription = (name, id) => {
		console.log('subscribing to ' + name)
		if ( id !== activeSubscriptionID){
			setSubChangeID(id)
			setSubChangeName(name)
			activeSubscription ? setOpenSubscriptionChange(true) : handlePackage(name, id)
		}

		if (isCanceled === true){
			setSubChangeID(id)
			setSubChangeName(name)
			handlePackage(name, id)
		}
	}

	const handleCloseSubscriptionChange = () => {
		setOpenSubscriptionChange(false)
	}

	const handleCloseSubscriptionCancel = () => {
		setOpenSubscriptionCancel(false)
	}

	const cancelSubscription = () => {
		setOpenSubscriptionCancel(true)
	}

	const handlePackage = async (name, id) => {
		setOpenSubscriptionChange(false)
		const dismiss = () =>  toast.dismiss(addSubscriptionToast.current);
		const addSubscriptionToast = toast.loading("Subscription Updating");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.post('subscription',
			JSON.stringify({subscription_status: 'chosen', package_id: id}),
			{
				signal: controller.signal
			});

			// Update auth state to include selected package
			setAuth({...auth, subscription: response.data.subscription });

			// Update Toast Notification
			toast.update(addSubscriptionToast, { render: 'Subscription Selected', type: 'success', isLoading: false, autoClose: 5000});
		} catch (err) {
			if (!err?.response) {
				toast.update(addSubscriptionToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else if (err.response?.status === 401) {
				toast.update(addSubscriptionToast, { render: 'Authorization Failed', type: 'error', isLoading: false});
				{/* setErrMsg('The email has already been taken.'); */}
			} else {
				toast.update(addSubscriptionToast, { render: 'Subscription Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();

	}

	const handleCancelSubscription = async () => {
		const dismiss = () =>  toast.dismiss(cancelSubscriptionToast.current);
		const cancelSubscriptionToast = toast.loading("Canceling Subscription");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.put('cancel-subscription',
			JSON.stringify({subscription_status: 'canceled'}),
			{
				signal: controller.signal
			});
			setAuth({...auth, subscription: response.data.subscription });
			setIsCanceled(true)

			setOpenSubscriptionCancel(false)
			setIsNewSubscription(false)

			// Update Toast Notification
			if (response?.data?.anet_response?.status === "success"){
				toast.update(cancelSubscriptionToast, { render: 'Subscription Canceled', type: 'success', isLoading: false, autoClose: 5000});
			} else {
				toast.update(cancelSubscriptionToast, { render: `${response?.data?.anet_response?.message}`, type: 'error', isLoading: false, autoClose: 5000});
			}

		} catch (err) {
			if (!err?.response) {
				toast.update(cancelSubscriptionToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else if (err.response?.status === 401) {
				toast.update(cancelSubscriptionToast, { render: 'Authorization Failed', type: 'error', isLoading: false});
				{/* setErrMsg('The email has already been taken.'); */}
			} else {
				toast.update(cancelSubscriptionToast, { render: 'Cancel Subscription Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	return (
		<>
			{ isNewSubscription ?
				<div className="dashboardItemWrapper">
					<DashboardItem id="account" className="active" title="New Subscription Activated!"></DashboardItem>
				</div>
				:
				null
			}
			{activePackages?.length ?
			(
				<>
					<ul className="availablePackages">
						{activePackages?.map((pkg, i) =>
							<li key={i} className={subscription?.package_id == pkg.id && (subscription?.subscription_status == "active" || subscription?.subscription_status == "chosen") && `active`} onClick={(e) => handleSubscription(pkg.package_name, pkg.id)} >
								<div className="price">
									<h3>{formatCurrency(pkg.package_price)}</h3>
								</div>
								<div className="name">
									<h4>{pkg.package_name}</h4>
								</div>
								<div className="description">
									<p>{pkg.package_description}</p>
								</div>
								{subscription?.package_id == pkg.id && subscription?.subscription_status == "active" &&
									<div className="configureActivePackage active">
										<p>Active</p>
										{/* <p>Active <Link to="/user/account/configure"><FiSettings /></Link></p> */}
									</div>
								}
								{subscription?.package_id == pkg.id && subscription?.subscription_status == "chosen" &&
									<Link to="/user/account/configure">
										<div className="configureActivePackage">
											<p>Selected (Click To Continue) <FiSettings /></p>
										</div>
									</Link>
								}
							</li>
						)}
						<li className="cancel">
							<div onClick={cancelSubscription}>Cancel Subscription</div>
						</li>
					</ul>
				</>
			) : (
				<p>No active packages availble</p>
			)}

			{/* Confirm Subscription Change */}
			<Dialog
				open={openSubscriptionChange}
				onClose={handleCloseSubscriptionChange}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			  >
				<DialogTitle id="alert-dialog-title">
				  {"Are you sure you want to change your subscription?"}
				</DialogTitle>
				<DialogContent>
				  <DialogContentText id="alert-dialog-description">
					You currently have an active subscription, changing your subscription package will require you to configure the new one before you show up in homeowner searches. Are you sure you want to change your subscription?
				  </DialogContentText>
				</DialogContent>
				<DialogActions>
				  <button className="cancel" onClick={handleCloseSubscriptionChange}>Cancel</button>
				  <button className="confirm" onClick={(e) => handlePackage(subChangeName, subChangeID)}>Yes, change subscription package</button>
				</DialogActions>
			  </Dialog>

			{/* Confirm Subscription Cancel */}
			<Dialog
				open={openSubscriptionCancel}
				onClose={handleCloseSubscriptionCancel}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			  >
				<DialogTitle id="alert-dialog-title">
				  {"Are you sure you want to cancel your subscription?"}
				</DialogTitle>
				<DialogContent>
				  <DialogContentText id="alert-dialog-description">
					Are you sure you want to cancel your subscription? Please refer to the <a href="https://legal.improveyourhouse.com/advertising-agreement" target="_blank">IYH Advertising Agreement</a> for current cancelation policy.
				  </DialogContentText>
				</DialogContent>
				<DialogActions>
				  <button className="cancel" onClick={handleCloseSubscriptionCancel}>Cancel</button>
				  <button className="confirm" onClick={(e) => handleCancelSubscription()}>Yes, cancel subscription</button>
				</DialogActions>
			  </Dialog>

			<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
			/>
		</>
	)
}

export default ContractorPackages