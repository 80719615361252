import { useEffect, useState, useRef } from 'react';
import { useLocation, NavLink, useParams, Link, useOutletContext, useNavigate } from "react-router-dom";
import usePageTitle from '../hooks/usePageTitle';
import useAxiosFunction from "../hooks/useAxiosFunction";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { FaCheck } from 'react-icons/fa';
import { FaExclamation } from 'react-icons/fa';
import { MdReportProblem } from 'react-icons/md';

const BUSINESS_REGEX = /[^\s]{1,100}$/;

const ContractorNameSearch = () => {

	const location = useLocation();
	const navigate = useNavigate()

	const { auth } = useAuth();
	const userType = auth?.user?.role;
	const userID = auth?.user?.id;
	const userName = `${auth?.user?.first_name} ${auth?.user?.last_name}`;

	const axiosPrivate = useAxiosPrivate()

	const businessNameRef = useRef();

	const [businessName, setBusinessName] = useState('');
	const [validBusinessName, setValidBusinessName] = useState(false);
	const [businessNameFocus, setBusinessNameFocus] = useState(false);

	/* Check Business Name */
	useEffect(() => {
		const result = BUSINESS_REGEX.test(businessName);
		setValidBusinessName(result);
	}, [businessName])

	const contractorSearchByName = async (e) => {
		e.preventDefault()

		navigate("/search-contractor-name-results", { state: {name: businessName}, replace: false })
	}

	return (
		<form onSubmit={contractorSearchByName}>
			<label htmlFor="business">Business Name:</label>
			<div className="inputWrapper">
				<span className={validBusinessName ? "valid" : "hide"}><FaCheck /></span>
				<span className={validBusinessName || !businessName ? "hide" : "invalid"}><FaExclamation /></span>
				<input
					type="text"
					id="business"
					className={validBusinessName || !businessName ? null : "error"}
					ref={businessNameRef}
					autoComplete="off"
					onChange={(e) => setBusinessName(e.target.value)}
					value={businessName}
					required
					aria-invalid={validBusinessName ? "false" : "true"}
					aria-describedby="urlnote"
					onFocus={() => setBusinessNameFocus(true)}
					onBlur={() => setBusinessNameFocus(false)}
				/>
			</div>
			<p id="urlnote" className={businessNameFocus && businessName && !businessName ? "instructions" : "offscreen"}>
				Invalid Business Name.
			</p>
			<button>Search</button>
		</form>
	)
}

export default ContractorNameSearch