import { createContext, useState, useEffect } from "react"
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const AdminFormContext = createContext({})

const title = {
	0: 'Select State',
	1: 'Select Counties',
	2: 'Exclude Zipcodes',
	3: 'Service Categories',
	4: 'Profile Add-ons',
	5: 'Subscription Overview',
	6: 'Payment'
}

export const AdminFormProvider = ({ children }) => {

	const [loading, setLoading] = useState(false)
	const [config, setConfig] = useState()
	const axiosPrivate = useAxiosPrivate()
	const [exitAdminForm, setExitAdminForm] = useState()

	const [page, setPage] = useState(0)
	const [userState, setUserState] = useState()
	const [userZip, setUserZip] = useState()
	const auth = useAuth()

	// useEffect(() => {
	// 	console.log("exitAdminForm", exitAdminForm)
	// }, [userState])
	// useEffect(() => {
	// 	console.log("AdminFormContext user zip", userZip)
	// }, [userZip])

	const profileState = auth?.auth?.user?.state
	const profileZip = auth?.auth?.user?.zip

	const [stateNotice, setStateNotice] = useState(false)

	const [canSubmit, setCanSubmit] = useState(false)

	const [data, setData] = useState({
		states: "",
		active_state: userState ? userState : profileState,
		active_zip: userZip ? userZip : profileZip,
		counties: "",
		excludes: "",
		categories: "",
		payment: "",
		selected_counties: "",
		excluded_zips: [],
		selected_services: "",
		active_county: [],
		monthly_charges: "",
		one_time_charges: "",
		selected_options: []
	})

	const [billingData, setBillingData] = useState({
		subscriber_first_name: "",
		subscriber_last_name: "",
		subscription_name: "",
		subscriber_company: "",
		subscriber_billing_address: "",
		subscriber_billing_city: "",
		subscriber_billing_state: "",
		subscriber_billing_zip: "",
		subscriber_cc: "",
		subscriber_sc: "",
		subscriber_cc_expiration: "",
		subscriber_coupon: "",
		subscription_full_amount: "",
		subscription_trial_amount: "",
		subscription_trial_months: "",
		subscription_initial_payment: "",
		skip_initial_payment: ""
	})
	useEffect(() => {
		// console.log("userState and run set data", userState)
		setData({...data, active_zip: userZip, active_state: userState})
	},[userState, userZip])
	// useEffect(() => {
	// 	setData({...data, active_zip: userZip})
	// },[userZip])
	// useEffect(() => {
	// 	console.log("data change is going", data)
	// },[data])

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getConfig = async () => {
			try {
				const response = await axiosPrivate.get('subscription-config', {
					signal: controller.signal
				});
				// console.log("response", response)
				let categories = response.data.service_categories
				let counties = response.data.active_state_counties
				let excludes = response.data.zip_codes
				let states = response.data.active_states
				let options = response.data.options
				let zipObject = excludes.filter(zip => { return zip.zipcodes === profileZip})
				let profileCounty = zipObject.county
				setConfig(response.data)
				setData({states: states, counties: counties, categories: categories, excludes: excludes, active_state: userState ? userState : profileState, active_zip: profileZip, active_county: profileCounty, options: options})
				setLoading(false)
			} catch(err) {
				console.log(err.message);
				{/* !auth && navigate('/login', { state: { from: location }, replace: true }); */}
			}
		}

		getConfig();

		return () => controller.abort();
		// eslint-disable-next-line
	},[])

	const updateCharges = (charges, onetime) => {
		// console.log("updating charges")
		setData(prevData => ({
			...prevData,
			monthly_charges: charges,
			one_time_charges: onetime
		}))
	}

	const updateCounties = (categories) => {
		// console.log("updating categories")
		setData(prevData => ({
			...prevData,
			selected_counties: categories
		}))
	}

	const updateExcludes = (zips) => {
		// console.log("updating excludes")
		setData(prevData => ({
			...prevData,
			excluded_zips: zips
		}))
	}

	const updateServices = (services) => {
		// console.log("updating services")
		setData(prevData => ({
			...prevData,
			selected_services: services
		}))
	}

	const updateOptions = (options) => {
		// console.log("updating options")
		setData(prevData => ({
			...prevData,
			selected_options: options
		}))
		// console.log(options)
	}

	const handleChange = (e) => {
		const idx = e.target.selectedIndex;
		const option = e.target.querySelectorAll('option')[idx];
		const dataActiveState = option.getAttribute('data-active-state');

		if (dataActiveState == "disabled"){
			setStateNotice(true)
		} else {
			setStateNotice(false)
		}

		const type = e.target.type

		const name = e.target.name

		const value = type === "checkbox"
			? e.target.checked
			: e.target.value

		setData(prevData => ({
			...prevData,
			[name]: value
		}))
	}

	// let canSubmit = false
// 	if (page === Object.keys(title).length - 1) {
// 		const {...requiredInputs } = data
//
// 		canSubmit = [...Object.values(requiredInputs)].every(Boolean)
// 	}


	const disablePrev = page === 0

	const disableNext = page === Object.keys(title).length - 1 || page === 1 && data?.selected_counties?.length < 1 || page === 3 && data?.selected_services?.length < 1

	const prevHide = page === 0 && "remove-button"

	const nextHide = page === Object.keys(title).length - 1 && "remove-button"

	const submitHide = page !== Object.keys(title).length - 1 && "remove-button"

	return (
		<AdminFormContext.Provider value={{ title, page, setPage, data, setData, canSubmit, setCanSubmit, handleChange, disablePrev, disableNext, prevHide, nextHide, submitHide, updateCounties, updateExcludes, updateServices, updateCharges, updateOptions, billingData, setBillingData, setUserState, setUserZip, exitAdminForm, setExitAdminForm }}>
			{children}
		</AdminFormContext.Provider>
	)
}

export default AdminFormContext