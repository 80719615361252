import { Link } from 'react-router-dom';
import { RiFacebookCircleLine } from 'react-icons/ri';
import { RiInstagramLine } from 'react-icons/ri';

const LegalFooter = () => {

	const getCurrentYear = () => {
		return new Date().getFullYear();
	}

	return (
		<footer>
			<div className="sub__footer">
				<article>
					<p id='copy'>©2018-{getCurrentYear()} <Link to="/">GDHI Marketing LLC</Link></p>
					<p id='credit'><a href="https://tandemdesignlab.com" target="_blank" rel="noreferrer">Tandem Design Lab</a>, Colorado Web Design</p>
				</article>
			</div>
		</footer>
	)
}

export default LegalFooter