import usePageTitle from '../../hooks/usePageTitle';
import LegalPage from '../../components/LegalPage';

const Privacy = () => {
	usePageTitle("Privacy Policy");

	return (
		<LegalPage title='Privacy Policy' slug='privacy-policy' />
	)
}

export default Privacy