import { useState, useEffect } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import { AiFillDelete } from 'react-icons/ai';
import { AiOutlineShareAlt } from 'react-icons/ai';
import { GrFormEdit } from 'react-icons/gr';
import { GrFormView } from 'react-icons/gr';
import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import Loader from './Loader';
import CreateMarket from "./CreateMarket"

const States = () => {

	const [loading, setLoading] = useState(false);
	const [states, setStates] = useState();
	const axiosPrivate = useAxiosPrivate();

	const navigate = useNavigate();
	const location = useLocation();

	const auth = useAuth();
	const role = auth?.role;
	const id = auth?.id;

	const handleChange = async (position, updateID, status) => {

		// Get new status
		let newStatus = ""
		if ( status == "active"){
			newStatus = "disabled"
		} else {
			newStatus = "active"
		}

		const dismiss = () =>  toast.dismiss(updateStateStatusToast.current);
		const updateStateStatusToast = toast.loading("Updating Status");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.put('state/' + updateID,
			JSON.stringify({status: newStatus}),
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(updateStateStatusToast, { render: 'Status Updated', type: 'success', isLoading: false, autoClose: 5000});

			// Create copy and assign the new value at index
			let temp_states = [...states];
			let temp_element = { ...temp_states[position] };
			temp_element.status = newStatus
			temp_states[position] = temp_element;

			// Update State
			setStates( temp_states );

		} catch (err) {
			if (!err?.response) {
				toast.update(updateStateStatusToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(updateStateStatusToast, { render: 'Status Update Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	};

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getStates = async () => {
			try {
				const response = await axiosPrivate.get('states', {
					signal: controller.signal
				});
				console.log(response.data);
				setStates(response.data);
				setLoading(false)
			} catch(err) {
				console.log(err.message);
				!auth && navigate('/login', { state: { from: location }, replace: true });
			}
		}

		getStates();

		return () => controller.abort();

	},[])


	return (
		<div className="backendList">
			{states?.length
				? (
					<>
						<ul className="statesList">
							{states.map((state, index) =>
								<li key={index}>
									<div className="switch">
										<Switch
											checked={state.status == "active" ? true : false}
											onChange={() => handleChange(index, state?.id, state?.status)}
										/>
									</div>
									<div className="state">
										{state?.state}
									</div>
									<div className={`status ${state?.status}`}>
										{state?.status ? state?.status : "disabled"}
									</div>
								</li>
							)}
						</ul>

					</>
				) :
					loading ?
					<Loader />
					:
					<p>No states to display</p>
			}
			<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
			/>
		</div>
	)
}

export default States