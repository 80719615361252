import { useRef, useState, useEffect } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import { AiFillDelete } from 'react-icons/ai';
import { AiOutlineShareAlt } from 'react-icons/ai';
import { MdOutlineEdit } from 'react-icons/md';
import { MdVisibility } from 'react-icons/md';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import Loader from './Loader';
import Rating from '@mui/material/Rating';


const UserReviews = () => {

	const [loading, setLoading] = useState(false);
	const [reviews, setReviews] = useState();
	const axiosPrivate = useAxiosPrivate();

	const navigate = useNavigate();
	const location = useLocation();

	const auth = useAuth();
	const role = auth?.role;
	const id = auth?.id;

	const [name, setName] = useState();
	const [reviewText, setReviewText] = useState();
	const [commentText, setCommentText] = useState();
	const [title, setTitle] = useState();
	const [rating, setRating] = useState();
	const [deleteName, setDeleteName] = useState();
	const [deleteID, setDeleteID] = useState();
	const [editID, setEditID] = useState();
	const [open, setOpen] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [openView, setOpenView] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleEdit = (edit) => {
		setOpenEdit(true);
	};

	const handleCloseEdit = () => {
		setOpenEdit(false);
	};

	const handleView = () => {
		setOpenView(true);
	};

	const handleCloseView = () => {
		setOpenView(false);
	};

	const handleUpdateReview = async () => {
		const dismiss = () =>  toast.dismiss(editReviewToast.current);
		const editReviewToast = toast.loading("Updating Review");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.put('review/' + editID,
			JSON.stringify({review_text: reviewText, rating: rating, review_title: title, status: 'pending', private_homeower_comment: commentText}),
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(editReviewToast, { render: 'Review Updated', type: 'success', isLoading: false, autoClose: 5000});

			// Update State
			const index = reviews.map(function(x) {return x.id; }).indexOf(editID);
			reviews[index].status = 'pending';
			reviews[index].review_text = reviewText
			reviews[index].rating = rating
			reviews[index].review_title = title
			reviews[index].private_homeower_comment = commentText

			// Close Alert Window
			setOpenEdit(false);

		} catch (err) {
			if (!err?.response) {
				toast.update(editReviewToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(editReviewToast, { render: 'Review Update Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	const handleDelete = async () => {
		const dismiss = () =>  toast.dismiss(deleteReviewToast.current);
		const deleteReviewToast = toast.loading("Deleting Review");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.delete('review/' + deleteID,
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(deleteReviewToast, { render: 'Review Deleted', type: 'success', isLoading: false, autoClose: 5000});

			// Close Alert Window
			setOpen(false);

			// Delete job from UI
			setReviews(reviews.filter((review) => review.id !== deleteID))

		} catch (err) {
			if (!err?.response) {
				toast.update(deleteReviewToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(deleteReviewToast, { render: 'Review Delete Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	};

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getReviews = async () => {
			try {
				const response = await axiosPrivate.get('user-reviews', {
					signal: controller.signal
				});
				console.log(response.data);
				setReviews(response.data);
				setLoading(false)
			} catch(err) {
				console.log(err.message);
				{/* !auth && navigate('/login', { state: { from: location }, replace: true }); */}
			}
		}

		getReviews();

		return () => controller.abort();

	},[])

	const deleteReview = (e, i, name, id) => {
		setOpen(true);
		setDeleteName(name);
		setDeleteID(id);
	}

	const viewReview = (e, i, name, id, review, rating) => {
		setOpenView(true);
		setName(name)
		setReviewText(review)
		setRating(rating)
	}

	const editReview = (e, i, name, id, review, rating, title) => {
		setOpenEdit(true);
		setName(name)
		setReviewText(review)
		setRating(rating)
		setTitle(title)
		setEditID(id)
	}

	const addReview = (review) => {
		console.log(review);
		setReviews([...reviews, review]);
	}


	const formatDate = (dateString) => {
	  const options = { month: "long", day: "numeric", year: "numeric" }
	  return new Date(dateString).toLocaleDateString(undefined, options)
	}

	function convertToSlug(Text)
	{
		return Text
			.toLowerCase()
			.replace(/ /g,'-')
			.replace(/[^\w-]+/g,'')
			;
	}

	function contractorURL(name){
		return "/contractor/" + convertToSlug(name);
	}

	return (
		<div className="backendList">
			{reviews?.length
				? (
					<>
					<ul className="reviewList">
						<li className="key">
							<div className="name">Contractor</div>
							<div className="statusContainer">Status</div>
							<div className="date">Date of Review</div>
							<div className="buttons"></div>
						</li>
						{reviews.map((review, i) =>
							<li key={i}>
								<div className="name">
									<Link to={contractorURL(review?.contractor_name)}>{review?.contractor_name}</Link>
								</div>
								<div className="statusContainer">
									<div className={`status ${review?.status}`}>
										{review?.status}
									</div>
								</div>
								<div className="date">
									{formatDate(review?.created_at)}
								</div>
								<div className="buttons">
									<div className="edit" onClick={(e) => editReview(e, i, review?.contractor_name, review?.id, review?.review_text, review?.rating, review?.review_title)}>
										<span><MdOutlineEdit /></span>
									</div>
									<div className="view" onClick={(e) => viewReview(e, i, review?.contractor_name, review?.id, review?.review_text, review?.rating)}>
										<span><MdVisibility /></span>
									</div>
									<div className="delete" onClick={(e) => deleteReview(e, i, review?.contractor_name, review?.id)}>
										<span><AiFillDelete /></span>
									</div>
								</div>
							</li>
						)}
					</ul>

					{/* Delete Dialog */}
					<Dialog
						open={open}
						onClose={handleClose}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					  >
						<DialogTitle id="alert-dialog-title">
						  {"Are you sure you want to delete this review?"}
						</DialogTitle>
						<DialogContent>
						  <DialogContentText id="alert-dialog-description">
							{`This action cannot be undone. This will permanently delete the review with name, ${deleteName}`}.
						  </DialogContentText>
						</DialogContent>
						<DialogActions>
						  <button className="cancel" onClick={handleClose}>Cancel</button>
						  <button className="confirm" onClick={handleDelete}>Yes, delete review</button>
						</DialogActions>
					  </Dialog>

					{/* Edit Dialog */}
					<Dialog
						open={openEdit}
						onClose={handleCloseEdit}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					  >
						<DialogTitle id="alert-dialog-title">
						  {`Editing Review of ${name}`}
						</DialogTitle>
						<DialogContent>
						  <DialogContentText id="alert-dialog-description">
							<form encType="multipart/form-data" >
								{/* Rating */}
								<label htmlFor="rating">Rating:</label>
								<Rating
									onChange={(event, newValue) => {
									  setRating(newValue);
									}}
									defaultValue={rating}
								/>

								{/* Title */}
								<label htmlFor="title">Review Title:</label>
								<div className="inputWrapper">
									<input
										type="text"
										id="title"
										autoComplete="off"
										onChange={(e) => setTitle(e.target.value)}
										value={title}
									/>
								</div>

								{/* Review */}
								<label htmlFor="review">Review:</label>
								<div className="inputWrapper">
									<textarea
										onChange={(e) => setReviewText(e.target.value)}
										name="review"
										id="review"
										rows="5"
										value={reviewText}
									>
									</textarea>
								</div>

								{/* Private Comment */}
								{ rating < 3 &&
									<>
										<label htmlFor="comment">Private Admin Comment:</label>
										<span className="commentInstructions">Poor ratings are often sent for review by the contractor. Please provide detailed information of your experience for our review process. If you don't fill this out and the contractor explains their side the review may not post.</span>
										<div className="inputWrapper">
											<textarea
												onChange={(e) => setCommentText(e.target.value)}
												name="comment"
												id="comment"
												rows="5"
												value={commentText}
											>
											</textarea>
										</div>
									</>
								}
								<p>Submitting an update to your review will remove it from public view and be reviewed by an admin.</p>
							</form>
						  </DialogContentText>
						</DialogContent>
						<DialogActions>
						  <button className="cancel" onClick={handleCloseEdit}>Cancel</button>
						  <button className="confirm" onClick={handleUpdateReview}>Yes, update review</button>
						</DialogActions>
					  </Dialog>

					{/* View Dialog */}
					<Dialog
						open={openView}
						onClose={handleCloseView}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					  >
						<DialogTitle id="alert-dialog-title">
						  {`Your review of ${name}`}
						</DialogTitle>
						<DialogContent>
						  <DialogContentText id="alert-dialog-description">
							<>
							<Rating name="size-medium" defaultValue={rating} readOnly />
							<p>{reviewText}</p>
							</>
						  </DialogContentText>
						</DialogContent>
						<DialogActions>
						  <button className="cancel" onClick={handleCloseView}>Close</button>
						</DialogActions>
					  </Dialog>
		</>
				) :
					loading ?
					<Loader />
					:
					<p>You have not reviewed any contractors yet.<br />To review a contractor go to the contractors profile and click review.</p>
			}
		</div>
	)
}

export default UserReviews