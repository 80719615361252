import { useState, useEffect, useRef } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import { AiFillDelete } from 'react-icons/ai';
import { AiOutlineShareAlt } from 'react-icons/ai';
import { MdVisibility } from 'react-icons/md';
import { FaCheck } from 'react-icons/fa';
import { FaExclamation } from 'react-icons/fa';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ToastContainer, toast } from 'react-toastify';
import CreateUserJob from "./CreateUserJob";
import Loader from './Loader';
import FileDropEmbed from './FileDropEmbed';
import SendMessage from './SendMessage';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import ImageGallery from 'react-image-gallery';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { MdClose } from 'react-icons/md';


const NAME_REGEX = /^[a-zA-Z0-9-_&@ ]{1,50}$/;
const DESCRIPTION_REGEX = /^[a-zA-Z0-9-_.,& ]{1,500}$/;

const SharedJobs = () => {

	const [loading, setLoading] = useState(false);
	const [jobs, setJobs] = useState();
	const [filteredJobs, setFilteredJobs] = useState();
	const axiosPrivate = useAxiosPrivate();

	const navigate = useNavigate();
	const location = useLocation();

	const auth = useAuth();
	const role = auth?.role;
	const id = auth?.id;
	const contractorName = auth?.business_name;

	const [deleteName, setDeleteName] = useState();
	const [deleteID, setDeleteID] = useState();

	const [viewName, setViewName] = useState();
	const [viewHomeowner, setViewHomeowner] = useState();
	const [viewHomeownerID, setViewHomeownerID] = useState();
	const [viewContractorID, setViewContractorID] = useState();
	const [viewID, setViewID] = useState();
	const [viewShareID, setViewShareID] = useState();
	const [viewDescription, setViewDescription] = useState();
	const [viewImages, setViewImages] = useState([]);
	const [viewStatus, setViewStatus] = useState()
	const [viewAddress, setViewAddress] = useState()
	const [viewCategory, setViewCategory] = useState()

	const [open, setOpen] = useState(false);
	const [openView, setOpenView] = useState(false);
	const [openReplyView, setOpenReplyView] = useState(false);

	const [message, setMessage] = useState("");
	const [validMessage, setValidMessage] = useState(false);
	const [validShare, setValidShare] = useState(false);

	const [updating, setUpdating] = useState(false);

	const [filterProjects, setFilterProjects] = useState("all")
	const [filterSearch, setFilterSearch] = useState("")

	const [openLightbox, setOpenLightbox] = useState(false)

	const [sharedImages, setSharedImages] = useState([])

	useEffect(() => {
		// Build Gallery Array
		let images = [];
		viewImages?.map((item, i) =>
			  images.push({
				original: `https://storage.iyh.app/${item.path}`,
				thumbnail: `https://storage.iyh.app/${item.path}`
			  })
		)
		setSharedImages(images)
	}, [viewImages])

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleClickOpenView = () => {
		setOpenView(true);
	};

	const handleCloseView = (id) => {
		setOpenView(false);
		setUpdating(false)

		const updateNewStatusToViewed = async () => {
			const controller = new AbortController();
			try {
				const response = await axiosPrivate.put('update-shared-job-status/' + id,
				JSON.stringify({status: 'viewed'}),
				{
					signal: controller.signal
				});

				// Update State in UI
				const index = jobs.map(function(x) {return x.shared_id; }).indexOf(id);
				jobs[index].status = 'viewed';

				setUpdating(true)

			} catch (err) {
				if (!err?.response) {
					console.log('No Server Response');
					console.log(err);
					{/* setErrMsg('No Server Response'); */}
				} else {
					console.log('Project Status Update Failed');
					{/* setErrMsg('Registration Failed'); */}
				}
				{/* errRef.current.focus(); */}
			}
			return () => controller.abort();
		}

		if (viewStatus === 'new' && openView === true){
			updateNewStatusToViewed()
		}
	};

	const handleCloseReply = () => {
		setOpenReplyView(false);
	};

	const handleReply = () => {
		setOpenView(false);
		setOpenReplyView(true);
	};

	const viewFullSizedImage = () => {
		setOpenLightbox(true)

		console.log("view full sized image")
	}

	useEffect(() => {
		if (filterProjects === "all"){
			const resultsArray = jobs?.filter(job => !job?.status?.includes('archived'))
			setFilteredJobs(resultsArray)
		} else {
			const resultsArray = jobs?.filter(job => job?.status?.includes(filterProjects))
			setFilteredJobs(resultsArray)
		}
	}, [filterProjects, jobs])

	useEffect(() => {
		if (filterSearch === ""){
			setFilteredJobs(jobs)
		} else {
			const resultsArray = jobs?.filter(job => {
				return (
					job.name.toLowerCase().includes(filterSearch.toLowerCase()) ||
					job.homeowner_name.toLowerCase().includes(filterSearch.toLowerCase())
					// ||
					// job.service_category.toLowerCase().includes(filterSearch.toLowerCase())
				)
			})

			setFilteredJobs(resultsArray)
		}
	}, [filterSearch, jobs])

	const viewJob = (e, i, name, id, description, images, homeownerName, homeownerID, contractorID, status, address, sharedID, category) => {
		setOpenView(true);
		setViewName(name);
		setViewID(id)
		setViewDescription(description)
		setViewImages(images)
		setViewHomeowner(homeownerName)
		setViewHomeownerID(homeownerID)
		setViewContractorID(contractorID)
		setViewStatus(status)
		setViewAddress(address)
		setViewShareID(sharedID)
		setViewCategory(category)
	}

	// Send Reply
	const handleSendMessage = async (e) => {
		const controller = new AbortController();
		const dismiss = () =>  toast.dismiss(sendMessageToast.current);
		const sendMessageToast = toast.loading("Sending Message");
		const type = viewStatus === 'new' ? 'project-reply' : ''
		try {
			const response = await axiosPrivate.post('message',
			JSON.stringify({receiver_id: viewHomeownerID, receiver_name: viewHomeowner, sender_id: id, sender_name: contractorName, message: message, type: type, project_id: viewShareID}),
			{
				signal: controller.signal
			});
			setOpenReplyView(false);

			// Update status state
			if (viewStatus === 'new'){
				const index = jobs.map(function(x) {return x.shared_id; }).indexOf(viewShareID);
				filteredJobs[index].status = 'replied';
			}

			// Update Toast Notification
			toast.update(sendMessageToast, { render: 'Message Sent', type: 'success', isLoading: false, autoClose: 5000});
		} catch(err) {
			if (!err?.response) {
				toast.update(sendMessageToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else if (err.response?.status === 401) {
				toast.update(sendMessageToast, { render: 'Authorization Failed', type: 'error', isLoading: false});
				{/* setErrMsg('The email has already been taken.'); */}
			} else {
				toast.update(sendMessageToast, { render: 'Message Send Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
		}

		return () => controller.abort();
	}

	// Delete Job
	const handleDelete = async () => {
		const dismiss = () =>  toast.dismiss(deleteProfileToast.current);
		const deleteProfileToast = toast.loading("Deleting Project");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.delete('job/' + deleteID,
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(deleteProfileToast, { render: 'Project Deleted', type: 'success', isLoading: false, autoClose: 5000});

			// Close Alert Window
			setOpen(false);

			// Delete job from UI
			setJobs(jobs.filter((job) => job.id !== deleteID))

		} catch (err) {
			if (!err?.response) {
				toast.update(deleteProfileToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(deleteProfileToast, { render: 'Project Delete Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	};

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getJobs = async () => {
			try {
				const response = await axiosPrivate.get('shared-jobs', {
					signal: controller.signal
				});
				console.log(response.data);
				setJobs(response.data.jobs);
				setFilterProjects(response.data);
				setLoading(false)
			} catch(err) {
				console.log(err.message);
				{/* !auth && navigate('/login', { state: { from: location }, replace: true }); */}
			}
		}

		getJobs();

		return () => controller.abort();

	// eslint-disable-next-line
	},[])


	const deleteJob = (e, i, name, id) => {
		setOpen(true);
		setDeleteName(name);
		setDeleteID(id);
	}

	const formatDate = (dateString) => {
		const options = { month: "long", day: "numeric", year: "numeric" }
		return new Date(dateString).toLocaleDateString(undefined, options)
	}

	const formatShortDate = (dateString) => {
		const options = { month: "numeric", day: "numeric", year: "numeric" }
		return new Date(dateString).toLocaleDateString(undefined, options)
	}

	const handleUpdateStatus = async () => {
		const dismiss = () =>  toast.dismiss(updateJobStatusToast.current);
		const updateJobStatusToast = toast.loading("Updating Project Status");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.put('update-shared-job-status/' + viewShareID,
			JSON.stringify({status: viewStatus}),
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(updateJobStatusToast, { render: 'Project Status Updated', type: 'success', isLoading: false, autoClose: 5000});

			// Update State in UI
			const index = jobs.map(function(x) {return x.shared_id; }).indexOf(viewShareID);
			jobs[index].status = viewStatus


			// Close Alert Window
			setOpenView(false)

		} catch (err) {
			if (!err?.response) {
				toast.update(updateJobStatusToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(updateJobStatusToast, { render: 'Project Status Update Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	const handleCloseLightbox = () => {
		setOpenLightbox(false)
	}

	return (
		<div className="backendList">
			<div className="selectContainer">
				<div className="selectWrapper">
					<Box>
						<FormControl fullWidth>
							<InputLabel id="filter-status-label">Filter Status</InputLabel>
							<Select
							  labelId="filter-status-label"
							  id="filterStatus"
							  onChange={(e) => setFilterProjects(e.target.value)}
							  value={filterProjects || "all"}
							>
								<MenuItem value="all">View All</MenuItem>
								<MenuItem value="new">New</MenuItem>
								<MenuItem value="viewed">Viewed</MenuItem>
								<MenuItem value="pending">Pending</MenuItem>
								<MenuItem value="replied">Replied</MenuItem>
								<MenuItem value="progress">Progress</MenuItem>
								<MenuItem value="complete">Complete</MenuItem>
								<MenuItem value="archived">Archived</MenuItem>
							</Select>
						  </FormControl>
					</Box>
				</div>
				<div className="selectWrapper">
					<Box>
						<FormControl fullWidth>
							<TextField id="outlined-basic" label="Search" variant="outlined" onClick={(e) => setFilterProjects("all")} onChange={(e) => setFilterSearch(e.target.value)} />
						</FormControl>
					</Box>
				</div>
			</div>
			{filteredJobs?.length
				? (
					<>
					{filterProjects === "all" && <p>View all does not contain archived projects.</p>}
					<ul className="jobsList shared">
						<li className="key">
							<div className="name">Project Name</div>
							<div className="client">Client</div>
							<div className="date">Date</div>
							<div className="statusContainer">Status</div>
							<div className="buttons"></div>
						</li>
						{filteredJobs.map((job, i) =>
							<li key={i}>
								<div className="name">
									{job?.name}
								</div>
								<div className="client">
									{job?.homeowner_name}
								</div>
								<div className="date">
									{formatShortDate(job?.created_at)}
								</div>
								<div className="statusContainer">
									<div className={`status ${job?.status}`}>{job?.status}</div>
								</div>
								<div className="buttons">
									<div className="view" onClick={(e) => viewJob(e, i, job?.name, job?.id, job?.description, job?.images, job?.homeowner_name, job?.user, job?.contractor_id, job?.status, job?.address, job?.shared_id, job?.service_category)}>
										<span><MdVisibility /></span>
									</div>
									{/* <div className="delete" onClick={(e) => deleteJob(e, i, job?.name, job?.id)}>
										<span><AiFillDelete /></span>
									</div> */}
								</div>
							</li>
						)}
					</ul>

					{/* DELETE JOB */}
					<Dialog
						open={open}
						onClose={handleClose}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					  >
						<DialogTitle id="alert-dialog-title">
						  {"Are you sure you want to delete this project?"}
						</DialogTitle>
						<DialogContent>
						  <DialogContentText id="alert-dialog-description">
							{`This action cannot be undone. This will permanently delete the project with name, ${deleteName}`}.
						  </DialogContentText>
						</DialogContent>
						<DialogActions>
						  <button className="cancel" onClick={handleClose}>Cancel</button>
						  <button className="confirm" onClick={handleDelete}>Yes, delete project</button>
						</DialogActions>
					</Dialog>

					{/* Reply */}
					<Dialog
						open={openReplyView}
						onClose={handleCloseReply}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					  >
						<DialogTitle id="alert-dialog-title">
						  {`Reply to ${viewHomeowner}`}
						</DialogTitle>
						<DialogContent>
						  <DialogContentText id="alert-dialog-description">
							<SendMessage message={message} setMessage={setMessage} validMessage={validMessage} setValidMessage={setValidMessage} />
						  </DialogContentText>
						</DialogContent>
						<DialogActions>
						  <button className="cancel" onClick={handleCloseReply}>Cancel</button>
						  <button className="confirm" onClick={handleSendMessage}>Send Message</button>
						</DialogActions>
					</Dialog>


					{/* View Job */}
					<Dialog
						open={openView}
						onClose={handleCloseView}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
						id="viewJobPopup"
					  >
						<DialogTitle id="alert-dialog-title">
						  {`Viewing project ${viewName}`}
						</DialogTitle>
						<DialogContent>
						  <DialogContentText id="alert-dialog-description">
								<div>
									<div className="status">
										{/* <h3>Status</h3> */}
										<div className="selectContainer">
											<div className="selectWrapper">
												<Box>
													<FormControl fullWidth>
														<InputLabel id="filter-status-label">Status</InputLabel>
														<Select
												  		labelId="filter-status-label"
												  		id="filterStatus"
												  		onChange={(e) => setViewStatus(e.target.value)}
												  		value={viewStatus || "all"}
														>
															<MenuItem value="all">View All</MenuItem>
															<MenuItem value="new">New</MenuItem>
															<MenuItem value="viewed">Viewed</MenuItem>
															<MenuItem value="pending">Pending</MenuItem>
															<MenuItem value="replied">Replied</MenuItem>
															<MenuItem value="progress">In Progress</MenuItem>
															<MenuItem value="complete">Complete</MenuItem>
															<MenuItem value="archived">Archived</MenuItem>
														</Select>
											  		</FormControl>
												</Box>
											</div>
										</div>
										{/* <select defaultValue={viewStatus} onChange={(e) => setViewStatus(e.target.value)}>
											<option value="new">New</option>
											<option value="viewed">Viewed</option>
											<option value="replied">Replied</option>
											<option value="pending">Pending</option>
											<option value="progress">In Progress</option>
											<option value="complete">Complete</option>
											<option value="archived">Archived</option>
										</select> */}
									</div>
									<div class="category">
										<h3>Service Category</h3>
										<span>{viewCategory ? viewCategory : "Not Provided"}</span>
									</div>
									<div className="address">
										<h3>Location</h3>
										<span>{viewAddress ? viewAddress : 'Not Provided'}</span>
									</div>
									<h3>Description</h3>
									<p>{viewDescription}</p>
									<ul className="jobImageList">
									{viewImages?.map((image, i) =>
										<li key={i} onClick={(e) => viewFullSizedImage(e, i, image?.id, viewImages)}>
											<img src={`https://storage.iyh.app/${image.path}`} alt="" />
										</li>
									)}
									</ul>
								</div>
						  </DialogContentText>
						</DialogContent>
						<DialogActions>
						  <button className="cancel" onClick={(e) => handleCloseView(viewShareID)}>Cancel</button>
						  <button className="confirm" onClick={handleUpdateStatus}>Update Status</button>
						  <button className="confirm" onClick={handleReply}>Reply</button>
						</DialogActions>
					</Dialog>

					{/* View Job Images */}
					<Dialog
						fullScreen
						open={openLightbox}
						onClose={handleCloseLightbox}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
						id="viewLightboxPopup"
					  >
					  <AppBar sx={{ position: 'relative' }}>
						<Toolbar>
						  <IconButton
							edge="start"
							color="inherit"
							onClick={handleCloseLightbox}
							aria-label="close"
						  >
							<MdClose />
						  </IconButton>
						  <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
						  	{`Viewing project ${viewName} images`}
						  </Typography>
						</Toolbar>
					  </AppBar>
						<DialogContent>
						  <DialogContentText id="alert-dialog-description">
								{sharedImages.length > 0 ?
									<ImageGallery items={sharedImages} />
									:
									<p className="centeredNote">No photos available.</p>
								}
						  </DialogContentText>
						</DialogContent>
					</Dialog>
		</>
				) :
					loading ?
					<Loader />
					:
					<>
						{jobs?.length && !filteredJobs?.length ?
						<p>No projects in this search.</p>
						:
						<p>No projects have been shared with you, yet.</p>
						}
					</>
			}
			<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
			/>
		</div>
	)
}

export default SharedJobs