import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';
import { FaExclamation } from 'react-icons/fa';
import { MdVisibilityOff } from 'react-icons/md';
import { MdVisibility } from 'react-icons/md';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useFormContext from "../hooks/useFormContext"
import useAuth from '../hooks/useAuth';
import PopupAgreement from '../components/PopupAgreement'

const SC_REGEX = /^\d{3,4}(?:[-\s]\d{4})?$/;
const CITY_REGEX = /^[a-zA-Z ]{2,50}$/;
const ZIPCODE_REGEX = /^\d{5}(?:[-\s]\d{4})?$/;
const NAME_REGEX = /^[a-zA-Z0-9 ]{2,50}$/;
const ADDRESS_REGEX = /^[a-zA-Z0-9 &#/-_,. ]{2,100}$/;
const EXPIRATION_REGEX = /^([2][0][2-3][1-9]) *\- *(?:0?[1-9]|1[0-2])$/
const EXPIRATION_YEAR_REGEX = /^([2][0][2-3][1-9])$/
const EXPIRATION_MONTH_REGEX = /^(?:0?[1-9]|1[0-2])$/

const PaymentForm = ({ monthlyCharges, onetimeCharge }) => {

	const axiosPrivate = useAxiosPrivate();
	const auth = useAuth();
	const subscription = auth?.auth?.subscription

	const { canSubmit, setCanSubmit } = useFormContext()

	const [formValid, setFormValid] = useState(false)

	const ccRef = useRef()
	const scRef = useRef()
	const monthRef = useRef()
	const yearRef = useRef()
	const zipRef = useRef()
	const cityRef = useRef()
	const fnameRef = useRef()
	const lnameRef = useRef()
	const addressRef = useRef()
	const couponRef = useRef()

	const [state, setState] = useState()
	const [stateFocus, setStateFocus] = useState(false)

	const [coupon, setCoupon] = useState()
	const [discount, setDiscount] = useState()
	const [couponFocus, setCouponFocus] = useState(false)
	const [validCoupon, setValidCoupon] = useState(false)
	const [couponError, setCouponError] = useState(false)

	const [total, setTotal] = useState()
	const [initialPayment, setInitialPayment] = useState()

	const [city, setCity] = useState()
	const [cityFocus, setCityFocus] = useState(false)
	const [validCity, setValidCity] = useState(false)

	const [fname, setFName] = useState()
	const [fnameFocus, setFNameFocus] = useState(false)
	const [validFName, setValidFName] = useState(false)

	const [lname, setLName] = useState()
	const [lnameFocus, setLNameFocus] = useState(false)
	const [validLName, setValidLName] = useState(false)

	const [address, setAddress] = useState()
	const [addressFocus, setAddressFocus] = useState(false)
	const [validAddress, setValidAddress] = useState(false)

	const [zip, setZip] = useState()
	const [validZip, setValidZip] = useState(false)
	const [zipFocus, setZipFocus] = useState(false)

	const [cc, setCC] = useState()
	const [validCC, setValidCC] = useState(false)
	const [ccFocus, setCCFocus] = useState(false)
	const [ccErrorMessage, setCCErrorMessage] = useState()

	const [sc, setSC] = useState()
	const [validSC, setValidSC] = useState(false)
	const [scFocus, setSCFocus] = useState(false)

	const [month, setMonth] = useState()
	const [validMonth, setValidMonth] = useState(false)
	const [monthFocus, setMonthFocus] = useState(false)

	const [year, setYear] = useState()
	const [validYear, setValidYear] = useState(false)
	const [yearFocus, setYearFocus] = useState(false)

	const [trialMonths, setTrialMonths] = useState()

	const {billingData, setBillingData} = useFormContext()

	const [viewCreditCard, setViewCreditCard] = useState(false)
	const [viewCode, setViewCode] = useState(false)

	const [specialInitialCharge, setSpecialInitialCharge] = useState()

	// useEffect(() => {
	// 	console.log(billingData)
	// }, [billingData])

	// Calculate discount
	useEffect(() => {
		if (validCoupon){
			if (discount.includes('%')){
				let percentage = Number(discount.replace('%', ''))
				let sub = (percentage * .01) * monthlyCharges
				setTotal((Number(monthlyCharges) - sub).toFixed(2))
				setInitialPayment((Number(monthlyCharges) - sub + Number(specialInitialCharge ? specialInitialCharge : onetimeCharge)).toFixed(2))
			} else {
				if (Number(discount) <= monthlyCharges){
					setTotal((Number(monthlyCharges) - Number(discount)).toFixed(2))
					setInitialPayment((Number(monthlyCharges) - Number(discount) + Number(specialInitialCharge ? specialInitialCharge : onetimeCharge)).toFixed(2))
				} else {
					setTotal(0.00)
					setInitialPayment((Number(monthlyCharges) + Number(specialInitialCharge ? specialInitialCharge : onetimeCharge)).toFixed(2))
				}
			}
		} else {
			setTotal(monthlyCharges)
			setInitialPayment((Number(monthlyCharges) + Number(onetimeCharge)).toFixed(2))
		}
	}, [monthlyCharges, discount, validCoupon, onetimeCharge])

	//Check for valid form
	useEffect(() => {
		validFName && validLName && validAddress && validCity && validZip && validCC && validSC && validMonth && validYear ? setCanSubmit(true) : setCanSubmit(false)
		const ex = year + '-' + month
		setBillingData({
				subscriber_first_name: fname,
				subscriber_last_name: lname,
				subscription_name: subscription?.package_details?.package_name,
				subscriber_company: auth?.auth?.user?.business_name,
				subscriber_billing_address: address,
				subscriber_billing_city: city,
				subscriber_billing_state: state,
				subscriber_billing_zip: zip,
				subscriber_cc: cc,
				subscriber_sc: sc,
				subscriber_cc_expiration: ex,
				subscriber_coupon: validCoupon ? coupon : 'na',
				subscription_full_amount: monthlyCharges,
				subscription_trial_amount: total,
				subscription_trial_months: trialMonths,
				subscription_initial_payment:  initialPayment
			})
	}, [validFName, validLName, validAddress, validCity, validZip, validCC, validSC, validMonth, validYear, validCoupon, total, trialMonths, auth, month, year])

	// Check credit Card
	useEffect(() => {
		const check = checkCreditCard(cc)
		setValidCC(check?.success)
		setCCErrorMessage(check?.message)
		console.log(check)
	}, [cc])

	// Check security code
	useEffect(() => {
		const result = SC_REGEX.test(sc);
		setValidSC(result);
	}, [sc])

	// Check expiration date
	useEffect(() => {
		const result = EXPIRATION_MONTH_REGEX.test(month);
		setValidMonth(result);
	}, [month])

	// Check expiration date
	useEffect(() => {
		const result = EXPIRATION_YEAR_REGEX.test(year);
		setValidYear(result);
	}, [year])

	/* Check City */
	useEffect(() => {
		const result = CITY_REGEX.test(city);
		setValidCity(result);
	}, [city])

	/* Check Zip */
	useEffect(() => {
		const result = ZIPCODE_REGEX.test(zip);
		setValidZip(result);
	}, [zip])

	/* Check First Name */
	useEffect(() => {
		const result = NAME_REGEX.test(fname);
		setValidFName(result);
	}, [fname])

	/* Check Last Name */
	useEffect(() => {
		const result = NAME_REGEX.test(lname);
		setValidLName(result);
	}, [lname])

	/* Check Address */
	useEffect(() => {
		const result = ADDRESS_REGEX.test(address);
		setValidAddress(result);
	}, [address])

	/* Check Coupon */
	useEffect(() => {
		setValidCoupon(false)
		setCouponError(false)
	}, [coupon])

	const checkCreditCard = cardnumber => {

	  //Error messages
	  const ccErrors = [];
	  ccErrors [0] = "Unknown card type";
	  ccErrors [1] = "No card number provided";
	  ccErrors [2] = "Credit card number is in invalid format";
	  ccErrors [3] = "Credit card number is invalid";
	  ccErrors [4] = "Credit card number has an inappropriate number of digits";
	  ccErrors [5] = "Warning! This credit card number is associated with a scam attempt";

	  //Response format
	  const response = (success, message = null, type = null) => ({
		message,
		success,
		type
	  });

	  // Define the cards we support. You may add additional card types as follows.

	  //  Name:         As in the selection box of the form - must be same as user's
	  //  Length:       List of possible valid lengths of the card number for the card
	  //  prefixes:     List of possible prefixes for the card
	  //  checkdigit:   Boolean to say whether there is a check digit
	  const cards = [];
	  cards [0] = {name: "Visa",
				   length: "13,16",
				   prefixes: "4",
				   checkdigit: true};
	  cards [1] = {name: "MasterCard",
				   length: "16",
				   prefixes: "51,52,53,54,55",
				   checkdigit: true};
	  cards [2] = {name: "DinersClub",
				   length: "14,16",
				   prefixes: "36,38,54,55",
				   checkdigit: true};
	  cards [3] = {name: "AmEx",
				   length: "15",
				   prefixes: "34,37",
				   checkdigit: true};
	  cards [4] = {name: "Discover",
				   length: "16",
				   prefixes: "6011,622,64,65",
				   checkdigit: true};
	  cards [5] = {name: "JCB",
				   length: "16",
				   prefixes: "35",
				   checkdigit: true};


	  // Ensure that the user has provided a credit card number
	  if (cardnumber?.length == 0)  {
		 return response(false, ccErrors[1]);
	  }

	  // Now remove any spaces from the credit card number
	  // Update this if there are any other special characters like -
	  cardnumber = cardnumber?.replace (/\s/g, "");

	  // Validate the format of the credit card
	  // luhn's algorithm
	  // if(!validateCardNumber(cardnumber)){
		// return response(false, ccErrors[2]);
	  // }

	  // Check it's not a spam number
	  if (cardnumber == '5490997771092064') {
		return response(false, ccErrors[5]);
	  }

	  // The following are the card-specific checks we undertake.
	  let lengthValid = false;
	  let prefixValid = false;
	  let cardCompany = "";

	  // Check if card belongs to any organization
	  for(let i = 0; i < cards.length; i++){
		const prefix = cards[i].prefixes.split(",");

		for (let j = 0; j < prefix.length; j++) {
		  const exp = new RegExp ("^" + prefix[j]);
		  if (exp.test (cardnumber)) {
			prefixValid = true;
		  }
		}

		if(prefixValid){
		  const lengths = cards[i].length.split(",");
		  // Now see if its of valid length;
		  for (let j=0; j < lengths.length; j++) {
			if (cardnumber.length == lengths[j]) {
			  lengthValid = true;
			}
		  }
		}

		if(lengthValid && prefixValid){
		  cardCompany = cards[i].name;
		  return response(true, null, cardCompany);
		}
	  }

	  // If it isn't a valid prefix there's no point at looking at the length
	  if (!prefixValid) {
		 return response(false, ccErrors[3]);
	  }

	  // See if all is OK by seeing if the length was valid
	  if (!lengthValid) {
		 return response(false, ccErrors[4]);
	  };

	  // The credit card is in the required format.
	  return response(true, null, cardCompany);
	}

	const handleViewCreditCard = () => {
		setViewCreditCard(!viewCreditCard)
	}

	const handleViewCode = () => {
		setViewCode(!viewCode)
	}

	const ignoreClick = async (e) => {
		e.preventDefault();
	}
	const validateCoupon = async (e) => {
		e.preventDefault();

		if (coupon === "DHS24") {
			setSpecialInitialCharge(1)
		} else {
			setSpecialInitialCharge(null)
		}

		const controller = new AbortController();
		try {
			const response = await axiosPrivate.post('validate-coupon',
			JSON.stringify({coupon_code: coupon}),
			{
				signal: controller.signal
			});

			setValidCoupon(response?.data?.coupon_active)
			setCouponError(response?.data?.coupon_active ? false : true)
			setDiscount(response?.data?.coupon_active ? response?.data?.coupon_discount : null)
			setTrialMonths(response?.data?.coupon_active ? response?.data?.applies_to_months : null)

		} catch (err) {
			if (!err?.response) {
				console.log('no server response')
				{/* setErrMsg('No Server Response'); */}
			} else {
				console.log('Market Update Failed')
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	return (
		<>
			<h3>Billing Information</h3>
			<div className="nameColumnWrapper">
				<div className="nameColumn">
					{/* Billing First Name */}
					<label htmlFor="name">First Name:</label>
					<div className="inputWrapper">
						<span className={validFName ? "valid" : "hide"}><FaCheck /></span>
						<span className={validFName || !fname ? "hide" : "invalid"}><FaExclamation /></span>
						<input
							type="text"
							id="fname"
							className={validFName || !fname ? null : "error"}
							ref={fnameRef}
							autoComplete="off"
							onChange={(e) => setFName(e.target.value)}
							value={fname}
							required
							aria-invalid={validFName ? "false" : "true"}
							aria-describedby="fnamenote"
							onFocus={() => setFNameFocus(true)}
							onBlur={() => setFNameFocus(false)}
						/>
					</div>
					<p id="fnamenote" className={fnameFocus && !validFName ? "instructions" : "offscreen"}>
						Invalid First Name. Must be at least 2 characters long and containly only letters or numbers.
					</p>
				</div>

				<div className="nameColumn">
					{/* Billing Last Name */}
					<label htmlFor="name">Last Name:</label>
					<div className="inputWrapper">
						<span className={validLName ? "valid" : "hide"}><FaCheck /></span>
						<span className={validLName || !lname ? "hide" : "invalid"}><FaExclamation /></span>
						<input
							type="text"
							id="fname"
							className={validLName || !lname ? null : "error"}
							ref={lnameRef}
							autoComplete="off"
							onChange={(e) => setLName(e.target.value)}
							value={lname}
							required
							aria-invalid={validLName ? "false" : "true"}
							aria-describedby="lnamenote"
							onFocus={() => setLNameFocus(true)}
							onBlur={() => setLNameFocus(false)}
						/>
					</div>
					<p id="lnamenote" className={lnameFocus && !validLName ? "instructions" : "offscreen"}>
						Invalid Last Name. Must be at least 2 characters long and containly only letters or numbers.
					</p>
				</div>
			</div>

			{/* Billing Address */}
			<label htmlFor="name">Address:</label>
			<div className="inputWrapper">
				<span className={validAddress ? "valid" : "hide"}><FaCheck /></span>
				<span className={validAddress || !address ? "hide" : "invalid"}><FaExclamation /></span>
				<input
					type="text"
					id="city"
					className={validAddress || !address ? null : "error"}
					ref={addressRef}
					autoComplete="off"
					onChange={(e) => setAddress(e.target.value)}
					value={address}
					required
					aria-invalid={validAddress ? "false" : "true"}
					aria-describedby="addressnote"
					onFocus={() => setAddressFocus(true)}
					onBlur={() => setAddressFocus(false)}
				/>
			</div>
			<p id="addressnote" className={addressFocus && address && !validAddress ? "instructions" : "offscreen"}>
				Invalid Address. Must be between 2-100 characters long. Can contain letters, numbers and these special characters (&#/-_,.)
			</p>

			<div className="billingColumnWrapper">
				<div className="billingColumn">
					{/* Billing City */}
					<label htmlFor="name">City:</label>
					<div className="inputWrapper">
						<span className={validCity ? "valid" : "hide"}><FaCheck /></span>
						<span className={validCity || !city ? "hide" : "invalid"}><FaExclamation /></span>
						<input
							type="text"
							id="city"
							className={validCity || !city ? null : "error"}
							ref={cityRef}
							autoComplete="off"
							onChange={(e) => setCity(e.target.value)}
							value={city}
							required
							aria-invalid={validCity ? "false" : "true"}
							aria-describedby="citynote"
							onFocus={() => setCityFocus(true)}
							onBlur={() => setCityFocus(false)}
						/>
					</div>
					<p id="citynote" className={cityFocus && city && !validCity ? "instructions" : "offscreen"}>
						Invalid City.
					</p>
				</div>

				<div className="billingColumn">
					{/* State */}
					<label htmlFor="state">State:</label>
					<div className="inputWrapper">
						<select
							id="state"
							onChange={(e) => setState(e.target.value)}
							value={state}
							required
							onFocus={() => setStateFocus(true)}
							onBlur={() => setStateFocus(false)}
						>
							<option value="">Select State</option>
							<option value="AL">Alabama</option>
							<option value="AK">Alaska</option>
							<option value="AZ">Arizona</option>
							<option value="AR">Arkansas</option>
							<option value="CA">California</option>
							<option value="CO">Colorado</option>
							<option value="CT">Connecticut</option>
							<option value="DE">Delaware</option>
							<option value="DC">District Of Columbia</option>
							<option value="FL">Florida</option>
							<option value="GA">Georgia</option>
							<option value="HI">Hawaii</option>
							<option value="ID">Idaho</option>
							<option value="IL">Illinois</option>
							<option value="IN">Indiana</option>
							<option value="IA">Iowa</option>
							<option value="KS">Kansas</option>
							<option value="KY">Kentucky</option>
							<option value="LA">Louisiana</option>
							<option value="ME">Maine</option>
							<option value="MD">Maryland</option>
							<option value="MA">Massachusetts</option>
							<option value="MI">Michigan</option>
							<option value="MN">Minnesota</option>
							<option value="MS">Mississippi</option>
							<option value="MO">Missouri</option>
							<option value="MT">Montana</option>
							<option value="NE">Nebraska</option>
							<option value="NV">Nevada</option>
							<option value="NH">New Hampshire</option>
							<option value="NJ">New Jersey</option>
							<option value="NM">New Mexico</option>
							<option value="NY">New York</option>
							<option value="NC">North Carolina</option>
							<option value="ND">North Dakota</option>
							<option value="OH">Ohio</option>
							<option value="OK">Oklahoma</option>
							<option value="OR">Oregon</option>
							<option value="PA">Pennsylvania</option>
							<option value="RI">Rhode Island</option>
							<option value="SC">South Carolina</option>
							<option value="SD">South Dakota</option>
							<option value="TN">Tennessee</option>
							<option value="TX">Texas</option>
							<option value="UT">Utah</option>
							<option value="VT">Vermont</option>
							<option value="VA">Virginia</option>
							<option value="WA">Washington</option>
							<option value="WV">West Virginia</option>
							<option value="WI">Wisconsin</option>
							<option value="WY">Wyoming</option>

						</select>
					</div>
				</div>

				<div className="billingColumn">
					{/* Billing Zip */}
					<label htmlFor="name">Zipcode:</label>
					<div className="inputWrapper">
						<span className={validZip ? "valid" : "hide"}><FaCheck /></span>
						<span className={validZip || !zip ? "hide" : "invalid"}><FaExclamation /></span>
						<input
							type="tel"
							id="zip"
							className={validZip || !zip ? null : "error"}
							ref={zipRef}
							autoComplete="off"
							onChange={(e) => setZip(e.target.value)}
							value={zip}
							required
							aria-invalid={validZip ? "false" : "true"}
							aria-describedby="zipnote"
							onFocus={() => setZipFocus(true)}
							onBlur={() => setZipFocus(false)}
						/>
					</div>
					<p id="zipnote" className={zipFocus && zip && !validZip ? "instructions" : "offscreen"}>
						Invalid Zip. Valid Format includes 55555 or 55555-5555.
					</p>
				</div>
			</div>
			<hr/>
			<h3>Payment Information</h3>

			<div className="paymentColumnWrapper">

				<div id="cc">
					{/* Credit Card Number */}
					<label htmlFor="creditcardnumber">Creditcard Number: </label>
					<div className="inputWrapper">
						<span className={validCC ? "valid" : "hide"}><FaCheck /></span>
						<span className={validCC || !cc ? "hide" : "invalid"}><FaExclamation /></span>
						<span className="viewCC" onClick={handleViewCreditCard}>{viewCreditCard ? <MdVisibility /> : <MdVisibilityOff /> }</span>
						<input
							type={viewCreditCard ? "tel" : "password"}
							id="creditcard"
							className={validCC || !cc ? null : "error"}
							ref={ccRef}
							autoComplete="off"
							onChange={(e) => setCC(e.target.value)}
							value={cc}
							required
							aria-invalid={validCC ? "false" : "true"}
							aria-describedby="ccnote"
							onFocus={() => setCCFocus(true)}
							onBlur={() => setCCFocus(false)}
							placeholder="1234123412341234"
					/>
					</div>
					<p id="ccnote" className={ccFocus && cc && !validCC ? "instructions" : "offscreen"}>
						{ccErrorMessage}
					</p>

				</div>

				<div id="ex-month">
					{/* Credit Card Month Expiration */}
					<label htmlFor="name">Month:</label>
					<div className="inputWrapper">
						<span className={validMonth ? "valid" : "hide"}><FaCheck /></span>
						<span className={validMonth || !month ? "hide" : "invalid"}><FaExclamation /></span>
						<input
							type="text"
							id="expiration"
							className={validMonth || !month ? null : "error"}
							ref={monthRef}
							autoComplete="off"
							onChange={(e) => setMonth(e.target.value)}
							value={month}
							required
							aria-invalid={validMonth ? "false" : "true"}
							aria-describedby="exmonthnote"
							onFocus={() => setMonthFocus(true)}
							onBlur={() => setMonthFocus(false)}
							placeholder="01"
					/>
					</div>
					<p id="exmonthnote" className={monthFocus && month && !validMonth ? "instructions" : "offscreen"}>
						Expiration month must be formatted as MM, ie 01 to 12
					</p>

				</div>

				<div id="ex-year">
					{/* Credit Card Year Expiration */}
					<label htmlFor="name">Year:</label>
					<div className="inputWrapper">
						<span className={validYear ? "valid" : "hide"}><FaCheck /></span>
						<span className={validYear || !year ? "hide" : "invalid"}><FaExclamation /></span>
						<input
							type="text"
							id="expiration"
							className={validYear || !year ? null : "error"}
							ref={yearRef}
							autoComplete="off"
							onChange={(e) => setYear(e.target.value)}
							value={year}
							required
							aria-invalid={validYear ? "false" : "true"}
							aria-describedby="exyearnote"
							onFocus={() => setYearFocus(true)}
							onBlur={() => setYearFocus(false)}
							placeholder='2023'
					/>
					</div>
					<p id="exyearnote" className={yearFocus && year && !validYear ? "instructions" : "offscreen"}>
						Expiration year must be formatted as YYYY, ie 2023
					</p>

				</div>

				<div id="sc">
					{/* Security Code */}
					<label htmlFor="name">Security Code: </label>
					<div className="inputWrapper">
						<span className={validSC ? "valid" : "hide"}><FaCheck /></span>
						<span className={validSC || !sc ? "hide" : "invalid"}><FaExclamation /></span>
						<span className="viewCC" onClick={handleViewCode}>{viewCode ? <MdVisibility /> : <MdVisibilityOff /> }</span>
						<input
							type={viewCode ? "text" : "password"}
							id="creditcard"
							className={validSC || !sc ? null : "error"}
							ref={scRef}
							autoComplete="off"
							onChange={(e) => setSC(e.target.value)}
							value={sc}
							required
							aria-invalid={validSC ? "false" : "true"}
							aria-describedby="scnote"
							onFocus={() => setSCFocus(true)}
							onBlur={() => setSCFocus(false)}
							placeholder='CVC'
						/>
						</div>
						<p id="scnote" className={scFocus && cc && !validSC ? "instructions" : "offscreen"}>
							Invalid Security Code. Must be 3 or 4 digits.
						</p>
				</div>

			</div>

			<hr />

			<h3>Coupons</h3>
			<p>If you have a coupon code please enter it below and click apply.</p>
			<div className="couponColumnWrapper">
				<div id="coupon">
					<label htmlFor="name">Coupon Code:</label>
					<div className="inputWrapper">
						<input
							type="text"
							id="coupon"
							className={validCoupon || !coupon ? null : "error"}
							ref={couponRef}
							autoComplete="off"
							onChange={(e) => setCoupon(e.target.value)}
							value={coupon}
							aria-invalid={validCoupon ? "false" : "true"}
						/>
						<p>{couponError && 'Invalid Coupon Code'}</p>
					</div>
				</div>
				<div id="apply">
					<button className={validCoupon ? 'verified' : 'apply'} disabled={!coupon ? true : false} onClick={!validCoupon ? validateCoupon : ignoreClick}>{ validCoupon ? 'Applied' : 'Apply'}</button>
				</div>
			</div>

			<h3>Subscription Charges</h3>
			<div className="feeWrapper">
				<div className="fees">
					<div className="paymentDetails">
						<p>Monthly Charge: ${monthlyCharges}</p>
						{onetimeCharge ? <p>One-time Setup: ${specialInitialCharge ? specialInitialCharge : onetimeCharge}</p> : ''}
					</div>
					{ validCoupon ?
						<div className="couponDetails">
				   			<p>{`COUPON: ${coupon}`}</p>
							<p>DISCOUNT: {discount.includes('%') ? `${discount}` : `-$${discount}`}</p>
							{trialMonths ? <p>Discounted Montly Charge (first {trialMonths} months): ${total}</p> : ''}
						</div>
						:
						''
					}
				</div>

				<p className="initialPayment">
					<strong>Initial Payment: ${initialPayment}</strong><br />
					<em className="chargeNote">(first month + one-time charges)</em>
				</p>
			</div>

			<h3>Agreements (Required)</h3>
			<div className="proAgreements">
				<PopupAgreement title="Terms of Use" slug="terms-of-use" required="true" />
				<PopupAgreement title="Service Provider's User Agreement" slug="service-providers-user-agreement" required="true" />
				<PopupAgreement title="Advertising Agreement" slug="advertising-agreement" required="true" />
				<PopupAgreement title="Privacy Policy" slug="privacy-policy" required="true" />
			</div>

		</>
	)
}

export default PaymentForm