import { Button, Link } from '@mui/material'
import * as React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { BsArrowRightShort } from 'react-icons/bs'

const CtaBlock = ({header, subhead, link}) => {
  return <div className='cta-block-wrap'>
    <Container className='cta-block'>
    <Row>
      <Col>
      <h2>
        {header}
      </h2>
      <h4>
        {subhead}
      </h4>
      {link?.to && link?.to !== "" ? (
          <Button variant="contained">
            <Link href={link.to}>
              {link.linkText} <BsArrowRightShort />
            </Link>
          </Button>
        ) : null}
      </Col>
    </Row>
  </Container>
    </div>
}

export default CtaBlock