import { useRef, useState, useEffect } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { AiFillDelete } from 'react-icons/ai';
import { FcImageFile } from 'react-icons/fc';
import { FcVideoFile } from 'react-icons/fc';
import { FcFile } from 'react-icons/fc';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { ToastContainer, toast } from 'react-toastify';
import LinearProgress from '@mui/material/LinearProgress';

const ImageConfig = {
	default: <FcFile />,
	png: <FcImageFile />,
	jpg: <FcImageFile />,
	jpeg: <FcImageFile />,
	gif: <FcImageFile />,
	mp4: <FcVideoFile />,
	mov: <FcVideoFile />,
	avi: <FcVideoFile />,
	mkv: <FcVideoFile />,
	m4v: <FcVideoFile />,
}

const FileDropEmbed = ({ showFiles, autoUpload, limit, multiple, accept, buttonTitle, files, setFiles }) => {
	
	const axiosPrivate = useAxiosPrivate();

	const dropRef = useRef();
	
	const [uploadComplete, setUploadComplete] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(0);
	const [fileUploadCount, setFileUploadCount] = useState(1);
	
	const [showFilePreview, setShowFilePreview] = useState(false)
	
	const onDragEnter = () => dropRef.current.classList.add('dragover');
	
	const onDragLeave = () => dropRef.current.classList.remove('dragover');
	
	const onDrop = () => dropRef.current.classList.remove('dragover');
	
	useEffect(() => {
		if (uploadProgress == 100 && fileUploadCount <= files.length){
			setFileUploadCount(fileUploadCount + 1);
		}
	}, [uploadProgress])
	
	const onFileDrop = (e) => {
		const newFiles = e.target.files;
		const filesArray = [...files, ...newFiles]
		setFiles(filesArray)
		setUploadComplete(false)
	}
	
	const fileRemove = (file) => {
		const updatedFiles = [...files];
		updatedFiles.splice(files.indexOf(file), 1);
		setFiles(updatedFiles);
	}
	
	const toggleFiles = () => {
			
	}
	
	function formatBytes(bytes, decimals = 2) {
		if (bytes === 0) return '0 Bytes';
	
		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	
		const i = Math.floor(Math.log(bytes) / Math.log(k));
	
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	
	
	return (
		<>
			<div className="drop-file-container"
				ref={dropRef}
				className="drop-file-input embed"
				onDragEnter={onDragEnter}
				onDragLeave={onDragLeave}
				onDrop={onDrop}
			>
				<div className="drop-file-input__label">
					<AiOutlineCloudUpload />
					<p>Drag & Drop your {limit ? 
						limit >= 1 && multiple == "true" ? 
							`files (max ${limit}) `
							:
							"file "
								: "files "} 
						here
					</p>
				</div>
				{ multiple == "true" ?
				<input type="file" id="files" accept={accept} value="" multiple onChange={onFileDrop}/>
				:
				<input type="file" id="files" accept={accept} value="" onChange={onFileDrop}/>
				}
			</div>
			{
				files?.length > 0 && showFiles == "true" ? (
					<div className="drop-file-preview">
						<p className="drop-file-preview__title">
							{files?.length < limit && <>{files?.length} files ready to upload <span onClick={() => setShowFilePreview(!showFilePreview)}>{showFilePreview ? `Hide` : `Show` } Files</span></>}
							{files?.length == limit && "Ready to upload. File Limit Reached!"}
							{files?.length > limit && `File Limit Exceded! Please delete ${files?.length - limit} or more files.`}
						</p>
						
						{
							files?.map((file, index) => (
								<div key={index} className={`drop-file-preview__item ${showFilePreview ? "" : "hide"}`}>
									{ImageConfig[file?.type.split('/')[1]] || ImageConfig['default']}
									<div className="drop-file-preview__item__info">
										<p>{file?.name}</p>
										<p>{formatBytes(file?.size)}</p>
									</div>
									<span className="drop-file-preview__item__del" onClick={() => fileRemove(file)}><AiFillDelete /></span>
								</div>
								
							))
						}
					</div>
				) : (
					<p>
						{files?.length < limit && files?.length > 0 && `${files?.length} file(s) ready to upload`}
					</p>
				)
			}
	
		</>
	)
}


export default FileDropEmbed