import { Link, useNavigate, useLocation } from "react-router-dom";
import DashboardItemContent from "./DashboardItemContent"

const DashboardItem = ({ title, id, icon, count, includeTime, description, link }) => {
	return (
		<>
		{link ? 
		(	
			<div id={id ? id : null} className="dashboardItem">
				<Link to={link}>
					<DashboardItemContent title={title} icon={icon} count={count} includeTime={includeTime} description={description} />
				</Link>
			</div>
		) 
		: 
		(	
			<div id={id ? id : null} className="dashboardItem">
				<DashboardItemContent title={title} icon={icon} count={count} includeTime={includeTime} description={description} />
			</div>
		)
		}
		</>
	)
}

export default DashboardItem