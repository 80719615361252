import { useRef, useState, useEffect } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { AiFillDelete } from 'react-icons/ai';
import { FcImageFile } from 'react-icons/fc';
import { FcVideoFile } from 'react-icons/fc';
import { FcFile } from 'react-icons/fc';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { ToastContainer, toast } from 'react-toastify';
import LinearProgress from '@mui/material/LinearProgress';

const ImageConfig = {
	default: <FcFile />,
	png: <FcImageFile />,
	jpg: <FcImageFile />,
	jpeg: <FcImageFile />,
	gif: <FcImageFile />,
	mp4: <FcVideoFile />,
	mov: <FcVideoFile />,
	avi: <FcVideoFile />,
	mkv: <FcVideoFile />,
	m4v: <FcVideoFile />,
}

const PageFileDrop = ({ showFiles, autoUpload, limit, multiple, accept, buttonTitle, handleUploadImage, setPageImages }) => {
	
	const axiosPrivate = useAxiosPrivate();

	const dropRef = useRef();
	
	const [files, setFiles] = useState([]);
	const [validFiles, setValidFiles] = useState(false);
	const [uploadComplete, setUploadComplete] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(0);
	const [fileUploadCount, setFileUploadCount] = useState(1);
	
	const onDragEnter = () => dropRef.current.classList.add('dragover');
	
	const onDragLeave = () => dropRef.current.classList.remove('dragover');
	
	const onDrop = () => dropRef.current.classList.remove('dragover');
	
	useEffect(() => {
		if (uploadProgress == 100 && fileUploadCount <= files.length){
			setFileUploadCount(fileUploadCount + 1);
		}
	}, [uploadProgress])
	
	useEffect(() => {
		const count = files.length;
		
		if (limit){
			count >= 1 && count <= limit ? setValidFiles(true) : setValidFiles(false);
		} else {
			count >= 1 ? setValidFiles(true) : setValidFiles(false);
		}
		
	}, [files])
	
	const onFileDrop = (e) => {
		const newFiles = e.target.files;
		const filesArray = [...files, ...newFiles]
		setFiles(filesArray)
		setUploadComplete(false)
	}
	
	const fileRemove = (file) => {
		const updatedFiles = [...files];
		updatedFiles.splice(files.indexOf(file), 1);
		setFiles(updatedFiles);
	}
	
	function formatBytes(bytes, decimals = 2) {
		if (bytes === 0) return '0 Bytes';
	
		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	
		const i = Math.floor(Math.log(bytes) / Math.log(k));
	
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	const handleUpload = async (e) => {
		e.preventDefault();
		setIsUploading(true);
		const updateImageToast = toast.loading("Uploading Files");
		const controller = new AbortController();
		try {
			const formData = new FormData();
			console.log(files)
			files.map((file, index) => {
				formData.append('files[]', file);
			});
						
			console.log(formData)
			const response = await axiosPrivate.post('page/upload-files', 
			formData, 
			{	
				onUploadProgress: progressEvent => {
					{/* console.log('upload progress: ' + Math.round(progressEvent.loaded / progressEvent.total * 100) + '%') */}
					setUploadProgress(Math.round(progressEvent.loaded / progressEvent.total * 100))
				},
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				signal: controller.signal
			});
			
			// Delete local files from form
			setFiles([]);
			setUploadComplete(true);
			setIsUploading(false);
			setFileUploadCount(1);
			setUploadProgress(0);
			
			// Add new files to state
			setPageImages(response?.data?.images)

			// Update Toast Notification
			toast.update(updateImageToast, { render: 'Upload Complete', type: 'success', isLoading: false, autoClose: 5000});
		} catch (err) {
			if (!err?.response) {
				toast.update(updateImageToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
			} else if (err.response?.status === 400) {				
				toast.update(updateImageToast, { render: 'Upload Failed', type: 'error', isLoading: false});
			} else if (err.response?.status === 401) {				
				toast.update(updateImageToast, { render: 'Authorization Failed', type: 'error', isLoading: false});
			} else {
				toast.update(updateImageToast, { render: 'Upload Failed', type: 'error', isLoading: false, autoClose: 5000});				
			}
		}
		return () => controller.abort();
	};
	
	return (
		<form encType="multipart/form-data">
			<div className="drop-file-container"
				ref={dropRef}
				className="drop-file-input"
				onDragEnter={onDragEnter}
				onDragLeave={onDragLeave}
				onDrop={onDrop}
			>
				<div className="drop-file-input__label">
					<AiOutlineCloudUpload />
					<p>Drag & Drop your {limit ? 
						limit > 1 && multiple == "true" ? 
							`files (max ${limit}) `
							:
							"file "
								: "files "} 
						here
					</p>
				</div>
				{ multiple == "true" ?
				<input type="file" id="files" accept={accept} value="" multiple onChange={onFileDrop}/>
				:
				<input type="file" id="files" accept={accept} value="" onChange={onFileDrop}/>
				}
			</div>
			{
				files.length > 0 && showFiles == "true" ? (
					<div className="drop-file-preview">
						<p className="drop-file-preview__title">
							{files.length < 10 && `${files.length} files ready to upload`}
							{files.length == 10 && "Ready to upload. File Limit Reached!"}
							{files.length > 10 && `File Limit Exceded! Please delete ${files.length - limit} or more files.`}
						</p>
						
						{
							files.map((file, index) => (
								<div key={index} className="drop-file-preview__item">
									{ImageConfig[file.type.split('/')[1]] || ImageConfig['default']}
									<div className="drop-file-preview__item__info">
										<p>{file.name}</p>
										<p>{formatBytes(file.size)}</p>
									</div>
									<span className="drop-file-preview__item__del" onClick={() => fileRemove(file)}><AiFillDelete /></span>
								</div>
								
							))
						}
					</div>
				) : null
			}
			
			{autoUpload !== "true" && 
			<button className={`drop-file ${isUploading && "uploading"}`} disabled={!validFiles ? true : false} onClick={handleUpload}>
				{buttonTitle ? buttonTitle : 
					isUploading ? `Uploading File ${fileUploadCount} of ${files.length}` :
					limit > 1 ? "Upload Files" : "Upload File"}
				{isUploading && <LinearProgress variant="determinate" value={uploadProgress} />}
			</button>}
			
		</form>
	)
}


export default PageFileDrop