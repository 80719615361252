import { useState, useEffect } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import { AiFillDelete } from 'react-icons/ai';
import { MdOutlineEdit } from 'react-icons/md';
import { MdVisibility } from 'react-icons/md';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import Loader from './Loader';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';

const Jobs = () => {

	const [loading, setLoading] = useState(false);
	const [jobs, setJobs] = useState();	
	const [filteredJobs, setFilteredJobs] = useState();
	const axiosPrivate = useAxiosPrivate();

	const navigate = useNavigate();
	const location = useLocation();

	const auth = useAuth();
	const role = auth?.role;
	const id = auth?.id;

	const [deleteName, setDeleteName] = useState();
	const [deleteID, setDeleteID] = useState();
	const [open, setOpen] = useState(false);

	const [viewName, setViewName] = useState();
	const [viewID, setViewID] = useState();
	const [viewDescription, setViewDescription] = useState();
	const [viewImages, setViewImages] = useState([]);

	const [openView, setOpenView] = useState(false);

	const [filterSearch, setFilterSearch] = useState("")

	const handleClickOpen = () => {
	    setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleDelete = async () => {
		const dismiss = () =>  toast.dismiss(deleteProfileToast.current);
		const deleteProfileToast = toast.loading("Deleting Job");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.delete('job/' + deleteID,
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(deleteProfileToast, { render: 'Job Deleted', type: 'success', isLoading: false, autoClose: 5000});

			// Close Alert Window
			setOpen(false);

			// Delete job from UI
			const filtered = jobs.filter((job) => job.id !== deleteID)
			setFilteredJobs(filtered)
			setJobs(filtered)

		} catch (err) {
			if (!err?.response) {
				toast.update(deleteProfileToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(deleteProfileToast, { render: 'Job Delete Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	};

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getJobs = async () => {
			try {
				const response = await axiosPrivate.get('jobs', {
					signal: controller.signal
				});
				console.log(response.data);
				setJobs(response.data);
				setFilteredJobs(response.data);
				setLoading(false)
			} catch(err) {
				console.log(err.message);
				{/* !auth && navigate('/login', { state: { from: location }, replace: true }); */}
			}
		}

		getJobs();

		return () => controller.abort();
		// eslint-disable-next-line
	},[])

	const deleteJob = (e, i, name, id) => {
		setOpen(true);
		setDeleteName(name);
		setDeleteID(id);
	}

	const editJob = (e, i) => {
	}

	const handleClickOpenView = () => {
		setOpenView(true);
	};

	const handleCloseView = () => {
		setOpenView(false);
	};

	const viewFullSizedImage = () => {
		console.log("view full sized image")
	}

	const viewJob = (e, i, name, id, description, images) => {
		setOpenView(true);
		setViewName(name);
		setViewID(id)
		setViewDescription(description)
		setViewImages(images)
	}

	const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
	const onSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        const sortedData = [...jobs].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setFilteredJobs(sortedData);
        setSortConfig({ key, direction });
    };

	useEffect(() => {
		if (filterSearch === ""){
			setFilteredJobs(jobs)
		} else {
			const resultsArray = jobs?.filter(job => {
				return (
					job.homeowner_name.toLowerCase().includes(filterSearch.toLowerCase()) ||
					job.user == filterSearch
				)
			})

			setFilteredJobs(resultsArray)
		}
	}, [filterSearch])
    
	return (
		<div className="backendList">

			<div className="selectContainer">
				<div className="selectWrapper">
					<Box>
						<FormControl fullWidth>
							<TextField id="outlined-basic" label="Search" variant="outlined" onChange={(e) => setFilterSearch(e.target.value)} />
						</FormControl>
					</Box>
				</div>
			</div>

			{jobs?.length
				? (
					<>
					<ul className="jobsList">
						<li className="key">
							<div className="name" onClick={() => onSort('name')}>Project Name</div>
							<div className="user" onClick={() => onSort('user')}>User ID</div>
							<div className="homeowner" onClick={() => onSort('homeowner_name')}>Homeowner</div>
							<div className="buttons"></div>
						</li>
						{filteredJobs.map((job, i) =>
							<li key={i}>
								<div className="name">
									{job?.name}
								</div>
								<div className="user">
									{job?.user}
								</div>
								<div className="homeowner">
									{job?.homeowner_name}
								</div>
								<div className="buttons">
									{/* <div className="edit" onClick={(e) => editJob(e, i)}>
										<span><MdOutlineEdit /></span>
									</div> */}
									<div className="view" onClick={(e) => viewJob(e, i, job?.name, job?.id, job?.description, job?.images)}>
										<span><MdVisibility /></span>
									</div>
									<div className="delete" onClick={(e) => deleteJob(e, i, job?.name, job?.id)}>
										<span><AiFillDelete /></span>
									</div>
								</div>
							</li>
						)}
					</ul>
					{/* Delete Job */}
					<Dialog
						open={open}
						onClose={handleClose}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					  >
						<DialogTitle id="alert-dialog-title">
						  {"Are you sure you want to delete this project?"}
						</DialogTitle>
						<DialogContent>
						  <DialogContentText id="alert-dialog-description">
							This action cannot be undone. This will permanently delete the project with name, "{deleteName}".
						  </DialogContentText>
						</DialogContent>
						<DialogActions>
						  <button className="cancel" onClick={handleClose}>Cancel</button>
						  <button className="confirm" onClick={handleDelete}>Yes, delete project</button>
						</DialogActions>
					  </Dialog>

					  {/* View Job */}
					  <Dialog
						  open={openView}
						  onClose={handleCloseView}
						  aria-labelledby="alert-dialog-title"
						  aria-describedby="alert-dialog-description"
						  id="viewJobPopup"
						>
						  <DialogTitle id="alert-dialog-title">
							{`Viewing project ${viewName}`}
						  </DialogTitle>
						  <DialogContent>
							<DialogContentText id="alert-dialog-description">
								  <div>
									  <h3>Description</h3>
									  <p>{viewDescription}</p>
									  <ul className="jobImageList">
									  {viewImages?.map((image, i) =>
										  <li key={i} onClick={(e) => viewFullSizedImage(e, i, image?.id)}>
											  <img src={`https://storage.iyh.app/${image.path}`} alt="" />
										  </li>
									  )}
									  </ul>
								  </div>
							</DialogContentText>
						  </DialogContent>
						  <DialogActions>
							<button className="cancel" onClick={handleCloseView}>Cancel</button>
						  </DialogActions>
					  </Dialog>

		</>
				) :
					loading ?
					<Loader />
					:
					<p>No projects to display</p>
			}
			<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
			/>
		</div>
	)
}

export default Jobs