import { useState, useEffect, useRef } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import { AiFillDelete } from 'react-icons/ai';
import { AiOutlineShareAlt } from 'react-icons/ai';
import { GrFormEdit } from 'react-icons/gr';
import { GrFormView } from 'react-icons/gr';
import { FaCheck } from 'react-icons/fa';
import { FaExclamation } from 'react-icons/fa';
import { MdOutlinePlaylistAdd } from 'react-icons/md';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import Loader from './Loader';
import CreateZipCode from "./CreateZipCode"
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const ZIP_REGEX = /^[0-9]{5}$/;

const ZipCodes = () => {

	const [loading, setLoading] = useState(false);
	const [zipcodes, setZipcodes] = useState();
	const axiosPrivate = useAxiosPrivate();

	const navigate = useNavigate();
	const location = useLocation();

	const auth = useAuth();
	const role = auth?.role;
	const id = auth?.id;

	const zipRef = useRef()
	const countyRef = useRef()

	const [status, setStatus] = useState()
	const [activeStates, setActiveStates] = useState()
	const [activeStateCounties, setActiveStateCounties] = useState()
	const [filteredCounties, setFilteredCounties] = useState()

	const [name, setName] = useState();
	const [county, setCounty] = useState();
	const [description, setDescription] = useState();
	const [open, setOpen] = useState(false);
	const [openAdd, setOpenAdd] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [openView, setOpenView] = useState(false);

	const [deleteID, setDeleteID] = useState()
	const [deleteName, setDeleteName] = useState()
	const [deleteZip, setDeleteZip] = useState()

	const [viewName, setViewName] = useState()
	const [viewDescription, setViewDescription] = useState()
	const [viewZips, setViewZips] = useState()

	const [editID, setEditID] = useState()
	const [editName, setEditName] = useState()
	const [editDescription, setEditDescription] = useState()
	const [editZip, setEditZip] = useState()
	const [editCounty, setEditCounty] = useState()
	const [editState, setEditState] = useState()

	const [validEditZipcode, setValidEditZipcode] = useState()
	const [zipcodeFocus, setZipcodeFocus] = useState()
	const [editZipFocus, setEditZipFocus] = useState()
	const [editStateFocus, setEditStateFocus] = useState()
	const [validEditCounty, setValidEditCounty] = useState()
	const [editCountyFocus, setEditCountyFocus] = useState()
	const [validCounty, setValidCounty] = useState()
	const [validState, setValidState] = useState(true)

	const [filterState, setFilterState] = useState()
	const [filterCounty, setFilterCounty] = useState()
	const [searchResults, setSearchResults] = useState()

	useEffect(() => {
		const resultsArray = zipcodes?.filter(zip => zip.state.includes(filterState))

		setSearchResults(resultsArray)
	}, [filterState])

	useEffect(() => {
		const resultsArray = zipcodes?.filter(zip => zip.county === filterCounty && zip.state.includes(filterState))

		setSearchResults(resultsArray)
	}, [filterCounty])

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClickOpenAdd = () => {
		setOpenAdd(true)
	}

	const handleClose = () => {
		setOpen(false);
	};

	const handleAddClose = () => {
		setOpenAdd(false);
	};

	const handleEdit = (edit) => {
		setOpenEdit(true);
	};

	const handleCloseEdit = () => {
		setOpenEdit(false);
	};

	const handleView = () => {
		setOpenView(true);
	};

	const handleCloseView = () => {
		setOpenView(false);
	};

	const handleAddZipcode = async () => {
		console.log("adding zipcode")
	}

	const handleSaveEdit = async () => {
		const dismiss = () =>  toast.dismiss(updateZipCodeToast.current);
		const updateZipCodeToast = toast.loading("Updating Zip Code");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.put('zip-code/' + editID,
			JSON.stringify({zipcode: editZip, county: editCounty, state: editState, market: null}),
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(updateZipCodeToast, { render: 'Zip Code Updated', type: 'success', isLoading: false, autoClose: 5000});

			// Update UI
			const index = zipcodes.map(function(x) {return x.id; }).indexOf(editID);
			zipcodes[index].zipcode = editZip
			zipcodes[index].county = editCounty
			zipcodes[index].state = editState

			// Close Alert Window
			setOpenEdit(false);

		} catch (err) {
			if (!err?.response) {
				toast.update(updateZipCodeToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(updateZipCodeToast, { render: 'Zip Code Update Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	const handleDelete = async () => {
		const dismiss = () =>  toast.dismiss(deleteZipCodeToast.current);
		const deleteZipCodeToast = toast.loading("Deleting Zip Code");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.delete('zip-code/' + editID,
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(deleteZipCodeToast, { render: 'Zip Code Deleted', type: 'success', isLoading: false, autoClose: 5000});

			// Delete job from UI
			setZipcodes(zipcodes.filter((zipcode) => zipcode.id !== editID))

			// Close Alert Window
			setOpen(false);

		} catch (err) {
			if (!err?.response) {
				toast.update(deleteZipCodeToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(deleteZipCodeToast, { render: 'Zip Code Delete Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	};

	/* Check Name */
	useEffect(() => {
		const result = ZIP_REGEX.test(editZip);
		setValidEditZipcode(result);
	}, [editZip])

	useEffect(() => {
		editState !== null && editState !== "" ? setValidState(false) : setValidState(true)
	}, [editState])

	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		const getZipcodes = async () => {
			try {
				const response = await axiosPrivate.get('zip-codes', {
					signal: controller.signal
				});
				console.log(response.data);
				setZipcodes(response.data.zip_codes);
				setSearchResults(response.data.zip_codes);
				setActiveStates(response.data.active_states)
				setActiveStateCounties(response.data.active_state_counties)
				setLoading(false)
			} catch(err) {
				console.log(err.message);
				!auth && navigate('/login', { state: { from: location }, replace: true });
			}
		}

		getZipcodes();

		return () => controller.abort();

	},[])

	const deleteZipcodes = (e, i, id, zip) => {
		setOpenEdit(false)
		setOpen(true);
		setDeleteID(id);
		setDeleteZip(zip)
	}

	const viewZipcode = (e, i, id, zip) => {
		setOpenView(true);
		setViewName(name)
		setViewDescription(description)
		setViewZips(zip)
	}

	const editZipcode = (e, i, id, zip, county, state) => {
		setOpenEdit(true);
		setEditID(id)
		setEditZip(zip)
		setEditCounty(county)
		setEditState(state)
	}

	const addZipCode = (zipcode) => {
		console.log(zipcode)
		setOpenAdd(false)
		setZipcodes([...zipcodes, zipcode])
	}

	const renderOpts = key => activeStateCounties[key].map((c, i) => <MenuItem key={i} value={c.county}>{c.county}</MenuItem>)
	const renderOptions = key => activeStateCounties[key].map((c, i) => <option key={i} value={c.county}>{c.county}</option>)

	const formatDate = (dateString) => {
	  const options = { month: "long", day: "numeric", year: "numeric" }
	  return new Date(dateString).toLocaleDateString(undefined, options)
	}

	return (
		<div className="backendList">
			<div className="selectContainer">
				<div className="selectWrapper">
					<Box>
						<FormControl fullWidth>
							<InputLabel id="filter-state-label">Filter State</InputLabel>
							<Select
							  labelId="filter-state-label"
							  id="filterState"
							  onChange={(e) => setFilterState(e.target.value)}
							  value={filterState}
							>
								{activeStates?.map((state, i) =>
								    <MenuItem key={i} value={state.state}>{state.state}</MenuItem>
								)}
							</Select>
						  </FormControl>
					</Box>
				</div>
				<div className="selectWrapper">
					<Box>
						<FormControl fullWidth>
							<InputLabel id="filter-county-label">Filter County</InputLabel>
							<Select
							  labelId="filter-county-label"
							  id="filterCounty"
							  onChange={(e) => setFilterCounty(e.target.value)}
							  value={filterCounty}
							  disabled={filterState ? false : true}
							>
								{filterState == "Alabama" && renderOpts('Alabama')}
								{filterState == "Alaska" && renderOpts('Alaska')}
								{filterState == "Arizona" && renderOpts('Arizona')}
								{filterState == "Arkansas" && renderOpts('Arkansas')}
								{filterState == "California" && renderOpts('California')}
								{filterState == "Colorado" && renderOpts('Colorado')}
								{filterState == "Connecticut" && renderOpts('Connecticut')}
								{filterState == "Delaware" && renderOpts('Delaware')}
								{filterState == "District of Columbia" && renderOpts('District of Columbia')}
								{filterState == "Florida" && renderOpts('Florida')}
								{filterState == "Georgia" && renderOpts('Georgia')}
								{filterState == "Hawaii" && renderOpts('Hawaii')}
								{filterState == "Idaho" && renderOpts('Idaho')}
								{filterState == "Illinois" && renderOpts('Illinois')}
								{filterState == "Indiana" && renderOpts('Indiana')}
								{filterState == "Iowa" && renderOpts('Iowa')}
								{filterState == "Kansas" && renderOpts('Kansas')}
								{filterState == "Kentucky" && renderOpts('Kentucky')}
								{filterState == "Louisiana" && renderOpts('Louisiana')}
								{filterState == "Maine" && renderOpts('Maine')}
								{filterState == "Maryland" && renderOpts('Maryland')}
								{filterState == "Massachusetts" && renderOpts('Massachusetts')}
								{filterState == "Michigan" && renderOpts('Michigan')}
								{filterState == "Minnesota" && renderOpts('Minnesota')}
								{filterState == "Mississippi" && renderOpts('Mississippi')}
								{filterState == "Missouri" && renderOpts('Missouri')}
								{filterState == "Montana" && renderOpts('Montana')}
								{filterState == "Nebraska" && renderOpts('Nebraska')}
								{filterState == "Nevada" && renderOpts('Nevada')}
								{filterState == "New Hampshire" && renderOpts('New Hampshire')}
								{filterState == "New Jersey" && renderOpts('New Jersey')}
								{filterState == "New Mexico" && renderOpts('New Mexico')}
								{filterState == "New York" && renderOpts('New York')}
								{filterState == "North Carolina" && renderOpts('North Carolina')}
								{filterState == "North Dakota" && renderOpts('North Dakota')}
								{filterState == "Ohio" && renderOpts('Ohio')}
								{filterState == "Oklahoma" && renderOpts('Oklahoma')}
								{filterState == "Oregon" && renderOpts('Oregon')}
								{filterState == "Pennsylvania" && renderOpts('Pennsylvania')}
								{filterState == "Rhode Island" && renderOpts('Rhode Island')}
								{filterState == "South Carolina" && renderOpts('South Carolina')}
								{filterState == "South Dakota" && renderOpts('South Dakota')}
								{filterState == "Tennessee" && renderOpts('Tennessee')}
								{filterState == "Texas" && renderOpts('Texas')}
								{filterState == "Utah" && renderOpts('Utah')}
								{filterState == "Vermont" && renderOpts('Vermont')}
								{filterState == "Virginia" && renderOpts('Virginia')}
								{filterState == "Washington" && renderOpts('Washington')}
								{filterState == "West Virginia" && renderOpts('West Virginia')}
								{filterState == "Wisconsin" && renderOpts('Wisconsin')}
								{filterState == "Wyoming" && renderOpts('Wyoming')}
							</Select>
						</FormControl>
					</Box>
				</div>
				<div className="addZipWrapper">
					<button className="addZip" onClick={(e) => handleClickOpenAdd(e)}><MdOutlinePlaylistAdd /></button>
				</div>
			</div>

			{zipcodes?.length
				? (
					<>
						<ul className="zipcodeList">
							{searchResults?.map((zipcode, i) =>
								<li key={i} onClick={(e) => editZipcode(e, i, zipcode?.id, zipcode?.zipcode, zipcode?.county, zipcode?.state)} >
									<div className="zip">
										{zipcode?.zipcode}
									</div>
								</li>
							)}
						</ul>
						{/* Delete Dialog */}
						<Dialog
							open={open}
							onClose={handleClose}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						  >
							<DialogTitle id="alert-dialog-title">
							  {"Are you sure you want to delete this zipcode?"}
							</DialogTitle>
							<DialogContent>
							  <DialogContentText id="alert-dialog-description">
								{`This action cannot be undone. This will permanently delete the zipcode ${editZip}.`}
							  </DialogContentText>
							</DialogContent>
							<DialogActions>
							  <button className="cancel" onClick={handleClose}>Cancel</button>
							  <button className="confirm" onClick={handleDelete}>Yes, delete zipcode</button>
							</DialogActions>
						  </Dialog>

						{/* Edit Dialog */}
						<Dialog
							open={openEdit}
							onClose={handleCloseEdit}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						  >
							<DialogTitle id="alert-dialog-title">
							  {`Editing Zip Code ${editZip}`}
							</DialogTitle>
							<DialogContent>
							  <DialogContentText id="alert-dialog-description">
								<form>
									{/* Name */}
									<label htmlFor="zipcode">Zip Code:</label>
									<div className="inputWrapper">
										<span className={validEditZipcode ? "valid" : "hide"}><FaCheck /></span>
										<span className={validEditZipcode || !editZip ? "hide" : "invalid"}><FaExclamation /></span>
										<input
											type="text"
											id="zipcode"
											className={validEditZipcode || !editZip ? null : "error"}
											ref={zipRef}
											autoComplete="off"
											onChange={(e) => setEditZip(e.target.value)}
											value={editZip}
											aria-invalid={validEditZipcode ? "false" : "true"}
											aria-describedby="namenote"
											onFocus={() => setZipcodeFocus(true)}
											onBlur={() => setZipcodeFocus(false)}
										/>
									</div>
									<p id="namenote" className={editZipFocus && editZip && !validEditZipcode ? "instructions" : "offscreen"}>
										Invalid Zipcode. Zipcodes must be exactly 5 numbers long.
									</p>

									{/* State */}
									<label htmlFor="state">State:</label>
									<div className="inputWrapper">
										<select
											id="state"
											onChange={(e) => setEditState(e.target.value)}
											value={editState}
											required
											onFocus={() => setEditStateFocus(true)}
											onBlur={() => setEditStateFocus(false)}
										>
											<option value="">Select State</option>
											{activeStates.map((state, i) =>
												<option key={i} value={state.state}>{state.state}</option>
											)}
										</select>
									</div>

									{/* County */}
									<label htmlFor="county">County:</label>
									<div className="inputWrapper">
										<select
										id="county"
										onChange={(e) => setEditCounty(e.target.value)}
										value={editCounty}
										required
										onFocus={() => setEditCountyFocus(true)}
										onBlur={() => setEditCountyFocus(false)}
										disabled={editState !== "" ? false : true}
									>
										{editState !== "" &&
										<>
											<option value="">Select County</option>
											{editState == "Alabama" && renderOptions('Alabama')}
											{editState == "Alaska" && renderOptions('Alaska')}
											{editState == "Arizona" && renderOptions('Arizona')}
											{editState == "Arkansas" && renderOptions('Arkansas')}
											{editState == "California" && renderOptions('California')}
											{editState == "Colorado" && renderOptions('Colorado')}
											{editState == "Connecticut" && renderOptions('Connecticut')}
											{editState == "Delaware" && renderOptions('Delaware')}
											{editState == "District of Columbia" && renderOptions('District of Columbia')}
											{editState == "Florida" && renderOptions('Florida')}
											{editState == "Georgia" && renderOptions('Georgia')}
											{editState == "Hawaii" && renderOptions('Hawaii')}
											{editState == "Idaho" && renderOptions('Idaho')}
											{editState == "Illinois" && renderOptions('Illinois')}
											{editState == "Indiana" && renderOptions('Indiana')}
											{editState == "Iowa" && renderOptions('Iowa')}
											{editState == "Kansas" && renderOptions('Kansas')}
											{editState == "Kentucky" && renderOptions('Kentucky')}
											{editState == "Louisiana" && renderOptions('Louisiana')}
											{editState == "Maine" && renderOptions('Maine')}
											{editState == "Maryland" && renderOptions('Maryland')}
											{editState == "Massachusetts" && renderOptions('Massachusetts')}
											{editState == "Michigan" && renderOptions('Michigan')}
											{editState == "Minnesota" && renderOptions('Minnesota')}
											{editState == "Mississippi" && renderOptions('Mississippi')}
											{editState == "Missouri" && renderOptions('Missouri')}
											{editState == "Montana" && renderOptions('Montana')}
											{editState == "Nebraska" && renderOptions('Nebraska')}
											{editState == "Nevada" && renderOptions('Nevada')}
											{editState == "New Hampshire" && renderOptions('New Hampshire')}
											{editState == "New Jersey" && renderOptions('New Jersey')}
											{editState == "New Mexico" && renderOptions('New Mexico')}
											{editState == "New York" && renderOptions('New York')}
											{editState == "North Carolina" && renderOptions('North Carolina')}
											{editState == "North Dakota" && renderOptions('North Dakota')}
											{editState == "Ohio" && renderOptions('Ohio')}
											{editState == "Oklahoma" && renderOptions('Oklahoma')}
											{editState == "Oregon" && renderOptions('Oregon')}
											{editState == "Pennsylvania" && renderOptions('Pennsylvania')}
											{editState == "Rhode Island" && renderOptions('Rhode Island')}
											{editState == "South Carolina" && renderOptions('South Carolina')}
											{editState == "South Dakota" && renderOptions('South Dakota')}
											{editState == "Tennessee" && renderOptions('Tennessee')}
											{editState == "Texas" && renderOptions('Texas')}
											{editState == "Utah" && renderOptions('Utah')}
											{editState == "Vermont" && renderOptions('Vermont')}
											{editState == "Virginia" && renderOptions('Virginia')}
											{editState == "Washington" && renderOptions('Washington')}
											{editState == "West Virginia" && renderOptions('West Virginia')}
											{editState == "Wisconsin" && renderOptions('Wisconsin')}
											{editState == "Wyoming" && renderOptions('Wyoming')}
										</>
										}

									</select>
									</div>
								</form>
							  </DialogContentText>
							</DialogContent>
							<DialogActions>
							  <button className="cancel" onClick={handleCloseEdit}>Cancel</button>
							  <button className="confirm" onClick={handleSaveEdit}>Yes, update zipcode</button>
							  <button className="confirm" onClick={deleteZipcodes}>Yes, delete zipcode</button>
							</DialogActions>
						  </Dialog>

						{/* View Dialog */}
						<Dialog
							open={openView}
							onClose={handleCloseView}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
							className="viewZipCodePopup"
						  >
							<DialogTitle id="alert-dialog-title">
							  {`${viewName} Zip Code`}
							</DialogTitle>
							<DialogContent>
							  <DialogContentText id="alert-dialog-description">
								<>
									{viewDescription}
									<h4>Assigned Zip Codes</h4>
									{viewZips?.length > 0 ?
										<p>list zip codes</p>
										:
										<p>No zip codes assigned to this zipcode</p>
									}
								</>
							  </DialogContentText>
							</DialogContent>
							<DialogActions>
							  <button className="cancel" onClick={handleCloseView}>Close</button>
							</DialogActions>
						  </Dialog>

						  {/* Add Zipcode Dialog */}
						  <Dialog
							  open={openAdd}
							  onClose={handleAddClose}
							  aria-labelledby="alert-dialog-title"
							  aria-describedby="alert-dialog-description"
							>
							  <DialogTitle id="alert-dialog-title">
								{"Add a new zipcode?"}
							  </DialogTitle>
							  <DialogContent>
								<DialogContentText id="alert-dialog-description">
								  <CreateZipCode addZipCode={addZipCode} activeStateCounties={activeStateCounties} activeStates={activeStates} />
								</DialogContentText>
							  </DialogContent>
							  <DialogActions>
								<button className="cancel" onClick={handleAddClose}>Cancel</button>
							  </DialogActions>
							</Dialog>
					</>
				) :
					loading ?
					<Loader />
					:
					<p>No zipcodes to display</p>
			}
			<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
			/>
		</div>
	)
}

export default ZipCodes