import AdminMenu from '../../components/AdminMenu';
import Reviews from "../../components/Reviews";
import { GiStarsStack } from 'react-icons/gi'

const AdminUsers = () => {
	return (
		<section>
			<article className="inner">
				<div className="backendTitleBar">
					<h1>Contractor Reviews</h1>
					<GiStarsStack />
				</div>
				<div className="backendColumnWrapper">
					<div id="menu" className="column">
						<AdminMenu />
					</div>
					<div id="content" className="column">
						<Reviews />
					</div>
				</div>
			</article>
		</section>
	)
}


export default AdminUsers