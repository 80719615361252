import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from "../hooks/useRefreshToken";
import useAuth from "../hooks/useAuth";
import Loader from "./Loader.js"

const PersistLogin = () => {
	const [isLoading, setIsLoading] = useState(true);
	const refresh = useRefreshToken();
	const { auth, persist } = useAuth();

	useEffect(() => {
		const verifyRefreshToken = async () => {
			try {
				await refresh();
			}
			catch (err) {
				console.log(err.message);
			}
			finally {
				setIsLoading(false);
			}
		}

		!auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);

	}, [])

	useEffect(() => {
		// console.log(`isLoading: ${isLoading}`);
		// console.log(`aT: ${JSON.stringify(auth?.accessToken)}`);
	}, [isLoading])

	return (
		<>
			{!persist
				? <Outlet />
				: isLoading
					? <Loader />
					: <Outlet />
			}
		</>
	)
}

export default PersistLogin;