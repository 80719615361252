import AdminMenu from "../../components/AdminMenu";
import Users from "../../components/Users";
import { MdPeopleAlt } from "react-icons/md";
import { AdminFormProvider } from "../../context/AdminFormContext";

const AdminUsers = () => {
  return (
    <section>
      <article className="inner">
        <div className="backendTitleBar">
          <h1>Users</h1>
          <MdPeopleAlt />
        </div>
        <div className="backendColumnWrapper">
          <div id="menu" className="column">
            <AdminMenu />
          </div>
          <div id="content" className="column">
            <AdminFormProvider>
              <Users />
            </AdminFormProvider>
          </div>
        </div>
      </article>
    </section>
  );
};

export default AdminUsers;
