import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import usePageTitle from '../../hooks/usePageTitle';
import UserPageNav from '../../components/UserPageNav';
import ContractorPackages from '../../components/ContractorPackages'
import useAuth from '../../hooks/useAuth';
import { AiOutlineDashboard } from 'react-icons/ai'
import { CgProfile } from 'react-icons/cg'
import { MdAccountBox } from 'react-icons/md'
import { FaUserCircle } from 'react-icons/fa'
import { GiStarsStack } from 'react-icons/gi'
import { AiOutlineBarChart } from 'react-icons/ai'
import { HiChartSquareBar } from 'react-icons/hi'
import { ImBullhorn } from 'react-icons/im';
import { MdDashboard } from 'react-icons/md';
import { RiCustomerService2Line } from 'react-icons/ri';
import { MdHelpCenter } from 'react-icons/md';
import { AiFillMessage } from 'react-icons/ai';
import { MdMessage } from 'react-icons/md';
import { MdFavorite } from 'react-icons/md';
import { MdFactCheck } from 'react-icons/md';
import { MdRequestPage } from 'react-icons/md';
import { MdSettings } from 'react-icons/md';
import { MdWork } from 'react-icons/md';

const Account = () => {

	usePageTitle("Account");

	const {auth} = useAuth();
	const role = auth?.roles;
	const userID = auth?.user?.id;
	const subscription = auth?.subscription;

	const [serviceCategories, setServiceCategories] = useState()
	const [activeCounties, setActiveCounties] = useState()
	const [activeState, setActiveState] = useState()
	const [excludedZipcodes, setExcludedZipcodes] = useState()

	const axiosPrivate = useAxiosPrivate();

	const [loading, setLoading] = useState(false);
	const [subscriptionDetails, setSubscriptionDetails] = useState()

	const navigate = useNavigate();
	const location = useLocation();

	// /subscription-details/{id}

	useEffect(() => {
		// console.log(auth)
		const controller = new AbortController();
		setLoading(true);
		const getPackages = async () => {
			try {
				const response = await axiosPrivate.get('subscription-details/' + subscription?.subscription_id, {
					signal: controller.signal
				});
				// console.log(response.data)
				setSubscriptionDetails(response.data);

				setServiceCategories(JSON.parse(subscription?.service_categories))
				setActiveCounties(JSON.parse(subscription?.active_counties))
				setActiveState(JSON.parse(subscription?.active_state).State)
				setExcludedZipcodes(JSON.parse(subscription?.excluded_zipcodes))

				setLoading(false)
			} catch(err) {
				console.log(err.message);
				!auth && navigate('/login', { state: { from: location }, replace: true });
			}
		}

		if (subscription?.subscription_id){
			getPackages();
		}

		return () => controller.abort();
		// eslint-disable-next-line
	},[])

	useEffect(() => {
		if (subscription?.active){
			setServiceCategories(JSON.parse(subscription?.service_categories))
			setActiveCounties(JSON.parse(subscription?.active_counties))
			setActiveState(JSON.parse(subscription?.active_state).State)
			setExcludedZipcodes(JSON.parse(subscription?.excluded_zipcodes))
		}
	}, [subscription])

	return (
		<section>
			<article className="inner">
				<div className="backendTitleBar">
					<h1>Account/Subscription</h1>
					<MdDashboard />
				</div>
				<div className="backendColumnWrapper">
					<div id="menu" className="column">
						<UserPageNav />
					</div>
					<div id="content" className="column">
						<ContractorPackages activeSubscription={subscription} />
						{ subscriptionDetails && subscription?.subscription_status == "active" ?
							<div className="subDetails">
								<h3>Subscription Details</h3>
								<p><strong>Subscription Status:</strong> {subscriptionDetails?.status}</p>
								<p><strong>Monthly Charge:</strong> {subscriptionDetails?.amount && `$${subscriptionDetails?.amount.toFixed(2)}`}</p>
								{subscriptionDetails?.schedule?.trialOccurrences > 0 && subscriptionDetails?.trial_amount ?
									<p><strong>Trial Period Charge:</strong> ${subscriptionDetails?.trial_amount.toFixed(2)}</p>
									:
									null
								}
								{subscriptionDetails?.schedule?.trialOccurrences > 0 ?
									<p><strong>Trial Length:</strong> {subscriptionDetails?.schedule?.trialOccurrences + 1} {subscriptionDetails?.schedule?.interval?.unit}</p>
									:
									null
								}
								<p><strong>Monthly Payments to date:</strong> {subscriptionDetails?.transactions ? subscriptionDetails?.transactions?.length : 0}</p>
								<p><strong>Service Categories:</strong> {serviceCategories?.length ?
									<ul className="cat">
										{serviceCategories.map((category, i) =>
											<li key={i}>
												<div>{category}</div>
											</li>
										)}
									</ul>
									:
									'None'
									}
								</p>
								<p><strong>Active Counties:</strong> {activeCounties?.length ?
									<ul>
										{activeCounties.map((county, i) =>
											<li key={i}>
												<div>{county}</div>
											</li>
										)}
									</ul>
									:
									'None'
									}
								</p>
								<p><strong>Active State:</strong><br /><div className="activeState">{activeState ? activeState : 'Not Defined'}</div></p>
								<p><strong>Excluded Zipcodes:</strong> {excludedZipcodes?.length ?
									<ul>
										{excludedZipcodes.map((zip, i) =>
											<li key={i}>
												<div>{zip}</div>
											</li>
										)}
									</ul>
									:
									'None'
									}
								</p>
							</div>
							:
							null
						}
						{ subscriptionDetails && subscription?.subscription_status == "active"  && (auth?.user?.featured_contractor || auth?.user?.priority_listing) ?
							<div className="subDetails">
								<p>{ auth?.user?.featured_contractor ? 'Featured Home Pro Enabled' : null}</p>
								<p>{ auth?.user?.priority_listing ? 'Priority Listing Enabled' : null}</p>
							</div>
							:
							null
						}
					</div>
				</div>
			</article>
		</section>
	)
}

export default Account