import FormInputs from './FormInputs'
import useFormContext from "../hooks/useFormContext"
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from '../hooks/useAuth'
import { ToastContainer, toast } from 'react-toastify'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useRef, useState, useEffect } from 'react';
import { GoAlert } from 'react-icons/go'


const states = [
	{
		"State": "Alabama",
		"Code":	"AL"
	},
	{
		"State": "Alaska",
		"Code":	"AK"
	},
	{
		"State": "Arizona",
		"Code":	"AZ"
	},
	{
		"State": "Arkansas",
		"Code":	"AR"
	},
	{
		"State": "California",
		"Code":	"CA"
	},
	{
		"State": "Colorado",
		"Code":	"CO"
	},
	{
		"State": "Connecticut",
		"Code":	"CT"
	},
	{
		"State": "Delaware",
		"Code":	"DE"
	},
	{
		"State": "District of Columbia",
		"Code":	"DC"
	},
	{
		"State": "Florida",
		"Code":	"FL"
	},
	{
		"State": "Georgia",
		"Code":	"GA"
	},
	{
		"State": "Hawaii",
		"Code":	"HI"
	},
	{
		"State": "Idaho",
		"Code":	"ID"
	},
	{
		"State": "Illinois",
		"Code":	"IL"
	},
	{
		"State": "Indiana",
		"Code":	"IN"
	},
	{
		"State": "Illinois",
		"Code":	"IL"
	},
	{
		"State": "Iowa",
		"Code":	"IA"
	},
	{
		"State": "Kansas",
		"Code":	"KS"
	},
	{
		"State": "Kentucky",
		"Code":	"KY"
	},
	{
		"State": "Louisiana",
		"Code":	"LA"
	},
	{
		"State": "Maine",
		"Code":	"ME"
	},
	{
		"State": "Maryland",
		"Code":	"MD"
	},
	{
		"State": "Massachusetts",
		"Code":	"MA"
	},
	{
		"State": "Michigan",
		"Code":	"MI"
	},
	{
		"State": "Minnesota",
		"Code":	"MN"
	},
	{
		"State": "Mississippi",
		"Code":	"MS"
	},
	{
		"State": "Missouri",
		"Code":	"MO"
	},
	{
		"State": "Montana",
		"Code":	"MT"
	},
	{
		"State": "Nebraska",
		"Code":	"NE"
	},
	{
		"State": "Nevada",
		"Code":	"NV"
	},
	{
		"State": "New Hampshire",
		"Code":	"NH"
	},
	{
		"State": "New Jersey",
		"Code":	"NJ"
	},
	{
		"State": "New Mexico",
		"Code":	"NM"
	},
	{
		"State": "New York",
		"Code":	"NY"
	},
	{
		"State": "North Carolina",
		"Code":	"NC"
	},
	{
		"State": "North Dakota",
		"Code":	"ND"
	},
	{
		"State": "Ohio",
		"Code":	"OH"
	},
	{
		"State": "Oklahoma",
		"Code":	"OK"
	},
	{
		"State": "Oregon",
		"Code":	"OR"
	},
	{
		"State": "Pennsylvania",
		"Code":	"PA"
	},
	{
		"State": "Rhode Island",
		"Code":	"RI"
	},
	{
		"State": "South Carolina",
		"Code":	"SC"
	},
	{
		"State": "South Dakota",
		"Code":	"SD"
	},
	{
		"State": "Tennessee",
		"Code":	"TN"
	},
	{
		"State": "Texas",
		"Code":	"TX"
	},
	{
		"State": "Utah",
		"Code":	"UT"
	},
	{
		"State": "Vermont",
		"Code":	"VT"
	},
	{
		"State": "Virginia",
		"Code":	"VA"
	},
	{
		"State": "Washington",
		"Code":	"WA"
	},
	{
		"State": "West Virginia",
		"Code":	"WV"
	},
	{
		"State": "Wisconsin",
		"Code":	"WI"
	},
	{
		"State": "Wyoming",
		"Code":	"WY"
	}
]

const AccountConfigureForm = () => {

	const {
		page,
		setPage,
		data,
		billingData,
		title,
		canSubmit,
		disablePrev,
		disableNext,
		prevHide,
		nextHide,
		submitHide
	} = useFormContext()

	const handlePrev = () => setPage(prev => prev - 1)

	const handleNext = () => setPage(prev => prev + 1)

	const axiosPrivate = useAxiosPrivate();

	const navigate = useNavigate()

	const {auth, setAuth} = useAuth()
	const subscription = auth?.subscription
	const subscriptionID = subscription?.id

	const [subscriptionErrorMessage, setSubscriptionErrorMessage] = useState()
	const [transactionErrorMessage, setTransactionErrorMessage] = useState()

	const monthlyCharges = data.monthly_charges
	const serviceCategories = data.selected_services
	const activeCounties = data.selected_counties
	const excludedZips = data.excluded_zips
	const selectedStateCode = data.active_state
	const selectedOptions = data.selected_options

	const [responseError, setResponseError] = useState()

	const selectedState = states?.find(state => {
		return state.Code === selectedStateCode;
	});

	let selectedStateName = selectedState?.State


	const handleSubmit = async (e) => {
		e.preventDefault();

		// const dismiss = () =>  toast.dismiss(createSubToast.current);
		// const subscribeToast = toast.loading("Subscribing");
		const controller = new AbortController();

		try {
			const response = await axiosPrivate.put('subscription/' + subscriptionID,
			JSON.stringify({subscription_charge: monthlyCharges, service_categories: serviceCategories, active_counties: activeCounties, excluded_zipcodes: excludedZips, subscription_status: "active", active_state: selectedState, selected_options: selectedOptions, billing_info: billingData}),
			{
				signal: controller.signal
			});

			setSubscriptionErrorMessage(response.data.subscription.status)
			setTransactionErrorMessage(response.data.transaction.status)

			console.log(response.data.status)
			// redirect to contractor package page
			if (response.data.subscription.status === "fail" || response.data.transaction.status === "fail"){
				if (response.data.subscription.status === "fail" ){
					setResponseError(response.data.subscription.message)
				} else if (response.data.transaction.status === "fail") {
					setResponseError(response.data.transaction.message)
				} else {
					setResponseError("Error running payment")
				}
				const createSubNSRToast = toast.error(responseError, { type: 'error', isLoading: false, autoClose: 5000});
				console.log(response.data.subscription.message)
				console.log(response.data.transaction.message)
				setSubscriptionErrorMessage(response.data.message)
			} else {
				setSubscriptionErrorMessage()
				setTransactionErrorMessage()
				setAuth({...auth, subscription: response.data.subscription.subscription });
				navigate("/user/account", { state: {newSub: true}, replace: false })
				const createSubToast = toast.success("Subscribed", { type: 'success', isLoading: false, autoClose: 5000});
			}

			// Update Toast Notification
			{/* toast.update(subscribeToast, { render: 'Subscribed', type: 'success', isLoading: false, autoClose: 5000}); */}
		} catch (err) {
			if (!err?.response) {
				{/* toast.update(subscribeToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000}); */}
				const createSubNSRToast = toast.error("No Server Response", { type: 'error', isLoading: false, autoClose: 5000});

				{/* setErrMsg('No Server Response'); */}
			} else if (err.response?.status === 401) {
				const createSubAFToast = toast.error("Authorization Failed", { type: 'error', isLoading: false, autoClose: 5000});
				{/* toast.update(subscribeToast, { render: 'Authorization Failed', type: 'error', isLoading: false}); */}
				{/* setErrMsg('The email has already been taken.'); */}
			} else {
				const createSubSFToast = toast.error("Subscription Failed", { type: 'error', isLoading: false, autoClose: 5000});
				{/* toast.update(subscribeToast, { render: 'Subscription Failed', type: 'error', isLoading: false, autoClose: 5000});				 */}
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	const content = (
		<>
		<form className="configureForm" onSubmit={handleSubmit}>

			<div className="formHeader">
				<h2>{title[page]}</h2>
			</div>

			<FormInputs />

			<div className="buttonWrapper">
				<button type="button" className={`button ${prevHide}`} onClick={handlePrev} disabled={disablePrev}>Previous</button>
				<button type="button" className={`button ${nextHide}`} onClick={handleNext} disabled={disableNext}>Next</button>
				<button type="submit" className={`button ${submitHide}`} disabled={!canSubmit}>Subscribe</button>
			</div>
			{subscriptionErrorMessage ?
				<p className="ccError"><GoAlert /> {subscriptionErrorMessage}</p>
				:
				null
			}
		</form>
		<ToastContainer
		position="top-right"
		autoClose={5000}
		hideProgressBar={false}
		newestOnTop={false}
		closeOnClick
		rtl={false}
		pauseOnFocusLoss
		draggable
		pauseOnHover
		theme="colored"
		/>
		</>
	)

	return content
}
export default AccountConfigureForm