import usePageTitle from '../../hooks/usePageTitle';
import LegalPage from '../../components/LegalPage';

const Terms = () => {
	usePageTitle("Terms of Use");

	return (
		<LegalPage title='Terms of Use' slug='terms-of-use' />
	)
}

export default Terms